import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { removeEmpty } from 'helper';
import withModal from 'modules/common/hoc/withModal';
import ButtonAsync from 'components/Button';
import { ToastSuccess } from 'modules/common/components/Toast';
import { ModalContext } from 'App';
import * as actions from '../action';

function CorpStatusModal({
  updateCorpAdmin,
  data,
  getCorpUsers,
  validatePassword,
  logoutUser,
}) {
  const setModal = useContext(ModalContext);
  const [view, setView] = useState(false);
  const [formData, setFormData] = useState({});
  const [pending, setPending] = useState();

  const modalData = useSelector(
    ({ modal }) => _.get(modal, 'modalData', {}) || {}
  );

  const handleOnViewPassword = value => e => {
    e.preventDefault();
    setView(value);
  };

  const closeModal = e => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleOnChange = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const checkInput = () => {
    return _.isEmpty(formData.password);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);
    const reset = () => {
      setFormData({});
      setPending(false);
    };
    const finalCall = () => {
      ToastSuccess(`Successfully ${data.returnLabel}.`);
      getCorpUsers(removeEmpty(modalData.filter));
      setModal({ isOpen: false });
      reset();
    };
    const update = () =>
      updateCorpAdmin(data.retailerId, data.payload, finalCall, reset);
    validatePassword(formData, update, reset);
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="text-center">
        <span className="font-weight-bold">
          Are you sure you want to {data.label}?
        </span>
      </div>
      <div className="text-center">
        <small>Your password is required to continue.</small>
      </div>
      <div className="form-group">
        <div className="prepend-form-right">
          <label className="form-label text-success">Password:</label>
          <div className="prepend-input-group">
            <input
              type={view ? 'text' : 'password'}
              className="form-control form-custom form-control_bottom"
              required
              autoFocus
              name="password"
              value={_.get(formData, 'password') || ''}
              onChange={handleOnChange}
            />
          </div>
          <button
            type="button"
            onClick={handleOnViewPassword(!view)}
            className="btn btn-default rounded-circle p-0 mr-1"
          >
            <i className={`fas ${!view ? 'fa-eye' : 'fa-eye-slash'}`} />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-center">
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold mr-2"
            >
              CANCEL
            </button>

            <ButtonAsync
              type="submit"
              loading={pending}
              disabled={checkInput()}
            >
              PROCEED
            </ButtonAsync>
          </div>
        </div>
      </div>
    </form>
  );
}

CorpStatusModal.propTypes = {
  updateCorpAdmin: PropTypes.func.isRequired,
  getCorpUsers: PropTypes.func.isRequired,
  validatePassword: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = ({ modal, api }) => ({
  data: _.get(modal, 'modalData.data', {}),
});

const enhance = _.flowRight([
  withModal('corp-update-status', {
    title: '',
    size: 'modal-md',
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(CorpStatusModal);
