import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ModalContext } from 'App';
import withModal from 'modules/common/hoc/withModal';
import GenericTable from 'modules/common/components/GenericTable';
import ButtonAsync from 'components/Button';
import { ToastSuccess, ToastError } from 'modules/common/components/Toast';
import * as action from '../actions';

const BatchCreateSetup = ({ csvData, validation, onComplete }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const setModal = React.useContext(ModalContext);

  const handleBatchCreateSuccess = data => {
    setLoading(false);
    setModal({ isOpen: false });
    ToastSuccess(data?.res?.data?.success?.[0]?.detail);
    if (typeof onComplete !== 'function') return;
    onComplete();
  };

  const handleBatchCreateError = () => {
    setLoading(false);
    setModal({ isOpen: false });
    ToastError('Error creating users');
  };

  const handleBatchCreate = () => {
    setLoading(true);
    dispatch(
      action.createBatchRetailer(
        { csv_link: csvData.url },
        handleBatchCreateSuccess,
        handleBatchCreateError
      )
    );
  };

  return (
    <>
      <div className="text-sm mb-2">
        File: <strong>{csvData.name}</strong>
      </div>
      <GenericTable
        data={validation.map((item, index) => ({ ...item, index }))}
        format={[
          {
            key: item => (
              <span className="text-sm">
                {item?.mobile_identification_number}
              </span>
            ),
            label: 'Mobile',
          },
          {
            key: item => <span className="text-sm">{item?.retailer_type}</span>,
            label: 'Retailer Type',
          },
          {
            key: item => <span className="text-sm">{item?.birth_date}</span>,
            label: 'Birthday',
          },
          {
            key: item => <span className="text-sm">{item?.first_name}</span>,
            label: 'First Name',
          },
          {
            key: item => <span className="text-sm">{item?.last_name}</span>,
            label: 'Last Name',
          },
          {
            key: item => <span className="text-sm">{item?.email}</span>,
            label: 'Email',
          },
          {
            key: item => <span className="text-sm">{item?.address}</span>,
            label: 'Address',
          },
          {
            key: item => <span className="text-sm">{item?.province}</span>,
            label: 'Province',
          },
          {
            key: item => (
              <span className="text-sm">{item?.city_municipality}</span>
            ),
            label: 'City / Municipality',
          },
          {
            key: item => <span className="text-sm">{item?.barangay}</span>,
            label: 'Barangay',
          },
          {
            key: item => <span className="text-sm">{item?.zip_code}</span>,
            label: 'Zip Code',
          },
          {
            key: item => <Errors errors={item.errors} />,
            label: <span className="text-xs">Remarks</span>,
            className: 'lh-1',
          },
        ]}
        headerClassName="bg-gray-600"
        containerClassName="text-sm relative table-sticky border"
        customStyles={{ overflow: 'auto', maxHeight: '50vh' }}
      />
      <p className="mt-3">
        <strong>Notice:</strong>
      </p>
      <div className="row">
        <div className="col text-sm">
          <p className="mb-2">
            Onboarding process can still proceed on items with no errors.
          </p>
          <p>
            Conflicting data will be recorded to{' '}
            <strong>&quot;Error Logs&quot;</strong> and will be available for
            download.
          </p>
        </div>
        <div className="col text-sm d-flex justify-content-end align-items-end">
          <p>
            <ButtonAsync
              loading={loading}
              disabled={loading || !validation.length}
              onClick={handleBatchCreate}
            >
              <span className="px-2">Batch Create</span>
            </ButtonAsync>
          </p>
        </div>
      </div>
    </>
  );
};

BatchCreateSetup.propTypes = {
  csvData: PropTypes.instanceOf(Object).isRequired,
  validation: PropTypes.instanceOf(Object).isRequired,
  onComplete: PropTypes.func.isRequired,
};

const modalKey = 'batch-create-retailer-setup';
const modalConfig = {
  title: 'Batch Create Account Validation',
  size: 'modal-xl',
};

export default withModal(modalKey, modalConfig)(BatchCreateSetup);

const Errors = ({ errors }) => {
  if (!Object.keys(errors).length)
    return <div className="text-xs text-success">OK</div>;

  return Object.keys(errors).map(e => {
    return errors[e].map(error => (
      <div className="text-xs text-danger mb-1">* {error}</div>
    ));
  });
};

Errors.propTypes = {
  errors: PropTypes.instanceOf(Object).isRequired,
};
