import React from 'react';
import PropTypes from 'prop-types';

const Panel = ({ title, children, renderActions: Actions, clearBg }) => {
  const childrenContainer = !clearBg
    ? {
        className: 'bg-white',
        style: {
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        },
      }
    : {};
  return (
    <div
      className={`${!clearBg ? 'shadow-sm ' : ''}m-3`}
      style={{ borderRadius: 5 }}
    >
      <div
        className="bg-gray-500 text-white d-flex align-items-stretch justify-content-between overflow-hidden"
        style={{
          height: '2.5rem',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      >
        <div className="text-sm align-self-center font-weight-bold px-3">
          {title}
        </div>
        <Actions />
      </div>
      <div {...childrenContainer}>{children}</div>
    </div>
  );
};

export default Panel;

Panel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  renderActions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  clearBg: PropTypes.bool,
};

Panel.defaultProps = {
  renderActions: () => null,
  clearBg: false,
};
