import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IconPhoto } from 'components/Icons';
import placeholderPhoto from 'assets/images/upload-square-placeholder.png';
import { ToastSuccess } from 'modules/common/components/Toast';
import { useLoading } from 'modules/ui/hooks';
import Img from 'modules/common/components/ImgDoc';
import IconTooltip from 'modules/common/components/IconTooltip';
import UploadFile from '../../../components/UploadFileNew';
import * as c from '../../constants';
import * as actions from '../../actions';

const Loader = () => (
  <div className="text-center w-100" style={{ fontSize: 25 }}>
    <span className="fas fa-1x fa-spin fa-spinner" />
  </div>
);

const renderStatus = item => {
  const status = _.get(item, 'attributes.status');
  if (status === 'PENDING') return null;
  const custom = {
    icon: '',
    textStyle: '',
  };
  if (status === 'REJECTED') {
    custom.icon = 'fas fa-exclamation-circle';
    custom.textStyle = 'text-danger';
  }
  if (status === 'APPROVED') {
    custom.icon = 'fas fa-check-circle';
    custom.textStyle = 'text-primary';
  }
  const component = (
    <IconTooltip
      disable={status !== 'REJECTED'}
      icon={`${custom.icon} ${custom.textStyle} supporting-doc-icon`}
      text={_.get(item, 'attributes.reject_reason') || ''}
      id={item?.id || ''}
      backgroundColor="black"
      place="top"
    />
  );
  return component;
};

const ApprovedDocs = ({ docs, loading }) => {
  if (docs.length > 0 && !loading)
    return docs.map(item => (
      <div className="col-sm-12 col-md-6 col-lg-3" key={item.id}>
        <div className="d-flex align-items-baseline justify-content-center">
          <h4 className="label">{_.get(item, 'attributes.name', '')}</h4>
          {renderStatus(item)}
        </div>
        <div className="requirement-item">
          <Img
            lightBox
            withZoom
            defaultSrc={placeholderPhoto}
            type={_.get(item, 'attributes.type')}
            src={_.get(item, 'attributes.value') || ''}
          />
        </div>
      </div>
    ));
  return (
    <div className="text-center w-100">
      <p className="m-0" style={{ fontSize: '.7rem' }}>
        There are no records found
      </p>
    </div>
  );
};

ApprovedDocs.propTypes = {
  docs: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  loading: PropTypes.bool.isRequired,
};

const SupportingDocumentsPage = ({ isSubmitFinish, dealer_id, status }) => {
  const dispatch = useDispatch();

  const userProfile = useSelector(({ api }) =>
    _.get(api, 'AUTH/user_profile.item')
  );
  const userDetails = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_USER_ACCESS_DETAILS}.item`)
  );
  const requiredDocumentsImages = useSelector(({ api }) =>
    _.get(api, `${c.GET_REQUIRED_DOCUMENTS_IMAGES_LIST}.list`, [])
  );
  const initialList = useSelector(({ api }) =>
    _.get(api, `${c.GET_CHANNEL_LIST}.list`, [])
  );
  const channelList = useMemo(() => {
    return _.sortBy(initialList, [
      ch => {
        return +_.get(ch, 'attributes.primary_id');
      },
    ]);
  }, [initialList]);
  const companyInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_COMPANY_INFORMATION}.item`, {})
  );

  const isChannelLoading = useLoading(c.GET_CHANNEL_LIST);
  const reqDocsLoading = useLoading(c.GET_REQUIRED_DOCUMENTS_IMAGES_LIST);

  const dealer_company_id = _.get(companyInfo, 'id');

  const channel_id =
    _.get(userProfile, 'channel_id') ||
    _.get(userDetails, 'attributes.channel_id');
  const updated_by = _.get(userProfile, 'full_name');

  const isAllowed = useMemo(() => status === null || status === 'returned', [
    userDetails,
  ]);

  const removeDuplicateDocumentsImages = useMemo(
    () =>
      Array.from(
        new Set(
          requiredDocumentsImages.map(({ attributes }) =>
            _.get(attributes, 'name')
          )
        )
      ).map(name =>
        requiredDocumentsImages.find(
          ({ attributes }) => _.get(attributes, 'name') === name
        )
      ),
    [requiredDocumentsImages]
  );

  const isMatch = useCallback(
    imgName => {
      const filtered = removeDuplicateDocumentsImages.filter(
        ({ attributes }) => _.get(attributes, 'name') === imgName
      );

      return filtered ? _.get(filtered, '0') : '';
    },
    [removeDuplicateDocumentsImages]
  );

  const handleUploadImg = (files, item) => {
    const successCallback = () => {
      dispatch(actions.getRequiredDocumentsImagesList(dealer_company_id));
      if (isMatch(_.get(item, 'attributes.name')))
        ToastSuccess('Successfully Updated Document.');
      else ToastSuccess('Successfully Added Document.');
    };

    const samePayload = {
      name: _.get(item, 'attributes.name'),
      value: _.get(files, '0.file'),
      size: _.get(files, '0.size'),
      type: _.get(files, '0.type'),
      requirement_id: _.get(item, 'id'),
      modified_by: updated_by,
    };

    const updatePayload = {
      updated_by,
      management_account_step: 5,
      documents: [
        {
          document_id: _.get(isMatch(_.get(item, 'attributes.name')), 'id'),
          ...samePayload,
        },
      ],
    };

    const addPayload = {
      updated_by,
      management_account_step: 5,
      documents: [
        {
          uploaded_by: updated_by,
          ...samePayload,
        },
      ],
    };

    if (isMatch(_.get(item, 'attributes.name')))
      dispatch(
        actions.updateRequiredDocumentsImage(
          dealer_company_id,
          updatePayload,
          successCallback
        )
      );
    else
      dispatch(
        actions.addRequiredDocumentsImage(
          dealer_company_id,
          addPayload,
          successCallback
        )
      );
  };

  useEffect(() => {
    if (_.isEmpty(channel_id)) return;
    dispatch(actions.getChannelList(channel_id));
  }, [channel_id]);

  useEffect(() => {
    if (dealer_company_id) {
      dispatch(
        actions.showMoaSignatoryInformation(dealer_id, dealer_company_id)
      );
      dispatch(actions.getRequiredDocumentsImagesList(dealer_company_id));
    }
  }, [dealer_company_id, dealer_id]);

  const shouldRenderLoading = isChannelLoading || !channel_id;
  const shouldRenderApproved = status === 'done' || status === 'approved';

  if (_.isUndefined(status))
    return (
      <div className="card-body upload-requirements mt-4">
        <Loader />
      </div>
    );

  if (shouldRenderApproved)
    return (
      <div className="card-body upload-requirements">
        <div className="requirement-wrapper mt-3">
          {reqDocsLoading ? (
            <Loader />
          ) : (
            <div className="documents-container row">
              <ApprovedDocs
                docs={removeDuplicateDocumentsImages}
                loading={reqDocsLoading}
              />
            </div>
          )}
        </div>
      </div>
    );

  return (
    <div>
      <div className="note">
        <strong>Note:</strong> Please provide a clear copy of your document to
        upload. Accepted file formats are .jpeg / .jpg, .png, and .pdf only.
      </div>
      <div className="card-body upload-requirements">
        <div className="requirement-wrapper mt-3">
          {shouldRenderLoading ? (
            <Loader />
          ) : (
            <div className="documents-container row">
              {channelList.length > 0 ? (
                <>
                  {channelList.map(item => {
                    const current = isMatch(_.get(item, 'attributes.name'));
                    return (
                      <div
                        className="col-sm-12 col-md-6 col-lg-3"
                        key={item.id}
                      >
                        <div className="d-flex align-items-baseline justify-content-center">
                          <h4 className="label">
                            {_.get(item, 'attributes.name', '')}
                          </h4>
                          {renderStatus(current)}
                        </div>

                        <div className="requirement-item">
                          {!_.isEmpty(current) ? (
                            <>
                              <Img
                                lightBox
                                withZoom
                                defaultSrc={placeholderPhoto}
                                type={_.get(current, 'attributes.type')}
                                src={_.get(current, 'attributes.value') || ''}
                              />
                              {!_.isEmpty(current) && isAllowed && (
                                <div className="reupload-button">
                                  <UploadFile
                                    caption="Maximum size for the document is 40MB. (.jpg / .jpeg, .png, .pdf)"
                                    fileType="image/*, application/pdf"
                                    className="btn btn-sm btn-success font-weight-semibold"
                                    fileName={item}
                                    disabled={
                                      _.get(current, 'attributes.status') ===
                                      'APPROVED'
                                    }
                                    onChange={handleUploadImg}
                                    customButton={() => (
                                      <>
                                        <i className="fas fa-file-upload mr-2" />
                                        Update Document
                                      </>
                                    )}
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            <section>
                              <span className="icon">
                                <IconPhoto />
                              </span>
                              <h5 className="icon-label">
                                Upload a soft copy of this document.
                              </h5>
                              <UploadFile
                                caption="Maximum size for the document is 40MB. (.jpg / .jpeg, .png, .pdf)"
                                fileType="image/*, application/pdf"
                                className="btn btn-success text-center px-3"
                                onChange={handleUploadImg}
                                fileName={item}
                                disabled={isSubmitFinish}
                              />
                            </section>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="text-center w-100">
                  <p className="m-0" style={{ fontSize: '.7rem' }}>
                    There are no records found
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SupportingDocumentsPage.propTypes = {
  isSubmitFinish: PropTypes.bool.isRequired,
  dealer_id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default SupportingDocumentsPage;
