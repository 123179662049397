import React, { useState, useContext, useEffect } from 'react';
import withModal from 'modules/common/hoc/withModal';
import { connect, useDispatch } from 'react-redux';
import { withRouter, useRouteMatch } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ModalContext } from 'App';
import ButtonAsync from 'components/Button';
import * as a from '../action';

function UpdateSpecialPricings({ data }) {
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);
  const [formData, setFormData] = useState();
  const parentMatch = useRouteMatch();
  const match = useRouteMatch(`${parentMatch.url}/:pricing_id?`);
  const { pricing_id } = _.get(match, 'params');

  const closeModal = () => {
    setModal({ isOpen: false });
  };

  const handleChangeInput = key => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value,
    });
  };

  const [pending, setPending] = useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    const payload = {
      ...formData,
      special_price: _.get(formData, 'special_price'),
    };

    setPending(true);
    dispatch(
      a.updateSpecialPricing(pricing_id, _.get(formData, 'id'), payload, () => {
        dispatch(
          a.getSpecialPricingByPriceMatrix(pricing_id, { is_active: true })
        );
        closeModal();
      })
    );
  };

  const hasNewData = !_.isEmpty(data);

  useEffect(() => {
    setFormData({
      id: _.get(data, 'id'),
      special_price: _.get(data, 'attributes.special_price'),
      category: _.get(data, 'attributes.category'),
      category_value: _.get(data, 'attributes.category_value'),
      start_date: _.get(data, 'attributes.start_date'),
      expiry_date: _.get(data, 'attributes.expiry_date'),
      company_name: _.get(data, 'attributes.company_name'),
      code: _.get(data, 'attributes.code'),
    });
  }, [hasNewData, data]);

  return (
    <form onSubmit={handleSubmit} className="px-2">
      <div className="row mt-2">
        <div className="col-12">
          <label className="form-label font-weight-bold">Special Price</label>
        </div>

        <div className="col-2 mb-3">
          <input
            type="text"
            readOnly
            value="PHP"
            placeholder="PHP"
            className="form-control form-custom form-control_bottom"
          />
        </div>
        <div className="col-6 mb-3">
          <input
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={_.get(formData, 'special_price') || ''}
            onChange={handleChangeInput('special_price')}
            pattern="[+-]?\d+(?:[.,]\d+)?"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <label className="form-label font-weight-bold">Price Category</label>
          <input
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={_.get(formData, 'category') || ''}
            onChange={handleChangeInput('category')}
            disabled
          />
        </div>
        <div className="col-6">
          <label className="form-label font-weight-bold">Value</label>
          <input
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={_.get(formData, 'category_value') || ''}
            onChange={handleChangeInput('category_value')}
            disabled
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6">
          <label className="form-label font-weight-bold">
            Effectivity Date:
          </label>
          <input
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={moment(_.get(formData, 'start_date')).format('ll') || ''}
            onChange={handleChangeInput('start_date')}
            disabled
          />
        </div>
        <div className="col-6">
          <label className="form-label font-weight-bold">Expiry Date:</label>
          <input
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={moment(_.get(formData, 'expiry_date')).format('ll') || ''}
            onChange={handleChangeInput('expiry_date')}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 my-4">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <ButtonAsync
              type="submit"
              className="btn btn-primary p-3 position-relative text-uppercase mx-2"
              loading={pending}
            >
              Save
            </ButtonAsync>
            <button
              type="button"
              onClick={closeModal}
              className="text-uppercase btn p-3 btn-secondary font-weight-bold mr-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

UpdateSpecialPricings.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ modal }) => ({
  data: _.get(modal, 'modalData.data') || {},
});

const enhance = _.flowRight([
  withModal('fill-update-overide-special-price', {
    title: 'Overide Special Price',
    size: 'modal-md',
  }),
  withRouter,
  connect(mapStateToProps, a),
]);

export default enhance(UpdateSpecialPricings);
