import React from 'react';
import PropTypes from 'prop-types';
import ButtonAsync from 'modules/common/components/ButtonAsync';
import AgentSelectField from './AgentSelectField';

const TransferSelectSetup = React.memo(
  ({ onTransfer, onChange, loading, disabled }) => {
    return (
      <div className="px-3 mb-4">
        <h5 className="font-weight-bold m-0 mt-4 mb-3">Transfer to:</h5>
        <div className="d-flex justify-content-between align-items-end">
          <div className="col-6 p-0">
            <label className="form-label text-gray-400 font-weight-bold">
              Sub-Dealer Agent *
            </label>
            <AgentSelectField onChange={onChange} />
          </div>
          <ButtonAsync
            onClick={onTransfer}
            loading={loading}
            disabled={disabled}
            style={{ height: '3rem' }}
            appendClass="px-4"
          >
            transfer
          </ButtonAsync>
        </div>
      </div>
    );
  }
);

TransferSelectSetup.propTypes = {
  onTransfer: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TransferSelectSetup;
