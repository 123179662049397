import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { checkPermissions } from "helper";
import moment from "moment";
import { NavLink, useRouteMatch, Switch, Route } from "react-router-dom";
import EmptyImgState from "modules/common/components/EmptyImgState";
import ButtonAsync from "modules/common/components/ButtonAsync";
import Fronliners from "./Fronliners";
import BranchAdmins from "./BranchAdmins";
import * as c from "../constant";

function BranchDetails({
  onUpdateBranch,
  branchDetails,
  onBlock,
  onRemove,
  roles,
  onDownloadBranchHistory,
  isLoadingHistory,
}) {
  const match = useRouteMatch();
  const sub_match = useRouteMatch(`${match.path}/:id`);
  const branchId = _.get(sub_match, "params.id");

  const handleBlockButton = (value, branchName) => {
    if (value) {
      return (
        <div className="d-flex flex-wrap">
          <div className="col-6 px-1 pb-2">
            <ButtonAsync
              appendClass="btn-sm btn-block btn-warning"
              onClick={onDownloadBranchHistory(branchId)}
              loading={isLoadingHistory}
            >
              History
            </ButtonAsync>
          </div>
          {checkPermissions(["can-update-branch"]) && (
            <div className="col-6 px-1 pb-2">
              <button
                type="button"
                className="btn btn-sm btn-block btn-primary"
                onClick={onUpdateBranch(branchDetails)}
              >
                Update
              </button>
            </div>
          )}
          {checkPermissions(["can-delete-branch"]) && (
            <div className="col-6 px-1 pb-2">
              <button
                type="button"
                className="btn btn-sm btn-block btn-danger"
                onClick={onRemove(branchDetails)}
              >
                Delete
              </button>
            </div>
          )}
          {checkPermissions(["can-update-branch"]) && (
            <div className="col-6 px-1 pb-2">
              <button
                type="button"
                className="btn btn-sm btn-block btn-warning"
                onClick={onBlock("UNBLOCK", false, branchName, "Unblocked")}
              >
                UNBLOCK
              </button>
            </div>
          )}
        </div>
      );
    }
    if (!value) {
      return (
        <div className="d-flex flex-wrap">
          <div className="col-6 px-1 pb-2">
            <ButtonAsync
              appendClass="btn-sm btn-block btn-warning"
              onClick={onDownloadBranchHistory(branchId)}
              loading={isLoadingHistory}
            >
              History
            </ButtonAsync>
          </div>
          {checkPermissions(["can-update-branch"]) && (
            <div className="col-6 px-1 pb-2">
              <button
                type="button"
                className="btn btn-sm btn-block btn-primary"
                onClick={onUpdateBranch(branchDetails)}
              >
                Update
              </button>
            </div>
          )}
          {checkPermissions(["can-delete-branch"]) && (
            <div className="col-6 px-1 pb-2">
              <button
                type="button"
                className="btn btn-sm btn-block btn-danger"
                onClick={onRemove(branchDetails)}
              >
                Delete
              </button>
            </div>
          )}
          {checkPermissions(["can-update-branch"]) && (
            <div className="col-6 px-1 pb-2">
              <button
                type="button"
                className="btn btn-sm btn-block btn-warning"
                onClick={onBlock("BLOCK", true, branchName, "Blocked")}
              >
                BLOCK
              </button>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  if (_.isEmpty(branchId)) {
    return (
      <div className="main-content-inner p-0 h-100">
        <div className="container-fluid h-100">
          <EmptyImgState />
        </div>
      </div>
    );
  }

  const sourceFunds = {
    dealer: _.get(branchDetails, "included.sources", [])
      .filter((source) => _.get(source, "attributes.type") === "dealer_sims")
      .reduce(
        (r, min) =>
          _.get(min, "attributes.is_default") ? [min, ...r] : [...r, min],
        []
      ),
    retailer: _.get(branchDetails, "included.sources", [])
      .filter((min) => _.get(min, "attributes.type") === "dealer_retailer_sims")
      .reduce(
        (r, min) =>
          _.get(min, "attributes.is_default") ? [min, ...r] : [...r, min],
        []
      ),
  };

  const role = _.head(roles);

  const canViewBranchId = role === "super-user" || role === "smmt";

  return (
    <div>
      <div className="row px-3">
        <div className="col col-xl-8 d-xl-flex pl-0">
          <div className="col pl-0 text-sm">
            <div>
              <span className="font-weight-bold mr-1">Branch Name: </span>
              {_.truncate(_.get(branchDetails, "attributes.name"), {
                length: c.TRUNCATE_LIMIT,
              }) || "--"}
            </div>
            <div>
              <span className="font-weight-bold mr-1">Business Style: </span>
              {_.truncate(_.get(branchDetails, "attributes.business_style"), {
                length: c.TRUNCATE_LIMIT,
              }) || "--"}
            </div>
            <div>
              <span className="font-weight-bold mr-1">Address: </span>
              {_.truncate(_.get(branchDetails, "attributes.address"), {
                lenth: c.TRUNCATE_LIMIT,
              }) || "--"}
            </div>
          </div>
          <div className="col pl-0 text-sm">
            <div>
              <span className="font-weight-bold mr-1">Branch TIN: </span>
              {_.get(branchDetails, "attributes.tin") || "--"}
            </div>
            <div>
              <span className="font-weight-bold mr-1">Branch Code: </span>
              {_.truncate(_.get(branchDetails, "attributes.code"), {
                length: c.TRUNCATE_LIMIT,
              }) || "--"}
            </div>
            <div>
              <span className="font-weight-bold mr-1">Status:</span>
              {_.get(branchDetails, "attributes.is_blocked") === true ? (
                <span className="text-danger">Inactive</span>
              ) : (
                <span className="text-success">Active</span>
              )}
            </div>
          </div>
          <div className="col pl-0 text-sm">
            {canViewBranchId && (
              <div>
                <span className="font-weight-bold mr-1">Branch ID:</span>
                {branchId}
              </div>
            )}
            <div>
              <span className="font-weight-bold mr-1">Date Created: </span>
              {moment(_.get(branchDetails, "attributes.created_at")).format(
                "LL"
              ) || "--"}
            </div>
          </div>
        </div>
        <div className="col pl-0">
          {handleBlockButton(
            _.get(branchDetails, "attributes.is_blocked"),
            _.get(branchDetails, "attributes.name")
          )}
        </div>
      </div>
      <hr />
      <div className="row px-3 mt-3">
        <div className="col px-0">
          <div>
            <span className="font-weight-bold mr-1"> Source Fund: </span>
            <div className="d-flex flex-wrap align-items-center mt-1">
              <span className="text-sm mr-1">Dealer MINs:</span>
              {sourceFunds.dealer.map(({ id, attributes }) => (
                <div className="text-height-3">
                  <span
                    key={`rt_${id.slice(-6)}`}
                    className={`badge badge-pill mx-1 ${
                      _.get(attributes, `is_default`)
                        ? `badge-primary`
                        : `badge-secondary`
                    }`}
                  >
                    {_.get(attributes, `mobile_identification_number`)}
                  </span>
                </div>
              ))}
            </div>
            <div className="d-flex flex-wrap align-items-center mt-1">
              <span className="text-sm mr-1">Retailers MINs:</span>
              {sourceFunds.retailer.map(({ id, attributes }) => (
                <div className="text-height-3">
                  <span
                    key={`rt_${id.slice(-6)}`}
                    className={`badge badge-pill mx-1 ${
                      _.get(attributes, `is_default`)
                        ? `badge-primary`
                        : `badge-secondary`
                    }`}
                  >
                    {_.get(attributes, `mobile_identification_number`)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ul className="nav nav-tabs mt-4">
        {checkPermissions(["can-view-retailer-user-branch-admin"]) && (
          <li className="nav-item">
            <NavLink
              to={`${match.path}/${branchId}/branch-admins`}
              className="nav-link"
              activeClassName="active"
            >
              Branch Admins
            </NavLink>
          </li>
        )}
        {checkPermissions(["can-view-retailer-user-frontliner"]) && (
          <li className="nav-item">
            <NavLink
              to={`${match.path}/${branchId}/frontliners`}
              className="nav-link"
              activeClassName="active"
            >
              Frontliners
            </NavLink>
          </li>
        )}
      </ul>
      <div className="card">
        <div
          className="card-body p-0"
          style={{ borderTop: "6px solid #049051" }}
        >
          <Switch>
            <Route path={`${match.path}/${branchId}/branch-admins`}>
              <BranchAdmins branchId={branchId} />
            </Route>
            <Route path={`${match.path}/${branchId}/frontliners`}>
              <Fronliners branchId={branchId} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

BranchDetails.defaultProps = {
  isLoadingHistory: false,
};

BranchDetails.propTypes = {
  onUpdateBranch: PropTypes.func.isRequired,
  onBlock: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onDownloadBranchHistory: PropTypes.func.isRequired,
  branchDetails: PropTypes.instanceOf(Object).isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  isLoadingHistory: PropTypes.bool,
};

export default BranchDetails;
