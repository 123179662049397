import _ from "lodash";

export const GET_RETAILERS_SIMS = "MANAGE/get_retailers_sims";
export const GET_DEALER_SIMS = "MANAGE/get_dealer_sims";
export const GET_BRANCH = "MANAGE/get_branch";
export const SHOW_BRANCH = "MANAGE/show_branch";
export const CREATE_BRANCH = "MANAGE/create_branch";
export const UPDATE_BRANCH = "MANAGE/update_branch";
export const REMOVE_BRANCH = "MANAGE/remove_branch";

export const VALIDATE_PASSWORD = "MANAGE/validate_password";

export const GET_SHIFT = "MANAGE/get_shift";
export const SHOW_SHIFT = "MANAGE/show_shift";
export const CREATE_SHIFT = "MANAGE/create_shift";
export const UPDATE_SHIFT = "MANAGE/update_shift";
export const REMOVE_SHIFT = "MANAGE/remove_shift";

export const GET_ATTACHED_USERS = "MANAGE/get_attached_users";
export const ATTACH_USERS = "MANAGE/attach_users";
export const UPDATE_ACCTACH_USERS = "MANAGE/update_acctach_users";

export const GET_ATTACHED_USERS_BA = "MANAGE/get_attached_users_ba";

export const ASSIGN = "MANAGE/assign";

export const DOWNLOAD_BRANCH_HISTORY = "MANAGE/download_branch_history";
export const DOWNLOAD_SCHEDULE_HISTORY = "MANAGE/download_schedule_history";
export const FIELD_MAX_LIMIT = 50;
export const TRUNCATE_LIMIT = 25;

export const filter = {
  page: 1,
  q: "",
  business_style: "",
  is_blocked: "",
};

export const form = [
  {
    type: "select-static",
    name: "is_blocked",
    label: "Status:",
    options: [
      { value: 0, label: "ACTIVE" },
      { value: 1, label: "INACTIVE" },
    ],
    className: "col-12",
    isClearable: true,
    isSearchable: false,
  },
];
