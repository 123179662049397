import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { checkPermissions } from "helper";
import BranchIndex from "./containers/BranchIndex";
import AssignMins from "./containers/AssignMins";

function BranchOperations() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.url}/:branchId?`} component={BranchIndex} />
      {checkPermissions(["can-update-branch"]) && (
        <Route path={`${match.url}/:branchId?/:type?`} component={AssignMins} />
      )}
    </Switch>
  );
}

export default BranchOperations;
