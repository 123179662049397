import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { FilterSlide as Filter } from "components/Filter";
import Actions from "components/Actions";
import Pagination from "modules/common/components/Pagination";
import GenericTable from "modules/common/components/GenericTable";
import { ModalContext } from "App";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  checkPermissions,
  join,
  checkRoles,
  removeEmpty,
  removeNull,
} from "helper";
import FillupUserInfo from "../modalForms/FillupUserInfo";
import BatchCreate from "../modalForms/BatchCreate";
import UpdateFillupUserInfo from "../modalForms/UpdateFillupUserInfo";
import Assign from "../modalForms/Assign";
import BaFlStatusModal from "../modalForms/BaFlStatusModal";
import LogoutConfirm from "../modalForms/LogoutConfirm";
import { getRetailerUsers } from "../action";
import * as c from "../constant";

const dropdownButtonClass =
  "mt-0 btn btn-block btn-default py-1 rounded-0 text-right";

const selector = {
  usersList: ({ api }) => _.get(api, `${c.GET_USERS}.list`, []),
  isLoading: ({ api }) => api.loading.indexOf(c.GET_USERS) > -1,
  pager: ({ api }) => _.get(api, `${c.GET_USERS}.res.meta.pagination`),
  roles: ({ api }) => _.get(api, "AUTH/user_profile.item.roles"),
};

function BaAndFlUsers() {
  const setModal = useContext(ModalContext);
  const usersList = useSelector(selector.usersList);
  const isLoading = useSelector(selector.isLoading);
  const pager = useSelector(selector.pager);
  const roles = useSelector(selector.roles);

  const dispatch = useDispatch();

  const [filterData, setFilter] = useState(c.blFlInitState);

  const filter = useMemo(
    () =>
      removeNull({ ...filterData, is_active: filterData?.is_active?.value }),
    [filterData]
  );

  const handleChangePage = (page) => {
    const newFilter = { ...filter, page };
    setFilter((state) => ({ ...state, page }));
    dispatch(getRetailerUsers(newFilter));
  };

  const handleCreate = (e) => {
    e.preventDefault();
    setModal("fill-up-user-info", { setFilter });
  };

  const handleBatchCreate = (e) => {
    e.preventDefault();
    setModal("batch-create", { setFilter });
  };

  const handleUpdateUser = (item) => (e) => {
    e.preventDefault();
    setModal("update-fill-up-user-info", {
      userInfoData: {
        first_name: _.get(item, "attributes.first_name"),
        last_name: _.get(item, "attributes.last_name"),
        username: _.get(item, "attributes.username"),
        branch_id: _.get(item, "attributes.branch_ids") || [],
        schedule_id: _.get(item, "attributes.schedule_ids") || [],
        role_id: _.get(item, "attributes.role_id"),
        id: _.get(item, "id"),
      },
      terporaryData: {
        mobile: _.get(item, "attributes.mobile", ""),
        email: _.get(item, "attributes.email", ""),
      },
      filter,
    });
  };

  const handleStatus = (label, value, retailerId, returnLabel, userId) => (
    e
  ) => {
    e.preventDefault();
    const payload = {
      is_active: value,
    };
    setModal("ba-fl-update-status", {
      data: { label, retailerId, returnLabel, userId, payload },
      filter,
    });
  };

  const handleAssign = (item) => (e) => {
    e.preventDefault();
    setModal("assign", {
      id: item.id,
    });
  };

  const handleForceLogout = (item) => (e) => {
    const targetUser = _.get(item, "attributes");
    setModal("confirm-user-logout", {
      userName: _.get(targetUser, "username"),
      userId: _.get(targetUser, "user_id"),
    });
  };

  const renderActions = (item) => {
    if (checkPermissions(["can-update-retailer-user"])) {
      return (
        <Actions id={item.id}>
          <div>
            <button
              type="button"
              className={join`${dropdownButtonClass} border-bottom`}
              onClick={handleUpdateUser(item)}
            >
              <small>Update</small>
            </button>
            {_.get(item, "attributes.is_active") === true ? (
              <button
                type="button"
                className={join`${dropdownButtonClass} border-bottom`}
                onClick={handleStatus(
                  "Deactivate",
                  false,
                  _.get(item, "id"),
                  "Deactivated",
                  _.get(item, "attributes.user_id")
                )}
              >
                <small>Deactivate</small>
              </button>
            ) : (
              <button
                type="button"
                className={join`${dropdownButtonClass} border-bottom`}
                onClick={handleStatus(
                  "Activate",
                  true,
                  _.get(item, "id"),
                  "Activated",
                  _.get(item, "attributes.user_id")
                )}
              >
                <small>Activate</small>
              </button>
            )}
            <button
              type="button"
              className={join`${dropdownButtonClass} border-bottom`}
              onClick={handleAssign(item)}
            >
              <small>Assign</small>
            </button>
            <button
              type="button"
              className={dropdownButtonClass}
              onClick={handleForceLogout(item)}
            >
              <small>Force logout</small>
            </button>
          </div>
        </Actions>
      );
    }
    return null;
  };

  const form = useMemo(() => {
    const user_role = _.head(roles);
    return c.form.map((x) => {
      if (x.name === "role_slug")
        return {
          ...x,
          params: removeEmpty(c.getGuardName(user_role)),
        };
      return x;
    });
  }, [c.form, roles]);

  useEffect(() => {
    dispatch(getRetailerUsers(removeEmpty({ ...filter, page: 1 })));
  }, [filterData.q, filterData.is_active, filterData.role_slug]);

  return (
    <div className="main-content-inner">
      <Filter
        form={form}
        filterData={[filterData, setFilter]}
        loading={isLoading}
      />
      <div className="d-flex align-items-center mb-2 mt-2">
        <h1 className="h5 flex-grow-1 mt-0 mb-0">
          Manage User Accounts <br />
          {checkRoles(["branch-admin"]) ? (
            <small>List of Frontliner Users</small>
          ) : (
            <small>List of Branch Admins and Frontliner Users</small>
          )}
        </h1>
        {checkPermissions(["can-create-retailer-user"]) && (
          <Fragment>
            <button
              type="button"
              className="btn btn-success mr-1"
              onClick={handleCreate}
            >
              <i className="fas fa-plus mr-2" />
              Create Account
            </button>
            <button
              type="button"
              className="btn btn-success mr-1"
              onClick={handleBatchCreate}
            >
              <i className="fas fa-plus mr-2" />
              Batch Create
            </button>
          </Fragment>
        )}
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0 bg-white border">
            <GenericTable
              isLoading={isLoading}
              format={[
                {
                  key: (item) => (
                    <span>{_.get(item, "attributes.username") || "--"}</span>
                  ),
                  label: "Username",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>{_.get(item, "attributes.full_name") || "--"}</span>
                  ),
                  label: "Name",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span className="badge badge-success">
                      {_.get(item, "attributes.role_name") || "N/A"}
                    </span>
                  ),
                  label: "Role",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>
                      {moment(_.get(item, "attributes.created_at")).format(
                        "LLL"
                      ) || "--"}
                    </span>
                  ),
                  label: "Date Created",
                  width: "auto",
                },
                {
                  key: (item) =>
                    _.get(item, "attributes.is_active") ? (
                      <span className="text-success">Active</span>
                    ) : (
                      <span className="text-danger">Inactive</span>
                    ),
                  label: "Status",
                  width: "auto",
                },
                {
                  key: (item) => renderActions(item),
                  label: "Actions",
                  width: "80px",
                },
              ]}
              data={usersList}
              height="80vh"
            />
          </div>
        </div>
        <Pagination
          onChange={handleChangePage}
          data={pager}
          className="d-flex justify-content-between p-2 border-top border-muted"
          withPage
        />
      </div>
      <FillupUserInfo />
      <BatchCreate />
      <Assign />
      <BaFlStatusModal />
      <UpdateFillupUserInfo />
      <LogoutConfirm />
    </div>
  );
}

export default BaAndFlUsers;
