import React from 'react';
import ShiftSchedule from 'modules/widgets/shift-schedule';
import RetailerBalance from 'modules/widgets/wallet-balance/retailer';
import DealerBalance from 'modules/widgets/wallet-balance/dealer';
import TransactionReport from 'modules/widgets/transaction-report';
import TransactionHistory from 'modules/widgets/transaction-history';
import {
  AdsBanner,
  MainBanner,
  MiniBanner,
} from 'modules/widgets/advertisements';
import KUBalance from 'modules/widgets/wallet-balance/kapartner-user';
import { checkRoles } from 'helper';

const FrontlinerDash = () => {
  return (
    <div className="main-content-inner space">
      <div className="box-fit-wrapper h-100">
        <div className="box-fix-overflow">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-12">
                <div className="dashboard-wrapper">
                  <div className="wallet-balance">
                    {checkRoles(['kapartner-user']) ? (
                      <KUBalance />
                    ) : (
                      <>
                        <ShiftSchedule />
                        <div className="my-4">
                          <RetailerBalance />
                        </div>
                        <DealerBalance />
                      </>
                    )}
                  </div>
                  <AdsBanner />
                  <MainBanner />
                  <MiniBanner />
                  <div className="daily-transactions">
                    <TransactionReport />
                  </div>
                  <TransactionHistory />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrontlinerDash;
