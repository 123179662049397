import React, { useEffect } from 'react';
import GenericTable from 'modules/common/components/GenericTable';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../actions';
import * as c from '../constant';

const isLoading = false;
function OrderContainer({ getOrder, orderList }) {
  const history = useHistory();

  const handleNavigateProduct = e => {
    e.preventDefault();
    history.push('/purchase-order/product');
  };

  useEffect(() => {
    getOrder();
  }, [getOrder]);

  return (
    <div className="main-content-inner">
      <div className="d-flex align-items-center my-4">
        <h1 className="h5 flex-grow-1 mt-0 mb-0">Purchase Order</h1>
        {/* <div className="form-group my-2 mx-4">
					<div className="custom-control custom-checkbox">
						<input
							type="checkbox"
							className="custom-control-input"
							checked
							readOnly
						/>
						<label className="custom-control-label">Show for Approval Only</label>
					</div>
				</div> */}
        <button
          type="button"
          className="btn btn-success"
          onClick={handleNavigateProduct}
        >
          + Add Order
        </button>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0 bg-white border">
            <GenericTable
              isLoading={isLoading}
              format={[
                {
                  key: item => (
                    <span>{_.get(item, 'attributes.ref_id') || '--'}</span>
                  ),
                  label: 'Ref No',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span>{_.get(item, 'attributes.po_number') || '--'}</span>
                  ),
                  label: 'PO Number',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span>
                      {_.get(item, 'attributes.payment_type') || '--'}
                    </span>
                  ),
                  label: 'Sales Type',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span>{_.get(item, 'attributes.total') || '--'}</span>
                  ),
                  label: 'Total Order',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span>
                      {_.get(item, 'attributes.payment_date') || '--'}
                    </span>
                  ),
                  label: 'Payment Data',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span className="font-weight-bold">
                      {_.get(item, 'attributes.status') || '--'}
                    </span>
                  ),
                  label: 'State',
                  width: 'auto',
                },
              ]}
              data={orderList}
              height="80vh"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

OrderContainer.propTypes = {
  getOrder: PropTypes.func.isRequired,
  orderList: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api }) => ({
  orderList: _.get(api, `${c.GET_ORDER}.list`) || [],
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(OrderContainer);
