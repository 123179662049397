import React, { useState, useContext } from 'react';
import { ModalContext } from 'App';
import { useLoading } from 'modules/ui/hooks';
import { useDispatch } from 'react-redux';
import ReactInputMask from 'react-input-mask';
import withModal from 'modules/common/hoc/withModal';
import ButtonAsync from 'components/Button';
import * as c from '../constants';
import * as actions from '../actions';

const VerifyMin = () => {
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);
  const [min, setMin] = useState('');
  const isVerifying = useLoading(c.VERIFY_RETAILER_MIN);
  const close = () => setModal({ isOpen: false });

  const handleSubmit = e => {
    e.preventDefault();

    const successCallback = res => {
      setModal('add-retailer', {
        data: {
          min,
          ...res?.data,
        },
      });
    };

    dispatch(
      actions.verifyRetailerMin(
        { mobile_identification_number: min },
        successCallback,
        close
      )
    );
  };

  return (
    <div className="add-modal-container p-3">
      <form onSubmit={handleSubmit}>
        <div className="row mb-4">
          <div className="col">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Retailer Mobile No. *
            </label>

            <ReactInputMask
              type="text"
              required
              mask="+639999999999"
              maskChar=""
              className="form-control form-custom form-control_bottom px-3"
              name="mobile_identification_number"
              value={min}
              onChange={e => setMin(e.target.value)}
              placeholder="Enter..."
              style={c.inputStyles}
            />
          </div>
        </div>
        <div className="button-container d-flex justify-content-end">
          <ButtonAsync
            className="btn btn-primary position-relative py-2 px-4 mr-2"
            type="submit"
            loading={isVerifying}
            disabled={min.length < 13}
          >
            Verify
          </ButtonAsync>

          <button
            type="button"
            className="btn bg-gray-300 text-white py-2 px-4"
            onClick={close}
            disabled={isVerifying}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

const modalKey = 'verify-retailer-min';
const modalConfig = {
  title: 'Verify Retailer MIN',
  size: 'modal-md',
};

export default withModal(modalKey, modalConfig)(VerifyMin);
