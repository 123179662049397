import React from 'react';
import bgShape from 'assets/images/page-background.svg';

const styles = {
  backgroundImage: `url(${bgShape})`,
  width: '100vw',
  height: '100vh',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '32%',
  backgroundPosition: 'bottom left',
  position: 'absolute',
  top: 0,
  left: 0,
};

export default () => (
  <React.Fragment>
    <div style={styles} />
    <div style={{ ...styles, transform: 'scale(-1)' }} />
  </React.Fragment>
);
