import React, { Fragment, useContext, useMemo } from "react";
import _ from "lodash";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { useInterval } from "react-use";
import Panel from "components/Panel";
import PanelRow from "components/PanelRow";
import PanelButton from "components/PanelButton";
import * as Icon from "components/Icons";
import DataField from "components/DataField";
import { ModalContext } from "App";
import Actions from "components/Actions";
import ConfirmAlert from "modules/common/components/ConfirmAlert";
import {
  ToastError,
  ToastSuccess,
  ToastWarn,
} from "modules/common/components/Toast";
import ErrorMessage from "modules/common/components/ErrorMessage";
import { useRootPath } from "hooks";
import AgentRetailerSetup from "./AgentRetailerSetup";
import AgentRetailerTransferSetup from "./AgentRetailerTransferSetup";
import {
  getAgentMins,
  getAgentRetailers,
  deleteAgentRetailer,
  setAgentRetailers,
} from "../actions";

const refetchDelay = 3e3;

const AddIcon = () => <Icon.Add fill="#fff" />;

const AgentRetailerPanel = () => {
  const setModal = useContext(ModalContext);
  const urlParams = useParams();
  const rootPath = useRootPath();
  const history = useHistory();
  const queryClient = useQueryClient();
  const agentId = _.get(urlParams, "agentId");

  const agentMinQuery = useQuery({
    queryKey: ["agent-mins", { agentId }],
    queryFn: getAgentMins,
    retry: false,
    keepPreviousData: true,
  });

  const simId = agentMinQuery?.data?.data?.id;

  const agentRetailers = useQuery({
    queryKey: ["agent-retailers", simId, {}],
    queryFn: getAgentRetailers,
    enabled: !!simId,
  });

  const isRetailerInProgress = useMemo(
    () =>
      !!agentRetailers?.data?.data?.some((item) =>
        item?.attributes?.evc_status?.includes("in-progress")
      ),
    [agentRetailers]
  );

  useInterval(
    agentRetailers.refetch,
    isRetailerInProgress ? refetchDelay : null
  );

  const { mutate: removeRetailer, isFetching: isRemoving } = useMutation(
    deleteAgentRetailer,
    {
      onSuccess: () => {
        ToastWarn("Retailer removal is now in progress.");
        queryClient.invalidateQueries("agent-retailers");
      },
      onError: (err) => {
        const errorMessage = (
          <ErrorMessage
            error={err}
            defaultMessage="Retailer evc status is in-progress."
          />
        );
        ToastError(errorMessage);
        setModal({ isOpen: false });
      },
    }
  );

  const retailersList = _.filter(
    _.get(agentRetailers, "data.data"),
    (item) => item?.attributes?.evc_status !== "delink-done"
  );

  const handleAddRetailer = () => {
    history.push(_.join([rootPath, agentId, "assignment"], "/"));
  };

  const handleTransferRetailer = () => {
    history.push(_.join([rootPath, agentId, "transfer"], "/"));
  };

  const handleRemoveRetailer = (retailerId) => {
    const onConfirm = () => removeRetailer({ simId, retailerId });
    const onReject = () => {};
    const message = "This will remove the retailer from the agent.";
    const yesLabel = "Yes";
    const noLabel = "Cancel";
    const header = "Remove";
    const newButtons = true;
    const center = false;
    const sub_text = "Are you sure you want to proceed?";

    ConfirmAlert(message, onConfirm, onReject, {
      yesLabel,
      noLabel,
      header,
      newButtons,
      center,
      sub_text,
    });
  };

  const { mutate: addRetailers } = useMutation(setAgentRetailers, {
    onSuccess: () => {
      queryClient.invalidateQueries("agent-retailers");
      ToastSuccess("Successfully assigned retailer to agent");
      setModal({ isOpen: false });
    },
    onError: (err) => {
      const errorMessage = err?.response?.data?.errors?.map((msg) => (
        <div>{msg.detail}</div>
      )) || (
        <>
          <small>Failed assigning retailer to agent.</small>
          <br />
          <small>
            <em>{err?.message}</em>
          </small>
        </>
      );
      ToastError(errorMessage);
      setModal({ isOpen: false });
    },
  });

  const handleRetryAssign = (retailer) => {
    addRetailers({ simId, retailerId: retailer?.id });
    setModal({ isOpen: false });
  };

  const sortedRetailersList = retailersList?.sort((a, b) =>
    b?.attributes?.created_at?.localeCompare(a?.attributes?.created_at)
  );

  return (
    <Panel
      title="Retailers"
      renderActions={() => (
        <div className="btn-group">
          {_.isEmpty(retailersList) ? null : (
            <PanelButton
              label="Transfer"
              onClick={handleTransferRetailer}
              className="btn bg-gray-400 text-white text-uppercase rounded-0 outline-none d-flex align-items-center px-3"
            />
          )}
          {_.isEmpty(simId) ? null : (
            <PanelButton
              iconLeft={AddIcon}
              label="Assign"
              onClick={handleAddRetailer}
            />
          )}
        </div>
      )}
    >
      {_.isEmpty(retailersList) ? (
        <PanelRow>
          <div className="col text-center text-gray-400 text-xs">
            {_.isEmpty(simId) ? (
              <span>No MIN assigned to this agent yet.</span>
            ) : (
              <span>No assigned retailers yet.</span>
            )}
          </div>
        </PanelRow>
      ) : (
        <Fragment>
          {_.map(sortedRetailersList, (retailer) => {
            const id = _.get(retailer, "id");
            const status = retailer?.attributes?.evc_status || "";
            const hide_retry = !(
              status.includes("done") || status.includes("in-progress")
            );
            return (
              <PanelRow key={id} style={{ opacity: isRemoving ? 0.25 : 1 }}>
                <DataField
                  label="Retailer Type"
                  value={_.get(retailer, "attributes.retailer_type")}
                  className="col-md-3 mb-2 mb-md-0 order-1"
                />
                <DataField
                  label="Retailer Name"
                  value={_.get(retailer, "attributes.full_name")}
                  className="col-md-3 mb-2 mb-md-0 order-1"
                />
                <DataField
                  label="Retailer MIN"
                  value={
                    retailer?.attributes?.mobile_identification_number || ""
                  }
                  className="col mb-2 mb-md-0 order-1"
                />
                <DataField
                  label="EVC Status"
                  value={_.capitalize(
                    _.get(retailer, "attributes.evc_status").replaceAll(
                      "-",
                      " "
                    )
                  )}
                  className="col-md-2 mb-2 mb-md-0 order-1"
                />
                <div className="col d-flex align-items-center justify-content-end order-md-1">
                  <Actions label="Actions" id={id}>
                    <button
                      type="button"
                      className="btn btn-sm text-gray-600 w-100 text-left"
                      onClick={() => handleRemoveRetailer(id)}
                    >
                      Remove
                    </button>
                    {hide_retry && (
                      <button
                        type="button"
                        className="btn btn-sm text-gray-600 w-100 text-left"
                        onClick={() => handleRetryAssign(retailer)}
                      >
                        Retry
                      </button>
                    )}
                  </Actions>
                </div>
              </PanelRow>
            );
          })}
        </Fragment>
      )}
      <AgentRetailerSetup />
      <AgentRetailerTransferSetup />
    </Panel>
  );
};

export default AgentRetailerPanel;
