import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useRootPath } from "hooks";
import SearchField from "components/SearchField";
import ListAsync from "components/ListAsync";
import { getRetailers } from "app/load-wallet/actions";
import RetailerListItem from "./RetailerListItem";

const Listing = () => {
  const history = useHistory();
  const rootPath = useRootPath();
  const urlParams = useParams();

  const [query, setQuery] = useState("");
  const { isFetching, isLoading, data, error, refetch } = useQuery({
    queryKey: ["retailers-listing", { q: query }],
    queryFn: getRetailers,
    retry: false,
    keepPreviousData: true,
  });

  const handleItemSelection = (item) => {
    refetch();
    history.push(`${rootPath}/${item.id}`);
  };

  useEffect(() => {
    history.push(rootPath);
  }, [query, history, rootPath]);

  const retailerCount = data?.data?.data.length;

  return (
    <div className="d-flex flex-column h-100 border-right border-gray-200">
      <div className="flex-grow-0">
        <div className="px-3 my-4">
          <SearchField
            isLoading={isFetching}
            onChange={(value) => setQuery(value)}
          />
        </div>
      </div>
      <div className="flex-grow-1">
        <div className="box-fit-wrapper h-100">
          <div className="box-fit-overflow">
            <p className="m-0 px-3 py-2 text-sm text-gray-400">
              List of Retailers {retailerCount ? `(${retailerCount})` : ""}
            </p>
            <ListAsync
              isLoading={isLoading}
              refetch={refetch}
              error={error}
              data={data?.data?.data}
              renderItems={(item) => (
                <RetailerListItem
                  key={item.id}
                  isActive={_.get(urlParams, "retailerId") === item.id}
                  onClick={handleItemSelection}
                  data={item}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listing;
