import React from 'react';

function HelpSupport() {
  return (
    <div className="box-fit-wrapper h-100">
      <div className="box-fix-overflow">
        <div className="container-fluid">
          <div className="pl-5 pt-4">
            <h3 className="font-weight-bold text-primary">Support Hotlines</h3>
            <div className="mb-4">
              <p className="text-muted">
                For system related concerns, please call your Smart area sales
                representative.
              </p>
            </div>
            <div className="mb-4">
              <p className="font-weight-bold text-primary mb-3">
                For load concerns, contact:
              </p>
              <p className="text-muted mb-2">
                #7744
                <span className="ml-1 text-primary"> (E-load Dealer)</span>
              </p>
              <p className="text-muted mb-4">
                *8455
                <span className="ml-1 text-primary"> (Key Accounts)</span>
              </p>
              <p className="text-muted mb-2">
                8-845-7744
                <span className="ml-1 text-primary"> (E-load Dealer)</span>
              </p>
              <p className="text-muted mb-4">
                8-845-7789
                <span className="ml-1 text-primary"> (Key Accounts)</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpSupport;
