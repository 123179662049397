import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import withModal from "modules/common/hoc/withModal";
import { ModalContext } from "App";
import { ToastSuccess, ToastError } from "modules/common/components/Toast";
import ReactInputMask from "react-input-mask";
import ButtonAsync from "components/Button";
import * as actions from "../actions";
import * as c from "../constant";
import * as authActions from "../../auth/actions";

function SetPin({
  data,
  sendLoad,
  setBalance,
  inquireBalance,
  isLoading,
  branch_id,
}) {
  const setModal = React.useContext(ModalContext);
  const [state, setState] = React.useState({});
  const [pending, setPending] = useState(false);

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
    setModal("promo", { data, setBalance, branch_id });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);
    const min = _.get(data, "retailer_sim");
    const payloadMin = min.replace("+63", "0");
    const inquirePayload = { mobile: min.replace("+63", "0") };
    const mpinPayload = {
      grant_type: "mpin",
      mobile_identification_number: _.get(
        data,
        "newRetailer.attributes.mobile_identification_number"
      ),
      mpin: _.get(state, "mpin"),
    };

    const nonRTPayload =
      data.profileType !== "retailers"
        ? {
            actual_amount:
              _.get(data, "amount") || _.get(data, "attributes.amount"),
            branch_id,
          }
        : {};

    const payload = {
      ...nonRTPayload,
      target: _.get(data, "mobile_number"),
      source: payloadMin,
      keyword: _.get(data, "keyword"),
      provider_id: _.get(data, "provider_id"),
      category_id: _.get(data, "category_id"),
      from_mobile: false,
    };

    sendLoad(
      { ...mpinPayload, ...payload },
      (res) => {
        const response_code = _.get(res, "data.response_code");
        if (response_code === "0") {
          ToastSuccess(`${_.get(res, "data.message")}`);
          setModal({ isOpen: false });
          setPending(false);
        } else {
          ToastError(
            `An error has occurred while processing this request. Response Code ${response_code}`
          );
          setPending(false);
        }

        inquireBalance(
          inquirePayload,
          (inquireRes) => {
            setBalance(_.get(inquireRes, "data.available_balance"));
            setPending(false);
          },
          () => setPending(false)
        );
      },
      () => setPending(false)
    );
  };

  const handleChangeInput = (key) => ({ target }) => {
    setState({
      ...state,
      [key]: target.value,
    });
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <label className="form-label text-primary text-center">
            Enter your 6-Digit PIN
          </label>
          <ReactInputMask
            type="password"
            placeholder="6-Digit PIN"
            mask="999999"
            maskChar=""
            className="form-control form-custom form-control_bottom"
            name="mpin"
            required
            onChange={handleChangeInput("mpin")}
            value={_.get(state, "mpin") || ""}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <ButtonAsync type="submit" loading={pending}>
              Submit
            </ButtonAsync>
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold ml-2"
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

SetPin.propTypes = {
  sendLoad: PropTypes.func.isRequired,
  inquireBalance: PropTypes.func.isRequired,
  setBalance: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  branch_id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ api, modal }) => ({
  data: _.get(modal, "modalData.data"),
  setBalance: _.get(modal, "modalData.setBalance"),
  branch_id: _.get(modal, "modalData.branch_id", ""),
  isLoading:
    api.loading.indexOf(c.MPIN) > -1 || api.loading.indexOf(c.SEND_LOAD) > -1,
});

const enhance = _.flowRight([
  withModal("set-pin", {
    title: "Enter your 6-Digit Security PIN",
    size: "modal-sm",
  }),
  withRouter,
  connect(mapStateToProps, {
    ...actions,
    ...authActions,
  }),
]);

export default enhance(SetPin);
