import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { passwordStrength } from 'helper';
import Button from 'components/Button';
import { ToastError, ToastSuccess } from 'modules/common/components/Toast';
import * as action from '../action';

const ChangePasswordForm = ({ otp, username }) => {
  const [form, setForm] = useState({
    new_password: '',
    new_password_confirmation: '',
  });
  const [visibleFields, setVisibleFields] = useState({
    new_password: false,
    new_password_confirmation: false,
  });
  const [isPending, setIsPending] = useState(false);

  const dispatch = useDispatch();

  const history = useHistory();

  const {
    isAlphaNumeric,
    hasLowerCase,
    hasUpperCase,
    hasSpecialCharacter,
    isLongEnough,
    isValid: isPasswordValid,
  } = passwordStrength(form.new_password);

  const handleInputChange = event => {
    const {
      target: { name, value },
    } = event;
    setForm(prevForm => ({ ...prevForm, [name]: value }));
  };

  const onChangeVisibility = key => () => {
    setVisibleFields(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const checkFieldType = isVisible => (isVisible ? 'text' : 'password');
  const checkIconType = isVisible => (isVisible ? 'fa-eye-slash' : 'fa-eye');

  const onSubmit = e => {
    e.preventDefault();

    const { new_password, new_password_confirmation } = form;
    const newPwDoesNotMatch = new_password !== new_password_confirmation;
    const isInvalidPwStr =
      [isAlphaNumeric, hasLowerCase, hasUpperCase, hasSpecialCharacter].filter(
        check => check
      ).length < 3;

    if (newPwDoesNotMatch) {
      return ToastError('New password and confirm password did not match.');
    }
    if (!isLongEnough) {
      return ToastError('Password needs to be 8 or more characters.');
    }
    if (isInvalidPwStr) {
      return ToastError(
        'Password should contain characters from three out of four categories'
      );
    }

    setIsPending(true);

    const payload = {
      otp,
      username,
      new_password: form.new_password,
      new_password_confirmation: form.new_password_confirmation,
    };

    const successCallback = () => {
      setIsPending(false);
      ToastSuccess('Password successfully changed');
      history.push('/');
    };

    const errorCallback = () => {
      setIsPending(false);
    };

    dispatch(action.updatePassword(payload, successCallback, errorCallback));
    return false;
  };

  return (
    <div style={{ maxWidth: 450 }} className="w-100 mx-auto">
      <h4 className="font-weight-bold text-center m-0 mb-4">
        Nominate New Password
      </h4>
      <p className="text-center m-0">
        Create a strong password to secure account. Please nominate a password
        that is different from your last five (5) passwords.
      </p>

      <form onSubmit={onSubmit} className="change-pw-form">
        <div className="form-group mt-4">
          <div className="prepend-form-right">
            <label className="form-label text-primary">New Password:</label>
            <input
              className="form-control form-custom form-control_bottom"
              type={checkFieldType(visibleFields.new_password)}
              placeholder="New Password"
              required
              name="new_password"
              onChange={handleInputChange}
              value={form.new_password}
            />
            <button
              type="button"
              onClick={onChangeVisibility('new_password')}
              className="btn btn-default rounded-circle p-0 mr-1"
            >
              <i
                className={`fas ${checkIconType(visibleFields.new_password)}`}
              />
            </button>
          </div>
        </div>

        <div className="form-group">
          <div className="prepend-form-right">
            <label className="form-label text-primary">Confirm Password:</label>
            <input
              className="form-control form-custom form-control_bottom"
              type={checkFieldType(visibleFields.new_password_confirmation)}
              required
              placeholder="New Password"
              name="new_password_confirmation"
              onChange={handleInputChange}
              value={form.new_password_confirmation}
            />
            <button
              type="button"
              onClick={onChangeVisibility('new_password_confirmation')}
              className="btn btn-default rounded-circle p-0 mr-1"
            >
              <i
                className={`fas ${checkIconType(
                  visibleFields.new_password_confirmation
                )}`}
              />
            </button>
          </div>
        </div>

        <p>
          <small>
            Password length should be minimum eight(8) characters and should
            contain characters from three out of four categories
          </small>
        </p>

        <div className="form-inline mb-4">
          <div className="form-group mr-2">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                checked={isAlphaNumeric}
                readOnly
              />
              <label className="custom-control-label" htmlFor="passwordAlpha">
                Alphanumeric
              </label>
            </div>
          </div>
          <div className="form-group mr-2">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                checked={hasUpperCase}
                readOnly
              />
              <label
                className="custom-control-label"
                htmlFor="passwordUppercase"
              >
                Uppercase Letter
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                readOnly
                checked={hasLowerCase}
              />
              <label
                className="custom-control-label"
                htmlFor="passwordLowercase"
              >
                Lowercase Letter
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                readOnly
                checked={hasSpecialCharacter}
              />
              <label
                className="custom-control-label"
                htmlFor="passwordSpecialChar"
              >
                Special Characters
              </label>
            </div>
          </div>
        </div>

        <div className="form-group mt-4">
          <Button
            type="submit"
            className="btn btn-block btn-primary px-4"
            loading={isPending}
            disabled={!isPasswordValid}
          >
            Change Password
          </Button>
        </div>
      </form>
    </div>
  );
};

ChangePasswordForm.propTypes = {
  otp: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default ChangePasswordForm;
