import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import ReactInputMask from "react-input-mask";
import { ModalContext } from "App";
import { removeEmpty } from "helper";
import withModal from "modules/common/hoc/withModal";
import ButtonAsync from "components/Button";
import WithTimerButton from "components/WithTimerButton";
import { ToastSuccess } from "modules/common/components/Toast";
import {
  getDealerSimsAll,
  removeDealerSims,
} from "modules/LoadWalletManagement/actions";
import { useDealerSim } from "app/load-wallet/dealer-sims/context/DealerSimProvider";
import { MESSAGES_SIM_ACTION as messages } from "modules/LoadWalletManagement/constant";
import * as actions from "../actions";

function ConfirmOtp() {
  const dispatch = useDispatch();
  const { simDealerId } = useDealerSim();
  const initialData = useSelector(({ modal }) => _.get(modal, "modalData"));
  const [otpValue, setOtpValue] = useState("");
  const setModal = React.useContext(ModalContext);
  const [pending, setPending] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);
    const sameMinValue = _.get(initialData, "mobile_identification_number")
      ? ""
      : _.get(initialData, "old_min");

    const createPayload = {
      ...initialData,
      mobile_identification_number: sameMinValue,
      ..._.omit(initialData, ["tempSubmitType"]),
      otp: otpValue,
    };

    const onAddSuccess = () => {
      const submitType = initialData?.attributes?.tempSubmitType || "default";
      ToastSuccess(messages[submitType]);

      setModal({ isOpen: false });
      setPending(false);
      const simPayload = {
        dealer_id: simDealerId,
        order_by: "created_at",
        page: 1,
        per_page: 10,
        sort: "desc",
        userType: "dealers",
      };

      dispatch(getDealerSimsAll({ ...simPayload, per_page: 9999 }));
      dispatch(actions.getDealerSims(simPayload));
    };

    const onAddError = () => {
      setPending(false);
      setOtpValue("");
    };

    const onConfirmSuccess = () => {
      const submitTypeValue = _.get(initialData, "tempSubmitType", "");
      if (submitTypeValue?.includes("block"))
        return dispatch(
          actions.updateDealerSims(
            _.get(initialData, "id"),
            removeEmpty({ ...createPayload, mobile_identification_number: "" }),
            onAddSuccess,
            onAddError
          )
        );
      if (submitTypeValue === "update")
        return dispatch(
          actions.updateDealerSims(
            _.get(initialData, "id", ""),
            removeEmpty(createPayload),
            onAddSuccess,
            onAddError
          )
        );
      if (submitTypeValue === "delete")
        return dispatch(
          removeDealerSims(
            _.get(initialData, "id", ""),
            onAddSuccess,
            onAddError
          )
        );

      return dispatch(
        actions.createDealerSims(createPayload, onAddSuccess, onAddError)
      );
    };

    const onConfirmError = () => setPending(false);

    const onConfirmPayload = {
      mobile:
        _.get(initialData, "mobile_identification_number") ||
        _.get(initialData, "old_min"),

      purpose: "portal-verification",
      otp: otpValue,
    };

    dispatch(
      actions.confirmOtp(onConfirmPayload, onConfirmSuccess, onConfirmError)
    );
  };

  const isInputEmpty = useMemo(() => otpValue.length === 6, [otpValue]);

  const handleResend = (e) => {
    e.preventDefault();
    const payload = {
      mobile:
        _.get(initialData, "mobile_identification_number") ||
        _.get(initialData, "old_min"),
      purpose: "portal-verification",
      type: "sms",
    };
    dispatch(actions.setOtp(payload));
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="prepend-form-right">
            <label className="form-label text-primary text-center">
              Enter the one-time-password we have sent to your mobile number
              +63********{initialData?.sms?.detail?.mobile_end}
            </label>
            <ReactInputMask
              autoFocus
              type="text"
              placeholder="6 Digit OTP"
              mask="999999"
              maskChar=""
              className="form-control form-custom form-control_bottom"
              name="otp"
              required
              onChange={(e) => setOtpValue(e.target.value)}
              value={otpValue || ""}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <WithTimerButton
              type="button"
              duration={0.5}
              handleClick={handleResend}
            >
              Resend OTP
            </WithTimerButton>
            <ButtonAsync
              type="submit"
              className="btn btn-primary position-relative ml-1"
              loading={pending}
              disabled={!isInputEmpty}
            >
              Confirm OTP
            </ButtonAsync>
          </div>
        </div>
      </div>
    </form>
  );
}

const modalKey = "dealer-confirm-otp";
const modalConfig = {
  title: "Enter your 6-Digit OTP",
  size: "modal-md",
};

export default withModal(modalKey, modalConfig)(ConfirmOtp);
