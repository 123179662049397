import * as req from "api/actions";
import { serialize } from "helper";
import * as c from "./constant";

export const createUsers = (payload, callback) =>
  req.create(c.CREATE_USERS, "api/v1/retailer-users", payload, callback);

export const getRetailerUsers = (payload, callback) =>
  req.list(c.GET_USERS, "api/v1/retailer-users", payload, callback);

export const getUsersbyId = (id, payload, callback) =>
  req.item(c.GET_USER_BY_ID, `api/v1/retailer-users/${id}`, payload, callback);

export const updateRetailer = (id, payload, callback, errCallback) =>
  req.update(
    c.UPDATE_USERS,
    `api/v1/retailer-users/${id}`,
    payload,
    callback,
    {},
    errCallback,
  );

export const getBranch = (payload, callback) =>
  req.list(
    c.GET_BRANCH,
    "api/v1/branches",
    { ...payload, include: null },
    callback,
  );

export const getShift = (payload, callback) =>
  req.list(c.GET_SHIFT, "api/v1/schedules", payload, callback);

export const assign = (id, payload, callback, errCallback) =>
  req.create(
    c.ASSIGN,
    `api/v1/retailer/retailer_users/${id}/branches`,
    payload,
    callback,
    {},
    errCallback,
  );

export const detach = (id, branchId, payload, callback) =>
  req.remove(
    c.DETACH,
    `api/v1/retailer/retailer_users/${id}/branches/${branchId}`,
    payload,
    callback,
  );

export const getAssignedBranchShift = (id, payload, callback) =>
  req.list(
    c.GET_ASSIGNED_BRANCH_SHIFT,
    `api/v1/retailer/retailer_users/${id}/branches`,
    payload,
    callback,
  );

export const getRoles = (payload, callback) =>
  req.list(c.GET_ROLES, "api/v1/acl/kapartner-web/roles", payload, callback);

export const getTabRoles = (payload, callback) =>
  req.list(
    c.GET_TAB_ROLES,
    "api/v1/acl/kapartner-web/roles",
    payload,
    callback,
  );

export const createBatch = (payload, callback) =>
  req.create(c.CREATE_BATCH, "api/v1/retailer-users/import", payload, callback);

export const createBatchDealerUser = (payload, callback, errCallback) =>
  req.create(
    c.CREATE_BATCH_DU,
    "api/v1/dealer-users/import",
    payload,
    callback,
    {},
    errCallback,
  );

export const downloadFormat = (payload, params, callback) =>
  req.download(
    c.DOWNLOAD_FORMAT,
    `api/v1/retailer-users/download/import-format?${serialize(params)}`,
    payload,
    callback,
  );

export const getFailedImports = (params) =>
  req.list(c.GET_FAILED_IMPORTS, `external/api/v1/failed-imports`, params);

export const downloadImportFormatDealerUser = (payload, params, callback) =>
  req.download(
    c.DOWNLOAD_IMPORT_FORMAT_DU,
    `api/v1/dealer-users/download/import-format?${serialize(params)}`,
    payload,
    callback,
  );

export const getCorpUsers = (payload, callback) =>
  req.list(c.GET_CORP_ADMIN, "api/v1/dealer-users", payload, callback);

export const createCorpUsers = (payload, callback) =>
  req.create(c.CREATE_CORP_ADMIN, "api/v1/dealer-users", payload, callback);

export const updateCorpAdmin = (id, payload, callback, errCallback) =>
  req.update(
    c.UPDATE_CORP_ADMIN,
    `api/v1/dealer-users/${id}`,
    payload,
    callback,
    {},
    errCallback,
  );

export const validatePassword = (payload, callback, errCallback) =>
  req.create(
    c.VALIDATE_PASSWORD,
    "api/v1/external/users/my/validate-password",
    payload,
    callback,
    {},
    errCallback,
  );

export const logoutUser = (userId, callback, errCallback) =>
  req.create(
    c.LOGOUT_USER,
    `api/v1/external/users/${userId}/logout`,
    null,
    callback,
    {},
    errCallback,
  );
