import React from "react";
import GenericTable from "modules/common/components/GenericTable";
import Pagination from "modules/common/components/Pagination";
import _ from "lodash";
import Actions from "components/Actions";
import { USER_PROFILE } from "modules/auth/constants";
import { join } from "helper";
import { ModalContext } from "App";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import UpdateFillupUserInfo from "modules/UserAccountManagement/modalForms/UpdateFillupUserInfo";
import Assign from "modules/UserAccountManagement/modalForms/Assign";
import * as actions from "../action";
import * as c from "../constant";
import AssignBranchAdmin from "../modalForms/AssignBranchAdmin";
import BranchAdminStatusModal from "../modalForms/BranchAdminStatusModal";

const filter = {
  q: "",
  page: 1,
};

const dropdownButtonClass =
  "mt-0 btn btn-block btn-default py-1 rounded-0 text-right";

function BranchAdmin({
  getAttachUsersBA,
  pager,
  isLoading,
  attachedUserList,
  branchId,
  branchDetails,
}) {
  const setModal = React.useContext(ModalContext);
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const isNonMigrated = profile?.roles[0]?.includes("non-migrated");
  const handleStatus = (label, value, retailerId, name, callBackLabel) => (
    e
  ) => {
    e.preventDefault();
    const payload = {
      is_active: value,
    };
    setModal("branch-admin-update-status", {
      data: {
        label,
        value,
        retailerId,
        payload,
        name,
        callBackLabel,
        branchId,
      },
    });
  };
  const handleUpdateUser = (item) => (e) => {
    e.preventDefault();
    setModal("update-fill-up-user-info", {
      userInfoData: {
        first_name: _.get(item, "attributes.first_name"),
        last_name: _.get(item, "attributes.last_name"),
        username: _.get(item, "attributes.username"),
        branch_id: _.get(item, "attributes.branch_id"),
        schedule_id: _.get(item, "attributes.schedule_id"),
        role_id: _.get(item, "attributes.role_id"),
        id: _.get(item, "id"),
        type: "fu",
      },
      terporaryData: {
        email: _.get(item, "attributes.email"),
        mobile: _.get(item, "attributes.mobile"),
      },
      type: "load-wallet",
      branchId,
    });
  };

  const handleAssign = (item) => (e) => {
    e.preventDefault();
    setModal("assign", {
      id: item.id,
      branchId,
    });
  };

  const renderActions = (item) => {
    return (
      <Actions id={item.id}>
        <React.Fragment>
          <button
            type="button"
            className={join`${dropdownButtonClass} border-bottom`}
            onClick={handleUpdateUser(item)}
          >
            <small>Update</small>
          </button>
          {_.get(item, "attributes.is_active") === true ? (
            <button
              type="button"
              className={join`${dropdownButtonClass} border-bottom`}
              onClick={handleStatus(
                "Deactivate",
                false,
                _.get(item, "id"),
                _.get(item, "attributes.username"),
                "Deactivated"
              )}
            >
              <small>Deactivate</small>
            </button>
          ) : (
            <button
              type="button"
              className={join`${dropdownButtonClass} border-bottom`}
              onClick={handleStatus(
                "Activate",
                true,
                _.get(item, "id"),
                _.get(item, "attributes.username"),
                "Activated"
              )}
            >
              <small>Activate</small>
            </button>
          )}
          <button
            type="button"
            className={join`${dropdownButtonClass} border-bottom`}
            onClick={handleAssign(item)}
          >
            <small>Manage</small>
          </button>
        </React.Fragment>
      </Actions>
    );
  };

  const handleChangePage = () => {
    const newFilter = {
      page: pager.current_page + 1,
      ...filter,
      role_slug: isNonMigrated ? "branch-admin-non-migrated" : "branch-admin",
    };
    getAttachUsersBA(branchId, newFilter);
  };

  const handleCreateBA = (e) => {
    e.preventDefault();
    setModal("create-branchAdmin", {
      branchId,
      branch_name: _.get(branchDetails, "attributes.name", ""),
    });
  };

  const renderStatus = (status) => {
    if (status === true) {
      return <span className="text-success">Active</span>;
    }
    return <span className="text-danger">Inactive</span>;
  };

  React.useEffect(() => {
    if (!_.isEmpty(branchId)) {
      getAttachUsersBA(branchId, {
        role_slug: isNonMigrated ? "branch-admin-non-migrated" : "branch-admin",
      });
    }
  }, [branchId, getAttachUsersBA]);

  return (
    <div>
      <div className="d-flex align-items-center mb-2 mt-3">
        <h1 className="h5 flex-grow-1 mt-0 mb-0 font-weight-bold">
          List of Assigned Branch Admins
        </h1>
        {_.get(branchDetails, "attributes.is_blocked") === false && (
          <button
            type="button"
            className="btn btn-success mr-1"
            onClick={handleCreateBA}
          >
            <i className="fas fa-plus mr-2" />
            Assign Branch Admin
          </button>
        )}
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <GenericTable
              isLoading={isLoading}
              format={[
                {
                  key: (item) => (
                    <span>{_.get(item, "attributes.username") || "--"}</span>
                  ),
                  label: "Username",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>{_.get(item, "attributes.full_name") || "--"}</span>
                  ),
                  label: "Name",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span> {_.get(item, "attributes.email") || "--"} </span>
                  ),
                  label: "Email Address",
                  width: "auto",
                },
                {
                  key: (item) =>
                    renderStatus(_.get(item, "attributes.is_active")),
                  label: "Status",
                  width: "auto",
                },
                {
                  key: (item) => renderActions(item),
                  label: "Actions",
                  width: "80px",
                },
              ]}
              data={attachedUserList}
              height="100vh"
            />
            <Pagination
              onChange={handleChangePage}
              data={pager}
              className="d-flex justify-content-end my-3 mr-2"
            />
          </div>
        </div>
      </div>
      <Assign />
      <AssignBranchAdmin />
      <UpdateFillupUserInfo />
      <BranchAdminStatusModal />
    </div>
  );
}

BranchAdmin.propTypes = {
  getAttachUsersBA: PropTypes.func.isRequired,
  attachedUserList: PropTypes.instanceOf(Array).isRequired,
  pager: PropTypes.instanceOf(Object).isRequired,
  branchDetails: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  branchId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ api }) => ({
  attachedUserList: _.get(api, `${c.GET_ATTACHED_USERS_BA}.list`) || [],
  pager: _.get(api, `${c.GET_ATTACHED_USERS_BA}.res.meta`) || {},
  isLoading: api.loading.indexOf(c.GET_ATTACHED_USERS_BA) > -1,
  branchDetails: _.get(api, `${c.SHOW_BRANCH}.item`) || {},
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(BranchAdmin);
