import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { useFilterSim } from "app/load-wallet/context/SimFilterProvider";
import { removeEmpty } from "helper";
import { useDealerSim } from "app/load-wallet/dealer-sims/context/DealerSimProvider";
import { useDealer } from "app/load-wallet/context/DealerProvider";
import Filter from "components/Filter";
import * as a from "../actions";
import { form } from "../constant";

function SearchBar() {
  const { sortFilter, filterData, setFilterData } = useFilterSim();
  const { simDealerId } = useDealerSim();
  const { isNonMigrated, isBA, profile } = useDealer();
  const dispatch = useDispatch();

  const advanceForm = useMemo(() => {
    return form?.map((filterAssignedTo) => {
      if (filterAssignedTo?.name === "assigned_to") {
        if (isBA)
          return {
            ...filterAssignedTo,
            api: "retailer/api/v1/branches/assigned_to",
            params: !isNonMigrated
              ? { type: "dealer_sims", retailer_id: profile?.retailer_id }
              : { retailer_id: profile?.retailer_id },
            transformer: (list) => {
              return list?.attributes.map((x) => ({
                value: x?.value,
                label: x?.label,
              }));
            },
          };

        return {
          ...filterAssignedTo,
          params: { ...filterAssignedTo.params, dealer_id: simDealerId },
        };
      }
      if (filterAssignedTo?.name === "type" && isBA)
        return {
          ...filterAssignedTo,
          api: "retailer/api/v1/branches/sim_types",
          params: !isNonMigrated
            ? { type: "dealer_sims", retailer_id: profile?.retailer_id }
            : { retailer_id: profile?.retailer_id },
          transformer: (list) => {
            return list?.attributes.map((x) => ({
              value: x?.value,
              label: x?.label,
            }));
          },
        };
      if (filterAssignedTo?.name === "type")
        return {
          ...filterAssignedTo,
          params: { ...filterAssignedTo.params, dealer_id: simDealerId },
        };
      if (filterAssignedTo?.name === "status" && isNonMigrated)
        return { ...filterAssignedTo, show: false };
      if (filterAssignedTo?.name === "request_type" && isNonMigrated)
        return { ...filterAssignedTo, show: false };
      if (filterAssignedTo?.name === "sim_status" && isNonMigrated) {
        const detachInactive = _.pullAllBy(
          filterAssignedTo?.options,
          [
            {
              value: "INACTIVE",
            },
          ],
          "value"
        );
        return {
          ...filterAssignedTo,
          options: detachInactive,
        };
      }

      return filterAssignedTo;
    });
  }, [form, simDealerId]);

  const handleSubmitInput = (e) => {
    e.preventDefault();
    dispatch(
      a.getDealerSims(
        removeEmpty({
          dealer_id: simDealerId,
          userType: "dealers",
          ...filterData,
          ...sortFilter,
          sim_status: filterData?.sim_status?.value || "",
          status: filterData?.status?.value || "",
        })
      )
    );
    dispatch(
      a.getDealerSimsAll(
        removeEmpty({
          dealer_id: simDealerId,
          userType: "dealers",
          per_page: 9999,
          ...filterData,
          sim_status: filterData?.sim_status?.value || "",
          status: filterData?.status?.value || "",
          ...sortFilter,
        })
      )
    );
    dispatch(
      a.retailerSimsGetRetailerSims(
        removeEmpty({
          dealer_id: simDealerId,
          ...filterData,
          ...sortFilter,
          sim_status: filterData?.sim_status?.value || "",
        })
      )
    );
    dispatch(
      a.retailerSimsAll(
        removeEmpty({
          dealer_id: simDealerId,
          per_page: 9999,
          ...filterData,
          ...sortFilter,
          sim_status: filterData?.sim_status?.value || "",
        })
      )
    );
  };

  return (
    <Filter
      form={advanceForm}
      filterData={[filterData, setFilterData]}
      actions={handleSubmitInput}
      containerClass="tw-flex-grow"
    />
  );
}
export default SearchBar;
