import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withForm from 'modules/common/hoc/withForm';
import { ToastSuccess } from 'modules/common/components/Toast';
import UploadFileModal from 'modules/common/components/UploadFileModal';
import { ReactComponent as IconRequest } from 'assets/images/sidebar-icon-contact.svg';
import Img from 'modules/common/components/Img';
import * as actions from '../../action';
import * as c from '../../constant';

const renderIcon = type => {
  if (type === 'pdf') {
    return <i className="fas fa-3x fa-file-pdf text-muted" />;
  }
  if (type === 'doc' || type === 'docx') {
    return <i className="fas fa-3x fa-file-word text-muted" />;
  }
  if (type === 'csv') {
    return <i className="fas fa-3x fa-file-csv text-muted" />;
  }
  if (type === 'txt') {
    return <i className="fas fa-3x fa-file-alt text-muted" />;
  }
  return null;
};

const renderPartial = (xtn, item) => {
  const imgF = ['jpeg', 'jpg', 'png'];
  const detectWhatToRender = () => {
    if (imgF.indexOf(xtn) > -1) {
      return 'img';
    }
    return 'application';
  };

  try {
    const dispatchRender = {
      img: () => (
        <Img
          src={item}
          className="file-container file-container-img border rounded-sm mr-2"
          role="presentation"
          alt={item}
        />
      ),
      application: () => (
        <a
          href={item}
          className="file-container d-flex justify-content-center align-items-center border rounded-sm mr-2"
          target="_heopenit"
        >
          {renderIcon(xtn)}
        </a>
      ),
    };

    return dispatchRender[detectWhatToRender()]
      ? dispatchRender[detectWhatToRender()]()
      : dispatchRender.default();
  } catch (error) {
    // eslint-disable-next-line no-console
    return null;
  }
};

const priorityType = [
  { value: 'Low', label: 'Low' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Critical', label: 'Critical' },
];

function IssueContainer({
  createInput,
  createSelect,
  createTextArea,
  showIssue,
  showIssueList,
  history,
  createTicket,
  formData,
  id,
  issueId,
  issue,
  getIssue,
}) {
  const [state, setstate] = useState(false);
  const [file, setFiles] = useState([]);

  const handleCancel = e => {
    e.preventDefault();
    history.push(`/assurance-management/helpdesk/${id}/issue`);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const formatFiles = (_.get(file, 'files') || []).map(x => ({
      name: x,
    }));
    const payload = {
      ...formData,
      issue_id: issueId,
      files: formatFiles,
    };
    createTicket(payload, () => {
      ToastSuccess('Your request was successfully submitted.');
      history.push(`/assurance-management/helpdesk`);
      setFiles([]);
    });
  };

  const handleIssue = data => e => {
    e.preventDefault();
    history.push(
      `/assurance-management/helpdesk/${id}/issue/${_.get(data, 'id')}`
    );
    setstate(false);
  };

  const handleOnChangeUpload = files => {
    setFiles({
      ...file,
      files,
    });
  };

  useEffect(() => {
    showIssue(id, issueId);
    getIssue(id);
  }, [showIssue, id, getIssue, issueId]);

  return (
    <>
      <div className="container-fluid">
        <div className="text-left mr-auto">
          <div className="h4 text-primary mt-0">
            {_.get(showIssueList, 'attributes.name')}
          </div>
          <h2 className="h5 font-weight-normal mt-0 text-muted">
            {_.get(showIssueList, 'attributes.description')}
          </h2>
        </div>
      </div>
      <div className="container-fluid px-5">
        <h5>What can we help you with?</h5>
        <div className="dropdown mb-4">
          <button
            onClick={() => setstate(!state)}
            type="button"
            className="btn btn-default btn-block btn-helpdesk p-4 text-left"
          >
            <div className="d-flex align-items-center">
              <IconRequest className="text-primary icon-helpdesk mr-4" />

              <div className="flex-grow-1">
                <div className="text-success">
                  {_.get(showIssueList, 'attributes.name')}
                </div>
                <div className="text-muted text-normal font-weight-normal">
                  {_.get(showIssueList, 'attributes.description')}
                </div>
              </div>

              <i className={`fas fa-chevron-${state ? 'up' : 'down'}`} />
            </div>
          </button>

          {issue.length > 1 && (
            <div
              className={`${
                state ? 'show' : ''
              } dropdown-menu w-100 py-0 helpdesk-dropdown`}
            >
              {issue
                .filter(x => `${x.id}` !== `${issueId}`)
                .map(x => (
                  <button
                    type="button"
                    className="dropdown-item btn btn-default btn-dropdown p-4 border-bottom"
                    onClick={handleIssue(x)}
                    key={x.id}
                  >
                    <div className="d-flex align-items-center">
                      <IconRequest className="text-primary icon-helpdesk mr-4" />

                      <div className="flex-grow-1">
                        <div className="text-success">
                          {_.get(x, 'attributes.name')}
                        </div>
                        <div className="text-muted text-normal font-weight-normal">
                          {_.get(x, 'attributes.description')}
                        </div>
                      </div>
                    </div>
                  </button>
                ))}
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-6">
              {createInput(
                { key: 'subject', label: 'Subject' },
                { required: true, autoFocus: true }
              )}
            </div>
            <div className="col-6">
              {createSelect(
                { key: 'priority', label: 'Priority' },
                priorityType,
                { required: true, className: 'custom-select' }
              )}
            </div>
            <div className="col-12 mt-3">
              <div className="issue-file-upload">
                {createTextArea(
                  { key: 'description', label: 'Description' },
                  {
                    required: true,
                    row: '5',
                  }
                )}

                {!_.isEmpty(_.get(file, 'files')) && (
                  <div className="file-upload-container p-3 mt-3">
                    {(_.get(file, 'files') || []).map(x => {
                      const ext = (x.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1];
                      return (
                        <div key={x} className="flex-shrink-0">
                          {renderPartial(ext, x)}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="d-flex mt-3">
            <button type="submit" className="btn btn-primary btn-sm mr-3">
              <i className="fas fa-paper-plane mr-2" />
              Send
            </button>

            <UploadFileModal
              onChange={handleOnChangeUpload}
              className="btn btn-info btn-sm"
              customButton={() => (
                <>
                  <i className="fa fa-paperclip mr-2" />
                  <span className="text">Attachment</span>
                </>
              )}
              caption="Maximum size of file is 2MB"
              maxSize={2}
              isImageOnly={false}
              fileType="image/*, .pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, .doc, .docx"
              isMultiple
            />

            <button
              type="button"
              className="btn btn-outline-dark btn-sm ml-auto"
              onClick={handleCancel}
            >
              cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

IssueContainer.propTypes = {
  createInput: PropTypes.func.isRequired,
  createSelect: PropTypes.func.isRequired,
  createTextArea: PropTypes.func.isRequired,
  showIssue: PropTypes.func.isRequired,
  createTicket: PropTypes.func.isRequired,
  issueId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  getIssue: PropTypes.func.isRequired,
  issue: PropTypes.instanceOf(Array).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
  showIssueList: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ api }, { match }) => ({
  issue: _.get(api, `${c.GET_ISSUE}.list`) || [],
  showIssueList: _.get(api, `${c.SHOW_ISSUE}.item`) || {},
  id: _.get(match, 'params.id') || null,
  issueId: _.get(match, 'params.issueId') || null,
});

const enhance = _.flowRight([
  withRouter,
  withForm,
  connect(mapStateToProps, actions),
]);

export default enhance(IssueContainer);
