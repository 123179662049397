import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Stepper, Step } from 'modules/common/components/Stepper';
import { USER_PROFILE } from 'modules/auth/constants';
import ConfirmAlert from 'modules/common/components/ConfirmAlert';
import { logout } from 'modules/auth/actions';
import { ToastSuccess } from 'modules/common/components/Toast';
import Consent from './Consent';
import UpdateProfile from './UpdateProfile';
import RetailerChangePassword from './RetailerChangePassword';
import ChangeMPIN from './ChangeMPIN';
import { GET_CONSENT } from '../constant';
import {
  updatePasswordAndMPin,
  updateProfile,
  createConsent,
  listConsent,
  organizeConsents,
} from '../action';

const initChangePwFormData = {
  old_password: '',
  new_password: '',
  new_password_confirmation: '',
};

const initPinFormData = {
  old_mpin: '',
  new_mpin: '',
  new_mpin_confirmation: '',
};

const Onboarding = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const apiConsents = useSelector(({ api }) => api?.[GET_CONSENT]?.list || []);
  const profile = useSelector(({ api }) => api?.[USER_PROFILE]?.item);
  const [currentStep, setCurrentStep] = useState(0);
  const [changePwFormData, setChangePwFormData] = useState(
    initChangePwFormData
  );
  const [pinFormData, setPinFormData] = useState(initPinFormData);
  const [agreed, setAgreed] = useState([]);
  const [isPending, setPending] = useState(false);

  const consents = useMemo(() => {
    return organizeConsents(apiConsents, 'PRIVACY NOTICE', profile);
  }, [profile, apiConsents]);

  const profileFieldsData = useMemo(
    () => ({
      first_name: _.get(profile, 'first_name') || '',
      last_name: _.get(profile, 'last_name') || '',
      mobile:
        _.get(profile, 'mobile_identification_number') ||
        _.get(profile, 'mobile') ||
        '',
      email: _.get(profile, 'email') || '',
      is_first_time: 0,
    }),
    [profile]
  );

  // Stepper Actions
  const handleConsentAgree = targetConsent => {
    // TODO: wrap in update consent action
    const { id } = targetConsent;
    const att = _.get(targetConsent, 'attributes');

    setAgreed(prev => [
      ...prev,
      {
        consent_id: id,
        has_agreed: true,
        title: att.title,
        version: att.version,
        body: att.body,
      },
    ]);

    setCurrentStep(step => step + 1);
  };

  const onPrevStep = () => {
    setCurrentStep(step => step - 1);
  };

  // Cancel First Login
  const handleCancelFirstLogin = isProfileStep => {
    const alertText = isProfileStep
      ? 'For any incorrect details, please contact your Administrator.'
      : `Are you sure you don't want to proceed?`;

    ConfirmAlert(
      alertText,
      () => {
        dispatch(
          logout(() => {
            history.push(
              `/?mobile_identification_number=${_.get(profile, 'username')}`
            );
            ToastSuccess('You have successfully logged out!', {
              autoClose: 1500,
            });
          })
        );
      },
      () => {},
      {
        yesLabel: 'Yes',
        noLabel: 'No',
      }
    );
  };

  // Confirm Profile Action
  const handleSubmitProfile = e => {
    e.preventDefault();
    setCurrentStep(step => step + 1);
  };

  // Change Temporary Password Form actions
  const handleChangePasswordField = key => ({ target }) => {
    setChangePwFormData(prevState => ({
      ...prevState,
      [key]: target.value,
    }));
  };

  // Change MPIN form actions
  const handleChangeMPINInput = key => ({ target }) => {
    setPinFormData(prevState => ({
      ...prevState,
      [key]: target.value,
    }));
  };

  const handleFinishForm = () => {
    setPending(true);
    const logoutCallback = () => {
      ToastSuccess(
        'You have successfully updated your information. Please log-in again.'
      );
    };

    const updateProfileCallback = () => {
      setPending(false);
      dispatch(logout(logoutCallback()));
    };

    const updatePasswordAndMPinCallback = response => {
      dispatch(
        createConsent({ consents: agreed }, () => {
          dispatch(listConsent());
          ToastSuccess(_.get(response, 'res.data.success[0].detail'));
          dispatch(
            updateProfile({ is_first_time: false }, updateProfileCallback)
          );
          setPending(false);
        })
      );
    };

    const updateErrorCallback = () => {
      setPending(false);
    };

    const payload = { ...changePwFormData, ...pinFormData };

    dispatch(
      updatePasswordAndMPin(
        payload,
        response => updatePasswordAndMPinCallback(response),
        updateErrorCallback
      )
    );
  };

  return (
    <div>
      <Stepper activeStep={currentStep}>
        {consents.map(data => (
          <Step key={data.id}>{_.get(data, 'attributes.title')}</Step>
        ))}
        <Step>Details</Step>
        <Step>Change Password</Step>
        <Step>Change Security PIN</Step>
      </Stepper>

      {consents.map((data, index) => {
        if (currentStep !== index) return null;
        return (
          <Consent
            key={data.id}
            data={data}
            onNext={() => handleConsentAgree(data)}
            onCancel={handleCancelFirstLogin}
          />
        );
      })}

      {consents.length && currentStep === consents.length ? (
        <UpdateProfile
          profileFieldsData={profileFieldsData}
          handleSubmitProfile={handleSubmitProfile}
          onCancelProfileStep={() => handleCancelFirstLogin(true)}
        />
      ) : null}

      {consents.length && currentStep === consents.length + 1 ? (
        <RetailerChangePassword
          onChangeInput={handleChangePasswordField}
          fieldsData={changePwFormData}
          onNextStep={() => setCurrentStep(step => step + 1)}
          onPrevStep={() => setCurrentStep(step => step - 1)}
          onCancel={handleCancelFirstLogin}
        />
      ) : null}

      {consents.length && currentStep === consents.length + 2 ? (
        <ChangeMPIN
          pinFormData={pinFormData}
          onChangeInput={handleChangeMPINInput}
          onFinishForm={handleFinishForm}
          onPrevStep={onPrevStep}
          loading={isPending}
          onCancel={handleCancelFirstLogin}
        />
      ) : null}
    </div>
  );
};

export default Onboarding;
