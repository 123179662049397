import * as req from 'api/actions';
import * as c from './constant';

export const getPricingMatrices = (payload, callback) =>
  req.list(
    c.GET_PRICING_MATRICES,
    'product/api/v1/pricings',
    payload,
    callback
  );

export const showPricingDetails = (pricingId, payload, callback) =>
  req.item(
    c.SHOW_PRICING_DETAILS,
    `product/api/v1/pricings/${pricingId}`,
    payload,
    callback
  );

export const getSpecialPricingByPriceMatrix = (
  pricingId,
  params = {},
  callback = () => {}
) =>
  req.list(
    c.GET_SPECIAL_PRICING_BY_PRICE_MATRIX,
    `product/api/v1/pricings/${pricingId}/special-pricings`,
    params,
    callback
  );

export const showSpecialPricingDetails = (
  pricingId,
  specialPricingId,
  payload,
  callback
) =>
  req.item(
    c.SHOW_SPECIAL_PRICING_DETAILS,
    `product/api/v1/pricings/${pricingId}/special-pricings/${specialPricingId}`,
    payload,
    callback
  );

export const updateSpecialPricing = (
  pricingId,
  specialPricingId,
  payload,
  callback,
  errCallback
) =>
  req.update(
    c.UPDATE_SPECIAL_PRICING,
    `product/api/v1/pricings/${pricingId}/special-pricings/${specialPricingId}`,
    payload,
    callback,
    {},
    errCallback
  );
