import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import withModal from "modules/common/hoc/withModal";
import Select from "react-select";
import { ModalContext } from "App";
import _ from "lodash";
import * as actions from "../../actions";
import * as c from "../../constants";

function Generation() {
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);
  const [keyData, setKeyData] = useState({});
  const [initialData, setInitialData] = useState({
    branch_code: "",
  });

  const branches = useSelector(({ api }) =>
    _.get(api, `${c.GET_BRANCHES}.list`, [])
  );

  const handleBranches = (selectedItem) =>
    setInitialData({ branch_code: selectedItem });

  const branchesOptions = useMemo(
    () =>
      branches.map(({ attributes: { code } }) => {
        return {
          value: code,
          label: code,
        };
      }),
    [branches]
  );

  const closeModal = useCallback(
    (e) => {
      e.preventDefault();
      const variable = {
        client_id: keyData?.attributes?.client_id,
        client_secret: keyData?.attributes?.client_secret,
        secretKey: keyData?.attributes?.secret_key,
      };
      sessionStorage.setItem("keyGenData", JSON.stringify(variable));
      setModal({ isOpen: false });
    },
    [keyData]
  );

  useEffect(() => {
    const sucCallback = (data) => {
      setKeyData(data?.data);
    };

    dispatch(actions.generateKey(sucCallback));
    dispatch(actions.getBranches());
  }, []);
  return (
    <>
      <div className="container">
        <p className="tw-font-semibold">Authentication</p>
        <div className="tw-bg-gray-200 py-3 px-2 mb-3 rounded-sm">
          <div className="row">
            <div className="col-sm-3 mb-2">
              <div className="tw-font-semibold">client_id:</div>
            </div>
            <div className="col-sm-8">
              <div className="tw-font-light">
                {keyData?.attributes?.client_id || "--"}
              </div>
            </div>
            <div className="col-sm-1">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(keyData?.attributes?.client_id);
                }}
              >
                <i className="fas fa-copy" />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <div className="tw-font-semibold">client_secret:</div>
            </div>
            <div className="col-sm-8">
              <div className="tw-font-light">
                {keyData?.attributes?.client_secret || "--"}
              </div>
            </div>
            <div className="col-sm-1">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    keyData?.attributes?.client_secret
                  );
                }}
              >
                <i className="fas fa-copy" />
              </span>
            </div>
          </div>
        </div>
        <p className="tw-font-semibold">Transaction Key</p>
        <div className="tw-bg-gray-200 py-3 px-3 rounded-sm">
          <div className="row">
            <div className="col-sm-3 mb-2">
              <div className="tw-font-semibold">secretKey:</div>
            </div>
            <div className="col-sm-8">
              <div className="tw-font-light">
                {keyData?.attributes?.secret_key || "--"}
              </div>
            </div>
            <div className="col-sm-1">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    keyData?.attributes?.secret_key
                  );
                }}
              >
                <i className="fas fa-copy" />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 mb-2">
              <div className="tw-font-semibold">corporate_id:</div>
            </div>
            <div className="col-sm-8">
              <div className="tw-font-light">
                {keyData?.attributes?.corporate_id || "--"}
              </div>
            </div>
            <div className="col-sm-1">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    keyData?.attributes?.corporate_id
                  );
                }}
              >
                <i className="fas fa-copy" />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 mb-2">
              <div className="tw-font-semibold">branch_id:</div>
            </div>
            <div className="row col px-3">
              <div className="col tw-font-light">
                <Select
                  className="text-xs partners__select-options border-0 bottom-0"
                  name="retailer_type"
                  placeholder="Set here..."
                  id="retailer_type"
                  required
                  options={branchesOptions}
                  styles={c.selectOptionStyles}
                  onChange={handleBranches}
                  value={initialData?.branch_code || ""}
                  isLoading={false}
                />
              </div>
              <div className="col-sm-1 px-0">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      initialData?.branch_code?.value
                    );
                  }}
                >
                  <i className="fas fa-copy" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            onClick={closeModal}
            className="mt-3 btn btn-modal-yes mr-2 px-4 py-2"
            style={{ borderRadius: 5 }}
          >
            <span
              className="tw-font-semibold"
              style={{ color: "#FFFFFF", fontSize: 14 }}
            >
              CONTINUE
            </span>
          </button>
          <button
            type="button"
            onClick={closeModal}
            className="mt-3 btn btn-modal-no px-4 py-2"
            style={{ borderRadius: 5 }}
          >
            <span
              className="tw-font-semibold"
              style={{ color: "#FFFFFF", fontSize: 14 }}
            >
              Cancel
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

const modalKey = "can-see-keys";

const modalConfig = {
  title: "Key Generation",

  size: "modal-lg",
};

export default withModal(modalKey, modalConfig)(Generation);
