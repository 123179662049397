import React, { useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { checkRoles } from 'helper';
import { USER_PROFILE } from 'modules/auth/constants';
import Frontliner from './Frontliner';
import BranchAdmin from './BranchAdmin';
import {
  getBranch,
  getRetailerSims,
  showRetailer,
  showRetailerUsers,
  getBentaKita,
  getTop,
} from '../actions';

const INIT_STATE = {
  filter_by: 'monthly',
  date: moment().format('YYYY-MM-DD'),
};

const Container = () => {
  const dispatch = useDispatch();
  const retailerId = useSelector(
    ({ api }) =>
      _.get(api, `${USER_PROFILE}.item.retailer_id`) ||
      _.get(JSON.parse(sessionStorage.getItem('profile')), 'retailer_id')
  );

  useEffect(() => {
    dispatch(getBranch());
    dispatch(getRetailerSims());
    dispatch(getBentaKita(INIT_STATE));
    if (checkRoles(['kapartner-user'])) dispatch(showRetailer(retailerId));
    if (checkRoles(['frontliner', 'branch-admin']))
      dispatch(showRetailerUsers());
    if (checkRoles(['branch-admin'])) dispatch(getTop({ count: 5 }));
  }, [dispatch, retailerId]);

  const render = () => {
    if (checkRoles(['frontliner', 'kapartner-user', 'sub-dealer-agent']))
      return <Frontliner />;
    if (checkRoles(['branch-admin'])) return <BranchAdmin />;
    return null;
  };

  return render();
};

export default Container;
