import React, { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import _ from "lodash";

import { ModalContext } from "App";
import { ToastSuccess, ToastError } from "modules/common/components/Toast";
import withModal from "modules/common/hoc/withModal";

import ButtonAsync from "components/Button";
import { validatePass, updateAttachUsers } from "../actions";

function FrontlinerStatus() {
  const queryClient = useQueryClient();
  const setModal = useContext(ModalContext);
  const [formData, setFormData] = useState({ password: "" });
  const [view, setView] = useState(false);
  const [pending, setPending] = useState(false);

  const modalData = useSelector(({ modal }) => modal?.modalData?.data || {});

  const closeModal = () => setModal({ isOpen: false });

  const onError = (errors) => {
    setPending(false);
    _.forEach(errors?.response?.data?.errors, (err) => ToastError(err?.detail));
  };

  const { mutate: updateAttachUsersApi } = useMutation(updateAttachUsers, {
    onSuccess: () => {
      queryClient.invalidateQueries("attached_users_fl");
      ToastSuccess(
        `Successfully ${modalData?.callbackLabel} ${modalData?.name}.`
      );
      closeModal();
      setPending(false);
    },
    onError,
  });

  const { mutate: validatePassApi } = useMutation(validatePass, {
    onSuccess: () =>
      updateAttachUsersApi({
        id: modalData?.retailerId,
        ...modalData?.payload,
      }),
    onError,
  });

  const handleOnViewPassword = (value) => setView(value);

  const handleOnChange = ({ target }) =>
    setFormData({
      ...formData,
      password: target.value,
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);
    validatePassApi(formData);
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="text-center">
        <span className="font-weight-bold">
          Are you sure you want to {modalData?.label} {modalData?.name}?
        </span>
      </div>
      <div className="text-center">
        <small>Your password is required to continue.</small>
      </div>
      <div className="form-group">
        <div className="prepend-form-right">
          <label className="form-label text-success">Password:</label>
          <div className="prepend-input-group">
            <input
              type={view ? "text" : "password"}
              className="form-control form-custom form-control_bottom"
              required
              autoFocus
              name="password"
              value={_.get(formData, "password") || ""}
              onChange={handleOnChange}
            />
          </div>
          <button
            type="button"
            onClick={() => handleOnViewPassword(!view)}
            className="btn btn-default rounded-circle p-0 mr-1"
          >
            <i className={`fas ${!view ? "fa-eye" : "fa-eye-slash"}`} />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-center">
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold mr-2"
            >
              CANCEL
            </button>
            <ButtonAsync
              type="submit"
              loading={pending}
              disabled={_.get(formData, "password").length < 1}
            >
              PROCEED
            </ButtonAsync>
          </div>
        </div>
      </div>
    </form>
  );
}

const modalKey = "frontliner-update-status";
const modalConfig = {
  title: "",
  size: "modal-md",
};

export default withModal(modalKey, modalConfig)(FrontlinerStatus);
