import React from 'react';
import PropTypes from 'prop-types';
import PDF from 'assets/images/PDF_logo.png';
import { showModal } from '../Modal';
import Lightbox from './Lightbox';
import './style.css';

function Img({ src, defaultSrc, withZoom, lightBox, type, ...rest }) {
  const isPDF = type === 'application/pdf';
  const [url, setUrl] = React.useState(src || defaultSrc);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const handleLoad = () => {
    setImageLoaded(true);
  };
  const handleError = () => {
    setUrl(defaultSrc);
  };
  const handleOpenLightbox = () => {
    if (url === defaultSrc || !lightBox || !imageLoaded || src === '') return;
    showModal({
      title: false,
      className: 'modal-dialog-centered lightbox-wrapper modal-fullscreen',
      content: onClose => <Lightbox src={src} onClose={onClose} type={type} />,
    });
  };
  const handleKeyUp = e => {
    if (e.keyCode === 13) handleOpenLightbox();
  };
  React.useEffect(() => {
    setImageLoaded(false);
    if (isPDF) {
      setUrl(PDF);
      setImageLoaded(true);
      return;
    }
    setUrl(src || defaultSrc);
  }, [src]);

  if (!withZoom) {
    return (
      <div
        className={
          src !== defaultSrc && src !== '' ? 'default-view' : 'no-image'
        }
      >
        <img
          src={url}
          alt="thumb"
          role="presentation"
          onLoad={handleLoad}
          onError={handleError}
          onClick={handleOpenLightbox}
          onKeyUp={handleKeyUp}
          {...rest}
          style={{
            cursor: lightBox ? 'pointer' : 'default',
          }}
        />
      </div>
    );
  }

  return (
    <div
      className="image-container"
      role="presentation"
      onClick={handleOpenLightbox}
      onKeyUp={handleKeyUp}
    >
      <figure>
        {url !== defaultSrc && url !== '' ? (
          <div className="with-image">
            <img
              src={url}
              {...rest}
              alt="thumb"
              className="image"
              onLoad={handleLoad}
              onError={handleError}
              width={isPDF ? '60%' : '100%'}
            />
            <div className="middle">
              <div className="image-text">
                <i className="fas fa-lg fa-search-plus" /> View{' '}
                {isPDF ? 'Document' : 'Image'}
              </div>
            </div>
          </div>
        ) : (
          <div className="no-image">
            <img
              src={defaultSrc}
              {...rest}
              alt="thumb"
              className="image"
              onLoad={handleLoad}
              onError={handleError}
            />
          </div>
        )}
      </figure>
    </div>
  );
}

Img.propTypes = {
  src: PropTypes.string,
  defaultSrc: PropTypes.string,
  lightBox: PropTypes.bool,
  withZoom: PropTypes.bool,
  type: PropTypes.string,
};

Img.defaultProps = {
  src: '',
  defaultSrc: 'https://via.placeholder.com/128?text=Img',
  lightBox: false,
  withZoom: false,
  type: '',
};

export default Img;
