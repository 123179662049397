import React from 'react';
import { loadAPI } from '../../../helper';

const GOOGLE_API_KEY = 'AIzaSyA_z-j5zuoi1b2XPkNaJSHx065t6uVOikc';

export default WrappedComponent =>
  class WithGoogle extends React.Component {
    state = {
      initalized: false,
    };

    componentDidMount() {
      this.init();
    }

    init = async () => {
      await loadAPI(
        'google-places-api',
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`
      );
      this.setState({ initialized: true });
    };

    render() {
      const { initialized } = this.state;
      if (!initialized) return <div />;
      return <WrappedComponent {...this.props} />;
    }
  };
