import React, { useContext, useMemo } from "react";
import * as Icon from "components/Icons";
import Pagination from "modules/common/components/Pagination";
import moment from "moment";
import _ from "lodash";
import CardItem from "modules/common/components/CardItem";
import RetailerSimActions from "modules/sim/components/RetailerSimActions";
import { ModalContext } from "App";
import { useDealerSim } from "app/load-wallet/dealer-sims/context/DealerSimProvider";
import { useRetailerSim } from "app/load-wallet/retailer-sims/context/RetailerSimProvider";
import { useDispatch } from "react-redux";
import { removeEmpty, checkPermissions } from "helper";
import { useDealer } from "app/load-wallet/context/DealerProvider";
import { useFilterSim } from "app/load-wallet/context/SimFilterProvider";
import SetRetailerThreshold from "modules/sim/containers/SetRetailerThreshold";
import TabDetails from "./TabDetails";
import RetailerSimFormModal from "../modalForms/RetailerSimFormModal";
import RetailerSimPasswordConfirmation from "./RetailerSimPasswordConfirmation";
import UpdateRetailerSimFormModal from "../modalForms/UpdateRetailerSimFormModal";
import DeleteRetailerSim from "../modalForms/DeleteRetailerSim";
import ConfirmOtp from "./ConfirmOtp";
import { getRetailerSources } from "app/dashboard/actions";
import * as c from "../constant";
import * as a from "../actions";

const AddIcon = () => <Icon.Add fill="#fff" />;

const RetailerSimsList = () => {
  const dispatch = useDispatch();
  const { sortFilter, filterData } = useFilterSim();
  const { dealerRole, simDealerId, role, profile } = useDealer();
  const {
    retailerSimSliced,
    retailerSimPager,
    retailerSimsLoading,
  } = useRetailerSim();
  const isBA = role?.includes("branch-admin");

  const ChangePage = (page) => {
    isBA
      ? dispatch(
          getRetailerSources(
            removeEmpty({
              ...filterData,
              page,
              sim_status: filterData?.sim_status?.value || "",
              status: filterData?.status?.value || "",
              retailer_id: profile?.retailer_id,
            })
          )
        )
      : dispatch(
          a.retailerSimsGetRetailerSims(
            removeEmpty({
              dealer_id: simDealerId,
              ...filterData,
              ...sortFilter,
              page,
              sim_status: filterData?.sim_status?.value || "",
            })
          )
        );
  };

  return (
    <>
      <TabDetails
        isLoading={retailerSimsLoading}
        headerTitle={() => {
          return <AddRetailerSim />;
        }}
        data={retailerSimSliced}
        render={(item, index) => {
          return (
            <div className="col-lg-4 col-xl-12 pb-3 pb-lg-3" key={index}>
              <div className="card">
                <div className="row card-body">
                  <div className="col row pr-0">
                    <CardItem
                      label="Retailer MIN"
                      description={
                        item?.attributes?.mobile_identification_number
                      }
                    />
                    <CardItem
                      label="Assign to"
                      description={_.truncate(item?.attributes?.assigned_to, {
                        length: c.TEXT_LIMIT,
                        separator: " ",
                      })}
                    />
                    <CardItem
                      label="Date Created"
                      description={moment(item?.attributes?.created_at).format(
                        "lll"
                      )}
                    />
                    <CardItem
                      label="SIM Status"
                      description={item?.attributes?.sim_status}
                    />
                  </div>
                  <div className="col-1 text-right">
                    <div
                      className="font-weight-bold"
                      style={{
                        fontSize: ".7rem",
                        color: "rgba(0, 0, 0, .6)",
                      }}
                    >
                      Action
                    </div>
                    <div>
                      <RetailerSimActions item={item} dealerRole={dealerRole} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      />
      <Pagination
        className="d-flex justify-content-between p-2 border-top border-muted"
        onChange={ChangePage}
        data={retailerSimPager}
        withPage
      />

      <RetailerSimFormModal />
      <UpdateRetailerSimFormModal />
      <DeleteRetailerSim />
      <ConfirmOtp />
      <SetRetailerThreshold />
      <RetailerSimPasswordConfirmation />
    </>
  );
};

export default RetailerSimsList;

const AddRetailerSim = () => {
  const setModal = useContext(ModalContext);
  const { hasDealerSim } = useDealerSim();
  const { selectedDealer, dealerRole, isNonMigrated, isB2B } = useDealer();

  const newRetailerSim = () =>
    setModal("fill-retailer-sim", {
      dealerData: selectedDealer,
      dealerRole,
    });

  const AddBtn = useMemo(() => {
    return (
      <button
        type="button"
        className="d-flex btn text-md-size btn-success rounded-0 py-2 rounded-top-right"
        onClick={() => newRetailerSim()}
        disabled={!isB2B && !isNonMigrated && !hasDealerSim}
      >
        <AddIcon />
        <span className="ml-2">add new sim</span>
      </button>
    );
  }, [hasDealerSim]);

  return (
    <>
      <div className="header-title my-auto">
        <h6 className="h6 text-md-size font-weight-bold p-0 m-0">
          Manage Retailer SIMs
        </h6>
      </div>
      <div>
        {checkPermissions(["can-create-retailer-sim"]) ? (
          AddBtn
        ) : (
          <div>
            <button
              type="button"
              className="d-flex btn text-md-size rounded-0 py-4 rounded-top-right"
              disabled
            />
          </div>
        )}
      </div>
    </>
  );
};
