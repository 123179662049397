import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const List = ({ data, renderItems, emptyMessage }) => {
  if (_.isEmpty(data)) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <small className="text-gray-400">{emptyMessage}</small>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column position-relative">
      {_.map(data, renderItems)}
    </div>
  );
};

export default List;

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  renderItems: PropTypes.func.isRequired,
  emptyMessage: PropTypes.string,
};

List.defaultProps = {
  data: [],
  emptyMessage: 'No items found.',
};
