export const GET_DEALER_LIST = 'DEALER-INFORMATION/GET_DEALER_LIST';
export const CREATE_DEALER = 'DEALER-INFORMATION/CREATE_DEALER';
export const UPDATE_DEALER = 'DEALER-INFORMATION/UPDATE_DEALER';
export const DELETE_DEALER = 'DEALER-INFORMATION/DELETE_DEALER';
export const GET_BDL = 'DEALER-INFORMATION/GET-BDL';

export const SHOW_USER_DETAILS = 'DEALER-INFORMATION/SHOW_USER_DETAILS';
export const SHOW_COMPANY_INFORMATION =
  'DEALER-INFORMATION/SHOW_COMPANY_INFORMATION';
export const SHOW_COMPANY_ADDRESS = 'DEALER-INFORMATION/SHOW_COMPANY_ADDRESS';
export const SHOW_DELIVERY_ADDRESS_INFORMATION =
  'DEALER-INFORMATION/SHOW_DELIVERY_ADDRESS_INFORMATION';
export const SHOW_MOA_SIGNATORY_INFORMATION =
  'DEALER-INFORMATION/SHOW_MOA_SIGNATORY_INFORMATION';

export const GET_ACCOUNT_TYPE = 'DEALER-INFORMATION/GET_ACCOUNT_TYPE';
export const GET_CHANNEL_LIST = 'DEALER-INFORMATION/GET_CHANNEL_LIST';
export const GET_PROVINCE_LIST = 'DEALER-INFORMATION/GET_PROVINCE_LIST';
export const GET_CITY_LIST = 'DEALER-INFORMATION/GET_CITY_LIST';
export const GET_BARANGAY_LIST = 'DEALER-INFORMATION/GET_BARANGAY_LIST';
export const GET_REQUIRED_DOCUMENTS_IMAGES_LIST =
  'DEALER-INFORMATION/GET_REQUIRED_DOCUMENTS_IMAGES';
export const GET_REQUIRED_DOCUMENTS_LIST =
  'DEALER-INFORMATION/GET_REQUIRED_DOCUMENTS_LIST';
export const GET_LOCATION = 'DEALER-INFORMATION/GET_LOCATION';

export const CREATE_DEALER_COMPANY = 'DEALER-INFORMATION/CREATE_DEALER_COMPANY';
export const CREATE_MOA_SIGNATORIES =
  'DEALER-INFORMATION/CREATE_MOA_SIGNATORIES';
export const CREATE_DELIVERY_ADDRESS =
  'DEALER-INFORMATION/CREATE_DELIVERY_ADDRESS';
export const CREATE_APPLICATIONS = 'DEALER-INFORMATION/CREATE_APPLICATIONS';
export const CREATE_SUPPORTING_DOCUMENTS =
  'DEALER-INFORMATION/ CREATE_SUPPORTING_DOCUMENTS';
export const ADD_REQUIRED_DOCUMENTS_IMAGE =
  'DEALER-INFORMATION/ADD_REQUIRED_DOCUMENTS_IMAGE';

export const ADD_OTHER_REQUIREMENTS_CALL_ONE =
  'DEALER-INFORMATION/ADD_OTHER_REQUIREMENTS_CALL_ONE';
export const ADD_OTHER_REQUIREMENTS_CALL_TWO =
  'DEALER-INFORMATION/ADD_OTHER_REQUIREMENTS_CALL_TWO';
export const ADD_APPLICATION = 'DEALER-INFORMATION/ADD_APPLICATION';

export const UPDATE_DEALER_COMPANY = 'DEALER-INFORMATION/UPDATE_DEALER_COMPANY';
export const UPDATE_DELIVERY_ADDRESS =
  'DEALER-INFORMATION/UPDATE_DELIVERY_ADDRESS';
export const UPDATE_MOA_SIGNATORIES =
  'DEALER-INFORMATION/UPDATE_MOA_SIGNATORIES';
export const UPDATE_REQUIRED_DOCUMENTS_IMAGE =
  'DEALER-INFORMATION/UPDATE_REQUIRED_DOCUMENTS_IMAGE';
export const UPDATE_APPLICATION = 'DEALER-INFORMATION/UPDATE_APPLICATION';
export const UPDATE_APPLCATION_STATUS =
  'DEALER-INFORMATION/UPDATE_APPLCATION_STATUS';

export const updateDealerDefaultValue = {
  is_express: true,
  email: '',
  first_name: '',
  last_name: '',
  mobile: '',
  updated_by: '',
  profile_img: '',
};

// VARIABLES
export const compoundSearchDefaultValue = {
  order_by: 'created_at',
  sort_by: 'desc',
  page: 1,
  per_page: 10,
  q: '',

  has_application: true,
  account_status: 'on-boarded',
  application_status: 'done,for-renewal',
  account_type_id: '',
};

export const statusOptions = [
  { value: 'done', label: 'ON-BOARDED' },
  { value: 'for-renewal', label: 'FOR RENEWAL' },
];

export const labelStyles = { fontSize: '.6rem' };
export const inputStyles = { fontSize: '.7rem' };

// export const tabsInfo = [
// 	{ slug: 'company-information', label: 'Company Information', account_status: 'all' },
// 	{ slug: 'account-information', label: 'Account Information', account_status: 'on-boarded'},
// 	{ slug: 'supporting-documents', label: 'Supporting Documents', account_status: 'all' },
// ]

export const tabsInfo = [
  { label: 'Company Information', slug: 'company-information' },
  { slug: 'account-information', label: 'Account Information' },
  { label: 'Supporting Documents', slug: 'supporting-documents' },
];

export const companyInfoDropdownOptions = [
  { value: 1, label: '1%' },
  { value: 2, label: '2%' },
];

export const companyUpdateDefaultValue = {
  is_active: false,
  land_line: '',
  company_name: '',
  email: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  tin_number: '',
  withholding_agent: '',

  unit_building_house_no: '',
  street_name: '',
  barangay: '',
  municipality: '',
  province: '',
  postal_no: '',
};

export const companyAddressCreateUpdateModalDefaultValue = {
  unit_building_house_no: '',
  street_name: '',
  barangay: '',
  municipality: '',
  province: '',
  postal_no: '',
  isSame: false,
};

export const moaSignatoryDefaultValue = {
  first_name: '',
  middle_name: '',
  last_name: '',
  contact_number: '',
  type: 'MEMORANDUM_SIGNATORY',
  management_account_step: 5,
};

export const onboardDefaultValue = {
  area_id: '',
  is_exclusive: true,

  contract: '',
  card_number: '',
  bank_name: '',
  bank_account_name: '',
  bank_account_number: '',
  po_effectively_date: '',
  po_expiration_date: '',
};
