export const GET_PROVIDER = 'LOAD/get_provider';
export const GET_PROMO = 'LOAD/get_promo';
export const SHOW_RETAILER = 'LOAD/show_retailer';
export const SEND_LOAD = 'LOAD/send_load';
export const INQUIRE_BALANCE = 'LOAD/inquire_balance';
export const GET_BRANCH = 'LOAD/get_branch';
export const MPIN = 'LOAD/mpin';
export const GET_DEALER_MIN = 'LOAD/get_dealer_min';
export const GET_DEALER_MIN_THRESHOLD = 'LOAD/get_dealer_min_threshold';
export const GET_DEALER_MIN_BALANCE = 'LOAD/get_dealer_min_balance';
export const LOAD_RETAILER_EXTERNAL = 'LOAD/load_retailer_external';
export const VALIDATE_MPIN = 'LOAD/validate_mpin';
export const PIN_STATUS = 'LOAD/pin_status';
export const FORM_DATA = {};
export const LOAD_PARTY_ID = process.env.REACT_APP_ACCESS_PARTY_ID || 166;
