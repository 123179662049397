import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

function AccountInfoForm({ onChange, formData, roleList, type }) {
  return (
    <>
      <div className="d-flex align-items-center mb-2 mt-2">
        <span className="flex-grow-1 mt-0 mb-0 font-weight-bold">
          Account Information:
        </span>
      </div>
      <div className="row">
        {formData.type !== "fu" && (
          <div className="col">
            <label className="form-label text-success">User Type:</label>
            <select
              autoFocus
              className="custom-select form-control form-custom form-control_bottom"
              name="role"
              required
              disabled={type === "update"}
              onChange={onChange("role_id")}
              value={_.get(formData, "role_id") || ""}
            >
              <option value="">Select Role</option>
              {roleList.map((role) => {
                const label = role?.attributes?.name;
                const shortLabel = label.replace(
                  /\s-\s(rd|ka|ska|smmt|b2b|non migrated)/i,
                  ""
                );

                return (
                  <option key={role.id} value={role.id}>
                    {shortLabel}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div className="col">
          <label className="form-label text-success">User Name:</label>
          <input
            type="text"
            placeholder=""
            maskChar=""
            disabled={type === "update"}
            className="form-control form-custom form-control_bottom"
            name="username"
            required
            onChange={onChange("username")}
            value={_.get(formData, "username") || ""}
          />
        </div>
        <div className="col">
          <label className="form-label text-success">First Name:</label>
          <input
            type="text"
            className="form-control form-custom form-control_bottom"
            value={_.get(formData, "first_name") || ""}
            onChange={onChange("first_name")}
          />
        </div>
        <div className="col">
          <label className="form-label text-success">Last Name:</label>
          <input
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={_.get(formData, "last_name") || ""}
            onChange={onChange("last_name")}
          />
        </div>
      </div>
    </>
  );
}

AccountInfoForm.defaultProps = {
  roleList: [],
};

AccountInfoForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
  roleList: PropTypes.instanceOf(Array),
};

export default AccountInfoForm;
