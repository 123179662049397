import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import PropTypes from 'prop-types';

const localizer = momentLocalizer(moment);

export const newToolbar = () => {
  return '';
};

function CalendarSchedule({ eventList }) {
  return (
    <React.Fragment>
      <Calendar
        localizer={localizer}
        defaultView="week"
        views={['week']}
        components={{ toolbar: newToolbar }}
        events={eventList}
        style={{
          minWidth: '900px',
          width: '100%',
        }}
      />
    </React.Fragment>
  );
}

CalendarSchedule.propTypes = {
  eventList: PropTypes.instanceOf(Array),
};

CalendarSchedule.defaultProps = {
  eventList: [],
};

export default CalendarSchedule;
