import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import placeholderPhoto from 'assets/images/logo-loader.png';
import { ModalContext } from 'App';
import Img from 'modules/common/components/ImgDoc';
import { useLoading } from 'modules/ui/hooks';
import UpdateDealer from '../../../modal/UpdateDealer';

import * as c from '../../../constants';

const UserAccessDetails = ({ dealer_id }) => {
  const setModal = useContext(ModalContext);
  const userDetails = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_USER_ACCESS_DETAILS}.item`)
  );

  const isLoading = useLoading(c.SHOW_USER_ACCESS_DETAILS || '');

  const isAllowed = useMemo(() => {
    const applicationnStatus = _.get(
      userDetails,
      'attributes.application_status'
    );
    return applicationnStatus === null || applicationnStatus === 'returned';
  }, [userDetails]);

  const openUpdateModal = () => {
    const previousData = {
      dealer_id,
      updated_by: _.get(userDetails, 'attributes.updated_by', ''),
      first_name: _.get(userDetails, 'attributes.first_name', ''),
      last_name: _.get(userDetails, 'attributes.last_name', ''),
      email: _.get(userDetails, 'attributes.email', ''),
      mobile: _.get(userDetails, 'attributes.mobile', ''),
      profile_img: _.get(userDetails, 'attributes.profile_img', ''),
      is_express: _.get(userDetails, 'attributes.is_express'),
    };

    setModal('update-dealer', previousData);
  };

  return (
    <>
      {isLoading ? (
        <div className="w-100 my-3" style={{ fontSize: 25 }}>
          <div className="bg-light-0 py-3 text-center w-100">
            <span className="fas fa-1x fa-spin fa-spinner" />
          </div>
        </div>
      ) : (
        <div className="UserAccessDetails my-4">
          <header className="rounded-top-both bg-grey-800 overflow-hidden text-white d-flex justify-content-between pl-3">
            <div
              className="header-title my-auto"
              style={{ padding: `${!isAllowed ? '.7rem' : ''} 0` }}
            >
              <h6 className="h6 text-xs font-weight-bold p-0 m-0">
                User Access Details
              </h6>
            </div>
            {isAllowed && (
              <div>
                <button
                  type="button"
                  className="btn text-xs btn-success rounded-0 py-2"
                  onClick={openUpdateModal}
                  disabled={isLoading}
                >
                  <i className="fas fa-pen mr-2" />
                  Update
                </button>
              </div>
            )}
          </header>
          <div className="p-4 bg-white">
            <div className="row">
              <div className="mr-4 ml-3" style={{ width: '15vw' }}>
                <figure
                  className="border d-flex align-items-center justify-content-center cursor-pointer w-100"
                  style={{ height: '10rem' }}
                  aria-hidden="true"
                >
                  <Img
                    lightBox
                    withZoom
                    defaultSrc={placeholderPhoto}
                    src={_.get(userDetails, 'attributes.profile_img')}
                  />
                </figure>
              </div>
              <div className="col-sm-7 col-xl-5 text-light-gray ">
                <div className="row mb-4">
                  <div className="col-6 d-flex flex-column text-xs text-grey-1000">
                    <span className="label font-weight-bold mb-2">
                      First Name
                    </span>
                    <p className="description mb-0 break-word">
                      {_.get(userDetails, 'attributes.first_name', '')}
                    </p>
                  </div>
                  <div className="col-6 d-flex flex-column text-xs text-grey-1000">
                    <span className="label font-weight-bold mb-2">
                      Last Name
                    </span>
                    <p className="description mb-0 break-word">
                      {_.get(userDetails, 'attributes.last_name', '')}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 d-flex flex-column text-xs text-grey-1000">
                    <span className="label font-weight-bold mb-2">
                      Email Address
                    </span>
                    <p
                      className="description mb-0"
                      style={{
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                      }}
                    >
                      {_.get(userDetails, 'attributes.email', '')}
                    </p>
                  </div>
                  <div className="col-6 d-flex flex-column text-xs text-grey-1000">
                    <span className="label font-weight-bold mb-2">
                      Mobile No.
                    </span>
                    <p className="description mb-0 break-word">
                      {_.get(userDetails, 'attributes.mobile', '')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <UpdateDealer />
    </>
  );
};

UserAccessDetails.propTypes = {
  dealer_id: PropTypes.string.isRequired,
};

export default React.memo(UserAccessDetails);
