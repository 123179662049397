import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { inquireBalance } from 'app/dashboard/actions';
import { SHOW_RETAILER } from 'app/dashboard/constant';
import Threshold from 'modules/Load/components/LoadThreshold';

const WalletBalance = () => {
  const dispatch = useDispatch();
  const [wallet, setWallet] = useState();
  const [loading, setLoading] = useState(false);
  const retailer = useSelector(({ api }) =>
    _.get(api, `${SHOW_RETAILER}.item`, {})
  );

  useEffect(() => {
    setLoading(true);
    if (_.isEmpty(retailer)) return;
    const value = _.get(
      retailer,
      'attributes.mobile_identification_number',
      ''
    );
    dispatch(
      inquireBalance({ mobile: value.replace('+63', '0') }, res => {
        setWallet(_.get(res, 'data.current_balance'));
        setLoading(false);
      })
    );
  }, [retailer]);

  return (
    <div className="card shadow-sm">
      <div className="card-header bg-primary">
        <small className="card-title m-0 text-white font-weight-bold">
          Wallet Balance:
        </small>
      </div>
      <div className="card-body">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-12">
              <small className="text-primary mb-1 font-weight-bold">
                Retailer Load Wallet Balance:
              </small>
              <div className="form-group">
                <label className="form-label text-primary">Retailer MIN:</label>
                <input
                  className="form-control input-textbox form-custom form-control_bottom"
                  type="text"
                  required
                  readOnly
                  value={_.get(
                    retailer,
                    'attributes.mobile_identification_number',
                    ''
                  )}
                />
              </div>
              <div className="form-group d-flex align-items-center justify-content-between">
                <label className="form-label text-primary m-0">Balance:</label>
                <h6 className="title m-0 font-weight-bold">
                  <small>PHP</small> {wallet || 0}
                </h6>
              </div>
              <div className="form-group">
                <label className="form-label text-primary m-0">
                  Load Wallet Threshold:
                </label>
                <Threshold
                  loading={loading}
                  threshold={_.get(retailer, 'attributes.load_threshold')}
                  balance={wallet}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletBalance;
