import React, { useEffect } from 'react';
import { withRouter, useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../action';
import * as c from '../../constant';

function IssueCategoryContainer({
  getIssue,
  issue,
  showIssueCategory,
  showCategory,
  id,
}) {
  const history = useHistory();
  const match = useRouteMatch();
  const handleIssue = data => e => {
    e.preventDefault();
    history.push(`${match.url}/${_.get(data, 'id')}`);
  };

  useEffect(() => {
    getIssue(id);
    showIssueCategory(id);
  }, [getIssue, showIssueCategory, id]);

  return (
    <>
      <div className="container-fluid">
        <div className="text-left mr-auto">
          <div className="h4 text-primary mt-0">
            {_.get(showCategory, 'attributes.name') || '--'}
          </div>
          <h2 className="h5 font-weight-normal mt-0 text-muted">
            {_.get(showCategory, 'attributes.description') || '--'}
          </h2>
        </div>
      </div>
      <div className="container-fluid px-5">
        <h5>What can we help you with?</h5>
        {issue.length > 0 ? (
          <>
            {issue.map(x => (
              <button
                type="button"
                className="btn btn-default btn-block btn-helpdesk p-4 mb-3 shadow-sm text-left"
                onClick={handleIssue(x)}
                key={x.id}
              >
                <div className="text-success">
                  {_.get(x, 'attributes.name')}
                </div>

                <div className="text-muted text-normal font-weight-normal">
                  {_.get(x, 'attributes.description')}
                </div>
              </button>
            ))}
          </>
        ) : (
          <div className="text-center mt-1 mb-2">
            <span className="badge badge-warning">
              There are no Items Found.
            </span>
          </div>
        )}
      </div>
    </>
  );
}

IssueCategoryContainer.propTypes = {
  showCategory: PropTypes.instanceOf(Object).isRequired,
  issue: PropTypes.instanceOf(Array).isRequired,
  showIssueCategory: PropTypes.func.isRequired,
  getIssue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

const mapStateToProps = ({ api }, { match }) => ({
  showCategory: _.get(api, `${c.SHOW_ISSUE_CATEGORY}.item`) || {},
  issue: _.get(api, `${c.GET_ISSUE}.list`) || [],
  id: _.get(match, 'params.id') || null,
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(IssueCategoryContainer);
