import React, { useState, useEffect, useMemo, useContext } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import ReactInputMask from 'react-input-mask';
import { useSelector, useDispatch } from 'react-redux';

import { ModalContext } from 'App';
import { ToastSuccess } from 'modules/common/components/Toast';
import { getMaskContactNo, removeEmpty, middleNameValidator } from 'helper';
import { useLoading } from 'modules/ui/hooks';
import withModal from 'modules/common/hoc/withModal';
import FormInputSwitch from 'modules/common/components/input/FormInputSwitch';
import FormInputBorderBottom from 'modules/common/components/input/FormInputBorderBottom';
import ButtonAsync from 'components/Button';

import * as c from '../constants';
import * as actions from '../actions';

const style = {
  control: base => ({
    ...base,
    border: 0,
    borderBottom: '1px solid gray',
    borderRadius: 0,
    // This line disable the blue border
    boxShadow: 'none',
  }),
};

const CompanyInformationUpdate = () => {
  const setModal = useContext(ModalContext);
  const dispatch = useDispatch();

  const modalData = useSelector(({ modal }) => _.get(modal, 'modalData', {}));
  const provinceList = useSelector(({ api }) =>
    _.get(api, `${c.GET_PROVINCE_LIST}.list`, [])
  );
  const cityList = useSelector(({ api }) =>
    _.get(api, `${c.GET_CITY_LIST}.list`, [])
  );
  const barangayList = useSelector(({ api }) =>
    _.get(api, `${c.GET_BARANGAY_LIST}.list`, [])
  );

  const userProfile = useSelector(({ api }) =>
    _.get(api, 'AUTH/user_profile.item')
  );

  const [initialData, setInitialData] = useState(
    c.companyInfoCreateUpdateModalDefaultValue
  );

  const [pending, setPending] = useState(false);

  const updated_by = _.get(userProfile, 'full_name');
  const companyInfo = _.get(modalData, 'companyInfo', '');
  const company_id = _.get(modalData, 'companyInfo.id', '');
  const dealer_id = _.get(modalData, 'dealer_id', '');

  const isInputEmpty = useMemo(() => {
    const {
      land_line,
      company_name,
      email,
      first_name,
      last_name,
      tin_number,
      unit_building_house_no,
      street_name,
      barangay,
      municipality,
      province,
      postal_no,
    } = initialData;

    return (
      _.isEmpty(land_line) ||
      _.isEmpty(company_name) ||
      _.isEmpty(email) ||
      _.isEmpty(first_name) ||
      _.isEmpty(last_name) ||
      _.isEmpty(tin_number) ||
      _.isEmpty(unit_building_house_no) ||
      _.isEmpty(_.get(municipality, 'value', '')) ||
      _.isEmpty(_.get(province, 'value', '')) ||
      _.isEmpty(_.get(barangay, 'value', '')) ||
      _.isEmpty(street_name) ||
      _.isEmpty(postal_no)
    );
  }, [initialData]);

  const provinceOptions = useMemo(
    () =>
      provinceList.map(({ attributes: { name, code } }) => ({
        value: code,
        label: name,
      })),
    [provinceList]
  );

  const cityOptions = useMemo(
    () =>
      cityList.map(({ attributes: { name, code } }) => ({
        value: code,
        label: name,
      })),
    [cityList]
  );

  const barangayOptions = useMemo(
    () =>
      barangayList.map(({ attributes: { name, code, zip } }) => ({
        value: code,
        label: name,
        zip,
      })),
    [barangayList]
  );

  const handleCloseModal = () => setModal({ isOpen: false });

  const handleMiddleName = ({ target: { value } }) => {
    if (middleNameValidator(value) || value === '') {
      setInitialData({
        ...initialData,
        middle_name: value,
      });
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setInitialData({
      ...initialData,
      [name]: value,
    });
  };

  const handleWithHoldingAgent = value =>
    setInitialData({
      ...initialData,
      withholding_agent: value,
    });

  const handleProvince = value => {
    setInitialData({
      ...initialData,
      province: value,
      municipality: '',
      barangay: '',
      postal_no: '',
    });

    if (value) {
      dispatch(actions.getCityList(value.value));
      dispatch(actions.resetBarangay());
    }
  };

  const handleMunicipality = value => {
    setInitialData({
      ...initialData,
      municipality: value,
      barangay: '',
      postal_no: '',
    });
  };

  const handleBarangay = value =>
    setInitialData({
      ...initialData,
      barangay: value,
      postal_no: _.get(value, 'zip'),
    });

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);

    const payload = {
      ...initialData,
      dealer_id,
      land_line: _.get(initialData, 'land_line'),
      withholding_agent: _.get(initialData, 'withholding_agent.value') || 0,
      barangay_name: _.get(initialData, 'barangay.label'),
      barangay_code: _.get(initialData, 'barangay.value'),
      municipality_name: _.get(initialData, 'municipality.label'),
      municipality_code: _.get(initialData, 'municipality.value'),
      province_name: _.get(initialData, 'province.label'),
      province_code: _.get(initialData, 'province.value'),
      updated_by,
      is_active: '',
      barangay: '',
      municipality: '',
      province: '',
    };

    const successCallback = () => {
      dispatch(actions.showCompanyInformation(dealer_id));
      handleCloseModal();
      setPending(false);
      if (company_id) ToastSuccess('Successfully Updated Company Information.');
      else ToastSuccess('Successfully Created Company Information.');
    };

    const errCallback = () => setPending(false);

    if (company_id)
      dispatch(
        actions.updateDealerCompany(
          dealer_id,
          payload,
          successCallback,
          errCallback
        )
      );
    else
      dispatch(
        actions.createDealerCompany(
          removeEmpty(payload),
          successCallback,
          errCallback
        )
      );
  };

  useEffect(() => {
    if (_.get(initialData, 'province.value'))
      dispatch(actions.getCityList(_.get(initialData, 'province.value')));
  }, [initialData.province]);

  useEffect(() => {
    const provinceCode = _.get(initialData, 'province.value', '');
    const cityCode = _.get(initialData, 'municipality.value', '');
    if (!provinceCode) {
      dispatch(actions.resetCity());
    }

    if (provinceCode && cityCode) {
      dispatch(actions.getBarangayList(provinceCode, cityCode));
    } else {
      dispatch(actions.resetBarangay());
    }
  }, [initialData.province, initialData.municipality]);

  useEffect(() => {
    dispatch(actions.getProvinceList());

    return () => {
      if (!company_id) {
        dispatch(actions.resetCity());
        dispatch(actions.resetBarangay());
      }
    };
  }, []);

  useEffect(() => {
    if (company_id) {
      setInitialData({
        is_active:
          _.get(companyInfo, 'attributes.withholding_agent') !== '0' &&
          _.get(companyInfo, 'attributes.withholding_agent') !== null
            ? 1
            : 0,
        land_line: _.get(companyInfo, 'attributes.land_line'),
        company_name: _.get(companyInfo, 'attributes.company_name'),
        email: _.get(companyInfo, 'attributes.email'),
        first_name: _.get(companyInfo, 'attributes.first_name'),
        middle_name: _.get(companyInfo, 'attributes.middle_name'),
        last_name: _.get(companyInfo, 'attributes.last_name'),
        tin_number: _.get(companyInfo, 'attributes.tin_number'),
        withholding_agent:
          _.get(companyInfo, 'attributes.withholding_agent') !== '0' &&
          _.get(companyInfo, 'attributes.withholding_agent') !== null
            ? {
                value: Number(
                  _.get(companyInfo, 'attributes.withholding_agent')
                ),
                label: `${Number(
                  _.get(companyInfo, 'attributes.withholding_agent')
                )}%`,
              }
            : '',
        province: {
          label: _.get(companyInfo, 'attributes.province_name'),
          value: _.get(companyInfo, 'attributes.province_code'),
        },
        municipality: {
          label: _.get(companyInfo, 'attributes.municipality_name'),
          value: _.get(companyInfo, 'attributes.municipality_code'),
        },
        barangay: {
          label: _.get(companyInfo, 'attributes.barangay_name'),
          value: _.get(companyInfo, 'attributes.barangay_code'),
        },
        unit_building_house_no: _.get(
          companyInfo,
          'attributes.unit_building_house_no'
        ),
        street_name: _.get(companyInfo, 'attributes.street_name'),
        postal_no: _.get(companyInfo, 'attributes.postal_no'),
      });
    }
  }, [companyInfo]);

  const onWithHoldingAgent = value => {
    if (!value) {
      setInitialData(state => ({ ...state, is_active: +value ? 0 : 1 }));
      return;
    }
    setInitialData(state => ({
      ...state,
      is_active: +value ? 0 : 1,
      withholding_agent: '',
    }));
  };

  return (
    <div className="p-2">
      <div className="mb-2">
        <h6 className="h6 m-0 text-xs font-weight-bold">Company Details</h6>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-4 ">
            <FormInputBorderBottom
              label="Company Name *"
              name="company_name"
              value={_.get(initialData, 'company_name', '')}
              onChange={handleChange}
            />
          </div>

          <div className="col-4 ">
            <FormInputBorderBottom
              label="Company Email Address *"
              name="email"
              type="email"
              value={_.get(initialData, 'email', '')}
              onChange={handleChange}
            />
          </div>

          <div className="col-4 mb-2">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Contact No. *<br />
              <span className="font-weight-normal">
                (Mobile: 09XXXXXXXXXX / Landline: 02-XXXX-XXXX)
              </span>
            </label>
            <ReactInputMask
              required
              mask={getMaskContactNo(_.get(initialData, 'land_line', ''))}
              maskChar=""
              className="form-control form-custom form-control_bottom px-3"
              name="land_line"
              value={_.get(initialData, 'land_line', '')}
              onChange={handleChange}
              placeholder="Enter..."
              style={c.inputStyles}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-4 mb-2">
            <FormInputBorderBottom
              label="First Name *"
              name="first_name"
              value={_.get(initialData, 'first_name', '')}
              onChange={handleChange}
            />
          </div>

          <div className="col-4 ">
            <FormInputBorderBottom
              label="Middle Initial"
              name="middle_name"
              value={_.get(initialData, 'middle_name', '')}
              onChange={handleMiddleName}
              required={false}
            />
          </div>

          <div className="col-4 ">
            <FormInputBorderBottom
              label="Last Name *"
              name="last_name"
              value={_.get(initialData, 'last_name', '')}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-4 mb-2">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              TIN No: *
            </label>
            <ReactInputMask
              type="text"
              mask="999-999-999-99999"
              placeholder="111-111-111-11111"
              maskChar=""
              className="form-control form-custom form-control_bottom"
              name="tin_number"
              required
              value={_.get(initialData, 'tin_number', '')}
              onChange={handleChange}
              style={c.inputStyles}
            />
          </div>

          <div className="col-4">
            <div className="d-flex align-items-center justify-content-between mt-2">
              <label
                className="form-label font-weight-bold text-dark mb-0"
                style={c.labelStyles}
              >
                WithHolding Agent:
              </label>
              <FormInputSwitch
                containerClassName="form-check d-flex justify-content-start align-items-center m-0 ml-4"
                className="form-check-input toggle-switch m-0"
                type="checkbox"
                withLabel={false}
                name="is_active"
                value={_.get(initialData, 'is_active') ? 1 : 0}
                onGetValue={onWithHoldingAgent}
              />
            </div>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="withholding_agent"
              required
              options={c.companyInfoDropdownOptions}
              onChange={handleWithHoldingAgent}
              value={_.get(initialData, 'withholding_agent', '')}
              styles={style}
              isLoading={false}
              isDisabled={!_.get(initialData, 'is_active')}
            />
          </div>
        </div>

        <div className="mb-2">
          <h6 className="h6 m-0 text-xs font-weight-bold">
            Complete Company Address
          </h6>
        </div>
        <div className="row">
          <div className="col-4 mb-2">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Province *
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="province"
              required
              styles={style}
              options={provinceOptions}
              value={_.get(initialData, 'province', '')}
              onChange={handleProvince}
              isLoading={useLoading(c.GET_PROVINCE_LIST)}
              isDisabled={_.isEmpty(provinceOptions)}
              isClearable
            />
          </div>

          <div className="col-4 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              City /Municipality *
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="municipality"
              required
              options={cityOptions}
              value={_.get(initialData, 'municipality', '')}
              onChange={handleMunicipality}
              styles={style}
              isLoading={useLoading(c.GET_CITY_LIST)}
              isClearable
              isDisabled={
                _.isEmpty(_.get(initialData, 'municipality.value')) &&
                _.isEmpty(cityOptions)
              }
            />
          </div>

          <div className="col-4 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Barangay *
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="barangay"
              required
              isClearable
              styles={style}
              options={barangayOptions}
              value={_.get(initialData, 'barangay', '')}
              onChange={handleBarangay}
              isLoading={useLoading(c.GET_BARANGAY_LIST)}
              isDisabled={_.isEmpty(barangayOptions)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-4 mb-2">
            <FormInputBorderBottom
              label="Unit No./ Building No./ House No. *"
              name="unit_building_house_no"
              value={_.get(initialData, 'unit_building_house_no', '')}
              onChange={handleChange}
            />
          </div>

          <div className="col-4 ">
            <FormInputBorderBottom
              label="Street Name *"
              name="street_name"
              value={_.get(initialData, 'street_name', '')}
              onChange={handleChange}
            />
          </div>

          <div className="col-4 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              ZIP Code *
            </label>
            <ReactInputMask
              required
              mask="9999999999"
              maskChar=""
              className="form-control form-custom form-control_bottom px-3"
              name="postal_no"
              value={_.get(initialData, 'postal_no', '')}
              disabled
              placeholder="Enter..."
              style={c.inputStyles}
            />
          </div>
        </div>

        <div className="button-container d-flex justify-content-end mt-4">
          <ButtonAsync
            className="btn btn-primary position-relative py-2 px-4 mr-2"
            type="submit"
            loading={pending}
            disabled={isInputEmpty}
          >
            Save
          </ButtonAsync>

          <button
            type="button"
            className="btn bg-gray-300 text-white py-2 px-4"
            onClick={handleCloseModal}
            disabled={pending}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

const modalKey = 'company-information-update';
const modalConfig = {
  title: 'Company Information',
  size: 'modal-lg',
};

export default withModal(modalKey, modalConfig)(CompanyInformationUpdate);
