import { useMemo } from "react";
import { ToastError } from "modules/common/components/Toast";
import _ from "lodash";
import * as c from "./constant";

export function useSimTypeParams(dealerSimsAll, accountTypeName, dealerRole) {
  const createSimTypeParams = useMemo(() => {
    const parentSIMTypes = c.PARENT_SIM_TYPES;

    const hasMdSim = !_.isEmpty(
      dealerSimsAll?.find((sim) => sim?.attributes?.type?.includes("MD"))
    );
    const hasDSPSim = !_.isEmpty(
      dealerSimsAll?.find(
        (sim) =>
          sim?.attributes?.type?.includes("DSP") &&
          sim?.attributes?.status?.includes("Approved")
      )
    );

    const parentSIM = dealerSimsAll?.find((sim) =>
      parentSIMTypes.includes(sim?.attributes?.type)
    );
    const hasParentSIM = dealerSimsAll?.some((sim) =>
      parentSIMTypes.includes(sim?.attributes?.type)
    );
    const isParentStatusApproved = parentSIM?.attributes?.status?.includes(
      "Approved"
    );
    const accountTypeNameUpperCase = accountTypeName?.toUpperCase();

    return {
      user_type: dealerRole,
      ...(dealerRole === c.DEALER_SIM_ROLES.smmt
        ? { has_dsp: hasDSPSim }
        : {
            has_md: hasMdSim,
            ...(!_.isEmpty(dealerSimsAll) &&
            hasParentSIM &&
            isParentStatusApproved
              ? { type: parentSIM?.attributes?.type }
              : {
                  type: accountTypeNameUpperCase?.includes("DISTRIBUTOR")
                    ? "RD"
                    : "KA",
                }),
          }),
    };
  }, [dealerSimsAll, accountTypeName, dealerRole]);

  return createSimTypeParams;
}
export const useValidateNumber = (min, callback, errorCallback) => (e) => {
  e.preventDefault();
  if (_.startsWith(min, "+639") && _.isEqual(min.length, 13)) {
    callback();
  } else {
    ToastError("Mobile number format is invalid");
    errorCallback();
  }
};
