import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useParams, Link } from "react-router-dom";
import { ToastError, ToastWarn } from "modules/common/components/Toast";
import ErrorMessage from "modules/common/components/ErrorMessage";
import { removeEmpty } from "helper";
import AvailableRetailers from "./AvailableRetailers";
import SelectedRetailers from "./SelectedRetailers";
import TransferSelectSetup from "./TransferSelectSetup";
import Filter from "../components/Filter";
import {
  getAgent,
  getAgentMins,
  getAgentRetailers,
  transferAgentRetailers,
} from "../actions";

const RetailerTransferSetup = () => {
  const [params, setParams] = useState({
    q: "",
    retailer_type: "",
  });
  const [selected, setSelected] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [targetAgent, setTargetAgent] = useState();

  const queryClient = useQueryClient();
  const urlParams = useParams();
  const agentId = _.get(urlParams, "agentId");

  const agentMinQuery = useQuery({
    queryKey: ["agent-mins", { agentId }],
    queryFn: getAgentMins,
    enabled: !!agentId,
  });
  const dsp = agentMinQuery?.data?.data;
  const simId = dsp?.id;

  const targetAgentMinQuery = useQuery({
    queryKey: ["agent-mins", { agentId: targetAgent?.id }],
    queryFn: getAgentMins,
    enabled: !!targetAgent,
  });

  const transfer_to_dealer_sim_id = targetAgentMinQuery?.data?.data?.id;

  const apiRetailers = useQuery({
    queryKey: [
      "retailers-listing",
      simId,
      removeEmpty({
        ...params,
        evc_status:
          "transfer-done,transfer-failed,delink-failed,delink-done,link-done,link-failed",
        per_page: 999,
      }),
    ],
    queryFn: getAgentRetailers,
    enabled: !!simId,
  });

  const apiAgent = useQuery({
    queryKey: ["agent-info", agentId],
    queryFn: getAgent,
    retry: false,
    keepPreviousData: true,
  });
  const agentData = _.get(apiAgent, "data.data");
  const agent = {
    min: dsp?.attributes?.mobile_identification_number || "",
    name: agentData?.attributes?.full_name || "",
  };

  const { mutate: transfer, isLoading: isTransferring } = useMutation(
    transferAgentRetailers,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("retailers-listing");
        setRetailers([]);
        ToastWarn("Retailer transfer is now in progress.");
      },
      onError: (err) => {
        const errorMessage = (
          <ErrorMessage err={err} defaultMessage="Retailer transfer failed." />
        );
        ToastError(errorMessage);
      },
    }
  );

  const retailerData = apiRetailers?.data?.data || [];

  const retailer_ids = useMemo(() => _.map(retailers, (e) => e.id), [
    retailers,
  ]);

  const onTransfer = () => {
    transfer({ simId, transfer_to_dealer_sim_id, retailer_ids });
  };

  const invert = (id) => {
    const up = _.update(selected, id, () => !selected[id]);
    setSelected({ ...up });
  };

  const onSelect = (data) => {
    invert(data.id);
    setRetailers((state) => [data, ...state]);
  };

  const onSelectAll = () => {
    setSelected(_.mapValues({ ...selected }, () => true));
    setRetailers((state) =>
      _.concat(_.differenceBy(retailerData, [...state], "id"), [...state])
    );
  };

  const onClear = ({ id }) => {
    invert(id);
    setRetailers(_.filter([...retailers], (elem) => elem.id !== id));
  };

  const onClearAll = () => {
    setSelected(_.mapValues({ ...selected }, () => false));
    setRetailers([]);
  };

  const disableTransfer = useMemo(() => {
    return !targetAgent || _.isEmpty(retailers);
  }, [targetAgent, retailers]);

  useEffect(() => {
    if (_.isEmpty(retailerData)) return;
    setSelected(
      _.mapValues(
        _.mapKeys(retailerData, "id"),
        ({ id }) => _.some(retailers, (e) => e.id === id) || false
      )
    );
  }, [retailerData]);

  return (
    <div className="container-fluid h-100 bg-light-grey overflow-hidden">
      <div className="bg-white mx-1 my-3 py-3 px-4" style={{ height: "90vh" }}>
        <div>
          <h6 className="text-primary m-0">
            <Link
              to={{
                pathname: "/manage/agent-operation/",
                state: {
                  agentId,
                },
              }}
              className="text-green"
            >
              <i className="fas fa-arrow-left mr-2 green" /> Back to Agent
              Operations
            </Link>
          </h6>
          <h5 className="font-weight-bold m-0 my-3">Retailer Transfer Setup</h5>
        </div>
        <div className="row">
          <div className="col-6 border-right border-gray-200 pr-1">
            <div className="px-3 mt-4 mb-3">
              <span className="font-weight-bold mr-2">Transfer From:</span>
              <span className="font-weight-bold mr-3">{agent.min}</span>
              <span>{agent.name}</span>
            </div>
            <div className="px-3">
              <Filter
                values={params}
                loading={apiRetailers.isFetching}
                onChange={setParams}
              />
            </div>
            <AvailableRetailers
              apiData={apiRetailers}
              selected={{ original: selected, current: retailers }}
              onSelect={onSelect}
              onSelectAll={onSelectAll}
            />
          </div>
          <div className="col-6">
            <TransferSelectSetup
              onChange={setTargetAgent}
              onTransfer={onTransfer}
              loading={isTransferring}
              disabled={disableTransfer}
            />
            <SelectedRetailers
              data={retailers}
              onClear={onClear}
              onClearAll={onClearAll}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetailerTransferSetup;
