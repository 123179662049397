import React, { Fragment, useState, useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";
import DateInputPicker from "modules/common/components/DateInputPicker";
import ButtonAsync from "modules/common/components/ButtonAsync";
import _ from "lodash";
import SelectAsync from "components/SelectAsync";

const Filter = ({ onSubmit, filters, onChange, onClear, isLoading }) => {
  const [advance, setAdvance] = useState(false);

  const { params } = useRouteMatch();
  const isDateFromEmpty = _.isEmpty(filters?.date_from);
  const dealer_sim_id = params?.simId;
  const DateFrom = useMemo(() => {
    return (
      <>
        <DateInputPicker
          dateFormat="yyyy/MM/dd"
          placeholder="Date From"
          className="form-control form-custom form-control_bottom w-100"
          onChange={onChange("date_from")}
          value={_.get(filters, "date_from")}
          disabled={isLoading}
        />
      </>
    );
  }, [isLoading, filters?.date_from]);

  const DateTo = useMemo(() => {
    return (
      <>
        <DateInputPicker
          dateFormat="yyyy/MM/dd"
          placeholder="Date To"
          className="form-control form-custom form-control_bottom w-100"
          onChange={onChange("date_to")}
          value={_.get(filters, "date_to")}
          minDate={new Date(filters?.date_from)}
          disabled={isDateFromEmpty}
          maxDate={new Date()}
        />
      </>
    );
  }, [isDateFromEmpty, filters]);

  const handleToggle = () => {
    setAdvance(!advance);
  };
  return (
    <Fragment>
      <div className="row">
        <div className="col d-flex align-items-end">
          <div
            className="mr-2 mb-3"
            style={{
              minWidth: "170px",
            }}
          >
            <button
              type="button"
              className="btn btn-success btn-block btn-md"
              onClick={handleToggle}
            >
              Advance Filter
            </button>
          </div>
          <div className="form-group mr-4" style={{ width: "27.5rem" }}>
            <label className="form-label text-success">Search:</label>
            <input
              type="text"
              placeholder="Search..."
              className="form-control form-custom form-control_bottom w-100"
              value={_.get(filters, "q") || ""}
              onChange={onChange("q")}
            />
          </div>
          <div
            className="d-flex align-items-end mb-3"
            style={{ minWidth: "14.5rem" }}
          >
            <ButtonAsync
              type="button"
              className="btn btn-success btn-block btn-md mr-2"
              onClick={onSubmit}
              loading={isLoading}
            >
              Search
            </ButtonAsync>
            <ButtonAsync
              appendClass="btn-danger btn-block btn-md"
              onClick={onClear}
              loading={isLoading}
            >
              Clear All
            </ButtonAsync>
          </div>
        </div>
      </div>
      {advance && (
        <div className="row ">
          <div className="col-xl-2 col-lg-4 pr-0">
            <div className="form-group">
              <label className="form-label text-success">Date From:</label>
              {DateFrom}
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 pr-0">
            <div className="form-group">
              <label className="form-label text-success">Date To:</label>
              {DateTo}
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 pr-0">
            <div className="form-group">
              <label className="form-label text-success">User Name :</label>
              <input
                type="text"
                placeholder="Input Username..."
                className="custom-input form-control form-custom form-control_bottom w-100"
                name="created_by_username"
                value={_.get(filters, "created_by_username") || ""}
                onChange={onChange("created_by_username")}
              />
            </div>
          </div>
          {!params.simId ? (
            <Fragment>
              <div className="col-xl-2 col-lg-4 pr-0">
                <div className="form-group">
                  <label className="form-label text-success">Dealer Min:</label>
                  <SelectAsync
                    value={_.get(filters, "dealer_sim_min", null)}
                    api="/api/v1/dealer-sims/transfer-load/transactions/all-min"
                    name="dealer_sim_min"
                    transformer={({ data }) => {
                      return data.map((item) => ({
                        value: item?.attributes?.dealer_sim_min,
                        label: item?.attributes?.dealer_sim_min,
                      }));
                    }}
                    onChange={onChange("dealer_sim_min")}
                    isDisabled={isLoading}
                    params={{ type: "internal" }}
                  />
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 pr-0">
                <div className="form-group">
                  <label className="form-label text-success">
                    Source SIM Type:
                  </label>
                  <SelectAsync
                    value={_.get(filters, "dealer_sim_type", null)}
                    api="/api/v1/dealer-sims/transfer-load/transactions/all-min"
                    name="dealer_sim_type"
                    transformer={({ data }) => {
                      return data.map((item) => ({
                        value: item?.attributes?.dealer_sim_type,
                        label: item?.attributes?.dealer_sim_type,
                      }));
                    }}
                    onChange={onChange("dealer_sim_type")}
                    isDisabled={isLoading}
                    params={{
                      is_dealer_sim_type: 1,
                      type: "internal",
                      dealer_sim_id,
                    }}
                  />
                </div>
              </div>
            </Fragment>
          ) : null}
          <div className="col-xl-2 col-lg-4 pr-0">
            <div className="form-group">
              <label className="form-label text-success">Target Min:</label>
              <input
                type="text"
                placeholder="Input Target MIN..."
                className="custom-input form-control form-custom form-control_bottom w-100"
                name="target_min"
                value={_.get(filters, "target_min") || ""}
                onChange={onChange("target_min")}
              />
            </div>
          </div>
          <div className="col-xl-2 col-lg-4 pr-0">
            <div className="form-group">
              <label className="form-label text-success">
                Target SIM Type:
              </label>
              <SelectAsync
                value={_.get(filters, "target_sim_type", null)}
                api="/api/v1/dealer-sims/transfer-load/transactions/all-min"
                name="target_sim_type"
                transformer={({ data }) => {
                  return data.map((item) => ({
                    value: item?.attributes?.target_sim_type,
                    label: item?.attributes?.target_sim_type,
                  }));
                }}
                onChange={onChange("target_sim_type")}
                isDisabled={isLoading}
                params={{
                  is_target_sim_type: 1,
                  type: "internal",
                  dealer_sim_id,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

Filter.defaultProps = {
  isLoading: false,
};

Filter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool,
};

export default Filter;
