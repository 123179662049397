import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { ToastSuccess, ToastWarn } from 'modules/common/components/Toast';

import withModal from 'modules/common/hoc/withModal';
import { ModalContext } from 'App';
import { removeEmpty } from 'helper';
import placeholderPhoto from 'assets/images/upload-square-placeholder.png';
import ButtonAsync from 'components/Button';

import UploadFiles from '../../partners/components/UploadFileNew';

import * as c from '../constants';
import * as actions from '../actions';

const UpdateDealerAuthorizaion = () => {
  const setModal = React.useContext(ModalContext);
  const dispatch = useDispatch();
  const modalData = useSelector(({ modal }) => _.get(modal, 'modalData'));
  const dealer_company_id = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_COMPANY_INFORMATION}.item.id`)
  );
  const userProfile = useSelector(({ api }) =>
    _.get(api, 'AUTH/user_profile.item')
  );

  const dealer_id = useMemo(() => _.get(modalData, 'dealer_id'), [modalData]);
  const dealerInfo = useMemo(() => _.get(modalData, 'dealerInfo'), [modalData]);

  const [initialData, setInitialData] = useState([]);

  const updated_by = _.get(userProfile, 'full_name');

  const [pending, setPending] = useState(false);

  const handleCloseModal = () => setModal({ isOpen: false });

  const handleChangeFields = ({ target: { value, name } }, index) => {
    const newArray = [...initialData];
    newArray[index] = {
      ...newArray[index],
      [name]: value,
      type: 'DISTRIBUTOR_SIGNATORY',
    };
    setInitialData(newArray);
  };

  const handleUpload = (files, name, index) => {
    const newArray = [...initialData];
    newArray[index] = {
      ...newArray[index],
      [name]: _.get(files, '0'),
      type: 'DISTRIBUTOR_SIGNATORY',
    };

    setInitialData(newArray);
  };

  const isInputFieldsEmpty = useMemo(() => {
    const { first_name, last_name } = initialData[0] || '';
    const { upload_file_one, upload_file_two } = initialData[0] || {};
    return (
      _.isEmpty(first_name) ||
      _.isEmpty(last_name) ||
      _.isEmpty(upload_file_one) ||
      _.isEmpty(upload_file_two)
    );
  }, [initialData]);

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);

    const signatory = [
      {
        first_name: _.get(initialData, '0.first_name') || '',
        middle_name: _.get(initialData, '0.middle_name') || '',
        last_name: _.get(initialData, '0.last_name') || '',
        attach_image_1: _.get(initialData, '0.upload_file_one.file') || '',
        attach_name_1: _.get(initialData, '0.upload_file_one.name') || '',
        attach_image_2: _.get(initialData, '0.upload_file_two.file') || '',
        attach_name_2: _.get(initialData, '0.upload_file_two.name') || '',
        signatory_id: _.get(initialData, '0.signatory_id') || '',
        type: 'DISTRIBUTOR_SIGNATORY',
      },
      {
        first_name: _.get(initialData, '1.first_name') || '',
        middle_name: _.get(initialData, '1.middle_name') || '',
        last_name: _.get(initialData, '1.last_name') || '',
        attach_image_1: _.get(initialData, '1.upload_file_one.file') || '',
        attach_name_1: _.get(initialData, '1.upload_file_one.name') || '',
        attach_image_2: _.get(initialData, '1.upload_file_two.file') || '',
        attach_name_2: _.get(initialData, '1.upload_file_two.name') || '',
        signatory_id: _.get(initialData, '1.signatory_id') || '',
        type: 'DISTRIBUTOR_SIGNATORY',
      },
      {
        first_name: _.get(initialData, '2.first_name') || '',
        middle_name: _.get(initialData, '2.middle_name') || '',
        last_name: _.get(initialData, '2.last_name') || '',
        attach_image_1: _.get(initialData, '2.upload_file_one.file') || '',
        attach_name_1: _.get(initialData, '2.upload_file_one.name') || '',
        attach_image_2: _.get(initialData, '2.upload_file_two.file') || '',
        attach_name_2: _.get(initialData, '2.upload_file_two.name') || '',
        signatory_id: _.get(initialData, '2.signatory_id') || '',
        type: 'DISTRIBUTOR_SIGNATORY',
      },
    ];

    const validAuthorization = signatory.filter(x => {
      const removeEmptyField = _.omit(x, ['middle_name', 'signatory_id']);
      return Object.values(removeEmptyField).every(y => y !== '');
    });

    const withMissing = signatory.filter(x => {
      const removeEmptyField = _.omit(x, [
        'middle_name',
        'signatory_id',
        'type',
      ]);
      const allEmpty = Object.values(removeEmptyField).filter(y => y === '');
      const allWith = Object.values(removeEmptyField).filter(y => y !== '');
      return allEmpty.length > 0 && allWith.length > 0;
    });

    if (!_.isEmpty(withMissing)) {
      ToastWarn('Fill out the remaining required fields to continue.');
      setPending(false);
      return;
    }

    const payload = {
      dealer_id,
      updated_by,
      management_account_step: 5,
      signatories: removeEmpty(validAuthorization),
    };

    const successCallback = () => {
      dispatch(
        actions.showMoaSignatoryInformation(dealer_id, dealer_company_id)
      );
      handleCloseModal();
      setPending(false);

      if (!_.isEmpty(dealerInfo))
        ToastSuccess('Successfully Updated Dealer Authorization.');
      else ToastSuccess('Successfully Created Dealer Authorization.');
    };

    const errCallback = () => setPending(false);

    if (!_.isEmpty(dealerInfo[0]))
      dispatch(
        actions.updateMoaSignatories(
          dealer_company_id,
          removeEmpty(payload),
          successCallback,
          errCallback
        )
      );
    else
      dispatch(
        actions.createMoaSignatories(
          dealer_company_id,
          removeEmpty(payload),
          successCallback,
          errCallback
        )
      );
  };

  useEffect(() => {
    if (dealerInfo.length > 0) {
      const newArray = [...dealerInfo];

      dealerInfo.forEach((item, index) => {
        newArray[index] = {
          first_name: _.get(dealerInfo, `${index}.attributes.first_name`),
          middle_name: _.get(dealerInfo, `${index}.attributes.middle_name`),
          last_name: _.get(dealerInfo, `${index}.attributes.last_name`),
          upload_file_one: {
            file: _.get(dealerInfo, `${index}.attributes.attach_image_1`),
            name: _.get(dealerInfo, `${index}.attributes.attach_name_1`),
          },
          upload_file_two: {
            file: _.get(dealerInfo, `${index}.attributes.attach_image_2`),
            name: _.get(dealerInfo, `${index}.attributes.attach_name_2`),
          },
          signatory_id: _.get(dealerInfo, `${index}.id`),
        };
        setInitialData(newArray);
      });
    }
  }, [dealerInfo]);

  return (
    <div className="p-2 dealer-authorization-modal-container">
      <div className="mb-2">
        <h6 className="h6 text-xs font-weight-bold m-0">
          Authorized Representative
        </h6>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-4 pt-2 pl-1 pb-0 d-flex align-items-start">
            <div
              className="text-center col-5 p-0"
              style={{ fontSize: '.8rem' }}
            >
              <p className="text-center">ID Photo</p>
            </div>
            <div
              className="text-center col-7 p-0"
              style={{ fontSize: '.8rem' }}
            >
              <p className="text-center">E-Signature</p>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-5 pt-2 pl-1 d-flex align-items-start">
            <div className="text-center p-2 mr-2 bg-light-100">
              <UploadFiles
                fileType="image/*"
                onChange={handleUpload}
                fileName="upload_file_one"
                fileIndex={0}
                customButton={() => (
                  <div className="thumbnail upload-thumbnail">
                    <figure
                      className="img-container mx-auto"
                      style={{
                        width: '6rem',
                        height: '6rem',
                      }}
                    >
                      <img
                        src={
                          _.get(initialData, '0.upload_file_one.file') ||
                          placeholderPhoto
                        }
                        alt="Avatar"
                        className="w-100 h-100"
                      />
                    </figure>

                    <button
                      className="btn btn-success text-lowercase font-weight-normal px-4 mt-2"
                      type="button"
                      style={{ fontSize: '.7rem' }}
                    >
                      <span className="text-uppercase">U</span>pload
                    </button>
                  </div>
                )}
                className="p-0 border-0 text-center"
              />
            </div>

            <div className="text-center p-2 mr-2 bg-light-100">
              <UploadFiles
                fileType="image/*"
                onChange={handleUpload}
                fileName="upload_file_two"
                fileIndex={0}
                customButton={() => (
                  <div className="thumbnail upload-thumbnail">
                    <figure
                      className="img-container mx-auto"
                      style={{
                        width: '6rem',
                        height: '6rem',
                      }}
                    >
                      <img
                        src={
                          _.get(initialData, '0.upload_file_two.file') ||
                          placeholderPhoto
                        }
                        alt="Avatar"
                        className="w-100 h-100"
                      />
                    </figure>

                    <button
                      className="btn btn-success text-lowercase font-weight-normal px-4 mt-2"
                      type="button"
                      style={{ fontSize: '.7rem' }}
                    >
                      <span className="text-uppercase">U</span>pload
                    </button>
                  </div>
                )}
                className="p-0 border-0 text-center"
              />
            </div>
          </div>
          <div className="col row">
            <div className="col-6 mb-2">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                First Name *
              </label>
              <input
                type="text"
                name="first_name"
                className="form-control form-custom form-control_bottom px-3"
                placeholder="Enter..."
                autoFocus
                required
                style={c.inputStyles}
                value={_.get(initialData, '0.first_name', '') || ''}
                onChange={e => handleChangeFields(e, 0)}
              />
            </div>

            <div className="col-6 ">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                Middle Initial
              </label>
              <input
                type="text"
                name="middle_name"
                className="form-control form-custom form-control_bottom px-3"
                placeholder="Enter..."
                style={c.inputStyles}
                value={_.get(initialData, '0.middle_name', '') || ''}
                onChange={e => handleChangeFields(e, 0)}
              />
            </div>
            <div className="col">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                Last Name *
              </label>
              <input
                type="text"
                name="last_name"
                className="form-control form-custom form-control_bottom px-3"
                placeholder="Enter..."
                style={c.inputStyles}
                value={_.get(initialData, '0.last_name', '') || ''}
                onChange={e => handleChangeFields(e, 0)}
                required
              />
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-5 pt-2 pl-1 d-flex align-items-start">
            <div className="text-center p-2 mr-2 bg-light-100">
              <UploadFiles
                fileType="image/*"
                onChange={handleUpload}
                fileName="upload_file_one"
                fileIndex={1}
                customButton={() => (
                  <div className="thumbnail upload-thumbnail">
                    <figure
                      className="img-container mx-auto"
                      style={{
                        width: '6rem',
                        height: '6rem',
                      }}
                    >
                      <img
                        src={
                          _.get(initialData, '1.upload_file_one.file') ||
                          placeholderPhoto
                        }
                        alt="Avatar"
                        className="w-100 h-100"
                      />
                    </figure>
                    <button
                      className="btn btn-success text-lowercase font-weight-normal px-4 mt-2"
                      type="button"
                      style={{ fontSize: '.7rem' }}
                    >
                      <span className="text-uppercase">U</span>pload
                    </button>
                  </div>
                )}
                className="p-0 border-0 text-center"
              />
            </div>

            <div className="text-center p-2 mr-2 bg-light-100">
              <UploadFiles
                fileType="image/*"
                onChange={handleUpload}
                fileName="upload_file_two"
                fileIndex={1}
                customButton={() => (
                  <div className="thumbnail upload-thumbnail">
                    <figure
                      className="img-container mx-auto"
                      style={{
                        width: '6rem',
                        height: '6rem',
                      }}
                    >
                      <img
                        src={
                          _.get(initialData, '1.upload_file_two.file') ||
                          placeholderPhoto
                        }
                        alt="Avatar"
                        className="w-100 h-100"
                      />
                    </figure>

                    <button
                      className="btn btn-success text-lowercase font-weight-normal px-4 mt-2"
                      type="button"
                      style={{ fontSize: '.7rem' }}
                    >
                      <span className="text-uppercase">U</span>pload
                    </button>
                  </div>
                )}
                className="p-0 border-0 text-center"
              />
            </div>
          </div>
          <div className="col row">
            <div className="col-6 mb-2">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                First Name
              </label>
              <input
                type="text"
                name="first_name"
                className="form-control form-custom form-control_bottom px-3"
                placeholder="Enter..."
                autoFocus
                style={c.inputStyles}
                value={_.get(initialData, '1.first_name', '') || ''}
                onChange={e => handleChangeFields(e, 1)}
              />
            </div>

            <div className="col-6 ">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                Middle Initial
              </label>
              <input
                type="text"
                name="middle_name"
                className="form-control form-custom form-control_bottom px-3"
                placeholder="Enter..."
                style={c.inputStyles}
                value={_.get(initialData, '1.middle_name', '') || ''}
                onChange={e => handleChangeFields(e, 1)}
              />
            </div>
            <div className="col">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                Last Name
              </label>
              <input
                type="text"
                name="last_name"
                className="form-control form-custom form-control_bottom px-3"
                placeholder="Enter..."
                style={c.inputStyles}
                value={_.get(initialData, '1.last_name', '') || ''}
                onChange={e => handleChangeFields(e, 1)}
              />
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-5 pt-2 pl-1 d-flex align-items-start">
            <div className="text-center p-2 mr-2 bg-light-100">
              <UploadFiles
                fileType="image/*"
                onChange={handleUpload}
                fileName="upload_file_one"
                fileIndex={2}
                customButton={() => (
                  <div className="thumbnail upload-thumbnail">
                    <figure
                      className="img-container mx-auto"
                      style={{
                        width: '6rem',
                        height: '6rem',
                      }}
                    >
                      <img
                        src={
                          _.get(initialData, '2.upload_file_one.file') ||
                          placeholderPhoto
                        }
                        alt="Avatar"
                        className="w-100 h-100"
                      />
                    </figure>

                    <button
                      className="btn btn-success text-lowercase font-weight-normal px-4 mt-2"
                      type="button"
                      style={{ fontSize: '.7rem' }}
                    >
                      <span className="text-uppercase">U</span>pload
                    </button>
                  </div>
                )}
                className="p-0 border-0 text-center"
              />
            </div>

            <div className="text-center p-2 mr-2 bg-light-100">
              <UploadFiles
                fileType="image/*"
                onChange={handleUpload}
                fileName="upload_file_two"
                fileIndex={2}
                customButton={() => (
                  <div className="thumbnail upload-thumbnail">
                    <figure
                      className="img-container mx-auto"
                      style={{
                        width: '6rem',
                        height: '6rem',
                      }}
                    >
                      <img
                        src={
                          _.get(initialData, '2.upload_file_two.file') ||
                          placeholderPhoto
                        }
                        alt="Avatar"
                        className="w-100 h-100"
                      />
                    </figure>

                    <button
                      className="btn btn-success text-lowercase font-weight-normal px-4 mt-2"
                      type="button"
                      style={{ fontSize: '.7rem' }}
                    >
                      <span className="text-uppercase">U</span>pload
                    </button>
                  </div>
                )}
                className="p-0 border-0 text-center"
              />
            </div>
          </div>
          <div className="col row mb-5">
            <div className="col-6 mb-2">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                First Name
              </label>
              <input
                type="text"
                name="first_name"
                className="form-control form-custom form-control_bottom px-3"
                placeholder="Enter..."
                autoFocus
                style={c.inputStyles}
                value={_.get(initialData, '2.first_name', '') || ''}
                onChange={e => handleChangeFields(e, 2)}
              />
            </div>

            <div className="col-6 ">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                Middle Initial
              </label>
              <input
                type="text"
                name="middle_name"
                className="form-control form-custom form-control_bottom px-3"
                placeholder="Enter..."
                style={c.inputStyles}
                value={_.get(initialData, '2.middle_name', '') || ''}
                onChange={e => handleChangeFields(e, 2)}
              />
            </div>
            <div className="col">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                Last Name
              </label>
              <input
                type="text"
                name="last_name"
                className="form-control form-custom form-control_bottom px-3"
                placeholder="Enter..."
                style={c.inputStyles}
                value={_.get(initialData, '2.last_name', '') || ''}
                onChange={e => handleChangeFields(e, 2)}
              />
            </div>
          </div>
        </div>
        <div className="button-container d-flex justify-content-end">
          <ButtonAsync
            className="btn btn-primary position-relative py-2 px-4 mr-2"
            type="submit"
            loading={pending}
            disabled={isInputFieldsEmpty}
          >
            Save
          </ButtonAsync>

          <button
            className="btn bg-gray-300 text-white py-2 px-4"
            onClick={handleCloseModal}
            disabled={pending}
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

const modalKey = 'dealer-authorization-update';
const modalConfig = {
  title: 'Update Dealer Authorization',
  size: 'modal-lg',
};

export default withModal(modalKey, modalConfig)(UpdateDealerAuthorizaion);
