import React from 'react';
import PropTypes from 'prop-types';

const DataField = props => {
  const { label, value, ...rest } = props;
  return (
    <div {...rest}>
      <div className="text-xs text-gray-500 font-weight-bold">{label}</div>
      <div className="tw-text-sm">{value}</div>
    </div>
  );
};

export default DataField;

DataField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};
