/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import _ from "lodash";
import { ToastError } from "modules/common/components/Toast";
import { mainModule } from "modules/partials/DealerSideBar";
import DOMPurify from "dompurify";
import CryptoJS from "crypto-js";
import Faker from "faker";

const baseUrl = process.env.REACT_APP_END_POINT;

export const numberToLocale = (number) => {
  if (typeof number === "undefined") return "";
  return number.toLocaleString("en-US", {
    valute: "USD",
  });
};

export const rootApi = baseUrl.endsWith("/")
  ? baseUrl.replace(/\/+$/, "/")
  : `${baseUrl}/`;

export const loadAPI = (id, src) =>
  new Promise((cb) => {
    const fjs = document.getElementsByTagName("script")[0];
    const js = document.createElement("script");

    if (document.getElementById(id)) {
      cb();
      return;
    }

    js.id = id;
    js.src = src;
    js.onload = cb;
    fjs.parentNode.insertBefore(js, fjs);
  });

export const jsUcFirst = (string) =>
  string
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");

export const passwordStrength = (pw) => {
  const reg_lcase = /[a-z]/;
  const reg_ucase = /[A-Z]/;
  const reg_num = /[0-9]/;
  // eslint-disable-next-line no-useless-escape
  const reg_specialChar = /[!@#$%^&*(),.?":{}|<>]/g;
  const hasSpecialCharacter = reg_specialChar.test(pw);
  const hasLowerCase = reg_lcase.test(pw);
  const hasUpperCase = reg_ucase.test(pw);
  const hasNumber = reg_num.test(pw);
  const isLongEnough = `${pw}`.length >= 8;
  const isAlphaNumeric = hasNumber && (hasLowerCase || hasUpperCase);
  const isFormatOk =
    [hasLowerCase, hasUpperCase, isAlphaNumeric, hasSpecialCharacter].filter(
      (c) => c === true,
    ).length >= 3;
  const isValid = isFormatOk && isLongEnough;

  return {
    hasLowerCase,
    hasUpperCase,
    hasSpecialCharacter,
    hasNumber,
    isLongEnough,
    isAlphaNumeric,
    isFormatOk,
    isValid,
  };
};

export const serialize = (obj) => {
  const str = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }
  return str.join("&");
};

export const getPermissions = () => {
  try {
    const uniqueSet = _.uniq(JSON.parse(sessionStorage.getItem("permissions")));
    return uniqueSet;
  } catch (err) {
    return [];
  }
};

export const checkRoles = (arr) => {
  if (arr.length > 0) {
    const roles = _.uniq(
      _.get(JSON.parse(sessionStorage.getItem("profile")), "roles"),
    );
    const temp = roles.filter((item) => arr.indexOf(item) > -1);
    return arr.length === 0 || temp.length > 0;
  }
  return null;
};

export const checkPinStatus = () => {
  try {
    const pin = _.get(
      JSON.parse(sessionStorage.getItem("profile")),
      "is_pin_enabled",
    );
    return pin === undefined ? true : pin;
  } catch (err) {
    return [];
  }
};

export const checkPermissions = (validPermissions) => {
  if (validPermissions.length > 0) {
    const newPermissions = getPermissions()
      ? getPermissions().filter((item) => validPermissions.indexOf(item) > -1)
      : [];
    return validPermissions.length === 0 || newPermissions.length > 0;
  }
  return null;
};

export const errorCodes = (code) => {
  if (code === "7106") {
    ToastError(
      "Ang iyong retailer load wallet balance ay hindi sapat para sa transaction na ito. Magpaload sa distributor para magpatuloy.",
    );
  }
  if (code === "2019") {
    ToastError("Unable to process. Please try again later.");
  }
  return ToastError("Unable to process. Please try again later.");
};

export const queryParams = (params) =>
  Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&");

export const downloadGet = ({
  url,
  filename,
  params,
  onSuccess,
  onError = () => {},
}) => {
  try {
    fetch(`${url}?${queryParams(params)}`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        const temp_url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = temp_url;
        a.download = filename || "filename.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        onSuccess();
      })
      .catch((err) => {
        console.log(err); // eslint-disable-line
        ToastError("Unable to process request!");
        onError();
      });
  } catch (err) {
    ToastError("Unable to process request!");
    onError();
  }
};

export const parseNumber = (str, default_value = false) => {
  const v = parseFloat(`${str}`.replace(/,/g, ""));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(v)) return typeof default_value !== "boolean" ? default_value : str;
  return v;
};

export const formatNumber = (v, decimal = 2) => {
  try {
    const n = parseNumber(v);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(n)) return v;
    return n.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  } catch (err) {
    return v;
  }
};

export const renameProp = (oldProp, newProp, { [oldProp]: old, ...others }) => {
  return {
    [newProp]: old,
    ...others,
  };
};

/*
 * --------------------
 * Join Strings
 * --------------------
 * Template literal joining strings together,
 * delimited by a single whitespace
 *
 * Ex:
 *    <div className={join`default-class ${dynamicClass}`}></div>
 *
 * // <div class="default-class dynamic-class-1 dynamic-class-2"></div>
 */

// remove empty items and whitespaces around string items
export const clean = (array) => array.map((s) => s.trim()).filter((s) => s);
// splits a string item if contains whitespace
export const dissect = (array) => array.map((s) => s.split(" ")).flat();

export const join = (strings, ...keys) =>
  [...dissect(clean(strings)), ...dissect(clean(keys))].join(" ");

export const diffFromNow = (date, divisor) =>
  (new Date(date) - new Date()) / divisor;

export const removeEmpty = (obj) => _.omitBy(obj, (x) => x === "");

export const removeNull = (obj) =>
  _.omitBy(obj, (x) => typeof x === "undefined" || x === null);

/*
 * -------------------------
 * Space-separated values
 * -------------------------
 *  Generate space separated values, useful for CSS classname concatenation
 *
 *  Ex:
 *    <div calssName={ssv(
 *      'card',
 *      flipped ? 'card--active card--flipped' : 'card--active',
 *      isNew ? 'card--isNew' : ''
 *    )}/>
 */

export const ssv = (...keys) =>
  _.uniq(_.flatMap(keys, (k) => _.compact(_.split(k, " "))))
    .sort()
    .join(" ");

export const pathResolve = (...paths) => {
  return paths.map((path) => path.replace(/^\/|\/$/g, "")).join("/");
};

export const getMaskContactNo = (contactNo) => {
  const code = contactNo.substring(0, 2);
  if (code === "09") {
    return "99999999999";
  }
  if (code === "02") {
    return "99-9999-99999";
  }
  return "999-999-99999";
};

export const firstModule = (module_id) => {
  const main = mainModule.find((x) => x?.id === module_id);
  return main?.subModules.find((sub) =>
    checkPermissions(_.get(sub, "permissions") || []),
  );
};

export const middleNameValidator = (initial) => {
  const middleNameReg = new RegExp(
    /^[A-Za-zñÑ](\.|[a-zA-ZñÑ]|\.?[a-zA-ZñÑ]\.?)?$/,
    "gm",
  );
  return middleNameReg.test(initial);
};

export const invert = (string) =>
  string
    .split("")
    .reverse()
    .join("");
export const md5 = (string) => CryptoJS.MD5(string).toString();
export const sha1 = (string) => CryptoJS.SHA1(string).toString();
export const sha256 = (string) => CryptoJS.SHA256(string).toString();

export const reqInterceptor = (req) => {
  let apiPath = "";
  const ignoreURL = ["/partners/api/v1/consumers/auth"];

  try {
    apiPath = new URL(req.url);
    if (req.method !== "POST") return req;
    if (ignoreURL.includes(apiPath.pathname)) return req;

    const requestBody = JSON.parse(req.body);

    const bodyJSON = {
      ...requestBody,

      requestRefNo: Faker.finance.bic(),
    };

    return { ...req, body: JSON.stringify(bodyJSON) };
  } catch (error) {
    console.log(error);
    return req;
  }
};

export const stringToHTML = function(str) {
  const domContainer = document.createElement("span");
  domContainer.innerHTML = str;
  return domContainer;
};

export const oembed_transform = (raw) => {
  const parentEmbed = stringToHTML(raw);
  let oldIframe = parentEmbed.querySelectorAll("oembed");
  oldIframe = Array.from(oldIframe);
  for (const i in oldIframe) {
    let url = oldIframe[i].getAttribute("url");
    url = url.replace("watch?v=", "embed/");
    const newIframe = document.createElement("iframe");
    newIframe.setAttribute("style", "width: 100%;height: 200px;");
    newIframe.setAttribute("allowFullScreen", "");
    newIframe.setAttribute("frameBorder", 0);
    if (url) newIframe.setAttribute("src", url);
    oldIframe[i].parentNode.replaceChild(newIframe, oldIframe[i]);
  }
  return parentEmbed.outerHTML;
};

export const renderIframe = (body) =>
  DOMPurify.sanitize(oembed_transform(body), {
    ADD_TAGS: ["iframe"],
    ADD_ATTR: [
      "class",
      "style",
      "frameborder",
      "src",
      "allow",
      "allowfullscreen",
    ],
  });
