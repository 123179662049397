import * as req from "api/actions";
import _ from "lodash";
import store from "store";
import * as c from "./constant";

export const getProvider = (payload, callback) =>
  req.list(c.GET_PROVIDER, "api/v1/providers", payload, callback);

export const getPromo = (providerId, payload, callback) =>
  req.item(
    c.GET_PROMO,
    `api/v1/providers/${providerId}/promos`,
    { ...payload, ui_channel: "web", is_active: 1 },
    callback
  );

export const showRetailer = (retailerId, payload, callback) =>
  req.item(
    c.SHOW_RETAILER,
    `api/v1/dealer-retailer-sims/${retailerId}`,
    payload,
    (res) => {
      const sourceFund = _.get(res, "data.attributes.branch.sourceFunds") || [];
      store.dispatch(req.setList(`${c.SHOW_RETAILER}/sourceFund`, sourceFund));
      if (callback) callback(res);
    }
  );

export const showRetailerUses = (payload, callback) =>
  req.item(c.SHOW_RETAILER, "api/v1/retailer-users/my", payload, callback);

export const sendLoad = (payload, callback, errCallback) =>
  req.create(
    c.SEND_LOAD,
    "mobile/api/v2/send-load",
    { ...payload, access_party_id: c.LOAD_PARTY_ID, ui_channel: "web" },
    callback,
    {},
    errCallback
  );

export const inquireBalance = (payload, callback, errCallback) =>
  req.create(
    c.INQUIRE_BALANCE,
    "mobile/api/v2/inquire-balance",
    payload,
    callback,
    {},
    errCallback
  );

export const inquireMpin = (payload, callback, errCallback) =>
  req.create(
    c.MPIN,
    "api/v1/external/users/my/validate-mpin",
    payload,
    callback,
    {},
    errCallback
  );

export const getBranch = (payload, callback) =>
  req.item(
    c.GET_BRANCH,
    "api/v1/retailer/retailer_users/my/branches/current",
    payload,
    callback
  );

export const getLoadThreshold = (dealerSimId, payload, callback) =>
  req.item(
    c.GET_DEALER_MIN_THRESHOLD,
    `api/v1/dealer-sims/${dealerSimId}`,
    payload,
    callback
  );

export const getLoadBalance = (payload, callback, errCallback) =>
  req.create(
    c.GET_DEALER_MIN_BALANCE,
    `mobile/api/v2/inquire-balance`,
    payload,
    callback,
    {},
    errCallback
  );

export const postLoadRetailer = (dealerSimId, payload, callback, errCallback) =>
  req.create(
    c.LOAD_RETAILER_EXTERNAL,
    `api/v1/dealer-sims/${dealerSimId}/transfer-load`,
    payload,
    callback,
    {},
    errCallback
  );

export const validateMPin = (payload, callback, errCallback) =>
  req.create(
    c.VALIDATE_MPIN,
    `api/v1/external/users/my/validate-mpin`,
    payload,
    callback,
    {},
    errCallback
  );

export const getPinStatus = (payload, callback) =>
  req.item(c.PIN_STATUS, "api/v1/external/users/my/profile", payload, callback);
