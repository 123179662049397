import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useLoading } from 'modules/ui/hooks';
import Img from 'modules/common/components/ImgDoc';

import * as c from '../../constants';
import * as actions from '../../actions';

const SupportingDocumentsPage = () => {
  const dispatch = useDispatch();
  const requiredDocumentsImages = useSelector(({ api }) =>
    _.get(api, `${c.GET_REQUIRED_DOCUMENTS_IMAGES_LIST}.list`, [])
  );
  const companyInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_COMPANY_INFORMATION}.item`, {})
  );

  const isChannelLoading = useLoading(c.GET_REQUIRED_DOCUMENTS_IMAGES_LIST);

  const dealer_company_id = _.get(companyInfo, 'id');

  useEffect(() => {
    if (dealer_company_id)
      dispatch(actions.getRequiredDocumentsImagesList(dealer_company_id));
    return () => {
      dispatch(actions.removeSupDocs());
    };
  }, [dealer_company_id]);

  return (
    <div className="card-body upload-requirements">
      <div className="requirement-wrapper mt-3">
        {isChannelLoading ? (
          <div className="text-center w-100" style={{ fontSize: 25 }}>
            <span className="fas fa-1x fa-spin fa-spinner" />
          </div>
        ) : (
          <div className="documents-container row">
            {requiredDocumentsImages.length > 0 ? (
              requiredDocumentsImages.map(item => (
                <div className="col-sm-12 col-md-6 col-lg-3" key={item.id}>
                  <div className="d-flex align-items-baseline justify-content-center">
                    <h4 className="label">
                      {_.get(item, 'attributes.requirement_name', '')}
                    </h4>
                  </div>
                  <div className="requirement-item">
                    <Img
                      lightBox
                      withZoom
                      type={_.get(item, 'attributes.type')}
                      src={_.get(item, 'attributes.value') || ''}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center w-100">
                <p className="m-0" style={{ fontSize: '.7rem' }}>
                  There are no records found
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportingDocumentsPage;
