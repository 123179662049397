import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  Route,
  useRouteMatch,
  withRouter,
  Redirect,
  NavLink,
} from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import _ from "lodash";
import { ModalContext } from "App";
import {
  IconLayoutGrid,
  IconLayoutTable,
} from "modules/common/components/Icons";
import ReactInputMask from "react-input-mask";
import { ToastError } from "modules/common/components/Toast";
import { checkPermissions, removeEmpty } from "helper";
import PromoModal from "../components/PromoModal";
import SetPin from "../components/SetPin";
import OfferGrid from "../components/OfferGrid";
import OfferTable from "../components/OfferTable";
import Filter from "../components/Filter";
import Pagination from "modules/common/components/Pagination";
import * as actions from "../actions";
import * as c from "../constant";
import * as r from "../../LoadWalletManagement/constant";
import { useLoading } from "../../ui/hooks";
import * as loadWalletActions from "../../LoadWalletManagement/actions";

const INIT_STATE = {
  q: "",
  page: 1,
  per_page: 25,
};

const changeNumberFormat = (num) => num.replace("+63", "0");

const normalizeRetailerSimList = (retailerSimListArray) => {
  const retailerSimListObj = {};
  retailerSimListArray.forEach((sim) => {
    const trimmedSim = sim.slice(-10);
    retailerSimListObj[trimmedSim] = trimmedSim;
  });
  return retailerSimListObj;
};

function RetailerToSubscriber({
  getProvider,
  provider,
  getPromo,
  promo,
  showRetailer,
  retailerId,
  selectedRetailer,
  profileType,
  inquireBalance,
  getRetailerSims,
  retailerSims,
  roles,
  showRetailerUses,
  getBranch,
  branchDetails,
  sourceFund,
}) {
  const match = useRouteMatch();
  const sub_match = useRouteMatch(`${match.path}/:providerId`);
  const providerId = _.get(sub_match, "params.providerId");
  const pager = useSelector(({ api }) =>
    _.get(api, `${c.GET_PROMO}.res.meta.pagination`)
  );
  const [wallet, setWallet] = useState();
  const [view, setView] = useState("grid");
  const setModal = useContext(ModalContext);
  const [formData, setFormData] = useState({
    mobile_number: "",
    mobile_identification_number: "",
  });
  const [filter, setFilter] = useState(INIT_STATE);
  const retailerSimList = [];
  const errorTargetMinInit = {
    ownedMin: "",
  };

  const [errorTargetMin, setErrorTargetMin] = useState(errorTargetMinInit);
  const parsProviderDetails = provider.find(
    (data) => _.get(data, "id") === providerId
  );
  const setThreshold = useMemo(() => {
    return retailerSims?.find(
      (sim) =>
        sim?.attributes?.mobile_identification_number ===
        formData?.mobile_identification_number
    );
  }, [retailerSims, formData?.mobile_identification_number]);

  const isRetailerWithinSchedule = _.get(
    selectedRetailer,
    "attributes.has_ongoing_schedule",
    null
  );

  const checkIsTargetMinError = (targetMin, normRetailerSimList) => {
    const errorMsgOwned = !_.isEmpty(normRetailerSimList[targetMin.slice(-10)])
      ? "Cannot be a Retailer MIN."
      : "";
    setErrorTargetMin({
      ownedMin: errorMsgOwned,
    });

    return !_.isEmpty(errorMsgOwned);
  };

  const handleSelectPromo = (args, brand, newRetailer) => {
    if (
      checkIsTargetMinError(
        _.get(formData, "mobile_number", ""),
        normalizeRetailerSimList(retailerSimList)
      )
    ) {
      return null;
    }
    const canCreateLoad = checkPermissions(["can-create-load-transaction"]);
    const isOutOfSchedule = !_.get(
      newRetailer,
      "attributes.has_ongoing_schedule"
    );

    if (isOutOfSchedule) {
      return ToastError(
        "You are not allowed to use this feature outside your selling window. If this is not the case, please contact your System Administrator."
      );
    }
    if (!canCreateLoad) {
      return ToastError("You are not allowed to send load.");
    }

    const roleChecklist = [
      "retailer-admin",
      "branch-admin",
      "branch-admin-non-migrated",
    ];
    const roleChecklistFL = ["frontliner", "frontliner-non-migrated"];
    const isKapartnerUser =
      profileType === "retailers" && _.get(roles, "0", "") === "kapartner-user";
    const isDealersOrAllowedRoles =
      profileType === "dealers" ||
      roleChecklist.includes(_.get(roles, "0", ""));
    const isFrontliner = roleChecklistFL.includes(_.get(roles, "0", ""));
    const isMobileNumEmpty = _.isEmpty(_.get(formData, "mobile_number"));
    const isMINEmpty = _.isEmpty(
      _.get(formData, "mobile_identification_number")
    );
    const isEqualToZero =
      _.get(formData, "mobile_identification_number") === "0" ||
      _.get(formData, "mobile_number") === "0";

    const activatePromoModal = () => {
      setModal("promo", {
        data: {
          ...args,
          ...formData,
          category_id: _.get(args, "attributes.category.id"),
          brand: _.get(args, "attributes.provider.brand.label"),
          keyword: _.get(args, "attributes.dms_keyword"),
          newRetailer,
          retailer_sim: _.get(formData, "mobile_identification_number"),
          profileType,
          provider_id: providerId,
        },
        setBalance: setWallet,
        branch_id: branchDetails?.id,
      });
    };

    if (isKapartnerUser) {
      if (isMobileNumEmpty || isEqualToZero) {
        return ToastError("Mobile Number is Required");
      }
      return activatePromoModal();
    }
    if (isDealersOrAllowedRoles) {
      if (isMobileNumEmpty || isMINEmpty || isEqualToZero) {
        return ToastError("Mobile Number / MIN Field is Required");
      }
      return activatePromoModal();
    }

    if (isFrontliner) {
      if (isMobileNumEmpty || isMINEmpty || isEqualToZero) {
        return ToastError("Mobile Number / MIN Field is Required");
      }
      return activatePromoModal();
    }

    return null;
  };

  const handleSelectedPromoGrid = (args, brand, newRetailer) => (e) => {
    e.preventDefault();
    handleSelectPromo(args, brand, newRetailer);
  };

  const handleSelectedPromoTable = (args, brand, newRetailer) => {
    handleSelectPromo(args, brand, newRetailer);
  };

  const handleView = (value) => (e) => {
    e.preventDefault();
    setView(value);
  };

  const handleChangeFilter = ({ target }) => {
    const newFilter = { ...filter, q: target?.value, page: "" };
    setFilter((state) => newFilter);
    getPromo(providerId, removeEmpty(newFilter));
  };

  const handleChangeInputMobile = (key) => ({ target }) => {
    const value = target.value === "" ? 0 : target.value;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleChangeInput = (key) => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value,
    });
  };

  // Check balance function
  const updateBalance = useCallback(
    (min) => {
      if (!min) return;
      inquireBalance({ mobile: min.replace("+63", "0") }, (res) => {
        setWallet(_.get(res, "data.current_balance"));
      });
    },
    [setWallet, inquireBalance]
  );

  const handleSourceMinChange = (event) => {
    const { value } = event.target;

    setFormData((prev) => ({
      ...prev,
      mobile_identification_number: value,
    }));
  };

  const setDefaultMin = useCallback(() => {
    // not applicable to KU users
    if (_.get(roles, "0") === "kapartner-user") return;

    if (!sourceFund) return;

    const sources = sourceFund.filter((min) => {
      if (_.get(min, "attributes.type") !== "dealer_retailer_sims")
        return false;
      if (!_.get(min, "attributes.is_active")) return false;
      return true;
    });

    const defaultSource = sources.find(
      (min) => _.get(min, "attributes.is_default") === true
    );

    if (!defaultSource) return;

    const min = _.get(defaultSource, "attributes.mobile_identification_number");

    setFormData((prev) => ({
      ...prev,
      mobile_identification_number: min,
    }));
  }, [setFormData, sourceFund]);

  const fetchPromo = useCallback(
    (page) => {
      const newFilter = { ...filter, page };
      return getPromo(providerId, removeEmpty(newFilter));
    },
    [filter, getPromo, providerId]
  );

  const handleChangePage = (page) => {
    setFilter((state) => ({ ...state, page }));
    fetchPromo(page);
  };

  useEffect(() => {
    const min = _.get(formData, "mobile_identification_number");
    if (!min) return;
    updateBalance(min);
  }, [formData.mobile_identification_number]);

  useEffect(() => {
    setDefaultMin();
  }, [sourceFund]);

  const renderThreshold = (args, smartWallet) => {
    if (_.get(args, "attributes.load_threshold") < smartWallet) {
      return (
        <React.Fragment>
          <div
            className="progress-bar-percentage percent-green"
            style={{ width: `${smartWallet}%` }}
          />
          <span className="percent-text">Good</span>
        </React.Fragment>
      );
    }
    if (_.get(args, "attributes.load_threshold") >= smartWallet) {
      return (
        <React.Fragment>
          <div
            className="progress-bar-percentage percent-red"
            style={{ width: `${smartWallet}%` }}
          />
          <span className="percent-text text-white">Critical</span>
        </React.Fragment>
      );
    }
    return null;
  };

  const renderRetailerSims = () => {
    if (
      profileType === "retailers" &&
      !_.get(roles, "0")?.includes("frontliner") &&
      !_.get(roles, "0")?.includes("branch-admin")
    ) {
      const selectedRetailerMin = _.get(
        selectedRetailer,
        "attributes.mobile_identification_number",
        ""
      );
      if (!_.isEmpty(selectedRetailerMin)) {
        retailerSimList.push(selectedRetailerMin);
      }
      return (
        <input
          className="form-control input-textbox form-custom form-control_bottom"
          type="text"
          required
          readOnly
          value={selectedRetailerMin}
        />
      );
    }

    if (
      profileType === "dealers" ||
      _.get(roles, "0")?.includes("branch-admin")
    ) {
      const sources = sourceFund.filter((min) => {
        if (_.get(min, "attributes.type") !== "dealer_retailer_sims")
          return false;
        if (!_.get(min, "attributes.is_active")) return false;
        return true;
      });
      return (
        <select
          name="mobile_identification_number"
          id="mobile_identification_number"
          className="custom-select"
          onChange={handleSourceMinChange}
          value={_.get(formData, "mobile_identification_number", "")}
          required
        >
          <option value="" disabled>
            Select Min
          </option>
          {sources.map((sims) => {
            const sourcesMin = _.get(
              sims,
              "attributes.mobile_identification_number"
            );
            const sourcesType = _.get(sims, "attributes.sim_type");
            if (!_.isEmpty(sourcesMin)) {
              retailerSimList.push(sourcesMin);
            }
            return (
              <option key={sims.id} value={sourcesMin}>
                {`${sourcesMin} ${sourcesType ? `- ${sourcesType}` : ""}`}
              </option>
            );
          })}
        </select>
      );
    }

    if (
      _.get(roles, "0")?.includes("frontliner") &&
      profileType !== "dealers"
    ) {
      const sources = sourceFund.filter((min) => {
        if (_.get(min, "attributes.type") !== "dealer_retailer_sims")
          return false;
        if (!_.get(min, "attributes.is_active")) return false;
        return true;
      });
      return (
        <select
          name="mobile_identification_number"
          id="mobile_identification_number"
          className="custom-select"
          onChange={handleSourceMinChange}
          value={_.get(formData, "mobile_identification_number", "")}
          required
        >
          <option value="" disabled>
            Select Min
          </option>
          {sources.map((sims) => {
            const sourcesMin = _.get(
              sims,
              "attributes.mobile_identification_number"
            );
            const sourcesType = _.get(sims, "attributes.sim_type");
            if (!_.isEmpty(sourcesMin)) {
              retailerSimList.push(sourcesMin);
            }
            return (
              <option key={sims.id} value={sourcesMin}>
                {`${sourcesMin} ${sourcesType ? `- ${sourcesType}` : ""}`}
              </option>
            );
          })}
        </select>
      );
    }
    return null;
  };

  const renderInput = () => {
    const maxLength = _.get(parsProviderDetails, "attributes.max_digits");
    if (_.get(parsProviderDetails, "attributes.is_mobile") === true) {
      return (
        <input
          className="form-control border border-bottom-1 border-left-0 border-right-0 border-top-0 rounded-0 px-2 py-0 shadow-none"
          type="text"
          name="mobile_number"
          placeholder={`0`.repeat(maxLength)}
          required
          onChange={handleChangeInputMobile("mobile_number")}
          value={_.get(formData, "mobile_number")}
          maxLength={maxLength + 1}
        />
      );
    }
    return (
      <ReactInputMask
        type="text"
        placeholder={`Ex. ${`0`.repeat(maxLength)}`}
        mask={"9".repeat(maxLength)}
        maskChar=""
        className="form-control input-textbox form-custom form-control_bottom"
        name="mobile_number"
        required
        onChange={handleChangeInput("mobile_number")}
        value={_.get(formData, "mobile_number") || ""}
      />
    );
  };

  useEffect(() => {
    getProvider();
    getRetailerSims({ is_active: true });
    getBranch();
    if (_.get(roles, "0") === "kapartner-user") {
      showRetailer(retailerId);
    }
    if (
      _.get(roles, "0") === "branch-admin" ||
      _.get(roles, "0") === "frontliner" ||
      _.get(roles, "0") === "frontliner-non-migrated" ||
      _.get(roles, "0") === "branch-admin-non-migrated"
    ) {
      showRetailerUses();
    }
    if (!_.isEmpty(providerId)) {
      fetchPromo(1);
    }
    if (_.get(parsProviderDetails, "attributes.is_mobile", true) === true) {
      setFormData((prevState) => ({ ...prevState, mobile_number: "0" }));
    } else {
      setFormData((prevState) => ({ ...prevState, mobile_number: "" }));
    }

    setModal({ isOpen: false });
  }, [
    roles,
    profileType,
    providerId,
    retailerId,
    getProvider,
    getRetailerSims,
    getBranch,
    getPromo,
    showRetailer,
    showRetailerUses,
  ]);

  const isSelectedRetailerEmpty = !_.isEmpty(selectedRetailer);
  const sortedPromo = _.sortBy(promo, (e) => {
    return e?.attributes?.name;
  });
  const promoList = _.filter(sortedPromo, (e) => {
    return e?.type;
  });

  const isLoadingPromoList = useLoading(c.GET_PROMO);

  useEffect(() => {
    if (!_.isEmpty(selectedRetailer) && profileType !== "retailer_users") {
      const payload = {
        mobile: changeNumberFormat(
          _.get(selectedRetailer, "attributes.mobile_identification_number")
        ),
      };
      inquireBalance(payload, (res) => {
        setWallet(_.get(res, "data.available_balance"));
      });
    }
  }, [
    isSelectedRetailerEmpty,
    inquireBalance,
    setWallet,
    profileType,
    selectedRetailer,
  ]);

  // for multiple Sims
  useEffect(() => {
    if (
      _.get(roles, "0") === "kapartner-user" &&
      profileType !== "retailer_users"
    ) {
      setFormData({
        mobile_identification_number: _.get(
          selectedRetailer,
          "attributes.mobile_identification_number"
        ),
      });
    }
  }, [isSelectedRetailerEmpty, profileType, roles, selectedRetailer]);

  const isLoadingShowRetailer = useLoading(c.SHOW_RETAILER);
  if (_.isNil(isRetailerWithinSchedule) && isLoadingShowRetailer)
    return (
      <div className="d-flex justify-content-center align-items-center h-100 ">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  if (!isRetailerWithinSchedule)
    return (
      <div className="container-fluid pt-5">
        <div className="alert alert-danger" role="alert">
          You are not allowed to use this feature outside your selling window.
          If this is not the case, please contact your System Administrator.
        </div>
      </div>
    );

  return (
    <div className="main-content-inner">
      <div className="box-fit-wrapper h-100">
        <div className="box-fix-overflow">
          <div className="container-fluid h-100 px-0">
            <div className="row h-100">
              <div className="col-lg-12 h-100">
                <div className="sales-order-wrapper">
                  <div className="loadSubscriber__mobile-load-balance-container">
                    <div className="mobile-loaded">
                      <div className="network-form">
                        <div className="network-form-list row">
                          {provider.map((x) => (
                            <NavLink
                              to={`${match.url}/${_.get(x, "id")}`}
                              className="network-logo shadow-sm"
                              activeClassName="selected"
                              key={_.get(x, "id")}
                            >
                              <img
                                className="image"
                                src={_.get(x, "attributes.logo")}
                                alt="..."
                              />
                            </NavLink>
                          ))}
                        </div>
                        <div className="form-group mt-3">
                          <label className="form-label text-primary">
                            Mobile Number to be loaded:
                          </label>
                          {renderInput()}
                          <div
                            style={{
                              height: "1.5rem",
                              color: "IndianRed",
                              fontSize: ".875rem",
                              fontStyle: "italic",
                            }}
                          >
                            <span className="mr-1">
                              {errorTargetMin.ownedMin}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mobile-wallet">
                      <div className="card shadow-sm px-0">
                        <div className="card-header bg-primary">
                          <p className="card-title m-0 text-white font-weight-bold">
                            Retailer Balance:
                          </p>
                        </div>
                        <div className="card-body">
                          {_.get(roles, "0")?.includes("frontliner") && (
                            <div className="form-group">
                              <label className="form-label text-primary">
                                Branch:
                              </label>
                              <input
                                type="text"
                                className="form-control input-textbox form-custom form-control_bottom"
                                value={
                                  _.get(branchDetails, "attributes.name") || ""
                                }
                                readOnly
                              />
                            </div>
                          )}
                          <div className="form-group">
                            <label className="form-label text-primary">
                              Retailer MIN:
                            </label>
                            {renderRetailerSims()}
                          </div>
                          {!_.get(roles, "0")?.includes("frontliner") &&
                            !_.get(roles, "0")?.includes("branch-admin") && (
                              <div className="form-group d-flex align-items-center justify-content-between">
                                <label className="form-label text-primary m-0">
                                  Balance:
                                </label>
                                <h5 className="title m-0 font-weight-bold">
                                  <small>PHP</small> {wallet || 0}
                                </h5>
                              </div>
                            )}
                          <div className="form-group">
                            <label className="form-label text-primary m-0">
                              Load Wallet Threshold:
                            </label>
                            <div className="progress-bar">
                              {_.get(roles, "0") !== "kapartner-user"
                                ? renderThreshold(setThreshold, wallet)
                                : renderThreshold(selectedRetailer, wallet)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="offer-list">
                    <div className="container-fluid d-flex flex-column">
                      <Filter value={filter} onChange={handleChangeFilter} />
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between">
                          <h5 className="title font-weight-bold m-0">
                            Load Dispensing - {_.get(promo, "type")} Offers
                            Lists retailer
                          </h5>
                          <div className="layout-action d-flex align-items-center">
                            <button
                              type="button"
                              className="btn btn-link p-0 mr-2"
                              onClick={handleView("grid")}
                            >
                              <IconLayoutGrid />
                            </button>
                            <button
                              type="button"
                              className="btn btn-link p-0 ml-0"
                              onClick={handleView("table")}
                            >
                              <IconLayoutTable />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row flex-grow-1 mt-4 h-100">
                        {view === "grid" && (
                          <OfferGrid
                            data={promoList}
                            logo={_.get(promo, "logo")}
                            brand={_.get(promo, "type")}
                            retailer={selectedRetailer}
                            onSelectPromo={handleSelectedPromoGrid}
                            isLoading={isLoadingPromoList}
                          />
                        )}
                        {view === "table" && (
                          <OfferTable
                            data={promoList}
                            brand={_.get(promo, "type")}
                            retailer={selectedRetailer}
                            onSelectPromo={handleSelectedPromoTable}
                            isLoading={isLoadingPromoList}
                          />
                        )}
                      </div>
                      <Pagination
                        onChange={handleChangePage}
                        data={pager}
                        className="d-flex justify-content-between p-2 border-top border-muted"
                        withPage
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Route
        path={`${match.path}/:providerId?`}
        render={({ match: { params } }) =>
          !params.providerId && provider.length > 0 ? (
            <Redirect to={`${match.url}/${_.get(provider, "0.id")}`} />
          ) : null
        }
      />
      <PromoModal />
      <SetPin />
    </div>
  );
}

RetailerToSubscriber.propTypes = {
  getProvider: PropTypes.func.isRequired,
  getPromo: PropTypes.func.isRequired,
  inquireBalance: PropTypes.func.isRequired,
  showRetailer: PropTypes.func.isRequired,
  getRetailerSims: PropTypes.func.isRequired,
  showRetailerUses: PropTypes.func.isRequired,
  getBranch: PropTypes.func.isRequired,
  provider: PropTypes.instanceOf(Array).isRequired,
  retailerSims: PropTypes.instanceOf(Array).isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  sourceFund: PropTypes.instanceOf(Array).isRequired,
  branchDetails: PropTypes.instanceOf(Object).isRequired,
  promo: PropTypes.instanceOf(Object).isRequired,
  selectedRetailer: PropTypes.instanceOf(Object).isRequired,
  retailerId: PropTypes.string.isRequired,
  profileType: PropTypes.string.isRequired,
};

const mapStateToProps = ({ api }) => ({
  retailerSims: _.get(api, `${r.GET_RETAILER_SIMS}.list`) || [],
  provider: _.get(api, `${c.GET_PROVIDER}.list`) || [],
  branchDetails: _.get(api, `${c.GET_BRANCH}.item`, {}),
  sourceFund: _.get(api, `${c.GET_BRANCH}.item.included.sources`, []),
  promo: _.get(api, `${c.GET_PROMO}.item`) || {},
  selectedRetailer: _.get(api, `${c.SHOW_RETAILER}.item`) || {},
  retailerId:
    _.get(api, "AUTH/user_profile.item.retailer_id") ||
    _.get(api, "AUTH/user_profile.item.id"),
  profileType: _.get(api, "AUTH/user_profile.item.profile_type") || "",
  roles: _.get(api, "AUTH/user_profile.item.roles") || [],
});

const enhance = _.flowRight([
  withRouter,
  connect(mapStateToProps, {
    ...actions,
    ...loadWalletActions,
  }),
]);

export default enhance(RetailerToSubscriber);
