import * as c from "./constants";
import { service } from "./service";

export const hasSession = (token) => {
  try {
    const tokenJSON = JSON.parse(atob(token.split(".")[1]));
    return Date.now() <= tokenJSON.exp * 1000;
  } catch {
    return false;
  }
};

// set default config
const defaultConfig = {};

const apiService = ({ dispatch }) => (next) => async (action) => {
  next(action);

  // GET
  if (action.type === c.API_GET) {
    const { url, params, onSuccess, onError } = action;
    const token = sessionStorage.getItem("token");
    const authorization = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const config = {
      ...defaultConfig,
      ...(hasSession(token) ? authorization : {}),
    };

    try {
      const response = await service.get(url, { params }, config);
      dispatch({ type: onSuccess, response });
    } catch (error) {
      dispatch({ type: onError, error });
    }
  }

  // POST
  if (action.type === c.API_POST) {
    const { url, data, onSuccess, onError } = action;
    const token = sessionStorage.getItem("token");
    const authorization = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const config = {
      ...defaultConfig,
      ...(hasSession(token) ? authorization : {}),
    };

    try {
      const response = await service.post(url, data, config);
      if (!onSuccess) return;
      dispatch({ type: onSuccess, payload: data, response });
    } catch (error) {
      if (!onError) return;
      dispatch({ type: onError, payload: data, error });
    }
  }

  // PUT
  if (action.type === c.API_PUT) {
    const { url, data, onSuccess, onError } = action;
    const token = sessionStorage.getItem("token");
    const authorization = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const config = {
      ...defaultConfig,
      ...(hasSession(token) ? authorization : {}),
    };

    try {
      const response = await service.put(url, data, config);
      if (!onSuccess) return;
      dispatch({ type: onSuccess, payload: data, response });
    } catch (error) {
      if (!onError) return;
      dispatch({ type: onError, payload: data, error });
    }
  }

  // DELETE
  if (action.type === c.API_DELETE) {
    const { url, data, onSuccess, onError } = action;
    const token = sessionStorage.getItem("token");
    const authorization = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const config = {
      ...defaultConfig,
      ...(hasSession(token) ? authorization : {}),
    };

    try {
      const response = await service.get(url, data, config);
      dispatch({ type: onSuccess, response });
    } catch (error) {
      dispatch({ type: onError, error });
    }
  }
};

export default [apiService];
