import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { service } from "api/service";
import Select from "react-select";

const RetailerSelectField = (props) => {
  const { onChange, ...rest } = props;
  const [inputValue, setInputValue] = useState("");

  const apiUrl = `/api/v1/dealer-users`;
  const apiParams = {
    without_cluster: true,
    role_slug: "sub-dealer-agent",
    has_dealer_sim: true,
  };

  const { isFetching, data } = useQuery({
    queryKey: ["cluster-agents-assignable", { q: inputValue, ...apiParams }],
    queryFn: ({ queryKey: [__, params] }) => service.get(apiUrl, { params }),
    retry: false,
    keepPreviousData: true,
  });

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleChange = (value) => {
    if (!_.isFunction(onChange)) return;
    onChange(_.get(value, "data"));
  };

  const retailerOptions = _.map(_.get(data, "data.data"), (opt) => ({
    label: _.get(opt, "attributes.full_name"),
    value: _.get(opt, "id"),
    data: opt,
  }));

  return (
    <Select
      options={retailerOptions}
      onChange={handleChange}
      onInputChange={handleInputChange}
      isLoading={isFetching}
      noOptionsMessage={() => "No sub-dealer agents available"}
      {...rest}
    />
  );
};

RetailerSelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default RetailerSelectField;
