import React, { useRef, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

function OverflowTip({ text, id, className, toUpperCase, isSmall, ...rest }) {
  const [hoverStatus, setHover] = useState(false);
  const textElementRef = useRef();
  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, []);

  return (
    <>
      <div
        ref={textElementRef}
        data-tip={text}
        className={`${className}ellipsis-wrap`}
        data-for={id}
        {...rest}
      >
        {text}
      </div>
      <ReactTooltip
        id={id}
        place="bottom"
        effect="solid"
        backgroundColor="#28a745"
        disable={!hoverStatus}
        className={`${toUpperCase ? 'text-uppercase' : ''} ${
          isSmall ? 'tooltip-small' : 'tooltip'
        }`}
        html
      />
    </>
  );
}

OverflowTip.defaultProps = {
  className: '',
  toUpperCase: false,
  style: {},
  isSmall: false,
};

OverflowTip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  toUpperCase: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
  isSmall: PropTypes.bool,
};

export default OverflowTip;
