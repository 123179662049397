import React from 'react';
import PropTypes from 'prop-types';
import emptyStateIllustation from 'assets/images/empty-states.svg';

function NoDataPlaceholder({ label, renderButton }) {
  return (
    <div className="empty-states">
      <img src={emptyStateIllustation} alt="" className="image" />
      <div>{label}</div>
      {renderButton && (
        <div className="empty-state-button">{renderButton()}</div>
      )}
    </div>
  );
}

NoDataPlaceholder.defaultProps = {
  label: <p className="text">No data found.</p>,
  renderButton: false,
};

NoDataPlaceholder.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.bool,
    PropTypes.string,
  ]),
  renderButton: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ]),
};

export default NoDataPlaceholder;
