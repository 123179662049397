import React from 'react';
import AccountListing from '../components/AccountListing';
import SelectedAccount from '../components/SelectedAccount';
import AccountInquiries from '../components/AccountInquiries';

const Accounts = () => (
  <div className="border bg-white">
    <div className="row">
      <div className="col-12 col-md-3 border-right pr-0 pb-4">
        <AccountListing />
      </div>

      <div className="col-12 col-md-9 pl-0">
        <div className="p-4">
          <SelectedAccount />
        </div>

        <div className="border-top p-4">
          <AccountInquiries />
        </div>
      </div>
    </div>
  </div>
);

export default Accounts;
