import React, { useState, useContext } from "react";
import withModal from "modules/common/hoc/withModal";
import _ from "lodash";
import { ModalContext } from "App";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { USER_PROFILE } from "modules/auth/constants";

import PropTypes from "prop-types";
import { ToastSuccess } from "modules/common/components/Toast";
import { useLoading } from "modules/ui/hooks";
import ButtonAsync from "components/Button";
import * as actions from "../action";
import * as c from "../constant";
import SelectFrontliners from "./SelectFrontliners";
import SelectShift from "./SelectShift";

function CreateFrontliner({ assign, getAttachUsers, branchId, branch_name }) {
  const setModal = useContext(ModalContext);
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const isNonMigrated = profile?.roles[0]?.includes("non-migrated");

  const [formData, setFormData] = useState([]);
  const [pending, setPending] = useState(false);
  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);
    const id = _.get(formData, "user.value");
    const payload = {
      schedule_id: _.get(formData, "schedule.value"),
      branch_id: branchId,
    };
    assign(
      id,
      payload,
      () => {
        setModal({ isOpen: false });
        setPending(false);
        getAttachUsers(branchId, {
          role_slug: isNonMigrated ? "frontliner-non-migrated" : "frontliner",
        });
        ToastSuccess("Successfully assigned branch and selling window.");
      },
      () => setPending(false)
    );
  };

  const isInputEmpty = () => {
    const { schedule, user } = formData;
    return _.isEmpty(schedule) || _.isEmpty(user);
  };

  const handleChangeSelect = (key, callback) => (value) => {
    setFormData({
      ...formData,
      [key]: value || {},
    });
    if (callback) callback();
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label className="form-label text-success">
              Select Frontliner:
            </label>
            <SelectFrontliners
              onChange={handleChangeSelect("user")}
              value={_.get(formData, "user", {})}
              branchId={branchId}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label className="form-label text-primary">Branch:</label>
            <input
              autoFocus
              type="text"
              name="name"
              readOnly
              value={branch_name}
              className="form-control form-custom form-control_bottom"
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label className="form-label text-success">Select Shift:</label>
            <SelectShift
              onChange={handleChangeSelect("schedule")}
              value={_.get(formData, "schedule", {})}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <ButtonAsync
              type="submit"
              loading={useLoading(c.ASSIGN) || pending}
              disabled={isInputEmpty()}
            >
              Add
            </ButtonAsync>
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

CreateFrontliner.propTypes = {
  assign: PropTypes.func.isRequired,
  getAttachUsers: PropTypes.func.isRequired,
  branch_name: PropTypes.string.isRequired,
  branchId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ api, modal }) => ({
  userList: _.get(modal, "modalData.data") || [],
  branch_name: _.get(modal, "modalData.branch_name") || "",
  branchId: _.get(modal, "modalData.branchId") || "",
});

const enhance = _.flowRight([
  withModal("create-frontliner", {
    title: "Add Frontliner",
    size: "modal-lg",
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(CreateFrontliner);
