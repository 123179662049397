import _ from "lodash";
import { service } from "api/service";

const transformResponse = (data) => _.get(JSON.parse(data), "data");

export const getClusters = ({ queryKey: [__, clusterId, params] }) => {
  const url = `/dealer/api/v1/clusters/${clusterId}`;
  return service.get(url, { params, transformResponse });
};

export const getClustersRaw = ({ queryKey: [__, clusterId, params] }) => {
  const url = `/dealer/api/v1/clusters/${clusterId}`;
  return service.get(url, { params });
};

export const createCluster = ({ data }) => {
  const url = `/dealer/api/v1/clusters`;
  return service.post(url, data);
};

export const updateCluster = ({ clusterId, data }) => {
  const url = `/dealer/api/v1/clusters/${clusterId}`;
  return service.put(url, data);
};

export const deleteCluster = ({ clusterId }) => {
  const url = `/dealer/api/v1/clusters/${clusterId}`;
  return service.delete(url);
};

export const getClusterAgents = ({ queryKey: [__, clusterId, params] }) => {
  const url = `/external/api/v1/clusters/${clusterId}/dealer-users`;
  return service.get(url, { params, transformResponse });
};

export const addClusterAgents = ({ clusterId, data }) => {
  const url = `/external/api/v1/clusters/${clusterId}/dealer-users`;
  return service.post(url, data);
};

export const deleteClusterAgent = ({ clusterId, agentId }) => {
  const url = `/external/api/v1/clusters/${clusterId}/dealer-users/${agentId}`;
  return service.delete(url);
};
