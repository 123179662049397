import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import withModal from 'modules/common/hoc/withModal';
import withForm from 'modules/common/hoc/withForm';
import { ToastSuccess } from 'modules/common/components/Toast';
import { IconEyeOff, IconEyeOn } from 'modules/common/components/Icons';
import { ModalContext } from 'App';
import * as actions from '../actions';

function ChangePassword({ formData, setFormData, changePassword }) {
  const setModal = useContext(ModalContext);
  const [hidden, setHidden] = useState({ hidden: false });

  const closeModal = e => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const args = {
      old_password: _.get(formData, 'old_password'),
      password: _.get(formData, 'password'),
      password_confirmation: _.get(formData, 'password_confirmation') || '',
    };
    changePassword({ ...args }, () => {
      setModal({ isOpen: false });
      ToastSuccess('You Successfully Change your Password', {
        autoClose: 1500,
      });
    });
  };

  const handleOnChange = e => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mx-2">
        <div className="col form-group px-2">
          <span className="form-label d-flex">
            Old Password
            <span className="text-danger">*</span>
          </span>
          <div className="inner-addon right-addon right-addon-button">
            <input
              type={hidden ? 'password' : 'text'}
              placeholder="Password"
              onChange={handleOnChange}
              value={_.get(formData, 'old_password') || ''}
              name="old_password"
              id="oldloginPassword1"
              className="form-control"
              style={{ border: '1px solid', borderColor: '#979797' }}
            />
          </div>
        </div>
        <div className="col form-group px-2">
          <span className="form-label d-flex">
            New Password
            <span className="text-danger">*</span>
          </span>
          <div className="inner-addon right-addon right-addon-button">
            <input
              type={hidden ? 'password' : 'text'}
              placeholder="Password"
              onChange={handleOnChange}
              value={_.get(formData, 'password') || ''}
              name="password"
              id="loginPassword1"
              className="form-control"
              style={{ border: '1px solid', borderColor: '#979797' }}
            />
          </div>
        </div>
        <div className="col form-group px-2">
          <span className="form-label d-flex">
            Confirm Password
            <span className="text-danger">*</span>
          </span>
          <div className="inner-addon right-addon right-addon-button">
            <input
              type={hidden ? 'password' : 'text'}
              placeholder="Confirm Password"
              onChange={handleOnChange}
              value={_.get(formData, 'password_confirmation') || ''}
              name="password_confirmation"
              id="loginPasswordConfirm1"
              className="form-control"
              style={{ border: '1px solid', borderColor: '#979797' }}
            />
          </div>
        </div>
        <button
          type="button"
          tabIndex={-1}
          className="btn btn-yes mt-4"
          onClick={() => setHidden(!hidden)}
          style={{ height: 46, position: 'relative', width: 44 }}
        >
          {hidden ? <IconEyeOn /> : <IconEyeOff />}
        </button>
      </div>
      <div className="d-flex">
        <button
          type="button"
          onClick={closeModal}
          className="mt-3 btn btn-danger ml-auto mr-2"
          style={{ width: 200, borderRadius: 8 }}
        >
          <span
            className="font-weight-bold"
            style={{ color: '#FFFFFF', fontSize: 14 }}
          >
            CANCEL
          </span>
        </button>
        <button
          type="submit"
          className="mt-3 btn btn-modal-yes mr-3"
          style={{ width: 200, borderRadius: 8 }}
        >
          <span
            className="font-weight-bold"
            style={{ color: '#FFFFFF', fontSize: 14 }}
          >
            SAVE
          </span>
        </button>
      </div>
    </form>
  );
}

ChangePassword.propTypes = {
  formData: PropTypes.instanceOf(Object).isRequired,
  setFormData: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const enhance = _.flowRight([
  withModal('change-password-modal', {
    title: 'Change Password',
    size: 'modal-lg',
  }),
  withRouter,
  connect(mapStateToProps, actions),
  withForm,
]);

export default enhance(ChangePassword);
