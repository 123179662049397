import moment from "moment";

export const GET_APPLICATION_LIST = "APPLICATIONS/GET_APPLICATION_LIST";
export const UPDATE_APPLCATION_STATUS = "APPLICATIONS/UPDATE_APPLCATION_STATUS";
export const GET_DEALER_BY_ID = "APPLICATIONS/GET_DEALER_BY_ID";

export const compoundSearchApplcationsDefaultValue = {
  order_by: "created_at",
  sort_by: "desc",
  per_page: 10,
  q: "",
  has_application: true,
  account_status: "temporary",
  application_status: "rejected,on-going,approved,returned,resubmitted",
  account_type_id: "",
  created_by_name: "",
};

export const onboardDefaultValue = {
  area_id: "",
  is_exclusive: true,

  contract: "",
  card_number: "",
  bank_name: "",
  bank_account_name: "",
  bank_account_number: "",
  po_effectively_date: moment(new Date(), "YYYY-MM-DD HH:mm").format(
    "YYYY-MM-DD"
  ),
  po_expiration_date: "",
};

export const statusOptions = [
  { value: "on-going", label: "ON-GOING" },
  { value: "returned", label: "RETURNED" },
  { value: "resubmitted", label: "RESUBMITTED" },
  { value: "rejected", label: "REJECTED" },
  { value: "approved", label: "APPROVED" },
];

export const style = {
  control: (base) => ({
    ...base,
    border: 0,
    borderBottom: "1px solid gray",
    borderRadius: 0,
    boxShadow: "none",
  }),
};

export const labelStyles = { fontSize: ".6rem" };
export const inputStyles = { fontSize: ".7rem", color: "rgb(1, 1, 1)" };
export const COMPANY_TEXT_LIMIT = 20;

export const form = [
  {
    type: "select-static",
    name: "application_status",
    label: "Status:",
    options: statusOptions,
    isClearable: true,
  },
];
