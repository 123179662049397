import React, { useContext, useEffect, useMemo } from "react";
import { ModalContext } from "App";
import { head } from "lodash";
import { useQuery, useQueryClient } from "react-query";
import { getRetailers } from "app/load-wallet/actions";
import { useProfile } from "modules/auth/hooks";
import { UNLINKED_PARAMS } from "app/load-wallet/constant";
import { useParams } from "react-router-dom";

const NoticeUnlinked = () => {
  const setModal = useContext(ModalContext);
  const urlParams = useParams();
  const queryClient = useQueryClient();

  const id = urlParams?.retailerId || "";

  const profile = useProfile();
  const role = head(profile?.roles);
  const isKA = role.includes("ka");

  const { data, refetch, isFetching } = useQuery({
    queryKey: ["unlinked-retailers", { evc_status: UNLINKED_PARAMS }],
    queryFn: getRetailers,
    retry: false,
    keepPreviousData: true,
  });

  const count = data?.data?.meta?.pagination?.total || 0;

  const list = data?.data?.data || [];

  const isLinking = useMemo(
    () =>
      list.some((sim) => sim?.attributes?.evc_status === "link-in-progress"),
    [list]
  );

  const openUnlinkedModal = (e) => {
    e.preventDefault();
    setModal("view-unlinked-retailers");
  };

  const refresh = (e) => {
    e.preventDefault();
    if (isFetching) return;
    refetch();
    queryClient.invalidateQueries("retailer-id");
    queryClient.invalidateQueries("retailers-listing");
  };

  useEffect(() => {
    refetch();
  }, [id, refetch]);

  if (!count || !isKA) return null;

  if (isLinking)
    return (
      <div className="container-fluid pt-3 pl-0 pr-0">
        <div className="alert alert-warning" role="alert">
          <i className="fa fa-info-circle bg-transparent mr-2" />
          <span className="font-weight-bold">
            Notice!
            <span className="font-weight-normal ml-2">
              Linking of some primary MINs are currently in progress.
            </span>
            <a href="/" onClick={refresh} className="text-primary ml-1">
              <u>Click here to refresh status.</u>
            </a>
          </span>
          {isFetching && (
            <div
              className="spinner-border spinner-border-sm ml-2 mt-1 text-primary"
              role="status"
            />
          )}
        </div>
      </div>
    );
  return (
    <div className="container-fluid pt-3 pl-0 pr-0">
      <div className="alert alert-info" role="alert">
        <i className="fa fa-info-circle bg-transparent mr-2" />
        <span className="font-weight-bold">
          Notice!
          <span className="font-weight-normal ml-2">
            You have ({count}) unlinked Retailer{count > 1 ? "s" : ""}.
          </span>
          <a href="/" onClick={openUnlinkedModal} className="text-primary ml-1">
            <u>Click here to view list.</u>
          </a>
        </span>
      </div>
    </div>
  );
};

export default NoticeUnlinked;
