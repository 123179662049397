import * as c from './constants';

export const updateProfile = (payload, callback) => ({
  type: c.PROFILE_UPDATE,
  payload,
  callback
});

export const getProfile = () => ({
  type: c.PROFILE,
});
