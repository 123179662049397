import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Img from 'modules/common/components/ImgDoc';
import * as session from 'session';
import { useLoading } from 'modules/ui/hooks';
import { ModalContext } from 'App';
import { getExtension } from 'hooks';
import placeholderPhoto from 'assets/images/logo-loader.png';
import AccountInformation from '../../../applications/modal/AccountInformation';
import ViewDocument from '../../modal/ViewDocument';

import * as actions from '../../actions';
import * as c from '../../constants';

const filterData = {
  page: 1,
  per_page: 999,
  is_active: true,
  userType: 'dealers',
};

const AccountInformationPage = ({ dealer_id }) => {
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);
  const companyInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_COMPANY_INFORMATION}.item`, {})
  );
  const userDetails = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_USER_ACCESS_DETAILS}.item`)
  );
  const location = useSelector(({ api }) =>
    _.get(api, `${c.GET_LOCATION}.item`)
  );

  const sims = useSelector(({ api }) => _.get(api, `${c.GET_BDL}.list`));
  const isFetchingSims = useLoading(c.GET_BDL);

  const isLoading = useLoading(c.SHOW_USER_ACCESS_DETAILS || '');

  const actionType = session.get('type') || '';

  const userLocationId = _.get(userDetails, 'attributes.area_id', '');
  const applicationStatus = _.get(userDetails, 'attributes.application_status');

  const company_id = _.get(companyInfo, 'id', '');

  const bdl_sim = useMemo(
    () =>
      sims ? sims.find(sim => sim?.attributes?.type.includes('-BDL')) : {},
    [sims]
  );

  const bdl_sim_min = bdl_sim
    ? bdl_sim?.attributes?.mobile_identification_number
    : 'For Setup';

  const openUpdateModal = () =>
    setModal('account-information', {
      item: userDetails,
      companyInfo,
      applicationStatus,
    });

  useEffect(() => {
    if (company_id)
      dispatch(actions.showMoaSignatoryInformation(dealer_id, company_id));
  }, [company_id]);

  useEffect(() => {
    if (_.isEmpty(userLocationId)) return;
    dispatch(actions.getLocation(userLocationId));
  }, [userLocationId]);

  useEffect(() => {
    if (dealer_id)
      dispatch(
        actions.getBdl({
          dealer_id,
          ...filterData,
        })
      );
    return () => {
      dispatch(actions.removeSims());
    };
  }, [dealer_id]);

  return (
    <>
      {isLoading ? (
        <div className="w-100 mt-3" style={{ fontSize: 25 }}>
          <div className="bg-light-0 py-3 text-center w-100">
            <span className="fas fa-1x fa-spin fa-spinner" />
          </div>
        </div>
      ) : (
        <div className="CompanyInformation mt-4">
          <div className="CompanyInformation-container mb-4">
            <header className="rounded-top-both bg-grey-800 text-white overflow-hidden d-flex justify-content-between pl-3">
              <div
                className="header-title my-auto"
                style={{
                  padding: `${applicationStatus === 'done' ? '.7rem' : ''} 0`,
                }}
              >
                <h6 className="h6 text-xs font-weight-bold p-0 m-0">
                  User Account Information
                </h6>
              </div>
              {applicationStatus !== 'done' && (
                <div>
                  <button
                    type="button"
                    className="btn text-xs btn-success rounded-0 py-2"
                    onClick={openUpdateModal}
                  >
                    <i className="fas fa-pen mr-2" />
                    Update
                  </button>
                </div>
              )}
            </header>
            <div className="p-4 bg-white">
              <div className="row">
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    Dealer Code
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(companyInfo, 'attributes.dealer_code')}
                  </p>
                </div>
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    Dealer Exclusivity
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(userDetails, 'attributes.is_exclusive')
                      ? 'Exclusive'
                      : 'Non-exclusive'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {location && (
            <div className="CompanyInformation-container mb-4">
              <header className="rounded-top-both bg-grey-800 text-white overflow-hidden d-flex justify-content-between pl-3">
                <div
                  className="header-title my-auto"
                  style={{
                    padding: `${applicationStatus === 'done' ? '.7rem' : ''} 0`,
                  }}
                >
                  <h6 className="h6 text-xs font-weight-bold p-0 m-0">
                    Selling Location
                  </h6>
                </div>
                {applicationStatus !== 'done' && (
                  <div>
                    <button
                      type="button"
                      className="btn text-xs btn-success rounded-0 py-2"
                      onClick={openUpdateModal}
                    >
                      <i className="fas fa-pen mr-2" />
                      Update
                    </button>
                  </div>
                )}
              </header>
              <div className="p-4 bg-white">
                <div className="row">
                  <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                    <span className="label text-capitalize font-weight-bold mb-2">
                      {_.capitalize(
                        _.get(location, 'included.parent.attributes.type')
                      ) || '--'}
                    </span>
                    <p className="description text-capitalize mb-0">
                      {_.get(location, 'included.parent.attributes.name') ||
                        '--'}
                    </p>
                  </div>
                  <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                    <span className="label text-capitalize font-weight-bold mb-2">
                      {_.capitalize(
                        _.get(userDetails, 'attributes.area_type')
                      ) || '--'}
                    </span>
                    <p className="description text-capitalize mb-0">
                      {_.get(userDetails, 'attributes.area_name') || '--'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="CompanyInformation-container mb-4">
            <header className="rounded-top-both bg-grey-800 text-white overflow-hidden d-flex justify-content-between pl-3">
              <div
                className="header-title my-auto"
                style={{
                  padding: `${applicationStatus === 'done' ? '.7rem' : ''} 0`,
                }}
              >
                <h6 className="h6 text-xs font-weight-bold p-0 m-0">
                  Contract Details
                </h6>
              </div>
              {applicationStatus === 'done' && actionType === 'update' && (
                <div>
                  <button
                    type="button"
                    className="btn text-xs btn-success rounded-0 py-2"
                    onClick={openUpdateModal}
                  >
                    <i className="fas fa-pen mr-2" />
                    Update
                  </button>
                </div>
              )}
            </header>
            <div className="p-4 bg-white">
              <div className="row">
                <div className="mr-4 ml-3" style={{ width: '15vw' }}>
                  <Img
                    lightBox
                    withZoom
                    defaultSrc={placeholderPhoto}
                    type={getExtension(
                      _.get(companyInfo, 'attributes.contract') || ''
                    )}
                    src={_.get(companyInfo, 'attributes.contract') || ''}
                  />
                </div>
                <div className="col-8 row">
                  <div className="col-lg-4 col-xl-3 d-flex flex-column text-xs text-grey-1000">
                    <span className="label font-weight-bold mb-2">
                      Effectvity Date
                    </span>
                    <p className="description mb-0 break-word">
                      {_.get(companyInfo, 'attributes.po_effectively_date') ||
                        '--'}
                    </p>
                  </div>
                  <div className="col-lg-4 col-xl-3 d-flex flex-column text-xs text-grey-1000">
                    <span className="label font-weight-bold mb-2">
                      Expiry Date
                    </span>
                    <p className="description mb-0 break-word">
                      {_.get(companyInfo, 'attributes.po_expiration_date') ||
                        '--'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-title my-auto p-3">
              <h6 className="h6 text-xs font-weight-bold p-0 m-0">
                Trade Money Information
              </h6>
            </div>
            <div className="p-4 bg-white">
              <div>
                <div className="row mb-4">
                  <div className="col-sm-3 col-xl-2 d-flex flex-column text-xs text-grey-1000">
                    <span className="label font-weight-bold mb-2">
                      Dealer Card Number
                    </span>
                    <p className="description mb-0 break-word">
                      {_.get(companyInfo, 'attributes.card_number') || '--'}
                    </p>
                  </div>
                  <div className="col-sm-3 col-xl-2 d-flex flex-column text-xs text-grey-1000">
                    <span className="label font-weight-bold mb-2">BDL SIM</span>
                    <p className="description mb-0 break-word">
                      {isFetchingSims || _.isUndefined(sims) ? '' : bdl_sim_min}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3 col-xl-2 d-flex flex-column text-xs text-grey-1000">
                    <span className="label font-weight-bold mb-2">
                      Bank Name
                    </span>
                    <p className="description mb-0 break-word">
                      {_.get(companyInfo, 'attributes.bank_name') || '--'}
                    </p>
                  </div>
                  <div className="col-sm-3 col-xl-2 d-flex flex-column text-xs text-grey-1000">
                    <span className="label font-weight-bold mb-2">
                      Bank Account Name
                    </span>
                    <p className="description mb-0 break-word">
                      {_.get(companyInfo, 'attributes.bank_account_name') ||
                        '--'}
                    </p>
                  </div>
                  <div className="col-sm-3 col-xl-2 d-flex flex-column text-xs text-grey-1000">
                    <span className="label font-weight-bold mb-2">
                      Bank Account Number
                    </span>
                    <p className="description mb-0 break-word">
                      {_.get(companyInfo, 'attributes.bank_account_number') ||
                        '--'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AccountInformation />
          <ViewDocument />
        </div>
      )}
    </>
  );
};

AccountInformationPage.defaultProps = {
  dealer_id: '',
};

AccountInformationPage.propTypes = {
  dealer_id: PropTypes.string,
};

export default AccountInformationPage;
