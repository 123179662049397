import * as req from "api/actions";
import { downloadGet, rootApi } from "helper";
import { ToastWarn, ToastSuccess } from "modules/common/components/Toast";
import * as c from "./constant";

export const validateSim = (payload, callback, errCallback) =>
  req.create(
    c.VALIDATE_RETAILER_SIM,
    "mobile/api/v2/validate-sim",
    payload,
    callback,
    {},
    errCallback
  );

export const retryFailedSim = (id, payload, callback, errCallback) =>
  req.update(
    c.RETRY_FAILED_SIM,
    `api/v1/dealer-sims/approve/store-evc/${id}`,
    payload,
    callback,
    {},
    errCallback
  );

export const setOtp = (payload, callback, errCallback) =>
  req.create(
    c.SET_OTP,
    "external/api/v1/otp/send",
    { ...payload, purpose: "portal-verification", type: "sms" },
    callback,
    {},
    errCallback
  );

export const inquireRetailerBalance = (simId, payload, callback) =>
  req.create(
    c.RETAILER_BALANCE_INQUIRE,
    `api/v1/history/retailer-sim/${simId}`,
    payload,
    callback
  );

export const downloadSimHistory = (id, params) => {
  ToastWarn("Generating export file...");
  downloadGet({
    url: `${rootApi}api/v1/history/dealer_sims/${id}`,
    params,
    filename: "Dealer Sim History.csv",
    onSuccess: () => {
      ToastSuccess("Export success!");
    },
  });
  return { type: "DOWNLOAD_SIM_HISTORY" };
};

export const confirmOtp = (payload, callback, errCallback) =>
  req.create(
    c.CONFIRM_OTP,
    "api/v1/external/otp/verify",
    { ...payload, purpose: "portal-verification" },
    callback,
    {},
    errCallback
  );

//  Retailer Sims
export const createRetailerSims = (payload, callback = () => {}, errCallback) =>
  req.create(
    c.CREATE_RETAILER_SIMS,
    "api/v1/dealer-retailer-sims",
    payload,
    callback,
    {},
    errCallback
  );

export const getRetailerSims = (payload, callback) =>
  req.list(
    c.GET_RETAILER_SIMS,
    "api/v1/dealer-retailer-sims?per_page=1000",
    payload,
    callback
  );

export const retailerSimsGetRetailerSims = (payload, callback) =>
  req.list(
    c.RETAILER_SIMS_GET_RETAILER_SIMS,
    "api/v1/dealer-retailer-sims",
    payload,
    callback
  );

export const retailerSimsAll = (payload, callback) =>
  req.list(
    c.RETAILER_SIMS_GET_RETAILER_SIMS_ALL,
    "api/v1/dealer-retailer-sims",
    { ...payload, per_page: 9999 },
    callback
  );

export const showRetailerSims = (Id, payload, callback) =>
  req.item(
    c.SHOW_RETAILER_SIMS,
    `api/v1/retailer_sims/${Id}`,
    payload,
    callback
  );

export const updateRetailerSims = (Id, payload, callback, errCallback) =>
  req.update(
    c.UPDATE_RETAILER_SIMS,
    `api/v1/retailer_sims/${Id}`,
    payload,
    callback,
    {},
    errCallback
  );

export const retailerSimsUpdateRetailerSims = (
  Id,
  payload,
  callback,
  errCallback
) =>
  req.update(
    c.RETAILER_SIMS_UPDATE_RETAILER_SIMS,
    `api/v1/dealer-retailer-sims/${Id}`,
    payload,
    callback,
    {},
    errCallback
  );

export const deleteRetailerSims = (Id, callback, errCallback) =>
  req.remove(
    c.DELETE_RETAILER_SIMS,
    `api/v1/dealer-retailer-sims/${Id}`,
    callback,
    {},
    {},
    errCallback
  );

export const removeDealerSims = (id, callback, errCallback) =>
  req.remove(
    c.REMOVE_DEALER_SIM,
    `api/v1/dealer-sims/${id}`,
    callback,
    {},
    {},
    errCallback
  );

//  Dealer Sims
export const getDealerSims = (payload, callback) =>
  req.list(c.GET_DEALER_SIMS, "api/v1/dealer-sims", payload, callback);

export const getDealerSimsAll = (payload, callback) =>
  req.list(
    c.GET_DEALER_SIMS_ALL,
    "api/v1/dealer-sims",
    { ...payload, per_page: 9999 },
    callback
  );

export const removeRetailerSims = (id, callback, errCallback) =>
  req.remove(
    c.REMOVE_RETAILER_SIM,
    `api/v1/retailers/${id}`,
    callback,
    {},
    {},
    errCallback
  );

export const updateDealerSims = (Id, payload, callback, errCallback) =>
  req.update(
    c.UPDATE_DEALER_SIMS,
    `api/v1/dealer-sims/${Id}`,
    payload,
    callback,
    {},
    errCallback
  );

export const createDealerSims = (payload, callback, errCallback) =>
  req.create(
    c.CREATE_DEALER_SIMS,
    "api/v1/dealer-sims",
    payload,
    callback,
    {},
    errCallback
  );

// Dealer List
export const createDealer = (payload, callback, errCallback) =>
  req.create(
    c.CREATE_DEALER,
    "api/v1/dealers",
    payload,
    callback,
    {},
    errCallback
  );

export const updateDealer = (Id, payload, callback, errCallback) =>
  req.update(
    c.UPDATE_DEALER,
    `api/v1/dealers/${Id}`,
    payload,
    callback,
    {},
    errCallback
  );

export const updateCompany = (Id, payload, callback, errCallback) =>
  req.update(
    c.UPDATE_COMPANY,
    `dealer/api/v1/dealers/${Id}/companies`,
    payload,
    callback,
    {},
    errCallback
  );

export const getSelectedCompanyById = (dealer_id, payload, callback) =>
  req.item(
    c.GET_SELECTED_COMPANY_BY_ID,
    `dealer/api/v1/dealers/${dealer_id}/companies`,
    payload,
    callback
  );

export const getDealerList = (params, callback) =>
  req.list(c.GET_DEALER_LIST, "api/v1/dealers", params, callback);

export const getSelectedDealerById = (Id, payload, callback) =>
  req.item(
    c.GET_SELECTED_DEALER_BY_ID,
    `api/v1/dealers/${Id}`,
    payload,
    callback
  );

export const getAccountType = (Id, payload, callback) =>
  req.item(c.GET_ACCOUNT_TYPE, `api/v1/account_types/${Id}`, payload, callback);

// Retailer Sims (multiple)
export const getRetailersList = (params, callback) =>
  req.list(c.GET_RETAILERS_LIST, "external/api/v1/retailers", params, callback);

export const createRetailerMultipleSim = (
  retailerId,
  payload,
  callback,
  errCallback
) =>
  req.create(
    c.CREATE_RETAILER_MULTIPLE_SIMS,
    `retailer/api/v1/retailers/${retailerId}/sims`,
    payload,
    callback,
    {},
    errCallback
  );
// List multiple sims by retailer ID
export const getSimsByRetailerId = (retailerId, params) =>
  req.list(
    c.GET_RETAILER_SIMS_BY_ID,
    `retailer/api/v1/retailers/${retailerId}/sims`,
    params
  );

export const updateSimByRetailerId = (
  retailerId,
  payload,
  callback,
  errCallback
) =>
  req.update(
    c.UPDATE_RETAILER_SIMS_BY_ID,
    `retailer/api/v1/retailers/${retailerId}/sims`,
    payload,
    callback,
    {},
    errCallback
  );

export const detachRetailerSim = (retailerId, payload, callback, errCallback) =>
  req.remove(
    c.DETACH_RETAILER_SIMS_BY_ID,
    `retailer/api/v1/retailers/${retailerId}/sims`,
    callback,
    {},
    payload,
    errCallback
  );

export const downloadRetailerSimHistory = (id, params) => {
  ToastWarn("Generating export file...");
  downloadGet({
    url: `${rootApi}api/v1/history/retailer-sim/${id}`,
    params,
    filename: "Retailer Sim History.csv",
    onSuccess: () => {
      ToastSuccess("Export success!");
    },
  });
  return { type: "DOWNLOAD_SIM_HISTORY" };
};

export const validatePassword = (payload, callback, errCallback) =>
  req.create(
    c.VALIDATE_PASSWORD,
    "api/v1/external/users/my/validate-password",
    payload,
    callback,
    {},
    errCallback
  );

export const getRoleBySelectedDealerById = (uuid, payload, callback) =>
  req.item(
    c.GET_ROLE_BY_SELECTED_DEALER_BY_ID,
    `api/v1/acl/users/${uuid}/roles`,
    payload,
    callback
  );
