import React from 'react';
import PropTypes from 'prop-types';
import OverflowTip from 'modules/common/components/OverflowTip';

const CardItem = ({ label, description, status, descriptionClassName }) => (
  <div>
    <div
      className="font-weight-bold mb-2"
      style={{ fontSize: '.6rem', color: 'rgba(0, 0, 0, .6)' }}
    >
      {label}
    </div>
    {status !== 'yes' ? (
      <OverflowTip
        id={description}
        text={description || ''}
        className={`${descriptionClassName} `}
        toUpperCase
        style={{ fontSize: '.7rem', color: 'rgba(0, 0, 0, .7)' }}
      />
    ) : (
      <p
        className={`mb-0 font-weight-bold ${
          description ? 'text-success' : 'text-secondary'
        }`}
        style={{ fontSize: '.7rem', color: 'rgba(0, 0, 0, .7)' }}
      >
        {description ? 'Registered' : 'Not Registered'}
      </p>
    )}
  </div>
);

CardItem.defaultProps = {
  status: '',
  descriptionClassName: 'mb-0 text-capitalize',
};

CardItem.propTypes = {
  status: PropTypes.string,
  descriptionClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default CardItem;
