import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { ToastSuccess } from 'modules/common/components/Toast';
import { ModalContext } from 'App';
import withModal from 'modules/common/hoc/withModal';
import ReactInputMask from 'react-input-mask';
import ButtonAsync from 'components/Button';
import { removeEmpty, getMaskContactNo } from 'helper';

import * as c from '../constants';
import * as actions from '../actions';

const UpdateMoaSignatoryInformation = () => {
  const setModal = React.useContext(ModalContext);
  const dispatch = useDispatch();

  const modalData = useSelector(({ modal }) => _.get(modal, 'modalData'));
  const dealer_company_id = _.get(modalData, 'company_id');
  const dealer_id = _.get(modalData, 'dealer_id');

  const signatoryInformation = _.get(modalData, 'signatoryInformation');
  const otherSignatories = _.get(modalData, 'otherSignatories');

  const [signatoryInitialData, setSignatory] = useState(
    c.moaSignatoryDefaultValue
  );
  const [authorizeOneInitialData, setAuthorizeOne] = useState();
  const [authorizeTwoInitialData, setAuthorizeTwo] = useState();
  const [authorizeThreeInitialData, setAuthorizeThree] = useState();
  const [pending, setPending] = useState(false);

  const handleCloseModal = () => setModal({ isOpen: false });

  const handleSignatory = ({ target }) =>
    setSignatory({
      ...signatoryInitialData,
      [target.name]: target.value,
    });

  const handleAuthorizeOne = ({ target }) =>
    setAuthorizeOne({
      ...authorizeOneInitialData,
      [target.name]: target.value,
    });

  const handleAuthorizeTwo = ({ target }) =>
    setAuthorizeTwo({
      ...authorizeTwoInitialData,
      [target.name]: target.value,
    });

  const handleAuthorizeThree = ({ target }) =>
    setAuthorizeThree({
      ...authorizeThreeInitialData,
      [target.name]: target.value,
    });

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);

    const signatory = [
      signatoryInitialData,
      authorizeOneInitialData
        ? {
            ...authorizeOneInitialData,
            management_account_step: 5,
            type: 'OTHER_SIGNATORY',
          }
        : '',
      authorizeTwoInitialData
        ? {
            ...authorizeTwoInitialData,
            management_account_step: 5,
            type: 'OTHER_SIGNATORY',
          }
        : '',
      authorizeThreeInitialData
        ? {
            ...authorizeThreeInitialData,
            management_account_step: 5,
            type: 'OTHER_SIGNATORY',
          }
        : '',
    ];

    const payload = {
      dealer_id,
      signatories: removeEmpty(signatory),
    };

    const successCallback = () => {
      dispatch(
        actions.showMoaSignatoryInformation(dealer_id, dealer_company_id)
      );
      setPending(false);
      handleCloseModal();
      ToastSuccess('Successfully Updated Moa Signatory Information');
    };

    const errCallback = () => setPending(false);

    dispatch(
      actions.updateMoaSignatories(
        dealer_company_id,
        removeEmpty(payload),
        successCallback,
        errCallback
      )
    );
  };

  useEffect(() => {
    if (!_.isEmpty(signatoryInformation)) {
      const attributes = _.get(signatoryInformation, '0.attributes');
      setSignatory({
        ...signatoryInitialData,
        first_name: _.get(attributes, 'first_name'),
        middle_name: _.get(attributes, 'middle_name'),
        last_name: _.get(attributes, 'last_name'),
        contact_number: _.get(attributes, 'contact_number').replace(/-/g, ''),
        signatory_id: _.get(signatoryInformation, '0.id'),
      });
    }

    if (!_.isEmpty(otherSignatories[0])) {
      setAuthorizeOne({
        first_name: _.get(otherSignatories, '0.attributes.first_name'),
        middle_name: _.get(otherSignatories, '0.attributes.middle_name'),
        last_name: _.get(otherSignatories, '0.attributes.last_name'),
        position: _.get(otherSignatories, '0.attributes.position'),
        email: _.get(otherSignatories, '0.attributes.email'),
        contact_number: _.get(otherSignatories, '0.attributes.contact_number'),
        signatory_id: _.get(otherSignatories, '0.id'),
        management_account_step: 5,
        type: 'OTHER_SIGNATORY',
      });
    }

    if (!_.isEmpty(otherSignatories[1])) {
      setAuthorizeTwo({
        first_name: _.get(otherSignatories, '1.attributes.first_name'),
        middle_name: _.get(otherSignatories, '1.attributes.middle_name'),
        last_name: _.get(otherSignatories, '1.attributes.last_name'),
        position: _.get(otherSignatories, '1.attributes.position'),
        email: _.get(otherSignatories, '1.attributes.email'),
        contact_number: _.get(otherSignatories, '1.attributes.contact_number'),
        signatory_id: _.get(otherSignatories, '1.id'),
        management_account_step: 5,
        type: 'OTHER_SIGNATORY',
      });
    }

    if (!_.isEmpty(otherSignatories[2])) {
      setAuthorizeThree({
        first_name: _.get(otherSignatories, '2.attributes.first_name'),
        middle_name: _.get(otherSignatories, '2.attributes.middle_name'),
        last_name: _.get(otherSignatories, '2.attributes.last_name'),
        position: _.get(otherSignatories, '2.attributes.position'),
        email: _.get(otherSignatories, '2.attributes.email'),
        contact_number: _.get(otherSignatories, '2.attributes.contact_number'),
        signatory_id: _.get(otherSignatories, '2.id'),
        management_account_step: 5,
        type: 'OTHER_SIGNATORY',
      });
    }
  }, [otherSignatories, signatoryInformation]);

  return (
    <div className="p-2">
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <h6 className="h6 m-0 text-xs font-weight-bold">
            Signatory Information
          </h6>
        </div>
        <div className="row mb-3">
          <div className="col-3 mb-2">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              First Name *
            </label>
            <input
              type="text"
              name="first_name"
              autoFocus
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              required
              value={_.get(signatoryInitialData, 'first_name', '')}
              onChange={handleSignatory}
              style={c.inputStyles}
            />
          </div>

          <div className="col-3 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Middle Initial
            </label>
            <input
              type="text"
              name="middle_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(signatoryInitialData, 'middle_name', '')}
              onChange={handleSignatory}
              style={c.inputStyles}
            />
          </div>

          <div className="col-3 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Last Name *
            </label>
            <input
              type="text"
              name="last_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              required
              value={_.get(signatoryInitialData, 'last_name', '')}
              onChange={handleSignatory}
              style={c.inputStyles}
            />
          </div>

          <div className="col-3 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Contact No.* <br />
              <span className="font-weight-normal">
                (Mobile: 09XXXXXXXXXX / Landline: 02-XXXX-XXXX)
              </span>
            </label>
            <ReactInputMask
              placeholder="Enter"
              mask={getMaskContactNo(
                _.get(signatoryInitialData, 'contact_number', '')
              )}
              required
              maskChar=""
              className="form-control form-custom form-control_bottom px-3"
              name="contact_number"
              value={_.get(signatoryInitialData, 'contact_number', '')}
              onChange={handleSignatory}
              style={c.inputStyles}
            />
          </div>
        </div>

        <div className="mb-3">
          <h6 className="h6 m-0 text-xs font-weight-bold">
            Other Authorized Signatories
          </h6>
        </div>
        <div className="row mb-3">
          <div className="col-2 mb-2">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              First Name *
            </label>
            <input
              type="text"
              name="first_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(authorizeOneInitialData, 'first_name', '')}
              onChange={handleAuthorizeOne}
              style={c.inputStyles}
            />
          </div>

          <div className="col-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Middle Initial
            </label>
            <input
              type="text"
              name="middle_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(authorizeOneInitialData, 'middle_name', '')}
              onChange={handleAuthorizeOne}
              style={c.inputStyles}
            />
          </div>

          <div className="col-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Last Name *
            </label>
            <input
              name="last_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(authorizeOneInitialData, 'last_name', '')}
              onChange={handleAuthorizeOne}
              style={c.inputStyles}
            />
          </div>

          <div className="col-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Position *
            </label>
            <input
              name="position"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(authorizeOneInitialData, 'position', '')}
              onChange={handleAuthorizeOne}
              style={c.inputStyles}
            />
          </div>

          <div className="col-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Email *
            </label>
            <input
              type="email"
              name="email"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(authorizeOneInitialData, 'email', '')}
              onChange={handleAuthorizeOne}
              style={c.inputStyles}
            />
          </div>

          <div className="col-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Contact No. <br />
              <span className="font-weight-normal">
                (Mobile: 09XXXXXXXXXX / Landline: 02-XXXX-XXXX)
              </span>
            </label>
            <ReactInputMask
              placeholder="Enter"
              mask={getMaskContactNo(
                _.get(authorizeOneInitialData, 'contact_number', '')
              )}
              maskChar=""
              className="form-control form-custom form-control_bottom px-3"
              name="contact_number"
              value={_.get(authorizeOneInitialData, 'contact_number', '')}
              onChange={handleAuthorizeOne}
              style={c.inputStyles}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-2 mb-2">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              First Name *
            </label>
            <input
              type="text"
              name="first_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(authorizeTwoInitialData, 'first_name', '')}
              onChange={handleAuthorizeTwo}
              style={c.inputStyles}
            />
          </div>

          <div className="col-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Position *
            </label>
            <input
              type="text"
              name="middle_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(authorizeTwoInitialData, 'middle_name', '')}
              onChange={handleAuthorizeTwo}
              style={c.inputStyles}
            />
          </div>

          <div className="col-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Middle Initial
            </label>
            <input
              name="last_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(authorizeTwoInitialData, 'last_name', '')}
              onChange={handleAuthorizeTwo}
              style={c.inputStyles}
            />
          </div>

          <div className="col-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Position *
            </label>
            <input
              name="position"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(authorizeTwoInitialData, 'position', '')}
              onChange={handleAuthorizeTwo}
              style={c.inputStyles}
            />
          </div>

          <div className="col-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Email *
            </label>
            <input
              type="email"
              name="email"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(authorizeTwoInitialData, 'email', '')}
              onChange={handleAuthorizeTwo}
              style={c.inputStyles}
            />
          </div>

          <div className="col-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Contact No. <br />
              <span className="font-weight-normal">
                (Mobile: 09XXXXXXXXXX / Landline: 02-XXXX-XXXX)
              </span>
            </label>
            <ReactInputMask
              placeholder="Enter"
              mask={getMaskContactNo(
                _.get(authorizeTwoInitialData, 'contact_number', '')
              )}
              maskChar=""
              className="form-control form-custom form-control_bottom px-3"
              name="contact_number"
              value={_.get(authorizeTwoInitialData, 'contact_number', '')}
              onChange={handleAuthorizeTwo}
              style={c.inputStyles}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-2 mb-2">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              First Name *
            </label>
            <input
              type="text"
              name="first_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(authorizeThreeInitialData, 'first_name', '')}
              onChange={handleAuthorizeThree}
              style={c.inputStyles}
            />
          </div>

          <div className="col-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Middle Initial
            </label>
            <input
              type="text"
              name="middle_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(authorizeThreeInitialData, 'middle_name', '')}
              onChange={handleAuthorizeThree}
              style={c.inputStyles}
            />
          </div>

          <div className="col-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Last Name *
            </label>
            <input
              name="last_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(authorizeThreeInitialData, 'last_name', '')}
              onChange={handleAuthorizeThree}
              style={c.inputStyles}
            />
          </div>

          <div className="col-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Position *
            </label>
            <input
              name="position"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(authorizeThreeInitialData, 'position', '')}
              onChange={handleAuthorizeThree}
              style={c.inputStyles}
            />
          </div>

          <div className="col-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Email *
            </label>
            <input
              type="email"
              name="email"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              value={_.get(authorizeThreeInitialData, 'email', '')}
              onChange={handleAuthorizeThree}
              style={c.inputStyles}
            />
          </div>

          <div className="col-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Contact No. <br />
              <span className="font-weight-normal">
                (Mobile: 09XXXXXXXXXX / Landline: 02-XXXX-XXXX)
              </span>
            </label>
            <ReactInputMask
              placeholder="Enter"
              mask={getMaskContactNo(
                _.get(authorizeThreeInitialData, 'contact_number', '')
              )}
              maskChar=""
              className="form-control form-custom form-control_bottom px-3"
              name="contact_number"
              value={_.get(authorizeThreeInitialData, 'contact_number', '')}
              onChange={handleAuthorizeThree}
              style={c.inputStyles}
            />
          </div>
        </div>

        <div className="button-container d-flex justify-content-end mt-4">
          <ButtonAsync
            className="btn btn-primary position-relative py-2 px-4 mr-2"
            type="submit"
            loading={pending}
          >
            Save
          </ButtonAsync>

          <button
            className="btn bg-grey-100 text-grey-1000 py-2 px-4"
            onClick={handleCloseModal}
            disabled={pending}
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

const modalKey = 'moa-signatory-information-update';
const modalConfig = {
  title: 'Update Memorandum of Agreement Signatory Information',
  size: 'modal-xl',
};

export default withModal(modalKey, modalConfig)(UpdateMoaSignatoryInformation);
