import React, { useState, useMemo } from 'react';
import withModal from 'modules/common/hoc/withModal';
import _ from 'lodash';
import { ModalContext } from 'App';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ToastSuccess } from 'modules/common/components/Toast';
import TimePicker from 'modules/common/components/TimePicker';
import ButtonAsync from 'components/Button';
import * as actions from '../action';
import * as c from '../constant';

function CreateShiftForm({ createShiftSchedule, getShiftSchedule, isLoading }) {
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  const formInit = {
    name: '',
    description: '',
    days: [],
    start_time: '08:00:00',
    end_time: '16:00:00',
  };
  const [formData, setFormData] = React.useState(formInit);
  const setModal = React.useContext(ModalContext);
  const [pending, setPending] = useState(false);

  const { name } = formData;

  const closeModal = e => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleChangeInput = key => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value,
    });
  };

  const handleChangeTime = ({ key, value }) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleToggleDay = (day, checked) => e => {
    e.preventDefault();
    setFormData({
      ...formData,
      days: checked
        ? (formData.days || []).filter(d => d !== day)
        : (formData.days || []).concat([day]),
    });
  };

  const isInputEmpty = useMemo(
    () => formData.days.length < 1 || _.isEmpty(name),
    [formData]
  );

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);
    const payload = {
      ...formData,
    };
    createShiftSchedule(
      payload,
      () => {
        getShiftSchedule();
        ToastSuccess('Successfully created Selling Window.');
        setModal({ isOpen: false });
        setPending(false);
      },
      () => setPending(false)
    );
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <label className="form-label text-success">
            Selling Window Name:
          </label>
          <input
            autoFocus
            type="text"
            name="name"
            required
            onChange={handleChangeInput('name')}
            value={_.get(formData, 'name') || ''}
            className="form-control form-custom form-control_bottom"
            maxLength={c.FIELD_MAX_LIMIT}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="d-flex align-items-center mb-2 mt-2">
            <span className="flex-grow-1 mt-0 mb-0 font-weight-bold">
              Select Days:
            </span>
          </div>
          <div className="row">
            {days.map(day => (
              <div className="form-group col-lg-6" key={day}>
                <a
                  className="text-dark"
                  href="/"
                  onClick={handleToggleDay(
                    day,
                    formData.days.indexOf(day) > -1
                  )}
                >
                  {formData.days.indexOf(day) > -1 ? (
                    <i className="fa fa-check-square text-success" />
                  ) : (
                    <i className="far fa-square" />
                  )}
                  <span className="ml-1">{day}</span>
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex align-items-center mb-2 mt-2">
            <span className="flex-grow-1 mt-0 mb-0 font-weight-bold">
              Select Time:
            </span>
          </div>
          <div className="row">
            <div className="col-12">
              <label className="form-label text-success">Shift Start at:</label>
              <TimePicker
                hourOptParam={{ floor: 1, ceiling: 12 }}
                minuteOptParam={{ floor: 0, ceiling: 59 }}
                timeStateParam={{
                  key: 'start_time',
                  value: formData.start_time,
                }}
                setTimeStateParam={handleChangeTime}
                nameIdPrefixParam="start"
              />
            </div>
            <div className="col-12">
              <label className="form-label text-success">Shift Ends at:</label>
              <TimePicker
                hourOptParam={{ floor: 1, ceiling: 12 }}
                minuteOptParam={{ floor: 0, ceiling: 59 }}
                timeStateParam={{ key: 'end_time', value: formData.end_time }}
                setTimeStateParam={handleChangeTime}
                nameIdPrefixParam="end"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <ButtonAsync
              loading={isLoading}
              disabled={isInputEmpty || pending}
              type="submit"
            >
              Save
            </ButtonAsync>
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

CreateShiftForm.propTypes = {
  createShiftSchedule: PropTypes.func.isRequired,
  getShiftSchedule: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ api }) => ({
  isLoading: api.loading.indexOf(c.CREATE_SHIFT) > -1,
});

const enhance = _.flowRight([
  withModal('create-shift', {
    title: 'Create Selling Window',
    size: 'modal-lg',
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(CreateShiftForm);
