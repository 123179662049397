import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { ModalContext } from "App";
import Button from "components/Button";
import { ToastError } from "modules/common/components/Toast";
import ErrorMessage from "modules/common/components/ErrorMessage";
import withModal from "modules/common/hoc/withModal";
import { verifyPassword } from "app/load-wallet/actions";

const ConfirmPassword = ({ callBack }) => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const setModal = useContext(ModalContext);

  const errorCallback = (err, defaultMessage) => {
    const errorMessage = (
      <ErrorMessage error={err} defaultMessage={defaultMessage} />
    );
    ToastError(errorMessage);
    setModal({ isOpen: false });
  };

  const close = () => setModal({ isOpen: false });

  const { mutate: verify } = useMutation(verifyPassword, {
    onSuccess: (res) => {
      callBack(res)
        .then(close)
        .catch(close);
    },
    onError: (err) => errorCallback(err, "Incorrect password."),
    retry: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    verify(password);
  };

  const handleModalClose = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleFieldVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="prepend-form-right">
            <h5 className="font-weight-bolder my-2">IMPORTANT!</h5>
            <p className="mb-4">Enter your password to authorize action</p>
            <div className="form-group">
              <div className="prepend-form-right">
                <label className="form-label mb-2">Password *</label>
                <div className="prepend-input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control input-textbox password with-pass-icon form-custom form-control_bottom "
                    required
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="off"
                  />
                </div>
                <button
                  type="button"
                  onClick={handleFieldVisibility}
                  className="btn btn-default rounded-circle p-0 mr-1 outline-none"
                >
                  <i
                    className={`fas ${
                      !showPassword ? "fa-eye" : "fa-eye-slash"
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-5 d-flex align-items-center justify-content-end">
            <Button
              type="submit"
              className="btn btn-primary position-relative ml-1"
              loading={loading}
            >
              Confirm
            </Button>
            <button
              type="button"
              onClick={handleModalClose}
              className="btn btn-danger font-weight-bold ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

ConfirmPassword.propTypes = {
  callBack: PropTypes.func.isRequired,
};

const modalKey = "confirm-password-setup";
const modalConfig = {
  title: "Password Confirmation",
  size: "modal-md modal-center",
};

export default withModal(modalKey, modalConfig)(ConfirmPassword);
