import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import Pager from 'react-pager';

class Pagination extends Component {
  static propTypes = {
    data: PropTypes.instanceOf(Object).isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func,
    maxPages: PropTypes.number,
    withPage: PropTypes.bool,
  };

  static defaultProps = {
    onChange: () => {},
    className: 'd-flex justify-content-center p-2 border-top border-muted',
    maxPages: 3,
    withPage: false,
  };

  handleOnChange = page => {
    const { onChange } = this.props;
    onChange(page + 1);
  };

  render() {
    const { data, className, maxPages, withPage } = this.props;
    if (!_isEmpty(data) && data.total_pages > 1) {
      return (
        <div className={`${className}`}>
          {withPage && (
            <span>
              Page {data?.current_page} of {data.total_pages}
            </span>
          )}
          <Pager
            className="mb-0"
            total={data.total_pages}
            current={+data.current_page - 1}
            visiblePages={maxPages}
            titles={{
              first: <i className="fa fa-angle-double-left" />,
              next: <i className="fa fa-angle-right" />,
              prev: <i className="fa fa-angle-left" />,
              last: <i className="fa fa-angle-double-right" />,
            }}
            onPageChanged={this.handleOnChange}
          />
        </div>
      );
    }
    return null;
  }
}

export default Pagination;
