import React, { useContext, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ReactInputMask from 'react-input-mask';
import { connect, useDispatch } from 'react-redux';
import { ModalContext } from 'App';
import withModal from 'modules/common/hoc/withModal';
import { ToastSuccess, ToastWarn } from 'modules/common/components/Toast';
import { getPinStatus } from 'modules/auth/actions';

import Button from 'components/Button';
import * as actions from '../actions';

function TogglePin({
  updateStatus,
  updatePin,
  confirmPassword,
  payload,
  profile,
}) {
  const dispatch = useDispatch();

  const setModal = useContext(ModalContext);
  const [state, setState] = useState({});
  const [view, setView] = useState(false);
  const [pending, setPending] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);
    confirmPassword(
      state,
      () =>
        updatePin(
          { is_pin_enabled: !_.get(payload, 'is_pin_enabled'), ...state },
          () => {
            updateStatus({
              ...profile,
              is_pin_enabled: !_.get(payload, 'is_pin_enabled'),
            });
            dispatch(getPinStatus());
            setPending(false);
            setModal({ isOpen: false });
            if (_.get(payload, 'is_pin_enabled'))
              ToastWarn(`Your Security PIN has been DISABLED`);
            else ToastSuccess(`Your Security PIN has been ENABLED`);
          },
          () => {
            setModal({ isOpen: false });
            setPending(false);
          }
        ),
      () => {
        setState({ password: '' });
        setPending(false);
      }
    );
  };
  const handleChangeInput = key => ({ target }) => {
    setState({
      ...state,
      [key]: target.value,
    });
  };
  const closeModal = e => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="prepend-form-right">
            <label className="form-label">
              Are you sure you want to{' '}
              {_.get(payload, 'is_pin_enabled') ? 'DISABLE' : 'ENABLE'} this
              account&apos;s Security PIN?
            </label>
            <small className="text-muted">
              Your password is required to continue.
            </small>
            <ReactInputMask
              type={view ? 'text' : 'password'}
              placeholder="Insert Password"
              maskChar=""
              className="form-control form-custom form-control_bottom my-2"
              required
              onChange={handleChangeInput('password')}
              value={state.password || ''}
            />
            <button
              type="button"
              onClick={() => setView(!view)}
              className="btn btn-default rounded-circle p-0 mr-1"
            >
              <i className={`fas ${!view ? 'fa-eye' : 'fa-eye-slash'}`} />
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <Button
              type="submit"
              className="btn btn-primary position-relative font-weight-bold mr-2"
              loading={pending}
            >
              Confirm
            </Button>
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

TogglePin.propTypes = {
  updateStatus: PropTypes.func.isRequired,
  updatePin: PropTypes.func.isRequired,
  confirmPassword: PropTypes.func.isRequired,
  payload: PropTypes.instanceOf(Object).isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ api, modal }) => ({
  setFormData: _.get(modal, 'modalData.setFormData'),
  payload: _.get(modal, 'modalData.payload'),
  profile: _.get(api, 'AUTH/user_profile.item') || {},
});

const enhance = _.flowRight([
  withModal('toggle-pin', {
    title: 'Toggle Security Pin',
    size: 'modal-sm',
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(TogglePin);
