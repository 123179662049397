import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const populateTimeArrays = ({ floor, ceiling }) => {
  const timeArray = [];
  for (let index = floor; index <= ceiling; index += 1) {
    const timeValue = `0${index}`.slice(-2);
    timeArray.push({ value: timeValue, label: timeValue });
  }
  return timeArray;
};

const convertTimeTo12 = time24 => {
  const hourStr = time24.slice(0, 2);
  const minuteStr = time24.slice(3, 5);
  const hourInt = parseInt(hourStr, 10);
  if (hourInt > 0 && hourInt < 12) {
    return { hour: hourStr, minute: minuteStr, suffix: 'am' };
  }
  if (hourInt > 12 && hourInt < 24) {
    return {
      hour: `0${hourInt - 12}`.slice(-2),
      minute: minuteStr,
      suffix: 'pm',
    };
  }
  if (hourStr === '00') {
    return { hour: '12', minute: minuteStr, suffix: 'am' };
  }
  return { hour: '12', minute: minuteStr, suffix: 'pm' };
};

const convertTimeTo24 = time12 => {
  let hour = `${time12.hour}`;
  if (time12.suffix === 'pm' && hour !== '12') {
    hour = `${parseInt(time12.hour, 10) + 12}`;
    return `${hour}:${time12.minute}:00`;
  }
  if (time12.suffix === 'am' && hour === '12') {
    hour = '00';
    return `${hour}:${time12.minute}:00`;
  }
  return `${hour}:${time12.minute}:00`;
};

function TimePicker(props) {
  const {
    hourOptParam,
    minuteOptParam,
    nameIdPrefixParam,
    timeStateParam,
    setTimeStateParam,
  } = props;

  const hoursOptions = populateTimeArrays(hourOptParam);
  const minutesOptions = populateTimeArrays(minuteOptParam);
  const suffixOptions = [
    { value: 'am', label: 'AM' },
    { value: 'pm', label: 'PM' },
  ];
  const time12 = convertTimeTo12(timeStateParam.value);

  const labelFilter = (option, searchText) => {
    return option.data.label.toLowerCase().includes(searchText.toLowerCase());
  };

  const handleChangeTime = (key, selectedOption) => {
    const updatedTime12 = {
      ...time12,
      [key]: selectedOption.value,
    };
    setTimeStateParam({
      key: timeStateParam.key,
      value: convertTimeTo24(updatedTime12),
    });
  };

  return (
    <div className="row">
      <div className="col-lg mb-1">
        <Select
          name={`${nameIdPrefixParam}_hr`}
          id={`${nameIdPrefixParam}_hr`}
          options={hoursOptions}
          value={hoursOptions.find(h => h.value === time12.hour)}
          placeholder="Hours"
          onChange={selectedOption => {
            handleChangeTime('hour', selectedOption);
          }}
          isSearchable
          filterOption={labelFilter}
        />
      </div>
      <div className="col-lg mb-1">
        <Select
          name={`${nameIdPrefixParam}_min`}
          id={`${nameIdPrefixParam}_min`}
          placeholder="Minutes"
          options={minutesOptions}
          value={minutesOptions.find(m => m.value === time12.minute)}
          onChange={selectedOption => {
            handleChangeTime('minute', selectedOption);
          }}
          isSearchable
          filterOption={labelFilter}
        />
      </div>
      <div className="col-lg mb-1">
        <Select
          name={`${nameIdPrefixParam}_suffix`}
          id={`${nameIdPrefixParam}_suffix`}
          placeholder="AM/PM"
          options={suffixOptions}
          value={suffixOptions.find(s => s.value === time12.suffix)}
          onChange={selectedOption => {
            handleChangeTime('suffix', selectedOption);
          }}
          isSearchable
          filterOption={labelFilter}
        />
      </div>
    </div>
  );
}

TimePicker.propTypes = {
  hourOptParam: PropTypes.shape({
    floor: PropTypes.number.isRequired,
    ceiling: PropTypes.number.isRequired,
    increment: PropTypes.number,
  }).isRequired,
  minuteOptParam: PropTypes.shape({
    floor: PropTypes.number.isRequired,
    ceiling: PropTypes.number.isRequired,
    increment: PropTypes.number,
  }).isRequired,
  timeStateParam: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  setTimeStateParam: PropTypes.func.isRequired,
  nameIdPrefixParam: PropTypes.string,
};

TimePicker.defaultProps = {
  nameIdPrefixParam: 'timePicker',
};

export default TimePicker;
