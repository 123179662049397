export const SHOW_RETAILER = 'DASHBOARD/show_retailer';
export const GET_BENTA_KITA = 'DASHBOARD/get_benta_kita';
export const GET_BENTA_KITA_DEALER = 'DASHBOARD/get_benta_kita_dealer';
export const GET_LOAD_TRANSACTION = 'DASHBOARD/get_load_transaction';
export const GET_BILLS_TRANSACTION = 'DASHBOARD/get_bills_transaction';
export const INQUIRE_BALANCE = 'DASHBOARD/inquire_balance';
export const GET_BRANCH = 'DASHBOARD/get_branch';

export const GET_DEALER_DASHBOARD_TOPS = 'DASHBOARD/get_dealer_dashboard_tops';

export const FORM_DATA = {};
