import React, { useEffect, useContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useLoading } from 'modules/ui/hooks';
import { ModalContext } from 'App';
import NoDataPlaceholder from '../../../../components/NoDataPlaceholder';
import DeliveryAddressInformationUpdate from '../../../modal/DeliveryAddressInformationUpdate';

import * as c from '../../../constants';
import * as actions from '../../../actions';

const DeliveryAddressInformation = ({ dealer_id }) => {
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);
  const deliveryAddressInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_DELIVERY_ADDRESS_INFORMATION}.item`, {})
  );
  const companyInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_COMPANY_INFORMATION}.item`, {})
  );
  const userDetails = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_USER_ACCESS_DETAILS}.item`)
  );

  const isLoading = useLoading(c.SHOW_DELIVERY_ADDRESS_INFORMATION || '');
  const company_id = _.get(companyInfo, 'id', '');
  const companyName = _.get(companyInfo, 'attributes.company_name', '');
  const streetName = _.get(deliveryAddressInfo, 'attributes.street_name', '');

  const isAllowed = useMemo(() => {
    const applicationnStatus = _.get(
      userDetails,
      'attributes.application_status'
    );
    return applicationnStatus === null || applicationnStatus === 'returned';
  }, [userDetails]);

  const companyAddress = useMemo(() => {
    const attributes = _.get(companyInfo, 'attributes', '');
    return {
      unit_building_house_no: _.get(attributes, 'unit_building_house_no', ''),
      postal_no: _.get(attributes, 'postal_no', ''),
      street_name: _.get(attributes, 'street_name', ''),
      province: {
        label: _.get(attributes, 'province_name'),
        value: _.get(attributes, 'province_code'),
      },
      municipality: {
        label: _.get(attributes, 'municipality_name'),
        value: _.get(attributes, 'municipality_code'),
      },
      barangay: {
        label: _.get(attributes, 'barangay_name'),
        value: _.get(attributes, 'barangay_code'),
      },
    };
  }, [companyInfo]);

  const previousDeliveryAddress = useMemo(() => {
    const attributes = _.get(deliveryAddressInfo, 'attributes', '');
    return {
      unit_building_house_no: _.get(attributes, 'unit_building_house_no', ''),
      postal_no: _.get(attributes, 'postal_no', ''),
      street_name: _.get(attributes, 'street_name', ''),
      province:
        {
          label: _.get(attributes, 'province_name'),
          value: _.get(attributes, 'province_code'),
        } || '',
      municipality:
        {
          label: _.get(attributes, 'municipality_name'),
          value: _.get(attributes, 'municipality_code'),
        } || '',
      barangay:
        {
          label: _.get(attributes, 'barangay_name'),
          value: _.get(attributes, 'barangay_code'),
        } || '',
    };
  }, [deliveryAddressInfo]);

  const openUpdateModal = () =>
    setModal('delivery-address-information-update', {
      deliveryAddressInfo,
      dealer_id,
      previousDeliveryAddress,
      companyAddress,
    });

  useEffect(() => {
    dispatch(actions.showDeliveryAddressInformation(dealer_id));
  }, [dealer_id]);

  return (
    <>
      {isLoading ? (
        <div className="w-100 mb-3" style={{ fontSize: 25 }}>
          <div className="bg-light-0 py-3 text-center w-100">
            <span className="fas fa-1x fa-spin fa-spinner" />
          </div>
        </div>
      ) : (
        <>
          {!_.isEmpty(companyName) && (
            <div className="DeliveryAddressInformation mb-4">
              <header className="rounded-top-both bg-grey-800 overflow-hidden text-white  d-flex justify-content-between pl-3">
                <div
                  className="header-title my-auto"
                  style={{ padding: `${!isAllowed ? '.7rem' : ''} 0` }}
                >
                  <h6 className="h6 text-xs font-weight-bold p-0 m-0">
                    Delivery Address Information
                  </h6>
                </div>
                {isAllowed && (
                  <div>
                    <button
                      type="button"
                      className="btn text-xs btn-success rounded-0 py-2"
                      onClick={openUpdateModal}
                      disabled={isLoading || !company_id}
                    >
                      <i className="fas fa-pen mr-2" />
                      Update
                    </button>
                  </div>
                )}
              </header>
              <div className="p-4 bg-white">
                {!_.isEmpty(streetName) ? (
                  <div>
                    <div className="same-container d-flex align-items-center mb-4">
                      <input
                        type="checkbox"
                        name="same_company_address"
                        className="mr-2"
                        checked={_.isEqual(
                          previousDeliveryAddress,
                          companyAddress
                        )}
                        readOnly
                      />
                      <span className="text-xs text-grey-1000 font-weight-bold">
                        {' '}
                        Same as Company Address
                      </span>
                    </div>
                    <div>
                      <div className="row mb-4" style={{ width: '90%' }}>
                        <div className="col-4 d-flex flex-column text-xs text-grey-1000">
                          <span className="label font-weight-bold mb-2">
                            Unit No./ Building No. House No.
                          </span>
                          <p className="description mb-0 break-word">
                            {_.get(
                              deliveryAddressInfo,
                              'attributes.unit_building_house_no'
                            ) || '--'}
                          </p>
                        </div>
                        <div className="col-lg-4 col-xl-3 d-flex flex-column text-xs text-grey-1000">
                          <span className="label font-weight-bold mb-2">
                            Street Name
                          </span>
                          <p className="description mb-0 break-word">
                            {_.get(
                              deliveryAddressInfo,
                              'attributes.street_name'
                            ) || '--'}
                          </p>
                        </div>
                        <div className="col-lg-4 col-xl-3 d-flex flex-column text-xs text-grey-1000">
                          <span className="label font-weight-bold mb-2">
                            Barangay
                          </span>
                          <p className="description mb-0 break-word">
                            {_.get(
                              deliveryAddressInfo,
                              'attributes.barangay_name'
                            ) || '--'}
                          </p>
                        </div>
                      </div>

                      <div className="row mb-4" style={{ width: '90%' }}>
                        <div className="col-4 d-flex flex-column text-xs text-grey-1000">
                          <span className="label font-weight-bold mb-2">
                            City / Municipality.
                          </span>
                          <p className="description mb-0 break-word">
                            {_.get(
                              deliveryAddressInfo,
                              'attributes.municipality_name'
                            ) || '--'}
                          </p>
                        </div>
                        <div className="col-lg-4 col-xl-3 d-flex flex-column text-xs text-grey-1000">
                          <span className="label font-weight-bold mb-2">
                            Province
                          </span>
                          <p className="description mb-0 break-word">
                            {_.get(
                              deliveryAddressInfo,
                              'attributes.province_name'
                            ) || '--'}
                          </p>
                        </div>
                        <div className="col-lg-4 col-xl-3 d-flex flex-column text-xs text-grey-1000">
                          <span className="label font-weight-bold mb-2">
                            ZIP Code
                          </span>
                          <p className="description mb-0 break-word">
                            {_.get(
                              deliveryAddressInfo,
                              'attributes.postal_no'
                            ) || '--'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center">
                    <NoDataPlaceholder />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}

      <DeliveryAddressInformationUpdate />
    </>
  );
};

DeliveryAddressInformation.propTypes = {
  dealer_id: PropTypes.string.isRequired,
};

export default React.memo(DeliveryAddressInformation);
