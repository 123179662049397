import React from "react";
import {
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
  withRouter,
} from "react-router-dom";
import GenericList from "modules/common/components/GenericList";
import Pagination from "modules/common/components/Pagination";
import ButtonAsync from "modules/common/components/ButtonAsync";
import _ from "lodash";
import { connect } from "react-redux";
import { ModalContext } from "App";
import PropTypes from "prop-types";
import { checkPermissions } from "helper";
import CalendarSchedule from "../components/CalendarSchedule";
import CreateShiftForm from "../modalForms/CreateShiftForm";
import * as actions from "../action";
import * as c from "../constant";
import UpdateShiftSchedule from "../modalForms/UpdateShiftSchedule";

import "../styles/ShiftSchedule.style.scss";

const filter = {
  page: 1,
  q: "",
};
function ShiftSchedule({
  getShiftSchedule,
  shiftList,
  isLoading,
  showShiftSchedule,
  shiftDetails,
  pager,
  roles,
  downloadScheduleHistory,
}) {
  const setModal = React.useContext(ModalContext);
  const [filterData, setFilterData] = React.useState(filter);
  const match = useRouteMatch();
  const sub_match = useRouteMatch(`${match.path}/:id`);
  const schedId = _.get(sub_match, "params.id");
  const history = useHistory();
  const parseShiftDetails = (_.get(shiftDetails, "included.shifts") || []).map(
    (x) => ({
      start: new Date(_.get(x, "attributes.start_date")),
      end: new Date(_.get(x, "attributes.end_date")),
      title: _.get(shiftDetails, "attributes.name"),
    })
  );

  const [isLoadingHistory, setIsLoadingHistory] = React.useState(false);

  const handleChangePage = (page) => {
    const newFilter = { ...filterData, page };
    getShiftSchedule(newFilter);
    setFilterData(newFilter);
  };

  const handleChangeInput = (key) => ({ target }) => {
    setFilterData({
      ...filterData,
      [key]: target.value,
    });
    getShiftSchedule({ ...filterData, [key]: _.trim(target.value) });
  };

  const handleSelectRow = (item) => {
    history.push(`${match.url}/${_.get(item, "id")}`);
  };

  const handleCreateShift = (e) => {
    e.preventDefault();
    setModal("create-shift");
  };

  const handleUpdateShift = (item) => (e) => {
    e.preventDefault();
    setModal("update-shift", {
      data: {
        name: _.get(item, "attributes.name"),
        days: (_.get(item, "included.shifts") || []).map((x) =>
          _.get(x, "attributes.day")
        ),
        start_time: _.get(item, "included.shifts.0.attributes.start_time"),
        end_time: _.get(item, "included.shifts.0.attributes.end_time"),
      },
    });
  };

  const handleDownloadScheduleHistory = async (e) => {
    e.preventDefault();
    downloadScheduleHistory(schedId, {}, setIsLoadingHistory);
  };

  React.useEffect(() => {
    getShiftSchedule(filterData);
    if (!_.isEmpty(schedId)) {
      showShiftSchedule(schedId);
    }
  }, [schedId, getShiftSchedule, showShiftSchedule]);

  return (
    <div className="main-content-inner p-0 h-100 retailMgmt-sellingWindow">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-3 h-100 px-0">
            <div className="app-listing d-flex flex-column h-100">
              <div className="app-search shadow-sm px-3 mb-2">
                <div className="form-group">
                  <label className="form-label text-primary">
                    Selling Window:
                  </label>
                  <input
                    className="form-control input-textbox"
                    type="text"
                    placeholder="Search Selling Window.."
                    value={_.get(filterData, "q") || ""}
                    onChange={handleChangeInput("q")}
                  />
                </div>
                {checkPermissions(["can-create-schedule"]) && (
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-primary btn-block py-2 text-uppercase"
                      onClick={handleCreateShift}
                    >
                      Create Shift
                    </button>
                  </div>
                )}
              </div>
              <div className="app-list shadow-sm flex-grow-1 mb-2">
                <div className="box-fit-wrapper h-100">
                  <div className="box-fit-overflow">
                    <Route
                      path={`${match.path}/:id?`}
                      render={({ match: { params } }) => (
                        <GenericList
                          label=""
                          labelKey={(item) => (
                            <div className="d-flex align-items-center p-2">
                              <div className="details">
                                <div className="schedule-title">
                                  {_.truncate(_.get(item, "attributes.name"), {
                                    length: c.TRUNCATE_LIMIT,
                                  })}
                                </div>
                              </div>
                              {checkPermissions(["can-update-schedule"]) && (
                                <button
                                  type="button"
                                  className="btn btn-warning btn-sm ml-auto"
                                  onClick={handleUpdateShift(item)}
                                >
                                  <i className="fas fa-pencil-alt" />
                                </button>
                              )}
                            </div>
                          )}
                          rowsCount={pager.total || false}
                          isLoading={isLoading}
                          selectedKey="id"
                          data={shiftList}
                          selected={params.id}
                          onSelectRow={handleSelectRow}
                        />
                      )}
                    />
                    <Pagination onChange={handleChangePage} data={pager} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${
              _.get(roles, "0")?.includes("frontliner")
                ? "col-12 h-100 px-0"
                : "col-9 h-100 px-0"
            }`}
          >
            <div className="schedule-section h-100 p-4">
              <div className="row px-3 pb-4">
                <h5 className="mt-0 mb-4 font-weight-bold">Selling Window</h5>
                <ButtonAsync
                  appendClass="btn-warning ml-auto"
                  onClick={handleDownloadScheduleHistory}
                  loading={isLoadingHistory}
                >
                  History
                </ButtonAsync>
              </div>
              <div className="calendar-view retailMgmt-sellingWindow__calendar-container scroll-lime">
                <CalendarSchedule eventList={parseShiftDetails} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Route
        path={`${match.path}/:id?`}
        render={({ match: { params } }) =>
          !params.id && shiftList.length > 0 ? (
            <Redirect to={`${match.url}/${_.get(shiftList, "0.id")}`} />
          ) : null
        }
      />
      <CreateShiftForm />
      <UpdateShiftSchedule />
    </div>
  );
}

ShiftSchedule.propTypes = {
  getShiftSchedule: PropTypes.func.isRequired,
  showShiftSchedule: PropTypes.func.isRequired,
  downloadScheduleHistory: PropTypes.func.isRequired,
  shiftList: PropTypes.instanceOf(Array).isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  shiftDetails: PropTypes.instanceOf(Object).isRequired,
  pager: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ api }) => ({
  shiftList: _.get(api, `${c.GET_SHIFT}.list`) || [],
  shiftDetails: _.get(api, `${c.SHOW_SHIFT}.item`) || {},
  isLoading: api.loading.indexOf(c.GET_SHIFT) > -1,
  pager: _.get(api, `${c.GET_SHIFT}.res.meta`) || {},
  roles: _.get(api, "AUTH/user_profile.item.roles") || [],
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(ShiftSchedule);
