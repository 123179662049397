import React, { useContext, useMemo } from "react";
import { ModalContext } from "App";
import DataField from "components/DataField";
import * as Icon from "components/Icons";
import Panel from "components/Panel";
import Button from "components/PanelButton";
import PanelRow from "components/PanelRow";
import _ from "lodash";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useProfile } from "modules/auth/hooks";
import AgentLocationSetup from "./AgentLocationSetup";
import { getAgent, getNewLocations } from "../actions";

const AddIcon = () => <Icon.Add fill="#fff" />;

const LocationPanel = () => {
  const urlParams = useParams();
  const queryClient = useQueryClient();
  const agentId = _.get(urlParams, "agentId");
  const setModal = useContext(ModalContext);
  const profile = useProfile();
  const role_slug = _.head(profile?.roles);

  const apiAgent = useQuery({
    queryKey: ["agent-info", agentId],
    queryFn: getAgent,
    retry: false,
    keepPreviousData: true,
  });

  const agent = _.get(apiAgent, "data.data");
  const agentLocation = agent
    ? agent?.attributes?.area_name || "Unassigned"
    : "";

  const apiParentLocation = useQuery({
    queryKey: ["location", { role_slug }],
    queryFn: getNewLocations,
    retry: false,
    keepPreviousData: true,
  });

  const parentLocation = _.get(apiParentLocation, "data.data");
  const parentLocationType = _.get(parentLocation, "data.attributes.type");
  const parentLocationName = _.get(parentLocation, "data.attributes.name");

  const agentLocationType = useMemo(() => {
    const included = _.get(parentLocation, "included");
    return _.get(_.head(included), "attributes.type");
  }, [parentLocation]);

  const refetch = () => {
    queryClient.invalidateQueries("location");
    queryClient.invalidateQueries("agent-info");
  };

  const handleAddLocation = () => {
    setModal("agent-location-setup", {
      refetch,
      parentLocationType,
      parentLocationName,
      agentLocationType,
    });
  };

  if (!parentLocation) return null;

  return (
    <Panel
      title="Selling Location"
      renderActions={() => (
        <div className="btn-group">
          <Button
            iconLeft={AddIcon}
            label="Assign"
            onClick={handleAddLocation}
          />
        </div>
      )}
    >
      <PanelRow>
        <DataField
          label={_.capitalize(parentLocationType)}
          value={parentLocationName || ""}
          className="col-md-4 mb-2 mb-md-0"
        />
        <DataField
          label={_.capitalize(agentLocationType)}
          value={agentLocation}
          className="col-md-4 mb-2 mb-md-0"
        />
      </PanelRow>
      <AgentLocationSetup />
    </Panel>
  );
};

export default LocationPanel;
