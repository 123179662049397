export const customStyles = {
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    // eslint-disable-next-line
    backgroundColor: isSelected ? '#28a745' : isFocused ? '#2dbe601a' : null,
    ':active': {
      ...base[':active'],
      backgroundColor: isSelected ? null : '#2dbe604a',
    },
  }),
  control: (base, { isFocused }) => ({
    ...base,
    '&:hover': {
      borderColor: '#88e3a8',
      outline: 0,
      boxShadow: '0 0 0 0.2rem rgb(45 190 96 / 25%)',
    },
    borderColor: isFocused ? '#88e3a8' : '#cccccc',
    outline: 0,
    boxShadow: isFocused ? '0 0 0 0.2rem rgb(45 190 96 / 25%)' : 'none',
  }),
};

export default {};
