import React, { useState, useContext, useMemo } from 'react';
import { useLoading } from 'modules/ui/hooks';
import _ from 'lodash';
import { withRouter, useRouteMatch } from 'react-router-dom';
import withModal from 'modules/common/hoc/withModal';
import { USER_PROFILE } from 'modules/auth/constants';
import { useSelector, connect } from 'react-redux';
import PropTypes from 'prop-types';
import ButtonAsync from 'components/Button';
import { ToastSuccess } from 'modules/common/components/Toast';
import { ModalContext } from 'App';
import * as actions from '../actions';
import * as c from '../constant';

const form = {
  load_threshold: '',
};

const filterData = {
  page: 1,
  per_page: 9,
  q: '',
  order_by: 'created_at',
  sort_by: 'desc',
};

function LoadThreshold({ data, updateDealerSims, id, getDealerSims }) {
  const [formData, setFormData] = useState(form);
  const setModal = useContext(ModalContext);
  const [pending, setPending] = useState(false);
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const parentMatch = useRouteMatch();
  const match = useRouteMatch(`${parentMatch.url}/:dealer_id?`);
  const { dealer_id } = _.get(match, 'params');

  const handleChangeInput = key => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value,
    });
  };

  const checkInput = useMemo(() => _.isEmpty(formData.load_threshold), [
    formData,
  ]);

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);
    updateDealerSims(
      id,
      formData,
      () => {
        ToastSuccess('Successfully set Threshold.');
        getDealerSims({
          dealer_id:
            dealer_id || _.get(profile, 'id') || _.get(profile, 'parent_id'),
          userType: 'dealers',
          ...filterData,
        });
        setFormData(form);
        setModal({ isOpen: false });
        setPending(false);
      },
      () => setPending(false)
    );
  };

  return (
    <div className="container-fluid h-100">
      <div className="row d-flex align-items-center justify-content-center h-100">
        <div className="col-xl-10 col-lg-10 mt-lg-4 mt-xl-auto mx-auto">
          <h4 className="font-weight-bold mt-0 mb-2 text-center">
            Setup your load threshold
          </h4>
          <div className="mt-0 mb-2 text-center">
            <h4 className="px-2 py-2 bg-primary rounded-sm text-white d-inline-block font-weight-bold">
              PHP {data.load_threshold}
            </h4>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                className="form-control input-textbox text-center"
                type="number"
                placeholder="Enter value"
                value={_.get(formData, 'load_threshold') || ''}
                onChange={handleChangeInput('load_threshold')}
              />
            </div>
            <div className="form-group">
              <ButtonAsync
                type="submit"
                loading={useLoading(c.UPDATE_DEALER_SIMS) || pending}
                disabled={checkInput}
                className="btn btn-primary btn-block px-4 position-relative"
              >
                Set Load ThresholdS
              </ButtonAsync>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

LoadThreshold.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  updateDealerSims: PropTypes.func.isRequired,
  getDealerSims: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

const mapStateToProps = ({ modal }) => ({
  data: _.get(modal, 'modalData.data.attributes') || {},
  id: _.get(modal, 'modalData.data.id') || '',
});

const enhance = _.flowRight([
  withModal('threshold', {
    title: 'Threshold',
    size: 'modal-md',
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(LoadThreshold);
