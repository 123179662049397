import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { ToastSuccess, ToastWarn } from 'modules/common/components/Toast';
import { ModalContext } from 'App';
import withModal from 'modules/common/hoc/withModal';
import ReactInputMask from 'react-input-mask';
import ButtonAsync from 'components/Button';
import { removeEmpty, getMaskContactNo, middleNameValidator } from 'helper';

import * as c from '../constants';
import * as actions from '../actions';

const MoaSignatoryInformationUpdate = () => {
  const setModal = React.useContext(ModalContext);
  const dispatch = useDispatch();

  const modalData = useSelector(({ modal }) => _.get(modal, 'modalData'));
  const dealer_company_id = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_COMPANY_INFORMATION}.item.id`)
  );
  const dealer_id = _.get(modalData, 'dealer_id');

  const signatoryInformation = _.get(modalData, 'signatoryInformation');
  const otherSignatories = _.get(modalData, 'otherSignatories');

  const [signatoryInitialData, setSignatory] = useState(
    c.signatoryDefaultValue
  );
  const [otherSignatoriesInitialData, setOtherSignatories] = useState(
    c.otherSignatoriesDefaultValue
  );
  const userProfile = useSelector(({ api }) =>
    _.get(api, 'AUTH/user_profile.item')
  );

  const [pending, setPending] = useState(false);

  const updated_by = _.get(userProfile, 'full_name');

  const handleCloseModal = () => setModal({ isOpen: false });

  const handleChangeSignatory = ({ target: { value, name } }) =>
    setSignatory({
      ...signatoryInitialData,
      [name]: value,
    });

  const handleSignatoryMiddleName = ({ target: { value } }) => {
    if (middleNameValidator(value) || value === '') {
      setSignatory({
        ...signatoryInitialData,
        middle_name: value,
      });
    }
  };

  const defaultOtherSignatoriesFieldValue = {
    first_name: '',
    middle_name: '',
    last_name: '',
    position: '',
    email: '',
    contact_number: '',
    type: 'OTHER_SIGNATORY',
  };

  const handleChangeOtherSignatories = ({ target: { value, name } }, index) => {
    const newArray = [...otherSignatoriesInitialData];

    newArray[index] = {
      ...defaultOtherSignatoriesFieldValue,
      ...newArray[index],
      [name]: value,
    };

    if (name === 'middle_name') {
      if (middleNameValidator(value) || value === '')
        setOtherSignatories(newArray);
    } else setOtherSignatories(newArray);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);
    const validSignatories = otherSignatoriesInitialData.filter(x => {
      const removeEmptyField = _.omit(x, ['middle_name']);
      return Object.values(removeEmptyField).every(y => y !== '');
    });
    const withMissing = otherSignatoriesInitialData.filter(x => {
      const removeEmptyField = _.omit(x, ['middle_name', 'type']);
      const allEmpty = Object.values(removeEmptyField).filter(y => y === '');
      const allWith = Object.values(removeEmptyField).filter(y => y !== '');
      return allEmpty.length > 0 && allWith.length > 0;
    });

    if (!_.isEmpty(withMissing)) {
      ToastWarn('Fill out the remaining required fields to continue.');
      setPending(false);
      return;
    }

    const payload = {
      dealer_id,
      updated_by,
      management_account_step: 5,
      signatories: [signatoryInitialData, ...validSignatories],
    };

    const successCallback = () => {
      dispatch(
        actions.showMoaSignatoryInformation(dealer_id, dealer_company_id)
      );
      setPending(false);
      handleCloseModal();

      if (!_.isEmpty(signatoryInformation))
        ToastSuccess('Successfully Updated Moa Signatory Information');
      else ToastSuccess('Successfully Created Moa Signatory Information');
    };

    const errCallback = () => setPending(false);

    if (!_.isEmpty(signatoryInformation[0]))
      dispatch(
        actions.updateMoaSignatories(
          dealer_company_id,
          removeEmpty(payload),
          successCallback,
          errCallback
        )
      );
    else
      dispatch(
        actions.createMoaSignatories(
          dealer_company_id,
          removeEmpty(payload),
          successCallback,
          errCallback
        )
      );
  };

  useEffect(() => {
    if (!_.isEmpty(signatoryInformation[0])) {
      const attributes = _.get(signatoryInformation, '0.attributes');
      setSignatory({
        ...signatoryInitialData,
        first_name: _.get(attributes, 'first_name'),
        middle_name: _.get(attributes, 'middle_name'),
        last_name: _.get(attributes, 'last_name'),
        contact_number: _.get(attributes, 'contact_number'),
        signatory_id: _.get(signatoryInformation, '0.id'),
      });
    }

    if (otherSignatories.length > 0) {
      const newArray = [...otherSignatoriesInitialData];

      otherSignatories.forEach((item, index) => {
        newArray[index] = {
          first_name: _.get(otherSignatories, `${index}.attributes.first_name`),
          middle_name: _.get(
            otherSignatories,
            `${index}.attributes.middle_name`
          ),
          last_name: _.get(otherSignatories, `${index}.attributes.last_name`),
          position: _.get(otherSignatories, `${index}.attributes.position`),
          email: _.get(otherSignatories, `${index}.attributes.email`),
          contact_number: _.get(
            otherSignatories,
            `${index}.attributes.contact_number`
          ),
          signatory_id: _.get(otherSignatories, `${index}.id`),
          type: 'OTHER_SIGNATORY',
        };
        setOtherSignatories(newArray);
      });
    }
  }, [otherSignatories, signatoryInformation]);

  return (
    <div className="p-2">
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <h6 className="h6 m-0 text-xs font-weight-bold">
            Signatory Information
          </h6>
        </div>
        <div className="row d-flex align-items-end mb-sm-5 mb-xl-3">
          <div className="col-3">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              First Name *
            </label>
            <input
              type="text"
              name="first_name"
              autoFocus
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={handleChangeSignatory}
              value={_.get(signatoryInitialData, 'first_name', '')}
              required
              style={c.inputStyles}
              maxLength="50"
            />
          </div>

          <div className="col-3 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Middle Initial
            </label>
            <input
              type="text"
              name="middle_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={handleSignatoryMiddleName}
              value={_.get(signatoryInitialData, 'middle_name', '')}
              style={c.inputStyles}
            />
          </div>

          <div className="col-3 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Last Name *
            </label>
            <input
              type="text"
              name="last_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={handleChangeSignatory}
              value={_.get(signatoryInitialData, 'last_name', '')}
              required
              style={c.inputStyles}
              maxLength="50"
            />
          </div>

          <div className="col-3 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Contact No. *<br />
              <span className="font-weight-normal">
                (Mobile: 09XXXXXXXXXX / Landline: 02-XXXX-XXXX)
              </span>
            </label>
            <ReactInputMask
              mask={getMaskContactNo(
                _.get(signatoryInitialData, 'contact_number', '')
              )}
              required
              maskChar=""
              placeholder="Enter..."
              className="form-control form-custom form-control_bottom px-3"
              onChange={handleChangeSignatory}
              value={_.get(signatoryInitialData, 'contact_number', '')}
              name="contact_number"
              style={c.inputStyles}
            />
          </div>
        </div>

        <div className="mb-3">
          <h6 className="h6 m-0 text-xs font-weight-bold">
            Other Authorized Signatories
          </h6>
        </div>
        <div className="row d-flex align-items-end mb-lg-5 mb-xl-0 mb-3">
          <div className="col-sm-3 col-xl-2">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.moaLabelStyles}
            >
              First Name
            </label>
            <input
              type="text"
              name="first_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={e => handleChangeOtherSignatories(e, 0)}
              value={_.get(otherSignatoriesInitialData, '0.first_name', '')}
              style={c.inputStyles}
              maxLength="50"
            />
          </div>

          <div className="col-sm-3 col-xl-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.moaLabelStyles}
            >
              Middle Initial (Optional)
            </label>
            <input
              type="text"
              name="middle_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={e => handleChangeOtherSignatories(e, 0)}
              value={_.get(otherSignatoriesInitialData, '0.middle_name', '')}
              style={c.inputStyles}
            />
          </div>

          <div className="col-sm-3 col-xl-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.moaLabelStyles}
            >
              Last Name
            </label>
            <input
              name="last_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={e => handleChangeOtherSignatories(e, 0)}
              value={_.get(otherSignatoriesInitialData, '0.last_name', '')}
              style={c.inputStyles}
              maxLength="50"
            />
          </div>

          <div className="col-sm-3 col-xl-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.moaLabelStyles}
            >
              Position
            </label>
            <input
              name="position"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={e => handleChangeOtherSignatories(e, 0)}
              value={_.get(otherSignatoriesInitialData, '0.position', '')}
              style={c.inputStyles}
              maxLength="50"
            />
          </div>

          <div className="col-sm-3 col-xl-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.moaLabelStyles}
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={e => handleChangeOtherSignatories(e, 0)}
              value={_.get(otherSignatoriesInitialData, '0.email', '')}
              style={c.inputStyles}
            />
          </div>

          <div className="col-sm-3 col-xl-2 mt-sm-3">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Contact No.{' '}
              <span className="font-weight-normal">
                (Mobile: 09XXXXXXXXXX / Landline: 02-XXXX-XXXX)
              </span>
            </label>
            <ReactInputMask
              placeholder="Enter..."
              mask={getMaskContactNo(
                _.get(otherSignatoriesInitialData, '0.contact_number', '')
              )}
              maskChar=""
              className="form-control form-custom form-control_bottom px-3"
              onChange={e => handleChangeOtherSignatories(e, 0)}
              value={_.get(otherSignatoriesInitialData, '0.contact_number', '')}
              name="contact_number"
              style={c.inputStyles}
            />
          </div>
        </div>

        <div className="row d-flex align-items-end mb-lg-5 mb-xl-0 mb-3">
          <div className="col-sm-3 col-xl-2">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.moaLabelStyles}
            >
              First Name
            </label>
            <input
              type="text"
              name="first_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={e => handleChangeOtherSignatories(e, 1)}
              value={_.get(otherSignatoriesInitialData, '1.first_name', '')}
              style={c.inputStyles}
              maxLength="50"
            />
          </div>

          <div className="col-sm-3 col-xl-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.moaLabelStyles}
            >
              Middle Initial (Optional)
            </label>
            <input
              type="text"
              name="middle_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={e => handleChangeOtherSignatories(e, 1)}
              value={_.get(otherSignatoriesInitialData, '1.middle_name', '')}
              style={c.inputStyles}
            />
          </div>

          <div className="col-sm-3 col-xl-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.moaLabelStyles}
            >
              Last Name
            </label>
            <input
              name="last_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={e => handleChangeOtherSignatories(e, 1)}
              value={_.get(otherSignatoriesInitialData, '1.last_name', '')}
              style={c.inputStyles}
              maxLength="50"
            />
          </div>

          <div className="col-sm-3 col-xl-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.moaLabelStyles}
            >
              Position
            </label>
            <input
              name="position"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={e => handleChangeOtherSignatories(e, 1)}
              value={_.get(otherSignatoriesInitialData, '1.position', '')}
              style={c.inputStyles}
              maxLength="50"
            />
          </div>

          <div className="col-sm-3 col-xl-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.moaLabelStyles}
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={e => handleChangeOtherSignatories(e, 1)}
              value={_.get(otherSignatoriesInitialData, '1.email', '')}
              style={c.inputStyles}
              maxLength="50"
            />
          </div>

          <div className="col-sm-3 col-xl-2 mt-sm-3">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Contact No.{' '}
              <span className="font-weight-normal">
                (Mobile: 09XXXXXXXXXX / Landline: 02-XXXX-XXXX)
              </span>
            </label>
            <ReactInputMask
              placeholder="Enter..."
              maskChar=""
              className="form-control form-custom form-control_bottom px-3"
              onChange={e => handleChangeOtherSignatories(e, 1)}
              value={_.get(otherSignatoriesInitialData, '1.contact_number', '')}
              mask={getMaskContactNo(
                _.get(otherSignatoriesInitialData, '1.contact_number', '')
              )}
              name="contact_number"
              style={c.inputStyles}
            />
          </div>
        </div>

        <div className="row d-flex align-items-end mb-lg-5 mb-xl-0">
          <div className="col-sm-3 col-xl-2">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.moaLabelStyles}
            >
              First Name
            </label>
            <input
              type="text"
              name="first_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              style={c.inputStyles}
              onChange={e => handleChangeOtherSignatories(e, 2)}
              value={_.get(otherSignatoriesInitialData, '2.first_name', '')}
              maxLength="50"
            />
          </div>

          <div className="col-sm-3 col-xl-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.moaLabelStyles}
            >
              Middle Initial (Optional)
            </label>
            <input
              type="text"
              name="middle_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={e => handleChangeOtherSignatories(e, 2)}
              value={_.get(otherSignatoriesInitialData, '2.middle_name')}
              style={c.inputStyles}
            />
          </div>

          <div className="col-sm-3 col-xl-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.moaLabelStyles}
            >
              Last Name
            </label>
            <input
              name="last_name"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={e => handleChangeOtherSignatories(e, 2)}
              value={_.get(otherSignatoriesInitialData, '2.last_name', '')}
              style={c.inputStyles}
              maxLength="50"
            />
          </div>

          <div className="col-sm-3 col-xl-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.moaLabelStyles}
            >
              Position
            </label>
            <input
              name="position"
              className="form-control form-custom form-control_bottom px-3"
              placeholder="Enter..."
              onChange={e => handleChangeOtherSignatories(e, 2)}
              value={_.get(otherSignatoriesInitialData, '2.position')}
              style={c.inputStyles}
              maxLength="50"
            />
          </div>

          <div className="col-sm-3 col-xl-2 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.moaLabelStyles}
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              className="form-control form-custom form-control_bottom px-3"
              onChange={e => handleChangeOtherSignatories(e, 2)}
              value={_.get(otherSignatoriesInitialData, '2.email', '')}
              placeholder="Enter..."
              style={c.inputStyles}
              maxLength="50"
            />
          </div>

          <div className="col-sm-3 col-xl-2 mt-sm-3">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Contact No.{' '}
              <span className="font-weight-normal">
                (Mobile: 09XXXXXXXXXX / Landline: 02-XXXX-XXXX)
              </span>
            </label>
            <ReactInputMask
              placeholder="Enter..."
              maskChar=""
              className="form-control form-custom form-control_bottom px-3"
              name="contact_number"
              onChange={e => handleChangeOtherSignatories(e, 2)}
              value={_.get(otherSignatoriesInitialData, '2.contact_number', '')}
              mask={getMaskContactNo(
                _.get(otherSignatoriesInitialData, '2.contact_number', '')
              )}
              style={c.inputStyles}
            />
          </div>
        </div>

        <div className="button-container d-flex justify-content-end mt-4">
          <ButtonAsync
            className="btn btn-primary position-relative py-2 px-4 mr-2"
            type="submit"
            loading={pending}
          >
            Save
          </ButtonAsync>

          <button
            className="btn bg-grey-100 text-grey-1000 py-2 px-4"
            onClick={handleCloseModal}
            disabled={pending}
            type="button"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

const modalKey = 'moa-signatory-information-update';
const modalConfig = {
  title: 'Update MOA Signatory Information',
  size: 'modal-xl',
};

export default withModal(modalKey, modalConfig)(MoaSignatoryInformationUpdate);
