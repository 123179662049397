import React, { useState, useMemo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { ToastSuccess } from 'modules/common/components/Toast';
import ReactInputMask from 'react-input-mask';

import withModal from 'modules/common/hoc/withModal';
import { ModalContext } from 'App';
import { removeEmpty } from 'helper';
import placeholderPhoto from 'assets/images/upload-square-placeholder.png';
import ButtonAsync from 'components/Button';
import UploadFile from '../../components/UploadFileNew';

import * as c from '../constants';
import * as actions from '../actions';

const UpdateDealer = () => {
  const setModal = useContext(ModalContext);
  const dispatch = useDispatch();
  const previousData = useSelector(({ modal }) =>
    _.get(modal, 'modalData', '')
  );

  const [initialData, setInitialData] = useState(
    previousData || c.addUpdateDealerDefaultValue
  );
  const [pending, setPending] = useState(false);

  const dealer_id = _.get(previousData, 'dealer_id', '');

  const isInputEmpty = useMemo(() => {
    const { first_name, last_name, email, mobile, profile_img } = initialData;
    return (
      !first_name && !last_name && !email && !mobile && _.isEmpty(profile_img)
    );
  }, [initialData]);

  const handleCloseModal = () => setModal({ isOpen: false });

  const handleChangeFields = e => {
    const { name, value } = e.target;
    setInitialData({
      ...initialData,
      [name]: value,
    });
  };

  const handleUploadImage = (files, name) =>
    setInitialData({
      ...initialData,
      [name]: _.get(files, '0.file'),
    });

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);

    const payload = {
      ...initialData,
      email: _.get(initialData, 'email').toLowerCase(),
      profile_img: _.get(initialData, 'profile_img') || '',
      is_express: true,
      dealer_id: '',
    };

    const successCallback = () => {
      dispatch(actions.showUserAccessDetails(dealer_id));
      handleCloseModal();
      ToastSuccess('Successfully Updated Dealer.');
      setPending(false);
    };

    const errCallback = () => setPending(false);

    dispatch(
      actions.updateDealer(
        dealer_id,
        removeEmpty(payload),
        successCallback,
        errCallback
      )
    );
  };

  return (
    <div className="add-modal-container p-2">
      <div className="mb-2">
        <h6 className="h6 text-xs font-weight-bold m-0">
          Authorized Representative
        </h6>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-3 col-md-4 pt-2 pl-1">
            <div
              className="text-center p-2"
              style={{ background: 'rgba(50, 50, 50, .1)' }}
            >
              <UploadFile
                fileType="image/*"
                onChange={handleUploadImage}
                fileName="profile_img"
                customButton={() => (
                  <div className="thumbnail upload-thumbnail">
                    <figure
                      className="img-container mx-auto"
                      style={{ width: '6rem', height: '6rem' }}
                    >
                      <img
                        src={
                          _.get(initialData, 'profile_img') || placeholderPhoto
                        }
                        alt="Avatar"
                        className="w-100 h-100"
                      />
                    </figure>
                    <p className="m-0 my-2" style={{ fontSize: '.65rem' }}>
                      Upload Dealer Display Photo
                    </p>
                    <button
                      className="btn btn-success text-lowercase font-weight-normal px-4"
                      type="button"
                      style={{ fontSize: '.7rem' }}
                    >
                      <span className="text-uppercase">U</span>pload
                    </button>
                  </div>
                )}
                className="p-0 border-0 text-center"
              />
            </div>
          </div>
          <div className="col row mb-5">
            <div className="col-6 mb-2">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                First Name *
              </label>
              <input
                maxLength="50"
                type="text"
                name="first_name"
                className="form-control form-custom form-control_bottom px-3"
                placeholder="Enter First Name"
                autoFocus
                required
                style={c.inputStyles}
                value={
                  _.get(initialData, 'first_name').replace(/  +/g, ' ') || ''
                }
                onChange={handleChangeFields}
              />
            </div>

            <div className="col-6 ">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                Last Name *
              </label>
              <input
                maxLength="50"
                type="text"
                name="last_name"
                className="form-control form-custom form-control_bottom px-3"
                placeholder="Enter Last Name"
                style={c.inputStyles}
                value={
                  _.get(initialData, 'last_name').replace(/  +/g, ' ') || ''
                }
                onChange={handleChangeFields}
                required
              />
            </div>

            <div className="col-6 ">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                Email Address *
              </label>
              <input
                type="email"
                name="email"
                className="form-control form-custom form-control_bottom px-3 "
                placeholder="Enter Email Address"
                style={c.inputStyles}
                value={_.get(initialData, 'email', '').replace(/\s/g, '')}
                onChange={handleChangeFields}
                required
              />
            </div>

            <div className="col-6 ">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                Mobile Number *
              </label>

              <ReactInputMask
                required
                type="text"
                mask="+639999999999"
                maskChar=""
                className="form-control form-custom form-control_bottom px-3"
                name="mobile"
                value={_.get(initialData, 'mobile', '')}
                onChange={handleChangeFields}
                placeholder="Enter..."
                style={c.inputStyles}
              />
            </div>
          </div>
        </div>
        <div className="button-container d-flex justify-content-end">
          <ButtonAsync
            className="btn btn-primary position-relative py-2 px-4 mr-2"
            type="submit"
            loading={pending}
            disabled={isInputEmpty}
          >
            Create
          </ButtonAsync>

          <button
            type="button"
            className="btn bg-gray-300 text-white py-2 px-4"
            onClick={handleCloseModal}
            disabled={pending}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

const modalKey = 'update-dealer';
const modalConfig = {
  title: 'Update Dealer',
  size: 'modal-lg',
};

export default withModal(modalKey, modalConfig)(UpdateDealer);
