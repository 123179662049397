import React from 'react';
import PropTypes from 'prop-types';
import Step from './Step';

const Stepper = ({ withNumbers, activeStep, children }) => {
  return (
    <div className="mdl-card">
      <div className="mdl-card__supporting-text">
        <div className="mdl-stepper-horizontal-alternative">
          {React.Children.map(children, (child, index) => {
            if (child !== undefined && child.type === Step) {
              return (
                <Step
                  active={activeStep >= index}
                  stepNumber={withNumbers ? index + 1 : null}
                  done={activeStep > index}
                >
                  {child.props.children}
                </Step>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

Stepper.propTypes = {
  withNumbers: PropTypes.bool,
  activeStep: PropTypes.number,
  children: PropTypes.node.isRequired,
};

Stepper.defaultProps = {
  withNumbers: true,
  activeStep: 0,
};

export default Stepper;
