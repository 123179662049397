import React, { useContext, useMemo, useState } from "react";
import { ModalContext } from "App";
import Button from "components/Button";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useMutation, useQueryClient, useQuery } from "react-query";
import withModal from "modules/common/hoc/withModal";
import { ToastError, ToastSuccess } from "modules/common/components/Toast";
import ErrorMessage from "modules/common/components/ErrorMessage";
import { addClusterAgents } from "../actions";
import { getLocations } from "../../agent-operation/actions";
import AgentSelectField from "./AgentSelectField";

const ClusterAgentSetup = () => {
  const [agent, setAgent] = useState("");
  const urlParams = useParams();
  const setModal = useContext(ModalContext);
  const queryClient = useQueryClient();
  const clusterId = _.get(urlParams, "clusterId");

  const handleAgentSelection = (selection) => {
    setAgent(selection);
  };

  const apiAgentLocation = useQuery({
    queryKey: [
      "location-agent",
      _.get(agent, "attributes.area_id", "") || "",
      {},
    ],
    enabled: !!_.get(agent, "attributes.area_id"),
    queryFn: getLocations,
    retry: false,
    keepPreviousData: true,
  });

  const { mutate: saveClusterAgents, isLoading: isAdding } = useMutation(
    addClusterAgents,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("cluster-agents");
        ToastSuccess("Successfully assigned agent to cluster.");
        setModal({ isOpen: false });
      },
      onError: (err) => {
        const errorMessage = (
          <ErrorMessage
            error={err}
            defaultMessage="Failed assigning agent to cluster."
          />
        );
        ToastError(errorMessage);
        setModal({ isOpen: false });
      },
    }
  );

  const handleModalClose = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveClusterAgents({ clusterId, data: { dealer_user_id: agent?.id } });
  };

  const isFormValid = useMemo(() => {
    return !_.isEmpty(agent);
  }, [agent]);

  const selectedSubDealerAgentLocation = useMemo(() => {
    if (_.isEmpty(_.get(agent, "attributes.area_id"))) return "";
    return _.get(apiAgentLocation, "data.data.data.attributes.name") || "";
  }, [agent, apiAgentLocation]);

  return (
    <div className="col">
      <form onSubmit={handleSubmit}>
        <div className="row px-0 mb-3">
          <div className="col">
            <label className="form-label text-primary">
              Sub-dealer agent *
            </label>
            <AgentSelectField onChange={handleAgentSelection} />
          </div>
        </div>
        <div className="row px-0">
          <div className="col">
            <label className="form-label text-primary">
              Selling Location *
            </label>
            {apiAgentLocation.isLoading ? (
              <div className=" text-center">
                <span className="fas fa-1x fa-spin fa-spinner" />
              </div>
            ) : (
              <>
                {!_.isEmpty(selectedSubDealerAgentLocation) ? (
                  <input
                    readOnly
                    type="text"
                    name="selling-location"
                    value={selectedSubDealerAgentLocation}
                    className="form-control form-custom form-control_bottom"
                    disabled={apiAgentLocation.isLoading}
                  />
                ) : (
                  <div className="text-center text-xs text-gray-800">
                    Agent selling location is unassigned.
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end pt-4">
          <Button loading={isAdding} type="submit" disabled={!isFormValid}>
            Assign
          </Button>
          <Button onClick={handleModalClose} className="btn btn-light ml-2">
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

const modalKey = "cluster-agent-setup";
const modalConfig = {
  title: "Add Sub-Dealer Agent",
  size: "modal-md",
};

export default withModal(modalKey, modalConfig)(ClusterAgentSetup);
