import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactInputMask from 'react-input-mask';
import Button from 'modules/common/components/ButtonAsync';
import _ from 'lodash';

import { ToastError } from 'modules/common/components/Toast';

const ChangeMPIN = ({
  pinFormData,
  onChangeInput,
  onFinishForm,
  onPrevStep,
  onCancel,
  loading,
}) => {
  const [visibleFields, setVisibleFields] = useState({
    old_mpin: false,
    new_mpin: false,
    new_mpin_confirmation: false,
  });

  const oldMpin = _.get(pinFormData, 'old_mpin', '');
  const newMpin = _.get(pinFormData, 'new_mpin', '');
  const newMpinConfirm = _.get(pinFormData, 'new_mpin_confirmation', '');

  const onChangeVisibility = key => () => {
    setVisibleFields(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const checkFieldType = isVisible => (isVisible ? 'text' : 'password');
  const checkIconType = isVisible => (isVisible ? 'fa-eye-slash' : 'fa-eye');

  const onSubmit = e => {
    e.preventDefault();

    const isSameFromOldMpin = oldMpin === newMpin;
    const newMpinDoesNotMatch = newMpin !== newMpinConfirm;
    const isMinNumCharsInvalid = newMpin.length < 6;

    if (isSameFromOldMpin)
      return ToastError(
        'New Security PIN cannot be the same as old Security PIN.'
      );
    if (newMpinDoesNotMatch)
      return ToastError(
        'New Security PIN and confirm Security PIN did not match.'
      );
    if (isMinNumCharsInvalid)
      return ToastError('New Security PIN must be 6 characters.');

    return onFinishForm();
  };

  return (
    <div style={{ maxWidth: 450 }} className="w-100 mx-auto">
      <h4 className="font-weight-bold text-center m-0 mb-4">
        Change Security PIN
      </h4>
      <p className="text-center m-0">
        Please nominate a Security PIN that is different from your Temporary
        6-Digit Security PIN.
      </p>

      <form onSubmit={onSubmit} className="change-mpin-form">
        <div className="form-group mt-4">
          <div className="prepend-form-right">
            <label className="form-label text-primary">
              Temporary 6-Digit Security PIN:
            </label>
            <ReactInputMask
              type={checkFieldType(visibleFields.old_mpin)}
              placeholder="Temporary 6 Digit PIN"
              mask="999999"
              maskChar=""
              className="form-control form-custom form-control_bottom"
              name="old_mpin"
              required
              onChange={onChangeInput('old_mpin')}
              value={oldMpin}
              disabled={loading}
            />
            <button
              type="button"
              onClick={onChangeVisibility('old_mpin')}
              className="btn btn-default rounded-circle p-0 mr-1"
            >
              <i className={`fas ${checkIconType(visibleFields.old_mpin)}`} />
            </button>
          </div>
        </div>

        <div className="form-group">
          <div className="prepend-form-right">
            <label className="form-label text-primary">
              New 6-Digit Security PIN:
            </label>
            <ReactInputMask
              type={checkFieldType(visibleFields.new_mpin)}
              placeholder="New 6 Digit PIN"
              mask="999999"
              maskChar=""
              className="form-control form-custom form-control_bottom"
              name="new_mpin"
              required
              onChange={onChangeInput('new_mpin')}
              value={newMpin}
              disabled={loading}
            />
            <button
              type="button"
              onClick={onChangeVisibility('new_mpin')}
              className="btn btn-default rounded-circle p-0 mr-1"
            >
              <i className={`fas ${checkIconType(visibleFields.new_mpin)}`} />
            </button>
          </div>
        </div>

        <div className="form-group">
          <div className="prepend-form-right">
            <label className="form-label text-primary">
              Confirm 6-Digit Security PIN:
            </label>
            <ReactInputMask
              type={checkFieldType(visibleFields.new_mpin_confirmation)}
              placeholder="New 6-Digit PIN again"
              mask="999999"
              maskChar=""
              className="form-control form-custom form-control_bottom"
              name="new_mpin_confirmation"
              required
              onChange={onChangeInput('new_mpin_confirmation')}
              value={newMpinConfirm}
              disabled={loading}
            />
            <button
              type="button"
              onClick={onChangeVisibility('new_mpin_confirmation')}
              className="btn btn-default rounded-circle p-0 mr-1"
            >
              <i
                className={`fas ${checkIconType(
                  visibleFields.new_mpin_confirmation
                )}`}
              />
            </button>
          </div>
        </div>

        <div className="form-group mt-4">
          <Button
            type="submit"
            loading={loading}
            className="btn btn-primary position-relative w-100"
          >
            Change Pin
          </Button>

          <div className="text-center mt-2">
            <button
              type="button"
              className="prev-btn btn btn-link px-4 mr-4"
              onClick={onPrevStep}
            >
              Previous
            </button>
            <button
              type="button"
              className="cancel-btn btn btn-link px-4"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

ChangeMPIN.propTypes = {
  onFinishForm: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  pinFormData: PropTypes.instanceOf(Object).isRequired,
  onPrevStep: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ChangeMPIN;
