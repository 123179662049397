import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import imgThumb from 'assets/images/logo-loader.png';
import _ from 'lodash';
import { withRouter, useHistory } from 'react-router-dom';
import withModal from 'modules/common/hoc/withModal';
import { connect } from 'react-redux';
// import { ModalContext } from 'App';
// import { ToastSuccess } from 'modules/common/components/Toast';
import * as actions from '../actions';
import * as c from '../constant';
import CartDetails from '../components/CartDetails';
import RemoveItem from '../forms/RemoveItem';

function ProductModal({ cartList, total, isLoading }) {
  const [toggle, setToggle] = useState('details');
  const [oldData, setOldData] = useState({});
  const history = useHistory();

  const handleRemoveItem = (value, args) => e => {
    e.preventDefault();
    setToggle(value);
    setOldData(args);
  };

  const handleBack = value => e => {
    e.preventDefault();
    setToggle(value);
  };

  const handlePlaceOrder = e => {
    e.preventDefault();
    history.push('/purchase-order/order-details');
  };

  return (
    <div className="container-fluid">
      {toggle === 'details' && (
        <CartDetails
          data={cartList}
          onRemove={handleRemoveItem}
          total={total}
          loading={isLoading}
          onPlaceOrder={handlePlaceOrder}
        />
      )}
      {toggle === 'remove' && (
        <RemoveItem data={oldData} onBack={handleBack} setToggle={setToggle} />
      )}
    </div>
  );
}

ProductModal.propTypes = {
  cartList: PropTypes.instanceOf(Array).isRequired,
  total: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ api }) => ({
  total: _.get(api, `${c.GET_CART}.res.subtotal`) || '',
  cartList: _.get(api, `${c.GET_CART}.list`) || [],
  isLoading: api.loading.indexOf(c.GET_CART) > -1,
});

const enhance = _.flowRight([
  withModal('cart', {
    title: 'Cart',
    size: 'modal-lg',
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(ProductModal);
