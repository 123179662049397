import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ToastError } from 'modules/common/components/Toast';
import { passwordStrength } from 'helper';
import ButtonAsync from 'components/Button';

const DealerChangePassword = ({ onSubmit, pending }) => {
  const [formData, setFormData] = useState();
  const [visibleFields, setVisibleFields] = useState({
    old_password: false,
    new_password: false,
    new_password_confirmation: false,
  });

  const oldPw = _.get(formData, 'old_password', '');
  const newPw = _.get(formData, 'new_password', '');
  const newPwConfirm = _.get(formData, 'new_password_confirmation', '');

  const {
    isAlphaNumeric,
    hasLowerCase,
    hasUpperCase,
    hasSpecialCharacter,
    isValid,
    isLongEnough,
  } = passwordStrength(newPw);

  const onChangeVisibility = key => () => {
    setVisibleFields(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const checkFieldType = isVisible => (isVisible ? 'text' : 'password');
  const checkIconType = isVisible => (isVisible ? 'fa-eye-slash' : 'fa-eye');

  const handleInputChange = ({ target }) => {
    setFormData(prevState => ({
      ...prevState,
      [target.name]: target.value,
    }));
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    // new password cannot be the same as old password
    const isSameFromOldPassword = oldPw === newPw;

    // new password cannot be the same as old password
    if (isSameFromOldPassword)
      return ToastError('New password cannot be the same as old password.');

    // check length
    if (!isLongEnough)
      return ToastError('Password needs to be 8 or more characters.');

    // check validity
    if (!isValid)
      return ToastError(
        'Password should contain characters three out of four categories'
      );

    return onSubmit && onSubmit(formData);
  };

  return (
    <div style={{ maxWidth: 450 }} className="w-100 mx-auto">
      <h5 className="font-weight-bold text-center m-0 mb-4">
        Change Temporary Password
      </h5>
      <p className="text-sm text-center m-0">
        Create a strong password to secure account. Please nominate a password
        that is different from your Temporary password.
      </p>

      <form onSubmit={handleFormSubmit} className="change-pw-form">
        <div className="form-group mt-4">
          <div className="prepend-form-right">
            <label className="form-label text-primary">
              Temporary Password:
            </label>
            <input
              className="form-control form-custom form-control_bottom"
              type={checkFieldType(visibleFields.old_password)}
              placeholder="Temporary Password"
              required
              name="old_password"
              onChange={handleInputChange}
              value={oldPw}
            />
            <button
              type="button"
              onClick={onChangeVisibility('old_password')}
              className="btn btn-default rounded-circle p-0 mr-1"
            >
              <i
                className={`fas ${checkIconType(visibleFields.old_password)}`}
              />
            </button>
          </div>
        </div>

        <div className="form-group">
          <div className="prepend-form-right">
            <label className="form-label text-primary">New Password:</label>
            <input
              className="form-control form-custom form-control_bottom"
              type={checkFieldType(visibleFields.new_password)}
              placeholder="New Password"
              required
              name="new_password"
              onChange={handleInputChange}
              value={newPw}
            />
            <button
              type="button"
              onClick={onChangeVisibility('new_password')}
              className="btn btn-default rounded-circle p-0 mr-1"
            >
              <i
                className={`fas ${checkIconType(visibleFields.new_password)}`}
              />
            </button>
          </div>
        </div>

        <div className="form-group">
          <div className="prepend-form-right">
            <label className="form-label text-primary">Confirm Password:</label>
            <input
              className="form-control form-custom form-control_bottom"
              type={checkFieldType(visibleFields.new_password_confirmation)}
              required
              placeholder="Confirm New Password"
              name="new_password_confirmation"
              onChange={handleInputChange}
              value={newPwConfirm}
            />
            <button
              type="button"
              onClick={onChangeVisibility('new_password_confirmation')}
              className="btn btn-default rounded-circle p-0 mr-1"
            >
              <i
                className={`fas ${checkIconType(
                  visibleFields.new_password_confirmation
                )}`}
              />
            </button>
          </div>
        </div>

        <p style={{ lineHeight: 1.2 }}>
          <small>
            Password length should be minimum eight(8) characters and should
            contain characters from three out four categories{' '}
          </small>
        </p>

        <div className="form-inline mb-4">
          <div className="form-group mr-2">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                checked={isAlphaNumeric}
                readOnly
              />
              <label className="custom-control-label" htmlFor="passwordAlpha">
                Alphanumeric
              </label>
            </div>
          </div>
          <div className="form-group mr-2">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                checked={hasUpperCase}
                readOnly
              />
              <label
                className="custom-control-label"
                htmlFor="passwordUppercase"
              >
                Uppercase Letter
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                readOnly
                checked={hasLowerCase}
              />
              <label
                className="custom-control-label"
                htmlFor="passwordLowercase"
              >
                Lowercase Letter
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                readOnly
                checked={hasSpecialCharacter}
              />
              <label
                className="custom-control-label"
                htmlFor="passwordSpecialChar"
              >
                Special Characters
              </label>
            </div>
          </div>
        </div>

        <div className="form-group mt-4">
          <ButtonAsync
            disabled={!isValid}
            loading={pending}
            type="submit"
            className="btn btn-block btn-primary px-4 position-relative"
          >
            Change Password
          </ButtonAsync>
        </div>
      </form>
    </div>
  );
};

DealerChangePassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired,
};

export default DealerChangePassword;
