import React, { useState } from 'react';

const AccountListing = () => {
  const [formData, setFormData] = useState({});

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    // search feature here
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="p-4">
        <div className="prepend-form-right mb-2">
          <label htmlFor="search" className="sr-only">
            Search Account
          </label>
          <input
            type="text"
            id="search"
            name="search"
            className="form-control"
            placeholder="Search..."
            value={formData.name}
            onChange={e => handleChange(e)}
          />
          <button
            type="submit"
            className="btn btn-default rounded-circle px-1 py-0 mr-1"
          >
            <i className="fa fa-search" />
          </button>
        </div>

        <label htmlFor="type" className="sr-only">
          Account Type
        </label>
        <select
          name="type"
          id="type"
          className="custom-select"
          value={formData.type}
          onChange={e => handleChange(e)}
        >
          <option defaultValue>All Account Types</option>
          <option value="type-1">Account Type 1</option>
          <option value="type-2">Account Type 2</option>
          <option value="type-3">Account Type 3</option>
        </select>
      </form>

      <ul className="list-group list-group-listing">
        <li className="list-group-item list-group-item-action">
          Provincial Dealer 1
        </li>
        <li className="list-group-item list-group-item-action active">
          Provincial Distributor 2
        </li>
        <li className="list-group-item list-group-item-action">
          Provincial Distributor 3
        </li>
      </ul>
    </>
  );
};

export default AccountListing;
