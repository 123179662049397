import * as c from './constants';

const initialState = {
  loading: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case c.LOADING_ADD:
      return { ...state, loading: [...state.loading, action.key] };

    case c.LOADING_REMOVE:
      return {
        ...state,
        loading: state.loading.filter(key => key !== action.key),
      };

    case c.LOADING_CLEAR:
      return {
        ...state,
        loading: [],
      };

    case c.UI_RESET:
      return initialState;

    default:
      return state;
  }
};
