import React from 'react';
import { render } from 'react-dom';
import AsyncButton from './ButtonAsync';

const ConfirmAlert = (
  label,
  onYes,
  onNo,
  options = {
    yesLabel: 'Yes',
    noLabel: 'No',
    header: null,
    newButtons: false,
    center: true,
    sub_text: '',
  }
) => {
  const rootElement = document.getElementById('my-confirm-modal');

  const handleYes = () => {
    if (onYes) {
      onYes();
    }
    render(<div id="my-confirm-modal" />, rootElement);
  };

  const handleNo = () => {
    if (onNo) {
      onNo();
    }
    render(<div id="my-confirm-modal" />, rootElement);
  };

  render(
    <div>
      <div style={{ position: 'absolute' }}>
        <div
          className="modal show"
          style={{
            display: 'block',
            zIndex: 1051,
            backgroundColor: 'rgba(0, 0, 0, .6)',
            pointerEvents: 'none',
          }}
        >
          <div
            className="modal-dialog modal-margin"
            style={{ pointerEvents: 'initial' }}
          >
            <div className="modal-content">
              {options.header && (
                <div className="modal-header">
                  <h4 className="modal-title m-0">{options.header}</h4>
                </div>
              )}
              <div
                className={`modal-body${
                  options.center === undefined ? ' text-center' : ''
                }`}
              >
                <span className="text-muted">{label}</span>
                {options.sub_text && (
                  <div className="font-weight-bold mt-3">
                    {options.sub_text}
                  </div>
                )}
              </div>
              {options.newButtons ? (
                <div className="pb-4 pt-2 px-4 d-flex justify-content-end mt-2">
                  <AsyncButton
                    type="submit"
                    // loading={isLoading}
                    className="btn btn-success ml-2 mt-3 position-relative d-flex justify-content-center align-items-center px-4"
                    onClick={handleYes}
                  >
                    {options.yesLabel}
                  </AsyncButton>
                  <AsyncButton
                    className="btn bg-gray-300 ml-2 mt-3 position-relative d-flex justify-content-center align-items-center"
                    type="button"
                    onClick={handleNo}
                  >
                    {options.noLabel}
                  </AsyncButton>
                </div>
              ) : (
                <div className="modal-footer" style={{ borderTop: 'none' }}>
                  <button
                    type="button"
                    className="btn btn-danger ml-auto"
                    onClick={handleNo}
                    style={{ width: 138 }}
                  >
                    {options.noLabel}
                  </button>{' '}
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-yes mr-auto"
                    onClick={handleYes}
                    style={{ width: 138 }}
                  >
                    {options.yesLabel}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {
          // eslint-disable-next-line
          <div
            className="modal-backdrop fade show"
            style={{ zIndex: 1050, pointerEvents: 'initial' }}
            onClick={handleNo}
          />
        }
      </div>
    </div>,
    rootElement
  );
};

export default ConfirmAlert;
