import React from "react";
import Proptypes from "prop-types";

const CircleIndicator = ({ is_active }) => (
  <div
    className={`rounded-circle ${is_active ? "bg-success" : "bg-secondary "}`}
    style={{ height: ".5rem", width: ".5rem" }}
  >
    &nbsp;
  </div>
);

CircleIndicator.propTypes = {
  is_active: Proptypes.bool.isRequired,
};

export default CircleIndicator;
