import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import RetailerToSubscriber from "modules/Load/containers/RetailerToSubscriber";
import DealerToRetailer from "modules/Load/containers/DealerToRetailer";
import { checkPermissions, firstModule } from "helper";

const routes = [
  {
    path: "/load-subscriber",
    component: RetailerToSubscriber,
    permission: ["can-view-providers"],
  },
  {
    path: "/load-retailer",
    component: DealerToRetailer,
    permission: ["can-view-dealer-sim-transaction-internal"],
  },
];

function SalesOrderContainer() {
  const match = useRouteMatch();
  const first = firstModule("sales-order");
  const redirect_path = first?.path
    ? `${match.path}/${first?.path}`
    : "/not-found";
  return (
    <>
      <Redirect exact from={match.path} to={redirect_path} />
      <Switch>
        {routes.map(
          (route) =>
            checkPermissions(route.permission) && (
              <Route
                path={`${match.path}${route.path}`}
                component={route.component}
              />
            )
        )}
      </Switch>
    </>
  );
}

export default SalesOrderContainer;
