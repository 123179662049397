import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { removeEmpty } from "helper";
import { useLoading } from "modules/ui/hooks";
import { useDealer } from "app/load-wallet/context/DealerProvider";
import { useFilterSim } from "app/load-wallet/context/SimFilterProvider";
import { useInterval } from "react-use";
import { getDealerSources } from "app/dashboard/actions";
import { GET_DEALER_SOURCES } from "app/dashboard/constant";
import * as req from "api/actions";
import * as c from "modules/LoadWalletManagement/constant";
import * as a from "modules/LoadWalletManagement/actions";

export const DealerSimsContext = createContext();

export const DealerSimProvider = ({ children }) => {
  const {
    selectedDealer,
    dealerRole,
    role,
    parentId,
    accountTypeName,
    dealerId,
    profile,
    simDealerId,
    transactionPath,
  } = useDealer();
  const { filterData } = useFilterSim();
  const dispatch = useDispatch();
  const isBA = role?.includes("branch-admin");
  const { data: dealerSims, meta: dealerSimsMeta } =
    useSelector(({ api }) => _.get(api, `${c.GET_DEALER_SIMS}.res`)) || {};
  const { meta: branchSimsMeta } =
    useSelector(({ api }) => _.get(api, `${GET_DEALER_SOURCES}.res`)) || {};
  const dealerSimsAll = useSelector(({ api }) =>
    _.get(api, `${c.GET_DEALER_SIMS_ALL}.res.data`)
  );

  // branch sources
  const branchSources = useSelector(({ api }) =>
    _.get(api, `${GET_DEALER_SOURCES}.res.data.attributes.rows`, [])
  );

  const filteredBranchSources = branchSources.filter((sim) =>
    sim?.type.includes("dealer_sims")
  );

  const branchDealerSims = _.intersectionBy(
    dealerSimsAll,
    filteredBranchSources,
    (sim) =>
      sim?.attributes?.mobile_identification_number ||
      sim?.mobile_identification_number
  );

  const dealerSimsPager = isBA
    ? branchSimsMeta
    : dealerSimsMeta?.pagination || [];
  const parentSIMTypes = useMemo(() => {
    return dealerRole?.includes(c.DEALER_SIM_ROLES.smmt)
      ? c.SMMT_PARENT_SIM_TYPES
      : c.PARENT_SIM_TYPES;
  }, [dealerRole]);
  const hasDealerSim = useMemo(() => {
    const parentSIM = dealerSimsAll?.find((sim) =>
      parentSIMTypes?.includes(sim?.attributes?.type)
    );
    return parentSIM?.attributes?.status?.includes(c.DEALERSIM_STATUS.approved);
  }, [dealerSimsAll, parentSIMTypes]);
  const userRolesList = Object?.keys(c.DEALER_SIM_ROLES);
  const simStatus = useMemo(() => {
    if (userRolesList?.includes(role)) return {};
    return parentId ? { is_active: true } : {};
  }, [role, parentId]);

  const dealerSimSliced = useMemo(() => {
    const dealerSimListSliced = isBA
      ? branchDealerSims
      : dealerSims?.slice(0, 10);
    return dealerSimListSliced?.filter((item) =>
      c.DEALERSIM_VISIBLE_LIST?.includes(item?.attributes?.delete_status)
    );
  }, [dealerSims, branchDealerSims, isBA]);

  const dealerSimsLoading = useLoading(
    c.GET_DEALER_SIMS || c.GET_DEALER_SIMS_ALL
  );
  const parentSimStatus = useMemo(() => {
    const parentSIM = dealerSims?.find((sim) =>
      parentSIMTypes?.includes(sim?.attributes?.type)
    );

    const hasParentSIM = dealerSims?.some((sim) =>
      parentSIMTypes?.includes(sim?.attributes?.type)
    );

    const isParentStatusApproved = parentSIM?.attributes?.status?.includes(
      c.DEALERSIM_STATUS.approved
    );

    if (hasParentSIM) {
      if (isParentStatusApproved) return false;
      return true;
    }
    return false;
  }, [dealerSims, parentSIMTypes]);

  const loading = useMemo(() => {
    const isDealerUser = role?.includes("corporate-admin");

    return (
      dealerSimsLoading ||
      (!isDealerUser && _.isEmpty(accountTypeName)) ||
      parentSimStatus
    );
  }, [role, dealerSimsLoading, accountTypeName, parentSimStatus]);

  const isDeleting = useMemo(() => {
    if (!dealerSims || !dealerSims?.length) return false;
    return dealerSims?.find(
      (item) =>
        item?.attributes?.delete_status?.toLowerCase() ===
        c.DEALERSIM_STATUS.processing?.toLowerCase()
    );
  }, [dealerSims]);
  const refetchDealerSim = useCallback(() => {
    dispatch(
      a.getDealerSims(
        removeEmpty({
          dealer_id: simDealerId,
          userType: "dealers",
          ...filterData,
          sim_status: filterData?.sim_status?.value || "",
          status: filterData?.status?.value || "",
        })
      )
    );
    dispatch(
      a.getDealerSimsAll(
        removeEmpty({
          dealer_id: simDealerId,
          userType: "dealers",
          per_page: 9999,
          ...filterData,
          sim_status: filterData?.sim_status?.value || "",
          status: filterData?.status?.value || "",
        })
      )
    );
  }, [dispatch, simDealerId, filterData]);

  /* Polling */
  useInterval(refetchDealerSim, isDeleting ? 3000 : null);

  useEffect(() => {
    if (transactionPath) return;
    dispatch(
      a.getDealerSims(
        removeEmpty({
          dealer_id: simDealerId,
          userType: "dealers",
          ...filterData,
          sim_status: filterData?.sim_status?.value || "",
          status: filterData?.status?.value || "",
        })
      )
    );
    return () => {
      dispatch(req.setItem(c.GET_DEALER_SIMS, {}));
    };
  }, [dispatch, simDealerId, filterData]);

  useEffect(() => {
    if (transactionPath) return;
    dispatch(
      a.getDealerSimsAll(
        removeEmpty({
          dealer_id: simDealerId,
          userType: "dealers",
          per_page: 9999,
          ...filterData,
          sim_status: filterData?.sim_status?.value || "",
          status: filterData?.status?.value || "",
        })
      )
    );

    return () => {
      dispatch(req.setItem(c.GET_DEALER_SIMS_ALL, []));
    };
  }, [dispatch, simDealerId, filterData]);

  useEffect(() => {
    if (!isBA) return;
    dispatch(
      getDealerSources({
        ...filterData,
        sim_status: filterData?.sim_status?.value || "",
        status: filterData?.status?.value || "",
        retailer_id: profile?.retailer_id,
      })
    );
  }, [dispatch, filterData]);

  const dealerSimsValue = {
    selectedDealer,
    dealerId,
    dealerSims,
    dealerSimsMeta,
    userRolesList,
    simDealerId,
    simStatus,
    dealerSimsAll,
    hasDealerSim,
    parentSIMTypes,
    dealerSimSliced,
    dealerSimsPager,
    dealerSimsLoading,
    loading,
  };

  return (
    <DealerSimsContext.Provider value={dealerSimsValue}>
      {children}
    </DealerSimsContext.Provider>
  );
};
DealerSimProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
export const useDealerSim = () => {
  return useContext(DealerSimsContext);
};
