import React, { Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

function Filter({ onChange, value }) {
  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-7 col-lg-6 pr-0">
          <div className="form-group">
            <label className="form-label text-success">Search Promo:</label>
            <input
              type="text"
              placeholder="All Promos ..."
              className="form-control"
              value={_.get(value, "q") || ""}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

Filter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object),
};

Filter.defaultProps = {
  value: {},
};

export default Filter;
