import React, { useState, useEffect, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { removeEmpty } from "helper";
import { useLoading } from "modules/ui/hooks";
import { ModalContext } from "App";
import ViewBalance from "app/load-wallet/retailer-sims/containers/ViewBalance";
import Actions from "components/Actions";
import Pagination from "modules/common/components/Pagination";
import * as Icon from "components/Icons";
import Filter from "components/Filter";
import BatchCreateSetup from "./BatchCreateSetup";
import ViewErrorLogs from "./ViewErrorLogs";
import CardItem from "../../components/CardItem";
import AddRetailer from "../modal/AddRetailer";
import UpdateRetailer from "../modal/UpdateRetailer";
import EnterPassword from "../modal/EnterPassword";
import Details from "../modal/Details";
import DeleteRetailer from "../modal/DeleteRetailer";
import VerifyMin from "../modal/VerifyMin";
import ValidateCSV from "./ValidateCSVSetup";
import * as c from "../constants";
import * as actions from "../actions";

const buttonClass = `mt-0 btn btn-block btn-default p-0 rounded-0 text-right`;
const AddIcon = () => <Icon.Add fill="#fff" />;

const RetailerContainer = () => {
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);
  const retailerList = useSelector(({ api }) =>
    _.get(api, `${c.GET_RETAILER_LIST}.list`, [])
  );

  const updated_by = useSelector(({ api }) =>
    _.get(api, "AUTH/user_profile.item.full_name")
  );
  const retailerListPager = useSelector(({ api }) =>
    _.get(api, `${c.GET_RETAILER_LIST}.pager`, [])
  );
  const [filterData, setFilterData] = useState(
    c.compoundSearchRetailerDefaultValue
  );

  const isRetailerFetching = useLoading(c.GET_RETAILER_LIST);

  const getRetailerListParams = useMemo(
    () => ({
      ...filterData,
      is_active: _.get(filterData, "is_active.value", ""),
      updated_by_name: updated_by,
    }),
    [filterData, updated_by]
  );

  const actionsModal = (type, item) => setModal(type, { item });

  const handlePager = (page) =>
    dispatch(
      actions.getRetailerList(removeEmpty({ ...getRetailerListParams, page }))
    );

  const fetchRetailers = () => {
    dispatch(actions.getRetailerList(removeEmpty(getRetailerListParams)));
  };

  const handleBatchCreate = () => {
    setModal("validate-batch-create-retailer-csv");
  };

  const handleViewErrors = () => {
    setModal("view-batch-retailer-error-logs");
  };

  useEffect(() => {
    fetchRetailers();
  }, []);

  const renderDropdownActions = (item) => {
    const id = _.get(item, "id");
    return (
      <Actions
        id={id}
        containerClassName="dropdown"
        buttonClassName="btn btn-link p-0"
      >
        <button
          type="button"
          className={`${buttonClass} text-uppercase`}
          onClick={() =>
            setModal("view-retailer-sim-balance", {
              min: item?.attributes.mobile_identification_number,
            })
          }
        >
          <small>View Balance</small>
        </button>
        <button
          type="button"
          className={`${buttonClass} text-uppercase`}
          onClick={() => actionsModal("details-retailer", item)}
        >
          <small>Details</small>
        </button>
        <button
          type="button"
          className={`${buttonClass} text-uppercase`}
          onClick={() => actionsModal("update-retailer", { item, filterData })}
        >
          <small>Update</small>
        </button>
        <button
          type="button"
          className={`${buttonClass} text-uppercase`}
          onClick={() =>
            actionsModal("delete-retailer", { item, fetchRetailers })
          }
        >
          <small>Delete</small>
        </button>
      </Actions>
    );
  };

  return (
    <>
      <div className="partners__dealer-container partners__bg-light-gray px-5 py-3 h-100 d-inline-block">
        <div className="tw-flex tw-items-start">
          <Filter
            form={c.form}
            filterData={[filterData, setFilterData]}
            actions={fetchRetailers}
            loading={isRetailerFetching}
            containerClass="tw-flex-grow tw-mr-2"
          />
          <div>
            <button
              type="button"
              className="btn btn-success mr-2 mt-2"
              onClick={() => actionsModal("verify-retailer-min", {})}
            >
              <AddIcon />
              <span className="ml-2">Add Retailer</span>
            </button>
            <button
              type="button"
              className="btn btn-success mt-2 mr-2"
              onClick={handleBatchCreate}
            >
              <AddIcon />
              <span className="ml-2">Batch Create</span>
            </button>
            <button
              type="button"
              className="btn btn-dark mt-2"
              onClick={handleViewErrors}
            >
              <span>Error Logs</span>
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-end">
          <h6 className="h6 font-weight-bold m-0 my-3">Manage Retailers</h6>
          <p className="mt-2">
            <Link
              to={`${process.env.PUBLIC_URL}/Retailer_Info_Sheet_with_DP_and_T&C.pdf`}
              target="_blank"
              download
              className="btn btn-success"
            >
              <i className="fas fa-download mr-2" />
              Download Information Sheet
            </Link>
          </p>
        </div>
        <div className="row">
          {isRetailerFetching ? (
            <div className="w-100 px-3" style={{ fontSize: 25 }}>
              <div className="bg-light-0 py-3 text-center w-100">
                <span className="fas fa-1x fa-spin fa-spinner" />
              </div>
            </div>
          ) : (
            <>
              {retailerList.length > 0 ? (
                retailerList.map((item) => (
                  <div
                    className="col-lg-4 col-xl-12 pb-3 pb-lg-3"
                    key={_.get(item, "id")}
                  >
                    <div className="card border">
                      <div className="row card-body">
                        <div className="col row pr-0">
                          <div className="col">
                            <CardItem
                              label="Retailer Min"
                              description={
                                _.get(item, "attributes.mobile") || "--"
                              }
                            />
                          </div>
                          <div className="col-2">
                            <CardItem
                              label="Name"
                              description={_.get(item, "attributes.full_name")}
                            />
                          </div>

                          <div className="col">
                            <CardItem
                              label="Retailer Type"
                              description={
                                _.get(item, "attributes.retailer_type") || "--"
                              }
                            />
                          </div>
                          <div className="col">
                            <CardItem
                              label="Date Created"
                              description={moment(
                                _.get(item, "attributes.created_at")
                              ).format("LL")}
                            />
                          </div>

                          <div className="col-2">
                            <CardItem
                              label="Modified By"
                              description={
                                _.get(item, "attributes.updated_by_name") ||
                                "--"
                              }
                            />
                          </div>

                          <div className="col">
                            <CardItem
                              label="Modified Date"
                              description={moment(
                                _.get(item, "attributes.updated_at")
                              ).format("LL")}
                            />
                          </div>

                          <div className="col">
                            <CardItem
                              label="Ka-Partner App Status"
                              description={
                                _.get(item, "attributes.is_active") || ""
                              }
                              status="yes"
                            />
                          </div>
                        </div>
                        <div className="col-1 text-center">
                          <div
                            className="font-weight-bold"
                            style={{
                              fontSize: ".7rem",
                              color: "rgba(0, 0, 0, .6)",
                            }}
                          >
                            Action
                          </div>
                          <div>{renderDropdownActions(item)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="bg-light-0 py-3 text-center w-100">
                  <p className="m-0" style={{ fontSize: ".7rem" }}>
                    There are no records found
                  </p>
                </div>
              )}
            </>
          )}
        </div>

        <div className="d-flex justify-content-end">
          <Pagination onChange={handlePager} data={retailerListPager} />
        </div>
      </div>

      <AddRetailer />
      <UpdateRetailer />
      <Details />
      <ValidateCSV />
      <BatchCreateSetup onComplete={fetchRetailers} />
      <ViewErrorLogs />
      <VerifyMin />
      <DeleteRetailer />
      <EnterPassword />
      <ViewBalance />
    </>
  );
};

export default RetailerContainer;
