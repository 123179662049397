import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

export function useLoading(key) {
  const apiLoading = useSelector(({ api }) => _.get(api, `loading`));
  const uiLoading = useSelector(({ ui }) => _.get(ui, `loading`));
  return uiLoading.includes(key) || apiLoading.includes(key);
}

export const renderStatus = {
  1: <span className="badge badge-clear-success p-0">active</span>,
  0: <span className="badge badge-clear-danger p-0">deactivated</span>,
};

export const renderBranchStatus = {
  1: <span className="badge badge-clear-danger p-0">blocked</span>,
  0: <span className="badge badge-clear-success p-0">active</span>,
};
