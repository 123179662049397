/* eslint-disable */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useOnClickOutside } from 'hooks';
import { join } from 'helper';

const Actions = ({ id, children, label }) => {
  const menuRef = useRef();
  const [expanded, setExpanded] = useState(false);

  // handle if clicked outside the action button
  useOnClickOutside(menuRef, () => setExpanded(false));

  return (
    <div className="dropdown text-right d-flex flex-column align-items-end">
      {label ? (
        <div className="text-xs text-gray-500 font-weight-bold d-none d-md-block">{label}</div>
      ) : null}
      <button
        className="btn btn-link py-0 outline-none"
        type="button"
        id={id}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded={expanded}
        onClick={() => setExpanded(true)}
      >
        <i className="fas fa-ellipsis-h" />
      </button>
      <div
        ref={menuRef}
        className={join`dropdown-menu dropdown-callout px-2 ${
          expanded ? `show` : ``
        }`}
        style={{ right: 0, left: 'auto' }}
        aria-labelledby={id}
      >
        {children}
      </div>
    </div>
  );
};

Actions.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Actions.defaultProps = {
  label: '',
};

export default Actions;
