import React from 'react';
import GenericTable from 'modules/common/components/GenericTable';
import PropTypes from 'prop-types';
import _ from 'lodash';

function TopRetailsProduct({ data, isLoading }) {
  return (
    <div className="col-12 p-0 mt-3">
      <div className="card shadow-sm h-100">
        <div className="card-body px-0">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h6 className="m-0 font-weight-bold mr-auto">
                    Top 5 Retail Products
                  </h6>
                </div>
                <GenericTable
                  isLoading={isLoading}
                  format={[
                    {
                      key: item => (
                        <small>
                          {_.get(item, 'attributes.promo_name') || '--'}
                        </small>
                      ),
                      label: 'Promo Name',
                      width: 'auto%',
                    },
                    {
                      key: item => (
                        <small>{_.get(item, 'attributes.sales') || '--'}</small>
                      ),
                      label: 'Sales',
                      width: 'auto',
                    },
                    {
                      key: item => (
                        <small>
                          {_.get(item, 'attributes.profit') || '--'}
                        </small>
                      ),
                      label: 'Profit',
                      width: 'auto',
                    },
                  ]}
                  data={data}
                  height={data.length ? '55vh' : '10vh'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TopRetailsProduct.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TopRetailsProduct;
