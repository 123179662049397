import React, { useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from 'modules/common/components/Pagination';
import GenericList from 'modules/common/components/GenericList';
import NoDataPlaceholder from 'modules/partners/components/NoDataPlaceholder';
import { useLoading } from 'modules/ui/hooks';
import { Route, useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import StandardPrice from './StandardPrice';
import SpecialPrice from './SpecialPrice';
import ProductInformation from './ProductInformation';
import UpdateSpecialPricings from '../modals/UpdateSpecialPricings';
import * as a from '../action';
import * as c from '../constant';

const filter = {
  page: 1,
  per_page: 10,
  sort_by: 'desc',
  q: '',
  is_active: true,
};

const ProductPricing = () => {
  const dispatch = useDispatch();
  const parentMatch = useRouteMatch();
  const history = useHistory();
  const match = useRouteMatch(`${parentMatch.url}/:product_id?`);
  const [filterData, setFilterData] = React.useState(filter);
  const { product_id } = _.get(match, 'params');
  const pricingMatrixLoading = useLoading(c.GET_PRICING_MATRICES);
  const specialPriceLoading = useLoading(c.GET_SPECIAL_PRICING_BY_PRICE_MATRIX);
  const pricingMatrixData = useSelector(
    ({ api }) => _.get(api, `${c.GET_PRICING_MATRICES}.res`) || []
  );
  const pricingMatrixList = useSelector(
    ({ api }) => _.get(api, `${c.GET_PRICING_MATRICES}.res.data`) || []
  );
  const specialPriceList = useSelector(
    ({ api }) =>
      _.get(api, `${c.GET_SPECIAL_PRICING_BY_PRICE_MATRIX}.res.data`) || []
  );

  const selectedProduct = useSelector(
    ({ api }) => _.get(api, `${c.SHOW_PRICING_DETAILS}.res.data`) || []
  );

  const pager = _.get(pricingMatrixData, 'meta.pagination', []);
  const handleSelectRow = data => {
    if (product_id !== _.get(data, 'id')) {
      history.push(`${parentMatch.url}/${_.get(data, 'id')}`);
    }
  };

  const handleChangePage = page => {
    dispatch(
      a.getPricingMatrices({
        ...filterData,
        page,
      })
    );
  };

  const handleChangeInput = key => ({ target }) => {
    setFilterData({
      ...filterData,
      [key]: target.value,
    });

    dispatch(
      a.getPricingMatrices({
        ...filterData,
        [key]: target.value,
      })
    );
  };

  useEffect(() => {
    dispatch(a.getPricingMatrices({ ...filterData }));
  }, [filterData]);
  useEffect(() => {
    if (product_id) {
      dispatch(
        a.getSpecialPricingByPriceMatrix(product_id, { is_active: true })
      );
    }
  }, [product_id]);

  useEffect(() => {
    if (product_id) {
      dispatch(a.showPricingDetails(product_id));
    }
  }, [product_id]);

  return (
    <>
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-3 px-0">
            <div
              className="app-listing d-flex flex-column h-100"
              style={{ padding: '0 3px' }}
            >
              <div className="px-3 mt-4 mb-3">
                <div className="form-group">
                  <input
                    className="form-control input-textbox"
                    type="text"
                    placeholder="Search..."
                    value={_.get(filterData, 'q') || ''}
                    onChange={handleChangeInput('q')}
                  />
                </div>
              </div>
              <span className="font-weight-bold mb-2 pl-2 text-muted text-sm">
                Listings {_.isEmpty(pager) ? '' : `(${_.get(pager, 'total')})`}
              </span>
              <div className="app-list shadow-sm flex-grow-1 mb-2">
                <div className="box-fit-wrapper h-100">
                  <div className="box-fit-overflow">
                    <Route
                      path={`${match.path}/:product_id?`}
                      render={({ match: { params } }) => (
                        <GenericList
                          isLoading={pricingMatrixLoading}
                          label=""
                          labelKey={item => (
                            <div className="d-flex align-items-center w-100 justify-content-between">
                              <div className="details py-1">
                                <p className="font-weight-bold mb-1">
                                  {_.get(item, 'attributes.matrix_name')}
                                </p>
                                <span className="text-xs text-muted">
                                  {_.get(item, 'attributes.product.name')}
                                </span>
                              </div>
                            </div>
                          )}
                          rowsCount={pager.total}
                          selectedKey="id"
                          data={pricingMatrixList}
                          selected={params.id}
                          onSelectRow={handleSelectRow}
                          height="550px"
                        />
                      )}
                    />
                    <Pagination onChange={handleChangePage} data={pager} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-9 px-0 p-4" style={{ background: '#dadada' }}>
            {_.isEmpty(pricingMatrixList) ? (
              <div
                style={{
                  display: 'flex',
                  minHeight: '80vh',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <NoDataPlaceholder label="No product selected." />
              </div>
            ) : (
              <>
                <ProductInformation data={selectedProduct} />
                <StandardPrice data={selectedProduct} />
                <SpecialPrice
                  data={specialPriceList}
                  dataLoading={specialPriceLoading}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <Route
        path={`${match.path}`}
        render={({ match: { params } }) => {
          return !params.product_id && pricingMatrixList.length > 0 ? (
            <Redirect
              to={`${parentMatch.path}/${_.get(pricingMatrixList, '0.id')}`}
            />
          ) : null;
        }}
      />
      <UpdateSpecialPricings />
    </>
  );
};

export default React.memo(ProductPricing);
