import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'modules/common/components/Loader';

const Sales = ({ label, data, isLoading }) => {
  return (
    <div className="col align-items-center">
      <div className="card shadow-sm">
        <div className="card-body">
          <div className="py-1 w-100">
            <div className="mb-2 text-muted">{label}</div>
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  marginLeft: '4rem',
                  position: 'absolute',
                }}
              >
                <Loader />
              </div>
            ) : (
              <span className="font-weight-bold">{data}</span>
            )}
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

Sales.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Sales;
