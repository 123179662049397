import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const getCell = (item, key) => {
  try {
    return typeof key === 'function' ? key(item) : _.get(item, key);
  } catch (err) {
    return '-';
  }
};

export default class GenericListModify extends React.Component {
  static propTypes = {
    data: PropTypes.instanceOf(Array),
    labelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    onSelectRow: PropTypes.oneOfType([
      PropTypes.instanceOf(Object),
      PropTypes.func,
      PropTypes.bool,
    ]),
    selected: PropTypes.string,
    label: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedKey: PropTypes.string,
    className: PropTypes.string,
    noRowsLabel: PropTypes.string,
    isLoading: PropTypes.bool,
    rowsCount: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
    onLoadMore: PropTypes.func,
    header: PropTypes.bool,
  };

  static defaultProps = {
    data: [],
    labelKey: 'name',
    onSelectRow: false,
    selected: '',
    height: '100%',
    selectedKey: 'id',
    label: 'List',
    className: 'message-list',
    noRowsLabel: 'No Items Found',
    isLoading: false,
    rowsCount: false,
    header: false,
    onLoadMore: () => {},
  };

  handleSelectRow = data => e => {
    e.preventDefault();
    const { onSelectRow } = this.props;
    if (onSelectRow) {
      onSelectRow(data);
    }
  };

  handleLoadMore = e => {
    e.preventDefault();
    const { onLoadMore, onSelectRow, data } = this.props;
    onLoadMore();
    const last = data[data.length - 1];
    if (last) onSelectRow(last);
  };

  render() {
    const {
      data,
      labelKey,
      label,
      selected,
      height,
      selectedKey,
      className,
      noRowsLabel,
      isLoading,
      rowsCount,
      header,
    } = this.props;

    return (
      <div
        className="generic-list bg-white mb-2"
        style={{
          overflowY: 'scroll',
          height,
          flexGrow: '1',
        }}
      >
        <table className={`table table-link table-hover ${className} table-lg`}>
          {header && (
            <thead>
              <tr>
                <th className="text-ellipsis bg-white border-0 text-left">
                  <h4 className="mb-0 font-weight-bold">
                    <small className="text-muted font-weight-bold">
                      List of :
                    </small>{' '}
                    &nbsp; {label}
                    {rowsCount && ` (${rowsCount})`}
                  </h4>
                </th>
              </tr>
            </thead>
          )}
          <tbody>
            {data.length < 1 ? (
              <tr>
                <td rowSpan="5">
                  {isLoading ? (
                    <>
                      <div
                        className="d-flex justify-content-center align-items-center flex-column"
                        style={{ minHeight: 630 }}
                      >
                        <div
                          className="spinner-border text-success"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <small className="text-muted tex-center font-weight-bold d-block">
                          Loading...
                        </small>
                      </div>
                    </>
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: 630 }}
                    >
                      <small className="text-muted tex-center font-weight-bold">
                        {noRowsLabel}
                      </small>
                    </div>
                  )}
                </td>
              </tr>
            ) : (
              data.map(item => {
                const isSelected =
                  `${selected}` === `${_.get(item, selectedKey)}`;
                const key = item[selectedKey];
                return (
                  <tr
                    key={key}
                    className={
                      isSelected ? 'generic-active font-weight-bold' : ''
                    }
                    onClick={this.handleSelectRow(item)}
                    style={{ cursor: 'pointer' }}
                  >
                    <td className="py-0 px-2">{getCell(item, labelKey)}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
