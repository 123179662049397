import React, { memo } from "react";
import _ from "lodash";
import { checkPermissions } from "helper";
import TransactionsButton from "../components/TransactionsButton";
import SearchBar from "../components/SearchBar";
import DealerSimsList from "../components/DealerSimsList";
import RetailerSimsList from "../components/RetailerSimsList";

const MySims = () => (
  <div
    className="my__sims-container px-5 py-3 "
    style={{ background: "#ECEDF2" }}
  >
    <div className="tw-flex tw-justify-between tw-items-start">
      <SearchBar />
      <TransactionsButton />
    </div>
    {checkPermissions(["can-view-dealer-sim"]) && <DealerSimsList />}
    {checkPermissions(["can-view-retailer-sim"]) && <RetailerSimsList />}
  </div>
);
export default memo(MySims);
