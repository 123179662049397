import React, { useMemo, useState, useContext } from "react";
import IdleTimer from "react-idle-timer";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { ModalContext } from "App";
import IdleDialog from "../components/UserIdleDialog";

// allow configuration of key with environment
const expIdleTimeout = 1000 * 60 * 60 * 24;
const idleTimOutKey = process.env.REACT_APP_IDLE_TIMEOUT_KEY || `__idleTimeout`;
const idleTimeoutDefault = process.env.REACT_APP_IDLE_TIMEOUT_DEFAULT || expIdleTimeout;

const UserIdleTimer = () => {
  const location = useLocation();
  const timeoutFromParam = useMemo(
    () => qs.parse(location.search)[idleTimOutKey],
    [location]
  );
  const timeout = +timeoutFromParam || +idleTimeoutDefault;
  const setModal = useContext(ModalContext);
  const [sessionCountdown, setSessionCountdown] = useState(2 * 60);
  const idleProps = {
    onAction: () => setSessionCountdown(2 * 60),
    onIdle: () => setModal("session-expired"),
    timeout,
  };
  return (
    <React.Fragment>
      <IdleTimer {...idleProps} />

      <IdleDialog
        sessionCountdown={sessionCountdown}
        setSessionCountdown={setSessionCountdown}
      />
    </React.Fragment>
  );
};

export default UserIdleTimer;
