import React from "react";
import _ from "lodash";
import { useDealer } from "app/load-wallet/context/DealerProvider";

const SelectedDealerInfo = () => {
  const { selectedCompany, selectedDealer } = useDealer();

  return (
    <>
      <div className="shadow-sm">
        <header
          className="header-rounded rounded-top-both-1 background-gray text-white overflow-hidden d-flex justify-content-between pl-3"
          style={{ backgroundColor: "#939393", height: "2.5rem" }}
        >
          <div className="header-title my-auto">
            <h6 className="h6 font-weight-bold p-0 m-0 text-sm font-weight-bold">
              Sub-Dealer Details
            </h6>
          </div>
        </header>
        <div className="p-4 bg-white rounded-bottom-both-1">
          <div className="w-100">
            <div className="row mb-4">
              <div className="col d-flex flex-column text-sm-size">
                <span className="label font-weight-bold mb-1 text-xs text-gray-500 font-weight-bold">
                  Company Name
                </span>
                <p className="description mb-0" style={{ fontSize: ".85rem" }}>
                  {_.startCase(selectedDealer?.attributes?.company_name) || ""}
                </p>
              </div>
              <div className="col d-flex flex-column text-sm-size">
                <span className="label font-weight-bold mb-1 text-xs text-gray-500 font-weight-bold">
                  Dealer Code
                </span>
                <p className="description mb-0" style={{ fontSize: ".85rem" }}>
                  {_.startCase(selectedCompany?.attributes?.dealer_code) || ""}
                </p>
              </div>
              <div className="col d-flex flex-column text-sm-size">
                <span className="label font-weight-bold mb-1 text-xs text-gray-500 font-weight-bold">
                  Sales Channel
                </span>
                <p className="description mb-0" style={{ fontSize: ".85rem" }}>
                  {_.startCase(selectedDealer?.attributes?.channel_name) || ""}
                </p>
              </div>
              <div className="col d-flex flex-column text-sm-size">
                <span className="label font-weight-bold mb-1 text-xs text-gray-500 font-weight-bold">
                  Account Type
                </span>
                <p className="description mb-0" style={{ fontSize: ".85rem" }}>
                  {_.startCase(selectedDealer?.attributes?.account_type_name) ||
                    ""}
                </p>
              </div>
              <div className="col d-flex flex-column text-sm-size">
                <span className="label font-weight-bold mb-1 text-xs text-gray-500 font-weight-bold">
                  Account Status
                </span>
                <p className="description mb-0 badge-success font-weight-bold">
                  {_.startCase(selectedDealer?.attributes?.account_status) ||
                    ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectedDealerInfo;
