import React from 'react';
import Layout from '../Layout';

const TermsAndConditions = () => {
  return (
    <Layout>
      <h4 className="font-weight-bold text-center m-0 mb-4">
        Terms and Conditions
      </h4>
    </Layout>
  );
};

export default TermsAndConditions;
