import React, { useState, useMemo, useContext } from "react";
import withModal from "modules/common/hoc/withModal";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { removeEmpty } from "helper";
import _ from "lodash";
import { ModalContext } from "App";
import ButtonAsync from "components/Button";
import { useDealer } from "app/load-wallet/context/DealerProvider";
import { useDealerSim } from "app/load-wallet/dealer-sims/context/DealerSimProvider";
import ReactInputMask from "react-input-mask";
import * as actions from "../actions";
import * as c from "../constant";

const form = {
  assigned_to: "",
  mobile_identification_number: "",
  is_active: true,
  sim_status: "ACTIVE",
};

function RetailerSimFormModal({ item }) {
  const { profile, dealerRole, isNonMigrated, role, isB2B } = useDealer();
  const { dealerSimsAll } = useDealerSim();
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);
  const [formData, setFormData] = useState(form);
  const [pending, setPending] = useState(false);

  const closeModal = () => {
    setModal({ isOpen: false });
  };

  const parentSim = useMemo(() => {
    return dealerSimsAll?.find((sim) =>
      dealerRole?.includes("smmt")
        ? sim?.attributes?.type?.includes("DSP")
        : sim?.attributes?.type?.includes("-BDL")
    );
  }, [dealerSimsAll, dealerRole]);

  const replaceEndOfSimType = useMemo(() => {
    return parentSim?.attributes?.type.replace("-BDL", "-HA");
  }, [parentSim]);

  const isRetailerSimType =
    dealerRole?.includes("smmt") || isNonMigrated || isB2B;
  const args = useMemo(
    () => ({
      email: item?.attributes?.email || profile?.email,
      dealer_id: item?.id || profile?.id || profile?.parent_id,
      parent_min:
        isNonMigrated || isB2B
          ? ""
          : parentSim?.attributes?.mobile_identification_number,
      sim_type: isRetailerSimType ? "" : replaceEndOfSimType,
      role_type: isNonMigrated || isB2B ? role : "",
    }),
    [
      profile,
      item,
      dealerRole,
      parentSim,
      replaceEndOfSimType,
      role,
      isNonMigrated,
      isB2B,
    ]
  );

  const handleChangeInput = (key) => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);

    const payload = {
      ...args,
      ...formData,
    };
    if (c.otp_enabled === "false" && c.isDevEnvironment) {
      dispatch(
        actions.createRetailerSims(removeEmpty(payload), closeModal, closeModal)
      );
      setPending(false);
      return;
    }

    dispatch(
      actions.setOtp(
        {
          mobile: formData?.mobile_identification_number,
          purpose: c.PURPOSE.portal,
        },
        (res) =>
          setModal(
            "confirm-otp",
            removeEmpty({ ...payload, ...res?.res?.data })
          ),
        setPending(false)
      )
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-6">
          <label className="form-label font-weight-bold">SIM Number:</label>
          <ReactInputMask
            mask="+639999999999"
            maskChar=""
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={_.get(formData, "mobile_identification_number") || ""}
            onChange={handleChangeInput("mobile_identification_number")}
          />
        </div>
        <div className="col-6">
          <label
            className="form-label font-weight-bold"
            style={{ fontSize: 12 }}
          >
            Assign to physical SIM holder (name)
          </label>
          <input
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={_.get(formData, "assigned_to") || ""}
            onChange={handleChangeInput("assigned_to")}
            maxLength={50}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 my-4">
          <span className="text-left form-label">
            By clicking &quot;CONTINUE&quot; a One-Time-Password(OTP) <br />
            Will be sent through this number to verify ownership
          </span>
        </div>
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <ButtonAsync
              type="submit"
              className="btn btn-primary position-relative text-uppercase mx-2"
              loading={pending}
            >
              Continue
            </ButtonAsync>
            <button
              type="button"
              onClick={closeModal}
              className="text-uppercase btn btn-danger font-weight-bold mr-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

RetailerSimFormModal.defaultProps = {
  item: {},
};

RetailerSimFormModal.propTypes = {
  item: PropTypes.instanceOf(Object),
};

const modalKey = "fill-retailer-sim";
const modalConfig = {
  title: "Add New Retailer SIM",
  size: "modal-md",
};

export default withModal(modalKey, modalConfig)(RetailerSimFormModal);
