import React from 'react';
import PropTypes from 'prop-types';

const FormInputBorderBottom = ({
  label,
  onChange,
  value,
  name,
  type,
  inputStyles,
  labelStyles,
  maxLength,
  ...otherProps
}) => {
  return (
    <div>
      <label
        className="form-label font-weight-bold text-dark"
        style={labelStyles}
      >
        {label}
      </label>
      <input
        type={type}
        name={name}
        className="form-control form-custom form-control_bottom px-3"
        placeholder="Enter..."
        required
        style={inputStyles}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        {...otherProps}
      />
    </div>
  );
};

FormInputBorderBottom.defaultProps = {
  label: 'Label',
  type: 'text',
  labelStyles: { fontSize: '.6rem' },
  inputStyles: { fontSize: '.7rem' },
  onChange: () => {},
  maxLength: '50',
};

FormInputBorderBottom.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  labelStyles: PropTypes.instanceOf(Object),
  inputStyles: PropTypes.instanceOf(Object),
  maxLength: PropTypes.string,
};

export default FormInputBorderBottom;
