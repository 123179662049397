import React from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";

function LineCharts({ data, height }) {
  if (isEmpty(data)) return null;
  return (
    <Bar
      data={data}
      height={height || 446}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
        tooltips: {
          enabled: true,
        },
        hover: { mode: null },
      }}
    />
  );
}

LineCharts.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  height: PropTypes.number.isRequired,
};

export default LineCharts;
