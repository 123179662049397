import React, { useEffect, useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ModalContext } from 'App';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import GenericTable from '../../../common/components/GenericTable';
import Pagination from '../../../common/components/Pagination';
import * as actions from '../../action';
import * as c from '../../constant';
import TicketInformationModal from '../components/TicketInformationModal';

const filterData = {
  status: '',
  issue_category_id: '',
  q: '',
  my_tickets: 0,
};

function AssuranceContainer({
  getTicket,
  pager,
  isLoading,
  ticketList,
  getIssueCategory,
  issueCategory,
}) {
  const setModal = useContext(ModalContext);
  const [filter, setFilter] = useState(filterData);

  const handleChangePage = page => {
    getTicket({ page, ...filter });
  };

  const handleSelectedTicket = args => {
    setModal('ticket-information', { data: args });
  };

  const handleChnageSelect = key => e => {
    setFilter({
      ...filter,
      [key]: e.target.value || '',
    });
    getTicket({ ...filter, [key]: e.target.value });
  };

  const handleClearFilter = e => {
    e.preventDefault();
    setFilter(filterData);
    getTicket(filterData);
  };

  const handleChange = key => e => {
    setFilter({
      ...filter,
      [key]: e.target.value || '',
    });
    getTicket({ ...filter, [key]: e.target.value });
  };

  const renderStatus = status => {
    if (status === 'open') {
      return <span className="text-success font-weight-bold">OPEN</span>;
    }
    if (status === 'on-going') {
      return <span className="text-warning font-weight-bold">ONGOING</span>;
    }
    if (status === 'closed') {
      return <span className="text-danger font-weight-bold">CLOSED</span>;
    }
    if (status === 'escalated') {
      return <span className="text-muted font-weight-bold">ESCALATED</span>;
    }
    return null;
  };

  useEffect(() => {
    getTicket();
    getIssueCategory();
  }, [getTicket, getIssueCategory]);

  return (
    <>
      <h1 className="h5 flex-grow-1 mt-0 mb-3">Inquiries</h1>

      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-3">
              <div className="form-group">
                <label className="form-label">Filter by Category:</label>
                <select
                  className="custom-select"
                  name="issue_category_id"
                  value={_.get(filter, 'issue_category_id') || ''}
                  onChange={handleChnageSelect('issue_category_id')}
                >
                  <option value="">ALL Category</option>
                  {issueCategory.map(x => (
                    <option key={_.get(x, 'id')} value={_.get(x, 'id')}>
                      {_.get(x, 'attributes.name')}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="form-label">Filter by Status:</label>
                <select
                  className="custom-select"
                  name="status"
                  value={_.get(filter, 'status') || ''}
                  onChange={handleChnageSelect('status')}
                >
                  <option value="">All Status</option>
                  <option value="open">Open</option>
                  <option value="on-going">Ongoing</option>
                  <option value="closed">Closed</option>
                  {/* <option value="escalated">Escalated</option> */}
                </select>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="form-label">Filter by Tickets:</label>
                <select
                  className="custom-select"
                  name="my_tickets"
                  value={_.get(filter, 'my_tickets') || ''}
                  onChange={handleChnageSelect('my_tickets')}
                >
                  <option value="0">All Tickets</option>
                  <option value="1">My Tickets</option>
                </select>
              </div>
            </div>
            <div className="col-2 d-flex align-items-center">
              <button
                type="button"
                className="btn btn-sm btn-danger mt-3"
                onClick={handleClearFilter}
                style={{
                  boxShadow: 'none',
                }}
              >
                Clear all Filter
              </button>
            </div>
            <div className="col-3 d-flex align-items-center">
              <div className="form-group w-100">
                <form className="form-search mt-3">
                  <input
                    placeholder="Search..."
                    type="text"
                    className="form-control"
                    name="q"
                    value={_.get(filter, 'q') || ''}
                    onChange={handleChange('q')}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0 bg-white border">
            <GenericTable
              isLoading={isLoading}
              onSelectRow={handleSelectedTicket}
              format={[
                {
                  key: item => (
                    <span>
                      {_.get(item, 'attributes.control_number') || '--'}
                    </span>
                  ),
                  label: 'Ticket',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span>{_.get(item, 'attributes.subject') || '--'}</span>
                  ),
                  label: 'Subject',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span>
                      {_.get(item, 'attributes.issue_category_name') || '--'}
                    </span>
                  ),
                  label: 'Category',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span>
                      {_.get(
                        item,
                        'included.assignees.0.attributes.assignee_name'
                      ) || '--'}
                    </span>
                  ),
                  label: 'Assigned To',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span>
                      {moment(_.get(item, 'attributes.updated_at')).format(
                        'LLL'
                      ) || '--'}
                    </span>
                  ),
                  label: 'Elapsed',
                  width: 'auto',
                },
                {
                  key: item => renderStatus(_.get(item, 'attributes.status')),
                  label: 'Status',
                  width: 'auto',
                },
              ]}
              data={ticketList}
              height="125vh"
            />
            <Pagination onChange={handleChangePage} data={pager} />
          </div>
        </div>
      </div>
      <TicketInformationModal />
    </>
  );
}

AssuranceContainer.propTypes = {
  getTicket: PropTypes.func.isRequired,
  pager: PropTypes.instanceOf(Object).isRequired,
  ticketList: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getIssueCategory: PropTypes.func.isRequired,
  issueCategory: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api }) => ({
  ticketList: _.get(api, `${c.GET_TICKET}.list`) || [],
  issueCategory: _.get(api, `${c.GET_ISSUE_CATEGORY}.list`) || [],
  pager: _.get(api, `${c.GET_TICKET}.pager`) || {},
  isLoading: api.loading.indexOf(c.GET_TICKET) > -1,
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(AssuranceContainer);
