import { service } from "api/service";

export const getRetailers = ({ queryKey: [__, params] }) => {
  const url = `external/api/v1/retailers`;
  return service.get(url, { params });
};

export const getRetailerById = ({ queryKey: [__, id] }) => {
  const url = `external/api/v1/retailers/${id}`;
  return service.get(url);
};

export const getRetailerSimsById = ({ queryKey: [__, id, params] }) => {
  const url = `retailer/api/v1/retailers/${id}/sims`;
  return service.get(url, { params });
};

export const verifyPassword = (password) => {
  const url = "api/v1/external/users/my/validate-password";
  return service.post(url, { password });
};

export const addNewRetailerSim = ({ retailerId, ...params }) => {
  const url = `retailer/api/v1/retailers/${retailerId}/sims`;
  return service.post(url, params);
};

export const updateRetailerSim = ({ retailerId, ...params }) => {
  const url = `retailer/api/v1/retailers/${retailerId}/sims`;
  return service.put(url, params);
};

export const detachRetailerSim = ({ retailerId, ...params }) => {
  const url = `retailer/api/v1/retailers/${retailerId}/sims`;
  return service.delete(url, { data: params });
};

export const inquireRetailerBalance = ({ queryKey: [__, min] }) => {
  const url = `mobile/api/v1/retailerSim/inquireBalance/${min}`;
  return service.get(url);
};

export const linkRetailerMin = ({ dealer_sim_id, retailer_id }) => {
  const url = `external/api/v1/dealer-sims/${dealer_sim_id}/retailers/${retailer_id}`;
  return service.post(url);
};

export const bulkLinkRetailers = ({ dealer_sim_id, retailer_ids }) => {
  const url = `external/api/v1/dealer-retailer/${dealer_sim_id}/bulk-retailers`;
  return service.post(url, { retailer_ids });
};

export const unlinkRetailerMin = ({ dealer_sim_id, retailer_id }) => {
  const url = `external/api/v1/dealer-sims/${dealer_sim_id}/retailers/${retailer_id}`;
  return service.delete(url);
};

export const getParentSim = ({ queryKey: [__, params] }) => {
  const url = `/api/v1/dealer-sims`;
  return service.get(url, { params });
};
