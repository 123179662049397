import React from "react";
import PropTypes from "prop-types";
import SearchField from "components/SearchField";

const Filter = ({ loading, onChange }) => {
  return (
    <div className="row">
      <div className="col-3 pr-2">
        <SearchField
          isLoading={loading}
          onChange={(value) => onChange((state) => ({ ...state, q: value }))}
          custom={{ right: "2rem" }}
        />
      </div>
    </div>
  );
};

Filter.propTypes = {
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Filter;
