import React from 'react';
import PropTypes from 'prop-types';

/**
 * Extends a reguar button by adding a loading parameter
 * that accepts a boolean. Loading state also disables the button
 * from further click events until loading completes or returns false
 */

const ButtonAsync = ({ loading, children, ...rest }) => (
  <button
    className="btn btn-primary position-relative"
    type="button"
    {...rest}
    disabled={rest.disabled || loading}
  >
    {loading ? (
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    ) : null}
    <span style={{ opacity: loading ? 0.2 : 1 }}>{children}</span>
  </button>
);

ButtonAsync.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ButtonAsync.defaultProps = {
  loading: false,
};

export default ButtonAsync;


