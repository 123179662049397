import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from 'helper';
// import _ from 'lodash';

const TaxDetails = ({
  onTooglePayment,
  payment,
  cartDetails,
  formData,
  handleCheckedCreditTax,
  tax,
  cwt,
}) => {
  return (
    <div className="card">
      <div className="card-header bg-card-header">
        <span className="justify-content-center text-white">Tax Options</span>
      </div>
      <div className="card-body shadow-sm">
        <div className="row">
          <div className="col p-0">
            <div className="form-group px-2">
              <label className="form-label">VAT:</label>
              <input
                className="form-control input-textbox form-custom form-control_bottom"
                type="text"
                value={formatNumber(tax)}
                readOnly
              />
            </div>
          </div>
          {formData.with_cwt === 1 && (
            <div className="col p-0">
              <div className="form-group px-2">
                <label className="form-label">Credit Withholding Tax:</label>
                <input
                  className="form-control input-textbox form-custom form-control_bottom"
                  type="text"
                  value={formatNumber(Math.round(cwt))}
                  readOnly
                />
              </div>
            </div>
          )}
          <div className="col">
            <button className="btn btn-blue mt-4" type="button" disabled>
              ATTACH FORM 2307
            </button>
          </div>
        </div>
        {formData.with_cwt === 1 ? (
          <a
            href="/"
            onClick={handleCheckedCreditTax('with_cwt', 0)}
            className="custom-checkbox pl-0"
          >
            <i className="fas fa-check-square text-success mr-2" />
            <label className="mb-0">Credit Tax</label>
          </a>
        ) : (
          <a
            href="/"
            onClick={handleCheckedCreditTax('with_cwt', 1)}
            className="custom-checkbox pl-0"
          >
            <i className="fas fa-square text-muted mr-2" />
            <label className="mb-0">Credit Tax</label>
          </a>
        )}
        {/* <div>
          <span className="text-muted font-weight-bold">Total Tax: {totalTax}</span>
        </div> */}
        <hr className="my-5" />
        <div className="row">
          <div className="col">
            <span className="text-muted font-weight-bold">TOTAL PRICE</span>
            <h4 className="text-success font-weight-bold m-0">
              {cartDetails.subtotal || '00'}
            </h4>
          </div>
          <div className="col">
            <button
              className="btn btn-success float-right"
              disabled={payment}
              onClick={onTooglePayment(true)}
              type="button"
            >
              PLACE ORDER
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

TaxDetails.propTypes = {
  onTooglePayment: PropTypes.func.isRequired,
  handleCheckedCreditTax: PropTypes.func.isRequired,
  payment: PropTypes.bool.isRequired,
  tax: PropTypes.number.isRequired,
  cwt: PropTypes.number.isRequired,
  cartDetails: PropTypes.instanceOf(Object).isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
};

export default TaxDetails;
