export const SET_OTP = "LOAD-WALLET/set_otp";
export const BALANCE_INQUIRE = "LOAD-WALLET/balance_inquire";
export const CONFIRM_OTP = "LOAD-WALLET/confirm_otp";

// Retailer Sims
export const CREATE_RETAILER_SIMS = "LOAD-WALLET/create_retailer_sims";
export const GET_RETAILER_SIMS = "LOAD-WALLET/get_retailer_sims";
export const SHOW_RETAILER_SIMS = "LOAD-WALLET/show_retailer_sims";
export const UPDATE_RETAILER_SIMS = "LOAD-WALLET/update_retailer_sims";
export const VALIDATE_RETAILER_SIM = "LOAD-WALLET/verify-retailer-sim";
export const DELETE_RETAILER_SIMS = "LOAD-WALLET/delete_retailer_sims";
export const RETAILER_SIMS_UPDATE_RETAILER_SIMS =
  "LOAD-WALLET/retailer_sims_delete_retailer_sims";
export const RETAILER_SIMS_GET_RETAILER_SIMS =
  "LOAD-WALLET/retailer_sims_get_retailer_sims";
export const RETAILER_SIMS_GET_RETAILER_SIMS_ALL =
  "LOAD-WALLET/retailer_sims_get_retailer_sims_all";

// Dealer Sims
export const GET_DEALER_LIST = "LOAD-WALLET/get_dealer_list";
export const GET_SELECTED_DEALER_BY_ID = "LOAD-WALLET/get_selected_dealer";
export const CREATE_DEALER = "LOAD-WALLET/create_dealer";
export const UPDATE_DEALER = "LOAD-WALLET/update_dealer";
export const CREATE_DEALER_SIMS = "LOAD-WALLET/create_dealer_sim";
export const UPDATE_DEALER_SIMS = "LOAD-WALLET/update_dealer_sims";
export const GET_DEALER_SIMS = "LOAD-WALLET/get_dealer_sims";
export const GET_DEALER_SIMS_ALL = "LOAD-WALLET/get_dealer_sims_all";
export const REMOVE_DEALER_SIM = "LOAD-WALLET/remove_dealer_sim";
export const REMOVE_RETAILER_SIM = "LOAD-WALLET/remove_retailer_sim";
export const RETRY_FAILED_SIM = "LOAD-WALLET/retry_sim";

// Retailer Sims (Multiple)
export const GET_RETAILERS_LIST = "LOAD-WALLET/get_retailers_list";
export const CREATE_RETAILER_MULTIPLE_SIMS =
  "LOAD-WALLET/create_retailer_multiple_sims";
export const GET_RETAILER_SIMS_BY_ID = "LOAD-WALLET/get_retailer_sims_by_id";
export const UPDATE_RETAILER_SIMS_BY_ID =
  "LOAD-WALLET/update_retailer_sims_by_id";
export const DETACH_RETAILER_SIMS_BY_ID =
  "LOAD-WALLET/detach_retailer_sims_by_id";

// Company
export const UPDATE_COMPANY = "LOAD-WALLET/update_company";
export const GET_SELECTED_COMPANY_BY_ID = "LOAD-WALLET/get_selected_company";

// Others
export const GET_ROLE_BY_SELECTED_DEALER_BY_ID = "LOAD-WALLET/get_role_by_id";
export const GET_ACCOUNT_TYPE = "LOAD-WALLET/get_account_type";
export const DOWNLOAD_SIM_HISTORY = "LOAD-WALLET/download_sim_history";
export const VALIDATE_PASSWORD = "USE-MGT/validate_password";
export const RETAILER_BALANCE_INQUIRE =
  "LOAD-WALLET/download_retailer_sim_history";
export const FORM_DATA = {};
export const TEXT_LIMIT = 20;
export const OTP_STATUS = false;
export const PARENT_SIM_TYPES = ["RD-BDL", "KA-BDL", "SKA-BDL", "PD-BDL"];
export const SMMT_PARENT_SIM_TYPES = ["DSP"];

export const MESSAGES_SIM_ACTION = {
  unblock: "Sim successfully unblocked.",
  block: "Sim successfully blocked.",
  update: "Sim successfully updated.",
  delete: "Sim successfully deleted.",
  default: "Sim successfully added.",
};

export const DEALER_SIM_ROLES = {
  smmt: "smmt",
  "sub-dealer-ska": "sub_dealer",
  "sub-dealer": "sub_dealer",
  "super-user": "dealer",
  "super-user-ka": "dealer",
  "super-user-b2b": "dealer",
  "corporate-admin": "sub_dealer",
  "corporate-admin-ska": "sub_dealer",
  "corporate-admin-rd": "dealer",
  "corporate-admin-ka": "dealer",
  "corporate-admin-smmt": "smmt",
  "corporate-admin-b2b": "dealer",
};

export const DEALERSIM_VISIBLE_LIST = [null, "Failed", "Processing"];
export const SIM_STATUS = {
  blocked: "BLOCKED",
  inactive: "INACTIVE",
  active: "ACTIVE",
};
export const ACTIONS = { add: "ADD", edit: "EDIT" };
export const DEALERSIM_STATUS = {
  approved: "Approved",
  approval: "For Approval",
  declined: "Declined",
  processing: "Processing",
  failed: "Failed",
  incomplete: "Incomplete",
};
export const REFETCH_DEALERSIM_BY_DELETE_STATUS = ["Processing", "Done"];

export const SUBMIT_TYPE = {
  block: "block",
  unblock: "unblock",
  update: "update",
  delete: "delete",
};

export const sortFilter = {
  order_by: "created_at",
  sort_by: "desc",
};

export const listUserFilter = {
  has_application: true,
  application_status: "done",
  account_status: "on-boarded",
  per_page: 10,
};

export const PURPOSE = {
  portal: "portal-verification",
};

export const search = document.location.search.substring(1);
export const urlSearchParams = new URLSearchParams(search);
export const otp_enabled = urlSearchParams.get("otp_enabled");
export const node_environment = process.env.NODE_ENV;
export const isDevEnvironment = node_environment?.includes("development");

export const form = [
  {
    type: "select-async",
    name: "type",
    label: "SIM Type:",
    api: "api/v1/sim_types",
    transformer: (list) =>
      list?.data.map((x) => ({
        value: x?.attributes?.organization_type,
        label: x?.attributes?.organization_type,
      })),
    params: { type: "dealer_sims" },
    isClearable: true,
  },
  {
    type: "select-async",
    name: "assigned_to",
    label: "Assigned To:",
    api: "api/v1/dealer-sims/pluck/dealer-retailer-sim",
    transformer: (list) =>
      list.map((x) => ({
        value: x,
        label: x,
      })),
    params: { field: "assigned_to" },
    isClearable: true,
  },
  {
    type: "select-async",
    name: "request_type",
    label: "Request Type:",
    api: "api/v1/dealer-sims/pluck",
    transformer: (list) =>
      list.map((x) => ({
        value: x,
        label: x,
      })),
    params: { field: "request_type" },
    isSearchable: false,
    isClearable: true,
  },
  {
    type: "select-static",
    name: "status",
    label: "Request Status:",
    options: [
      {
        value: "Approved",
        label: "Approved",
      },
      {
        value: "Declined",
        label: "Declined",
      },
      {
        value: "For Approval",
        label: "For Approval",
      },
      {
        value: "Processing",
        label: "Processing",
      },
      {
        value: "Failed",
        label: "Failed",
      },
      {
        value: "Incomplete",
        label: "Incomplete",
      },
    ],
    isClearable: true,
  },
  {
    type: "select-static",
    name: "sim_status",
    label: "SIM Status:",
    options: [
      {
        value: "ACTIVE",
        label: "Active",
      },
      {
        value: "INACTIVE",
        label: "Inactive",
      },
      {
        value: "BLOCKED",
        label: "Blocked",
      },
    ],
    isClearable: true,
  },
];
