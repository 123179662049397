import _ from 'lodash';
import { apiPut, apiPost, setItem } from 'api/actions';
import { USER_PROFILE } from 'modules/auth/constants';
import history from '../../history';
import * as c from './constants';

const profileFlow = ({ dispatch, getState }) => next => action => {
  next(action);

  if (action.type === c.PROFILE) {
    dispatch(
      apiPost(
        '/api/v1/external/users/my/profile',
        action.payload,
        c.PROFILE_SUCCESS,
        c.PROFILE_ERROR
      )
    );
  }

  if (action.type === c.PROFILE_UPDATE) {
    dispatch(
      apiPut(
        '/api/v1/external/users/my/profile',
        action.payload,
        c.PROFILE_UPDATE_SUCCESS,
        c.PROFILE_UPDATE_ERROR
      )
    );
  }

  if (action.type === c.PROFILE_UPDATE_SUCCESS) {
    const { api } = getState();
    const updatedProfile = _.omit(_.get(api[USER_PROFILE], 'item'), ['was_updated'])
    dispatch(setItem(USER_PROFILE, updatedProfile));

    try {
      sessionStorage.setItem('profile', JSON.stringify(updatedProfile))
    } catch {
      //
    }

    history.push('/')
  }
};

export default [profileFlow];
