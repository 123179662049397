import React, { useState, useContext, useMemo } from "react";
import { useLoading } from "modules/ui/hooks";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { removeEmpty } from "helper";

import { ModalContext } from "App";
import withModal from "modules/common/hoc/withModal";
import { ToastSuccess } from "modules/common/components/Toast";
import ButtonAsync from "components/Button";
import TemporaryAccountForm from "../forms/TemporaryAccountForm";
import AccountInfoForm from "../forms/AccountInfoForm";

import * as actions from "../action";
import * as c from "../constant";

const temporaryFormFields = {
  email: "",
  mobile: "",
};

const form = {
  first_name: "",
  last_name: "",
  username: "",
  role_id: "",
};

function FillupUserInfo({
  createUsers,
  getRetailerUsers,
  getBranch,
  getShift,
  getRoles,
  roleList,
  userRoles,
}) {
  const setModal = useContext(ModalContext);
  const [temporaryForm, setTemporaryForm] = useState(temporaryFormFields);
  const [formData, setformData] = useState(form);

  const setFilter = useSelector(({ modal }) =>
    _.get(modal, "modalData.setFilter")
  );

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleTemporaryFormChange = (values) =>
    setTemporaryForm((prevState) => ({
      ...prevState,
      ...values,
    }));

  const handleChange = (key) => ({ target }) => {
    setformData({
      ...formData,
      [key]: target.value,
    });
  };

  const checkInput = useMemo(() => {
    const { first_name, last_name, username, role_id } = formData;
    const { email, mobile } = temporaryForm;
    return (
      _.isEmpty(first_name) ||
      _.isEmpty(last_name) ||
      _.isEmpty(username) ||
      _.isEmpty(role_id) ||
      _.isEmpty(email) ||
      _.size(mobile) !== 13
    );
  }, [formData, temporaryForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const temp = { ...formData, ...temporaryForm };
    Object.keys(temp).forEach((k) => {
      temp[k] = temp[k].trim();
    });
    createUsers(temp, () => {
      ToastSuccess("Successfully created user account.");
      getRetailerUsers(c.blFlInitState);
      setFilter(c.blFlInitState);
      setModal({ isOpen: false });
    });
  };

  React.useEffect(() => {
    const user_role = _.head(userRoles);
    const payload = {
      user_guard_name: "",
      role_guard_name: "",
    };

    const isSMMTCA = user_role === "corporate-admin-smmt";
    const isKACA = user_role === "corporate-admin-ka";
    const isRDCA = user_role === "corporate-admin-rd";
    const isPDCA = user_role === "corporate-admin";
    const isSKACA = user_role === "corporate-admin-ska";
    const isB2BCA = user_role === "corporate-admin-b2b";
    const isSUNM = user_role === "super-user-non-migrated";
    const isCANM = user_role === "corporate-admin-non-migrated";
    const isBANM = user_role === "branch-admin-non-migrated";

    if (isSMMTCA) payload.user_guard_name = "smmt-dealer-user";
    else if (isKACA) payload.user_guard_name = "ka-dealer-user";
    else if (isRDCA) payload.user_guard_name = "rd-dealer-user";
    else if (isSKACA) payload.user_guard_name = "ska-dealer-user";
    else if (isPDCA) payload.user_guard_name = "dealer-user";
    else if (isB2BCA) payload.user_guard_name = "b2b-dealer-user";
    else if (isSUNM) payload.role_guard_name = "non-migrated-retailer-user";
    else if (isCANM) {
      payload.user_guard_name = "non-migrated-dealer-user";
      payload.role_guard_name = "non-migrated-retailer-user";
    } else if (isBANM) {
      payload.user_guard_name = "non-migrated-retailer-user";
      payload.role_guard_name = "non-migrated-retailer-user";
    }

    getBranch();
    getShift();
    getRoles(removeEmpty(payload));
  }, [getBranch, getShift, getRoles, userRoles]);

  return (
    <form onSubmit={handleSubmit} className="container-fluid">
      <AccountInfoForm
        onChange={handleChange}
        formData={formData}
        roleList={roleList}
        type="create"
      />
      <TemporaryAccountForm
        onChange={handleTemporaryFormChange}
        temporaryForm={temporaryForm}
        type="create"
      />
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold mr-2"
            >
              Cancel
            </button>
            <ButtonAsync
              type="submit"
              loading={useLoading(c.CREATE_USERS)}
              disabled={checkInput}
            >
              Save
            </ButtonAsync>
          </div>
        </div>
      </div>
    </form>
  );
}

FillupUserInfo.propTypes = {
  createUsers: PropTypes.func.isRequired,
  getRetailerUsers: PropTypes.func.isRequired,
  getBranch: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  getShift: PropTypes.func.isRequired,
  roleList: PropTypes.instanceOf(Array).isRequired,
  userRoles: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api }) => ({
  roleList: _.get(api, `${c.GET_ROLES}.list`) || [],
  userRoles: _.get(api, "AUTH/user_profile.item.roles") || [],
});

const enhance = _.flowRight([
  withModal("fill-up-user-info", {
    title: "Create Account",
    size: "modal-lg",
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(FillupUserInfo);
