/* eslint-disable */
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { ModalContext } from 'App';
import withModal from 'modules/common/hoc/withModal';
import { ToastSuccess } from 'modules/common/components/Toast';
import ButtonAsync from 'components/Button';

import * as actions from '../actions';

function EnterPassword() {
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);
  const [formData, setFormData] = useState({ password: '' });
  const [view, setView] = useState(false);
  const [pending, setPending] = useState(false);

  const modalDataItems = useSelector(
    ({ modal }) => _.get(modal, 'modalData.modalData.item', {}) || {}
  );

  const closeModal = () => setModal({ isOpen: false });
  const handleOnViewPassword = value => setView(value);

  const handleOnChange = ({ target }) =>
    setFormData({
      ...formData,
      [target?.name]: target?.value,
    });

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);
    const retailerId = modalDataItems?.item?.id;

    const deleteSuccessCallback = () => {
      ToastSuccess('Successfully Deleted Retailer');
      modalDataItems?.fetchRetailers();
      closeModal();
      setPending(false);
    };

    const deleteErrCallback = () => closeModal();

    const passwordSuccessCallback = () => {
      dispatch(
        actions.DeleteRetailer(
          retailerId,
          deleteSuccessCallback,
          deleteErrCallback
        )
      );
    };

    const passwordErrCallback = () => closeModal();

    dispatch(
      actions.validatePassword(
        formData,
        passwordSuccessCallback,
        passwordErrCallback
      )
    );
  };

  return (
    <form className="container-fluid py-2" onSubmit={handleSubmit}>
      <div>
        <span className="font-weight-bolder my-2">IMPORTANT!</span>
      </div>
      <div>
        <p className="mb-4">Enter your password to authorize action</p>
      </div>
      <div className="form-group">
        <div className="prepend-form-right">
          <label className="form-label mb-2">Password *</label>
          <div className="prepend-input-group">
            <input
              type={view ? 'text' : 'password'}
              className="form-control form-custom form-control_bottom"
              required
              autoFocus
              name="password"
              value={_.get(formData, 'password') || ''}
              onChange={handleOnChange}
            />
          </div>
          <button
            type="button"
            onClick={() => handleOnViewPassword(!view)}
            className="btn btn-default rounded-circle p-0 mr-1"
          >
            <i className={`fas ${!view ? 'fa-eye' : 'fa-eye-slash'}`} />
          </button>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <button
              type="button"
              onClick={closeModal}
              disabled={pending}
              className="btn btn-danger font-weight-bold mr-2"
            >
              CANCEL
            </button>

            <ButtonAsync type="submit" disabled={pending} loading={pending}>
              PROCEED
            </ButtonAsync>
          </div>
        </div>
      </div>
    </form>
  );
}

const modalKey = 'partners-retailer-enter-password';
const modalConfig = { title: 'Password Confirmation', size: 'modal-md' };

export default withModal(modalKey, modalConfig)(EnterPassword);
