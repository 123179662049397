export const GET_DEALER_LIST = "DEALER/GET_DEALER_LIST";
export const CREATE_DEALER = "DEALER/CREATE_DEALER";
export const UPDATE_DEALER = "DEALER/UPDATE_DEALER";
export const DELETE_DEALER = "DEALER/DELETE_DEALER";
export const SHOW_DEALER = "DEALER/SHOW_DEALER";

export const SHOW_USER_ACCESS_DETAILS = "DEALER/SHOW_USER_ACCESS_DETAILS";
export const SHOW_COMPANY_INFORMATION = "DEALER/SHOW_COMPANY_INFORMATION";
export const SHOW_COMPANY_ADDRESS = "DEALER/SHOW_COMPANY_ADDRESS";
export const SHOW_DELIVERY_ADDRESS_INFORMATION =
  "DEALER/SHOW_DELIVERY_ADDRESS_INFORMATION";
export const SHOW_MOA_SIGNATORY_INFORMATION =
  "DEALER/SHOW_MOA_SIGNATORY_INFORMATION";
export const SHOW_ACCOUNT_TYPE = "SHOW_ACCOUNT_TYPE";

export const GET_BDL = "DEALER/GET_BDL";
export const GET_ACCOUNT_TYPE = "DEALER/GET_ACCOUNT_TYPE";
export const GET_CHANNEL_LIST = "DEALER/GET_CHANNEL_LIST";
export const GET_PROVINCE_LIST = "DEALER/GET_PROVINCE_LIST";
export const GET_CITY_LIST = "DEALER/GET_CITY_LIST";
export const GET_BARANGAY_LIST = "DEALER/GET_BARANGAY_LIST";
export const GET_REQUIRED_DOCUMENTS_IMAGES_LIST =
  "DEALER/GET_REQUIRED_DOCUMENTS_IMAGES";
export const GET_REQUIRED_DOCUMENTS_LIST = "DEALER/GET_REQUIRED_DOCUMENTS";
export const GET_LOCATION = "DEALER/GET_LOCATION";

export const CREATE_DEALER_COMPANY = "DEALER/CREATE_DEALER_COMPANY";
export const CREATE_MOA_SIGNATORIES = "DEALER/CREATE_MOA_SIGNATORIES";
export const CREATE_DELIVERY_ADDRESS = "DEALER/CREATE_DELIVERY_ADDRESS";
export const CREATE_APPLICATIONS = "DEALER/CREATE_APPLICATIONS";
export const CREATE_SUPPORTING_DOCUMENTS =
  "DEALER/ CREATE_SUPPORTING_DOCUMENTS";
export const ADD_REQUIRED_DOCUMENTS_IMAGE =
  "DEALER/ADD_REQUIRED_DOCUMENTS_IMAGE";

export const ADD_OTHER_REQUIREMENTS_CALL_ONE =
  "DEALER/ADD_OTHER_REQUIREMENTS_CALL_ONE";
export const ADD_OTHER_REQUIREMENTS_CALL_TWO =
  "DEALER/ADD_OTHER_REQUIREMENTS_CALL_TWO";
export const ADD_APPLICATION = "DEALER/ADD_APPLICATION";

export const UPDATE_DEALER_COMPANY = "DEALER/UPDATE_DEALER_COMPANY";
export const UPDATE_DELIVERY_ADDRESS = "DEALER/UPDATE_DELIVERY_ADDRESS";
export const UPDATE_MOA_SIGNATORIES = "DEALER/UPDATE_MOA_SIGNATORIES";
export const UPDATE_REQUIRED_DOCUMENTS_IMAGE =
  "DEALER/UPDATE_REQUIRED_DOCUMENTS_IMAGE";
export const UPDATE_APPLICATION = "DEALER/UPDATE_APPLICATION";

export const compoundSearchDefaultValue = {
  order_by: "created_at",
  sort_by: "desc",
  per_page: 10,
  q: "",
  has_application: true,
  account_status: "on-boarded",
  application_status: "",
  account_type_id: "",
};

export const statusOptions = [
  { value: "done", label: "ON-BOARDED" },
  { value: "for-renewal", label: "FOR RENEWAL" },
];

export const labelStyles = { fontSize: ".6rem" };
export const moaLabelStyles = { fontSize: ".6rem" };
export const inputStyles = { fontSize: ".7rem", color: "rgb(1, 1, 1)" };

export const tabsInfo = [
  {
    slug: "company-information",
    label: "Company Information",
    account_status: "all",
  },
  {
    slug: "account-information",
    label: "Account Information",
    account_status: "on-boarded",
  },
  {
    slug: "supporting-documents",
    label: "Supporting Documents",
    account_status: "all",
  },
];

export const addUpdateDealerDefaultValue = {
  email: "",
  first_name: "",
  last_name: "",
  mobile: "",
  updated_by: "",
  profile_img: "",
};

export const companyInfoDropdownOptions = [
  { value: 1, label: "1%" },
  { value: 2, label: "2%" },
];

export const companyInfoCreateUpdateModalDefaultValue = {
  is_active: false,
  land_line: "",
  company_name: "",
  email: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  tin_number: "",
  withholding_agent: "",

  unit_building_house_no: "",
  street_name: "",
  barangay: "",
  municipality: "",
  province: "",
  postal_no: "",
};

export const companyAddressCreateUpdateModalDefaultValue = {
  unit_building_house_no: "",
  street_name: "",
  barangay: "",
  municipality: "",
  province: "",
  postal_no: "",
  isSame: false,
};

export const signatoryDefaultValue = {
  first_name: "",
  middle_name: "",
  last_name: "",
  contact_number: "",
  type: "MEMORANDUM_SIGNATORY",
};

export const otherSignatoriesDefaultValue = [];

export const category_uuid = {
  dealer: "285628ee-8e04-11eb-8dcd-0242ac130003",
  sub_dealer: "285627ee-8e04-11eb-8dcd-0242ac130003",
  smmt: "28562e74-8e04-11eb-8dcd-0242ac130003",
};

export const form = [
  {
    type: "select-static",
    name: "application_status",
    label: "Status:",
    options: statusOptions,
    isClearable: true,
  },
];
