import React, { useCallback, useState, useEffect } from 'react';

import PropTypes from 'prop-types';

const Input = ({
  defaultValue,
  disabled,
  loading,
  prefix,
  maxLength,
  name,
  onBlur,
  onValueChange,
  ...rest
}) => {
  const [number, setNumber] = useState(defaultValue || prefix);

  const handleChange = useCallback(
    event => {
      const { value } = event.target;
      const stripped = value.match(/^(?:\+?\d+|\+)/g);
      const masked =
        !stripped || !stripped[0].startsWith(prefix) ? prefix : stripped[0];
      setNumber(masked);
    },
    [setNumber, onValueChange, prefix]
  );

  const handleBlur = e => {
    if (!e.target.value) {
      setNumber(prefix);
    }
    onBlur();
  };

  useEffect(() => {
    if (!onValueChange || typeof onValueChange !== 'function') return;
    onValueChange(number);
  }, [number]);

  const maxLengthWithPrefix = maxLength + prefix.length;

  return (
    <input
      type="text"
      className="form-control border-0 border-bottom rounded-0 px-2 py-0 shadow-none"
      maxLength={maxLengthWithPrefix}
      onBlur={handleBlur}
      value={number}
      name={name}
      {...rest}
      disabled={disabled || loading}
      onChange={handleChange}
    />
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  prefix: PropTypes.string,
  maxLength: PropTypes.number,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
};

Input.defaultProps = {
  maxLength: 10,
  prefix: '',
  disabled: false,
  loading: false,
  onBlur: () => {},
  defaultValue: '',
};

export default Input;
