import React, { useContext } from "react";
import _ from "lodash";
import moment from "moment";
import withModal from "modules/common/hoc/withModal";
import { ModalContext } from "App";
import { useSelector } from "react-redux";

function ViewDetails() {
  const setModal = useContext(ModalContext);
  const data = useSelector(({ modal }) => _.get(modal, "modalData"));

  const type = _.get(data, "type");

  const attrib = _.get(data, "attributes");

  let transformed = [
    {
      label: "Source SIM Type:",
      value: _.get(attrib, "source_sim_type"),
    },
    {
      label: "Target Mobile:",
      value:
        _.get(attrib, "target_ret_account") ||
        _.get(attrib, "target_dealer_account") ||
        _.get(attrib, "target_sub_account"),
    },
    {
      label: "Target SIM Type:",
      value: _.get(attrib, "target_sim_type"),
    },
    {
      label: "Amount Transferred:",
      value: `PHP ${_.get(attrib, "amount")}`,
    },
    {
      label: "Response Code:",
      value: _.get(attrib, "response_code"),
    },
    {
      label: "Offer:",
      value: _.get(attrib, "offer_name") || " ",
    },
    {
      label: "Response Code Description:",
      value: _.get(attrib, "response_desc") || " ",
    },
    {
      label: "Reference No.:",
      value: _.get(attrib, "transaction_rrn"),
    },
    {
      label: "Client RRN:",
      value: _.get(attrib, "request_reference_no"),
    },
    {
      label: "Status:",
      value: (
        <span
          style={{
            fontSize: "0.75rem",
          }}
          className={`badge font- ${
            _.get(attrib, "status") === "SUCCESSFUL"
              ? "badge-success"
              : "badge-danger"
          }`}
        >
          {_.get(attrib, "status") || "--"}
        </span>
      ),
    },
  ];
  const remove = ["Offer:"];
  if (type === "D2R") {
    transformed = transformed.filter((i) => !remove.includes(i.label));
  }

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const renderRow = (item) => {
    return (
      <tr key={item.label}>
        <td
          className="text-left text-muted"
          style={{ width: 400, paddingLeft: 10 }}
        >
          {item.label}
        </td>
        <td className="text-left text-muted font-weight-bold">{item.value}</td>
      </tr>
    );
  };

  return (
    <div>
      <div className="row px-4">
        <div className="col-2 px-0">
          <div className="text-muted">Date:</div>
        </div>
        <div className="col-4 px-0">
          <div className="text-muted font-weight-bold">
            {moment(_.get(attrib, "created_at")).format("lll")}
          </div>
        </div>
        <div className="col-2 px-0">
          <div className="text-muted">Branch Name:</div>
        </div>
        <div className="col-3 px-0">
          <div className="text-muted font-weight-bold">
            {_.get(attrib, "branch_name")}
          </div>
        </div>
      </div>
      <div className="row px-4 mt-2">
        <div className="col-2 px-0">
          <div className="text-muted">Source Fund:</div>
        </div>
        <div className="col-4 px-0">
          <div className="text-muted font-weight-bold">
            {_.get(attrib, "source")}
          </div>
        </div>
        <div className="col-2 px-0">
          <div className="text-muted">Branch ID:</div>
        </div>
        <div className="col-3 px-0">
          <div className="text-muted font-weight-bold">
            {_.get(attrib, "branch_id")}
          </div>
        </div>
      </div>
      <div className="flex-grow-1 mt-3">
        <div className="row">
          <div className="col-12">
            <table className="table table-striped table-sm table-borderless">
              <tbody>{transformed.map((item) => renderRow(item))}</tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <button
          type="button"
          onClick={closeModal}
          className="mt-3 btn btn-modal-yes ml-auto mr-2"
          style={{ borderRadius: 8 }}
        >
          <span
            className="font-weight-bold"
            style={{ color: "#FFFFFF", fontSize: 14 }}
          >
            CLOSE
          </span>
        </button>
      </div>
    </div>
  );
}

const enhance = _.flowRight([
  withModal("view-report-details", {
    title: "View Details",
    size: "modal-lg",
  }),
]);

export default enhance(ViewDetails);
