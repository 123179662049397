import { createStore, applyMiddleware, compose } from 'redux';
import epics from './epics';
import reducers from './reducers';
import middlewares, { epicMiddleware } from './middlewares';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middlewares))
);

epicMiddleware.run(epics);

export default store;
