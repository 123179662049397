import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import RetailerBalance from 'modules/widgets/wallet-balance/retailer';
import DealerBalance from 'modules/widgets/wallet-balance/dealer';
import ShiftSchedule from 'modules/widgets/shift-schedule';
import TransactionReport from 'modules/widgets/transaction-report';
import TopRetailsBrand from 'modules/widgets/top-retails-brand';
import TopRetailsArea from 'modules/widgets/top-retails-area';
import TopFrontliners from 'modules/widgets/top-frontliners';
import Sales from 'modules/widgets/sales-amount';
import { GET_DEALER_DASHBOARD_TOPS } from 'app/dashboard/constant';
import { useLoading } from 'modules/ui/hooks';

const BranchDash = () => {
  const loading = useLoading(GET_DEALER_DASHBOARD_TOPS);
  const all = useSelector(({ api }) =>
    _.get(api, `${GET_DEALER_DASHBOARD_TOPS}.res`)
  );
  const data = {
    brands: _.get(all, 'brands.data', []),
    area: _.get(all, 'branches.data', []),
    frontliners: _.get(all, 'front-liners.data', []),
    total_sales: _.get(all, 'total-sales', ''),
    average_sales: _.get(all, 'average-sales', ''),
  };
  const sales = [
    {
      label: 'Total Sales Amount',
      data: _.get(data, 'total_sales'),
    },
    {
      label: 'Average Sales Amount',
      data: _.get(data, 'average_sales'),
    },
  ];
  return (
    <div className="main-content-inner space">
      <div className="box-fit-wrapper h-100">
        <div className="box-fix-overflow">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-12">
                <div className="dashboard-wrapper-ba">
                  <div className="wallet-balance">
                    <ShiftSchedule />
                    <div className="my-4">
                      <RetailerBalance />
                    </div>
                    <DealerBalance />
                  </div>
                  <div className="sales">
                    <div className="row">
                      {sales.map(item => (
                        <Sales key={item.label} isLoading={loading} {...item} />
                      ))}
                    </div>
                    <TopRetailsBrand data={data.brands} isLoading={loading} />
                  </div>
                  <div className="retail-area">
                    <TopRetailsArea data={data.area} isLoading={loading} />
                  </div>
                  <div className="daily-transactions">
                    <TransactionReport />
                  </div>
                  <div className="top-frontliner">
                    <TopFrontliners
                      data={data.frontliners}
                      isLoading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchDash;
