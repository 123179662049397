import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import CloseButton from './CloseButton';
import ToastTemplate from './ToastTemplate';

export default () => <ToastContainer limit={1} closeButton={<CloseButton />} />;

export const ToastDefault = (content, config) => {
  toast(<ToastTemplate content={content} />, {
    className: 'alert alert-primary',
    hideProgressBar: true,
    ...config,
  });
};

export const ToastSuccess = (content, config) => {
  toast.success(<ToastTemplate icon="check-circle" content={content} />, {
    className: 'alert alert-success',
    ...config,
  });
};

export const ToastError = (content, config) => {
  toast.error(<ToastTemplate icon="exclamation-circle" content={content} />, {
    className: 'alert alert-danger',
    ...config,
  });
};

export const ToastWarn = (content, config) => {
  toast.warn(<ToastTemplate icon="exclamation-triangle" content={content} />, {
    className: 'alert alert-warning',
    ...config,
  });
};

export const ToastInfo = (content, config) => {
  toast.info(<ToastTemplate icon="info-circle" content={content} />, {
    className: 'alert alert-info',
    ...config,
  });
};
