import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';

function FormInpuSelect({
  name,
  onChange,
  value,
  label,
  containerClassName,
  labelClassName,
  withLabel,
  required,
  options,
  onGetValue,
  ...props
}) {
  const selectRef = React.useRef(null);

  const handleChange = e => {
    onChange(state => ({ ...state, [name]: e }));
    if (onGetValue) {
      onGetValue(e);
    }
  };

  const handleOnFocus = () => {
    selectRef.current.focus();
  };

  const isMultiValue = () => {
    if (value.length > 0 && _.get(props, 'isMulti')) {
      return value.map(item => _.get(item, 'label', '')).join(' ');
    }
    return '';
  };
  return (
    <div className={containerClassName}>
      {withLabel && (
        <label htmlFor={name} className={labelClassName}>
          {label}
          {required ? <span className="text-danger"> *</span> : null}
        </label>
      )}
      <Select
        id={name}
        placeholder="Select..."
        ref={selectRef}
        classNamePrefix="react-select"
        className="react-select-container"
        options={options}
        onChange={handleChange}
        value={value}
        {...props}
      />
      {required && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            width: '100%',
            height: 0,
            position: 'absolute',
            zIndex: -1,
          }}
          value={
            _.get(props, 'isMulti')
              ? isMultiValue()
              : `${_.get(value, 'value', '')}` || ''
          }
          onChange={() => {}}
          onFocus={handleOnFocus}
          required={required}
        />
      )}
    </div>
  );
}

FormInpuSelect.defaultProps = {
  onChange: () => {},
  label: 'Name',
  containerClassName: 'form-group',
  labelClassName: 'form-label',
  withLabel: true,
  required: false,
  options: [],
  onGetValue: false,
};

FormInpuSelect.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.instanceOf(Function),
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Object),
  ]).isRequired,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  withLabel: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.instanceOf(Array),
  onGetValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Function),
  ]),
};

export default React.memo(FormInpuSelect);
