import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { join } from "helper";
import { useDispatch, useSelector } from "react-redux";
import { USER_PROFILE } from "modules/auth/constants";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { getRetailerSims, getDealerSims } from "../../action";
import * as c from "../../constant";

const AssignSIM = ({ assignedSims, onChange }) => {
  const dispatch = useDispatch();
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const retailerSims = useSelector(
    ({ api }) => _.get(api, `${c.GET_RETAILERS_SIMS}.list`) || []
  );
  const dealerSims = useSelector(
    ({ api }) => _.get(api, `${c.GET_DEALER_SIMS}.list`) || []
  );

  const allSims = useMemo(() => [...retailerSims, ...dealerSims], [
    retailerSims,
    dealerSims,
  ]);

  const [sources, setSources] = useState();

  const filterSims = useCallback(
    (selection, defaultId) =>
      allSims
        .filter((sim) => selection.includes(sim.id))
        .map((sim) => ({
          sim_id: sim?.id,
          type: sim?.type,
          sim_type: sim?.attributes?.sim_type || sim?.attributes?.type || "",
          mobile_identification_number:
            sim?.attributes?.mobile_identification_number,
          is_default: sim?.id === defaultId,
        })),
    [allSims]
  );

  const handleChange = (key) => (selection, defaultId) => {
    const typeSources = filterSims(selection, defaultId);
    setSources((prev) => ({ ...prev, [key]: typeSources }));
  };

  useEffect(() => {
    if (!sources) return;
    onChange(Object.values(sources).flat());
  }, [sources]);

  useEffect(() => {
    dispatch(
      getDealerSims({
        per_page: 1000,
        is_active: true,
        userType: "dealers",
        dealer_id: _.get(profile, "id") || _.get(profile, "parent_id"),
      })
    );
    dispatch(
      getRetailerSims({
        per_page: 1000,
        is_active: true,
        dealer_id: _.get(profile, "id") || _.get(profile, "parent_id"),
      })
    );
  }, []);

  const dsims = useMemo(
    () => allSims.filter((sim) => sim.type === "dealer_sims"),
    [allSims]
  );

  const rsims = useMemo(
    () => allSims.filter((sim) => sim.type === "dealer_retailer_sims"),
    [allSims]
  );

  const assigned_dsims = useMemo(
    () =>
      assignedSims.filter(
        (sim) => _.get(sim, "attributes.type") === "dealer_sims"
      ),
    [assignedSims]
  );

  const assigned_rsims = useMemo(
    () =>
      assignedSims.filter(
        (sim) => _.get(sim, "attributes.type") === "dealer_retailer_sims"
      ),
    [assignedSims]
  );

  return (
    <div className="row pr-3 mt-3">
      <FieldSet
        title="Assign Dealer Mins to Branch"
        onChange={handleChange("dealer_sims")}
        sims={dsims}
        assigned={assigned_dsims}
      />
      <FieldSet
        title="Assign Retailer Mins to Branch"
        onChange={handleChange("dealer_retailer_sims")}
        sims={rsims}
        assigned={assigned_rsims}
      />
    </div>
  );
};

AssignSIM.defaultProps = {
  assignedSims: [],
};

AssignSIM.propTypes = {
  assignedSims: PropTypes.instanceOf(Array),
  onChange: PropTypes.func.isRequired,
};

export default AssignSIM;

const FieldSet = ({ sims, assigned, title, onChange }) => {
  const maxHeight = 200;

  const minListRef = useRef();
  const summaryRef = useRef();
  const defaultSim = assigned.find((sim) =>
    _.get(sim, "attributes.is_default")
  );
  const [selection, setSelection] = useState(() =>
    assigned.map((sim) => _.get(sim, "attributes.sim_id"))
  );
  const [defaultId, setDefaultId] = useState(() =>
    _.get(defaultSim, "attributes.sim_id")
  );

  const handleChange = (event) => {
    const { type, value } = event.target;

    if (type === "radio") {
      setDefaultId(value);
    }

    if (type === "checkbox") {
      if (selection.includes(value)) {
        setSelection(selection.filter((id) => id !== value));
        return;
      }

      setSelection([...selection, value]);
      // add value if no default was set
      if (selection.includes(defaultId)) return;
      setDefaultId(value);
    }
  };

  useMemo(() => {
    if (!minListRef.current) return null;
    return new PerfectScrollbar(minListRef.current);
  }, [minListRef.current]);

  useMemo(() => {
    if (!summaryRef.current) return null;
    return new PerfectScrollbar(summaryRef.current);
  }, [summaryRef.current]);

  useEffect(() => {
    // if default will be removed, delegate to first in line
    if (selection.includes(defaultId)) return;
    setDefaultId(selection[0]);
  }, [selection]);

  useEffect(() => {
    if (!sims) return;
    onChange(selection, defaultId);
  }, [selection, defaultId, sims]);

  if (!sims) return null;

  return (
    <div className="col-xl-6 pr-0">
      <div className="bg-light p-3 h-100">
        <h3 className="h6 mt-0">{title}</h3>
        <div className="row pr-3">
          <div className="col-sm-8 d-flex flex-column">
            <div className="d-flex bg-gray-400 text-white px-3 py-2 justify-content-between rounded-top">
              <div>Available MINs</div>
              <div>Set as default</div>
            </div>
            <div
              className="bg-white p-3 overflow-auto flex-grow-1 position-relative"
              style={{ height: maxHeight }}
              ref={minListRef}
            >
              <div className="">
                {sims.map((sim) => {
                  return (
                    <MinItem
                      key={sim.id}
                      id={sim.id}
                      value={_.get(
                        sim,
                        "attributes.mobile_identification_number"
                      )}
                      type={
                        _.get(sim, "attributes.type") ||
                        _.get(sim, "attributes.sim_type") ||
                        ""
                      }
                      isSelected={selection.some((id) => id === sim.id)}
                      isDefault={sim.id === defaultId}
                      onChange={handleChange}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-sm-4 p-0 d-flex flex-column">
            <div className="d-flex bg-gray-400 text-white px-3 py-2 justify-content-between rounded-top">
              <div>Summary</div>
            </div>
            <div
              className="bg-white p-3 flex-grow-1 overflow-auto position-relative"
              ref={summaryRef}
              style={{ height: maxHeight }}
            >
              <h6 className="h6 mt-0 text-primary">Default MIN</h6>
              <div>
                <div>
                  {_.get(
                    sims.find((sim) => sim.id === defaultId),
                    "attributes.mobile_identification_number"
                  )}
                </div>
              </div>
              <h6 className="h6 mt-3 text-primary">Secondary MIN</h6>
              <div className="overflow-auto flex-grow-1">
                {sims
                  .filter(
                    (sim) => selection.includes(sim.id) && sim.id !== defaultId
                  )
                  .map((sim) => (
                    <div key={sim.id}>
                      {_.get(sim, "attributes.mobile_identification_number")}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FieldSet.propTypes = {
  sims: PropTypes.instanceOf(Array).isRequired,
  assigned: PropTypes.instanceOf(Array).isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const MinItem = ({ id, value, type, isDefault, isSelected, onChange }) => {
  const hasType = type ? `(${type})` : "";
  return (
    <div className="d-flex align-items-center" style={{ height: "2.2em" }}>
      <div className="form-check">
        <input
          id={`toggle_${id.slice(-6)}`}
          type="checkbox"
          className="form-check-input"
          checked={isSelected || false}
          value={id || ""}
          onChange={onChange}
        />
        <label
          htmlFor={`toggle_${id.slice(-6)}`}
          className="form-check-label text-xs"
        >
          {value} {hasType}
        </label>
      </div>
      {isSelected ? (
        <div className="form-check ml-auto">
          <input
            disabled={isDefault}
            className="d-none"
            id={`default_${id.slice(-6)}`}
            name={`default_${type}`}
            type="radio"
            checked={isDefault}
            onChange={onChange}
            value={id || ""}
          />
          <label
            htmlFor={`default_${id.slice(-6)}`}
            className={join`rounded-pill px-2 m-0 ${
              isDefault ? `bg-primary` : `btn-anchor btn-link`
            }`}
          >
            {isDefault ? (
              <small className="text-white text-uppercase cursor-pointer text-xs">
                Default
              </small>
            ) : (
              <small
                className="text-uppercase font-weight-bold text-xs"
                style={{ cursor: "pointer" }}
              >
                Set as default
              </small>
            )}
          </label>
        </div>
      ) : null}
    </div>
  );
};

MinItem.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
