import React, { useEffect } from 'react';
import { withRouter, NavLink, Switch, Route } from 'react-router-dom';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { checkPermissions } from 'helper';

import logo from 'assets/images/dealers-portal-logo-sm.png';
import IconDashboard from 'assets/images/icon/icon-dashboard.png';
import IconLoad from 'assets/images/icon/icon-load.png';
import IconProducts from 'assets/images/icon/icon-product.png';
import { USER_PROFILE } from 'modules/auth/constants';

import * as actions from './actions';
import * as c from './constants';

import './styles/Sidebar.style.scss';

const mainModule = [
  {
    is_submenu: false,
    id: 'dashboard',
    name: 'Dashboard',
    to: '/dashboard',
    icon: IconDashboard,
    permission: ['can-view-balance', 'can-view-transaction'],
    subModules: [],
  },
  {
    id: 'load-wallet',
    name: 'Load Wallet',
    to: '/load-wallet',
    icon: IconLoad,
    permission: ['can-view-dealer-sim', 'can-view-retailer-sim'],
    subModules: [
      {
        id: 'retailer-sims',
        name: 'Retailer Sims',
        path: 'retailer-sims',
        permissions: ['can-view-retailer-sim'],
      },
      {
        id: 'dealer-sims',
        name: 'My SIMs',
        path: 'dealer-sims',
        permission: ['can-view-dealer-sim'],
      },
      {
        id: 'my-sims',
        name: 'My SIMs',
        path: 'my-sims',
        permissions: ['can-view-dealer-sim'],
      },
      {
        id: 'sub-dealer-sims',
        name: 'Sub-Dealer Sims',
        path: 'sub-dealer-sims',
        permissions: ['can-view-dealer-sim'],
      },
    ],
  },
  {
    id: 'sales-order',
    name: 'Sales Order',
    to: '/sales-order',
    icon: IconLoad,
    permission: [
      'can-view-providers',
      'can-view-dealer-sim-transaction-internal',
    ],
    subModules: [
      {
        id: 'load-subscriber',
        name: 'Load Subscriber',
        path: 'load-subscriber',
        permission: ['can-view-providers'],
      },
      {
        id: 'load-retailer',
        name: 'Load Retailer',
        path: 'load-retailer',
        permission: ['can-view-dealer-sim-transaction-internal'],
      },
      {
        id: 'items',
        name: 'Items',
        path: 'items',
        permission: ['can-access-sales-order-items'],
      },
      {
        id: 'bills-payment',
        name: 'Bills Payment',
        path: 'bills-payment',
        permissions: ['can-view-bills-transaction'],
      },
      {
        id: 'wallet-loading',
        name: 'Wallet Loading',
        path: 'wallet-loading',
        permission: ['can-access-sales-order-wallet-loading'],
      },
    ],
  },
  {
    id: 'manage',
    name: 'Manage',
    to: '/manage',
    icon: IconDashboard,
    permission: [
      'can-view-branch',
      'can-view-schedule',
      'can-view-retailer-user',
      'can-view-dealer-user',
    ],
    subModules: [
      {
        id: 'branch',
        name: 'Branch Operation',
        path: 'branch',
        permission: ['can-view-branch'],
      },
      {
        id: 'shift-schedule',
        name: 'Selling Window',
        path: 'shift-schedule',
        permission: ['can-view-schedule'],
      },
      {
        id: 'user-access',
        name: 'User Access',
        path: 'user-access',
        permission: ['can-view-retailer-user', 'can-view-dealer-user'],
      },
    ],
  },
  {
    id: 'notebook',
    name: 'Notebook',
    to: '/notebook',
    icon: IconProducts,
    permission: [
      'can-view-transaction',
      'can-view-sales-profit',
      'can-view-bills-transaction',
      'can-view-dealer-sim-transaction',
      'can-view-dealer-sim',
    ],
    subModules: [
      {
        id: 'load-subscriber-history',
        name: 'Load Subscriber History',
        path: 'load-subscriber-history',
        permission: ['can-view-transaction'],
      },
      {
        id: 'load-retailer-history',
        name: 'Load Retailer History',
        path: 'load-retailer-history',
        permission: ['can-view-dealer-sim-transaction', 'can-view-dealer-sim'],
      },
      {
        id: 'bill-payment-history',
        name: 'Bills Payment History',
        path: 'bill-payment-history',
        permission: ['can-view-bills-transaction'],
      },
      {
        id: 'benta-kita',
        name: 'Benta at Kita',
        path: 'benta-kita',
        permission: ['can-view-sales-profit'],
      },
    ],
  },
  {
    is_submenu: false,
    id: 'inventory',
    name: 'Inventory',
    to: '/inventory',
    icon: IconProducts,
    permission: ['can-access-inventory'],
    subModules: [],
  },
  {
    is_submenu: false,
    id: 'shift-schedule',
    name: 'Shift Schedule',
    to: '/manage/shift-schedule',
    icon: IconProducts,
    permission: ['can-access-shift-schedule'],
    subModules: [],
  },
  {
    is_submenu: false,
    id: 'profile-setting',
    name: 'Profile & Settings',
    to: '/profile-setting',
    icon: IconDashboard,
    permission: [
      'can-update-password',
      'can-update-pin',
      'can-update-retailer-profile',
      'can-update-retailer-profile',
    ],
    subModules: [],
  },
  {
    id: 'help-support',
    name: 'Help & Support',
    to: '/help-support',
    icon: IconProducts,
    permission: ['can-create-contact-us'],
    subModules: [
      {
        id: 'contact',
        name: 'Contact Us',
        path: 'contact',
        permission: ['can-create-contact-us'],
      },
    ],
  },
];

const RetailerSideBar = () => {
  const dispatch = useDispatch();
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));

  const companyInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_USER_COMPANY_INFO}.item`, {})
  );

  useEffect(() => {
    if (!profile.parent_id) return;
    dispatch(actions.showUserCompanyInfo(profile?.parent_id));
  }, [profile, dispatch]);

  return (
    <aside className="main-sidebar sidebar__container">
      <header className="mt-5 mb-3">
        <img src={logo} alt="PLTD / SMART Retailer's Portal" />
        <h4 className="h6 text-sm mt-3 ">
          {companyInfo?.attributes?.company_name}
        </h4>
      </header>
      <ul className="pl-0">
        {mainModule.map(
          modules =>
            checkPermissions(_.get(modules, 'permission') || []) && (
              <li
                className="menu-item text-left sidebar__list-item"
                key={modules.id}
              >
                <NavLink
                  className="btn btn-menu-link menu-link"
                  to={`${modules.to}`}
                  aria-haspopup="true"
                >
                  <img src={modules.icon} alt="Load" className="icon-image" />
                  <span className="name">{_.get(modules, 'name')}</span>
                  {modules.is_submenu !== false && (
                    <Switch>
                      <Route
                        path={`${modules.to}`}
                        render={() => <i className="fas fa-chevron-up" />}
                      />
                      <Route
                        render={() => <i className="fas fa-chevron-down" />}
                      />
                    </Switch>
                  )}
                </NavLink>
                <Route
                  path={`${modules.to}`}
                  render={() => (
                    <div
                      className="menu-child active dropdown-menu-child"
                      aria-label="Load submenu"
                    >
                      <ul className="menu-list">
                        {modules.subModules.map(
                          sub =>
                            checkPermissions(
                              _.get(sub, 'permission') || []
                            ) && (
                              <li className="menu-item" key={sub.id}>
                                <NavLink
                                  to={`${modules.to}/${sub.path}`}
                                  className="menu-child-link"
                                  activeClassName="active"
                                >
                                  <span className="name">
                                    {_.get(sub, 'name')}
                                  </span>
                                </NavLink>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  )}
                />
              </li>
            )
        )}
      </ul>
    </aside>
  );
};

export default withRouter(RetailerSideBar);
