/* eslint-disable */
import React from 'react';
import _ from 'lodash';
import ReactInputMask from 'react-input-mask';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ToastError, ToastSuccess } from 'modules/common/components/Toast';
import { ModalContext } from 'App';
import { checkPermissions } from 'helper';

import SetPassword from './SetPassword';
import * as dashboardActions from '../../dashboard/actions';
import * as actions from '../actions';
import * as c from '../constant';

const form = {
  new_mpin: '',
  new_mpin_confirmation: '',
};
const regex = /^[0-9\b]+$/;

function ForgotSecurityPin() {
  const setModal = React.useContext(ModalContext);
  const [formData, setFormData] = React.useState(form);
  const [view, setView] = React.useState();

  const handleChangeInput = key => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value,
    });
  };

  const handleOnViewPin = (key, value) => e => {
    e.preventDefault();
    setView({
      [key]: value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!regex.test(formData.new_mpin)) {
      ToastError('Use only number for PIN!');
    } else {
      setModal('set-password', { payload: formData, type: 'mpin' });
      setFormData({
        new_mpin: '',
        new_mpin_confirmation: '',
      });
    }
  };

  return (
    <div className="container-fluid h-100">
      <div className="" style={{ maxWidth: '32rem' }}>
        <div className="">
          <h4 className="font-weight-bold mt-0 mb-2">Forgot Security PIN</h4>
          <p className="text-muted my-4">
            Please fill up the fields with required data to process Reset
            Security PIN request
          </p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="prepend-form-right">
                <label className="form-label text-primary">New PIN:</label>
                <ReactInputMask
                  type={_.get(view, 'new_mpin') ? 'text' : 'password'}
                  placeholder="New PIN"
                  mask="999999"
                  maskChar=""
                  className="form-control form-custom form-control_bottom"
                  name="old_mpin"
                  required
                  onChange={handleChangeInput('new_mpin')}
                  value={_.get(formData, 'new_mpin') || ''}
                />
                <button
                  type="button"
                  onClick={handleOnViewPin(
                    'new_mpin',
                    !_.get(view, 'new_mpin')
                  )}
                  className="btn btn-default rounded-circle p-0 mr-1"
                >
                  <i
                    className={`fas ${
                      !_.get(view, 'new_mpin') ? 'fa-eye' : 'fa-eye-slash'
                    }`}
                  />
                </button>
              </div>
            </div>
            <div className="form-group">
              <div className="prepend-form-right">
                <label className="form-label text-primary">
                  Re-type New PIN:
                </label>
                <ReactInputMask
                  type={
                    _.get(view, 'new_mpin_confirmation') ? 'text' : 'password'
                  }
                  placeholder="Retype New PIN"
                  mask="999999"
                  maskChar=""
                  className="form-control form-custom form-control_bottom"
                  name="new_mpin_confirmation"
                  required
                  onChange={handleChangeInput('new_mpin_confirmation')}
                  value={_.get(formData, 'new_mpin_confirmation') || ''}
                />
                <button
                  type="button"
                  onClick={handleOnViewPin(
                    'new_mpin_confirmation',
                    !_.get(view, 'new_mpin_confirmation')
                  )}
                  className="btn btn-default rounded-circle p-0 mr-1"
                >
                  <i
                    className={`fas ${
                      !_.get(view, 'new_mpin_confirmation')
                        ? 'fa-eye'
                        : 'fa-eye-slash'
                    }`}
                  />
                </button>
              </div>
            </div>
            <p className="text-muted">
              This will require your Password to complete the process
            </p>
            <div className="form-group">
              <button
                type="submit"
                className="uppercase btn btn-primary btn-block px-4"
                disabled={
                  formData.new_mpin.length === 0 ||
                  formData.new_mpin.length !== 6 ||
                  formData.new_mpin !== formData.new_mpin_confirmation
                }
              >
                RESET SECURITY PIN
              </button>
            </div>
          </form>
        </div>
      </div>
      <SetPassword />
    </div>
  );
}

ForgotSecurityPin.propTypes = {
  setOtp: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
};

const mapStateToProps = ({ api }) => ({
  retailerUser: _.get(api, `${c.SHOW_RETAILER_USERS}.item`) || {},
  profile: _.get(api, 'AUTH/user_profile.item') || {},
  isLoading: api.loading.indexOf(c.CHANGE_PASSWORD) > -1,
  profileType: _.get(api, 'AUTH/user_profile.item.profile_type') || '',
  roles: _.get(api, 'AUTH/user_profile.item.roles') || [],
});

const enhance = _.flowRight([
  withRouter,
  connect(mapStateToProps, {
    ...dashboardActions,
    ...actions,
  }),
]);

export default enhance(ForgotSecurityPin);
