import React, { useState, useContext, useEffect, useMemo } from 'react';
import withModal from 'modules/common/hoc/withModal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactInputMask from 'react-input-mask';
import { ModalContext } from 'App';
import ButtonAsync from 'components/Button';
import * as actions from '../actions';

function ChangeTopOrg({ dealerSims }) {
  const setModal = useContext(ModalContext);
  const [formData, setFormData] = useState({
    transfer_to: '',
    assigned_to: '',
  });
  const closeModal = () => {
    setModal({ isOpen: false });
  };

  const handleChangeInput = key => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value,
    });
  };

  const [pending, setPending] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    setPending(true);
    const payload = {
      ...formData,
      dealer_id: dealerSims?.attributes?.dealer_id,
      userType: 'dealers',
      transfer_assigned_to: formData.assigned_to,
    };

    setModal('verify-top-org', {
      newSimData: payload,
      oldSimData: dealerSims,
    });
  };

  const isDuplicateMin = useMemo(() => {
    _.isEqual(
      formData?.transfer_to,
      dealerSims?.attributes?.mobile_identification_number
    );
  }, [formData?.transfer_to, dealerSims]);

  const isMinLengthAccepted = useMemo(
    () => !_.isEqual(_.size(formData?.transfer_to), 13),
    [formData?.transfer_to]
  );
  const isMinEmpty = useMemo(() => _.isEmpty(formData?.transfer_to), [
    formData?.transfer_to,
  ]);

  const hasNewData = !_.isEmpty(dealerSims);

  useEffect(() => {
    setFormData({
      is_active: dealerSims?.attributes?.is_active,
      type: dealerSims?.attributes?.type,
      mobile_identification_number:
        dealerSims?.attributes?.mobile_identification_number,
    });
  }, [hasNewData, dealerSims]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 my-4">
          <span className="my-0 font-weight-bold d-block">Important!</span>
          <span className="text-left form-label">
            To process the change of top org, a new Dealer SIM is required.
          </span>
        </div>
        <div className="col-4">
          <label className="form-label font-weight-bold">SIM Type</label>
          <input
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={dealerSims?.attributes?.type || formData?.type}
            onChange={handleChangeInput('type')}
            readOnly
          />
        </div>
        <div className="col-4">
          <label className="form-label font-weight-bold">SIM Number</label>
          <ReactInputMask
            mask="+639999999999"
            maskChar=""
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={formData?.transfer_to}
            onChange={handleChangeInput('transfer_to')}
          />
        </div>
        <div className="col-4">
          <label className="form-label font-weight-bold">Assign To</label>
          <input
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={formData?.assigned_to}
            onChange={handleChangeInput('assigned_to')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-3">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <ButtonAsync
              type="submit"
              className="btn btn-primary position-relative text-uppercase mx-2"
              loading={pending}
              disabled={isDuplicateMin || isMinLengthAccepted || isMinEmpty}
            >
              Request
            </ButtonAsync>
            <button
              type="button"
              onClick={closeModal}
              className="text-uppercase btn bg-gray-500 text-white font-weight-bold mr-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

ChangeTopOrg.propTypes = {
  dealerSims: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ modal }) => ({
  dealerSims: _.get(modal, 'modalData.dealerSims') || {},
});

const enhance = _.flowRight([
  withModal('change-top-org', {
    title: 'Change Dealer SIM Wizard',
    size: 'modal-md',
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(ChangeTopOrg);
