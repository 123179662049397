import React, { useState, useEffect, useMemo, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import * as req from "api/actions";
import { removeEmpty } from "helper";
import { useLoading } from "modules/ui/hooks";
import { USER_PROFILE } from "modules/auth/constants";
import IconTooltip from "modules/common/components/IconTooltip";
import { ModalContext } from "App";
import { useDebounce } from "hooks";
import Actions from "components/Actions";
import Pagination from "modules/common/components/Pagination";
import Filter from "components/Filter";
import CardItem from "../../components/CardItem";
import AccountInformation from "../modal/AccountInformation";
import * as actions from "../actions";
import * as c from "../constants";

const buttonClass = `mt-0 btn btn-block btn-default p-0 rounded-0 text-right`;

const ApplicationsContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const setModal = useContext(ModalContext);
  const userProfile = useSelector(({ api }) =>
    _.get(api, "AUTH/user_profile.item")
  );
  const dealerData = useSelector(({ api }) =>
    _.get(api, `${c.GET_DEALER_BY_ID}.item.attributes`, {})
  );
  const applicationList = useSelector(({ api }) =>
    _.get(api, `${c.GET_APPLICATION_LIST}.list`, [])
  );

  const sortApplicationList = applicationList.sort(
    (a, b) =>
      b?.attributes?.application_updated_at -
      a?.attributes?.application_updated_at
  );

  const applicationListPager = useSelector(({ api }) =>
    _.get(api, `${c.GET_APPLICATION_LIST}.pager`, [])
  );
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const profile_id = profile?.id || profile?.parent_id;
  const [filterData, setFilterData] = useState(
    c.compoundSearchApplcationsDefaultValue
  );
  const searchTerm = useDebounce(filterData.q, 1000);

  const isApplicationListLoading = useLoading(c.GET_APPLICATION_LIST);
  const isGetDealerLoading = useLoading(c.GET_DEALER_BY_ID);
  const isLoading = isApplicationListLoading || isGetDealerLoading;

  const parent_id =
    _.get(userProfile, "id", "") || _.get(userProfile, "parent_id");
  const channel_id = _.get(userProfile, "channel_id", "");

  const getApplicationListParams = useMemo(
    () => ({
      ...filterData,
      channel_id,
      parent_id,
      application_status:
        _.get(filterData, "application_status.value") ||
        "rejected,on-going,approved,returned,resubmitted",
      account_type_name: _.get(filterData, "account_type_name.label") || "",
    }),
    [filterData, userProfile]
  );

  const openOnBoardModal = (item) =>
    setModal("account-information", {
      item,
      getApplicationListParams,
      dealerData,
    });

  const handlePager = (page) =>
    dispatch(
      actions.getApplicationList(
        removeEmpty({ ...getApplicationListParams, page })
      )
    );

  const handleSearchSubmit = () => {
    dispatch(actions.getApplicationList(removeEmpty(getApplicationListParams)));
  };

  const dealersInformation = (id) => history.push(`${match.path}/${id}`);

  const renderDropdownActions = (item) => {
    const applicationStatus = _.get(item, "attributes.application_status");
    const id = _.get(item, "id");
    return (
      <Actions
        id={id}
        containerClassName="dropdown"
        buttonClassName="btn btn-link p-0"
      >
        {applicationStatus === "on-going" ||
        applicationStatus === "resubmitted" ? (
          <button
            type="button"
            className={`${buttonClass} text-uppercase`}
            onClick={() => dealersInformation(id)}
          >
            <small>Details</small>
          </button>
        ) : null}

        {applicationStatus === "returned" && (
          <button
            type="button"
            className={`${buttonClass} text-uppercase`}
            onClick={() => dealersInformation(id)}
          >
            <small>Resubmit</small>
          </button>
        )}

        {applicationStatus === "approved" && (
          <button
            type="button"
            className={`${buttonClass} text-uppercase`}
            onClick={() => openOnBoardModal(item)}
          >
            <small>ONBOARD</small>
          </button>
        )}
      </Actions>
    );
  };

  const renderStatus = (applicationStatus) => {
    let status;
    if (applicationStatus === "on-going") status = "orange";
    if (applicationStatus === "rejected") status = "red";
    if (applicationStatus === "approved") status = "green";

    return status;
  };

  useEffect(() => {
    if (!profile_id) return;
    dispatch(actions.getDealerById(profile_id));
  }, [profile_id]);

  useEffect(() => {
    dispatch(actions.getApplicationList(removeEmpty(getApplicationListParams)));
    return () => {
      dispatch(req.setItem(c.GET_APPLICATION_LIST, []));
    };
  }, []);

  return (
    <div className="d-table partners__dealer-container partners__bg-light-gray px-5 py-3 min-vh-100">
      <Filter
        form={c.form}
        filterData={[filterData, setFilterData]}
        actions={handleSearchSubmit}
        loading={isLoading}
      />
      <h6 className="h6 font-weight-bold m-0 my-3">
        Manage Application Request
      </h6>
      <div className="row">
        {isLoading ? (
          <div className="w-100 px-3" style={{ fontSize: 25 }}>
            <div className="py-3 text-center w-100">
              <span className="fas fa-1x fa-spin fa-spinner" />
            </div>
          </div>
        ) : (
          <>
            {sortApplicationList.length > 0 ? (
              sortApplicationList.map((item) => (
                <div
                  className="col-lg-6 col-xl-12 pb-lg-3 pb-xl-0 mb-lg-3 mb-xl-3"
                  key={_.get(item, "id")}
                >
                  <div className="card border">
                    <div className="row card-body">
                      <div className="col order-xl-first order-sm-second row pr-0 position-relative">
                        {_.get(item, "attributes.application_status") ===
                          "rejected" ||
                        _.get(item, "attributes.application_status") ===
                          "returned" ? (
                          <IconTooltip
                            icon="fas fa-exclamation-circle text-danger icon-tooltip"
                            text={_.get(
                              item,
                              "attributes.application_rejection_reason"
                            )}
                            id={item.id}
                            backgroundColor="black"
                          />
                        ) : null}
                        <div className="col-sm-6 col-xl mb-lg-4 mb-xl-0">
                          <CardItem
                            label="Dealer Code"
                            description={_.get(item, "attributes.code") || "--"}
                          />
                        </div>
                        <div className="col-2">
                          <CardItem
                            label="Company Name"
                            description={_.get(item, "attributes.company_name")}
                          />
                        </div>
                        <div className="col-sm-6 col-xl-2 mb-lg-4 mb-xl-0">
                          <CardItem
                            label="Account Type"
                            description={
                              _.get(item, "attributes.account_type_name") ||
                              "--"
                            }
                          />
                        </div>

                        <div className="col-sm-6 col-xl mb-lg-4 mb-xl-0">
                          <CardItem
                            label="Date Created"
                            description={moment(
                              _.get(item, "attributes.created_at")
                            ).format("LL")}
                          />
                        </div>

                        <div className="col-sm-6 col-xl-2 mb-lg-4 mb-xl-0">
                          <CardItem
                            label="Modified By"
                            description={
                              _.get(item, "attributes.updated_by") || "--"
                            }
                          />
                        </div>

                        <div className="col-sm-6 col-xl mb-lg-4 mb-xl-0">
                          <CardItem
                            label="Modified Date"
                            description={moment(
                              _.get(item, "attributes.application_updated_at")
                            ).format("LL")}
                          />
                        </div>

                        <div className="col-sm-6 col-xl mb-lg-4 mb-xl-0">
                          <CardItem
                            label="Application Type"
                            description={
                              _.get(item, "attributes.application_type_name") ||
                              "--"
                            }
                            descriptionClassName="mb-0 text-capitalize"
                          />
                        </div>

                        <div className="col-sm-6 col-xl">
                          <div>
                            <div
                              className="font-weight-bold mb-2"
                              style={{
                                fontSize: ".6rem",
                                color: "rgba(0, 0, 0, .6)",
                              }}
                            >
                              Application Status
                            </div>
                            <p
                              className="mb-0 font-weight-bold"
                              style={{
                                fontSize: ".7rem",
                                color: "rgba(0, 0, 0, .7)",
                              }}
                            >
                              <span
                                className="text-capitalize"
                                style={{
                                  color: renderStatus(
                                    _.get(item, "attributes.application_status")
                                  ),
                                }}
                              >
                                {_.get(item, "attributes.application_status")}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="p-0 col-lg-12 col-xl-1 mb-sm-2 mb-xl-0 order-xl-second order-sm-first text-right action-table">
                        {_.get(item, "attributes.application_status") !==
                          "rejected" && (
                          <>
                            <div
                              className="font-weight-bold d-sm-none d-xl-block"
                              style={{
                                fontSize: ".7rem",
                                color: "rgba(0, 0, 0, .6)",
                              }}
                            >
                              Action
                            </div>
                            <div>{renderDropdownActions(item)}</div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="bg-light-0 py-3 text-center w-100">
                <p className="m-0" style={{ fontSize: ".7rem" }}>
                  There are no records found
                </p>
              </div>
            )}
          </>
        )}
      </div>
      <div className="d-flex justify-content-end">
        <Pagination onChange={handlePager} data={applicationListPager} />
      </div>
      <AccountInformation />
    </div>
  );
};

export default ApplicationsContainer;
