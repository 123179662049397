import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import _ from "lodash";

import { ModalContext } from "App";
import withModal from "modules/common/hoc/withModal";
import { ToastError, ToastSuccess } from "modules/common/components/Toast";

import ButtonAsync from "components/Button";
import SelectShift from "modules/ManageAccounts/modalForms/SelectShift";
import SelectFrontliners from "modules/ManageAccounts/modalForms/SelectFrontliners";
import { assignFrontliner } from "app/branch-operations/actions";

function AssignFrontLiner() {
  const queryClient = useQueryClient();
  const setModal = useContext(ModalContext);
  const modalData = useSelector(({ modal }) => modal?.modalData || "");

  const branchId = modalData?.branchId;
  const branch_name = modalData?.branch_name;
  const [formData, setFormData] = useState([]);
  const [pending, setPending] = useState(false);

  const closeModal = () => setModal({ isOpen: false });

  const onSuccess = () => {
    setPending(false);
    queryClient.invalidateQueries("attached_users_fl");
    ToastSuccess("Successfully assigned branch and selling window.");
    closeModal();
  };

  const onError = (errors) => {
    setPending(false);
    _.forEach(errors?.response?.data?.errors, (err) => ToastError(err?.detail));
  };

  const { isLoading, mutate: assignFlApi } = useMutation(assignFrontliner, {
    onSuccess,
    onError,
  });

  const isInputEmpty = () => {
    const { schedule, user } = formData;
    return _.isEmpty(schedule) || _.isEmpty(user);
  };

  const handleChangeSelect = (key, callback) => (value) => {
    setFormData({
      ...formData,
      [key]: value || {},
    });
    if (callback) callback();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);
    assignFlApi({
      id: formData?.user.value,
      schedule_id: formData?.schedule?.value,
      branch_id: branchId,
    });
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label className="form-label text-success">
              Select Frontliner:
            </label>
            <SelectFrontliners
              onChange={handleChangeSelect("user")}
              value={_.get(formData, "user", {})}
              branchId={branchId}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label className="form-label text-primary">Branch:</label>
            <input
              autoFocus
              type="text"
              name="name"
              readOnly
              value={branch_name}
              className="form-control form-custom form-control_bottom"
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label className="form-label text-success">Select Shift:</label>
            <SelectShift
              onChange={handleChangeSelect("schedule")}
              value={_.get(formData, "schedule", {})}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <ButtonAsync
              type="submit"
              loading={isLoading || pending}
              disabled={isInputEmpty()}
            >
              Add
            </ButtonAsync>
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

const modalKey = "create-frontliner";
const modalConfig = {
  title: "Add Frontliner",
  size: "modal-lg",
};

export default withModal(modalKey, modalConfig)(AssignFrontLiner);
