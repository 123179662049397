import React, { useContext, useState, useMemo } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { ModalContext } from 'App';
import Button from 'components/Button';
import withModal from 'modules/common/hoc/withModal';
import { ToastError, ToastSuccess } from 'modules/common/components/Toast';
import ErrorMessage from 'modules/common/components/ErrorMessage';
import DealerMinSelectField from './DealerMinSelectField';
import { setAgentMins } from '../actions';

const AgentRetailerSetup = () => {
  const urlParams = useParams();
  const setModal = useContext(ModalContext);
  const queryClient = useQueryClient();
  const [min, setMin] = useState({});
  const agentId = _.get(urlParams, 'agentId');

  const { mutate: setMins, isLoading: isAdding }= useMutation(setAgentMins, {
    onSuccess: () => {
      queryClient.invalidateQueries('agent-mins');
      ToastSuccess('Successfully assigned MIN to agent.');
      setModal({ isOpen: false });
    },
    onError: err => {
      const errorMessage = (
        <ErrorMessage
          error={err}
          defaultMessage="Failed to assign MIN to agent."
        />
      );
      ToastError(errorMessage);
      setModal({ isOpen: false });
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    setMins({ agentId, dealer_sim_id: min?.id });
  };

  const handleModalClose = e => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const isFormValid = useMemo(() => {
    return !_.isEmpty(min);
  }, [min]);

  return (
    <div className="col">
      <form onSubmit={handleSubmit}>
        <div className="row px-0">
          <div className="col">
            <label className="form-label text-primary">Sub-dealer MINs *</label>
            <DealerMinSelectField onChange={setMin} />
          </div>
          <div className="col">
            <label className="form-label text-primary">SIM Type *</label>
            <input
              readOnly
              type="text"
              value={_.get(min, 'attributes.type')}
              className="form-control form-custom form-control_bottom"
            />
          </div>
        </div>
        <div className="d-flex justify-content-end pt-4">
          <Button
            loading={isAdding}
            type="submit"
            disabled={!isFormValid || isAdding}
          >
            Add
          </Button>
          <Button onClick={handleModalClose} className="btn btn-light ml-2">
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

const modalKey = 'agent-min-setup';
const modalConfig = {
  title: 'Add Sub-Dealer MINs',
  size: 'modal-md',
};

export default withModal(modalKey, modalConfig)(AgentRetailerSetup);
