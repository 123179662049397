import * as req from "api/actions";
import _ from "lodash";
import store from "store";
import { serialize } from "helper";
import * as c from "./constant";

export const getBentaKita = (payload, callback) =>
  req.list(
    c.GET_BENTA_KITA,
    "api/v1/logs/transactions/sales-profit",
    payload,
    (res) => {
      let parseSales = [];
      let parseProfit = [];
      // const totalSales = _.get(res, 'res.total_sales').replace(/,/g, "");
      // const totalProfit = _.get(res, 'res.total_profit').replace(/,/g, "");
      const graphData = {
        labels: (_.get(res, "data") || []).map((item) => {
          const sales = +_.get(item, "attributes.sales").replace(/,/g, "");
          const profit = +_.get(item, "attributes.profit").replace(/,/g, "");
          parseSales = parseSales.concat([sales]);
          parseProfit = parseProfit.concat([profit]);
          return _.get(item, "attributes.period");
        }),
        datasets: [
          {
            label: "Benta",
            backgroundColor: "rgba(45, 190, 96, 1)",
            hoverBackgroundColor: "rgba(45, 190, 96, 1)",
            data: parseSales,
          },
          {
            label: "Kita",
            backgroundColor: "rgba(217, 217, 217, 1)",
            hoverBackgroundColor: "rgba(217, 217, 217, 1)",
            data: parseProfit,
          },
        ],
      };
      store.dispatch(req.setItem(`${c.GET_BENTA_KITA}/graph`, graphData));
      if (callback) callback(res);
    },
  );

export const downloadBentaKita = (payload, params, callback) =>
  req.download(
    c.DOWNLOAD_BENTA_KITA,
    `api/v1/export/logs/transactions/sales-profit?${serialize(params)}`,
    payload,
    callback,
  );

export const downloadLoadTransaction = (params, callback) => {
  const now = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Manila",
  });
  const timestamp = now
    .replace(/[-/:]/g, "-")
    .replace(",", "")
    .replace(/\s/g, "_");
  const filename = `Load Subscriber History ${timestamp}.csv`;
  return req.download(
    c.DOWNLOAD_SUBSCRIBER_TRANSACTION,
    `api/v1/export/logs/transactions?${serialize(params)}`,
    filename,
    callback,
  );
};

export const downloadLoadRetailerTransaction = (payload, params, callback) =>
  req.download(
    c.DOWNLOAD_RETAILER_TRANSACTION,
    `api/v1/export/dealer-sims/transfer-load/transactions?${serialize(params)}`,
    payload,
    callback,
  );

export const getLoadTransaction = (payload, callback) =>
  req.list(
    c.GET_LOAD_SUBSCRIBER_TRANSACTIONS,
    "api/v1/logs/transactions",
    payload,
    callback,
  );

export const getLoadRetailerTransaction = (payload, callback) =>
  req.list(
    c.GET_LOAD_RETAILER_TRANSACTION,
    "api/v1/dealer-sims/transfer-load/transactions",
    payload,
    callback,
  );

export const getBillsTransaction = (payload, callback) =>
  req.list(
    c.GET_BILLS_TRANSACTION,
    "api/v1/my/transactions",
    payload,
    callback,
  );

export const getRetailerUsers = (payload, callback) =>
  req.list(c.GET_RETAILER_USERS, "api/v1/retailer-users", payload, callback);

export const getSourceFund = (payload, callback) =>
  req.list(c.GET_SOURCE_FUND, "api/v1/retailer_sims", payload, callback);

export const getSourceFundDealer = (payload, callback) =>
  req.list(c.GET_SOURCE_FUND_DEALER, "api/v1/dealer-sims", payload, callback);

export const getBranch = (payload, callback) =>
  req.list(
    c.GET_BRANCH,
    "api/v1/branches",
    { ...payload, include: null },
    callback,
  );
