import React, { useState, useEffect, useMemo, useContext } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import ReactInputMask from 'react-input-mask';

import { ModalContext } from 'App';
import { ToastSuccess } from 'modules/common/components/Toast';
import { removeEmpty } from 'helper';
import { useLoading } from 'modules/ui/hooks';
import withModal from 'modules/common/hoc/withModal';
import FormInputBorderBottom from 'modules/common/components/input/FormInputBorderBottom';
import ButtonAsync from 'components/Button';

import * as c from '../constants';
import * as actions from '../actions';

const style = {
  control: base => ({
    ...base,
    border: 0,
    borderBottom: '1px solid gray',
    borderRadius: 0,
    boxShadow: 'none',
  }),
};

const DeliveryAddressInformationUpdate = () => {
  const setModal = useContext(ModalContext);
  const dispatch = useDispatch();

  const modalData = useSelector(({ modal }) => _.get(modal, 'modalData', {}));
  const provinceList = useSelector(({ api }) =>
    _.get(api, `${c.GET_PROVINCE_LIST}.list`, [])
  );
  const cityList = useSelector(({ api }) =>
    _.get(api, `${c.GET_CITY_LIST}.list`, [])
  );
  const barangayList = useSelector(({ api }) =>
    _.get(api, `${c.GET_BARANGAY_LIST}.list`, [])
  );
  const [initialData, setInitialData] = useState(
    c.companyAddressCreateUpdateModalDefaultValue
  );
  const userProfile = useSelector(({ api }) =>
    _.get(api, 'AUTH/user_profile.item')
  );

  const [pending, setPending] = useState(false);

  const updated_by = _.get(userProfile, 'full_name');
  const dealer_id = _.get(modalData, 'dealer_id', '');
  const address_id = _.get(modalData, 'deliveryAddressInfo.id', {});

  const isInputEmpty = useMemo(() => {
    const {
      unit_building_house_no,
      street_name,
      barangay,
      municipality,
      province,
      postal_no,
    } = initialData;

    return (
      _.isEmpty(unit_building_house_no) ||
      _.isEmpty(_.get(municipality, 'value', '')) ||
      _.isEmpty(_.get(province, 'value', '')) ||
      _.isEmpty(_.get(barangay, 'value', '')) ||
      _.isEmpty(street_name) ||
      _.isEmpty(postal_no)
    );
  }, [initialData]);

  const companyAddress = _.get(modalData, 'companyAddress', {});
  const previousDeliveryAddress = _.get(
    modalData,
    'previousDeliveryAddress',
    {}
  );

  const provinceOptions = useMemo(
    () =>
      provinceList.map(({ attributes: { name, code } }) => ({
        value: code,
        label: name,
      })),
    [provinceList]
  );

  const cityOptions = useMemo(
    () =>
      cityList.map(({ attributes: { name, code } }) => ({
        value: code,
        label: name,
      })),
    [cityList]
  );

  const barangayOptions = useMemo(
    () =>
      barangayList.map(({ attributes: { name, code, zip } }) => ({
        value: code,
        label: name,
        zip,
      })),
    [barangayList]
  );

  const handleCloseModal = () => setModal({ isOpen: false });

  const handleChangeOtherFields = ({ target }) =>
    setInitialData({
      ...initialData,
      [target.name]: target.value,
    });

  const handleProvince = value => {
    setInitialData({
      ...initialData,
      province: value,
      municipality: '',
      barangay: '',
      postal_no: '',
    });

    if (value) {
      dispatch(actions.getCityList(value.value));
      dispatch(actions.resetBarangay());
    }
  };

  const handleMunicipality = value =>
    setInitialData({
      ...initialData,
      municipality: value,
      barangay: '',
      postal_no: '',
    });

  const handleBarangay = value => {
    setInitialData({
      ...initialData,
      barangay: value,
      postal_no: _.get(value, 'zip', ''),
    });
  };

  const handleChangeCheck = () =>
    setInitialData({
      ...initialData,
      isSame: !_.get(initialData, 'isSame'),
    });

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);

    const payload = {
      ...initialData,
      barangay_name: _.get(initialData, 'barangay.label'),
      barangay_code: _.get(initialData, 'barangay.value'),
      municipality_name: _.get(initialData, 'municipality.label'),
      municipality_code: _.get(initialData, 'municipality.value'),
      province_name: _.get(initialData, 'province.label'),
      province_code: _.get(initialData, 'province.value'),
      management_account_step: 5,
      dealer_id,
      updated_by,
      barangay: '',
      municipality: '',
      province: '',
      isSame: '',
    };

    const successCallback = () => {
      dispatch(actions.showDeliveryAddressInformation(dealer_id));
      handleCloseModal();
      setPending(false);

      if (_.isEmpty(address_id))
        ToastSuccess('Successfully Create Delivery Address Information.');
      else ToastSuccess('Successfully Updated Delivery Address Information.');
    };

    const errCallback = () => setPending(false);

    if (_.isEmpty(address_id))
      dispatch(
        actions.createDeliveryAddress(
          removeEmpty(payload),
          successCallback,
          errCallback
        )
      );
    else
      dispatch(
        actions.updateDeliveryAddress(
          address_id,
          removeEmpty(payload),
          successCallback,
          errCallback
        )
      );
  };

  useEffect(() => {
    if (_.get(initialData, 'province.value'))
      dispatch(actions.getCityList(_.get(initialData, 'province.value')));
  }, [initialData.province]);

  useEffect(() => {
    const provinceCode = _.get(initialData, 'province.value', '');
    const cityCode = _.get(initialData, 'municipality.value', '');

    if (!provinceCode) {
      dispatch(actions.resetCity());
    }

    if (provinceCode && cityCode) {
      dispatch(actions.getBarangayList(provinceCode, cityCode));
    } else {
      dispatch(actions.resetBarangay());
    }
  }, [initialData.province, initialData.municipality]);

  useEffect(() => {
    dispatch(actions.getProvinceList());

    if (_.isEmpty(address_id)) {
      dispatch(actions.resetCity());
      dispatch(actions.resetBarangay());
    }

    return () => {
      if (_.isEmpty(address_id)) {
        dispatch(actions.resetCity());
        dispatch(actions.resetBarangay());
      }
    };
  }, [previousDeliveryAddress]);

  useEffect(() => {
    if (!_.isEmpty(previousDeliveryAddress))
      setInitialData({
        ...previousDeliveryAddress,
        isSame: _.isEqual(previousDeliveryAddress, companyAddress),
      });
  }, [previousDeliveryAddress, companyAddress]);

  useEffect(() => {
    if (_.get(initialData, 'isSame')) {
      setInitialData({
        ...companyAddress,
        isSame: true,
      });
    }

    if (
      !_.get(initialData, 'isSame') &&
      !_.isEqual(previousDeliveryAddress, companyAddress)
    ) {
      setInitialData({
        ...previousDeliveryAddress,
        isSame: false,
      });
    }
  }, [initialData.isSame, previousDeliveryAddress, companyAddress]);

  return (
    <div className="p-2">
      <form onSubmit={handleSubmit}>
        <div className="same-container d-flex align-items-center mb-4">
          <input
            type="checkbox"
            name="same_company_address"
            className="mr-2"
            onChange={handleChangeCheck}
            checked={_.get(initialData, 'isSame')}
          />
          <span className="text-xs text-grey-1000 font-weight-bold">
            {' '}
            Same as Company Address
          </span>
        </div>
        <div className="row">
          <div className="col-4 mb-2">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Province *
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="province"
              required
              isClearable
              styles={style}
              options={provinceOptions}
              value={
                _.get(initialData, 'province.value')
                  ? _.get(initialData, 'province')
                  : ''
              }
              onChange={handleProvince}
              isLoading={useLoading(c.GET_PROVINCE_LIST)}
              isDisabled={_.isEmpty(provinceOptions)}
            />
          </div>

          <div className="col-4 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              City / Municipality *
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="municipality"
              required
              isClearable
              options={cityOptions}
              value={
                _.get(initialData, 'municipality.value')
                  ? _.get(initialData, 'municipality', '')
                  : ''
              }
              onChange={handleMunicipality}
              styles={style}
              isLoading={useLoading(c.GET_CITY_LIST)}
              isDisabled={
                _.isEmpty(_.get(initialData, 'province.value')) &&
                _.isEmpty(cityOptions)
              }
            />
          </div>

          <div className="col-4 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Barangay *
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="barangay"
              required
              isClearable
              styles={style}
              options={barangayOptions}
              value={
                _.get(initialData, 'barangay.value')
                  ? _.get(initialData, 'barangay', '')
                  : ''
              }
              onChange={handleBarangay}
              isLoading={useLoading(c.GET_BARANGAY_LIST)}
              isDisabled={_.isEmpty(barangayOptions)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-4 mb-2">
            <FormInputBorderBottom
              label="Unit No./ Building No./ House No. *"
              name="unit_building_house_no"
              value={_.get(initialData, 'unit_building_house_no', '')}
              onChange={handleChangeOtherFields}
            />
          </div>

          <div className="col-4 ">
            <FormInputBorderBottom
              label="Street Name *"
              name="street_name"
              value={_.get(initialData, 'street_name', '')}
              onChange={handleChangeOtherFields}
            />
          </div>

          <div className="col-4 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              ZIP Code*
            </label>
            <ReactInputMask
              required
              mask="9999999999"
              maskChar=""
              className="form-control form-custom form-control_bottom px-3"
              name="postal_no"
              value={_.get(initialData, 'postal_no', '--') || ''}
              onChange={handleChangeOtherFields}
              placeholder="Here..."
              style={c.inputStyles}
              disabled
            />
          </div>
        </div>

        <div className="button-container d-flex justify-content-end mt-4">
          <ButtonAsync
            className="btn btn-primary position-relative py-2 px-4 mr-2"
            type="submit"
            loading={pending}
            disabled={isInputEmpty}
          >
            Save
          </ButtonAsync>

          <button
            type="button"
            className="btn bg-gray-300 text-white py-2 px-4"
            onClick={handleCloseModal}
            disabled={pending}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

const modalKey = 'delivery-address-information-update';
const modalConfig = {
  title: 'Delivery Address Information',
  size: 'modal-lg',
};

export default withModal(
  modalKey,
  modalConfig
)(DeliveryAddressInformationUpdate);
