import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Icon from 'components/Icons';
import { useDebounce } from '../hooks';

const SearchField = ({ isLoading, onChange, placeholder, label, custom }) => {
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 400);

  useEffect(() => {
    if (!onChange || typeof onChange !== 'function') return;
    onChange(value);
  }, [debouncedValue]);

  return (
    <div className="form-group">
      {label ? <label className="form-label">{label}</label> : null}
      <div className="d-flex align-items-center">
        <div className="position-absolute d-flex p-2">
          {isLoading ? (
            <div
              className="spinner-border text-gray-300"
              role="status"
              style={{ width: '1rem', height: '1rem' }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <Icon.Search />
          )}
        </div>
        <input
          className="form-control border outline-none"
          placeholder={placeholder}
          type="text"
          value={value}
          onChange={e => setValue(e.target.value)}
          style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
        />
        {value.length ? (
          <div className="position-absolute" style={{ ...custom }}>
            <button
              type="button"
              className="btn px-2 no-outline-btn"
              onClick={() => setValue('')}
            >
              <Icon.Close className="d-block" />
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SearchField;

SearchField.propTypes = {
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  custom: PropTypes.instanceOf(Object),
};

SearchField.defaultProps = {
  isLoading: false,
  label: '',
  placeholder: 'Search',
  custom: { right: '1rem' },
};
