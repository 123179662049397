import React, { useEffect, useState } from "react";
import _ from "lodash";
import { rootApi as baseURL, removeEmpty } from "helper";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";

async function get(url = "") {
  const token = sessionStorage.getItem("token");
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
}

const jsonToQueryString = (json) =>
  `?${Object.keys(json)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(json[key]) || ""}`
    )
    .join("&")}`;

const SelectAsync = (props) => {
  const {
    api,
    value,
    transformer,
    onChange,
    isDisabled,
    params,
    label,
    defaultSelected,
    callBack,
    withAll,
    queryName,
    ...rest
  } = props;
  const [localValue, setLocalValue] = useState("");

  const handleChange = (args) => {
    setLocalValue(args);

    if (!onChange || typeof onChange !== "function") return;
    onChange((state) => ({
      ...state,
      [rest.name]: _.get(args, "value", ""),
    }));
    if (!callBack || typeof callBack !== "function") return;
    callBack(args);
  };

  const fetcher = async (query) => {
    const defaultValue = defaultSelected || value;
    if (_.isEmpty(api)) return [];
    const endpoint = `${baseURL}${api}${jsonToQueryString(removeEmpty({
      [queryName]: query,
      ...params,
    }))}`;
    let response = await get(endpoint);
    if (
      withAll &&
      _.get(response, "data", []).length >= 2 &&
      _.isEmpty(query)
    ) {
      response = {
        data: [{ id: "all", attributes: { name: "All" } }, ...response.data],
      };
    }
    if (defaultValue) {
      let val;
      if (_.get(response, "data", []).length === 1) val = transformer(response);
      else val = transformer(response).filter((i) => i.value === defaultValue);
      setLocalValue(_.get(val, "0", ""));
      onChange((state) => {
        if (defaultValue !== _.get(state, rest.name, ""))
          return {
            ...state,
            [rest.name]: _.get(val, "0.value", ""),
          };
        return state;
      });
    }
    return transformer(response);
  };

  useEffect(() => {
    if (value) return;
    setLocalValue(null);
  }, [value]);

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={rest.name} className="form-label">
          {label} {rest.required && <span className="text-danger">*</span>}
        </label>
      )}
      <AsyncSelect
        defaultOptions
        classNamePrefix="react-select"
        className="react-select-container"
        key={`${JSON.stringify(api)}_${JSON.stringify(params)}`}
        isSearchable
        {...props}
        value={localValue}
        loadOptions={fetcher}
        onChange={handleChange}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default SelectAsync;

SelectAsync.propTypes = {
  api: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  transformer: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  params: PropTypes.instanceOf(Object),
  defaultSelected: PropTypes.string,
  label: PropTypes.string,
  callBack: PropTypes.func,
  withAll: PropTypes.bool,
  queryName: PropTypes.string
};

SelectAsync.defaultProps = {
  params: {},
  label: "",
  defaultSelected: null,
  callBack: null,
  withAll: false,
  isDisabled: false,
  queryName: 'q'
};
