import React, { useEffect, useContext, useState, useMemo } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { checkPermissions, join, removeEmpty, removeNull } from "helper";
import { ModalContext } from "App";
import Actions from "components/Actions";
import { FilterSlide as Filter } from "components/Filter";
import Pagination from "modules/common/components/Pagination";
import GenericTable from "modules/common/components/GenericTable";
import { USER_PROFILE } from "modules/auth/constants";
import BatchCreateSetup from "./BatchCreateSetup";
import ViewErrorLogs from "./ViewErrorLogs";
import FillupCorpInfo from "../CorpAdminForms.js/FillupCorpInfo";
import UpdateFillupCorpInfo from "../CorpAdminForms.js/UpdateFillupCorpInfo";
import CorpStatusModal from "../modalForms/CorpStatusModal";
import LogoutConfirm from "../modalForms/LogoutConfirm";
import ValidateCSV from "../modalForms/ValidateCSV";
import * as actions from "../action";
import * as c from "../constant";

const dropdownButtonClass =
  "mt-0 btn btn-block btn-default py-1 rounded-0 text-right";

function CorpAdminUser({ getCorpUsers, corpList, isLoading, pager, tab_name }) {
  const setModal = useContext(ModalContext);
  const [filterData, setFilter] = useState(c.caSdaInitState);
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const roles = profile?.roles;
  const filteredCorpList = _.filter(
    corpList,
    (a) => !_.includes(_.get(a, "attributes.role_slug"), "corporate-admin")
  );

  const filter = useMemo(
    () =>
      removeNull({ ...filterData, is_active: filterData?.is_active?.value }),
    [filterData]
  );

  const handleChangePage = (page) => {
    const newFilter = { ...filter, page };
    setFilter((state) => ({ ...state, page }));
    getCorpUsers(newFilter);
  };

  const handleCreate = (e) => {
    e.preventDefault();
    setModal("fill-up-corp-info", { setFilter });
  };

  const handleBatchCreate = (e) => {
    e.preventDefault();
    setModal("validate-batch-create-csv", { setFilter });
  };

  const handleViewErrors = () => {
    setModal("view-error-logs");
  };

  const handleUpdateUser = (item) => (e) => {
    e.preventDefault();
    setModal("update-fill-up-corp-info", {
      userInfoData: {
        first_name: _.get(item, "attributes.first_name"),
        last_name: _.get(item, "attributes.last_name"),
        username: _.get(item, "attributes.username"),
        role_id: _.get(item, "attributes.role_id"),
        id: _.get(item, "id"),
      },
      terporaryData: {
        mobile: _.get(item, "attributes.mobile", ""),
        email: _.get(item, "attributes.email", ""),
      },
      filter,
    });
  };

  const handleStatus = (label, value, retailerId, returnLabel, userId) => (
    e
  ) => {
    e.preventDefault();
    const payload = {
      is_active: value,
    };
    setModal("corp-update-status", {
      data: { label, retailerId, returnLabel, userId, payload },
      filter,
    });
  };

  const handleForceLogout = (item) => (e) => {
    const data = {
      userId: _.get(item, "attributes.user_id"),
      userName: _.get(item, "attributes.username"),
    };
    setModal("confirm-user-logout", data);
  };

  const renderActions = (item) => {
    if (checkPermissions(["can-update-dealer-user"])) {
      return (
        <Actions id={item.id}>
          <button
            type="button"
            className={join`${dropdownButtonClass} border-bottom`}
            onClick={handleUpdateUser(item)}
          >
            <small>Update</small>
          </button>
          {_.get(item, "attributes.is_active") === true ? (
            <button
              type="button"
              className={join`${dropdownButtonClass} border-bottom`}
              onClick={handleStatus(
                "Deactivate",
                false,
                _.get(item, "id"),
                "Deactivated",
                _.get(item, "attributes.user_id")
              )}
            >
              <small>Deactivate</small>
            </button>
          ) : (
            <button
              type="button"
              className={join`${dropdownButtonClass} border-bottom`}
              onClick={handleStatus(
                "Activate",
                true,
                _.get(item, "id"),
                "Activated",
                _.get(item, "attributes.user_id")
              )}
            >
              <small>Activate</small>
            </button>
          )}

          <button
            type="button"
            className={dropdownButtonClass}
            onClick={handleForceLogout(item)}
          >
            <small>Force Logout</small>
          </button>
        </Actions>
      );
    }
    return null;
  };

  const form = useMemo(() => {
    const role = _.head(roles);
    return c.form.map((x) => {
      if (x.name === "role_slug")
        return {
          ...x,
          params: c.getCAGuardName(role),
        };
      return x;
    });
  }, [c.form, roles]);

  useEffect(() => {
    getCorpUsers(removeEmpty({ ...filter, page: 1 }));
  }, [filterData.q, filterData.is_active, filterData.role_slug]);

  return (
    <div className="main-content-inner">
      <Filter
        form={form}
        filterData={[filterData, setFilter]}
        loading={isLoading}
      />
      <div className="d-flex align-items-center mb-2 mt-2">
        <h1 className="h5 flex-grow-1 mt-0 mb-0">
          Manage User Accounts <br />
          <small>List of {tab_name}</small>
        </h1>
        {checkPermissions(["can-view-dealer-user"]) && (
          <div>
            <button
              type="button"
              className="btn btn-success mr-1"
              onClick={handleCreate}
            >
              <i className="fas fa-plus mr-2" />
              Create Account
            </button>
            <button
              type="button"
              className="btn btn-success mr-1"
              onClick={handleBatchCreate}
            >
              <i className="fas fa-plus mr-2" />
              Batch Create
            </button>
            <button
              type="button"
              className="btn btn-dark mr-1"
              onClick={handleViewErrors}
            >
              Error Logs
            </button>
          </div>
        )}
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0 bg-white border">
            <GenericTable
              isLoading={isLoading}
              format={[
                {
                  key: (item) => (
                    <span>{_.get(item, "attributes.username") || "--"}</span>
                  ),
                  label: "Username",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>{_.get(item, "attributes.full_name") || "--"}</span>
                  ),
                  label: "Name",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span className="badge badge-success">
                      {_.includes(
                        _.get(item, "attributes.role_slug"),
                        "corporate-admin"
                      )
                        ? "CORPORATE ADMIN"
                        : _.get(item, "attributes.role_name") || "N/A"}
                    </span>
                  ),
                  label: "Role",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>
                      {moment(_.get(item, "attributes.created_at")).format(
                        "LLL"
                      ) || "--"}
                    </span>
                  ),
                  label: "Date Created",
                  width: "auto",
                },
                {
                  key: (item) =>
                    _.get(item, "attributes.is_active") ? (
                      <span className="text-success">Active</span>
                    ) : (
                      <span className="text-danger">Inactive</span>
                    ),
                  className: "text-right",
                  label: "Status",
                  width: "auto",
                },
                {
                  key: (item) => renderActions(item),
                  label: "Actions",
                  width: "80px",
                },
              ]}
              data={
                _.includes(_.get(profile, "roles[0]"), "corporate-admin")
                  ? filteredCorpList
                  : corpList
              }
              height="80vh"
            />
          </div>
        </div>
        <Pagination
          onChange={handleChangePage}
          data={pager}
          className="d-flex justify-content-between p-2 border-top border-muted"
          withPage
        />
      </div>
      <FillupCorpInfo />
      <UpdateFillupCorpInfo />
      <CorpStatusModal />
      <LogoutConfirm />
      <ValidateCSV />
      <BatchCreateSetup onComplete={getCorpUsers} />
      <ViewErrorLogs />
    </div>
  );
}

CorpAdminUser.defaultProps = {
  tab_name: "",
};

CorpAdminUser.propTypes = {
  getCorpUsers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  corpList: PropTypes.instanceOf(Object).isRequired,
  pager: PropTypes.instanceOf(Object).isRequired,
  tab_name: PropTypes.string,
};

const mapStateToProps = ({ api }) => ({
  corpList: _.get(api, `${c.GET_CORP_ADMIN}.list`) || [],
  pager: _.get(api, `${c.GET_CORP_ADMIN}.res.meta.pagination`) || {},
  isLoading: api.loading.indexOf(c.GET_CORP_ADMIN) > -1,
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(CorpAdminUser);
