import React, { useRef } from 'react';
import { useOnClickOutside } from 'hooks';
import PropTypes from 'prop-types';
import PDFReader from './pdf/PDFReader';
import ZoomImage from './zoom/ZoomImage';

function Lightbox({ src, onClose, type }) {
  const boxRef = useRef();
  useOnClickOutside(boxRef, onClose);
  const isPDF = type === 'application/pdf';

  React.useEffect(() => {
    const closeModal = e => {
      if (e.key === 'Escape') onClose();
    };
    document.addEventListener('keyup', closeModal);
    return () => {
      document.removeEventListener('keyup', closeModal);
    };
  }, []);
  return (
    <div className={`lightbox-image ${isPDF ? 'pdf-height' : ''}`} ref={boxRef}>
      {isPDF ? (
        <PDFReader src={src} onClose={onClose} />
      ) : (
        <ZoomImage src={src} onClose={onClose} />
      )}
    </div>
  );
}

Lightbox.defaultProps = {
  type: 'image/*',
};

Lightbox.propTypes = {
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default Lightbox;
