import React, { Fragment } from 'react';
// import PropTypes from 'prop-types';

const CreditDetails = () => {
  return (
    <Fragment>
      <div className="col-4">
        <div className="card shadow-sm px-0 mt-4">
          <div className="card-body">
            <small>Available Credit Limit</small>
            <div className="font-weight-bold">PHP 100, 000.00</div>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="card shadow-sm px-0 mt-4">
          <div className="card-body">
            <small>Credit Term</small>
            <div className="font-weight-bold">30 Days</div>
          </div>
        </div>
      </div>
      <div className="col-4">
        <div className="card shadow-sm px-0 mt-4">
          <div className="card-body">
            <small>Credit Status</small>
            <div className="font-weight-bold text-success">Active</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

CreditDetails.propTypes = {};

export default CreditDetails;
