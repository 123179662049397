import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { isEmpty } from "lodash";
import { ModalContext } from "App";
import { checkPermissions, join } from "helper";
import { useProfile } from "modules/auth/hooks";
import { renderStatus } from "modules/ui/hooks";
import Actions from "components/Actions";
import Panel from "components/Panel";
import PanelButton from "components/PanelButton";
import PanelRow from "components/PanelRow";
import Assign from "modules/UserAccountManagement/modalForms/Assign";
import FrontlinerStatus from "../modals/FrontlinerStatus";
import AssignFrontliner from "../modals/AssignFrontliner";
import UpdateFrontliner from "../modals/UpdateFrontliner";
import DataField from "components/DataField";

import { getBranchById, getAttachedUsersFl } from "../actions";

const actionBtnClassName =
  "mt-0 btn btn-block btn-default py-1 rounded-0 text-right";

const FrontlinerPanel = () => {
  const params = useParams();
  const setModal = useContext(ModalContext);
  const profile = useProfile();

  const branchId = params?.branchId;
  const isNonMigrated = profile?.roles[0]?.includes("non-migrated");

  const apiBranch = useQuery({
    queryKey: ["branch", branchId],
    queryFn: getBranchById,
    retry: false,
    keepPreviousData: true,
    enabled: !branchId,
    refetchOnWindowFocus: false,
  });

  const attachedFlUsersApi = useQuery({
    queryKey: [
      "attached_users_fl",
      branchId,
      { role_slug: isNonMigrated ? "frontliner-non-migrated" : "frontliner" },
    ],
    queryFn: getAttachedUsersFl,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const branch = apiBranch?.data?.data || {};
  const attachedFlUsers = attachedFlUsersApi?.data?.data?.data || [];

  const handleAssignNewFl = () =>
    setModal(
      "create-frontliner",
      {
        branchId,
        branch_name: branch?.attributes?.name,
      },
      { modalTitle: "Add Frontliner" },
    );

  const handleStatus = (label, value, retailerId, name, callbackLabel) => {
    const payload = {
      is_active: value,
    };
    setModal("frontliner-update-status", {
      data: {
        label,
        value,
        retailerId,
        payload,
        name,
        callbackLabel,
        branchId,
      },
    });
  };

  const handleUpdateUser = (item) => {
    setModal("update-fl", {
      userInfoData: {
        first_name: item?.attributes.first_name,
        last_name: item?.attributes.last_name,
        username: item?.attributes.username,
        email: item?.attributes.email,
        branch_id: item?.attributes.branch_id,
        schedule_id: item?.attributes.schedule_id,
        role_id: item?.attributes.role_id,
        id: item?.id,
        type: "fu",
      },
      temporaryData: {
        email: item?.attributes?.email,
        mobile: item?.attributes?.mobile,
      },
    });
  };

  const handleManage = (item) => {
    setModal("assign", {
      id: item?.id,
      branchId,
    });
  };

  const renderDropdownActions = (item) => {
    const attributes = item?.attributes;
    return (
      <Actions id={item.id}>
        <React.Fragment>
          <button
            type="button"
            className={join`${actionBtnClassName} border-bottom`}
            onClick={() => handleUpdateUser(item)}
          >
            <small>Update</small>
          </button>
          {attributes.is_active ? (
            <button
              type="button"
              className={join`${actionBtnClassName} border-bottom`}
              onClick={() =>
                handleStatus(
                  "Deactivate",
                  false,
                  item?.id,
                  attributes.username,
                  "Deactivated",
                )
              }
            >
              <small>Deactivate</small>
            </button>
          ) : (
            <button
              type="button"
              className={join`${actionBtnClassName} border-bottom`}
              onClick={() =>
                handleStatus(
                  "Activate",
                  true,
                  item?.id,
                  attributes.username,
                  "Activated",
                )
              }
            >
              <small>Activate</small>
            </button>
          )}
          <button
            type="button"
            className={join`${actionBtnClassName} border-bottom`}
            onClick={() => handleManage(item)}
          >
            <small>Manage</small>
          </button>
        </React.Fragment>
      </Actions>
    );
  };

  if (checkPermissions(["can-view-retailer-user-frontliner"]))
    return (
      <>
        <Panel
          title="Frontliner"
          renderActions={() => (
            <div className="btn-group">
              {checkPermissions(["can-update-branch"]) && (
                <PanelButton label="Assign new" onClick={handleAssignNewFl} />
              )}
            </div>
          )}
        >
          {isEmpty(attachedFlUsers) ? (
            <PanelRow>
              <div className="col text-center text-gray-400 text-xs">
                No Frontliners assigned to this Branch yet.
              </div>
            </PanelRow>
          ) : (
            <div className="tw-px-4">
              {attachedFlUsers?.map((item) => (
                <PanelRow key={item?.id} appendClass="tw-grid tw-grid-cols-9">
                  <DataField
                    className="tw-col-span-2"
                    label="Username:"
                    value={item?.attributes?.username || "--"}
                  />
                  <DataField
                    className="tw-col-span-2"
                    label="Full Name:"
                    value={item?.attributes?.full_name || "--"}
                  />
                  <DataField
                    className="tw-break-words tw-col-span-3"
                    label="Email Address:"
                    value={item?.attributes?.email || "--"}
                  />
                  <DataField
                    label="Status:"
                    value={renderStatus[+item?.attributes?.is_active]}
                    className="tw-col-span-1"
                  />
                  {checkPermissions(["can-update-retailer-user"]) && (
                    <div>{renderDropdownActions(item)}</div>
                  )}
                </PanelRow>
              ))}
            </div>
          )}
        </Panel>

        <Assign />
        <AssignFrontliner />
        <UpdateFrontliner />
        <FrontlinerStatus />
      </>
    );
  return null;
};

export default FrontlinerPanel;
