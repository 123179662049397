import React from 'react';
import PropTypes from 'prop-types';
import placeholderPhoto from 'assets/images/upload-square-placeholder.png';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

function ViewImageTwo({ src, onClose }) {
  const close = e => {
    e.preventDefault();
    onClose();
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      role="button"
      tabIndex={-1}
    >
      <div className="container-max">
        <TransformWrapper
          defaultScale={1}
          wheel={{
            wheelEnabled: false,
            touchPadEnabled: false,
          }}
          options={{
            minScale: 1,
          }}
          zoomIn={{
            step: 10,
          }}
        >
          {({ zoomIn, zoomOut, resetTransform }) => {
            const buttons = [
              {
                id: 1,
                icon: 'fa-search-plus',
                func: zoomIn,
              },
              {
                id: 2,
                icon: 'fa-search-minus',
                func: zoomOut,
              },
              {
                id: 3,
                icon: 'fa-redo',
                func: resetTransform,
              },
            ];
            return (
              <>
                <div className="control-panel p-3 d-flex justify-content-between">
                  <div className="d-flex justify-content-between align-items-baseline">
                    {buttons.map(btn => (
                      <a
                        key={btn.id}
                        href="/"
                        onClick={e => {
                          e.preventDefault();
                          btn.func(e);
                        }}
                      >
                        <i className={`fas ${btn.icon} mx-3`} />
                      </a>
                    ))}
                  </div>
                  <div>
                    <a href="/" onClick={close}>
                      <i className="fas fa-times mx-3 hover-red" />
                    </a>
                  </div>
                </div>
                <div className="px-3 min-img d-flex w-100 justify-content-center">
                  <section id="pdf-section">
                    <TransformComponent>
                      <img src={src || placeholderPhoto} alt="" />
                    </TransformComponent>
                  </section>
                </div>
              </>
            );
          }}
        </TransformWrapper>
      </div>
    </div>
  );
}

ViewImageTwo.propTypes = {
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ViewImageTwo;
