import React, { useContext, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import withModal from 'modules/common/hoc/withModal';
import { ToastSuccess } from 'modules/common/components/Toast';
import { ModalContext } from 'App';
import ButtonAsync from 'components/Button';

import * as actions from '../actions';
import * as c from '../constant';

function Transfer() {
  const setModal = useContext(ModalContext);
  const dispatch = useDispatch();
  const initialData = useSelector(({ modal }) => _.get(modal, 'modalData'));
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [pending, setPending] = useState(false);
  const loading = useSelector(({ api }) => _.get(api, `loading`));
  const isLoading =
    loading.includes(c.TRANSFER_INTERNAL) ||
    loading.includes(c.VALIDATE_PASSWORD);

  const closeModal = () => {
    setModal({ isOpen: false });
  };

  const isInputEmpty = useMemo(() => password.length < 1, [password]);

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);

    const payload = {
      amount: _.get(initialData, 'amount'),
      target_type: _.get(initialData, 'targetType'),
      target_id: _.get(initialData, 'targetId'),
      type: _.get(initialData, 'transferType'),
    };

    const handleTransferSuccess = res => {
      ToastSuccess(_.get(res, 'res.data.success.0.detail', ''));
      closeModal();
      setPending(false);
    };

    const handleTransferError = () => {
      closeModal();
      setPending(false);
    };

    const handleValidatePasswordSuccess = () => {
      const sourceId = _.get(initialData, 'sourceId');
      dispatch(
        actions.transferInternal(
          sourceId,
          payload,
          handleTransferSuccess,
          handleTransferError
        )
      );
    };

    const handleValidatePasswordError = () => setPending(false);

    dispatch(
      actions.validatePassword(
        { password },
        handleValidatePasswordSuccess,
        handleValidatePasswordError
      )
    );
  };

  return (
    <form onSubmit={handleSubmit} className="p-2 px-4">
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col-6">Sim Type:</div>
            <div className="col text-left">
              {_.get(initialData, 'sourceType')}
            </div>
          </div>
          <div className="row">
            <div className="col-6">Source MIN:</div>
            <div className="col text-left">
              {_.get(initialData, 'sourceMin')}
            </div>
          </div>
          <div className="row">
            <div className="col-6">Target Mobile No.:</div>
            <div className="col text-left">
              {_.get(initialData, 'targetMin')}
            </div>
          </div>
          <div className="row">
            <div className="col-6">Amount:</div>
            <div className="col text-left">{_.get(initialData, 'amount')}</div>
          </div>
        </div>
      </div>
      <div className="transferTo">
        <hr />
        <h6 className="h6 my-2 font-weight-bold">
          Your password is required to continue.
        </h6>

        <div className="col px-0">
          <div className="prepend-form-right mb-3">
            <label className="form-label text-primary">Password:</label>
            <input
              className="form-control form-custom form-control_bottom"
              type={showPassword ? `text` : `password`}
              placeholder="Enter password"
              required
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
            <button
              type="button"
              onClick={() => setShowPassword(prev => !prev)}
              className="btn btn-default rounded-circle p-0 mr-1"
            >
              <i
                className={`fas ${showPassword ? `fa-eye-slash` : `fa-eye`}`}
              />
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-light px-4 float-right font-weight-bold mx-1"
            onClick={closeModal}
          >
            Cancel
          </button>

          <ButtonAsync
            type="submit"
            loading={isLoading || pending}
            disabled={isInputEmpty}
            className="btn btn-primary px-4 float-right font-weight-bold mx-1 position-relative"
          >
            Proceed
          </ButtonAsync>
        </div>
      </div>
    </form>
  );
}

const modalKey = 'transfer-confirm';
const modalConfig = { title: 'TRANSACTION CONFIRMATION' };

export default withModal(modalKey, modalConfig)(Transfer);
