import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const NoClickJack = () => {
  const [isTop, setIsTop] = useState(false);

  useEffect(() => {
    if (window.top.document.domain === window.document.domain) {
      setIsTop(true);
    }
  }, []);

  const domStyles = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 9999999,
  };

  if (!isTop)
    return ReactDOM.createPortal(
      <div style={domStyles} />,
      document.querySelector('body')
    );
  return null;
};

export default NoClickJack;
