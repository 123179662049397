import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ssv } from 'helper';
import OverflowTip from 'modules/common/components/OverflowTip';

const ListItem = React.memo(({ isActive, data, onClick }) => {
//   const status = _.get(data, 'attributes.is_active');
  const fullName = _.get(data, 'attributes.name');

  const handleClick = () => {
    onClick(data);
  };

  return (
    <div
      tabIndex={-1}
      role="menuitem"
      onKeyUp={() => {}}
      className={ssv(
        'd-flex justify-content-between align-items-center px-3 border-top border-gray-200',
        isActive ? 'bg-gray-100' : ''
      )}
      style={{ height: 55, cursor: 'pointer' }}
      onClick={handleClick}
    >
      <OverflowTip
        id={fullName}
        text={fullName}
        toUpperCase
        className="pr-2 "
        isSmall
      />
      {/* <div>
        <div
          className={ssv(`rounded`, status ? 'bg-primary' : 'bg-gray-300')}
          style={{ width: 7, height: 7 }}
        />
      </div> */}
    </div>
  );
});

ListItem.propTypes = {
  isActive: PropTypes.bool,
  data: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
};

ListItem.defaultProps = {
  isActive: false,
  onClick: () => {},
};

export default ListItem;
