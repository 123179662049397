import React, { useContext, useMemo, useState, useEffect } from "react";
import { ModalContext } from "App";
import Button from "components/Button";
import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastError, ToastSuccess } from "modules/common/components/Toast";
import withModal from "modules/common/hoc/withModal";
import ErrorMessage from "modules/common/components/ErrorMessage";
import { getAgent, setAgent } from "../actions";
import LocationSelectField from "./LocationSelectField";

const AgentLocationSetup = () => {
  const urlParams = useParams();
  const setModal = useContext(ModalContext);
  const queryClient = useQueryClient();
  const agentId = _.get(urlParams, "agentId");
  const modalData = useSelector(({ modal }) => _.get(modal, "modalData"));

  const refetch = modalData?.refetch;

  const [location, setLocation] = useState("");

  const agent = useQuery({
    queryKey: ["agent", agentId],
    queryFn: getAgent,
    retry: false,
    keepPreviousData: true,
  });

  const agent_data = agent?.data?.data;

  const { mutate: saveAgent, isLoading: isAdding } = useMutation(setAgent, {
    onSuccess: () => {
      const isUnassigned = _.isNull(location?.value);
      refetch();
      queryClient.invalidateQueries("location");
      queryClient.invalidateQueries("agent");
      ToastSuccess(
        `Successfully ${
          isUnassigned ? "unassigned" : "assigned"
        } selling location ${isUnassigned ? "from" : "to"} agent.`
      );
      setModal({ isOpen: false });
    },
    onError: (err) => {
      const errorMessage = (
        <ErrorMessage
          error={err}
          defaultMessage="Failed to assign a selling location to agent"
        />
      );
      ToastError(errorMessage);
      setModal({ isOpen: false });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const area_id = _.get(location, "value");
    saveAgent({ agentId, area_id });
  };

  const handleModalClose = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const isFormValid = useMemo(() => {
    return !_.isEmpty(location);
  }, [location]);

  useEffect(() => {
    const areaId = agent_data?.attributes?.area_id;
    const areaName = agent_data?.attributes?.area_id;
    const territoryPrevData =
      areaId && areaName
        ? {
            value: _.get(agent_data, "attributes.area_id"),
            label: _.get(agent_data, "attributes.area_name"),
          }
        : {
            value: null,
            label: "Unassigned",
          };
    if (!_.isEmpty(agent_data, "attributes")) setLocation(territoryPrevData);
  }, [agent_data]);

  return (
    <div className="col">
      <form onSubmit={handleSubmit}>
        <div className="row px-0">
          <div className="col">
            <label className="form-label text-primary">
              {_.capitalize(_.get(modalData, "parentLocationType"))}
            </label>
            <input
              readOnly
              type="text"
              value={_.get(modalData, "parentLocationName")}
              className="form-control form-custom form-control_bottom"
            />
          </div>
          <div className="col">
            <label className="form-label text-primary">
              {_.capitalize(_.get(modalData, "agentLocationType"))}
            </label>
            <LocationSelectField
              defaultValue={_.get(agent_data, "attributes.area_id")}
              onChange={setLocation}
              value={location}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end pt-4 pb-2">
          <Button loading={isAdding} type="submit" disabled={!isFormValid}>
            Assign
          </Button>
          <Button onClick={handleModalClose} className="btn btn-light ml-2">
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

const modalKey = "agent-location-setup";
const modalConfig = {
  title: "Assign Selling Location",
  size: "modal-md",
};

export default withModal(modalKey, modalConfig)(AgentLocationSetup);
