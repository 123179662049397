import React, { useState, useEffect, useMemo, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { ModalContext } from "App";
import * as session from "session";
import { useLoading } from "modules/ui/hooks";
import { removeEmpty, checkPermissions } from "helper";
import Actions from "components/Actions";
import Pagination from "modules/common/components/Pagination";
import * as Icon from "components/Icons";
import ButtonAsync from "modules/common/components/ButtonAsync";
import Filter from "components/Filter";
import CardItem from "../../components/CardItem";
import AddDealer from "../modal/AddDealer";
import Delete from "../modal/Delete";
import * as actions from "../actions";
import * as c from "../constants";

const buttonClass = `mt-0 btn btn-block btn-default p-0 rounded-0 text-right`;
const AddIcon = () => <Icon.Add fill="#fff" />;

const DealerContainer = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);
  const [options, setOptions] = useState({
    loading: false,
    isDisabled: false,
  });
  const parent_id = useSelector(
    ({ api }) =>
      _.get(api, "AUTH/user_profile.item.id") ||
      _.get(api, "AUTH/user_profile.item.parent_id")
  );

  const dealer_details = useSelector(
    ({ api }) => _.get(api, `${c.SHOW_DEALER}.item`) || {}
  );

  const dealerList = useSelector(({ api }) =>
    _.get(api, `${c.GET_DEALER_LIST}.list`, [])
  );

  const dealerListPager = useSelector(({ api }) =>
    _.get(api, `${c.GET_DEALER_LIST}.pager`, {})
  );

  const [filterData, setFilterData] = useState(c.compoundSearchDefaultValue);

  const isDealerListLoading = useLoading(c.GET_DEALER_LIST);

  const getDealerListParams = useMemo(
    () => ({
      ...filterData,
      parent_id,
      application_status:
        _.get(filterData, "application_status.value") || "done,for-renewal",
      account_type_name: _.get(filterData, "account_type_name.label") || "",
    }),
    [filterData, parent_id]
  );

  const openAddDealerModal = () => setModal("add-update-dealer");

  const handlePager = (page) =>
    dispatch(
      actions.getDealerList(removeEmpty({ ...getDealerListParams, page }))
    );

  const handleSearchSubmit = () => {
    dispatch(actions.getDealerList(removeEmpty(getDealerListParams)));
  };

  useEffect(() => {
    dispatch(actions.getDealerList(removeEmpty(getDealerListParams)));
  }, []);

  useEffect(() => {
    if (_.isEmpty(dealer_details)) return;
    const isSubDealer =
      dealer_details?.attributes?.account_type_category_uuid ===
      c.category_uuid.sub_dealer;
    const hasSMMT = dealer_details?.attributes?.business_model_has_smmt;
    if (isSubDealer && !hasSMMT) {
      setOptions({
        loading: false,
        isDisabled: true,
      });
      return;
    }
    setOptions({
      loading: false,
      isDisabled: false,
    });
  }, [dealer_details]);

  useEffect(() => {
    setOptions({ ...options, loading: true });
    dispatch(actions.showDealer(parent_id));
  }, [parent_id]);

  const dealersInformation = (id, type) => {
    history.push(`${match.path}/${id}`);
    session.set("type", type);
  };

  const renderDropdownActions = (id) => (
    <Actions
      id={id}
      containerClassName="dropdown"
      buttonClassName="btn btn-link p-0"
    >
      <button
        type="button"
        className={`${buttonClass}`}
        onClick={() => dealersInformation(id, "details")}
      >
        <small>Details</small>
      </button>

      <button
        type="button"
        className={`${buttonClass}`}
        onClick={() => dealersInformation(id, "update")}
      >
        <small>Update</small>
      </button>
    </Actions>
  );

  return (
    <div className="d-table partners__dealer-container partners__bg-light-gray px-5 py-3 min-vh-100">
      <div className="tw-flex tw-items-start">
        <Filter
          form={c.form}
          filterData={[filterData, setFilterData]}
          actions={handleSearchSubmit}
          loading={isDealerListLoading}
          containerClass="tw-flex-grow tw-mr-2"
        />
        <div className="add-dealer">
          {checkPermissions(["can-create-dealers"]) && (
            <ButtonAsync
              type="button"
              appendClass="partners__dealer-add-dealer btn btn-success"
              onClick={openAddDealerModal}
              loading={options.loading}
              disabled={options.isDisabled}
            >
              <AddIcon />
              <span className="ml-2">Add Dealer</span>
            </ButtonAsync>
          )}
        </div>
      </div>
      <h6 className="h6 font-weight-bold m-0 my-3">Manage Dealers</h6>
      <div className="row d-flex ">
        {isDealerListLoading ? (
          <div
            className="w-100 px-3"
            style={{ fontSize: 25, minHeight: "80vh" }}
          >
            <div className="py-3 text-center w-100">
              <span className="fas fa-1x fa-spin fa-spinner" />
            </div>
          </div>
        ) : (
          <>
            {dealerList.length > 0 ? (
              dealerList.map(({ id, attributes }) => (
                <div
                  className="partners__dealer col-lg-6 col-xl-12 mb-sm-4"
                  key={id}
                >
                  <div className="card border">
                    <div className="row card-body">
                      <div className="partners__dealer-card-container col order-sm-second order-xl-first row ">
                        <div className="partners__dealer-card-item col-sm-12 col-md-4 col-lg-6 col-xl mb-sm-3 mb-xl-0">
                          <CardItem
                            label="Dealer Code"
                            description={_.get(attributes, "code") || "--"}
                          />
                        </div>

                        <div className="partners__dealer-card-item col-sm-12 col-md-4 col-lg-6 col-xl-2 mb-sm-3 mb-xl-0">
                          <CardItem
                            label="Company Name"
                            description={_.get(attributes, "company_name")}
                          />
                        </div>

                        <div className="partners__dealer-card-item col-sm-12 col-md-4 col-lg-6 col-xl-2 mb-sm-3 mb-xl-0">
                          <CardItem
                            label="Account Type"
                            description={
                              _.get(attributes, "account_type_name") || "--"
                            }
                          />
                        </div>

                        <div className="partners__dealer-card-item col-sm-12 col-md-4 col-lg-6 col-xl mb-sm-3 mb-xl-0">
                          <CardItem
                            label="Date Created"
                            description={moment(
                              _.get(attributes, "created_at")
                            ).format("LL")}
                          />
                        </div>

                        <div className="partners__dealer-card-item col-sm-12 col-md-4 col-lg-6 col-xl-2 mb-sm-3 mb-xl-0">
                          <CardItem
                            label="Modified By"
                            description={
                              _.get(attributes, "updated_by") || "--"
                            }
                          />
                        </div>

                        <div className="partners__dealer-card-item col-sm-12 col-md-4 col-lg-6 col-xl mb-sm-3 mb-xl-0">
                          <CardItem
                            label="Modified Date"
                            description={moment(
                              _.get(attributes, "updated_at")
                            ).format("LL")}
                          />
                        </div>

                        <div className="partners__dealer-card-item col-sm-12 col-md-4 col-lg-6 col-xl mb-sm-3 mb-xl-0">
                          <CardItem
                            label="Account Status"
                            description={_.get(
                              attributes,
                              "account_status",
                              "--"
                            )}
                            descriptionClassName="mb-0 text-capitalize text-success font-weight-bold"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-xl-1 mb-lg-2 mb-xl-0 order-xs-first order-sm-first order-xl-second text-center">
                        <div
                          className="partners__dealer-action-title font-weight-bold d-lg-none d-md-none d-sm-none d-xl-block"
                          style={{
                            fontSize: ".7rem",
                            color: "rgba(0, 0, 0, .6)",
                          }}
                        >
                          Action
                        </div>
                        <div>{renderDropdownActions(id)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="bg-light-0 py-3 text-center w-100">
                <p className="m-0" style={{ fontSize: ".7rem" }}>
                  There are no records found
                </p>
              </div>
            )}
          </>
        )}
      </div>
      <div className="d-flex justify-content-end">
        <Pagination onChange={handlePager} data={dealerListPager} />
      </div>
      <AddDealer />
      <Delete />
    </div>
  );
};

export default DealerContainer;
