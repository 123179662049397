import * as req from "api/actions";
import _ from "lodash";
import store from "store";
import { downloadGet, rootApi } from "helper";
import { ToastWarn, ToastSuccess } from "modules/common/components/Toast";
import * as c from "./constant";

export const getBranch = (payload, callback, errCallback) =>
  req.list(
    c.GET_BRANCH,
    "api/v1/branches",
    { ...payload, include: null },
    callback,
  );

export const showBranch = (id, payload, callback) =>
  req.item(c.SHOW_BRANCH, `api/v1/branches/${id}`, payload, callback);

export const createBranch = (payload, callback, errCallback) =>
  req.create(
    c.CREATE_BRANCH,
    "api/v1/branches",
    payload,
    callback,
    {},
    errCallback,
  );

export const updateBranch = (id, payload, callback, errCallback) =>
  req.update(
    c.UPDATE_BRANCH,
    `api/v1/branches/${id}`,
    payload,
    callback,
    {},
    errCallback,
  );

export const removeBranch = (id, callback, errCallback) =>
  req.remove(
    c.REMOVE_BRANCH,
    `api/v1/branches/${id}`,
    callback,
    {},
    {},
    errCallback,
  );

export const getRetailerSims = (payload, callback) =>
  req.list(
    c.GET_RETAILERS_SIMS,
    "api/v1/dealer-retailer-sims",
    payload,
    (res) => {
      const options = (res.data || []).map((x) => ({
        label: _.get(x, "attributes.mobile_identification_number"),
        value: _.get(x, "id"),
      }));
      store.dispatch(req.setList(`${c.GET_RETAILERS_SIMS}/options`, options));
      if (callback) callback();
    },
  );

export const getDealerSims = (payload, callback) =>
  req.list(c.GET_DEALER_SIMS, "api/v1/dealer-sims", payload, callback);

export const getShiftSchedule = (payload, callback) =>
  req.list(c.GET_SHIFT, "api/v1/schedules", payload, callback);

export const showShiftSchedule = (id, payload, callback) =>
  req.item(c.SHOW_SHIFT, `api/v1/schedules/${id}`, payload, callback);

export const createShiftSchedule = (payload, callback, errCallback) =>
  req.create(
    c.CREATE_SHIFT,
    "api/v1/schedules",
    payload,
    callback,
    {},
    errCallback,
  );

export const updateShiftSchedule = (id, payload, callback, errCallback) =>
  req.update(
    c.UPDATE_SHIFT,
    `api/v1/schedules/${id}`,
    payload,
    callback,
    {},
    errCallback,
  );

export const getAttachUsers = (branchId, payload, callback) =>
  req.list(
    c.GET_ATTACHED_USERS,
    `api/v1/external_users/branches/${branchId}/retailer_users`,
    payload,
    (res) => {
      const sheduleData = (_.get(res, "data") || []).map((x) =>
        (_.get(x, "attributes.schedule.shifts") || []).map((shift) => ({
          id: _.get(x, "attributes.schedule.id"),
          title: _.get(x, "attributes.display_name"),
          start: new Date(_.get(shift, "start_date")),
          end: new Date(_.get(shift, "end_date")),
        })),
      );
      const secondParse = _.get(sheduleData, "0") || [];
      store.dispatch(
        req.setList(`${c.GET_ATTACHED_USERS}/sheduleData`, secondParse),
      );
      if (callback) callback(res);
    },
  );

export const attactUsers = (branchId, payload, callback) =>
  req.create(
    c.ATTACH_USERS,
    `api/v1/branches/${branchId}/retailer_users`,
    payload,
    callback,
  );

export const updateAttachUsers = (id, payload, callback) =>
  req.update(
    c.UPDATE_ACCTACH_USERS,
    `api/v1/retailer-users/${id}`,
    payload,
    callback,
  );

export const getAttachUsersBA = (branchId, payload, callback) =>
  req.list(
    c.GET_ATTACHED_USERS_BA,
    `api/v1/external_users/branches/${branchId}/retailer_users`,
    payload,
    callback,
  );

export const downloadBranchHistory = (id, params, setIsLoadingHistory) => {
  ToastWarn("Generating export file...");
  setIsLoadingHistory(true);
  downloadGet({
    url: `${rootApi}api/v1/history/branch/${id}`,
    params,
    filename: "Branch History.csv",
    onSuccess: () => {
      ToastSuccess("Export success!");
      setIsLoadingHistory(false);
    },
    onError: () => {
      setIsLoadingHistory(false);
    },
  });
  return { type: "DOWNLOAD_BRANCH_HISTORY" };
};

export const downloadScheduleHistory = (id, params, setIsLoadingHistory) => {
  ToastWarn("Generating export file...");
  setIsLoadingHistory(true);
  downloadGet({
    url: `${rootApi}api/v1/history/schedule/${id}`,
    params,
    filename: "Selling Window History.csv",
    onSuccess: () => {
      ToastSuccess("Export success!");
      setIsLoadingHistory(false);
    },
    onError: () => {
      setIsLoadingHistory(false);
    },
  });
  return { type: "DOWNLOAD_SCHEDULE_HISTORY" };
};

export const assign = (id, payload, callback, errCallback) =>
  req.create(
    c.ASSIGN,
    `api/v1/retailer/retailer_users/${id}/branches`,
    payload,
    callback,
    {},
    errCallback,
  );

export const validatePassword = (payload, callback, errCallback) =>
  req.create(
    c.VALIDATE_PASSWORD,
    "api/v1/external/users/my/validate-password",
    payload,
    callback,
    {},
    errCallback,
  );
