import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import imgThumb from 'assets/images/logo-loader.png';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formatNumber } from 'helper';
import { ToastSuccess } from 'modules/common/components/Toast';
import * as actions from '../actions';

function RemoveItem({ data, onBack, removeItemById, getCart, setToggle }) {
  const parsePrice = str => str.replace('₱', '');
  const firstParseResult = parsePrice(`${_.get(data, 'attributes.price')}`);
  const secondParse = str => str.replace('.00', '');
  const secondParseResult = secondParse(`${firstParseResult}`);
  const thirdParse = str => str.replace(',', '');
  const finalParseResult = thirdParse(`${secondParseResult}`);
  const total = `${finalParseResult}` * `${_.get(data, 'attributes.quantity')}`;

  const handleRemove = e => {
    e.preventDefault();
    removeItemById(data.id, () => {
      getCart();
      setToggle('details');
      ToastSuccess('Successfully removed');
    });
  };

  return (
    <div className="container-fluid">
      <div className="text-white mr-2 bg-danger rounded-pill px-3 mb-3">
        Are you sure you want to remove this Item? (You may not be able to undo
        this action)
      </div>
      <div className="row">
        <div className="col-5">
          <img
            src={_.get(data, 'attributes.primary_photo') || imgThumb}
            alt="..."
            style={{ height: 250, width: '100%', objectFit: 'contain' }}
          />
        </div>
        <div className="col-7 p-0">
          <h4 className="font-weight-bold m-0 mb-2">
            {_.get(data, 'attributes.name')}
          </h4>
          <small className="mb-2">
            {_.get(data, 'attributes.description')}
          </small>
          <div className="row">
            <div className="form-group px-3 my-2">
              <label className="form-label">Price:</label>
              <input
                className="form-control input-textbox form-custom form-control_bottom"
                type="text"
                value={_.get(data, 'attributes.price')}
                readOnly
              />
            </div>
            <div className="form-group px-3 my-2">
              <label className="form-label">Promo:</label>
              <select
                name="promo"
                id="promo"
                className="custom-select"
                disabled
              >
                <option defaultValue>Select Promo</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <div className="form-group">
                <label className="form-label">Quantity:</label>
                <input
                  type="text"
                  className="form-control"
                  value={_.get(data, 'attributes.quantity')}
                  readOnly
                />
                <small className="text-muted">
                  Discount per item: PHP 2.00
                </small>
              </div>
              <span className="text-muted font-weight-bold">
                Total: ₱ {formatNumber(total) || '00'}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mt-3 d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  className="btn px-5 btn-default font-weight-bold mr-2"
                  onClick={onBack('details')}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn px-5 btn-danger font-weight-bold mr-2"
                  onClick={handleRemove}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

RemoveItem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onBack: PropTypes.func.isRequired,
  getCart: PropTypes.func.isRequired,
  setToggle: PropTypes.func.isRequired,
  removeItemById: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(RemoveItem);
