import React, { useState, useMemo, useCallback, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import placeholderPhoto from 'assets/images/upload-square-placeholder.png';
import { service } from 'api/service';

import { useDebounce } from 'hooks';

const overlayStyles = {
  top: 0,
  left: 0,
  background: 'rgba(0, 0, 0, .5)',
  zIndex: 9999,
};
const boxContainerStyles = {
  border: '5px dashed rgba(0, 0, 0, .7)',
  cursor: 'pointer',
};

// Reference
// 1 Megabyte = 1048576 Bytes.
// https://upmostly.com/tutorials/react-dropzone-file-uploads-react

const xButtonClassName = { cursor: 'pointer' };
const containerClassName =
  'upload-modal-container position-fixed h-100 w-100 d-flex align-items-center justify-content-center';

const fileDefaultValue = {
  attach_name: '',
  attach_img: '',
};

const UploadFile = ({
  isUploadModalHidden,
  setHidden,
  handleUploadImg,
  dealerAvatarInfo,
}) => {
  const [imageInfo, setInfo] = useState(fileDefaultValue);
  const checkUrl = useDebounce(_.get(imageInfo, 'attach_img'), 1000) || '';

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone();

  const handleCloseModal = () => setHidden(!isUploadModalHidden);

  const handleConfirm = () => {
    handleUploadImg(imageInfo, dealerAvatarInfo);
    setHidden(!isUploadModalHidden);
  };

  const token = sessionStorage.getItem('token');

  const transform = useCallback(() => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    service
      .post('/api/v1/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(data => {
        const url = _.get(data, 'data.data.file', '') || '';
        if (_.isEmpty(url)) return;
        setInfo({
          attach_name: _.get(acceptedFiles, '0.name'),
          attach_img: _.get(data, 'data.data.file'),
          file: acceptedFiles[0],
        });
      })
      .catch(err => console.log(err, 'error'));
  }, [acceptedFiles]);

  useEffect(() => {
    if (_.isEmpty(acceptedFiles)) return;
    transform();
  }, [acceptedFiles]);

  const isFileEmpty = useMemo(() => !checkUrl.length > 0, [checkUrl]);

  return (
    <div className={containerClassName} style={overlayStyles}>
      <div style={{ borderRadius: '5px', overflow: 'hidden', width: '30%' }}>
        <div className="bg-success py-2 px-3 text-white font-weight-bold d-flex justify-content-between">
          <span>Upload</span>
          <span
            aria-hidden="true"
            style={xButtonClassName}
            onClick={handleCloseModal}
          >
            &times;
          </span>
        </div>
        <div className="bg-light p-3">
          <div
            {...getRootProps({
              className:
                'dropzone p-3 w-100 h-100 d-flex flex-column align-items-center text-center',
            })}
            style={boxContainerStyles}
          >
            <input
              {...getInputProps()}
              accept="image/png, image/jpg, image/jpeg"
              maxSize={1048576}
            />
            <figure className="img-container mb-5" style={{ maxWidth: '35%' }}>
              <img
                src={_.get(imageInfo, 'attach_img') || placeholderPhoto}
                alt="Upload"
                className="w-100"
              />
            </figure>

            <div>
              <h6 className="h6 text-sm font-weight-bold my-0 mb-1">
                Click me or Drag a file to upload
              </h6>
              <p className="my-0 text-xs">
                Maximum size of image is 1MB and a minimum resolution of <br />
                380x251 pixels.JPG.PNG
              </p>
            </div>
          </div>

          <div className="button-container text-center mt-3">
            <button
              type="submit"
              className={`text-light-0 btn ${
                isFileEmpty ? 'bg-grey-1000' : 'bg-success'
              }`}
              onClick={handleConfirm}
              disabled={isFileEmpty}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

UploadFile.propTypes = {
  isUploadModalHidden: PropTypes.bool.isRequired,
  setHidden: PropTypes.func.isRequired,
  handleUploadImg: PropTypes.func.isRequired,
  dealerAvatarInfo: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]).isRequired,
};

export default React.memo(UploadFile);
