import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ToastSuccess } from 'modules/common/components/Toast';
import * as actions from '../actions';

function LogoutContainer({ history, logout }) {
  useEffect(() => {
    logout(() => {
      ToastSuccess('Your session has expired, Please login again.', {
        autoClose: 1500,
      });
    });
  }, [logout, history]);

  return <div className="p-3">Logging out...</div>;
}

LogoutContainer.propTypes = {
  logout: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, actions)(LogoutContainer);
