import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { ModalContext } from 'App';
import withModal from 'modules/common/hoc/withModal';

const cancelButtonStyles = {
  background: 'rgba(0, 0, 0, .2)',
  fontSize: '.7rem',
  color: 'rgba(0, 0, 0, .7)',
};

const Delete = () => {
  const setModal = React.useContext(ModalContext);

  const modalData = useSelector(
    ({ modal }) => _.get(modal, 'modalData', {}) || {}
  );

  const handleClickCloseModal = () => setModal({ isOpen: false });

  const handleClick = () =>
    setModal('partners-retailer-enter-password', { modalData });

  return (
    <div className="p-2">
      <div className="modal-description mb-4 pb-1">
        <p
          className="font-weight-bold"
          style={{ fontSize: '.7rem', color: 'rgba(0, 0, 0, .6)' }}
        >
          This will delete the selected and all its contents.
        </p>
        <h6 className="h6 mt-0 font-weight-bold">
          Are you sure you want to proceed Deletion?
        </h6>
      </div>
      <div className="buttons d-flex align-items-center justify-content-end">
        <button
          type="submit"
          onClick={handleClick}
          style={{ fontSize: '.7rem' }}
          className="btn btn-success position-relative d-flex justify-content-center align-items-center px-4 py-2 text-uppercase"
        >
          yes
        </button>
        <button
          type="button"
          className="btn ml-2 px-3 py-2 text-uppercase"
          style={cancelButtonStyles}
          onClick={handleClickCloseModal}
        >
          cancel
        </button>
      </div>
    </div>
  );
};

const modalKey = 'delete-retailer';
const modalConfig = {
  title: 'Delete Retailer',
  size: 'modal-s',
};

export default withModal(modalKey, modalConfig)(Delete);
