import React, { useEffect, useState, useContext, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { USER_PROFILE } from "modules/auth/constants";
import { ModalContext } from "App";
import { removeEmpty } from "helper";
import withModal from "modules/common/hoc/withModal";
import ButtonAsync from "components/Button";
import { ToastSuccess } from "modules/common/components/Toast";
import { useLoading } from "modules/ui/hooks";
import * as retailerSimsAction from "modules/ManageAccounts/action";
import AccountInfoForm from "../forms/AccountInfoForm";
import TemporaryAccountForm from "../forms/TemporaryAccountForm";

import * as actions from "../action";
import * as c from "../constant";

const temporaryFormFields = {
  email: "",
  mobile: "",
};

const form = {
  first_name: "",
  last_name: "",
  username: "",
  role_id: "",
};

function UpdateFillupUserInfo({
  updateRetailer,
  userInfoData,
  getBranch,
  getShift,
  getRoles,
  roleList,
  branchId,
  getUsersbyId,
  getAttachUsersBA,
  getAttachUsers,
  type,
  getRetailerUsers,
  terporaryData,
  userRoles,
}) {
  const setModal = useContext(ModalContext);
  const [formData, setformData] = useState(userInfoData || form);
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const isNonMigrated = profile?.roles[0]?.includes("non-migrated");
  const [temporaryForm, setTemporaryForm] = useState(
    terporaryData || temporaryFormFields
  );
  const [pending, setPending] = useState(false);

  const modalData = useSelector(
    ({ modal }) => _.get(modal, "modalData", {}) || {}
  );

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleChange = (key) => ({ target }) => {
    setformData({
      ...formData,
      [key]: target.value,
    });
  };

  const handleChangeInput = (value) => {
    setTemporaryForm({
      ...temporaryForm,
      ...value,
    });
  };

  const checkInput = useMemo(() => {
    const { first_name, last_name } = formData;
    return _.isEmpty(first_name) || _.isEmpty(last_name);
  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);
    if (type === "load-wallet") {
      const payload = {
        ...temporaryForm,
        first_name: _.get(formData, "first_name"),
        last_name: _.get(formData, "last_name"),
        username: _.get(formData, "username"),
        role_id: _.get(formData, "role_id"),
        was_updated: true,
      };
      updateRetailer(
        formData.id,
        payload,
        () => {
          ToastSuccess("Successfully updated user account.");
          setModal({ isOpen: false });
          setPending(false);
          getUsersbyId(userInfoData.id);
          getRetailerUsers(removeEmpty(modalData.filter));
          getAttachUsers(branchId, {
            role_slug: isNonMigrated ? "frontliner-non-migrated" : "frontliner",
          });
          getAttachUsersBA(branchId, {
            role_slug: isNonMigrated
              ? "branch-admin-non-migrated"
              : "branch-admin",
          });
        },
        () => setPending(false)
      );
    } else {
      const payload = {
        ...temporaryForm,
        first_name: _.get(formData, "first_name"),
        last_name: _.get(formData, "last_name"),
        username: _.get(formData, "username"),
        role_id: _.get(formData, "role_id"),
        was_updated: true,
      };
      updateRetailer(
        formData.id,
        payload,
        () => {
          ToastSuccess("Successfully updated user account.");
          setModal({ isOpen: false });
          setPending(false);
          getUsersbyId(userInfoData.id);
          getRetailerUsers(removeEmpty(modalData.filter));
        },
        () => setPending(false)
      );
    }
  };

  useEffect(() => {
    const user_role = _.head(userRoles);
    const payload = {
      user_guard_name: "",
    };
    const isSMMTCA = user_role === "corporate-admin-smmt";
    const isKACA = user_role === "corporate-admin-ka";
    const isRDCA = user_role === "corporate-admin-rd";
    const isPDCA = user_role === "corporate-admin";
    const isSKACA = user_role === "corporate-admin-ska";
    const isSUNM = user_role === "super-user-non-migrated";
    const isCANM = user_role === "corporate-admin-non-migrated";
    const isBANM = user_role === "branch-admin-non-migrated";

    if (isSMMTCA) payload.user_guard_name = "smmt-dealer-user";
    else if (isKACA) payload.user_guard_name = "ka-dealer-user";
    else if (isRDCA) payload.user_guard_name = "rd-dealer-user";
    else if (isSKACA) payload.user_guard_name = "ska-dealer-user";
    else if (isPDCA) payload.user_guard_name = "dealer-user";
    else if (isSUNM) payload.role_guard_name = "non-migrated-retailer-user";
    else if (isCANM) {
      payload.user_guard_name = "non-migrated-dealer-user";
      payload.role_guard_name = "non-migrated-retailer-user";
    } else if (isBANM) {
      payload.user_guard_name = "non-migrated-retailer-user";
      payload.role_guard_name = "non-migrated-retailer-user";
    }

    getBranch();
    getShift();
    getRoles(removeEmpty(payload));
    getUsersbyId(userInfoData.id);
  }, [getBranch, getShift, getRoles, getUsersbyId, userInfoData.id, userRoles]);

  return (
    <form onSubmit={handleSubmit} className="container-fluid">
      <AccountInfoForm
        onChange={handleChange}
        formData={formData}
        roleList={roleList}
        type="update"
      />
      <TemporaryAccountForm
        onChange={handleChangeInput}
        temporaryForm={temporaryForm}
        type="update"
      />
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold mr-2"
            >
              Close
            </button>
            <ButtonAsync
              type="submit"
              loading={useLoading(c.UPDATE_USERS)}
              disabled={pending || checkInput}
            >
              Save
            </ButtonAsync>
          </div>
        </div>
      </div>
    </form>
  );
}

UpdateFillupUserInfo.propTypes = {
  updateRetailer: PropTypes.func.isRequired,
  getBranch: PropTypes.func.isRequired,
  getShift: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  getUsersbyId: PropTypes.func.isRequired,
  getAttachUsers: PropTypes.func.isRequired,
  getAttachUsersBA: PropTypes.func.isRequired,
  getRetailerUsers: PropTypes.func.isRequired,
  userInfoData: PropTypes.instanceOf(Object).isRequired,
  terporaryData: PropTypes.instanceOf(Object).isRequired,
  roleList: PropTypes.instanceOf(Array).isRequired,
  branchId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  userRoles: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api, modal }) => ({
  userInfoData: _.get(modal, "modalData.userInfoData") || {},
  terporaryData: _.get(modal, "modalData.terporaryData") || {},
  branchId: _.get(modal, "modalData.branchId") || "",
  type: _.get(modal, "modalData.type") || "",
  roleList: _.get(api, `${c.GET_ROLES}.list`) || [],
  userRoles: _.get(api, "AUTH/user_profile.item.roles") || [],
});

const enhance = _.flowRight([
  withModal("update-fill-up-user-info", {
    title: "Update Account",
    size: "modal-lg",
  }),
  withRouter,
  connect(mapStateToProps, {
    ...retailerSimsAction,
    ...actions,
  }),
]);

export default enhance(UpdateFillupUserInfo);
