import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import Button from 'components/Button';

import * as actions from '../action';

const RequestOTPForm = ({ onNextStep, requestOTP, username, setUsername }) => {
  const [isPending, setIsPending] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    setIsPending(true);
    const payload = { username, purpose: 'portal-verification' };
    requestOTP(
      payload,
      res => {
        setIsPending(false);
        onNextStep(res?.res?.data);
      },
      () => setIsPending(false)
    );
  };

  const handleInputChange = event => {
    const {
      target: { value },
    } = event;
    setUsername(value);
  };

  return (
    <div style={{ maxWidth: 450 }} className="w-100 mx-auto">
      <h4 className="font-weight-bold text-center m-0 mb-4">Forgot Password</h4>
      <p className="text-center m-0">
        Please enter your valid Username for an OTP to be sent via email and SMS
      </p>

      <form onSubmit={handleSubmit} className="mt-5">
        <div className="form-group">
          <label className="form-label text-primary">Enter User Name:</label>
          <input
            className="form-control form-custom form-control_bottom"
            type="text"
            required
            name="username"
            value={username}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group mt-4 text-center">
          <div className="position-relative">
            <Button
              type="submit"
              className="btn btn-block btn-primary px-4"
              disabled={!username}
              loading={isPending}
            >
              Continue
            </Button>
          </div>
          <Link to="/" className="back-btn btn btn-link px-4 mt-3">
            Back to Login
          </Link>
        </div>
      </form>
    </div>
  );
};

RequestOTPForm.propTypes = {
  onNextStep: PropTypes.func.isRequired,
  requestOTP: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  setUsername: PropTypes.func.isRequired,
};

const enhance = _.flowRight([
  connect(() => ({}), {
    ...actions,
  }),
]);

export default enhance(RequestOTPForm);
