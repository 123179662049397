import React, { useCallback, useContext, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";

import { service } from "api/service";
import { ModalContext } from "App";
import { useProfile } from "modules/auth/hooks";
import withModal from "modules/common/hoc/withModal";
import { ToastError } from "modules/common/components/Toast";
import ButtonAsync from "components/Button";
import UploadField from "../components/UploadField";
import * as c from "../constant";

function ValidateCSV() {
  const [loading, setLoading] = useState();
  const [csv, setCsv] = useState();

  const setModal = useContext(ModalContext);
  const profile = useProfile();
  const role = useMemo(() => profile?.roles?.[0] || "", [profile]);

  const setFilter = useSelector(({ modal }) =>
    _.get(modal, "modalData.setFilter")
  );

  const handleUploadSuccess = useCallback((file) => setCsv(file), [setCsv]);

  const downloadTemplateValue = useMemo(() => {
    let templateUrl = "Template_UAM_CorpAdmin_SubDealerAgent.xlsx";

    if (role.includes("smmt")) templateUrl = c.CaSdaTemplates?.ca_smmt;
    else if (role === "super-user-b2b" || role.includes("-b2b"))
      templateUrl = c.CaSdaTemplates?.ca_su_b2b;
    else if (role === "super-user" || role.includes("rd"))
      templateUrl = c.CaSdaTemplates?.ca_rd;
    else if (role === "super-user-ka" || role.includes("-ka"))
      templateUrl = c.CaSdaTemplates?.ca_ka;
    else if (role.includes("-ska")) templateUrl = c.CaSdaTemplates?.ca_ska;
    else if (role === "corporate-admin")
      templateUrl = c.CaSdaTemplates?.ca_pdca;
    else if (role === "super-user-non-migrated")
      templateUrl = c.CaSdaTemplates?.ca_su_nm;
    else templateUrl = c.CaSdaTemplates?.ca_pd;

    return templateUrl;
  }, [role]);

  const handleValidate = useCallback(async () => {
    if (!csv) return;
    setLoading(true);
    try {
      const payload = {
        role_guard_name: "",
      };
      if (role.includes("smmt")) payload.role_guard_name = "smmt-dealer-user";
      else if (role === "super-user-b2b" || role.includes("-b2b"))
        payload.role_guard_name = "b2b-dealer-user";
      else if (role === "super-user" || role.includes("rd"))
        payload.role_guard_name = "rd-dealer-user";
      else if (role === "super-user-ka" || role.includes("-ka"))
        payload.role_guard_name = "ka-dealer-user";
      else if (role.includes("-ska"))
        payload.role_guard_name = "ska-dealer-user";
      else if (role === "super-user-non-migrated")
        payload.role_guard_name = "non-migrated-dealer-user";
      else payload.role_guard_name = "dealer-user";
      const params = {
        csv_link: csv.url,
        role_guard_name: payload.role_guard_name,
      };
      const validation = await service.post(
        "/api/v1/dealer-users/validate/import",
        params
      );
      const modalConfig = {
        csvData: csv,
        validation: validation?.data?.data,
        setFilter,
      };
      setModal("batch-create-setup", modalConfig);
      setLoading(false);
    } catch (err) {
      const errors = err?.response?.data?.errors || [];
      setLoading(false);
      setModal({ isOpen: false });
      ToastError(<ErrorsList errors={errors} />);
    }
  }, [setModal, csv]);

  return (
    <div className="container-fluid py-3 px-5 pb-4">
      <div className="row">
        <div className="mb-4">
          <strong>Easy steps on creating accounts by batch</strong>
        </div>
        <div className="row mt-1">
          <div className="col-6 d-flex align-items-start border-right">
            <div className="mr-2 px-2 rounded-circle bg-dark">
              <span className="text-white">1</span>
            </div>
            <div className="description" style={{ lineHeight: "1.3" }}>
              <small>
                Download the starting template (.xlxs) below and follow the
                instructions within the file to generate file for batch upload.
              </small>
              <p className="mt-3">
                <Link
                  to={`${process.env.PUBLIC_URL}/${downloadTemplateValue}`}
                  target="_blank"
                  download
                  className="btn btn-primary"
                >
                  <i className="fas fa-download mr-2" />
                  <small>Download Starting Template</small>
                </Link>
              </p>
            </div>
          </div>

          <div className="col-6 d-flex align-items-start pl-5 h-100">
            <div className="mr-2 px-2 rounded-circle bg-dark">
              <span className="text-white">2</span>
            </div>
            <div className="description w-100">
              <p className="text-sm">
                Drag and drop the accomplished CSV <br /> (Comma Separated
                Values or Comma Delimited) template file on the field below or
                click it to launch your system&apos;s file selection dialog
              </p>
              <div className="row d-flex align-items-center my-3 mb-4">
                <div className="col">
                  <UploadField onSuccess={handleUploadSuccess} />
                  <ButtonAsync
                    loading={loading}
                    className="btn btn-primary position-relative btn-block mt-3"
                    onClick={handleValidate}
                    disabled={!csv?.url}
                  >
                    Validate
                  </ButtonAsync>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const modalKey = "validate-batch-create-csv";
const modalConfig = {
  title: "Batch Create Account",
  size: "modal-lg",
};

export default withModal(modalKey, modalConfig)(ValidateCSV);

const ErrorsList = ({ errors }) => {
  return errors.map((error) => <div>{error?.detail}</div>);
};
