import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useRouteMatch, useParams } from 'react-router-dom';

export const useSessionStorage = (key, initialValue) => {
  const [state, setState] = useState(() => {
    try {
      const sessionStorageValue = sessionStorage.getItem(key);
      if (typeof sessionStorageValue !== 'string') {
        sessionStorage.setItem(key, JSON.stringify(initialValue));
        return initialValue;
      }
      return JSON.parse(sessionStorageValue || 'null');
    } catch {
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      sessionStorage.setItem(key, JSON.stringify(state));
    } catch {
      // prevent throw
    }
  });

  return [state, setState];
};

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useRootPath() {
  const { url } = useRouteMatch();
  const params = useParams();

  return _.replace(url, _.values(params), '').replace(/\/$/, '');
}

export const getExtension = filename => {
  const ext = filename.substring(
    filename.lastIndexOf('.') + 1,
    filename.length
  );
  if (ext === 'pdf') return 'application/pdf';
  return '';
};

export default {};
