import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams, Link } from "react-router-dom";
import { ToastError, ToastSuccess } from "modules/common/components/Toast";
import ErrorMessage from "modules/common/components/ErrorMessage";
import { numberToLocale, removeEmpty, ssv } from "helper";
import FormInputCheckbox from "modules/common/components/input/FormInputCheckbox";
import ButtonAsync from "modules/common/components/ButtonAsync";
import FormInput from "modules/common/input/FormInput";
import Pagination from "modules/common/components/Pagination";
import { useMyProfile } from "modules/auth/hooks";
import NoRecords from "components/NoRecords";
import Filter from "../components/Filter";
import {
  addDealerRetailerSims,
  addDealerSims,
  getAllDealerRetailerSims,
  getAllDealerSims,
  getAssignedDealerRetailerSims,
  getAssignedDealerSims,
  getBranchById,
  updateBranchDefaultSim,
} from "../actions";

const configOptions = {
  "dealer-sims": {
    title: "Dealer MINs Update",
    subTitle: "Manage Dealer MINs for the currently selected branch.",
    simType: "dealer_sims",
    listFn: getAllDealerSims,
    selectedFn: getAssignedDealerSims,
    mutateFn: addDealerSims,
  },
  "retailer-sims": {
    title: "Retailer MINs Update",
    subTitle: "Manage Retailer MINs for the currently selected branch.",
    simType: "dealer_retailer_sims",
    listFn: getAllDealerRetailerSims,
    selectedFn: getAssignedDealerRetailerSims,
    mutateFn: addDealerRetailerSims,
  },
};

const AssignMins = () => {
  const qc = useQueryClient();
  const [params, setParams] = useState({
    q: "",
    sim_type: "",
    per_page: 50,
  });
  const [page, setPage] = useState("");
  const profile = useMyProfile();
  const isCorpAdmin = profile?.attributes?.role_slug?.includes(
    "corporate-admin",
  );
  const dealer_id = isCorpAdmin
    ? profile?.attributes?.parent_id
    : profile?.id ?? profile?.attributes?.parent_id;
  const [selected, setSelected] = useState([]);
  const [sims, setSims] = useState([]);
  const [isDefault, setIsDefault] = useState(null);
  const urlParams = useParams();
  const branchId = _.get(urlParams, "branchId");
  const type = _.get(urlParams, "type");

  const { title, subTitle, listFn, selectedFn, mutateFn } = configOptions[type];

  useEffect(() => {
    setPage("");
  }, [params?.q]);

  const apiSims = useQuery({
    queryKey: [`${type}-listing`, removeEmpty({ ...params, page, dealer_id })],
    queryFn: listFn,
    retry: false,
    keepPreviousData: true,
  });

  const apiSelected = useQuery({
    queryKey: [
      `selected-${type}-listing`,
      { branch_id: branchId, paginated: false },
    ],
    queryFn: selectedFn,
    retry: false,
    keepPreviousData: true,
  });

  const selectedDataTemp = apiSelected?.data?.data?.data || [];

  const selectedData = useMemo(
    () => selectedDataTemp?.map((x) => ({ id: x?.id, ...x?.attributes })),
    [selectedDataTemp],
  );

  const apiBranch = useQuery({
    queryKey: ["branch-info", branchId],
    queryFn: getBranchById,
    retry: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleChangePage = (page) => setPage(page);

  const simsPager =
    apiSims?.data?.data?.meta?.pagination || apiSims?.data?.data?.meta;

  const branchData = _.get(apiBranch, "data.data");

  const { mutate: save, isLoading: isSaving } = useMutation(mutateFn, {
    onSuccess: () => {
      ToastSuccess("Successfully updated branch sources!");
      qc.invalidateQueries(`selected-${type}-listing`);
      qc.invalidateQueries(`${type}-listing`);
    },
    onError: (err) => {
      const errorMessage = (
        <ErrorMessage
          err={err}
          defaultMessage="Failed to update branch sources!"
        />
      );
      ToastError(errorMessage);
      qc.invalidateQueries(`selected-${type}-listing`);
      qc.invalidateQueries(`${type}-listing`);
    },
  });

  const simData = apiSims?.data?.data?.data || [];

  const onSubmit = async () => {
    const defaultMatch = selectedData?.find(
      (y) => y?.sim_id === isDefault?.sim_id,
    );
    const include = sims.filter((x) => {
      const match = selectedData?.find((y) => y?.sim_id === x?.sim_id);
      if (match) return false;
      return true;
    });

    const exclude = selectedData
      .filter((x) => {
        const match = sims?.find((y) => y?.sim_id === x?.sim_id);
        if (match) return false;
        return true;
      })
      .map((x) => _.pick(x, ["id"]));
    try {
      if (!!defaultMatch) {
        await updateBranchDefaultSim({
          ...defaultMatch,
          is_default: true,
          branch_id: branchId,
        });
        save({
          branch_id: branchId,
          include: include
            ?.filter((x) => x?.sim_id !== defaultMatch?.sim_id)
            .map((sim) => ({ ...sim, is_default: false })),
          exclude,
        });
      } else {
        save({
          branch_id: branchId,
          include: _.uniqBy(
            [
              { ...isDefault, is_default: true },
              ...include.map((sim) => ({ ...sim, is_default: false })),
            ],
            "sim_id",
          ),
          exclude,
        });
      }
    } catch (error) {
      // eslint-disable-next-line
      const errorMessage = (
        <ErrorMessage
          err={error}
          defaultMessage="Failed to update branch sources!"
        />
      );
      ToastError(errorMessage);
    }
  };

  const invert = (id) => {
    const up = _.update(selected, id, () => !selected[id]);
    setSelected({ ...up });
  };

  const mapToNewProps = (data) => ({
    mobile_identification_number:
      data?.attributes?.mobile_identification_number,
    sim_id: data?.id,
    sim_type: data?.attributes?.type,
  });

  const onSelect = (value, data) => {
    invert(data.id);
    if (value === 1) {
      setSims((state) => {
        const newState = [...state].filter((sim) => sim?.sim_id !== data?.id);
        if (isDefault?.sim_id === data?.id) {
          setIsDefault(newState?.[0] || null);
        }
        return newState;
      });
      return;
    }
    setSims((state) => [...state, mapToNewProps(data)]);
  };

  const onSelectAll = (value) => {
    const shallowSelected = { ...selected };
    const match = simData.find((x) => x.id === isDefault?.sim_id);
    if (value === 1) {
      setSelected((state) => _.mapValues({ ...state }, () => false));
      setSims((state) =>
        state?.filter((item) => !shallowSelected?.[item?.sim_id]),
      );
      if (match) setIsDefault(null);
      return;
    }
    setSelected((state) => _.mapValues({ ...state }, () => true));
    setSims((state) =>
      _.uniqBy(
        [...state, ...simData.map((data) => mapToNewProps(data))],
        "sim_id",
      ),
    );
  };

  const handleDefault = (data) => {
    const up = _.update(selected, data?.id, () => true);
    setSelected({ ...up });
    setSims((state) => _.uniqBy([...state, mapToNewProps(data)], "sim_id"));
    setIsDefault(mapToNewProps(data));
  };

  const allStatus = useMemo(() => {
    if (apiSims?.isLoading || _.isEmpty(simData)) return 0;
    if (_.every(selected)) return 1;
    return 0;
  }, [selected, apiSims, simData]);

  useEffect(() => {
    if (_.isEmpty(simData)) return;
    setSelected(
      _.mapValues(
        _.mapKeys(simData, "id"),
        ({ id }) => _.some(sims, (e) => e.sim_id === id) || false,
      ),
    );
  }, [simData, sims, selectedData]);

  useEffect(() => {
    if (_.isEmpty(selectedData)) return;
    const defaultSim = selectedData.find((item) => item?.is_default);
    setIsDefault(defaultSim);
    setSims((state) => _.uniqBy([...state, ...selectedData], "sim_id"));
    return () => {
      setSims([]);
    };
  }, [selectedData]);

  const isSaveDisabled = useMemo(() => {
    if (_.isEmpty(sims) || !isDefault) return true;
    return false;
  }, [sims, isDefault]);

  return (
    <div className="container-fluid bg-light-grey">
      <div
        className="bg-white mx-1 my-3 py-3 px-4"
        style={{ minHeight: "90vh" }}
      >
        <div>
          <h6 className="text-primary m-0">
            <Link
              to={{
                pathname: "/manage/branch/",
                state: {
                  branchId,
                },
              }}
              className="text-green"
            >
              <i className="fas fa-arrow-left mr-2 green" /> Back to Branch
              Operations
            </Link>
          </h6>
          <div className="tw-flex tw-justify-between mt-4">
            <div>
              <h5 className="font-weight-bold m-0">{title}</h5>
              <p className="m-0">{subTitle}</p>
            </div>
            <ButtonAsync
              disabled={isSaveDisabled || isSaving}
              type="button"
              className="btn btn-primary ml-1 px-5"
              onClick={onSubmit}
            >
              SAVE CHANGES
            </ButtonAsync>
          </div>
        </div>
        <div className="mt-4 tw-flex tw-gap-6">
          <FormInput
            labelClassName="tw-font-bold"
            readOnly
            label="Branch"
            value={branchData?.attributes?.name || ""}
          />
          <FormInput
            labelClassName="tw-font-bold"
            readOnly
            label="Default MIN"
            value={isDefault?.mobile_identification_number || ""}
          />
        </div>
        <div className="tw-full mt-4 mb-2">
          <Filter
            values={params}
            loading={apiSims.isFetching}
            onChange={setParams}
          />
        </div>
        <div className="tw-flex tw-justify-between py-4">
          <div className="tw-ml-5">
            <FormInputCheckbox
              label="SELECT ALL"
              name="select-all"
              value={allStatus}
              onGetValue={onSelectAll}
              containerClassName="form-check d-flex align-items-center pl-0"
              labelClassName="mb-0 ml-2 tw-font-bold tw-font-gray-600 tw-tracking-wide"
            />
          </div>
          {simsPager?.total && (
            <div className="tw-font-semibold tw-tracking-wide">
              CURRENTLY SELECTED:{" "}
              <span className="tw-font-bold">
                {`${numberToLocale(sims?.length)}/${numberToLocale(
                  simsPager?.total,
                )}`}
              </span>
            </div>
          )}
        </div>
        <div className="tw-pl-20 tw-py-4 tw-w-full tw-bg-gray-200 tw-mb-3 tw-rounded">
          <span className="tw-font-bold">MIN</span>
        </div>
        {apiSims?.isLoading ? (
          [1, 2, 3, 4, 5, 6, 7, 8].map(() => (
            <div className="tw-grid tw-grid-cols-5 tw-gap-3 tw-p-3">
              <div className="tw-col-span-1 tw-animate-pulse tw-bg-gray-300 tw-h-8 tw-w-full tw-rounded" />
              <div className="tw-col-span-1 tw-animate-pulse tw-bg-gray-300 tw-h-8 tw-w-full tw-rounded" />
              <div className="tw-col-span-1 tw-animate-pulse tw-bg-gray-300 tw-h-8 tw-w-full tw-rounded" />
              <div className="tw-col-span-1 tw-animate-pulse tw-bg-gray-300 tw-h-8 tw-w-full tw-rounded" />
              <div className="tw-col-span-1 tw-animate-pulse tw-bg-gray-300 tw-h-8 tw-w-full tw-rounded" />
            </div>
          ))
        ) : _.isEmpty(simData) ? (
          <div className="tw-mt-20">
            <NoRecords />
          </div>
        ) : (
          <div>
            <div className="tw-grid tw-grid-cols-5 tw-gap-4 mb-4">
              {simData?.map((item) => {
                const isSelected = selected?.[item?.id] ? 1 : 0;
                return (
                  <div
                    className={ssv(
                      "tw-p-5 tw-flex tw-items-center tw-gap-2 hover:tw-border-b hover:tw-border-slate-700",
                      isSelected
                        ? "tw-bg-gray-100"
                        : "tw-bg-white tw-border-b tw-border-slate-500",
                    )}
                    key={JSON.stringify(item)}
                    style={{ height: 50 }}
                  >
                    <FormInputCheckbox
                      withLabel={false}
                      value={isSelected}
                      onGetValue={(value) => onSelect(value, item)}
                      containerClassName="form-check d-flex align-items-center pl-0"
                    />
                    <span>
                      {item?.attributes?.mobile_identification_number}
                    </span>
                    {isDefault?.sim_id === item?.id ? (
                      <button
                        type="button"
                        className="pointer-events-none cursor-default"
                      >
                        <i className="fas fa-check-circle text-primary" />
                      </button>
                    ) : (
                      <button type="button" onClick={() => handleDefault(item)}>
                        <i className="fas fa-check-circle text-grey-500" />
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
            <Pagination
              className="d-flex justify-content-between p-2"
              withPage
              onChange={handleChangePage}
              data={simsPager}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignMins;
