export const modalInitState = {
  modalKey: '',
  modalData: {},
};

export default {
  SET_MODAL: (state, { modalKey, modalData }) => ({
    ...state,
    modalKey,
    modalData,
  }),
};
