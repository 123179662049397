import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ModalContext } from 'App';
import GenericTable from '../../common/components/GenericTable';
import * as c from '../constant';
import * as actions from '../actions';
import ViewLoadTransaction from '../../notebook/components/ViewLoadTransaction';

const viewWidth = window.innerWidth;

function LoadTransaction({ getLoadTransaction, list, isLoading, history }) {
  const setModal = React.useContext(ModalContext);

  const handleView = data => {
    setModal('view-eload-modal', {
      amount_deducted: _.get(data, 'attributes.amount_deducted'),
      amount_loaded: _.get(data, 'attributes.amount_loaded'),
      earning: _.get(data, 'attributes.earning'),
      promo_description: _.get(data, 'attributes.promo_description'),
      promo_name: _.get(data, 'attributes.promo_name'),
      remaining_balance: _.get(data, 'attributes.remaining_balance'),
      retailer_id: _.get(data, 'attributes.retailer_id'),
      retailer_min: _.get(data, 'attributes.retailer_min'),
      retailer_name: _.get(data, 'attributes.retailer_name'),
      target_mobile: _.get(data, 'attributes.target_mobile'),
      transaction_reference: _.get(data, 'attributes.transaction_reference'),
      transaction_timestamp: _.get(data, 'attributes.transaction_timestamp'),
    });
  };

  React.useEffect(() => {
    getLoadTransaction();
  }, [getLoadTransaction]);
  return (
    <>
      <GenericTable
        isLoading={isLoading}
        onSelectRow={handleView}
        format={[
          {
            key: item => (
              <small>
                {_.get(item, 'attributes.transaction_timestamp') || '--'}
              </small>
            ),
            label: 'Date',
            width: 'auto',
          },
          {
            key: item => (
              <small>{_.get(item, 'attributes.promo_name') || '--'}</small>
            ),
            label: 'Offer',
            width: 'auto',
          },
          {
            key: item => (
              <small>{_.get(item, 'attributes.retailer_name') || '--'}</small>
            ),
            label: 'Retailer',
            width: 'auto',
          },
           {
            key: item => (
              <small>{_.get(item, 'attributes.branch_name') || '--'}</small>
            ),
            label: 'Branch',
            width: 'auto',
          },
          {
            key: item => (
              <small>
                {_.get(item, 'attributes.transaction_reference') || '--'}
              </small>
            ),
            label: 'Transaction ID',
            width: viewWidth > 1309 ? '30%' : 'auto',
          },
        ]}
        data={list.slice(0, 5)}
      />
      {!_.isEmpty(list) && (
        <button
          onClick={() => history.push('/notebook/load-subscriber-history')}
          type="button"
          className="btn btn-link float-right my-1"
        >
          View All
        </button>
      )}
      <ViewLoadTransaction />
    </>
  );
}

LoadTransaction.propTypes = {
  getLoadTransaction: PropTypes.func.isRequired,
  list: PropTypes.instanceOf(Array).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ api }) => ({
  list: _.get(api, `${c.GET_LOAD_TRANSACTION}.list`) || [],
  isLoading: api.loading.indexOf(c.GET_LOAD_TRANSACTION) > -1,
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(LoadTransaction);
