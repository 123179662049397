import _ from 'lodash';
import store from '../store';
import * as c from './constants';

export const apiPost = (url, data, onSuccess, onError) => ({
  type: c.API_POST,
  url,
  data,
  onSuccess,
  onError,
});

export const apiGet = (url, params, onSuccess, onError) => ({
  type: c.API_GET,
  url,
  params,
  onSuccess,
  onError,
});

export const apiPut = (url, data, onSuccess, onError) => ({
  type: c.API_PUT,
  url,
  data,
  onSuccess,
  onError,
});

export const setItemDelay = (constant, data, callback) => ({
  type: c.GOT_ITEM_DELAY,
  key: constant,
  data,
  callback,
});

export const setListDelay = (constant, data, callback) => ({
  type: c.GOT_LIST_DELAY,
  key: constant,
  data,
  pager: {},
  callback,
});

export const setItem = (constant, data) => ({
  type: c.GOT_ITEM,
  key: constant,
  data,
});

export const appendItem = (constant, data) => ({
  type: c.APPEND_ITEM,
  key: constant,
  data,
});

export const setList = (constant, data) => ({
  type: c.GOT_LIST,
  key: constant,
  data,
  pager: {},
});

export const list = (constant, url, params, callback, cacheKey) => ({
  type: c.GET_LIST,
  key: constant,
  url,
  params,
  callback,
  cacheKey,
});

export const listEternal = (constant, url, params, callback, cacheKey) => ({
  type: c.GET_LIST_ETERNAL,
  key: constant,
  url,
  params,
  callback,
  cacheKey,
});

export const loadIncludedToList = (items, included) =>
  items.map(x => {
    const rowIncluded = {};
    _.forOwn(x.relationships, (v, k) => {
      rowIncluded[k] = Array.isArray(v.data)
        ? v.data.map(
            z =>
              included.find(
                y => y.type === _.get(z, 'type') && y.id === _.get(z, 'id')
              ) || {}
          )
        : included.find(
            y =>
              y.type === _.get(v, 'data.type') && y.id === _.get(v, 'data.id')
          ) || {};
    });
    return { ...x, included: rowIncluded };
  });

export const toList = constant => res => ({
  type: c.GOT_LIST,
  key: constant,
  data: loadIncludedToList(
    _.get(res, 'response.data') || [],
    _.get(res, 'response.included') || []
  ),
  pager: _.get(res, 'response.meta.pagination') || {},
  res: _.get(res, 'response') || {},
});

export const toListEternal = (constant, reset) => res => ({
  type: c.GOT_LIST_ETERNAL,
  key: constant,
  data: loadIncludedToList(
    _.get(res, 'response.data') || [],
    _.get(res, 'response.included') || []
  ),
  pager: _.get(res, 'response.meta.pagination') || {},
  res: _.get(res, 'response') || {},
  reset,
});

export const clearError = constant => ({
  type: c.CLEAR_ERROR,
  key: constant,
});

export const listError = constant => error =>
  new Promise(resolve => {
    store.dispatch({
      type: c.GOT_LIST,
      key: constant,
      data: [],
      pager: {},
    });
    setTimeout(() => {
      store.dispatch(clearError(constant));
    }, 2000);
    resolve({
      type: 'ON_ERROR',
      key: constant,
      error,
    });
  });

export const item = (constant, url, params, callback, transform, cacheKey) => ({
  type: c.GET_ITEM,
  key: constant,
  url,
  params,
  callback,
  cacheKey,
  transform,
});

export const flatIncludedToItem = (x, included) => {
  const rowIncluded = {};
  _.forOwn(x.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map(
          z =>
            included.find(
              y => y.type === _.get(z, 'type') && y.id === _.get(z, 'id')
            ) || {}
        )
      : included.find(
          y => y.type === _.get(v, 'data.type') && y.id === _.get(v, 'data.id')
        ) || {};
  });
  return { ...x, included: rowIncluded };
};

export const toItem = (constant, transform) => res => ({
  type: c.GOT_ITEM,
  key: constant,
  data:
    typeof transform === 'function'
      ? transform(_.get(res, 'response') || {})
      : flatIncludedToItem(
          _.get(res, 'response.data') || {},
          _.get(res, 'response.included') || []
        ),
  res: _.get(res, 'response') || {},
});

export const itemError = constant => error =>
  new Promise(resolve => {
    store.dispatch({
      type: c.GOT_ITEM,
      key: constant,
      data: {},
    });
    setTimeout(() => {
      store.dispatch(clearError(constant));
    }, c.ERROR_TIMEOUT);
    resolve({
      type: 'ON_ERROR',
      key: constant,
      error,
    });
  });

export const create = (
  constant,
  url,
  payload,
  callback,
  options,
  errCallback
) => ({
  type: c.CREATE_ITEM,
  key: constant,
  url,
  payload,
  callback,
  options,
  errCallback,
});

export const update = (
  constant,
  url,
  payload,
  callback,
  options,
  errCallback
) => ({
  type: c.UPDATE_ITEM,
  key: constant,
  url,
  payload,
  callback,
  options,
  errCallback,
});

export const remove = (
  constant,
  url,
  callback,
  options,
  payload,
  errCallback
) => ({
  type: c.DELETE_ITEM,
  key: constant,
  url,
  callback,
  options,
  payload,
  errCallback,
});

export const upload = (constant, url, formData, callback) => ({
  type: c.UPLOAD_FORM_DATA,
  key: constant,
  url,
  formData,
  callback,
});

export const download = (constant, url, fileName, callback) => ({
  type: c.DOWNLOAD,
  key: constant,
  url,
  fileName,
  callback,
});

// export const withMessage = (doneConstant, default_message = 'Success!') => ({ response }) => {
//   const { message, data } = response;
//   if (typeof default_message !== 'boolean') {
//     ToastSuccess(_.get(data, 'message') || message || default_message);
//   }
//   return {
//     type: c.CREATED_ITEM,
//     key: doneConstant,
//     data: response,
//   };
// };

export const withMessage = doneConstant => ({ response }) => ({
  type: c.CREATED_ITEM,
  key: doneConstant,
  data: response,
});
