import React, { useMemo, useState, useEffect } from 'react';
import _ from 'lodash';
import {
  useRouteMatch,
  useHistory,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import * as session from 'session';
import { ToastSuccess } from 'modules/common/components/Toast';
import { useLoading } from 'modules/ui/hooks';
import { removeEmpty } from 'helper';

import ButtonAsync from 'components/Button';
import CompanyInformationPage from './company-information-page/CompanyInformationPage';
import AccountInformationPage from './account-information-page/AccountInformationPage';
import SupportingDocumentsPage from './supporting-documents-page/SupportingDocumentsPage';

import * as c from '../constants';
import * as actions from '../actions';

const ViewUpdateContainer = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const tabs_slug = useRouteMatch(`${match.url}/:slug?`);

  const userDetails = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_USER_ACCESS_DETAILS}.item`)
  );
  const companyInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_COMPANY_INFORMATION}.item`, {})
  );
  const deliveryAddressInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_DELIVERY_ADDRESS_INFORMATION}.item.attributes`, {})
  );
  const moaInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_MOA_SIGNATORY_INFORMATION}.item`, {})
  );
  const requiredDocumentsImages = useSelector(({ api }) =>
    _.get(api, `${c.GET_REQUIRED_DOCUMENTS_IMAGES_LIST}.list`, [])
  );
  const channelList = useSelector(({ api }) =>
    _.get(api, `${c.GET_CHANNEL_LIST}.list`, [])
  );

  const [pending, setPending] = useState(false);

  const actionType = session.get('type') || '';

  const isLoading = useLoading(c.SHOW_USER_ACCESS_DETAILS);
  const applicationStatus = _.get(userDetails, 'attributes.application_status');
  const on_going_application_id = _.get(
    userDetails,
    'attributes.on_going_application_id'
  );
  const company_id = _.get(companyInfo, 'id');
  const companyName = _.get(userDetails, 'attributes.company_name');
  const applicationCategoryUuid = _.get(
    userDetails,
    'attributes.account_type_category_uuid',
    ''
  );

  const isAllowed = useMemo(() => {
    const status = _.get(userDetails, 'attributes.application_status');
    return status === null || status === 'returned';
  }, [userDetails]);

  const dealerAuthorizationInfo = useMemo(
    () =>
      Object.keys(moaInfo)
        .map(item => moaInfo[item])
        .filter(item => !_.isEmpty(item))
        .filter(
          ({ attributes }) => attributes._type === 'DISTRIBUTOR_SIGNATORY'
        ),
    [moaInfo]
  );

  const isComplete = useMemo(
    () =>
      _.isEmpty(_.get(companyInfo, 'attributes')) ||
      _.isEmpty(_.get(moaInfo[0], 'attributes')) ||
      _.isEmpty(deliveryAddressInfo) ||
      _.isEmpty(dealerAuthorizationInfo),
    [companyInfo, deliveryAddressInfo, moaInfo, dealerAuthorizationInfo]
  );

  const { slug } = _.get(tabs_slug, 'params');
  const { dealer_id } = _.get(match, 'params');

  const handleClick = () => {
    setPending(true);

    const payload = {
      dealer_id,
      company_id: applicationStatus === 'returned' ? '' : company_id,
      status: applicationStatus === 'returned' ? 'resubmitted' : 'on-going',
      application_type_name:
        applicationStatus === 'returned' ? '' : 'accreditation',
    };

    const successCallback = () => {
      setPending(false);
      history.push('/partners/applications');
      ToastSuccess(
        `${companyName} Application Request has been ${
          applicationStatus === 'returned' ? 'resubmitted' : 'submitted'
        } for approval`
      );
    };

    const errCallback = () => setPending(false);

    if (applicationStatus === 'returned')
      dispatch(
        actions.updateApplication(
          on_going_application_id,
          removeEmpty(payload),
          successCallback,
          errCallback
        )
      );
    else
      dispatch(
        actions.addApplication(
          removeEmpty(payload),
          successCallback,
          errCallback
        )
      );
  };

  const tabs = (buttonSlug, label) => (
    <button
      className={`tabs__container-button font-weight-bold px-3 text-xs py-2 ${
        slug === buttonSlug ? 'active' : ''
      }`}
      onClick={() => history.push(`${match.url}/${buttonSlug}`)}
      type="button"
      disabled={isComplete && buttonSlug === 'supporting-documents'}
    >
      {label}
    </button>
  );

  useEffect(() => {
    dispatch(actions.showUserAccessDetails(dealer_id));
    dispatch(actions.showCompanyInformation(dealer_id));
  }, [slug, dealer_id]);

  useEffect(() => {
    dispatch(actions.showDeliveryAddressInformation(dealer_id));
  }, [dealer_id]);

  useEffect(() => {
    return () => {
      dispatch(actions.removeMoaInfo());
      dispatch(actions.removeUserDetails());
      dispatch(actions.removeCompany());
      dispatch(actions.removeDeliveryAddress());
      dispatch(actions.removeChannelInfo());
      dispatch(actions.removeSupDocs());
      session.removeItem('type');
    };
  }, []);

  const isEqual = useMemo(() => {
    const channelId = channelList.map(({ id }) => id);
    const docId = requiredDocumentsImages.map(({ attributes }) =>
      _.get(attributes, 'requirement_id')
    );
    return channelId.every(val => docId.includes(val));
  }, [channelList, requiredDocumentsImages]);

  return (
    <>
      <div className="scroll-lime partners partners__dealers-information partners__bg-light-gray px-4 pb-4 position-relative">
        {applicationStatus === 'done' && (
          <div className="pt-4 d-flex justify-content-between">
            <h5 className="m-0 h5" style={{ fontSize: '1.1rem' }}>
              <strong>
                {actionType === 'details' ? 'View' : 'Update'} Dealer details{' '}
              </strong>
            </h5>

            <h5 className="m-0 h5" style={{ fontSize: '1.1rem' }}>
              <strong>
                Account Status:
                <span className="text-success"> On-Boarded</span>
              </strong>
            </h5>
          </div>
        )}

        {applicationStatus !== 'done' && (
          <div className="pt-4">
            <h5 className="h5 m-0 mb-2" style={{ fontSize: '1.1rem' }}>
              <strong>
                {applicationStatus !== 'returned' && 'New'} Dealer Application
              </strong>
            </h5>

            <h6
              className="h6 m-0 text-capitalize"
              style={{ fontSize: '.9rem' }}
            >
              Application Status:{' '}
              {applicationStatus !== null ? applicationStatus : 'New'}
            </h6>
          </div>
        )}

        <ul
          className="tabs-container d-flex mb-0 p-0 mt-3 w-100 position-sticky"
          style={{ top: 0, left: 0, zIndex: 1 }}
        >
          {tabs('company-information', 'Company Information')}

          {applicationStatus === 'done' &&
            tabs('account-information', 'Account Information')}

          {tabs('supporting-documents', 'Supporting Documents')}

          {isAllowed && (
            <div>
              {slug === 'company-information' ? (
                <button
                  type="button"
                  className={`btn text-sm py-2 text-light-0 px-5 position-absolute d-flex align-items-center
													 ${!isComplete ? 'btn-success' : 'bg-grey-600'}`}
                  style={{
                    right: 0,
                    marginTop: '-4rem',
                  }}
                  disabled={isLoading || isComplete}
                  onClick={() =>
                    history.push(`${match.url}/supporting-documents`)
                  }
                >
                  <span>Next</span> <i className="fa fa-arrow-right ml-2" />
                </button>
              ) : (
                <>
                  {applicationCategoryUuid ===
                  _.get(c, 'category_uuid.smmt') ? (
                    <ButtonAsync
                      type="submit"
                      className={`btn text-sm py-2 text-light-0 px-5 position-absolute d-flex align-items-center
												 ${!isComplete ? 'btn-success' : 'bg-grey-600'}`}
                      style={{
                        right: 0,
                        marginTop: '-4rem',
                      }}
                      loading={pending}
                      disabled={isLoading || isComplete}
                      onClick={handleClick}
                    >
                      {applicationStatus === 'returned' ? 'Resubmit' : 'Submit'}
                    </ButtonAsync>
                  ) : (
                    <ButtonAsync
                      type="submit"
                      className={`btn text-sm py-2 text-light-0 px-5 position-absolute d-flex align-items-center
												 ${!isComplete ? 'btn-success' : 'bg-grey-600'}`}
                      style={{
                        right: 0,
                        marginTop: '-4rem',
                      }}
                      loading={pending}
                      disabled={
                        isLoading ||
                        isComplete ||
                        !isEqual ||
                        channelList.length === 0
                      }
                      onClick={handleClick}
                    >
                      {applicationStatus === 'returned' ? 'Resubmit' : 'Submit'}
                    </ButtonAsync>
                  )}
                </>
              )}
            </div>
          )}
        </ul>

        <Switch>
          <Route
            path={`${match.url}/company-information`}
            render={props => (
              <CompanyInformationPage dealer_id={dealer_id} {...props} />
            )}
          />

          {applicationStatus === 'done' && (
            <Route
              path={`${match.url}/account-information`}
              render={props => (
                <AccountInformationPage dealer_id={dealer_id} {...props} />
              )}
            />
          )}

          <Route
            path={`${match.url}/supporting-documents`}
            render={props => (
              <SupportingDocumentsPage
                isSubmitFinish={pending}
                dealer_id={dealer_id}
                status={applicationStatus}
                {...props}
              />
            )}
          />
        </Switch>
      </div>

      {!slug && <Redirect to={`${match.url}/${_.get(c.tabsInfo, '0.slug')}`} />}

      {applicationStatus !== 'done' && slug === 'account-information' && (
        <Redirect to={`${match.url}/${_.get(c.tabsInfo, '0.slug')}`} />
      )}
    </>
  );
};

export default React.memo(ViewUpdateContainer);
