export const SHOW_RETAILER = "DASHBOARD/show_retailer";
export const GET_BENTA_KITA = "DASHBOARD/get_benta_kita";
export const GET_BENTA_KITA_DEALER = "DASHBOARD/get_benta_kita_dealer";
export const GET_LOAD_TRANSACTION = "DASHBOARD/get_load_transaction";
export const GET_BILLS_TRANSACTION = "DASHBOARD/get_bills_transaction";
export const INQUIRE_BALANCE = "DASHBOARD/inquire_balance";
export const INQUIRE_DEALER_THRESHOLD = "DASHBOARD/inquire_dealer_threshold";
export const INQUIRE_DEALER_BALANCE = "DASHBOARD/inquire_dealer_balance";
export const GET_BRANCH = "DASHBOARD/get_branch";
export const GET_DEALER_SOURCES = "LWM/get_dealer_sources";
export const GET_RETAILER_SOURCES = "LWM/get_retailer_sources";
export const GET_RETAILER_SIMS = "DASHBOARD/get_retailer_sims";
export const GET_DEALER_DASHBOARD_TOPS = "DASHBOARD/get_dealer_dashboard_tops";
export const FORM_DATA = {};
export const RETAILER_USERS = {
  frontliner: "Frontline",
  "branch-admin": "Branch Admin",
  "kapartner-user": "Ka-Partner User",
  "sub-dealer-agent": "Sub-dealer Agent",
};
