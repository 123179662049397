import React, { useState, useContext, useMemo } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { removeEmpty } from "helper";
import withModal from "modules/common/hoc/withModal";
import { ModalContext } from "App";
import { ToastSuccess } from "modules/common/components/Toast";
import { useLoading } from "modules/ui/hooks";
import ButtonAsync from "components/Button";
import { USER_PROFILE } from "modules/auth/constants";
import TemporaryAccountForm from "../forms/TemporaryAccountForm";
import AccountInfoForm from "../forms/AccountInfoForm";

import * as actions from "../action";
import * as c from "../constant";

const temporaryFormFields = {
  email: "",
  mobile: "",
};

const form = {
  first_name: "",
  last_name: "",
  username: "",
  role_id: "",
};

function FillupCorpInfo({ createCorpUsers, getRoles, roleList, profile }) {
  const setModal = useContext(ModalContext);
  const [temporaryForm, setTemporaryForm] = useState(temporaryFormFields);
  const [formData, setformData] = useState(form);

  const setFilter = useSelector(({ modal }) =>
    _.get(modal, "modalData.setFilter")
  );

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleChangeInput = (value) => {
    setTemporaryForm({
      ...temporaryForm,
      ...value,
    });
  };

  const handleChange = (key) => ({ target }) => {
    setformData({
      ...formData,
      [key]: target.value,
    });
  };

  const checkInput = useMemo(() => {
    const { first_name, last_name, username, role_id } = formData;
    const { email, mobile } = temporaryForm;
    return (
      _.isEmpty(first_name) ||
      _.isEmpty(last_name) ||
      _.isEmpty(username) ||
      _.isEmpty(role_id) ||
      _.isEmpty(email) ||
      _.size(mobile) !== 13
    );
  }, [formData, temporaryForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const temp = { ...formData, ...temporaryForm };
    Object.keys(temp).forEach((k) => {
      temp[k] = temp[k].trim();
    });
    createCorpUsers(temp, () => {
      ToastSuccess("Successfully created user account.");
      setFilter(removeEmpty(c.caSdaInitState));
      setModal({ isOpen: false });
    });
  };

  const role = _.head(profile?.roles);

  React.useEffect(() => {
    const payload = {
      role_guard_name: "",
    };
    if (role.includes("smmt")) payload.role_guard_name = "smmt-dealer-user";
    else if (role === "super-user-b2b" || role.includes("-b2b"))
      payload.role_guard_name = "b2b-dealer-user";
    else if (role === "super-user" || role.includes("rd"))
      payload.role_guard_name = "rd-dealer-user";
    else if (role === "super-user-ka" || role.includes("-ka"))
      payload.role_guard_name = "ka-dealer-user";
    else if (role.includes("-ska")) payload.role_guard_name = "ska-dealer-user";
    else if (role === "super-user-non-migrated")
      payload.role_guard_name = "non-migrated-dealer-user";
    else if (role === "branch-admin-non-migrated") {
      payload.user_guard_name = "non-migrated-retailer-user";
      payload.role_guard_name = "non-migrated-retailer-user";
    } else payload.role_guard_name = "dealer-user";
    getRoles(payload);
  }, [getRoles, role]);

  return (
    <form onSubmit={handleSubmit} className="container-fluid">
      <AccountInfoForm
        onChange={handleChange}
        formData={formData}
        roleList={roleList}
        type="create"
      />
      <TemporaryAccountForm
        onChange={handleChangeInput}
        temporaryForm={temporaryForm}
        type="create"
      />
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold mr-2"
            >
              Cancel
            </button>
            <ButtonAsync
              type="submit"
              loading={useLoading(c.CREATE_CORP_ADMIN)}
              disabled={checkInput}
            >
              Save
            </ButtonAsync>
          </div>
        </div>
      </div>
    </form>
  );
}

FillupCorpInfo.propTypes = {
  createCorpUsers: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  roleList: PropTypes.instanceOf(Array).isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ api }) => ({
  roleList: _.get(api, `${c.GET_ROLES}.list`) || [],
  profile: _.get(api, `${USER_PROFILE}.item`) || {},
});

const enhance = _.flowRight([
  withModal("fill-up-corp-info", {
    title: "Create Account",
    size: "modal-lg",
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(FillupCorpInfo);
