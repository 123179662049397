import React, { useState, Fragment, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ToastSuccess } from 'modules/common/components/Toast';
import { formatNumber } from 'helper';
import TransactionDetails from '../components/TransactionDetails';
import TaxDetails from '../components/TaxDetails';
import PurchaseOrders from '../components/PurchaseOrders';
import MobilePayment from '../forms/MobilePayment';
import OnlineCash from '../forms/OnlineCash';
import CreditMemo from '../forms/CreditMemo';
import * as actions from '../actions';
import * as c from '../constant';

const paymentData = [
  // { id: 1, name: 'mobile', title: 'Mobile Payment' },
  { id: 1, name: 'online', title: 'Online Cash' },
  // { id: 3, name: 'credit_memo', title: 'Credit Memo' },
  // { id: 4, name: 'credit_tax', title: 'Credit Tax' },
];

const CreditData = [
  { id: 1, name: 'B1', ref: 'B123', amount: 'P2,290.00', date: 'Dec 12, 2020' },
  {
    id: 2,
    name: 'B2',
    ref: 'B456',
    amount: 'P1,540.00',
    date: 'March 23, 2020',
  },
];

function OrderDetails({
  profile,
  cartDetails,
  getCart,
  cartList,
  removeItemById,
  createOrder,
  getDealerProfile,
  dealerProfile,
}) {
  const [payment, setPayment] = useState(false);
  const [method, setPaymentMethod] = useState('online');
  const [credit, setCredit] = useState('mobile');
  const [formData, setFormData] = useState({});
  const history = useHistory();

  const parseTaxFirst = _.get(cartDetails, 'subtotal', '').replace('₱', '');
  const parseTaxSecond = parseTaxFirst.replace(',', '');
  const parseTaxThird = parseTaxSecond.replace('.00', '');
  const parseTaxFinal = parseTaxThird * 0.12;

  const parseCwtFinal = (parseTaxThird / 112) * 1;
  const parseTotalWithCwt = Math.round(parseCwtFinal) - parseTaxThird;
  const finalTotalWithCwt = `${parseTotalWithCwt}`.replace('-', '');

  const handleTogglePayment = value => e => {
    e.preventDefault();
    setPayment(value);
  };

  const handleCheckedMethod = value => e => {
    e.preventDefault();
    setPaymentMethod(value);
  };

  const handleCheckedCredit = value => e => {
    e.preventDefault();
    setCredit(value);
  };

  const handleCheckedCreditTax = (key, value) => e => {
    e.preventDefault();
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleSelected = key => e => {
    setFormData({
      ...formData,
      [key]: e.target.value || '',
    });
  };

  const handleChangeInput = key => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value,
    });
  };

  const handleCreateOrder = e => {
    e.preventDefault();
    const payload = {
      dealer_code: _.get(dealerProfile, 'attributes.code'),
      dealer_name: _.get(dealerProfile, 'attributes.full_name'),
      dealer_area: _.get(dealerProfile, 'attributes.area_name'),
      cart_id: cartDetails.cart_id,
      po_number: formData.po_number,
      sap: '454564564-456456-456456',
      payment_type: 'cash',
      with_cwt: formData.with_cwt,
    };
    createOrder(payload, () => {
      ToastSuccess('Your order has been processed.');
      history.push('/purchase-order');
      getCart();
    });
  };

  const renderForm = args => {
    if (args === 'mobile') {
      return <MobilePayment onSelected={handleSelected} formData={formData} />;
    }
    if (args === 'online') {
      return (
        <OnlineCash
          onChangeInput={handleChangeInput}
          formData={formData}
          onSelected={handleSelected}
        />
      );
    }
    if (args === 'credit_memo') {
      return (
        <CreditMemo
          credit={credit}
          onHandleCredit={handleCheckedCredit}
          CreditData={CreditData}
          onChangeInput={handleChangeInput}
          formData={formData}
        />
      );
    }
    if (args === 'credit_tax') {
      return (
        <CreditMemo
          credit={credit}
          onHandleCredit={handleCheckedCredit}
          CreditData={CreditData}
          onChangeInput={handleChangeInput}
          formData={formData}
        />
      );
    }
    return null;
  };

  useEffect(() => {
    getCart();
    getDealerProfile(profile.id);
  }, [getDealerProfile, profile, getCart]);

  return (
    <div className="container-fluid">
      <form onSubmit={handleCreateOrder}>
        <div className="row">
          <div className="col-6">
            <TransactionDetails
              profile={dealerProfile}
              handleChangeInput={handleChangeInput}
              formData={formData}
            />
          </div>
          <div className="col-6 pl-0">
            <TaxDetails
              onTooglePayment={handleTogglePayment}
              payment={payment}
              cartDetails={cartDetails}
              handleCheckedCreditTax={handleCheckedCreditTax}
              formData={formData}
              tax={parseTaxFinal}
              cwt={parseCwtFinal}
            />
          </div>
        </div>
        {!payment ? (
          <div className="card mt-4">
            <PurchaseOrders
              data={cartList}
              removeItemById={removeItemById}
              getCart={getCart}
            />
          </div>
        ) : (
          <div className="card mt-4">
            <div className="card-body shadow-sm">
              <div className="row">
                <div className="col-6">
                  <h5 className="m-0 font-weight-bold">
                    Total PO Amount: &nbsp;
                    <span className="text-success">{cartDetails.subtotal}</span>
                  </h5>
                  <div className="row mt-3">
                    <div className="col-6">
                      <span className="font-weight-bold">
                        Select Mode of Payment:
                      </span>
                      {paymentData.map(x => (
                        <div className="form-group my-2" key={x.id}>
                          <a
                            href="/"
                            onClick={handleCheckedMethod(x.name)}
                            className="custom-control custom-checkbox pl-0"
                          >
                            {x.name === method ? (
                              <i className="fas fa-check-circle text-success mr-2" />
                            ) : (
                              <i className="fas fa-circle text-muted mr-2" />
                            )}
                            <label>{x.title}</label>
                          </a>
                        </div>
                      ))}
                      {/* <button type="button" className="btn btn-success">Add Payment</button> */}
                    </div>
                    <div className="col-6 p-0">{renderForm(method)}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row px-2">
                    <h5 className="m-0 font-weight-bold">Summary</h5>
                    <h5 className="ml-auto m-0 font-weight-bold">Amount</h5>
                  </div>
                  <div className="row px-2 mt-3">
                    <div className="col-6 p-0">
                      <div className="border-custom-top border-custom-bottom">
                        <div className="py-3">
                          <span className="text-muted">Mobile Payment</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 p-0">
                      <div className="border-custom-top border-custom-bottom">
                        <div className="py-3 text-right">
                          <span className="text-muted">
                            {cartDetails.subtotal || '00'}
                          </span>
                        </div>
                      </div>
                    </div>
                    {formData.with_cwt === 1 && (
                      <Fragment>
                        <div className="col-6 p-0">
                          <div className="border-custom-top border-custom-bottom">
                            <div className="py-3">
                              <span className="text-muted">CWT</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 p-0">
                          <div className="border-custom-top border-custom-bottom">
                            <div className="py-3 text-right">
                              <span className="text-muted">
                                ₱
                                {formatNumber(Math.round(parseCwtFinal)) ||
                                  '00'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                    {method === 'credit_tax' && (
                      <Fragment>
                        <div className="col-6 p-0">
                          <div className="border-custom-bottom">
                            <div className="py-3">
                              <span className="text-muted">Credit Tax</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 p-0">
                          <div className="border-custom-bottom">
                            <div className="py-3 text-right">
                              <span className="text-muted">P4, 500.00</span>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                    <div className="col-3 p-0">
                      <div className="pt-3">
                        <h3 className="font-weight-bold m-0">Total</h3>
                      </div>
                    </div>
                    <div className="col-9 p-0">
                      <div className="py-3 text-right">
                        {formData.with_cwt === 1 ? (
                          <h3 className="text-success m-0 font-weight-bold">
                            ₱{formatNumber(finalTotalWithCwt) || '00'}
                          </h3>
                        ) : (
                          <h3 className="text-success m-0 font-weight-bold">
                            {formatNumber(cartDetails.subtotal) || '00'}
                          </h3>
                        )}
                        <div>
                          <small className="text-warning font-weight-bold">
                            Note: Amount in not sufficient for complete payment
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row float-right py-4">
                    {method === 'online' ||
                      (method === 'credit_memo' && (
                        <button className="btn btn-danger mr-3" type="button">
                          Delete Order
                        </button>
                      ))}
                    <button className="btn btn-warning" type="submit">
                      Confirm Order
                    </button>
                    {method === 'credit_tax' && (
                      <button className="btn btn-success ml-3" type="button">
                        Confirm
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

OrderDetails.propTypes = {
  profile: PropTypes.instanceOf(Object).isRequired,
  cartDetails: PropTypes.instanceOf(Object).isRequired,
  dealerProfile: PropTypes.instanceOf(Object).isRequired,
  cartList: PropTypes.instanceOf(Array).isRequired,
  getCart: PropTypes.func.isRequired,
  removeItemById: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,
  getDealerProfile: PropTypes.func.isRequired,
};

const mapStateToProps = ({ api }) => ({
  profile: _.get(api, 'AUTH/user_profile.item') || {},
  cartDetails: _.get(api, `${c.GET_CART}.res`) || {},
  cartList: _.get(api, `${c.GET_CART}.list`) || [],
  dealerProfile: _.get(api, `${c.GET_DEALER_PROFILE}.res.data`) || {},
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(OrderDetails);
