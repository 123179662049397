import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

function IconTooltip({ icon, text, id, className, toUpperCase, ...rest }) {
  return (
    <>
      <i data-tip={text} className={icon} data-for={id} />
      <ReactTooltip
        id={id}
        key={id}
        place="bottom"
        effect="solid"
        backgroundColor="rgb(96, 187, 69)"
        className={`${className}tooltip`}
        html
        {...rest}
      />
    </>
  );
}

IconTooltip.defaultProps = {
  className: '',
  toUpperCase: false,
};

IconTooltip.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  toUpperCase: PropTypes.bool,
};

export default IconTooltip;
