import React from 'react';
import Slider from 'react-slick';
import BannerAdsOne from 'assets/images/ads/banner-ads-1.png';
import BannerAdsTwo from 'assets/images/ads/banner-ads-2.png';
import BannerAdsThree from 'assets/images/ads/banner-ads-3.png';
import BannerAdsFour from 'assets/images/ads/banner-ads-4.png';

const settings = {
  className: 'slider variable-width',
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  autoplay: true,
  speed: 5000,
  autoplaySpeed: 5000,
  cssEase: 'linear',
};

const MiniBanner = () => {
  return (
    <div className="mini-banner">
      <div className="ads-section">
        <Slider {...settings}>
          <a href="https://smart.com.ph/" className="mr-2" target="_black">
            <img className="ads-banner-mini-2" src={BannerAdsOne} alt="Ads 1" />
          </a>
          <a href="https://smart.com.ph/" className="mr-2" target="_black">
            <img className="ads-banner-mini-2" src={BannerAdsTwo} alt="Ads 2" />
          </a>
          <a href="https://smart.com.ph/" className="mr-2" target="_black">
            <img
              className="ads-banner-mini-2"
              src={BannerAdsThree}
              alt="Ads 3"
            />
          </a>
          <a href="https://smart.com.ph/" className="mr-2" target="_black">
            <img
              className="ads-banner-mini-2"
              src={BannerAdsFour}
              alt="Ads 4"
            />
          </a>
        </Slider>
      </div>
    </div>
  );
};

export default MiniBanner;
