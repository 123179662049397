import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M15.293 3.167l-1.46-1.46L8.5 7.04 3.167 1.707l-1.46 1.46L7.04 8.5l-5.333 5.333 1.46 1.46L8.5 9.96l5.333 5.333 1.46-1.46L9.96 8.5l5.333-5.333z"
        fill="#999"
      />
    </svg>
  )
}

const MemoSvgComponent = React.memo(SvgComponent)
export default MemoSvgComponent
