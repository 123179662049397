import React, { useMemo } from "react";
import withModal from "modules/common/hoc/withModal";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { inquireRetailerBalance } from "app/load-wallet/actions";

const ViewBalance = ({ min }) => {
  const apiRetailerBalance = useQuery({
    queryKey: ["inquire-retailer-sim-balance", min],
    queryFn: inquireRetailerBalance,
    retry: false,
    keepPreviousData: true,
  });

  const { isFetching, data } = apiRetailerBalance;

  const raw = data?.data?.current_balance;

  const balance = useMemo(() => {
    if (raw)
      return Number(raw).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    return "0.00";
  }, [raw]);

  return (
    <div className="container-fluid">
      <div className="text-center font-weight-bold">Remaining Balance</div>
      <h4 className="mt-4 mb-3 text-center font-weight-bold text-success">
        {!isFetching ? `₱ ${balance}` : <small>Please wait...</small>}
      </h4>
    </div>
  );
};

ViewBalance.propTypes = {
  min: PropTypes.string,
};

ViewBalance.defaultProps = {
  min: "",
};

const modalKey = "view-retailer-sim-balance";
const modalConfig = {
  title: "Balance",
  size: "modal-sm modal-center",
};

export default withModal(modalKey, modalConfig)(ViewBalance);
