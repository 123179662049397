export const HEADER_NAMES = {
  "sub-dealer": "Dealer",
  "sub-dealer-ska": "Dealer",
  "super-user": "Dealer",
  "super-user-ka": "Dealer",
  "super-user-non-migrated": "Dealer",
  "super-user-b2b": "Dealer",
  "corporate-admin": "Corp Admin",
  "corporate-admin-non-migrated": "Corp Admin",
  "corporate-admin-ska": "Corp Admin",
  "corporate-admin-rd": "Corp Admin",
  "corporate-admin-ka": "Corp Admin",
  "corporate-admin-smmt": "Corp Admin",
  "corporate-admin-b2b": "Corp Admin",
  frontliner: "Frontliner",
  "branch-admin": "Branch Admin",
  "frontliner-non-migrated": "Frontliner",
  "branch-admin-non-migrated": "Branch Admin",
  "kapartner-user": "Kapartner-user",
  smmt: "SMMT",
};

export const SHOW_USER_COMPANY_INFO = "SHOW_USER_COMPANY_INFO";

export default HEADER_NAMES;
