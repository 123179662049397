import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import ReactInputMask from "react-input-mask";
import { ModalContext } from "App";
import { removeEmpty } from "helper";
import Button from "components/Button";
import WithTimerButton from "components/WithTimerButton";
import withModal from "modules/common/hoc/withModal";
import { useLoading } from "modules/ui/hooks";
import * as actions from "../actions";
import * as c from "../constants";

function ConfirmOtp() {
  const dispatch = useDispatch();
  const initialData = useSelector(({ modal }) => _.get(modal, "modalData"));
  const [formData, setFormData] = useState({ ...initialData, otp: "" });
  const setModal = useContext(ModalContext);

  const isVerifying = useLoading(c.OTP_VERIFY);
  const isPermissions = useLoading(c.AUTH_PERMISSIONS);

  const emailDetails = formData?.email?.detail;

  const isLoading = isVerifying || isPermissions;

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const credentials = {
      ...initialData,
      ...formData,
      email: "",
      sms: "",
      clear: () => setModal({ isOpen: false }),
    };
    dispatch(actions.verifyOtp(removeEmpty(credentials)));
  };

  const handleClickResend = (e) => {
    e.preventDefault();
    dispatch(
      actions.loginOTP(removeEmpty({ ...initialData, email: "", sms: "" }))
    );
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="prepend-form-right">
            <label className="form-label text-primary text-center">
              Enter the one-time-password sent to your email,{" "}
              {emailDetails?.email_start}***{emailDetails?.email_end}, and
              mobile number +63********{formData?.sms?.detail?.mobile_end}
            </label>
            <ReactInputMask
              autoFocus
              type="text"
              placeholder="6 Digit OTP"
              mask="999999"
              maskChar=""
              className="form-control form-custom form-control_bottom"
              name="otp"
              required
              onChange={handleChangeInput}
              value={_.get(formData, "otp") || ""}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <WithTimerButton handleClick={handleClickResend} duration={0.5}>
              Resend OTP
            </WithTimerButton>
            <Button
              type="submit"
              className="btn btn-primary font-weight-bold ml-2 position-relative"
              loading={isLoading}
            >
              Confirm OTP
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

const modalKey = "login-otp";
const modalConfig = {
  title: "Enter your 6-Digit OTP",
  size: "modal-md",
};

export default withModal(modalKey, modalConfig)(ConfirmOtp);
