import { service } from "api/service";
import { get } from "lodash";

const transformResponse = (data) => get(JSON.parse(data), "data");

export const getAllDealerSims = ({ queryKey: [__, params] }) => {
  const url = "/api/v1/dealer-sims";
  return service.get(url, { params });
};

export const getBranches = ({ queryKey: [__, params] }) => {
  const url = "/api/v1/branches";
  return service.get(url, { params: { ...params, include: "null" } });
};

export const getBranchesRaw = ({ queryKey: [__, branchId, params] }) => {
  const url = `/api/v1/branches/${branchId}`;
  return service.get(url, { params: { ...params, include: "null" } });
};

export const createBranch = ({ data }) => {
  const url = `/api/v1/branches`;
  return service.post(url, data);
};

export const attachUsers = ({ branchId, data }) => {
  const url = `/api/v1/retailer/retailer_users/${branchId}/branches`;
  return service.post(url, data);
};

export const updateBranch = ({ branchId, data }) => {
  const url = `/api/v1/branches/${branchId}`;
  return service.put(url, data);
};

export const getAllDealerRetailerSims = ({ queryKey: [__, params] }) => {
  const url = "/api/v1/dealer-retailer-sims";
  return service.get(url, { params });
};

export const getBranchById = ({ queryKey: [__, branchId] }) => {
  const url = `api/v1/branches/${branchId}`;
  return service.get(url, { transformResponse });
};

export const getAttachUsersBA = ({ queryKey: [__, branchId, params] }) => {
  const url = `api/v1/external_users/branches/${branchId}/retailer_users`;
  return service.get(url, { params });
};

export const getAssignedDealerSims = ({ queryKey: [__, params] }) => {
  const url = "/retailer/api/v1/branches/sources/dealer_sims";
  return service.get(url, { params });
};

export const getAssignedDealerRetailerSims = ({ queryKey: [__, params] }) => {
  const url = "/retailer/api/v1/branches/sources/dealer_retailer_sims";
  return service.get(url, { params });
};

export const addDealerSims = (data) => {
  const url = "retailer/api/v1/branches/sources/dealer_sims";
  return service.post(url, data);
};

export const addDealerRetailerSims = (data) => {
  const url = "retailer/api/v1/branches/sources/dealer_retailer_sims";
  return service.post(url, data);
};

export const getAttachedUsersFl = ({ queryKey: [__, branchId, params] }) => {
  const url = `api/v1/external_users/branches/${branchId}/retailer_users`;
  return service.get(url, { params });
};

export const assignFrontliner = ({ id, ...payload }) => {
  const url = `api/v1/retailer/retailer_users/${id}/branches`;
  return service.post(url, payload);
};

export const validatePass = (payload) => {
  const url = `api/v1/external/users/my/validate-password`;
  return service.post(url, payload);
};

export const updateAttachUsers = ({ id, ...payload }) => {
  const url = `api/v1/retailer-users/${id}`;
  return service.put(url, payload);
};

export const deleteSelectedBranch = ({ branchId }) => {
  const url = `api/v1/branches/${branchId}`;
  return service.delete(url);
};

export const updateBranchDefaultSim = ({ type, id, is_default, branch_id }) => {
  const url = `retailer/api/v1/branches/sources/${type}/${id}`;
  return service.put(url, { is_default, branch_id });
};
