/* eslint-disable no-lone-blocks */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Img from 'modules/common/components/Img';
import _ from 'lodash';
import * as actions from '../../action';
// import * as c from '../../constant';

const SharedFiles = ({ data }) => {
  const renderIcon = type => {
    if (type === 'pdf') {
      return <i className="fas fa-3x fa-file-pdf" style={{ color: 'gray' }} />;
    }
    if (type === 'doc' || type === 'docx') {
      return <i className="fas fa-3x fa-file-word" style={{ color: 'gray' }} />;
    }
    if (type === 'csv') {
      return <i className="fas fa-3x fa-file-csv" style={{ color: 'gray' }} />;
    }
    if (type === 'txt') {
      return <i className="fas fa-3x fa-file-alt" style={{ color: 'gray' }} />;
    }
    return null;
  };
  return (
    <>
      <div className="form-label mb-2">Shared Files</div>
      {data.length > 0 ? (
        <div
          style={{
            height: 230,
            overflowY: 'auto',
          }}
          className="px-3"
        >
          <div className="p-0 mb-4 row">
            {data.map(x => {
              const url = _.get(x, 'attributes.name') || '';
              const ext = (url.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1];
              return (
                <div className="d-flex align-items-center mb-2" key={x.id}>
                  {ext !== 'pdf' &&
                  ext !== 'doc' &&
                  ext !== 'docx' &&
                  ext !== 'csv' &&
                  ext !== 'txt' ? (
                    <Img
                      src={_.get(x, 'attributes.name')}
                      className="attachment-image mr-2"
                      role="presentation"
                      alt={_.get(x, 'attributes.name')}
                      style={{
                        border: '1px solid lightgray',
                        borderRadius: 5,
                        width: 102,
                        height: 72,
                      }}
                    />
                  ) : (
                    <a
                      href={_.get(x, 'attributes.name')}
                      target="_heopenit"
                      className="d-flex justify-content-center align-items-center mr-2"
                      style={{
                        border: '1px solid lightgray',
                        borderRadius: 5,
                        width: 102,
                        height: 72,
                      }}
                    >
                      {renderIcon(ext)}
                    </a>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="text-center mt-1 mb-2">
          <span className="badge badge-warning">There are no Files Found.</span>
        </div>
      )}
    </>
  );
};

SharedFiles.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = () => ({});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(SharedFiles);
