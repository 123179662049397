import React, { useContext, useState, useEffect, useMemo } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import withModal from "modules/common/hoc/withModal";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ToastSuccess } from "modules/common/components/Toast";
import ButtonAsync from "modules/common/components/ButtonAsync";
import ReactInputMask from "react-input-mask";
import { ModalContext } from "App";
import * as actions from "../actions";

function TransactionConfirmForm({
  formData,
  simType,
  postLoadRetailer,
  validateMPin,
  setFormData,
  showRetailerUses,
  getPinStatus,
}) {
  const setModal = useContext(ModalContext);
  const [securityPin, setSecurityPin] = useState("");
  const [isPinEnabled, setIsPinEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = React.useState();
  const [isLoadingPostLoad, setIsLoadingPostLoad] = useState(false);

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleOnViewPassword = (key, value) => (e) => {
    e.preventDefault();
    setView({
      [key]: value,
    });
  };

  const handleProceed = (e) => {
    e.preventDefault();
    setIsLoadingPostLoad(true);
    const payload = {
      target_min: formData.target_min,
      amount: String(parseInt(formData.amount, 10)),
      type: formData.type,
      branch_id: formData.branch.id,
    };

    const postCallback = (res) => {
      ToastSuccess(_.get(res.res.data, "success[0].detail"));
      setIsLoadingPostLoad(false);
      setFormData();
      setModal({ isOpen: false });
      showRetailerUses();
    };

    const mPinCallback = () => {
      postLoadRetailer(
        formData.dealer_min.dealerMinId,
        payload,
        postCallback,
        () => {
          setIsLoadingPostLoad(false);
          showRetailerUses({}, (res) => {
            if (!_.get(res, "data.attributes.has_ongoing_schedule")) {
              setModal({ isOpen: false });
            }
          });
        }
      );
    };
    if (isPinEnabled) {
      validateMPin({ mpin: securityPin }, mPinCallback, () => {
        setIsLoadingPostLoad(false);
        showRetailerUses();
      });
      return;
    }
    mPinCallback();
  };

  const hanldeOnChangeSecurityPin = (e) => {
    e.preventDefault();
    setSecurityPin(e.target.value);
  };

  const proceedDisabled = useMemo(() => {
    if (isPinEnabled) return isLoading || securityPin.length < 6;
    return isLoading;
  }, [isPinEnabled, isLoading, securityPin]);

  useEffect(() => {
    getPinStatus({}, (res) => {
      const is_pin_enabled = _.get(res, "data.attributes.is_pin_enabled");
      if (is_pin_enabled) setIsPinEnabled(true);
      else setIsPinEnabled(false);
      setIsLoading(false);
    });
  }, [getPinStatus, setIsPinEnabled, setIsLoading]);

  return (
    <div className="p-4" style={{ color: "#444444", fontSize: "1.125rem" }}>
      <div className="d-flex mb-2">
        <div style={{ width: "12rem" }}>SIM Type:</div>
        <div>{simType}</div>
      </div>

      <div className="d-flex mb-2">
        <div style={{ width: "12rem" }}>Source MIN:</div>
        <div className="break-word" style={{ width: "12rem" }}>
          {formData.dealer_min.value}
        </div>
      </div>

      <div className="d-flex mb-2">
        <div style={{ width: "12rem" }}>Target Mobile No.</div>
        <div>{formData.target_min} </div>
      </div>

      <div className="d-flex mb-2">
        <div style={{ width: "12rem" }}>Amount:</div>
        <div>{formData.amount}</div>
      </div>
      <hr />
      {isPinEnabled && !isLoading ? (
        <div>
          <div
            className="mt-4"
            style={{ fontSize: "1.25rem", fontWeight: "bold" }}
          >
            Your Security PIN is required to continue
          </div>
          <div style={{ marginBottom: "2.125rem" }}>
            <div
              className="prepend-form-right"
              style={{ marginTop: "1.75rem" }}
            >
              <form
                onSubmit={handleOnViewPassword(
                  "securityPin",
                  !_.get(view, "securityPin")
                )}
              >
                <label className="form-label text-primary">
                  {" "}
                  Security PIN:{" "}
                </label>
                <ReactInputMask
                  className="form-control form-custom form-control_bottom"
                  type={_.get(view, "securityPin") ? "text" : "password"}
                  placeholder="Security PIN"
                  required
                  mask="999999"
                  maskChar=""
                  onChange={hanldeOnChangeSecurityPin}
                  value={securityPin}
                />
                <div style={{ position: "relative" }}>
                  <button
                    type="submit"
                    className="btn btn-default rounded-circle p-0 mr-1"
                  >
                    <i
                      className={`fas ${
                        !_.get(view, "securityPin") ? "fa-eye" : "fa-eye-slash"
                      }`}
                      style={{
                        position: "absolute",
                        right: "0px",
                        bottom: "0px",
                      }}
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : null}
      <div className="d-flex justify-content-center">
        <ButtonAsync
          type="submit"
          onClick={handleProceed}
          appendClass="font-weight-bold mr-5"
          disabled={proceedDisabled}
          loading={isLoadingPostLoad}
        >
          PROCEED
        </ButtonAsync>
        <button
          type="button"
          onClick={closeModal}
          className="btn font-weight-bold"
          style={{ backgroundColor: "#DADADA" }}
        >
          {" "}
          CANCEL{" "}
        </button>
      </div>
    </div>
  );
}

TransactionConfirmForm.propTypes = {
  setFormData: PropTypes.instanceOf(Function).isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
  simType: PropTypes.string.isRequired,
  postLoadRetailer: PropTypes.instanceOf(Function).isRequired,
  validateMPin: PropTypes.instanceOf(Function).isRequired,
  showRetailerUses: PropTypes.instanceOf(Function).isRequired,
  getPinStatus: PropTypes.instanceOf(Function).isRequired,
};

const enhance = _.flowRight([
  withModal("confirm-load-retailer", {
    title: "TRANSACTION CONFIRMATION",
    size: "modal-md",
  }),
  withRouter,
  connect(null, actions),
]);

export default enhance(TransactionConfirmForm);
