import React, { useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ModalContext } from "App";
import Button from "components/Button";
import { ToastError, ToastSuccess } from "modules/common/components/Toast";
import ErrorMessage from "modules/common/components/ErrorMessage";
import withModal from "modules/common/hoc/withModal";
import { getBranchById, attachUsers } from "../actions";
import SelectBranchAdmins from "modules/ManageAccounts/modalForms/SelectBranchAdmins";
import SelectShift from "modules/ManageAccounts/modalForms/SelectShift";

const CreateBranchAdmin = ({ branchId, branchName }) => {
  const [form, setForm] = useState({ user: "", schedule: "" });
  const setModal = useContext(ModalContext);
  const queryClient = useQueryClient();

  const apiBranch = useQuery({
    queryKey: ["branch", branchId],
    queryFn: getBranchById,
    enabled: !_.isEmpty(branchId),
    retry: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const isBranchFetching = apiBranch.isFetching;

  const { mutate: saveBranchAdmin, isLoading: isProcessing } = useMutation(
    attachUsers,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("attached_users_ba");
        queryClient.invalidateQueries("branch");
        ToastSuccess("Successfully assigned Branch and Selling Window.");
        setModal({ isOpen: false });
      },
      onError: (err) => {
        const defaultMessage = "Failed to assign branch.";
        const errorMessage = (
          <ErrorMessage error={err} defaultMessage={defaultMessage} />
        );
        ToastError(errorMessage);
        setModal({ isOpen: false });
      },
    },
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const id = form?.user?.value || "";
    const payload = {
      schedule_id: form?.schedule?.value,
      branch_id: branchId,
    };
    const params = {
      branchId: id,
      data: payload,
    };
    saveBranchAdmin(params);
  };

  const handleModalClose = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleChangeSelect = (key, callback) => (value) => {
    setForm({
      ...form,
      [key]: value || {},
    });
    if (callback) callback();
  };

  const isFormValid = useMemo(() => {
    return !_.some(form, (v) => v === "");
  }, [form]);

  return (
    <div className="col">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <label className="form-label text-success">
              Select Branch Admin:
            </label>
            <SelectBranchAdmins
              onChange={handleChangeSelect("user")}
              value={form?.user || {}}
              branchId={branchId}
            />
          </div>
          <div className="col">
            <label className="form-label text-success">Branch:</label>
            <input
              type="text"
              name="name"
              autoFocus
              readOnly
              className="form-control form-custom form-control_bottom"
              value={branchName}
            />
          </div>
          <div className="col">
            <label className="form-label text-success">Select Shift:</label>
            <SelectShift
              onChange={handleChangeSelect("schedule")}
              value={form?.schedule || {}}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end pt-4">
          <Button
            loading={isBranchFetching || isProcessing}
            type="submit"
            disabled={!isFormValid}
          >
            Add
          </Button>
          <Button
            onClick={handleModalClose}
            className="btn btn-danger font-weight-bold ml-2"
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

CreateBranchAdmin.propTypes = {
  branchId: PropTypes.string.isRequired,
  branchName: PropTypes.string,
};

CreateBranchAdmin.defaultProps = {
  branchName: "",
};

const modalKey = "create-branch-admin";
const modalConfig = {
  title: "Add Branch Admin",
  size: "modal-lg",
};

export default withModal(modalKey, modalConfig)(CreateBranchAdmin);
