import React, {
  useState,
  useRef,
  useEffect,
  Fragment,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

function InfiniteScrollReverse({
  idKey,
  loadOptions,
  render,
  containerClassName,
  defaultValueToRender,
  addedOption,
  h,
}) {
  const [divId] = useState(
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
  const [noMoreItems, setNoMoreItems] = useState(false);
  const [isLoading, setIsLoading] = useState({});
  const [list, setList] = useState([]);
  const lastPage = useRef();
  const listRef = useRef();
  const scrollToId = useRef();

  const loadMore = useCallback(() => {
    setIsLoading(true);
    const nextPage = (lastPage.current || 0) + 1;
    loadOptions(nextPage, newItems => {
      setIsLoading(false);
      if (newItems.length < 1) {
        setNoMoreItems(true);
        return;
      }
      lastPage.current = nextPage;
      const scrollId = _.get(newItems, `${newItems.length - 1}.${idKey}`);
      if (!scrollId) return;
      scrollToId.current = scrollId;
      setList(newItems.concat(listRef.current));
    });
  }, [idKey, loadOptions, setNoMoreItems, setList, setIsLoading, scrollToId]);

  const handleScroll = useCallback(() => {
    const wrapper = document.getElementById(divId);
    if (wrapper.scrollTop === 0) {
      loadMore();
    }
  }, [loadMore, divId]);

  useEffect(() => {
    const wrapper = document.getElementById(divId);
    wrapper.addEventListener('scroll', handleScroll);
    loadMore();
    return () => {
      wrapper.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, divId, loadMore]);

  useEffect(() => {
    if (list.length < 1) return;
    const elem = document.getElementById(`item-${scrollToId.current}`);
    const wrapper = document.getElementById(divId);
    if (elem && wrapper) {
      elem.scrollIntoView({
        block: 'start',
        inline: 'end',
      });
    }
  }, [list, divId]);

  useEffect(() => {
    listRef.current = list;
  }, [list]);

  useMemo(() => {
    if (!_.isEmpty(addedOption)) {
      scrollToId.current = addedOption.id;
      setList(list.concat([addedOption]));
    }
  }, [list, addedOption]);

  return (
    <div
      className="flex-grow-1 box-fit-wrapper"
      style={{
        height: h,
        overflowY: 'auto',
      }}
    >
      <div className="box-fit-overflow overflow-hidden">
        <div className={`h-100 overflow-auto ${containerClassName}`} id={divId}>
          <div className="text-center text-muted mb-3">
            {noMoreItems && list.length > 1 && (
              <span className="badge badge-success">
                No more Items to Load.
              </span>
            )}
          </div>
          {defaultValueToRender && !isLoading && defaultValueToRender()}
          <div>
            {list.map(x => (
              <Fragment key={x[idKey]}>
                {render(x)}
                <div
                  id={`item-${x[idKey]}`}
                  style={{ position: 'relative', top: -29 }}
                />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

InfiniteScrollReverse.propTypes = {
  loadOptions: PropTypes.instanceOf(Function).isRequired,
  render: PropTypes.instanceOf(Function).isRequired,
  defaultValueToRender: PropTypes.instanceOf(Function),
  addedOption: PropTypes.instanceOf(Object),
  idKey: PropTypes.string,
  containerClassName: PropTypes.string,
  h: PropTypes.number,
};

InfiniteScrollReverse.defaultProps = {
  idKey: 'id',
  containerClassName: '',
  h: null,
  defaultValueToRender: null,
  addedOption: {},
};

export default InfiniteScrollReverse;
