import React, { Fragment } from 'react';
import _ from 'lodash';
import NoRoute from 'components/NoRoute';
import { useParams } from 'react-router-dom';
import ClustersListing from './ClustersListing';
import ClusterDetailsPanel from './ClusterDetailsPanel';
import ClusterAgentsPanel from './ClusterAgentsPanel';
import ClusterAgentSetup from './ClusterAgentSetup';
import ClusterSetup from './ClusterSetup';

const AgentClusters = () => {
  const params = useParams();

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-3 h-100 px-0">
          <ClustersListing />
        </div>
        <div className="col-9 px-0 bg-gray-100">
          {_.get(params, 'clusterId') ? (
            <Fragment>
              <ClusterDetailsPanel />
              <ClusterAgentsPanel />
              <ClusterAgentSetup />
              <ClusterSetup />
            </Fragment>
          ) : (
            <NoRoute />
          )}
        </div>
      </div>
    </div>
  );
};

export default AgentClusters;
