import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import imgThumb from 'assets/images/logo-loader.png';
import _ from 'lodash';
import { formatNumber } from 'helper';

const ItemsList = ({ onProductModal, data, filter }) => {
  return (
    <Fragment>
      <div className="card-body">
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-between">
            <span className="title font-weight-bold m-0">
              List of {filter.name}
            </span>
            <div className="layout-action d-flex align-items-center">
              <input
                className="form-control input-textbox form-custom form-control_bottom"
                type="text"
                placeholder="Search Product ..."
              />
            </div>
          </div>
        </div>
        <div className="row flex-grow-1 mt-4 h-100">
          <div className="col-12">
            <div className="card-columns">
              {data.map(x => (
                <a
                  className="card card-offer mb-4"
                  style={{ height: 250 }}
                  key={x.id}
                  href="/"
                  onClick={onProductModal(x)}
                >
                  <div className="promo-title d-flex align-items-center">
                    <img
                      src={_.get(x, 'attributes.primary_photo') || imgThumb}
                      alt="promo-logo"
                      className="mr-2"
                      style={{
                        width: '100%',
                        height: 127,
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                  <small className="promo-description mt-2 mb-1">
                    {_.get(x, 'attributes.description')}
                  </small>
                  <div className="promo-meta d-flex align-items-center justify-content-between">
                    <span className="promo-price text-black font-weight-bold text-uppercase mt-3 ml-auto mr-auto">
                      PHP {formatNumber(_.get(x, 'attributes.base_price'))}
                    </span>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ItemsList.propTypes = {
  onProductModal: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
};

export default ItemsList;
