import React from 'react';
// import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
// import Application from 'modules/ApplicationStatus/components/Application';
import DealerInformationContainer from '../dealer-information';
// import ApplicationStatusContainer from '../ApplicationStatus/container/ApplicationStatusContainer';
// import ContactContainer from '../Contact-us/container/ContactContainer';

const RelationshipManagement = () => {
  const match = useRouteMatch();

  return (
      <Switch>
        <Redirect
          exact
          from={match.path}
          to={`${match.path}/company-information`}
        />
        <Route path={`${match.path}`}>
          <DealerInformationContainer />
        </Route>
        {/*
        <Route path={`${match.path}/application-status`}>
          <ApplicationStatusContainer />
        </Route>
        <Route path={`${match.path}/contact-us`}>
          <ContactContainer />
        </Route>
        <Route path={`${match.path}/application/:id?`}>
          <Application />
        </Route>
        */}
      </Switch>
  );
};

RelationshipManagement.propTypes = {};

export default RelationshipManagement;
