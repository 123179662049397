import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import { useProfile } from "modules/auth/hooks";
import { getNewLocations } from "../actions";

const LocationSelectField = React.memo((props) => {
  const { onChange, ...rest } = props;
  const profile = useProfile();
  const role_slug = _.head(profile?.roles);

  const apiLocations = useQuery({
    queryKey: ["area", { role_slug }],
    queryFn: getNewLocations,
    retry: false,
    keepPreviousData: true,
  });

  const childIncludes = _.get(apiLocations, "data.data.included");

  const locationOptions = useMemo(() => {
    return [
      { value: null, label: "Unassigned", data: {} },
      ..._.map(childIncludes, (child) => {
        return {
          value: child.id,
          label: _.get(child, "attributes.name"),
          data: child,
        };
      }),
    ];
  }, [childIncludes]);

  const handleChange = (value) => {
    if (!_.isFunction(onChange)) return;
    onChange(value);
  };

  return (
    <Select
      options={locationOptions}
      onChange={handleChange}
      isLoading={_.get(apiLocations, "isFetching")}
      noOptionsMessage={() => "No children locations"}
      {...rest}
    />
  );
});

LocationSelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default LocationSelectField;
