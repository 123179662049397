import React, {
  useContext,
  useState,
}
  from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import withModal from 'modules/common/hoc/withModal';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import { ToastSuccess } from 'modules/common/components/Toast';
import { ModalContext } from 'App';
import * as actions from '../actions';

function ChangePinForm({
  // updateRetailerSims,
  data,
  // getRetailerSims,
  // validatePassword,
  setSuccessAlertVisible,
}) {

  // const setModal = useContext(ModalContext);
  // const [view, setView] = useState(false);
  // const [formData, setFormData] = useState({});

  // const handleOnViewPassword = value => e => {
  //   e.preventDefault();
  //   setView(value);
  // };

  // const closeModal = e => {
  //   e.preventDefault();
  //   setModal({ isOpen: false });
  // };

  // const handleOnChange = ({ target }) => {
  //   setFormData({
  //     ...formData,
  //     [target.name]: target.value,
  //   });
  // };

  // const handleSubmit = e => {
  //   e.preventDefault();
  //   validatePassword(formData, () => {
  //     updateRetailerSims(data.id, data.payload, () => {
  //       ToastSuccess(`Successfully ${data.callbackLabel}.`);
  //       getRetailerSims();
  //       setModal({ isOpen: false });
  //     });
  //   });
  // };

  const initForm = {
    newOtp: '',
    oldOtp: '',
  }
  const[form, setForm] = useState(initForm);

  const setModal = useContext(ModalContext);

  const handleConfirm = e => {
    e.preventDefault();
    setModal({ isOpen: false });
    setSuccessAlertVisible(true);
  };

  const handleOnOTPChange = e => {
    const { name, value } = e.target;
    setForm(state => ({ ...state, [name]: value }))
  };

  return (
    <>
      <div className="p-4" style={{ color: '#444444', fontSize: '1.125rem' }}>
        <div className="mt-4" style={{ fontSize: '1.25rem' }}>Enter your new Security PIN combination</div>
        <div style={{ marginBottom: '2.125rem' }}>
          <div className="prepend-form-right" style={{ marginTop: '1.75rem' }}>
            <label className="form-label text-primary"> New PIN: </label>
            <input
              className="form-control form-custom form-control_bottom"
              type='password'
              name='newOtp'
              placeholder="Enter New PIN"
              required
              onChange={handleOnOTPChange}
              value={form.newOtp}
              maxLength="6"
              minLength="6"
            // disabled={!checkPermissions(['can-update-password'])}
            />
            <div style={{ position: 'relative' }}>
              <button type="button" onClick={() => { alert('clicked!') }} className="btn btn-default rounded-circle p-0 mr-1">
                <i className={`fas ${true ? 'fa-eye' : 'fa-eye-slash'}`} style={{ position: 'absolute', right: '0px', bottom: '0px' }} />
              </button>
            </div>
          </div>
        </div>
        <div className="prepend-form-right" style={{ marginTop: '1.75rem' }}>
          <label className="form-label text-primary"> Retype New PIN: </label>
          <input
            className="form-control form-custom form-control_bottom"
            type='password'
            name='oldOtp'
            placeholder="Retype New PIN"
            required
            onChange={handleOnOTPChange}
            value={form.oldOtp}
            maxLength="6"
            minLength="6"
          // disabled={!checkPermissions(['can-update-password'])}
          />
          <div style={{ position: 'relative' }}>
            <button type="button" onClick={() => { alert('clicked!') }} className="btn btn-default rounded-circle p-0 mr-1">
              <i className={`fas ${true ? 'fa-eye' : 'fa-eye-slash'}`} style={{ position: 'absolute', right: '0px', bottom: '0px' }} />
            </button>
          </div>
        </div>
        <div style={{ marginTop: '1.75rem', marginBottom: '2.25rem', fontSize: '1rem', color: '#7E7E7E', textAlign: 'justify' }}>By clicking “Change PIN” button, a One Time Password (OTP) will be sent to your SMS for verification purposes.</div>
        <div className="d-flex justify-content-center">
          <button type="button" onClick={handleConfirm} className="btn btn-primary font-weight-bold" style={{ width: '100%' }} disabled={_.isEmpty(form.newOtp) || _.isEmpty(form.oldOtp)}> CONFIRM </button>
        </div>
      </div>
    </>
    // <div> 
    // <form className="container-fluid" onSubmit={handleSubmit}>
    //   <div className="text-center">
    //     <span className="font-weight-bold">
    //       Are you sure you want to {data.label}?
    //     </span>
    //   </div>
    //   <div className="text-center">
    //     <small>Your password is required to continue.</small>
    //   </div>
    //   <div className="form-group">
    //     <div className="prepend-form-right">
    //       <label className="form-label text-success">Password:</label>
    //       <div className="prepend-input-group">
    //         <input
    //           type={view ? 'text' : 'password'}
    //           className="form-control form-custom form-control_bottom"
    //           required
    //           autoFocus
    //           name="password"
    //           value={_.get(formData, 'password') || ''}
    //           onChange={handleOnChange}
    //         />
    //       </div>
    //       <button
    //         type="button"
    //         onClick={handleOnViewPassword(!view)}
    //         className="btn btn-default rounded-circle p-0 mr-1"
    //       >
    //         <i className={`fas ${!view ? 'fa-eye' : 'fa-eye-slash'}`} />
    //       </button>
    //     </div>
    //   </div>
    //   <div className="row">
    //     <div className="col-12">
    //       <div className="mt-3 d-flex align-items-center justify-content-center">
    //         <button
    //           type="button"
    //           onClick={closeModal}
    //           className="btn btn-danger font-weight-bold mr-2"
    //         >
    //           CANCEL
    //         </button>
    //         <button
    //           type="submit"
    //           className="btn btn-primary font-weight-bold ml-2"
    //         >
    //           PROCEED
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </form>
  );
}

ChangePinForm.propTypes = {
  // updateRetailerSims: PropTypes.func.isRequired,
  // getRetailerSims: PropTypes.func.isRequired,
  // validatePassword: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  setSuccessAlertVisible: PropTypes.instanceOf(Function).isRequired,
};

const mapStateToProps = ({ modal }) => ({
  data: _.get(modal, 'modalData.data', {}),
});

const enhance = _.flowRight([
  withModal('confirm-load-retailer-change-pin', {
    title: 'CHANGE PIN',
    size: 'modal-md',
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(ChangePinForm);
