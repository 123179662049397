import React, { useContext } from "react";
import moment from "moment";
import GenericTable from "modules/common/components/GenericTable";
import PropTypes from "prop-types";
import Actions from "components/Actions";
import _ from "lodash";
import Pagination from "modules/common/components/Pagination";
import { ModalContext } from "App";
import ViewDetails from "../modals/ViewDetails";

const customTableStyles = {
  minWidth: "1500px",
  width: "100%",
};
const dropdownButtonClass =
  "mt-0 btn btn-block btn-default py-1 rounded-0 text-right";

function ReportTable({ data, loading, pager, handlePager }) {
  const setModal = useContext(ModalContext);

  const openView = (item) => {
    setModal("view-report-details", { ...item, type: "R2S" });
  };

  const renderActions = (item) => {
    return (
      <Actions id={item.id}>
        <div>
          <button
            onClick={() => openView(item)}
            className={dropdownButtonClass}
            type="button"
          >
            <small>View Details</small>
          </button>
        </div>
      </Actions>
    );
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0 bg-white border">
            <GenericTable
              isLoading={loading}
              format={[
                {
                  key: (item) => (
                    <span>
                      {moment(_.get(item, "attributes.created_at")).format(
                        "lll"
                      )}
                    </span>
                  ),
                  label: "Date / Time",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>{_.get(item, "attributes.branch_name") || "--"}</span>
                  ),
                  label: "Branch Name",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>{_.get(item, "attributes.source") || "--"}</span>
                  ),
                  label: "Source Fund",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>
                      {_.get(item, "attributes.source_sim_type") || "--"}
                    </span>
                  ),
                  label: "Source Sim Type",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>
                      {_.get(item, "attributes.target_sub_account") || "--"}
                    </span>
                  ),
                  label: "Target Mobile",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>
                      {_.get(item, "attributes.target_sim_type") || "--"}
                    </span>
                  ),
                  label: "Target SIM Type",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>PHP {_.get(item, "attributes.amount") || "--"}</span>
                  ),
                  label: "Amount Transferred",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>{_.get(item, "attributes.offer_name") || "--"}</span>
                  ),
                  label: "Offer",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>
                      {_.get(item, "attributes.request_reference_no") || "--"}
                    </span>
                  ),
                  label: "Client RRN",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>
                      {_.get(item, "attributes.transaction_rrn") || "--"}
                    </span>
                  ),
                  label: "Reference No.",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span
                      className={`badge ${
                        _.get(item, "attributes.status") === "SUCCESSFUL"
                          ? "badge-success"
                          : "badge-danger"
                      }`}
                    >
                      {_.get(item, "attributes.status") || "--"}
                    </span>
                  ),
                  label: "Status",
                  width: "auto",
                },
                {
                  key: (item) => renderActions(item),
                  label: "Actions",
                  width: "80px",
                },
              ]}
              data={data}
              containerClassName="notebookRetailer__container-table scroll-lime"
              customTableStyles={customTableStyles}
            />
          </div>
        </div>
      </div>
      <Pagination
        onChange={handlePager}
        data={pager}
        className="d-flex justify-content-end p-2 border-top border-muted"
      />

      <ViewDetails />
    </>
  );
}

ReportTable.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool.isRequired,
  pager: PropTypes.instanceOf(Object).isRequired,
  handlePager: PropTypes.func.isRequired,
};

export default ReportTable;
