import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import * as actions from '../actions';

const mapStateToProps = (modalKey, options = {}) => ({ modal }) => ({
  showModal: modalKey === modal.modalKey, // swap with state
  modalData: modal.modalData,
  options: {
    title: 'MODAL TITLE',
    size: '',
    isClosable: true,
    bodyClass: 'modal-body',
    ...options,
  },
});

const hocComponentName = WrappedComponent =>
  class ModalWrapper extends React.Component {
    static propTypes = {
      setModal: PropTypes.func.isRequired,
      showModal: PropTypes.bool.isRequired,
      modalData: PropTypes.instanceOf(Object).isRequired,
      options: PropTypes.instanceOf(Object).isRequired,
      history: PropTypes.instanceOf(Object).isRequired,
      location: PropTypes.instanceOf(Object).isRequired,
    };

    handleShortcut = e => {
      if (
        e.key === 'Escape' &&
        !document.getElementById('prevent-modal-close')
      ) {
        this.handleCloseModal();
      }
    };

    openModal = () => {
      document.body.className = 'modal-open';
    };

    closeModal = () => {
      document.body.className = '';
    };

    handleCloseModal = (modalData = {}) => {
      const { setModal, history, location } = this.props;
      const closeUrl = _.get(location, 'state.closeUrl');
      history.push(closeUrl);
      const closeModal = _.get(location, 'state.closeModal');
      if (closeModal) {
        setModal(closeModal.key, {
          ...(closeModal.data || {}),
          onCloseData: modalData,
        });
        return;
      }
      setModal('');
    };

    UNSAFE_componentWillReceiveProps(props) {
      const { showModal: oldShow } = this.props;
      const { showModal: newShow } = props;
      if (oldShow !== newShow) {
        if (newShow) this.openModal();
        if (!newShow) this.closeModal();
      }
    }

    UNSAFE_componentDidMount() {
      const { showModal } = this.props;
      if (showModal) {
        this.openModal();
      }
      document.body.addEventListener('keydown', this.handleShortcut);
    }

    UNSAFE_componentWillUnmount() {
      document.body.removeEventListener('keydown', this.handleShortcut);
    }

    render() {
      const { showModal, options, modalData, ...props } = this.props;
      const customTitle = modalData?.modalTitle;

      return (
        <>
          <div
            className={`modal fade${showModal ? ' show' : ''}`}
            tabIndex="-1"
            role="dialog"
            style={
              showModal
                ? {
                    display: 'block',
                    paddingRight: 15,
                    pointerEvents: 'initial',
                  }
                : { display: 'block', paddingRight: 15, pointerEvents: 'none' }
            }
          >
            <div className={`modal-dialog ${options.size}`} role="document">
              <div
                className="modal-content"
                style={{ pointerEvents: showModal ? 'auto' : 'none' }}
              >
                <div className="modal-header">
                  <span
                    className="modal-title mt-0"
                    style={{ fontWeight: 900 }}
                  >
                    {customTitle || options.title}
                  </span>
                  {options.isClosable && (
                    <button
                      type="button"
                      className="close"
                      onClick={this.handleCloseModal}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  )}
                </div>
                <div className={options.bodyClass}>
                  {showModal && (
                    <WrappedComponent
                      {...modalData}
                      {...props}
                      onCloseModal={this.handleCloseModal}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* eslint-disable */
          showModal ? (
            <div
              className="modal-backdrop fade show"
              onClick={options.isClosable ? this.handleCloseModal : () => {}}
            />
          ) : null
          /* eslint-enable */
          }
        </>
      );
    }
  };

export default (modalKey, options) => WrapperComponent =>
  withRouter(
    connect(
      mapStateToProps(modalKey, options),
      actions
    )(hocComponentName(WrapperComponent))
  );
