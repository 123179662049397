import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { USER_PROFILE } from 'modules/auth/constants';
import { logout } from 'modules/auth/actions';
import { ToastSuccess } from 'modules/common/components/Toast';
import { updateProfile } from 'modules/profile/actions';
import { PROFILE_UPDATE } from 'modules/profile/constants';
import Logo from 'assets/images/dealers-portal-logo-sm.png';
import Backdrop from './Backdrop';

const Container = () => {
  const dispatch = useDispatch();
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const userLogout = () =>
    dispatch(
      logout(() => {
        ToastSuccess('You have successfully logged out.');
      })
    );

  const isUpdatingProfile = useSelector(({ ui }) =>
    ui.loading.includes(PROFILE_UPDATE)
  );

  const handleConfirmUpdate = e => {
    e.preventDefault();
    dispatch(updateProfile({ was_updated: false }));
  };

  return (
    <div className="vw-100 vh-100">
      <Backdrop />
      <div className="d-flex align-items-center justify-content-center vw-100 vh-100">
        <div className="col-md-8 col-xl-5">
          <img
            src={Logo}
            alt="PLDT / SMART Dealer's Portal"
            className="d-block mx-auto mb-5"
          />
          <div className="card shadow-sm px-5">
            <h5 className="text-center font-weight-bold">
              Profile Information Update
            </h5>
            <p className="text-center">
              Updates were made to your profile information that requires your
              attention. Please see your updated details below
            </p>

            <form onSubmit={handleConfirmUpdate}>
              <div className="form-row">
                <div className="form-group col">
                  <label className="form-label text-primary">First Name:</label>
                  <input
                    className="form-control form-custom form-control_bottom"
                    type="text"
                    readOnly
                    value={_.get(profile, 'first_name', '')}
                  />
                </div>
                <div className="form-group col">
                  <label className="form-label text-primary">Last Name:</label>
                  <input
                    className="form-control form-custom form-control_bottom"
                    type="text"
                    readOnly
                    value={_.get(profile, 'last_name', '')}
                  />
                </div>
                <p className="text-center text-sm">
                  By clicking &quot;Continue&quot;, you confirm that all the
                  details above are correct. If any doubts, you may discard
                  these updates and contact you immediate supervisor for
                  support.
                </p>
              </div>

              <div
                className="form-group mt-4 mx-auto w-100 text-center"
                style={{ maxWidth: 300 }}
              >
                <button
                  disabled={isUpdatingProfile}
                  type="submit"
                  className="btn btn-block btn-primary px-4"
                >
                  {isUpdatingProfile && <i className="fa fa-spinner fa-spin" />}{' '}
                  Continue
                </button>
                <button
                  type="button"
                  className="cancel-btn btn btn-link mt-2 px-4"
                  onClick={() => userLogout()}
                >
                  <span style={{ textTransform: 'none' }}>
                    I do not accept, log me out
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Container;
