import React, { useState, useEffect } from 'react';
import { withRouter, useRouteMatch, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactComponent as IconRequest } from 'assets/images/sidebar-icon-contact.svg';
// import { ReactComponent as IconLoadIssue } from 'assets/images/icon-monitor.svg';
// import { ReactComponent as IconOrder } from 'assets/images/sidebar-icon-order-mgt.svg';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../action';
import * as c from '../constant';

const filterData = {
  q: '',
};

const HelpDesk = ({ getIssueCategory, issueCategory }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const [search, setSearch] = useState(filterData);

  const handleChange = key => e => {
    setSearch({
      ...search,
      [key]: e.target.value || '',
    });
    getIssueCategory({ ...search, [key]: e.target.value });
  };

  const handleIssueCategory = args => e => {
    e.preventDefault();
    history.push(`${match.url}/${_.get(args, 'id')}/issue`);
  };

  useEffect(() => {
    getIssueCategory();
  }, [getIssueCategory]);

  return (
    <div className="text-center w-75 mr-auto ml-auto">
      <h1 className="h4 text-primary mt-0">Dealer&#39;s Portal Help Desk</h1>
      <h2 className="h5 font-weight-normal mt-0">
        Raise your Concerns, Issues, and Request.
      </h2>
      <div className="form-group mt-5">
        <label htmlFor="search" className="sr-only">
          Search help and services
        </label>
        <div className="prepend-input-group">
          <div className="input-group-prepend">
            <span className="input-group-text border-0 pl-3">
              <i className="fa fa-search" />
            </span>
          </div>
          <input
            id="search"
            className="form-control input-textbox"
            type="text"
            placeholder="Search help and services"
            autoFocus
            required
            name="q"
            value={_.get(search, 'q') || ''}
            onChange={handleChange('q')}
          />
        </div>
      </div>
      {issueCategory.length > 0 ? (
        <div className="row mt-5">
          {issueCategory.map(x => (
            <div key={x.id} className="col-12 col-md-4 mb-4">
              <button
                type="button"
                className="btn btn-default btn-block btn-helpdesk shadow-sm h-100 px-4 py-5"
                onClick={handleIssueCategory(x)}
              >
                <IconRequest className="text-primary icon-helpdesk" />
                <h3 className="h6 text-primary">
                  {_.get(x, 'attributes.name')}
                </h3>
                <p className="m-0 font-weight-normal text-normal">
                  {_.get(x, 'attributes.description')}
                </p>
              </button>
            </div>
          ))}
        </div>
      ) : (
        <span>There are no Items Found.</span>
      )}
    </div>
  );
};

HelpDesk.propTypes = {
  getIssueCategory: PropTypes.func.isRequired,
  issueCategory: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api }) => ({
  issueCategory: _.get(api, `${c.GET_ISSUE_CATEGORY}.list`) || [],
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(HelpDesk);
