import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={150} height={150} viewBox="0 0 150 150" {...props}>
      <g fill="none" fillRule="nonzero">
        <path
          d="M73.507 15.66a9.618 9.618 0 011.68-.623l.3.585c1.298 2.723 3.098 5.16 4.613 7.763 8.94 15.33 13.95 33.09 13.448 50.88.105 6.757-1.38 13.38-2.085 20.063l-.345.03A121.378 121.378 0 0074.79 67.823c-8.325-10.845-18.795-19.905-30.21-27.375C52.913 30.81 62.625 22.32 73.507 15.66z"
          fill="#989898"
        />
        <path
          d="M62.88 72.427c3.54-1.492 6.93-3.555 10.837-3.9-5.932 8.025-11.61 16.395-15.457 25.643-5.782 12.09-9.105 25.29-10.402 38.61-11.445-2.265-22.455-6.36-32.843-11.633 9.322-21.352 26.94-38.692 47.865-48.72z"
          fill="#5B5B5A"
        />
        <path
          d="M75.487 15.623c25.23 15.262 44.363 40.072 53.386 68.1-11.805 5.497-24.465 9.15-37.41 10.605.705-6.683 2.19-13.306 2.085-20.063.502-17.79-4.508-35.55-13.448-50.88-1.515-2.603-3.315-5.04-4.612-7.763z"
          fill="#818181"
        />
        <path
          d="M20.745 82.567c5.092-15.42 13.275-29.79 23.835-42.12 11.415 7.47 21.885 16.53 30.21 27.376-1.343.157-2.678-.083-4.013-.173l.42.9c-4.522.907-8.767 2.79-12.997 4.59-15.06 6.555-28.628 16.642-38.835 29.55-1.357 1.703-2.527 3.555-3.997 5.175 1.14-8.55 2.677-17.085 5.377-25.297z"
          fill="#40403F"
        />
        <path
          d="M91.463 94.328c12.945-1.456 25.605-5.108 37.41-10.606a117.384 117.384 0 015.332 35.116c-25.68.397-51.233-7.89-72.27-22.515-.945-.69-1.77-1.523-2.602-2.333 10.537 1.118 21.225 1.59 31.785.368l.345-.03z"
          fill="#CBCCCC"
        />
        <path
          d="M47.858 132.78c1.297-13.32 4.62-26.52 10.402-38.61l.615-.63.458.45c.832.81 1.657 1.642 2.602 2.333 21.037 14.625 46.59 22.912 72.27 22.515-15.563 9.532-33.563 14.842-51.72 16.155-11.58.367-23.258.307-34.627-2.213z"
          fill="#AEAFB0"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
