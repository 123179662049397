import React, { useMemo, useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import GenericTable from 'modules/common/components/GenericTable';
import Pagination from 'modules/common/components/Pagination';
import Filter from '../../components/TransactionFilter';

import * as actions from '../../actions';
import * as c from '../../constant';

import './Transaction.style.scss';

const defaultParams = {
  type: 'internal',
  page: 1,
  per_page: 7,
  date_from: '',
  date_to: '',
  created_by_username: '',
  dealer_sim_id: '',
  dealer_sim_min: '',
  dealer_sim_type: '',
  target_min: '',
  target_sim_type: '',
  q: '',
};

const selectors = {
  pager: ({ api }) => _.get(api, `${c.GET_TRANSACTIONS}.res.meta.pagination`),
  list: ({ api }) => _.get(api, `${c.GET_TRANSACTIONS}.list`, []),
  loading: ({ api }) => _.get(api, `loading`),
};

const customTableStyles = {
  minWidth: '1350px',
  width: '100%',
};

function AllTransactions() {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(defaultParams);

  const list = useSelector(selectors.list);
  const pager = useSelector(selectors.pager);
  const loading = useSelector(selectors.loading);

  const filterParams = useMemo(() => {
    return _.pickBy({
      ...filters,
      target_min: filters.target_min.replace('+63', '0'),
    });
  }, [filters]);

  const updateTransactions = useCallback(() => {
    dispatch(actions.getTransactions(_.pickBy(filterParams)));
  }, [filters, actions]);

  const handleClear = () => {
    setFilters(defaultParams);
  };

  const handleFilter = e => {
    e.preventDefault();
    updateTransactions();
  };

  const handleChange = key => e => {
    const value = _.get(e, 'value') || _.get(e, 'target.value') || '';
    setFilters(f => ({ ...f, [key]: value, page: 1 }));
  };

  const handlePaging = page => {
    setFilters(f => ({ ...f, page }));
  };

  const handleDownload = () => {
    const downloadParams = _.omit(filterParams, ['page', 'per_page']);
    dispatch(actions.downloadTransactionHistory(downloadParams));
  };

  useEffect(() => {
    dispatch(actions.getTransactions(_.pickBy(defaultParams)));
  }, [actions]);

  useEffect(() => {
    dispatch(actions.getTransactions(_.pickBy({ ...filters, q: filters.q })));
  }, [actions, filters.q]);

  useEffect(() => {
    updateTransactions();
  }, [..._.values(_.omit(filters, ['q']))]); // eslint-disable-line

  return (
    <div className="main-content-inner loadWalletDealerTransaction">
      <div className="row d-flex">
        <div className="col">
          <Filter
            onSubmit={handleFilter}
            onChange={handleChange}
            filters={filters}
            onClear={handleClear}
          />
        </div>
      </div>

      <div className="d-flex align-items-center my-2">
        <h1 className="h5 flex-grow-1 mt-0 mb-0">Dealer Transactions</h1>
        <button
          type="button"
          disabled={list.length === 0}
          onClick={handleDownload}
          className="btn btn-success ml-auto"
        >
          <i className="fas fa-download mr-2" />
          <small>Download</small>
        </button>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0 bg-white border">
            <GenericTable
              isLoading={loading.includes(c.GET_TRANSACTIONS)}
              format={[
                {
                  key: item => (
                    <span>
                      {moment(_.get(item, 'attributes.created_at')).format(
                        'LLL'
                      ) || '--'}
                    </span>
                  ),
                  label: 'Date and Time',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span>
                      {_.get(item, 'attributes.created_by_username', '--')}
                    </span>
                  ),
                  label: 'Username',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span>{_.get(item, 'attributes.ref_id', '--')}</span>
                  ),
                  label: 'Reference No.',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span>
                      {_.get(item, 'attributes.dealer_sim_min', '--')}
                    </span>
                  ),
                  label: 'Dealer Min',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span>
                      {_.get(item, 'attributes.dealer_sim_type', '--')}
                    </span>
                  ),
                  label: 'Source Sim Type',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span>{_.get(item, 'attributes.target_min', '--')}</span>
                  ),
                  label: 'Target MIN',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span>
                      {_.get(item, 'attributes.target_sim_type', '--')}
                    </span>
                  ),
                  label: 'Target Sim Type',
                  width: 'auto',
                },
                {
                  key: item => (
                    <span>{_.get(item, 'attributes.amount_loaded', '--')}</span>
                  ),
                  label: 'Amount',
                  width: 'auto',
                },
              ]}
              data={list}
              containerClassName="loadWalletDealerTransaction__container-table"
              customTableStyles={customTableStyles}
            />
            <Pagination
              onChange={handlePaging}
              data={pager || {}}
              className="d-flex justify-content-end my-3 mr-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllTransactions;
