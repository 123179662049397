export const setModal = (modalKey, modalData = {}) => ({
  type: 'SET_MODAL',
  modalKey,
  modalData,
});

export const startLoading = (x, z = 'loader') => ({
  ...x,
  loading: x.loading.concat([z]),
});

export const endLoading = (x, z = 'loader') => ({
  ...x,
  loading: x.loading.filter(y => y !== z),
});
