export const AUTH = "AUTH/auth";
export const LOGIN = "AUTH/login";
export const RETAILER_LOGIN = "AUTH/retailer_login";
export const RETAILER_USER_LOGIN = "AUTH/retailer_user_login";
export const LOGOUT = "AUTH/logout";
export const FORGOT_PASS = "AUTH/forgot_pass";
export const USER_PROFILE = "AUTH/user_profile";
export const MY_PROFILE = "AUTH/my_profile";
export const PIN_STATUS = "AUTH/pin-status";
export const VERIFY_MOBILE = "AUTH/verify_mobile";
export const CHANGE_PASSWORD = "AUTH/change_password";
export const AUTH_SESSION = "AUTH/login-verify-session-async";
export const AUTH_SESSION_SUCCESS = "AUTH/login-verify-session-success";
export const AUTH_SESSION_FAIL = "AUTH/login-verify-session-error";
export const AUTH_OTP = "AUTH/login-otp-async";
export const AUTH_OTP_SENT = "AUTH/login-otp-success";
export const AUTH_OTP_FAIL = "AUTH/login-otp-error";
export const AUTH_VERIFY = "AUTH/login-verify-async";
export const AUTH_SUCCESS = "AUTH/login-verify-success";
export const AUTH_FAIL = "AUTH/login-verify-error";
export const AUTH_PERMISSIONS = "AUTH/permissions-async";
export const AUTH_PERMISSIONS_SUCCESS = "AUTH/permissions-success";
export const AUTH_PERMISSIONS_FAIL = "AUTH/permissions-error";
export const AUTH_CREDS_FAIL = "AUTH/credentials-fail";
export const SET_USER = "AUTH/set-user";
export const AUTH_COMPLETE = "AUTH/complete-success";


export const REFRESH_TOKEN = "AUTH/refresh-token";
export const OTP_VERIFY = "AUTH/otp-verify-async";
export const OTP_VERIFY_SUCCESS = "AUTH/otp-verify-success";
export const OTP_VERIFY_FAIL = "AUTH/otp-verify-error";

export const OTP_SEND_SUCCESS = "AUTH/otp-send-success";
export const OTP_SEND_FAIL = "AUTH/otp-send-error";

export const LOGOUT_SUCCESS = "AUTH/logout-success";
export const UPDATE_ACCESS_TOKEN = "AUTH/update-access-token";
