import React, { useContext, useEffect } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { ModalContext } from "App";
import { checkPermissions } from "helper";
import { getPinStatus } from "modules/auth/actions";
import { PIN_STATUS } from "modules/auth/constants";
import { useLoading } from "modules/ui/hooks";
import ToggleMpin from "../components/ToggleMpin";

function SecuritySettingsContainer() {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const setModal = useContext(ModalContext);
  const isPinLoading = useLoading(PIN_STATUS);
  const is_pin_enabled = useSelector(({ api }) =>
    _.get(api, `${PIN_STATUS}.item`)
  );
  const status = _.get(is_pin_enabled, "attributes.is_pin_enabled");

  const handleSwitch = () => {
    setModal("toggle-pin", {
      payload: { is_pin_enabled: status },
    });
  };

  const isAdmin = checkPermissions(["can-update-is-mpin-enabled"]);
  const canUpdateMpin = checkPermissions(["can-update-mpin"]);

  useEffect(() => {
    dispatch(getPinStatus());
  }, []);

  const renderLoading = () => (
    <div style={{ height: "8.1rem" }}>
      <div
        style={{ width: "2.5rem", height: "2.5rem" }}
        className="spinner-border spinner-border-md text-success mt-3 ml-5 mb-5"
        role="status"
      />
    </div>
  );

  const renderAdminLoading = () => (
    <div style={{ height: "2.9rem" }}>
      <div
        style={{ width: "2.5rem", height: "2.5rem" }}
        className="spinner-border spinner-border-md text-success ml-4 mb-5"
        role="status"
      />
    </div>
  );

  const renderAdmin = () => (
    <>
      <div className="mb-5">
        <h6 className="font-weight-bold mt-0 mb-3">
          Enable/Disable Security PIN
        </h6>
        <small className="mb-4 d-block w-75">
          Option to toggle Enable or Disable your Security PIN setup. This will
          require your password to complete the process.
        </small>
        {isPinLoading ? (
          renderAdminLoading()
        ) : (
          <input
            className="toggle-switch large-switch self"
            style={{ cursor: "pointer" }}
            type="checkbox"
            checked={status}
            onChange={handleSwitch}
          />
        )}
      </div>
      {canUpdateMpin && (
        <>
          <div className="mb-5">
            <h6 className="font-weight-bold mt-0 mb-3">Change Security PIN</h6>
            <small className="mb-4 d-block w-75">
              This option allows you to change the current assigned Security
              PIN. A One-Time-Password (OTP) will be required to complete the
              process.
            </small>
            <Link to={`${match.path}/change-pin`}>
              <button
                type="button"
                className="btn security-btn btn-block px-4 font-weight-bold"
              >
                Change Pin
              </button>
            </Link>
          </div>
          <div className="mb-5">
            <h6 className="font-weight-bold mt-0 mb-3">Forgot Security PIN</h6>
            <small className="mb-4 d-block w-75">
              In case you fail to keep track of your Security PIN, this option
              will help you reset to a new one. This will require your password
              to complete the process.
            </small>
            <Link to={`${match.path}/forgot-security-pin`}>
              <button
                type="button"
                className="btn security-btn btn-block px-4 font-weight-bold"
              >
                I Forgot My Pin
              </button>
            </Link>
          </div>
        </>
      )}
    </>
  );

  const renderUser = () => {
    return (
      <>
        {isPinLoading ? (
          renderLoading()
        ) : (
          <div className="mb-5">
            <h6 className="font-weight-bold mt-0 mb-3">
              Your Security PIN is {status ? "ENABLED" : "DISABLED"}
            </h6>

            <input
              className="toggle-switch large-switch self"
              style={{ cursor: "default" }}
              type="checkbox"
              checked={status}
              disabled
            />
          </div>
        )}
        {canUpdateMpin && (
          <>
            <div className="mb-5">
              <h6 className="font-weight-bold mt-0 mb-3">
                Change Security PIN
              </h6>
              <small className="mb-4 d-block w-75">
                This option allows you to change the current assigned Security
                PIN. A One-Time-Password (OTP) will be required to complete the
                process.
              </small>
              <Link to={`${match.path}/change-pin`}>
                <button
                  type="button"
                  className="btn security-btn btn-block px-4 font-weight-bold"
                >
                  Change Pin
                </button>
              </Link>
            </div>
            <div className="mb-5">
              <h6 className="font-weight-bold mt-0 mb-3">
                Forgot Security PIN
              </h6>
              <small className="mb-4 d-block w-75">
                In case you fail to keep track of your Security PIN, this option
                will help you reset to a new one. This will require your
                password to complete the process.
              </small>
              <Link to={`${match.path}/forgot-security-pin`}>
                <button
                  type="button"
                  className="btn security-btn btn-block px-4 font-weight-bold"
                >
                  I Forgot My Pin
                </button>
              </Link>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div className="container-fluid h-100">
      <div className="align-items-center justify-content-center h-100">
        <div className="col-xl-10 col-lg-10 mt-lg-4 mt-xl-auto mx-auto">
          <h4 className="font-weight-bold mt-0 mb-4">Security PIN Settings</h4>
          {isAdmin ? renderAdmin() : renderUser()}
        </div>
      </div>
      <ToggleMpin />
    </div>
  );
}

export default SecuritySettingsContainer;
