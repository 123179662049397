import React from 'react';
import Layout from '../Layout';

const HelpCenter = () => {
  return (
    <Layout>
      <h4 className="font-weight-bold text-center m-0 mb-4">Help Center</h4>
    </Layout>
  );
};

export default HelpCenter;
