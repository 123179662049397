export const GET_ELOAD_LIST = "REPORTS/get_eload_list";
export const DOWNLOAD_ELOAD_REPORT = "REPORTS/download_eload_report";
export const defaultParams = {
  page: 1,
  per_page: 12,
  date_from: "",
  date_to: "",
  branch_id: "",
  source: "",
  target_min: "",
  status: "",
  q: "",
  order_by: "created_at",
  sort_by: "desc",
  command: "dealertoRetailer",
};

export const form = [
  {
    type: "date",
    name: "date_from",
    label: "Date From:",
  },
  {
    type: "date",
    name: "date_to",
    label: "Date To:",
  },
  {
    type: "select-async",
    name: "branch_id",
    label: "Branch:",
    transformer: (list) =>
      list?.data.map((x) => ({
        value: x?.attributes?.code,
        label: x?.attributes?.name,
      })),
    api: "api/v1/branches",
    params: { include: null },
    isClearable: true,
  },
  {
    type: "select-async",
    name: "source",
    label: "Source Fund:",
    transformer: (list) =>
      list?.data.map((x) => ({
        value: x?.attributes?.source,
        label: x?.attributes?.source,
      })),
    api: "partners/api/v1/transactions/source",
    isClearable: true,
  },
  {
    type: "text",
    name: "target_min",
    label: "Target MIN:",
  },
  {
    type: "select-static",
    name: "status",
    label: "Status:",
    options: [
      {
        value: "SUCCESSFUL",
        label: "Successful",
      },
      {
        value: "FAILED",
        label: "Failed",
      },
    ],
    isClearable: true,
  },
];
