import React from 'react';
import PropTypes from 'prop-types';
import ButtonAsync from 'modules/common/components/ButtonAsync';

const SubDealerInfo = React.memo(({ agent, onAssign, disabled, loading }) => {
  return (
    <div className="px-3 mb-4">
      <h5 className="font-weight-bold m-0 my-4">Assign to:</h5>
      <div className="d-flex justify-content-between">
        <div>
          <div className="mb-2 text-xs font-weight-bold text-gray-400">
            Sub-Dealer Agent:
          </div>
          <div className="ml-2">
            <span className="font-weight-bold">{agent.min}</span>
            <span className="ml-3">{agent.name}</span>
          </div>
        </div>
        <ButtonAsync onClick={onAssign} loading={loading} disabled={disabled}>
          assign selected
        </ButtonAsync>
      </div>
    </div>
  );
});

SubDealerInfo.propTypes = {
  agent: PropTypes.instanceOf(Object).isRequired,
  onAssign: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SubDealerInfo;
