import React, { useContext, useState } from 'react';
import _ from 'lodash';
import withModal from 'modules/common/hoc/withModal';
import { ToastSuccess, ToastError } from 'modules/common/components/Toast';
import { ModalContext } from 'App';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'components/Button';
import { useLoading } from 'modules/ui/hooks';
import { logoutUser, validatePassword } from '../action';
import * as c from '../constant';

function StatusModal() {
  const setModal = useContext(ModalContext);
  const dispatch = useDispatch();
  const initialData = useSelector(({ modal }) => _.get(modal, 'modalData'));
  const [view, setView] = useState(false);
  const [formData, setFormData] = useState({});
  const [pending, setPending] = useState(false);

  const handleOnViewPassword = value => e => {
    e.preventDefault();
    setView(value);
  };

  const closeModal = e => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleOnChange = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);
    dispatch(
      validatePassword(
        formData,
        () => {
          const targetUserId = _.get(initialData, 'userId');
          const targetUserName = _.get(initialData, 'userName');
          const logoutCallback = () => {
            ToastSuccess(`You have successfully logged out ${targetUserName}`);
            setModal('', {});
            setPending(false);
          };
          const logoutErrorCallback = () => {
            ToastError(`The user might not have an active session.`);
            setModal('', {});
            setPending(false);
          };
          dispatch(
            logoutUser(targetUserId, logoutCallback, logoutErrorCallback)
          );
        },
        () => setPending(false)
      )
    );
  };

  const isValidatingPassword = useLoading(c.VALIDATE_PASSWORD);
  const isLoggingOutUser = useLoading(c.LOGOUT_USER);

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="text-center">
        Your are about to force this user to log out.
        <div className="font-weight-bold">{_.get(initialData, 'userName')}</div>
      </div>
      <div className="text-center">
        <small>Your password is required to continue.</small>
      </div>
      <div className="form-group">
        <div className="prepend-form-right">
          <label className="form-label text-success">Password:</label>
          <div className="prepend-input-group">
            <input
              type={view ? 'text' : 'password'}
              className="form-control form-custom form-control_bottom"
              required
              autoFocus
              name="password"
              value={_.get(formData, 'password') || ''}
              onChange={handleOnChange}
            />
          </div>
          <button
            type="button"
            onClick={handleOnViewPassword(!view)}
            className="btn btn-default rounded-circle p-0 mr-1"
          >
            <i className={`fas ${!view ? 'fa-eye' : 'fa-eye-slash'}`} />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-center">
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold mr-2"
            >
              CANCEL
            </button>
            <Button
              disabled={!formData.password}
              loading={isValidatingPassword || isLoggingOutUser || pending}
              type="submit"
              className="btn btn-primary font-weight-bold ml-2 position-relative"
            >
              PROCEED
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

const modalKey = 'confirm-user-logout';
const modalConfig = { title: 'CONFIRM LOGOUT', size: 'modal-md' };
export default withModal(modalKey, modalConfig)(StatusModal);
