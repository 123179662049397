export const GET_ISSUE_CATEGORY = 'ASSURANCE/get_issue_category';
export const GET_ISSUE = 'ASSURANCE/get_issue';
export const GET_TICKET = 'ASSURANCE/get_ticket';
export const GET_MESSAGE = 'ASSURANCE/get_message';
export const GET_NOTE = 'ASSURANCE/get_note';
export const GET_ASSIGNEE = 'ASSURANCE/get_assignee';
export const GET_STATUS = 'ASSURANCE/get_status';
export const GET_FILES = 'ASSURANCE/get_files';

export const SHOW_ISSUE_CATEGORY = 'ASSURANCE/show_issue_category';
export const SHOW_ISSUE = 'ASSURANCE/show_issue';
export const SHOW_TICKET = 'ASSURANCE/show_ticket';

export const CREATE_TICKET = 'ASSURANCE/create_ticket';
export const CREATE_MESSAGE = 'ASSURANCE/create_message';
export const CREATE_NOTE = 'ASSURANCE/create_note';

export const UPDATE_TICKET_BY_STATUS = 'ASSURANCE/update_ticket_by_status';

export const FORM_DATA = {};
