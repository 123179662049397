import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import imgThumb from 'assets/images/logo-loader.png';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import withModal from 'modules/common/hoc/withModal';
import { connect } from 'react-redux';
import { ModalContext } from 'App';
import { ToastSuccess } from 'modules/common/components/Toast';
import * as actions from '../actions';

const ProductModal = ({ data, addToCart, getCart }) => {
  const [quantity, setQuatity] = useState(1);
  const [total, setTotal] = useState(_.get(data, 'attributes.base_price'));
  const setModal = useContext(ModalContext);

  const handleChangeQuantity = (type, value) => e => {
    e.preventDefault();
    if (type === 'add') {
      const newQuantity = quantity + value;
      const newTotal = newQuantity * _.get(data, 'attributes.base_price');
      setQuatity(newQuantity);
      setTotal(`${newTotal}`);
    }
    if (type === 'minus') {
      const newQuantity = quantity - value;
      const newTotal = total - _.get(data, 'attributes.base_price');
      if (newQuantity < 1) return;
      setQuatity(newQuantity);
      setTotal(`${newTotal}`);
    }
  };

  const handleAddToCart = e => {
    e.preventDefault();
    const payload = {
      product_id: data.id,
      quantity,
    };
    addToCart(payload, () => {
      setModal({ isOpen: false });
      ToastSuccess('Successfully added to cart.');
      getCart();
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-5">
          <img
            src={_.get(data, 'attributes.primary_photo') || imgThumb}
            alt="..."
            style={{ height: 250, width: '100%', objectFit: 'contain' }}
          />
        </div>
        <div className="col-7 p-0">
          <h4 className="font-weight-bold m-0 mb-2">
            {_.get(data, 'attributes.name')}
          </h4>
          <small className="mb-2">
            {_.get(data, 'attributes.description')}
          </small>
          <div className="row">
            <div className="form-group px-3 my-2">
              <label className="form-label">Price:</label>
              <input
                className="form-control input-textbox form-custom form-control_bottom"
                type="text"
                value={_.get(data, 'attributes.base_price')}
                readOnly
              />
            </div>
            <div className="form-group px-3 my-2">
              <label className="form-label">Promo:</label>
              <select name="promo" id="promo" className="custom-select">
                <option defaultValue>Select Promo</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <div className="form-group">
                <label className="form-label">Quantity:</label>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <button
                      className="btn btn-outline-danger btn-sm"
                      type="button"
                      onClick={handleChangeQuantity('minus', 1)}
                    >
                      <i className="fas fa-minus p-2" />
                    </button>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={quantity}
                    readOnly
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-success btn-sm"
                      type="button"
                      onClick={handleChangeQuantity('add', 1)}
                    >
                      <i className="fas fa-plus p-2" />
                    </button>
                  </div>
                </div>
                <small className="text-muted">
                  Discount per item: PHP 2.00
                </small>
              </div>
              <span className="text-muted font-weight-bold">
                Total: PHP {total || '00'}
              </span>
            </div>
            {quantity === _.get(data, 'attributes.quantity') && (
              <div className="col" style={{ lineHeight: 7 }}>
                <button type="button" className="btn btn-danger" disabled>
                  Available Stock: {_.get(data, 'attributes.quantity')}
                </button>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mt-3 d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  className="btn px-5 btn-download font-weight-bold mr-2"
                  onClick={handleAddToCart}
                >
                  <i className="fas fa-shopping-cart mr-3" />
                  Add to Cart
                </button>
                <button
                  type="button"
                  className="btn px-5 btn-success font-weight-bold ml-2"
                >
                  Order Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductModal.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  addToCart: PropTypes.func.isRequired,
  getCart: PropTypes.func.isRequired,
};

const mapStateToProps = ({ modal }) => ({
  data: _.get(modal, 'modalData') || {},
});

const enhance = _.flowRight([
  withModal('product', {
    title: 'Product',
    size: 'modal-lg',
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(ProductModal);
