import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import withModal from 'modules/common/hoc/withModal';
import { ModalContext } from 'App';
import { ToastSuccess } from 'modules/common/components/Toast';
import ReactInputMask from 'react-input-mask';
import * as authActions from 'modules/auth/actions';
import * as actions from '../actions';
import { REQUEST_OTP } from '../constants';

function RequestOTP({
  payload,
  isLoading,
  profile_type,
  changePasswordUsers,
  changePasswordRetailers,
  changePasswordDealers,
  setFormData,
  form,
  roles,
  mobile_identification_number,
  confirmOtp,
  logout,
}) {
  const setModal = React.useContext(ModalContext);
  const [state, setState] = React.useState({});

  const isDealer = profile_type === 'dealers';
  const isKapartnerUser =
    profile_type === 'retailers' && _.get(roles, '0', '') === 'kapartner-user';
  const closeModal = e => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const onPassworChangeSuccess = () => {
    ToastSuccess(
      'Password successfully updated! Please login with your new password.'
    );
    setModal({ isOpen: false });
    setFormData(form);
    logout();
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (isDealer) {
      const newPayload = {
        ...payload,
        otp: _.get(state, 'otp'),
      };
      changePasswordDealers(newPayload, onPassworChangeSuccess);
    }
    if (!isDealer) {
      const newPayload = {
        ...payload,
      };
      const otpPayload = {
        mobile_identification_number,
        otp: _.get(state, 'otp'),
      };
      confirmOtp(otpPayload, () => {
        changePasswordUsers(newPayload, onPassworChangeSuccess);
      });
    }
    if (isKapartnerUser) {
      const newPayload = {
        ...payload,
      };
      const otpPayload = {
        mobile_identification_number,
        otp: _.get(state, 'otp'),
      };
      confirmOtp(otpPayload, () => {
        changePasswordRetailers(newPayload, onPassworChangeSuccess);
      });
    }
  };

  const handleChangeInput = key => ({ target }) => {
    setState({
      ...state,
      [key]: target.value,
    });
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="prepend-form-right">
            <label className="form-label text-primary text-center">
              Enter your 6-Digit OTP
            </label>
            <ReactInputMask
              type="text"
              placeholder="6 Digit OTP"
              mask="999999"
              maskChar=""
              className="form-control form-custom form-control_bottom"
              name="otp"
              required
              onChange={handleChangeInput('otp')}
              value={_.get(state, 'otp') || ''}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <button
              type="submit"
              className="btn btn-primary font-weight-bold mr-2"
              disabled={isLoading}
            >
              Submit
            </button>
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

RequestOTP.propTypes = {
  changePasswordDealers: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  changePasswordRetailers: PropTypes.func.isRequired,
  changePasswordUsers: PropTypes.func.isRequired,
  confirmOtp: PropTypes.func.isRequired,
  payload: PropTypes.instanceOf(Object).isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  profile_type: PropTypes.string.isRequired,
  mobile_identification_number: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ api, modal }) => ({
  mobile_identification_number:
    _.get(modal, 'modalData.mobile_identification_number') || '',
  setFormData: _.get(modal, 'modalData.setFormData'),
  form: _.get(modal, 'modalData.form'),
  payload: _.get(modal, 'modalData.payload'),
  profile_type: _.get(api, 'AUTH/user_profile.item.profile_type', ''),
  isLoading: api.loading.indexOf(REQUEST_OTP) > -1,
  roles: _.get(api, 'AUTH/user_profile.item.roles') || [],
});

const enhance = _.flowRight([
  withModal('set-otp', {
    title: 'Enter your 6-Digit OTP',
    size: 'modal-sm',
  }),
  withRouter,
  connect(mapStateToProps, { ...actions, ...authActions }),
]);

export default enhance(RequestOTP);
