import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';
import { service } from 'api/service';
import PropTypes from 'prop-types';

const SelectAsync = props => {
  const {
    api,
    value,
    transformer,
    onChange,
    isDisabled,
    params,
    allowFalsy,
  } = props;
  const [localValue, setLocalValue] = useState();

  const handleChange = args => {
    setLocalValue(args);

    if (!onChange || typeof onChange !== 'function') return;
    onChange(args);
  };

  const fetcher = async query => {
    const response = await service.get(api, {
      params: allowFalsy
        ? { q: query, ...params }
        : _.pickBy({ q: query, ...params }),
    });
    return transformer(response.data);
  };

  useEffect(() => {
    if (value) return;
    setLocalValue(null);
  }, [value]);

  return (
    <AsyncSelect
      defaultOptions
      key={`${JSON.stringify(api)}_${JSON.stringify(params)}`}
      {...props}
      value={localValue}
      loadOptions={fetcher}
      onChange={handleChange}
      isClearable
      isDisabled={isDisabled}
    />
  );
};

export default SelectAsync;

SelectAsync.propTypes = {
  api: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.instanceOf(Object),
  ]).isRequired,
  transformer: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  params: PropTypes.instanceOf(Object),
  allowFalsy: PropTypes.bool,
};

SelectAsync.defaultProps = {
  params: {},
  isDisabled: false,
  allowFalsy: false,
};
