export const CHANGE_PASSWORD = 'SETTINGS/change_password';
export const CHANGE_PASSWORD_USER = 'SETTINGS/change_password_user';
export const CHANGE_MPIN = 'SETTINGS/change_mpin';
export const UPDATE_PROFILE = 'SETTINGS/update_profile';
export const GET_CONSENT = 'SETTINGS/get_consent';
export const SHOW_CONSENT = 'SETTINGS/show_consent';
export const CONSENT_STATUS = 'SETTINGS/consent_status';
export const UPDATE_CONSENT = 'SETTINGS/update_consent';
export const SET_THRESHOLD = 'SETTINGS/set_threshold';
export const SHOW_RETAILER = 'SETTINGS/show_retailer';
export const SET_OTP = 'SETTINGS/set_otp';
export const MPIN = 'SETTINGS/mpin';
export const SHOW_RETAILER_USERS = 'SETTINGS/show_retailer_users';
export const UPDATE_PIN = 'SETTINGS/update_pin';
export const FORGOT_PIN = 'SETTINGS/forgot_pin';
export const CONFIRM_OTP = 'SETTINGS/confirm_otp';
export const CONFIRM_PASS = 'SETTINGS/confirm_password';
export const TOGGLE_PIN = 'SETTINGS/toggle_pin';

export const FORM_DATA = {};
