import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const PaymentMethod = ({ handleIsChoose, is_choose }) => {
  return (
    <Fragment>
      <a
        href="/"
        onClick={handleIsChoose(true)}
        className={`${
          is_choose
            ? 'mt-2 cash-credit-wrapper active'
            : 'mt-2 cash-credit-wrapper'
        }`}
      >
        <div className="px-2 py-1">
          <div className={`${is_choose ? 'text-success ml-2' : 'ml-3'}`}>
            {is_choose && <i className="fas fa-check-circle mr-1" />}
            <span>CASH</span>
          </div>
        </div>
      </a>
      <div
        // href="/"
        // onClick={handleIsChoose(false)}
        className={`${
          !is_choose
            ? 'ml-1 mt-2 cash-credit-wrapper active'
            : 'ml-1 mt-2 cash-credit-wrapper'
        }`}
      >
        <div className="px-2 py-1">
          <div className={`${!is_choose ? 'text-success ml-1' : 'ml-3'}`}>
            {!is_choose && <i className="fas fa-check-circle mr-1" />}
            <span>CREDIT</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

PaymentMethod.propTypes = {
  handleIsChoose: PropTypes.func.isRequired,
  is_choose: PropTypes.bool.isRequired,
};

export default PaymentMethod;
