import React, { useState, useContext } from "react";
import _ from "lodash";
import { ModalContext } from "App";
import withModal from "modules/common/hoc/withModal";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { ToastSuccess, ToastError } from "modules/common/components/Toast";
import { loginOTP } from "modules/auth/actions";
import { service } from "api/service";
import { pathResolve, rootApi } from "helper";

function Session() {
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);
  const data = useSelector(({ modal }) => _.get(modal, "modalData"));
  const clear = _.get(data, "credentials.clear");
  const [loading, setLoading] = useState();

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
    clear();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const authorization = {
      headers: { Authorization: `Bearer ${data.token}` },
    };

    const logoutUrl = pathResolve(rootApi, `api/v1/external/users/logout`);

    service
      .post(logoutUrl, {}, authorization)
      .then((res) => {
        setLoading(false);
        setModal({ isOpen: false });
        ToastSuccess(
          _.get(
            res,
            "success[0].detail",
            "Successfully logged out other session."
          )
        );
        dispatch(loginOTP(data.credentials));
      })
      .catch(() => {
        setLoading(false);
        setModal({ isOpen: false });
        ToastError("Something went wrong logging out existing session");
      });
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="prepend-form-right">
            <label className="form-label">
              Your active session on another device/browser will be logged-out,
              would you like to proceed?
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <Button
              type="submit"
              className="btn btn-primary position-relative font-weight-bold mr-2"
              loading={loading}
            >
              Continue
            </Button>
            <Button
              type="button"
              onClick={closeModal}
              className="btn btn-danger position-relative font-weight-bold ml-2"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

const modalKey = "session-confirm";
const modalConfig = {
  title: "Session Confirmation",
  size: "modal-md",
};

export default withModal(modalKey, modalConfig)(Session);
