import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as req from "api/actions";
import _ from "lodash";
import * as c from "../constants";

export const mapStateToProps = ({ api }) => ({
  auth: _.get(api, c.AUTH) || {},
});

const getProfile = () => {
  try {
    return JSON.parse(sessionStorage.getItem("profile")) || {};
  } catch (err) {
    return {};
  }
};

export const hocComponent = (WrappedComponent) => {
  function WithAuth(props) {
    const { auth, setItem } = props;
    useEffect(() => {
      const token = sessionStorage.getItem("token");
      setItem(c.AUTH, {
        token,
        isAuthenticated: !!token,
      });
      setItem(c.USER_PROFILE, getProfile());
    }, [setItem]);
    if (_.isEmpty(auth)) return null;
    return <WrappedComponent {..._.omit(props, ["setItem"])} />;
  }
  WithAuth.propTypes = {
    auth: PropTypes.instanceOf(Object).isRequired,
    setItem: PropTypes.func.isRequired,
  };
  return WithAuth;
};

export default (WrapperComponent) =>
  connect(mapStateToProps, { ..._.pick(req, ["setItem"]) })(
    hocComponent(WrapperComponent)
  );
