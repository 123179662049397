import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AgentClusters from './containers/AgentClusters';

const AgentClustersMain = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/:clusterId?`} component={AgentClusters} />
    </Switch>
  );
};

export default AgentClustersMain;
