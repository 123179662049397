import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import withModal from 'modules/common/hoc/withModal';
import { ModalContext } from 'App';
import { ToastSuccess } from 'modules/common/components/Toast';
import ReactInputMask from 'react-input-mask';
import * as actions from '../actions';
import * as c from '../constant';

function SetPin({
  data,
  inquireMpin,
  updatePin,
  mobile_identification_number,
  showRetailer,
  retailerId,
  isLoading,
  profileType,
  roles,
  showRetailerUses,
}) {
  const setModal = React.useContext(ModalContext);
  const [state, setState] = React.useState({});
  const isRetailerUser =
    (profileType === 'retailer_users' &&
      _.get(roles, '0', '') === 'branch-admin') ||
    _.get(roles, '0', '') === 'frontliner';
  const isKapartnerUser =
    profileType === 'retailers' && _.get(roles, '0', '') === 'kapartner-user';

  const closeModal = e => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (isKapartnerUser) {
      const mpinPayload = {
        grant_type: 'mpin',
        mobile_identification_number,
        mpin: _.get(state, 'mpin'),
      };
      const updatePayload = {
        ...data,
        mpin: _.get(state, 'mpin'),
      };
      inquireMpin(mpinPayload, () => {
        updatePin(updatePayload, res => {
          showRetailer(retailerId);
          setModal({ isOpen: false });
          if (_.get(res, 'data.attributes.is_pin_enabled') === true) {
            ToastSuccess('Successfully Enabled');
          } else {
            ToastSuccess('Successfully Disabled.');
          }
        });
      });
    }
    if (isRetailerUser) {
      const mpinPayload = {
        grant_type: 'mpin',
        mobile_identification_number,
        mpin: _.get(state, 'mpin'),
      };
      const updatePayload = {
        ...data,
        mpin: _.get(state, 'mpin'),
      };
      inquireMpin(mpinPayload, () => {
        updatePin(updatePayload, res => {
          showRetailerUses();
          setModal({ isOpen: false });
          if (_.get(res, 'data.attributes.is_pin_enabled') === true) {
            ToastSuccess('Successfully Enabled');
          } else {
            ToastSuccess('Successfully Disabled.');
          }
        });
      });
    }
  };

  const handleChangeInput = key => ({ target }) => {
    setState({
      ...state,
      [key]: target.value,
    });
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <label className="form-label text-primary text-center">
            Enter your 6-Digit Security PIN
          </label>
          <ReactInputMask
            type="password"
            placeholder="6-Digit PIN"
            mask="999999"
            maskChar=""
            className="form-control form-custom form-control_bottom"
            name="mpin"
            required
            onChange={handleChangeInput('mpin')}
            value={_.get(state, 'mpin') || ''}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <button
              type="submit"
              className="btn btn-primary font-weight-bold mr-2"
              disabled={isLoading}
            >
              Submit
            </button>
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

SetPin.propTypes = {
  inquireMpin: PropTypes.func.isRequired,
  updatePin: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  mobile_identification_number: PropTypes.string.isRequired,
  showRetailer: PropTypes.func.isRequired,
  retailerId: PropTypes.string.isRequired,
  profileType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showRetailerUses: PropTypes.func.isRequired,
};

const mapStateToProps = ({ api, modal }) => ({
  data: _.get(modal, 'modalData.data'),
  mobile_identification_number: _.get(
    modal,
    'modalData.mobile_identification_number'
  ),
  retailerId: _.get(api, 'AUTH/user_profile.item.retailer_id') || '',
  isLoading:
    api.loading.indexOf(c.MPIN) > -1 &&
    api.loading.indexOf(c.UPDATE_PROFILE) > -1,
  profileType: _.get(api, 'AUTH/user_profile.item.profile_type') || '',
  roles: _.get(api, 'AUTH/user_profile.item.roles') || [],
});

const enhance = _.flowRight([
  withModal('set-mpin', {
    title: '',
    size: 'modal-sm',
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(SetPin);
