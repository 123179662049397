import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import withModal from 'modules/common/hoc/withModal';
import GenericTable from 'modules/common/components/GenericTable';
import { ToastError } from 'modules/common/components/Toast';
import { service } from 'api/service';
import { GET_FAILED_IMPORTS as GFI } from '../constants';
import * as actions from '../actions';

const downloadAs = async (url, name) => {
  try {
    const response = await service.get(url, {
      headers: { 'Content-Type': 'application/octet-stream' },
      responseType: 'blob',
    });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(response.data);
    a.download = name;
    a.click();
    a.remove();
  } catch {
    //
    ToastError('Download failed!');
  }
};

const ViewErrorLogs = () => {
  const dispatch = useDispatch();
  const failedImports = useSelector(({ api }) => api?.[GFI]?.list);

  useEffect(() => {
    const params = {
      sort_by: 'desc',
      order_by: 'created_at',
    };
    dispatch(actions.getFailedImports({ ...params, type: 'retailers' }));
  }, [dispatch]);

  return (
    <>
      <GenericTable
        key="something-funky"
        data={failedImports}
        format={[
          {
            key: item => {
              const dateTime = moment(item?.attributes?.created_at).format(
                'LLL'
              );
              return <span className="text-sm">{dateTime}</span>;
            },
            label: 'Date Uploaded',
          },
          {
            key: item => {
              const fileName = moment(item?.attributes?.created_at).format(
                'YYYY-MM-DD'
              );
              const fileUrl = item?.attributes?.file_url || '';
              if (!fileUrl) return null;
              return (
                <span className="text-sm">
                  <button
                    type="button"
                    className="btn btn-anchor"
                    onClick={() =>
                      downloadAs(fileUrl, `Error-logs-${fileName}.csv`)
                    }
                  >
                    <i className="fas fa-download text-primary" />
                  </button>
                </span>
              );
            },
            className: 'text-center',
            label: 'Download',
          },
        ]}
        headerClassName="bg-gray-600"
        containerClassName="text-sm relative table-sticky border"
        customStyles={{ overflow: 'auto', maxHeight: '70vh' }}
      />
    </>
  );
};

const modalKey = 'view-batch-retailer-error-logs';
const modalConfig = {
  title: 'Error Logs',
  size: 'modal-md',
};

export default withModal(modalKey, modalConfig)(ViewErrorLogs);
