import { ajax as rxAjax } from "rxjs/ajax";
import { rootApi as baseURL } from "helper";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import _ from "lodash";
import axios from "axios";
import store from "store";
import { LOGOUT_SUCCESS } from "modules/auth/constants";
const sessionGet = (key) => sessionStorage.getItem(key);

const jsonToQueryString = (json) =>
  `?${Object.keys(json)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(json[key]) || ""}`,
    )
    .join("&")}`;

const extraHeader = {
  "Content-Type": "application/vnd.api+json",
  Accept: "application/json",
};

function refreshToken() {
  // NOTE CYCLE : (AS PER SIR ED)
  // As long as user is navigating thru site allowed user to get new token or refresh token.
  // If user stop navigating and meets the expiry dateTime user will force to logout (token expired).

  if (!sessionStorage.getItem("token")) return;

  const initialToken = sessionStorage.getItem("token");
  const refreshToken = sessionStorage.getItem("refresh_token");
  const isRefreshTokenUsed = sessionStorage.getItem("is_refresh_token_used");
  const refreshTokenAccess = sessionStorage.getItem("new_token");
  const refreshTokenRefresh = sessionStorage.getItem("new_refresh_token");

  const headers = initialToken
    ? {
        Authorization: `Bearer ${initialToken}`,
        ...extraHeader,
      }
    : {
        ...extraHeader,
      };

  const decodeToken = jwt_decode(initialToken);

  const isExpired = dayjs.unix(decodeToken?.exp).diff(dayjs()) < 300000;
  // set difference to 5 minutes

  // if (isRefreshTokenUsed) {
    //logout token
  //   if (initialToken === refreshToken && isExpired) {
  //     store.dispatch({ type: LOGOUT_SUCCESS });
  //     return;
  //   }

  if (!isExpired) return;

  if(refreshTokenAccess && refreshTokenRefresh){
    if (initialToken !== refreshTokenAccess && isExpired) {
      sessionStorage.setItem("token", refreshTokenAccess);
      sessionStorage.setItem("refresh_token", refreshTokenRefresh);
      sessionStorage.setItem("new_token", "");
      sessionStorage.setItem("new_refresh_token", "");
    }
    return;
  }

  //   if (initialToken !== refreshTokenRefresh && isExpired) {
  //     sessionStorage.setItem("token", refreshTokenRefresh);
  //     return;
  //   }
  //   return;
  // }

  if (!isRefreshTokenUsed) {
    if (isExpired) {
      if (initialToken !== refreshToken) {
        sessionStorage.setItem("token", refreshToken);
        rxAjax({
          url: `${baseURL}/api/v2/auth/refresh`,
          method: "POST",
          responseType: "json",
          headers,
          body: JSON.stringify({
            refresh_token: refreshToken,
            is_for_testing:
              process.env.REACT_APP_END_POINT ===
              "https://api.stg.dms.smartpldt.ph/",
          }),
        }).done((res) => {
          // is_refresh_token_used set to default for repeating cycle.
          sessionStorage.setItem("is_refresh_token_used", false);
          sessionStorage.setItem("new_token", res?.data?.token);
          sessionStorage.setItem("new_refresh_token", res?.data?.refresh_token);
        });
      }
    }
    return;
  }
}
const throttledRefreshToken = _.throttle(refreshToken, 5000);

const get = (url, params = {}) => {
  const token = sessionGet("token");
  throttledRefreshToken();
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        ...extraHeader,
      }
    : {
        ...extraHeader,
      };

  return rxAjax({
    url: `${baseURL}${url}${jsonToQueryString(params)}`,
    method: "GET",
    responseType: "json",
    headers,
  });
};

const post = (url, params = {}) => {
  const token = sessionGet("token");
  throttledRefreshToken();
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        ...extraHeader,
      }
    : {
        ...extraHeader,
      };

  return rxAjax({
    url: `${baseURL}${url}`,
    method: "POST",
    responseType: "json",
    headers,
    body: JSON.stringify({
      ...params,
    }),
  });
};

const put = (url, params = {}) => {
  const token = sessionGet("token");
  throttledRefreshToken();
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        ...extraHeader,
      }
    : {
        ...extraHeader,
      };

  return rxAjax({
    url: `${baseURL}${url}`,
    method: "PUT",
    responseType: "json",
    headers,
    body: JSON.stringify({
      ...params,
    }),
  });
};

const remove = (url, payload = {}) => {
  const token = sessionGet("token");
  throttledRefreshToken();
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        ...extraHeader,
      }
    : {
        ...extraHeader,
      };

  return rxAjax({
    url: `${baseURL}${url}`,
    method: "DELETE",
    responseType: "json",
    headers,
    body: JSON.stringify({
      ...payload,
    }),
  });
};

const onErr = (constant, errCallback) => (error) =>
  new Promise((resolve) => {
    if (errCallback) errCallback();
    resolve({
      type: "ON_ERROR",
      error,
      key: constant,
    });
  });

const postFormData = (url, formData) => {
  const token = sessionGet("token");
  throttledRefreshToken();
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};

  return rxAjax({
    url: `${baseURL}${url}`,
    method: "POST",
    responseType: "json",
    headers,
    body: formData,
  });
};

const download = async (key, url, fileName, callback) => {
  const token = sessionGet("token");
  throttledRefreshToken();
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        ...extraHeader,
      }
    : {
        ...extraHeader,
      };
  return new Promise(async (resolve, reject) => {
    try {
      const raw = await fetch(`${baseURL}${url}`, {
        method: "GET",
        headers,
      });
      const blob = await raw.blob();
      blob.fileName = fileName || "download";
      const output_url = await URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = output_url;
      downloadLink.download = fileName;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      resolve({
        key,
        url,
        fileName,
        callback,
      });
    } catch (err) {
      reject(key, url, fileName, callback);
    }
  });
};

export { post, put, get, remove, postFormData, download, onErr };

export const service = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/vnd.api+json",
    Accept: "application/json",
  },
});

const interceptRequest = (config) => {
  const token = sessionStorage.getItem("token");
  return token
    ? _.set(config, "headers.Authorization", `Bearer ${token}`)
    : config;
};

service.interceptors.request.use(interceptRequest);

const errorResponseIntercept = (error) => {
  const { status } = error?.response;
  const exp = _.get(error, "response.exp");
  const reasons = _.get(error, "response.message");

  if (status === 401) {
    if (exp === "token expired" || reasons === "token expired") {
      store.dispatch({ type: LOGOUT_SUCCESS });
    }
  }

  return Promise.reject(error);
};

service.interceptors.response.use(
  (response) => response,
  errorResponseIntercept,
);
