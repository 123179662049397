import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ModalContext } from 'App';
import Actions from 'components/Actions';
import { checkPermissions, join } from 'helper';
import * as c from 'modules/LoadWalletManagement/constant';
import { downloadRetailerSimHistory } from 'modules/LoadWalletManagement/actions';

const button = `mt-0 btn btn-block btn-default py-1 rounded-0 text-right`;
const button_bt = join`${button} border-top`;

function RetailerSimActions({ item, dealerRole }) {
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);

  //   RETAILER
  const handleUpdateRetailerModal = () =>
    setModal('fill-update-retailer-sim', { data: item, dealerRole });

  const handleDetails = () =>
    setModal('fill-update-retailer-sim', { data: item, type: 'DETAILS' });

  const handleThreshold = () => setModal('retailer-threshold', { data: item });
  const handleDownloadSimsHistory = () =>
    dispatch(downloadRetailerSimHistory(item.id, {}));
  const handleViewBalance = () => setModal('balance', item);
  const payload = {
    is_blocked: true,
    id: item?.id,
    mobile_identification_number:
      item?.attributes?.mobile_identification_number,
  };
  const handleBlock = () => {
    setModal('retailer-sim-confirm-password', {
      ...payload,
      sim_status: c.SIM_STATUS.blocked,
      tempSubmitType: c.SUBMIT_TYPE.block,
    });
  };
  const handleUnblock = () => {
    setModal('retailer-sim-confirm-password', {
      ...payload,
      sim_status: c.SIM_STATUS.active,
      tempSubmitType: 'unblock',
    });
  };

  const handleUnlink = () => setModal('delete-retailer-sim', { data: item });
  const isBlocked = item?.attributes?.sim_status?.includes(c.SIM_STATUS.blocked);
  const ACL = [
    {
      id: 'update',
      label: 'Update',
      class: button_bt,
      display: !isBlocked,
      handler: handleUpdateRetailerModal,
      permission: ['can-update-retailer-sim'],
    },
    {
      id: 'view-balance',
      label: 'View Balance',
      class: button_bt,
      display: !isBlocked,
      handler: handleViewBalance,
      permission: ['can-view-balance'],
    },
    {
      id: 'threshold',
      label: 'Threshold',
      class: button_bt,
      display: !isBlocked,
      handler: handleThreshold,
      permission: ['can-update-retailer-sim'],
    },
    {
      id: 'history',
      label: 'History',
      class: button_bt,
      display: !isBlocked,
      handler: handleDownloadSimsHistory,
      permission: ['can-view-balance'],
    },
    {
      id: 'unlink',
      label: 'Delete',
      class: button_bt,
      display: true,
      handler: handleUnlink,
      permission: ['can-delete-retailer-sim'],
    },
    {
      id: 'block',
      label: 'Block',
      class: button_bt,
      display: !isBlocked,
      handler: handleBlock,
      permission: ['can-update-retailer-sim'],
    },
    {
      id: 'unblock',
      label: 'Unblock',
      class: button_bt,
      display: isBlocked,
      handler: handleUnblock,
      permission: ['can-update-retailer-sim'],
    },
    {
      id: 'details',
      label: 'Details',
      class: button_bt,
      display: false,
      handler: handleDetails,
      permission: ['can-view-retailer-sim'],
    },
  ];

  return (
    <Actions id={item?.id}>
      {ACL?.map(
        action =>
          checkPermissions(action?.permission) &&
          action?.display && (
            <button
              key={action?.id}
              type="button"
              className={action?.class}
              onClick={action?.handler}
            >
              <small>{action?.label}</small>
            </button>
          )
      )}
    </Actions>
  );
}
RetailerSimActions.defaultProps = {
  dealerRole: '',
};

RetailerSimActions.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  dealerRole: PropTypes.string,
};

export default RetailerSimActions;
