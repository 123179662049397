import React, { useContext, useState } from "react";
import withModal from "modules/common/hoc/withModal";
import _ from "lodash";
import { ModalContext } from "App";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastSuccess } from "modules/common/components/Toast";
import { useLoading } from "modules/ui/hooks";
import ButtonAsync from "components/Button";
import * as c from "../constant";
import * as actions from "../action";

function RemoveBranchForm() {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);
  const sub_match = useRouteMatch(`${match.path}/:id`);
  const branchId = _.get(sub_match, "params.id");
  const roles = useSelector(({ api }) =>
    _.get(api, "AUTH/user_profile.item.roles")
  );
  const [formData, setFormData] = useState({ password: "" });
  const [view, setView] = useState(false);
  const [pending, setPending] = useState(false);

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleOnChange = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const handleOnViewPassword = (value) => (e) => {
    e.preventDefault();
    setView(value);
  };

  const validating = useLoading(c.VALIDATE_PASSWORD);
  const updating = useLoading(c.GET_BRANCH);

  const onError = () => {
    setPending(false);
    setFormData({ password: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);

    const onSuccess = () =>
      dispatch(
        actions.removeBranch(
          branchId,
          () => {
            ToastSuccess("Successfully Removed.");
            setModal({ isOpen: false });
            setPending(false);
            dispatch(
              actions.getBranch({}, (res) => {
                if (!_.get(roles, "0", "")?.includes("branch-admin")) {
                  return history.push(
                    `/manage/branch/${_.get(
                      res,
                      "data.0.id",
                      ""
                    )}/branch-admins`
                  );
                }
                if (_.get(roles, "0", "")?.includes("branch-admin")) {
                  return history.push(
                    `/manage/branch/${_.get(res, "data.0.id", "")}/frontliners`
                  );
                }
                return null;
              })
            );
          },
          onError
        )
      );

    dispatch(actions.validatePassword(formData, onSuccess, onError));
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="text-center">
        <span className="font-weight-bold">
          Are you sure you want to delete this Branch?
        </span>
      </div>
      <div className="text-center">
        <small>Your password is required to continue.</small>
      </div>
      <div className="form-group">
        <div className="prepend-form-right">
          <label className="form-label text-success">Password:</label>
          <div className="prepend-input-group">
            <input
              type={view ? "text" : "password"}
              className="form-control form-custom form-control_bottom"
              required
              autoFocus
              name="password"
              value={_.get(formData, "password") || ""}
              onChange={handleOnChange}
            />
          </div>
          <button
            type="button"
            onClick={handleOnViewPassword(!view)}
            className="btn btn-default rounded-circle p-0 mr-1"
          >
            <i className={`fas ${!view ? "fa-eye" : "fa-eye-slash"}`} />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-center">
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold mr-2"
            >
              CANCEL
            </button>
            <ButtonAsync
              type="submit"
              loading={pending || validating || updating}
              disabled={_.get(formData, "password").length < 1}
            >
              PROCEED
            </ButtonAsync>
          </div>
        </div>
      </div>
    </form>
  );
}

const modalKey = "remove-branch";
const modalConfig = { title: "", size: "modal-md" };

export default withModal(modalKey, modalConfig)(RemoveBranchForm);
