import React, { useState, useMemo, useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  NavLink,
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  withRouter,
} from "react-router-dom";
import { removeEmpty } from "helper";
import { useLoading } from "modules/ui/hooks";
import Filter from "components/Filter";
import Range from "components/Range";
import R2STable from "./components/R2STable";
import D2RTable from "./components/D2RTable";
import * as c from "../constants";
import * as a from "../actions";

function Container() {
  const [filters, setFilter] = useState(c.defaultParams);
  const [cmd, setCmd] = useState("dealertoDealer");
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const location = useLocation();
  const path = location.pathname;

  const data = useSelector(({ api }) =>
    _.get(api, `${c.GET_ELOAD_LIST}.res`, [])
  );

  const list = _.get(data, "data", []);
  const pager = _.get(data, "meta.pagination", []);

  const loading = useLoading(c.GET_ELOAD_LIST);

  const filterParams = useMemo(() => {
    let target = "";
    if (path.includes("D2R")) target = "target_ret_account";
    if (path.includes("R2S")) target = "target_sub_account";
    if (path.includes("D2D")) target = "target_dealer_account";
    return _.pickBy({
      ...filters,
      [target]: filters.target_min.replace("+63", "0"),
      source: filters.source.replace("+63", "0"),
      status: filters?.status?.value,
      target_min: "",
    });
  }, [filters, cmd, path]);

  const command = useMemo(() => {
    if (path.includes("D2R")) return "dealertoRetailer";
    if (path.includes("R2S")) return "retailerToSub";
    if (path.includes("D2D")) return "dealertoDealer";
    return null;
  }, [path]);

  const handleClear = () => setFilter(c.defaultParams);

  const onDownload = () =>
    dispatch(a.downloadReport({ ...filterParams, command }));

  const onSubmit = () => {
    const payload = removeEmpty({
      ...filters,
      status: filters?.status?.value || "",
      page: 1,
      q: _.trim(filters.q),
      command,
    });
    dispatch(a.getBranch(payload));
  };

  const onChangeTab = (arg) => {
    setCmd(arg);
    setFilter((state) => ({ ...state, source: "", q: "" }));
  };

  const handlePager = (page) => {
    dispatch(
      a.getBranch(
        { ...filters, page, command, status: filters?.status?.value || "" },
        setFilter((state) => ({
          ...state,
          page,
        }))
      )
    );
  };

  useEffect(() => {
    if (!command) return;
    setCmd(command);
    const params = {
      ...filters,
      command,
      page: 1,
      status: filters?.status?.value || "",
    };
    dispatch(a.getBranch(params));
  }, [
    filters?.status?.value,
    filters?.branch_id,
    filters?.source,
    filters?.target_min,
    filters?.date_from,
    filters?.date_to,
    command,
  ]);

  const form = useMemo(() => {
    return c.form.map((x) => {
      if (x.name === "source" && command === "retailerToSub")
        return {
          ...x,
          api: "partners/api/v1/transactions/source",
          params: { command: "retailerToSub" },
        };
      if (
        x.name === "source" &&
        (command === "dealertoRetailer" || command === "dealertoDealer")
      )
        return {
          ...x,
          api: "partners/api/v1/transactions/source",
          params: { command: "dealertoRetailer" },
        };

      return x;
    });
  }, [c.form, command]);

  return (
    <>
      <div className="container-fluid scroll-lime tw-mt-4 tw-h-full">
        <Filter
          form={form}
          filterData={[filters, setFilter]}
          onDownload={onDownload}
          actions={onSubmit}
          onClear={handleClear}
          loading={loading}
          containerClass="tw-mb-4"
        />
        <div className="row">
          <div className="col d-flex align-items-bottom justify-content-between mb-3">
            <div className="d-flex flex-column justify-content-center ">
              <div className="font-weight-bold text-xl">
                Eload API Gateway Report
              </div>
              <Range date_from={filters.date_from} date_to={filters.date_to} />
            </div>
          </div>
        </div>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <NavLink
              to={`${match.path}/D2R`}
              onClick={() => onChangeTab("dealertoRetailer")}
              className="nav-link px-5"
              activeClassName="active"
            >
              D2R
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={`${match.path}/R2S`}
              onClick={() => onChangeTab("retailerToSub")}
              className="nav-link px-5"
              activeClassName="active"
            >
              R2S
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={`${match.path}/D2D`}
              onClick={() => onChangeTab("dealertoDealer")}
              className="nav-link px-5"
              activeClassName="active"
            >
              D2D
            </NavLink>
          </li>
        </ul>
        <div
          className="card-body p-0"
          style={{ borderTop: "6px solid #049051" }}
        >
          <Switch>
            <Route exact path={match.path}>
              <Redirect to={`${match.path}/D2R`} />
            </Route>
            <Route path={[`${match.path}/D2R`, `${match.path}/D2D`]}>
              <D2RTable
                data={list}
                loading={loading}
                handlePager={handlePager}
                pager={pager}
              />
            </Route>
            <Route path={`${match.path}/R2S`}>
              <R2STable
                data={list}
                loading={loading}
                handlePager={handlePager}
                pager={pager}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
}

export default withRouter(Container);
