export const CREATE_CONSENT = 'FIRST_LOGIN/create_consent';
export const GET_CONSENT = 'FIRST_LOGIN/get_terms';
export const USER_CONSENT = 'FIRST_LOGIN/user_consent';
export const SUBMIT_CONSENT = 'FIRST_LOGIN/submit_consent';
export const CHANGE_PASSWORD = 'FIRS_LOGIN/change_password';
export const SUBMIT_RETAILER_CONSENT = 'FIRS_LOGIN/submit_retailer_consent';
export const RETAILER_USERS_CHANGE_PASSWORD =
  'FIRS_LOGIN/retailer_users_change_password';
export const RETAILER_CHANGE_PASSWORD = 'FIRS_LOGIN/retailer_change_password';
export const RETAILER_CHANGE_MPIN = 'FIRS_LOGIN/retailer_change_mpin';
export const UPDATE_RETAILER_USER = 'FIRS_LOGIN/update_retailer_user';
export const UPDATE_RETAILER = 'FIRS_LOGIN/update_retailer';
export const UPDATE_MPIN = 'FIRS_LOGIN/update_mpin';

export const UPDATE_PASSWORD_AND_PIN = 'FIRS_LOGIN/update_password_and_pin';

export const SET_FORM_DATA = {};

export const CHECKBOX = [
  {
    id: 1,
    value: false,
    content: 'I have read and understood the Privacy Notice.',
  },
  {
    id: 2,
    value: false,
    content:
      'To the extent that my consent is necessary, I agree with the purpose for which personal data is processed with my use of the Ka-Partner Portal.',
  },
  {
    id: 3,
    value: false,
    content:
      'When I share personal data of other data subjects with Smart in the course of my use of the Ka-Partner Portal, I represent and warrant that I have obtained their consent in the form and following the procedure required by privacy laws.',
  },
];
