import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import store from 'store';

const modalUniqueID = 'my-modal-marker';

export const closeModal = () => {
  const rootElement = document.getElementById(modalUniqueID);
  const prevElem = document.activeElement;
  document.body.className = '';
  render(<div id={modalUniqueID} />, rootElement);
  setTimeout(() => {
    try {
      prevElem.focus();
    } catch (err) {} // eslint-disable-line
  }, 100);
};

export const showModal = (options = {}) => {
  const opt = {
    title: 'Modal',
    content: 'Modal Message',
    headerClassName: '',
    className: '',
    backdrop: false,
    blinkTitle: false,
    noCloseButton: true,
    closeModal: () => {},
    ...options,
  };
  const rootElement = document.getElementById(modalUniqueID);
  if (!rootElement) {
    alert('Alert Marker not found!'); // eslint-disable-line
    return;
  }

  document.body.className = 'modal-open';
  const prevElem = document.activeElement;

  const close = () => {
    document.body.className = '';
    render(<div id={modalUniqueID} />, rootElement);
    setTimeout(() => {
      try {
        prevElem.focus();
      } catch (err) {} // eslint-disable-line
    }, 100);
  };

  const renderContent = () => {
    if (typeof opt.content === 'function') return opt.content(close);
    return opt.content;
  };

  render(
    <Provider store={store}>
      <div id="main-modal">
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: 'block' }}
        >
          <div className={`modal-dialog ${opt.className}`} role="document">
            <div className="modal-content animated fadeIn faster">
              {!!opt.title && typeof opt.title === 'string' && (
                <div
                  className={`modal-header d-flex justify-content-between ${opt.headerClassName}`}
                >
                  <h5 className="modal-title p-0 m-0">{opt.title}</h5>
                  {opt.noCloseButton && (
                    <button type="button" className="close" onClick={close}>
                      <span>&times;</span>
                      <span className="sr-only">Close</span>
                    </button>
                  )}
                </div>
              )}
              {typeof opt.title === 'function' && (
                <div className={`modal-header ${opt.headerClassName}`}>
                  {opt.title()}
                </div>
              )}
              {renderContent()}
            </div>
          </div>
        </div>
        <div
          className={`modal-backdrop fade show${
            !opt.backdrop ? ' bg-hidden' : ''
          }`}
        />
      </div>
    </Provider>,
    rootElement
  );
};

export const ModalMarker = () => <div id={modalUniqueID} />;
