import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import Inquiries from './Inquiries/container/Inquiries';
import HelpDesk from './HelpDesk';
import IssueCategoryContainer from './HelpDesk/container/IssueCategoryContainer';
import IssueContainer from './HelpDesk/container/IssueContainer';

const AssuranceManagement = () => {
  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (match.isExact) {
      history.push(`${match.path}/inquiries`);
    }
  }, [match, history]);

  return (
    <div className="main-content-inner">
      <Switch>
        <Route path={`${match.path}/inquiries`} component={Inquiries} />
        <Route exact path={`${match.path}/helpdesk`} component={HelpDesk} />
        <Route
          exact
          path={`${match.path}/helpdesk/:id?/issue`}
          component={IssueCategoryContainer}
        />
        <Route
          path={`${match.path}/helpdesk/:id?/issue/:issueId?`}
          component={IssueContainer}
        />
      </Switch>
    </div>
  );
};

AssuranceManagement.propTypes = {};

export default AssuranceManagement;
