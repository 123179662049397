import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { inquireBalance, inquireDealerThreshold } from 'app/dashboard/actions';
import { GET_BRANCH } from 'app/dashboard/constant';
import Select from 'react-select';
import Threshold from 'modules/Load/components/LoadThreshold';

const WalletBalance = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    balance: '',
    threshold: '',
  });
  const [selected, setSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const branchDetails = useSelector(({ api }) =>
    _.get(api, `${GET_BRANCH}.item`, [])
  );
  const getDealerSims = () => {
    return _.get(branchDetails, 'included.sources', [])
      .filter(source => source.attributes.type === 'dealer_sims')
      .filter(source => source.attributes.is_active === true)
      .map(source => {
        const {
          mobile_identification_number,
          sim_type,
          sim_id,
          is_default,
        } = source?.attributes;
        return {
          value: mobile_identification_number,
          label: `${mobile_identification_number} ${
            sim_type ? `- ${sim_type}` : ''
          }`,
          dealerMinId: sim_id,
          default: is_default,
        };
      });
  };
  const dealer_sims = getDealerSims();

  const inquire = (id, minValue) => {
    setLoading(true);
    const callback = threshold => {
      dispatch(
        inquireBalance({ mobile: _.replace(minValue, '+63', '0') }, res => {
          setState({
            threshold,
            balance: parseFloat(_.get(res, 'data.current_balance', 0)),
          });
          setLoading(false);
        })
      );
    };
    dispatch(
      inquireDealerThreshold(id, res => {
        callback(_.get(res, 'data.attributes.load_threshold', 0));
      })
    );
  };

  const handleSelectDealerMin = selectedOption => {
    setSelected(selectedOption);
    inquire(selectedOption.dealerMinId, selectedOption.value);
  };

  useEffect(() => {
    setLoading(true);
    if (branchDetails.length === 0) return;
    const default_sim = dealer_sims.find(sim => _.get(sim, 'default'));
    if (default_sim) inquire(default_sim.dealerMinId, default_sim.value);
    else {
      setState({
        threshold: 0,
        balance: 0,
      });
      setLoading(false);
    }
    setSelected(default_sim);
  }, [branchDetails]);

  return (
    <div className="w-100 card shadow-sm">
      <div className="card-header bg-primary">
        <small className="card-title m-0 text-white font-weight-bold">
          Dealer Load Wallet Balance:
        </small>
      </div>
      <div className="card-body">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="form-label text-primary">Dealer MIN:</label>
                <Select
                  options={dealer_sims}
                  onChange={handleSelectDealerMin}
                  value={selected}
                  styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                  isDisabled={loading || _.isEmpty(dealer_sims)}
                  placeholder={
                    _.isEmpty(dealer_sims) ? 'No options' : 'Select MIN'
                  }
                />
              </div>
              <div className="form-group">
                <label className="form-label text-primary mb-3">
                  Load Wallet Threshold:
                </label>
                <Threshold
                  loading={loading}
                  threshold={state.threshold}
                  balance={state.balance}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletBalance;
