import React, { Fragment } from 'react';
import _ from 'lodash';
import DateInputPicker from 'modules/common/components/DateInputPicker';
import PropTypes from 'prop-types';

const MobilePayment = ({ formData, onSelected }) => {
  return (
    <Fragment>
      <div className="form-group">
        <label className="font-weight-bold">Date From:</label>
        <DateInputPicker
          dateFormat="yyyy/MM/dd"
          placeholder="Select Date"
          className="form-control form-custom form-control_bottom w-100"
          onChange={onSelected('date')}
          value={_.get(formData, 'date')}
        />
      </div>
      <div className="font-weight-bold pb-2">
        Deposit Time: <small>09:42:32 am</small>
      </div>
      <div className="form-group">
        <label className="font-weight-bold">Depository Account:</label>
        <select name="account" id="account" className="custom-select">
          <option defaultValue>Select Account</option>
        </select>
      </div>
      <div className="font-weight-bold pt-2">
        Branch Deposited: <small>Ortigas City Branch</small>
      </div>
      <div className="font-weight-bold pt-2">
        Amount Deposited: <small>P10, 000.00</small>
      </div>
      <button type="button" className="btn btn-blue mt-2">
        Attachment
      </button>
    </Fragment>
  );
};

MobilePayment.propTypes = {
  onSelected: PropTypes.func.isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
};

export default MobilePayment;
