export const SIM_ADMIN_ROLES = [
  "super-user",
  "super-user-ka",
  "super-user-b2b",
  "super-user-non-migrated",
  "sub-dealer",
  "sub-dealer-ska",
  "corporate-admin",
  "corporate-admin-ska",
  "corporate-admin-ka",
  "corporate-admin-rd",
  "corporate-admin-smmt",
  "corporate-admin-b2b",
  "corporate-admin-non-migrated",
  "smmt",
];
export const UNLINKED_STATUS = [
  null,
  "delink-done",
  "link-in-progress",
  "link-failed",
];

export const UNLINKED_PARAMS = [
  "null",
  "delink-done",
  "link-in-progress",
  "link-failed",
].join();

export default SIM_ADMIN_ROLES;
