import React, { useState, useContext, useMemo } from "react";
import withModal from "modules/common/hoc/withModal";
import _ from "lodash";
import { ModalContext } from "App";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { USER_PROFILE } from "modules/auth/constants";
import PropTypes from "prop-types";
import ButtonAsync from "components/Button";
import { useLoading } from "modules/ui/hooks";
import { ToastSuccess } from "modules/common/components/Toast";
import * as actions from "../action";
import * as c from "../constant";
import SelectBranchAdmins from "./SelectBranchAdmins";
import SelectShift from "./SelectShift";

function CreateBranchAdmin({
  assign,
  getAttachUsersBA,
  branchId,
  branch_name,
}) {
  const setModal = useContext(ModalContext);
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const isNonMigrated = profile?.roles[0]?.includes("non-migrated");

  const [formData, setFormData] = useState([]);
  const [pending, setPending] = useState(false);

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const id = _.get(formData, "user.value");
    setPending(true);
    const payload = {
      schedule_id: _.get(formData, "schedule.value"),
      branch_id: branchId,
    };
    assign(
      id,
      payload,
      () => {
        setModal({ isOpen: false });
        getAttachUsersBA(branchId, {
          role_slug: isNonMigrated
            ? "branch-admin-non-migrated"
            : "branch-admin",
        });
        ToastSuccess("Successfully assigned Branch and Selling Window.");
        setPending(false);
      },
      () => setPending(false)
    );
  };

  const handleChangeSelect = (key, callback) => (value) => {
    setFormData({
      ...formData,
      [key]: value || {},
    });
    if (callback) callback();
  };

  const isInputEmpty = useMemo(() => {
    const { user, schedule } = formData;
    return _.isEmpty(user) || _.isEmpty(schedule);
  }, [formData]);

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label className="form-label text-success">
              Select Branch Admin:
            </label>
            <SelectBranchAdmins
              onChange={handleChangeSelect("user")}
              value={_.get(formData, "user", {})}
              branchId={branchId}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label className="form-label text-primary">Branch:</label>
            <input
              autoFocus
              type="text"
              name="name"
              readOnly
              value={branch_name}
              className="form-control form-custom form-control_bottom"
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label className="form-label text-success">Select Shift:</label>
            <SelectShift
              onChange={handleChangeSelect("schedule")}
              value={_.get(formData, "schedule", {})}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <ButtonAsync
              type="submit"
              loading={useLoading(c.ASSIGN) || pending}
              disabled={isInputEmpty}
            >
              Add
            </ButtonAsync>
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

CreateBranchAdmin.propTypes = {
  assign: PropTypes.func.isRequired,
  branchId: PropTypes.string.isRequired,
  branch_name: PropTypes.string.isRequired,
  getAttachUsersBA: PropTypes.func.isRequired,
};

const mapStateToProps = ({ api, modal }) => ({
  branchId: _.get(modal, "modalData.branchId") || "",
  branch_name: _.get(modal, "modalData.branch_name") || "",
});

const enhance = _.flowRight([
  withModal("create-branchAdmin", {
    title: "Add Branch Admin",
    size: "modal-lg",
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(CreateBranchAdmin);
