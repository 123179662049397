import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ssv } from 'helper';
import OverflowTip from 'modules/common/components/OverflowTip';
import { UNLINKED_STATUS } from 'app/load-wallet/constant';

export const renderStatus = {
  1: 'bg-primary',
  0: 'bg-danger',
  progress: 'bg-warning',
};

const ListItem = React.memo(({ isActive, data, onClick }) => {
  const evc_status = _.get(data, 'attributes.evc_status');

  const isInProgress = evc_status ? evc_status.includes('in-progress') : false;
  const link_status = isInProgress
    ? 'progress'
    : +!UNLINKED_STATUS.includes(evc_status);

  const fullName = _.get(data, 'attributes.full_name');

  const handleClick = () => {
    onClick(data);
  };

  return (
    <div
      tabIndex={-1}
      role="menuitem"
      onKeyUp={() => {}}
      className={ssv(
        'd-flex justify-content-between align-items-center px-3 border-top border-gray-200',
        isActive ? 'bg-gray-100 font-weight-bolder' : ''
      )}
      style={{ height: 55, cursor: 'pointer' }}
      onClick={handleClick}
    >
      <OverflowTip
        id={fullName}
        text={fullName}
        toUpperCase
        className="pr-2 "
        isSmall
      />
      <div>
        <div
          className={ssv(`rounded`, renderStatus[link_status])}
          style={{ width: 7, height: 7 }}
        />
      </div>
    </div>
  );
});

ListItem.propTypes = {
  isActive: PropTypes.bool,
  data: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
};

ListItem.defaultProps = {
  isActive: false,
  onClick: () => {},
};

export default ListItem;
