import * as req from 'api/actions';
import * as c from './constants';

export const getMobileContents = (payload, callback) =>
  req.list(c.GET_MOBILE_CONTENTS, 'cms/api/v1/contents', payload, callback);

export const showMobileContents = (id, payload, callback) =>
  req.item(
    c.SHOW_MOBILE_CONTENTS,
    `api/v1/contents/slug/${id}`,
    payload,
    callback
  );
