import React, { useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ModalContext } from 'App';
import Actions from 'components/Actions';
import moment from 'moment';
import CardItem from 'modules/common/components/CardItem';
import TabDetails from 'modules/LoadWalletManagement/components/TabDetails';

const buttonClass = `mt-0 btn btn-block btn-default p-0 rounded-0 text-right`;

const SpecialPrice = ({ data, dataLoading }) => {
  const setModal = useContext(ModalContext);
  const overideSpecialPrice = item =>
    setModal('fill-update-overide-special-price', { data: item });
  const specialPriceAction = item => {
    return (
      <Actions
        id={item.id}
        containerClassName="dropdown"
        buttonClassName="btn btn-link p-0"
      >
        <button
          type="button"
          className={`${buttonClass}`}
          onClick={() => {
            overideSpecialPrice(item);
          }}
        >
          <small>OVERIDE</small>
        </button>
      </Actions>
    );
  };

  return (
    <>
      <TabDetails
        isLoading={dataLoading}
        containerClass="DeliveryAddressInformation mb-4 border-1 w-100"
        headerTitle={() => {
          return (
            <>
              <div
                className="header-title my-auto d-flex align-items-center"
                style={{ height: 39 }}
              >
                <h6 className="h6 text-md-size font-weight-bold p-0 m-0">
                  Special Price
                </h6>
              </div>
            </>
          );
        }}
        data={data}
        render={(item, index) => {
          return (
            <div className="col-lg-4 col-xl-12 pb-3 pb-lg-3" key={index}>
              <div className="card">
                <div className="row card-body">
                  <div className="col row pr-0 d-flex">
                    <CardItem
                      label="Special Price"
                      description={_.get(item, 'attributes.special_price')}
                    />
                    <CardItem
                      label="Product Category"
                      description={_.get(item, 'attributes.category')}
                    />
                    <CardItem
                      label="Value"
                      description={_.get(item, 'attributes.category_value')}
                    />
                    <CardItem
                      label="Effectivity"
                      description={
                        moment(_.get(item, 'attributes.start_date')).format(
                          'll'
                        ) || ''
                      }
                    />
                    <CardItem
                      label="Expiry"
                      description={
                        moment(_.get(item, 'attributes.expiry_date')).format(
                          'll'
                        ) || ''
                      }
                    />
                    <CardItem
                      label="Overide"
                      description={
                        _.get(item, 'attributes.is_overridable')
                          ? 'Enabled'
                          : 'Disabled'
                      }
                    />
                  </div>
                  <div className="col-1 text-right">
                    <div>
                      {_.get(item, 'attributes.is_overridable')
                        ? specialPriceAction(item)
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      />
    </>
  );
};

SpecialPrice.defaultProps = {
  dataLoading: false,
};

SpecialPrice.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  dataLoading: PropTypes.bool,
};

export default SpecialPrice;
