import * as req from 'api/actions';
import * as c from './constant';

export const getIssueCategory = (payload, callback) =>
  req.list(c.GET_ISSUE_CATEGORY, 'api/v1/issue_categories', payload, callback);

export const showIssueCategory = (id, payload, callback) =>
  req.item(
    c.SHOW_ISSUE_CATEGORY,
    `api/v1/issue_categories/${id}`,
    payload,
    callback
  );

export const getIssue = (id, payload, callback) =>
  req.list(
    c.GET_ISSUE,
    `api/v1/issue_categories/${id}/issues`,
    payload,
    callback
  );

export const showIssue = (id, issueId, payload, callback) =>
  req.item(
    c.SHOW_ISSUE,
    `api/v1/issue_categories/${id}/issues/${issueId}`,
    payload,
    callback
  );

export const createTicket = (payload, callback) =>
  req.create(c.CREATE_TICKET, 'api/v1/tickets', payload, callback);

export const updateTicketbyStatus = (ticketId, payload, callback) =>
  req.update(
    c.UPDATE_TICKET_BY_STATUS,
    `api/v1/tickets/${ticketId}/status`,
    payload,
    callback
  );

export const getTicket = (payload, callback) =>
  req.list(c.GET_TICKET, 'api/v1/tickets', payload, callback);

export const showTicket = (ticketId, payload, callback) =>
  req.item(c.SHOW_TICKET, `api/v1/tickets/${ticketId}`, payload, callback);

export const getMessage = (ticketId, payload, callback) =>
  req.list(
    c.GET_MESSAGE,
    `api/v1/tickets/${ticketId}/messages`,
    payload,
    callback
  );

export const createMessage = (ticketId, payload, callback) =>
  req.create(
    c.CREATE_MESSAGE,
    `api/v1/tickets/${ticketId}/messages`,
    payload,
    callback
  );

export const getNote = (ticketId, payload, callback) =>
  req.list(c.GET_NOTE, `api/v1/tickets/${ticketId}/notes`, payload, callback);

export const createNote = (ticketId, payload, callback) =>
  req.create(
    c.CREATE_NOTE,
    `api/v1/tickets/${ticketId}/notes`,
    payload,
    callback
  );

export const getAssignee = (ticketId, payload, callback) =>
  req.listEternal(
    c.GET_ASSIGNEE,
    `api/v1/tickets/${ticketId}/assignees`,
    payload,
    callback
  );

export const getStatus = (ticketId, payload, callback) =>
  req.list(
    c.GET_STATUS,
    `api/v1/tickets/${ticketId}/statuses`,
    payload,
    callback
  );

export const getFiles = (ticketId, payload, callback) =>
  req.list(c.GET_FILES, `api/v1/tickets/${ticketId}/files`, payload, callback);
