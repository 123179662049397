import React from 'react';
import withModal from 'modules/common/hoc/withModal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ModalContext } from 'App';
import ButtonAsync from 'components/Button';
import * as actions from '../actions';

const DelinkingDealerSim = ({ data }) => {
  const setModal = React.useContext(ModalContext);

  const handleClickCloseModal = () => setModal({ isOpen: false });

  const handleClick = () => {
    const payload = {
      ...data?.attributes,
      id: data?.id,
      tempSubmitType: 'delete',
    };

    setModal('dealer-sim-confirm-password', payload);
  };

  return (
    <div className="p-2">
      <div className="modal-description mb-4 pb-1">
        <p
          className="font-weight-bold"
          style={{ fontSize: '.7rem', color: 'rgba(0, 0, 0, .6)' }}
        >
          This will delete the selected and all its contents.
        </p>
        <h6 className="h6 mt-0 font-weight-bold">
          Are you sure you want to proceed ?
        </h6>
      </div>
      <div className="buttons d-flex align-items-center justify-content-end">
        <ButtonAsync
          type="submit"
          onClick={handleClick}
          style={{ fontSize: '.7rem' }}
          className="btn btn-success position-relative d-flex justify-content-center align-items-center px-4 py-2 text-uppercase"
        >
          yes
        </ButtonAsync>
        <button
          type="button"
          className="btn ml-2 px-3 py-2 text-uppercase"
          style={{
            background: 'rgba(0, 0, 0, .2)',
            fontSize: '.7rem',
            color: 'rgba(0, 0, 0, .7)',
          }}
          onClick={handleClickCloseModal}
        >
          cancel
        </button>
      </div>
    </div>
  );
};

DelinkingDealerSim.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ modal }) => ({
  data: _.get(modal, 'modalData.data') || {},
});

const enhance = _.flowRight([
  withModal('delink-dealer-sim', {
    title: 'Delete',
    size: 'modal-sm',
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(DelinkingDealerSim);
