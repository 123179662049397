import * as req from 'api/actions';
import * as c from './constant';

export const getConsent = (payload, callback) =>
  req.list(c.GET_CONSENT, 'cms/api/v1/consents', payload, callback);

export const changePassword = (payload, callback, errCallback) =>
  req.update(
    c.CHANGE_PASSWORD,
    'api/v1/external/users/change_password',
    payload,
    callback,
    {},
    errCallback
  );

export const createConsent = (payload, callback) =>
  req.create(
    c.CREATE_CONSENT,
    'api/v1/external/users/consents',
    payload,
    callback
  );

export const listConsent = (payload, callback) =>
  req.list(c.USER_CONSENT, 'api/v1/external/users/consents', payload, callback);

export const submitConsent = (payload, callback) =>
  req.create(
    c.SUBMIT_CONSENT,
    'api/v1/external/users/consents',
    payload,
    callback
  );

export const submitRetailerConsent = (retailerId, payload, callback) =>
  req.create(
    c.SUBMIT_RETAILER_CONSENT,
    `api/v1/external/users/consents`,
    payload,
    callback,
    retailerId
  );

export const retailerUserChangePassword = (payload, callback) =>
  req.update(
    c.RETAILER_USERS_CHANGE_PASSWORD,
    'api/v1/external/users/change_password',
    payload,
    callback
  );

export const retailerChangePassword = (payload, callback) =>
  req.create(
    c.RETAILER_CHANGE_PASSWORD,
    'api/v1/external/users/change_password',
    payload,
    callback
  );

export const updateProfile = (payload, callback) =>
  req.update(
    c.UPDATE_RETAILER_USER,
    'api/v1/external/users/my/profile',
    payload,
    callback
  );

export const updateRetailerProfile = (payload, callback) =>
  req.update(
    c.UPDATE_RETAILER,
    'api/v1/retailers/update-retailer',
    payload,
    callback
  );

export const updateMPIN = (payload, callback) =>
  req.update(
    c.UPDATE_MPIN,
    'api/v1/external/users/change_mpin',
    payload,
    callback
  );

export const updateRetailerMPIN = (payload, callback) =>
  req.create(c.UPDATE_MPIN, 'api/v1/retailers/change-mpin', payload, callback);

export const updatePasswordAndMPin = (payload, callback, errCallback) =>
  req.update(
    c.UPDATE_PASSWORD_AND_PIN,
    'api/v1/external/users/change_password_and_mpin',
    payload,
    callback,
    {},
    errCallback
  );

export function organizeConsents(consentsArray, priority, profile) {
  return (
    consentsArray
      // filter only shared consent and matching account type ids
      .filter(consent => {
        const isAccountTypeIdMatch =
          `${consent?.attributes?.account_type_id}` ===
          `${profile?.account_type_id}`;
        if (!consent?.attributes?.account_type_id) return true;
        if (isAccountTypeIdMatch) return true;
        return false;
      })
      // prioritization
      .sort((_a, _b) => {
        if (_a?.attributes?.title.includes(priority)) return -1;
        if (_b?.attributes?.title.includes(priority)) return 1;
        return 0;
      })
  );
}
