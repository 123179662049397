import * as req from 'api/actions';
// import _ from 'lodash';
import * as c from './constant';

export const getProduct = (payload, callback) =>
  req.list(c.GET_PRODUCT, 'api/v1/products', payload, callback);

export const getCategory = (payload, callback) =>
  req.list(c.GET_CATEGORY, 'api/v1/product/categories', payload, callback);

export const getCart = (payload, callback) =>
  req.list(c.GET_CART, 'api/v1/cart', payload, callback);

export const addToCart = (payload, callback) =>
  req.create(c.ADD_CART, 'api/v1/cart', payload, callback);

export const removeItemById = (id, payload, callback) =>
  req.remove(c.ADD_CART, `api/v1/cart/${id}`, payload, callback);

export const createOrder = (payload, callback) =>
  req.create(c.CREATE_ORDER, 'api/v1/orders', payload, callback);

export const getDealerProfile = (id, payload, callback) =>
  req.item(c.GET_DEALER_PROFILE, `api/v1/dealers/${id}`, payload, callback);

export const getOrder = (id, payload, callback) =>
  req.list(c.GET_ORDER, 'api/v1/orders', payload, callback);
