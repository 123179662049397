import React, { useEffect } from 'react';
import {
  NavLink,
  Switch,
  Route,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import Accounts from './container/Accounts';
import Queue from './container/Queue';

const Inquiries = () => {
  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    history.push(`${match.path}/accounts`);
  }, [match, history]);

  return (
    <>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <NavLink
            to={`${match.path}/accounts`}
            className="nav-link"
            activeClassName="active"
          >
            Accounts
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to={`${match.path}/queue`}
            className="nav-link"
            activeClassName="active"
          >
            Queue
          </NavLink>
        </li>
      </ul>

      <Switch>
        <Route path={`${match.path}/accounts`}>
          <Accounts />
        </Route>
        <Route path={`${match.path}/queue`}>
          <Queue />
        </Route>
      </Switch>
    </>
  );
};

Inquiries.propTypes = {};

export default Inquiries;
