import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ForgotPasswordContainer from 'modules/ForgotPassword/container';
import withAuth from 'modules/auth/hoc/withAuth';
import { USER_PROFILE } from 'modules/auth/constants';
import PrivateRoutes from 'modules/partials/PrivateRoutes';
import * as commonAction from 'modules/common/actions';
import * as authAction from 'modules/auth/actions';
import * as contentAction from 'modules/mobileContents/actions';
import withGoogle from 'modules/common/google/withGoogle';
import Marker from 'modules/common/components/Alert';
import { ModalMarker } from 'modules/common/components/Modal';
import FirstLogin from 'modules/FirstLogin/container';
import PasswordExpired from 'modules/password-expired/container';
import ProfileUpdateConsent from 'app/update-consent';
import LoginPage from 'app/login';
import { HelpCenter, TermsAndConditions, PrivacyPolicy } from 'modules/Public';

export const ModalContext = React.createContext(() => {});
function App({
  auth,
  setModal,
  is_first,
  is_first_time,
  isPasswordExpired,
  isProfileUpdated,
}) {
  const isAuthenticated = _.get(auth, 'item.isAuthenticated');
  const isFirstLogin = is_first_time || is_first;

  return (
    <ModalContext.Provider value={setModal}>
      <Switch>
        <Route exact path="/help-center" render={HelpCenter} />
        <Route exact path="/terms-and-conditions" render={TermsAndConditions} />
        <Route exact path="/privacy-policy" render={PrivacyPolicy} />
        <Route
          exact
          path="/forgot-password"
          render={() => {
            if (isAuthenticated) return <Redirect to="/" />;
            return <ForgotPasswordContainer />;
          }}
        />
        <Route
          path="/"
          render={() => {
            if (isAuthenticated) {
              if (isPasswordExpired) {
                return (
                  <>
                    <Redirect to="/password-expired" />
                    <Route
                      exact
                      path="/password-expired"
                      component={PasswordExpired}
                    />
                  </>
                );
              }
              if (isFirstLogin) {
                return (
                  <>
                    <Redirect to="/first-login" />
                    <FirstLogin />
                  </>
                );
              }
              if (isProfileUpdated) {
                return (
                  <>
                    <Redirect to="/profile-information-update" />
                    <ProfileUpdateConsent />
                  </>
                );
              }
              return <PrivateRoutes />;
            }
            return <LoginPage />;
          }}
        />
      </Switch>
      <Marker />
      <ModalMarker />
    </ModalContext.Provider>
  );
}

App.propTypes = {
  auth: PropTypes.instanceOf(Object).isRequired,
  setModal: PropTypes.instanceOf(Function).isRequired,
  is_first: PropTypes.bool,
  is_first_time: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isPasswordExpired: PropTypes.bool,
  isProfileUpdated: PropTypes.bool,
};

App.defaultProps = {
  is_first: false,
  is_first_time: false,
  isPasswordExpired: false,
  isProfileUpdated: false,
};

const mapStateToProps = ({ api }) => ({
  is_first: _.get(api, `${USER_PROFILE}.item.is_first_login`),
  is_first_time: _.get(api, `${USER_PROFILE}.item.is_first_time`),
  isPasswordExpired:
    new Date(_.get(api, `${USER_PROFILE}.item.password_expiration`)) <
    new Date(),
  isProfileUpdated: _.get(api, `${USER_PROFILE}.item.was_updated`),
});

const enhance = _.flowRight([
  withAuth,
  withRouter,
  connect(mapStateToProps, {
    ...commonAction,
    ...authAction,
    ...contentAction,
  }),
]);

export default enhance(withGoogle(App));
