import React from "react";
import GenericListModify from "modules/common/components/GenericListModify";
import Pagination from "modules/common/components/Pagination";
import CircleIndicator from "modules/common/components/CircleIndicator";
import { useDispatch } from "react-redux";
import { useDealer } from "app/load-wallet/context/DealerProvider";
import * as Icon from "components/Icons";
import { Route, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import * as a from "../actions";
import * as c from "../constant";

const DealerList = ({ parentMatch, match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    dealers,
    dealersPager,
    setDealerFilterData,
    dealerFilterData,
    dealerListLoading,
    dealerId,
    profileId,
    parentId,
  } = useDealer();

  const handleChangeInput = (key) => ({ target }) => {
    setDealerFilterData({
      ...dealerFilterData,
      [key]: target.value,
    });
  };

  const handleSelectRow = (data) => {
    if (dealerId !== data?.id) {
      history.push(`${parentMatch.url}/sub-dealer-sims/${data?.id}`);
    }
  };

  const handleChangePage = (page) => {
    dispatch(
      a.getDealerList({
        parent_id: profileId || parentId,
        ...dealerFilterData,
        ...c.listUserFilter,
        page,
      })
    );
  };

  return (
    <>
      <div className="col-3 px-0" style={{ background: "white" }}>
        <div className="d-flex flex-column h-100">
          <div className="px-3 my-2 mt-5">
            <div className="form-group">
              <div className="position-absolute d-flex p-2">
                <Icon.Search />
              </div>
              <input
                className="form-control border outline-none"
                type="text"
                style={{
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
                placeholder="Search"
                value={dealerFilterData?.q || ""}
                onChange={handleChangeInput("q")}
              />
            </div>
          </div>
          <span className="app-list m-0 ml-3 py-2 text-sm text-gray-400">
            List of Sub-Dealers{" "}
            {dealersPager ? `(${dealersPager?.total || "0"})` : ""}
          </span>
          <div className="shadow-sm flex-grow-1 mb-2">
            <div className="box-fit-wrapper h-100">
              <div className="box-fit-overflow">
                <Route
                  path={`${match.path}/:dealerId?`}
                  render={({ match: { params } }) => (
                    <GenericListModify
                      isLoading={dealerListLoading}
                      label=""
                      labelKey={(item) => (
                        <div
                          className="align-items-center d-flex justify-content-between px-1 ml-1"
                          style={{ height: 55, cursor: "pointer" }}
                        >
                          <div className="details py-3">
                            <div className="pr-2 ellipsis-wrap">
                              {item?.attributes?.company_name}
                            </div>
                          </div>
                          <CircleIndicator
                            is_active={+item?.attributes?.is_active}
                          />
                        </div>
                      )}
                      rowsCount={dealersPager?.total}
                      selectedKey="id"
                      data={dealers}
                      selected={params?.dealerId}
                      onSelectRow={handleSelectRow}
                      height="550px"
                    />
                  )}
                />
                <Pagination onChange={handleChangePage} data={dealersPager} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

DealerList.propTypes = {
  parentMatch: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

export default DealerList;
