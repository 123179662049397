import React, { useState } from "react";
import { Link } from "react-router-dom";
import Documentation from "./Documentation";

const CollapseDocu = () => {
  const [isCollapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!isCollapse);
  return (
    <div className="tw-mb-2">
      {Documentation.map((item, index) => {
        const collapsedClass = isCollapse ? `tw-rounded-t` : `tw-rounded`;
        return (
          <>
            <button
              type="button"
              className={`${collapsedClass} tw-bg-white tw-w-full tw-flex tw-items-center tw-justify-between`}
              onClick={() => toggle(index)}
            >
              <div className="tw-px-4">
                <span className="tw-font-semibold tw-text-gray-500 tw-my-2">
                  Documentation
                </span>
              </div>
              <div
                className="tw-p-3 d-flex tw-justify-end tw-text-green-600 rounded-sm"
                key={index}
              >
                <span className="ml-1">
                  {isCollapse ? (
                    <i className="fas fa-chevron-up" />
                  ) : (
                    <i className="fas fa-chevron-down" />
                  )}
                </span>
              </div>
            </button>
            {isCollapse && (
              <div className="bg-white tw-py-3 tw-px-4 tw-rounded-b">
                {item.content}
                <p className="mt-3">
                  <Link
                    to={`${process.env.PUBLIC_URL}/DMS_API_Gateway_2.5.pdf`}
                    target="_blank"
                    download
                    className="btn btn-success"
                  >
                    <i className="fas fa-download mr-2" />
                    Download Information Sheet
                  </Link>
                </p>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default CollapseDocu;
