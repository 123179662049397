import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { ModalContext } from 'App';
import { useLoading } from 'modules/ui/hooks';
import UpdateCompanyInformation from '../../../modal/UpdateCompanyInformation';

import * as c from '../../../constants';

const CompanyInformation = () => {
  const setModal = useContext(ModalContext);
  const dealer_id = useSelector(({ api }) =>
    _.get(api, 'AUTH/user_profile.item.id')
  );
  const companyInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_COMPANY_INFORMATION}.item`, {})
  );

  const isLoading = useLoading(c.SHOW_COMPANY_INFORMATION);

  const openUpdateModal = () =>
    setModal('update-company-information', { companyInfo, dealer_id });

  return (
    <>
      <div className="CompanyInformation mb-4">
        <div className="CompanyInformation-container">
          <header className="rounded-top-both bg-grey-800 text-white overflow-hidden d-flex justify-content-between pl-3">
            <div className="header-title my-auto">
              <h6 className="h6 text-xs font-weight-bold p-0 m-0">
                Company Information
              </h6>
            </div>
            <div className="invisible">
              <button
                type="button"
                className="btn text-xs btn-success rounded-0 py-2"
                onClick={openUpdateModal}
                disabled={isLoading}
              >
                <i className="fas fa-pen mr-2" />
                Update
              </button>
            </div>
          </header>
          <div className="p-4 bg-white">
            <div>
              <div className="row mb-4" style={{ width: '90%' }}>
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    Company Name
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(companyInfo, 'attributes.company_name', '--')}
                  </p>
                </div>
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">TIN No.</span>
                  <p className="description mb-0 break-word">
                    {_.get(companyInfo, 'attributes.tin_number', '--')}
                  </p>
                </div>
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    Company Contact No.
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(companyInfo, 'attributes.land_line', '--')}
                  </p>
                </div>
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    Company Email Address
                  </span>
                  <p
                    className="description mb-0"
                    style={{
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {_.get(companyInfo, 'attributes.email', '--')}
                  </p>
                </div>
              </div>

              <div className="row mb-4" style={{ width: '90%' }}>
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    Given Name
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(companyInfo, 'attributes.first_name', '--')}
                  </p>
                </div>
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    Middle Initial
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(companyInfo, 'attributes.middle_name', '--')}
                  </p>
                </div>
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">Last Name</span>
                  <p className="description mb-0 break-word">
                    {_.get(companyInfo, 'attributes.last_name', '--')}
                  </p>
                </div>
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    Tax Withheld
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(companyInfo, 'attributes.withholding_agent') ||
                      'N/A'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="CompanyAddress">
          <div className="p-3">
            <h6 className="h6 text-xs text-grey-1000 font-weight-bold p-0 m-0">
              Company Address
            </h6>
          </div>
          <div className="p-4 bg-white">
            <div>
              <div className="row mb-4" style={{ width: '90%' }}>
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">Province</span>
                  <p className="description mb-0 break-word">
                    {_.get(companyInfo, 'attributes.province_name') || '--'}
                  </p>
                </div>
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    City / Municipality.
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(companyInfo, 'attributes.municipality_name') || '--'}
                  </p>
                </div>
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">Barangay</span>
                  <p className="description mb-0 break-word">
                    {_.get(companyInfo, 'attributes.barangay_name') || '--'}
                  </p>
                </div>
              </div>

              <div className="row mb-4" style={{ width: '90%' }}>
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    Unit No./ Building No. House No.
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(companyInfo, 'attributes.unit_building_house_no') ||
                      '--'}
                  </p>
                </div>
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    Street Name
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(companyInfo, 'attributes.street_name') || '--'}
                  </p>
                </div>
                <div className="col-3 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">ZIP Code</span>
                  <p className="description mb-0 break-word">
                    {_.get(companyInfo, 'attributes.postal_no') || '--'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdateCompanyInformation />
    </>
  );
};
export default React.memo(CompanyInformation);
