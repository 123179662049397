import React, { useState, useMemo, useContext, useEffect } from "react";
import withModal from "modules/common/hoc/withModal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import PropTypes from "prop-types";
import ReactInputMask from "react-input-mask";
import { ModalContext } from "App";
import ButtonAsync from "components/Button";
import { useDealer } from "app/load-wallet/context/DealerProvider";
import { useDealerSim } from "app/load-wallet/dealer-sims/context/DealerSimProvider";
import * as actions from "../actions";
import * as c from "../constant";

function UpdateRetailerSimFormModal({ item }) {
  const { profile, dealerRole, isNonMigrated, role, isB2B } = useDealer();
  const { dealerSimsAll } = useDealerSim();
  const setModal = useContext(ModalContext);
  const [formData, setFormData] = useState();
  const [pending, setPending] = useState(false);

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const parentSim = useMemo(() => {
    return dealerSimsAll?.find((sim) =>
      dealerRole?.includes("smmt")
        ? sim?.attributes?.type?.includes("DSP")
        : sim?.attributes?.type?.includes("-BDL")
    );
  }, [dealerSimsAll, dealerRole]);

  const replaceEndOfSimType = useMemo(() => {
    return parentSim?.attributes?.type.replace("-BDL", "-HA");
  }, [parentSim]);

  const isRetailerSimType =
    dealerRole?.includes("smmt") || isNonMigrated || isB2B;
  const args = useMemo(
    () => ({
      email: item?.attributes?.email || profile?.email,
      assigned_to: profile?.assigned_to,
      dealer_id: item?.id || profile?.id || profile?.parent_id,
      parent_min:
        isNonMigrated || isB2B
          ? ""
          : parentSim?.attributes?.mobile_identification_number,
      sim_type: isRetailerSimType ? "" : replaceEndOfSimType,
      role_type: isNonMigrated || isB2B ? role : "",
    }),
    [
      profile,
      item,
      dealerRole,
      parentSim,
      replaceEndOfSimType,
      isNonMigrated,
      isB2B,
      role,
    ]
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    setPending(true);
    const payload = {
      ...args,
      ...formData,
      tempSubmitType: c.SUBMIT_TYPE.update,
    };

    setModal("retailer-sim-confirm-password", payload);
  };

  const handleChangeInput = (key) => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value,
    });
  };

  useEffect(() => {
    setFormData({
      id: item?.id,
      is_active: item?.attributes?.is_active,
      email: profile?.email,
      assigned_to: item?.attributes?.assigned_to,
      mobile_identification_number:
        item?.attributes?.mobile_identification_number,
    });
  }, [item, profile]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-6">
          <label className="form-label font-weight-bold">SIM Number:</label>
          <ReactInputMask
            mask="+639999999999"
            maskChar=""
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={formData?.mobile_identification_number}
            onChange={handleChangeInput("mobile_identification_number")}
            readOnly
          />
        </div>
        <div className="col-6">
          <label
            className="form-label font-weight-bold"
            style={{ fontSize: 12 }}
          >
            Assign to physical SIM holder (name)
          </label>
          <input
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={formData?.assigned_to}
            onChange={handleChangeInput("assigned_to")}
            maxLength={50}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 my-4">
          <span className="text-left form-label">
            By clicking &quot;CONTINUE&quot; a password confirmation form
            <br />
            will pop-up to verify ownership
          </span>
        </div>
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <ButtonAsync
              type="submit"
              className="btn btn-primary position-relative text-uppercase mx-2"
              loading={pending}
            >
              Continue
            </ButtonAsync>
            <button
              type="button"
              onClick={closeModal}
              className="text-uppercase btn btn-danger font-weight-bold mr-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

UpdateRetailerSimFormModal.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ modal }) => ({
  item: _.get(modal, "modalData.data") || {},
});

const enhance = _.flowRight([
  withModal("fill-update-retailer-sim", {
    title: "Update Retailer SIM",
    size: "modal-md",
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(UpdateRetailerSimFormModal);
