import React, { useState, useContext } from 'react';
import withModal from 'modules/common/hoc/withModal';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactInputMask from 'react-input-mask';
import { ModalContext } from 'App';
import ButtonAsync from 'components/Button';
import * as actions from '../actions';
import * as c from '../constant';

function VerifyTopOrg({ newSimData, oldSimData }) {
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);
  const closeModal = () => {
    setModal({ isOpen: false });
  };

  const [pending, setPending] = useState(false);
  const handleSubmit = e => {
    e.preventDefault();

    setPending(true);

    dispatch(
      actions.setOtp(
        {
          mobile: newSimData?.transfer_to,
          purpose: c.PURPOSE.portal,
        },
        res =>
          setModal('change-top-org-confirm-otp', {
            ...newSimData,
            ...res?.res?.data,
          })
      )
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 my-4">
          <span className="my-0 font-weight-bold d-block">Notice!</span>
          <span className="text-left form-label">
            Dealer SIM change top org process will transfer all existing Dealer
            SIM&quot;s downlines to the newly created one.
          </span>
        </div>
      </div>
      <VerifyTopOrgForm
        header="Existing Dealer SIM:"
        simTypeValue={oldSimData?.attributes?.type}
        minValue={oldSimData?.attributes?.mobile_identification_number}
        assignedToValue={oldSimData?.attributes?.assigned_to}
      />
      <VerifyTopOrgForm
        header="New Dealer SIM:"
        simTypeValue={newSimData?.type}
        minValue={newSimData?.transfer_to}
        assignedToValue={newSimData?.transfer_assigned_to}
      />
      <div className="row">
        <div className="col-12 mt-3">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <ButtonAsync
              type="submit"
              className="btn btn-primary position-relative text-uppercase mx-2"
              loading={pending}
            >
              Continue
            </ButtonAsync>
            <button
              type="button"
              onClick={closeModal}
              className="text-uppercase btn bg-gray-500 text-white font-weight-bold mr-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

VerifyTopOrg.propTypes = {
  newSimData: PropTypes.instanceOf(Object).isRequired,
  oldSimData: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ modal }) => ({
  newSimData: _.get(modal, 'modalData.newSimData') || {},
  oldSimData: _.get(modal, 'modalData.oldSimData') || {},
});

const enhance = _.flowRight([
  withModal('verify-top-org', {
    title: 'Change Dealer SIM Wizard',
    size: 'modal-lg',
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(VerifyTopOrg);

const VerifyTopOrgForm = ({
  header,
  simTypeValue,
  minValue,
  assignedToValue,
}) => {
  return (
    <>
      <div className="col-12">
        <h6 className="font-weight-bold my-3">{header}</h6>
      </div>
      <div className="d-flex">
        <div className="col-4">
          <label className="form-label font-weight-bold">SIM Type</label>
          <input
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={simTypeValue}
            readOnly
          />
        </div>
        <div className="col-4">
          <label className="form-label font-weight-bold">SIM Number</label>
          <ReactInputMask
            mask="+639999999999"
            maskChar=""
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={minValue}
            readOnly
          />
        </div>
        <div className="col-4">
          <label className="form-label font-weight-bold">Assign To</label>
          <input
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={assignedToValue}
            readOnly
          />
        </div>
      </div>
    </>
  );
};

VerifyTopOrgForm.propTypes = {
  header: PropTypes.string.isRequired,
  simTypeValue: PropTypes.string.isRequired,
  minValue: PropTypes.string.isRequired,
  assignedToValue: PropTypes.string.isRequired,
};
