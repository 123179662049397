import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

const dateFormat = { year: "numeric", month: "long", day: "numeric" };

function Range({ date_from, date_to }) {
  return (
    <div>
      {_.isEmpty(date_from) && _.isEmpty(date_to) ? null : (
        <div className="text-muted text-sm">
          <span className="font-weight-bold mr-2">Date:</span>
          {_.isEmpty(date_from) ? null : (
            <div className="d-inline-block mr-1">
              <span
                style={{
                  fontSize: ".875rem",
                  fontWeight: "lighter",
                  fontStyle: "italic",
                }}
              >
                From
              </span>
              {` ${new Date(date_from).toLocaleDateString(
                "en-US",
                dateFormat
              )}`}
            </div>
          )}
          {_.isEmpty(date_to) ? null : (
            <div className="d-inline-block mr-1">
              <span
                style={{
                  fontSize: ".875rem",
                  fontWeight: "lighter",
                  fontStyle: "italic",
                }}
              >
                up to
              </span>
              {` ${new Date(date_to).toLocaleDateString("en-US", dateFormat)}`}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

Range.propTypes = {
  date_from: PropTypes.node.isRequired,
  date_to: PropTypes.node.isRequired,
};

export default React.memo(Range);
