import * as c from './constants';

export const queueLoading = (key) => ({
  type: c.LOADING_ADD,
  key
})

export const dequeueLoading = (key) => ({
  type: c.LOADING_REMOVE,
  key
})

export const clearLoading = () => ({
  type: c.LOADING_CLEAR
})

export const resetLoading = () => ({
  type: c.UI_RESET
})
