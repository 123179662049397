import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { inquireBalance, getBranch } from 'app/dashboard/actions';
import { GET_BRANCH, SHOW_RETAILER } from 'app/dashboard/constant';
import Threshold from 'modules/Load/components/LoadThreshold';
import { checkRoles } from 'helper';

const WalletBalance = () => {
  const selectors = {
    frontliner: useSelector(
      ({ api }) => _.get(api, `${GET_BRANCH}.item.included.sources`, []) || []
    ),
    branch_admin: useSelector(
      ({ api }) => _.get(api, `${GET_BRANCH}.item.included.sources`, []) || []
    ),
    profile: useSelector(({ api }) => _.get(api, `${SHOW_RETAILER}.item`)),
  };

  const isBA = checkRoles(['branch-admin']);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState('');
  const [wallet, setWallet] = useState();
  const [loading, setLoading] = useState(false);
  const retailerSims = selectors.branch_admin;
  let list = isBA ? selectors.branch_admin : selectors.frontliner;
  const isOnShift = _.get(selectors.profile, 'attributes.has_ongoing_schedule');
  const getRetailerSims = () => {
    if (!isOnShift) {
      list = [];
    }

    if (isBA) {
      return list
        .filter(
          source =>
            source.attributes.type === 'dealer_retailer_sims' &&
            source.attributes.is_active === true
        )
        .map(source => {
          const { sim_type, mobile_identification_number, is_default } = source?.attributes
          return {
            value: mobile_identification_number,
            label: `${mobile_identification_number}${sim_type ? `- ${sim_type}` : ''}`,
            default: is_default,
          };
        });
    }

    return list
      .filter(
        source =>
          source.attributes.type === 'dealer_retailer_sims' &&
          source.attributes.is_active === true
      )
      .map(source => {
        const { sim_type, mobile_identification_number, is_default } = source?.attributes
        return {
          value: mobile_identification_number,
          label: `${mobile_identification_number}${sim_type ? `- ${sim_type}` : ''}`,
          default: is_default,
        };
      });
  };

  const options = getRetailerSims();

  const getThreshold = () => {
    const sims = retailerSims.find(
      x =>
        _.get(x, 'attributes.mobile_identification_number') ===
        _.get(selected, 'value', '')
    );
    return _.get(sims, 'attributes.load_threshold');
  };

  const handleChangeInput = selectedOption => {
    setSelected(selectedOption);
  };

  useEffect(() => {
    if (list.length === 0) return;
    const defaultMin = options.find(sim => _.get(sim, 'default')) || options[0];
    setSelected(defaultMin);
  }, [list]);

  useEffect(() => {
    setLoading(true);
    if (!selected) {
      setLoading(false);
      return;
    }
    const { value } = selected;
    dispatch(
      inquireBalance(
        { mobile: value.replace('+63', '0') },
        res => {
          setWallet(_.get(res, 'data.current_balance'));
          setLoading(false);
        },
        () => setLoading(false)
      )
    );
  }, [selected]);

  useEffect(() => {
    dispatch(getBranch());
  }, []);

  return (
    <div className="w-100 card shadow-sm">
      <div className="card-header bg-primary">
        <small className="card-title m-0 text-white font-weight-bold">
          Retailer Load Wallet Balance:
        </small>
      </div>
      <div className="card-body">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label className="form-label text-primary">Retailer MIN:</label>
                <Select
                  options={options}
                  onChange={handleChangeInput}
                  value={selected}
                  styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                  isDisabled={loading || _.isEmpty(options)}
                  placeholder={_.isEmpty(options) ? 'No options' : 'Select MIN'}
                />
              </div>
              <div className="form-group">
                <label className="form-label text-primary mb-3">
                  Load Wallet Threshold:
                </label>
                <Threshold
                  loading={loading}
                  threshold={getThreshold()}
                  balance={wallet}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletBalance;
