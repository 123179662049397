import React, { useState, useRef, forwardRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

const formatDate = (date, def = '', format = 'YYYY-MM-DD') => {
  try {
    const endDate = moment(date, 'YYYY-MM-DD HH:mm').format(format);
    const defDate = moment(def, 'YYYY-MM-DD HH:mm').format(format);

    if (_.isEmpty(date) && date === '') {
      return endDate === '';
    }
    const newDate = endDate === 'Invalid date' ? defDate : endDate;
    return newDate;
  } catch (error) {
    return def;
  }
};

const CustomRender = forwardRef(({ onClick }, ref) => (
  <button
    type="button"
    ref={ref}
    className="btn btn-date btn-success"
    onClick={onClick}
  >
    <i className="rs-button__icon fa fa-calendar-alt" />
  </button>
));

CustomRender.propTypes = {
  onClick: PropTypes.instanceOf(Function),
};

CustomRender.defaultProps = {
  onClick: () => {},
};

const renderDateFormat = (showMonthYearPicker, withTime) => {
  if (showMonthYearPicker) {
    return 'YYYY-MM';
  }
  if (withTime) {
    return 'YYYY-MM-DD HH:mm';
  }
  return 'YYYY-MM-DD';
};

const inputMaskRenderFormat = (showMonthYearPicker, withTime) => {
  if (showMonthYearPicker) {
    return '9999-99';
  }
  if (withTime) {
    return '9999-99-99 99:99';
  }
  return '9999-99-99';
};

const renderPlaceHolder = (data, showMonthYearPicker, withTime) => {
  let placeHolder = '';
  if (data.length > 0) {
    return data;
  }

  placeHolder = () => {
    if (showMonthYearPicker) {
      return 'YYYY-MM';
    }
    if (withTime) {
      return 'YYYY-MM-DD HH:MM';
    }
    return 'YYYY-MM-DD';
  };

  return placeHolder();
};

function DateInputPicker({
  disabled,
  showMonthYearPicker,
  withTime,
  isRequired,
  placeholder,
  onChange,
  maxDate,
  minDate,
  value,
  openToDate,
  extraClass,
}) {
  const [selected, setSelected] = useState(null);
  const customRenderRef = useRef();

  const handleChangeInput = e => {
    setSelected(e.target.value);
  };

  const handleChangePicker = e => {
    const newVal = formatDate(
      e,
      '',
      renderDateFormat(showMonthYearPicker, withTime)
    );
    onChange({ target: { value: newVal } });
  };

  const handleUpdateActual = e => {
    const newVal = formatDate(
      e.target.value,
      selected,
      renderDateFormat(showMonthYearPicker, withTime)
    );
    onChange({ target: { value: newVal } });
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div className="input-group-search">
      <span className={`btn-group ${extraClass}`}>
        <DatePicker
          customInput={<CustomRender ref={customRenderRef} />}
          disabled={disabled}
          showYearDropdown
          dropdownMode="select"
          timeIntervals={15}
          showMonthYearPicker={showMonthYearPicker}
          showTimeSelect={withTime}
          timeFormat="HH:mm"
          maxDate={maxDate}
          minDate={minDate}
          openToDate={!_.isEmpty(value) ? new Date(selected) : openToDate}
          popperClassName="calendar-details"
          popperPlacement="bottom-start"
          popperModifiers={{
            offset: {
              enabled: true,
              offset: '5px, 10px',
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: 'viewport',
            },
          }}
          selected={new Date(value || openToDate)}
          onChange={handleChangePicker}
        />

        <InputMask
          onChange={handleChangeInput}
          onBlur={handleUpdateActual}
          maskChar=" "
          disabled
          required={isRequired}
          placeholder={renderPlaceHolder(
            placeholder,
            showMonthYearPicker,
            withTime
          )}
          mask={inputMaskRenderFormat(showMonthYearPicker, withTime)}
          className="form-control custom-date form-custom form-control_bottom"
          value={selected || ''}
          // style={{ background: 'white' }}
        />
      </span>
    </div>
  );
}

DateInputPicker.defaultProps = {
  disabled: false,
  showMonthYearPicker: false,
  withTime: false,
  isRequired: false,
  placeholder: '',
  maxDate: new Date('3000-01-01'),
  minDate: new Date('1900-01-01'),
  openToDate: new Date(),
  value: '',
  extraClass: '',
};

DateInputPicker.propTypes = {
  disabled: PropTypes.bool,
  showMonthYearPicker: PropTypes.bool,
  withTime: PropTypes.bool,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.instanceOf(Function).isRequired,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  openToDate: PropTypes.instanceOf(Date),
  value: PropTypes.string,
  extraClass: PropTypes.string,
};

const mapStateToProps = () => ({});

const enhance = _.flowRight([withRouter, connect(mapStateToProps)]);

export default enhance(DateInputPicker);
