import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import ButtonAsync from "modules/common/components/ButtonAsync";
import withModal from "modules/common/hoc/withModal";
import { ModalContext } from "App";
import _ from "lodash";
import * as actions from "../../actions";

function Authorization() {
  const [password, setPassword] = useState("");
  const [pending, setPending] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);

  const handleFieldVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const openKeyModal = () => {
    setPending(true);
    const sucCallback = () => {
      setModal("can-see-keys");
      setPending(false);
    };
    const errCallback = () => setPending(false);

    dispatch(actions.validatePassword({ password }, sucCallback, errCallback));
  };

  return (
    <>
      <div className="container-fluid">
        <p>A password is required to proceed the key generation</p>
        <div className="form-group">
          <div className="prepend-form-right">
            <label className="tw-font-semibold">Password</label>
            <div className="prepend-input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control input-textbox password with-pass-icon form-custom form-control_bottom "
                required
                name="password"
                value={password}
                onChange={handleChange}
                autoComplete="off"
              />
            </div>
            <button
              type="button"
              onClick={handleFieldVisibility}
              className="btn btn-default rounded-circle p-0 mr-1 outline-none"
            >
              <i
                className={`fas ${!showPassword ? "fa-eye" : "fa-eye-slash"}`}
              />
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <ButtonAsync
            type="button"
            appendClass="mt-3 btn btn-modal-yes mr-2 px-4 py-2"
            onClick={openKeyModal}
            loading={pending}
            disabled={_.isEmpty(password)}
          >
            <span>Continue</span>
          </ButtonAsync>
          <button
            type="button"
            onClick={closeModal}
            className="mt-3 btn btn-modal-no px-4 py-2"
            style={{ borderRadius: 5 }}
          >
            <span
              className="tw-font-semibold"
              style={{ color: "#FFFFFF", fontSize: 14 }}
            >
              Cancel
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

const modalKey = "can-input-password";

const modalConfig = {
  title: "Password Confirmation",

  size: "modal-md",
};

export default withModal(modalKey, modalConfig)(Authorization);
