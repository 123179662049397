import React, { useContext, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import withModal from "modules/common/hoc/withModal";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { USER_PROFILE } from "modules/auth/constants";
import { ToastSuccess } from "modules/common/components/Toast";
import { ModalContext } from "App";
import { useLoading } from "modules/ui/hooks";
import ButtonAsync from "components/Button";
import * as c from "../../UserAccountManagement/constant";
import * as actions from "../action";
import * as userMgtActions from "../../UserAccountManagement/action";
import { UPDATE_ACCTACH_USERS } from "../constant";

function FrontlinerStatusModal({
  data,
  validatePassword,
  getAttachUsers,
  updateAttachUsers,
}) {
  const setModal = useContext(ModalContext);
  const [view, setView] = useState(false);
  const [formData, setFormData] = useState({ password: "" });
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const isNonMigrated = profile?.roles[0]?.includes("non-migrated");
  const [pending, setPending] = useState(false);

  const handleOnViewPassword = (value) => (e) => {
    e.preventDefault();
    setView(value);
  };
  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleOnChange = ({ target }) => {
    setFormData({
      ...formData,
      password: target.value,
    });
  };

  const userloading = useLoading(UPDATE_ACCTACH_USERS);
  const validating = useLoading(c.VALIDATE_PASSWORD);

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);
    validatePassword(
      formData,
      () => {
        updateAttachUsers(data.retailerId, data.payload, () => {
          ToastSuccess(`Successfully ${data.callbackLabel} ${data.name}.`);
          getAttachUsers(data.branchId, {
            role_slug: isNonMigrated ? "frontliner-non-migrated" : "frontliner",
          });
          setModal({ isOpen: false });
          setPending(false);
        });
      },
      () => setPending(false)
    );
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="text-center">
        <span className="font-weight-bold">
          Are you sure you want to {data.label} {data.name}?
        </span>
      </div>
      <div className="text-center">
        <small>Your password is required to continue.</small>
      </div>
      <div className="form-group">
        <div className="prepend-form-right">
          <label className="form-label text-success">Password:</label>
          <div className="prepend-input-group">
            <input
              type={view ? "text" : "password"}
              className="form-control form-custom form-control_bottom"
              required
              autoFocus
              name="password"
              value={_.get(formData, "password") || ""}
              onChange={handleOnChange}
            />
          </div>
          <button
            type="button"
            onClick={handleOnViewPassword(!view)}
            className="btn btn-default rounded-circle p-0 mr-1"
          >
            <i className={`fas ${!view ? "fa-eye" : "fa-eye-slash"}`} />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-center">
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold mr-2"
            >
              CANCEL
            </button>
            <ButtonAsync
              type="submit"
              loading={pending || userloading || validating}
              disabled={_.get(formData, "password").length < 1}
            >
              PROCEED
            </ButtonAsync>
          </div>
        </div>
      </div>
    </form>
  );
}

FrontlinerStatusModal.propTypes = {
  validatePassword: PropTypes.func.isRequired,
  getAttachUsers: PropTypes.func.isRequired,
  updateAttachUsers: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ modal }) => ({
  data: _.get(modal, "modalData.data", {}),
});

const enhance = _.flowRight([
  withModal("frontliner-update-status", {
    title: "",
    size: "modal-md",
  }),
  withRouter,
  connect(mapStateToProps, {
    ...actions,
    ...userMgtActions,
  }),
]);

export default enhance(FrontlinerStatusModal);
