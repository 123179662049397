import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

function UpdateProfile({
  profileFieldsData,
  handleSubmitProfile,
  onCancelProfileStep,
}) {
  return (
    <div>
      <h4 className="font-weight-bold text-center m-0 mb-4">
        Personal Details
      </h4>
      <p className="text-center m-0">
        Kindly check if the details below are all correct.
      </p>
      <p className="text-center m-0">
        Please confirm to continue, otherwise click cancel button.
      </p>

      <form onSubmit={handleSubmitProfile} className="update-profile-form mt-5">
        <div className="form-row">
          <div className="form-group col">
            <label className="form-label text-primary">Mobile Number:</label>
            <input
              className="form-control form-custom form-control_bottom"
              type="text"
              readOnly
              value={_.get(profileFieldsData, 'mobile') || ''}
            />
          </div>
          <div className="form-group col">
            <label className="form-label text-primary">Email Address:</label>
            <input
              className="form-control form-custom form-control_bottom"
              type="text"
              readOnly
              value={_.get(profileFieldsData, 'email') || ''}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col">
            <label className="form-label text-primary">First Name:</label>
            <input
              className="form-control form-custom form-control_bottom"
              type="text"
              readOnly
              value={_.get(profileFieldsData, 'first_name') || ''}
            />
          </div>
          <div className="form-group col">
            <label className="form-label text-primary">Last Name:</label>
            <input
              className="form-control form-custom form-control_bottom"
              type="text"
              readOnly
              value={_.get(profileFieldsData, 'last_name') || ''}
            />
          </div>
        </div>

        <div
          className="form-group mt-4 mx-auto w-100 text-center"
          style={{ maxWidth: 300 }}
        >
          <button type="submit" className="btn btn-block btn-primary px-4">
            Confirm
          </button>
          <button
            type="button"
            className="cancel-btn btn btn-link mt-2 px-4"
            onClick={onCancelProfileStep}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

UpdateProfile.propTypes = {
  profileFieldsData: PropTypes.instanceOf(Object).isRequired,
  handleSubmitProfile: PropTypes.func.isRequired,
  onCancelProfileStep: PropTypes.func.isRequired,
};

export default UpdateProfile;
