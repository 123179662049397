import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types';

// min(s) & secs only
const WithTimerButton = ({
	duration, 
	handleClick, 
	containerClass,
	buttonClass,
	timeClass,
	children
}) => {
	const [time, setTime] = useState(duration * 60)

	useEffect(() =>{
		const countdown = setInterval(() => {
				setTime(time - 1)
		}, 1000)
		if(time === 0) clearInterval(countdown)

		return () => clearInterval(countdown) 
	}, [time])

	const output = useMemo(() =>{
		const minutes = Math.floor(time / 60)
		let seconds = time % 60
		seconds = seconds < 10 ? `0${seconds}` : seconds

		return `${ minutes }: ${ seconds }`
	}, [time])

	return(
		<div className={ containerClass }>
			<button
			  type="button"
              className={ buttonClass }
              onClick={ e => handleClick(e, setTime(duration * 60)) }
              disabled={ time !== 0 }
			> 
			  { children }
			</button>

			<small className={`${time === 0 ? 'd-none' : ''} ${timeClass}`}> 
				in ({ output })
			</small>
		</div>
	)
}

WithTimerButton.propTypes = {
  duration: PropTypes.number,
  handleClick: PropTypes.func.isRequired,
  containerClass: PropTypes.string,
  buttonClass: PropTypes.string,
  timeClass: PropTypes.string,
   children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired
};

WithTimerButton.defaultProps = {
  duration: 0,
  containerClass: 'd-flex align-items-center',
  buttonClass: 'btn btn-default border-bottom text-muted mr-2 shadow-none p-0',
  timeClass: 'text-muted mr-1'
};

export default WithTimerButton
