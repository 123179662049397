import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "assets/images/dealers-portal-logo.png";
import loginBanner from "assets/images/login-banner.png";
import operatedByLogo from "assets/images/logo-operated-by.png";
import { loginOTP } from "modules/auth/actions";
import ConfirmOTP from "modules/auth/containers/ConfirmOTP";
import Session from "modules/auth/containers/Session";
import ButtonAsync from "components/Button";
import { AUTH_OTP } from "modules/auth/constants";
import { useLoading } from "modules/ui/hooks";
import LoginBg from "components/LoginBackground";

const INIT_STATE = {
  username: "",
  password: "",
};

function LoginContainer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [credentials, setCredentials] = useState(INIT_STATE);
  const [showPassword, setShowPassword] = useState(false);

  const params = _.get(location, "search", "") || "";
  const searchParams = new URLSearchParams(params);
  const defaultUsername =
    searchParams.get("mobile_identification_number") ||
    searchParams.get("email") ||
    "";

  const clear = () => {
    setCredentials(INIT_STATE);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    dispatch(loginOTP({ ...credentials, clear }));
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFieldVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    setCredentials((prev) => ({
      ...prev,
      username: defaultUsername,
    }));
  }, [defaultUsername]);

  return (
    <div className="container-fluid h-100 px-0">
      <div className="tw-h-screen d-flex justify-content-center align-items-center px-0">
        <div className="tw-absolute inset-0 tw-w-screen tw-h-screen">
          <LoginBg className="tw-absolute tw--inset-x-full tw-m-auto tw-h-screen" />
        </div>
        <div className="row w-100 h-100 px-0">
          <div className="col-xl-5 col-lg-7 d-flex align-items-center h-100 pl-0">
            <div className="login-wrapper">
              <div className="login-container">
                <div className="text-center">
                  <img src={logo} alt="logo" />
                </div>
                <form className="mt-5" onSubmit={handleOnSubmit}>
                  <div className="form-wrapper">
                    <div className="form-group">
                      <label htmlFor="username" className="form-label mb-2">
                        User Name
                      </label>
                      <div className="prepend-input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text border-0 pl-3">
                            <i className="fa fa-user" />
                          </span>
                        </div>
                        <input
                          className="form-control input-textbox"
                          type="text"
                          autoComplete="off"
                          autoFocus
                          required
                          name="username"
                          value={_.get(credentials, "username", "")}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="">
                        <label className="form-label mb-2">Password</label>
                        <div className="d-flex" style={{ height: "3.25rem" }}>
                          <div className="d-flex align-items-center">
                            <span className="position-absolute ml-3">
                              <i
                                className="fa fa-lock"
                                style={{ color: "#818181" }}
                              />
                            </span>
                          </div>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="border rounded-sm flex-grow-1 px-5"
                            required
                            name="password"
                            value={_.get(credentials, "password", "")}
                            onChange={handleOnChange}
                            autoComplete="off"
                          />
                          <div className="d-flex align-items-center justify-content-end">
                            <button
                              type="button"
                              onClick={handleFieldVisibility}
                              className="position-absolute btn btn-default rounded-circle p-0 mr-3 outline-none"
                            >
                              <i
                                className={`fas ${
                                  !showPassword ? "fa-eye" : "fa-eye-slash"
                                }`}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group text-center mt-4">
                    <ButtonAsync
                      type="submit"
                      className="btn btn-primary btn-block position-relative font-weight-bold"
                      style={{ height: "3.25rem" }}
                      loading={useLoading(AUTH_OTP)}
                    >
                      Login
                    </ButtonAsync>

                    <Link
                      className="btn btn-link mt-2 text-normal font-weight-bold text-sm text-dark"
                      to="/forgot-password"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-5 d-flex align-items-center justify-content-center h-100 pr-0">
            <img src={loginBanner} alt="Login Banner" />
            <img
              className="logo-operated-by"
              src={operatedByLogo}
              alt="Operated by Smart"
            />
          </div>
        </div>
      </div>
      <Session />
      <ConfirmOTP />
    </div>
  );
}

export default LoginContainer;
