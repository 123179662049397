import { useQuery } from "react-query";
import { service } from "api/service";

const transformResponse = (data) => JSON.parse(data)?.data;

const getDealer = (id) => {
  const dealerId = id ? `/${id}` : ``;
  const url = `/api/v1/dealers${dealerId}`;
  return service.get(url, { transformResponse });
};

export function useDealer(dealerId) {
  return useQuery(["dealer", dealerId], () => getDealer(dealerId), {
    keepPreviousData: true,
    enabled: !!dealerId,
  });
}

export default {};
