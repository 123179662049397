import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

const StandardPrice = ({ data }) => {
  return (
    <div className="my-4" style={{ fontSize: '.875rem' }}>
      <header
        className="rounded-top header-rounded background-gray text-white overflow-hidden d-flex justify-content-between pl-3"
        style={{ backgroundColor: '#7d7b7a' }}
      >
        <div
          className="header-title my-auto d-flex align-items-center"
          style={{ height: 39 }}
        >
          <h6 className="h6 text-md-size font-weight-bold p-0 m-0">
            Standard Price
          </h6>
        </div>
      </header>
      <div
        className="p-4 bg-white rounded-bottom"
        style={{ border: '1px solid #cec7c780' }}
      >
        <div className="w-100">
          <div className="row mb-4">
            <div className="col-3 d-flex flex-column text-sm-size">
              <span className="label font-weight-bold mb-2">
                Standard Price:
              </span>
              <p className="description mb-0">
                {_.get(data, 'attributes.product.base_price_formatted') || ''}
              </p>
            </div>
            <div className="col-3 d-flex flex-column text-sm-size">
              <span className="label font-weight-bold mb-2">
                Effectivity Date:
              </span>
              <p className="description mb-0">
                {moment(
                  _.get(data, 'attributes.product.effectivity_date')
                ).format('ll') || ''}
              </p>
            </div>
            <div className="col-3 d-flex flex-column text-sm-size">
              <span className="label font-weight-bold mb-2">Expiry Date:</span>
              <p className="description mb-0">
                {moment(_.get(data, 'attributes.product.expiry_date')).format(
                  'll'
                ) || ''}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StandardPrice.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default StandardPrice;
