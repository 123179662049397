import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';

const parseNumber = val => {
  try {
    return parseFloat(val);
  } catch (err) {
    return NaN;
  }
};

const count = (s1, letter) => s1.split(letter).length - 1;

export default WrappedComponent =>
  function WithForm(props) {
    const [form, setForm] = useState({});

    const handleChangeInput = key => ({ target }) => {
      setForm({ ...form, [key]: target.value });
    };

    const handleChangeCheck = key => ({ target }) => {
      setForm({ ...form, [key]: target.checked });
    };

    const handleChangeInputNumber = key => ({ target }) => {
      const svalue = target.value;
      const value = parseNumber(svalue);
      if (
        (Number.isNaN(value) && !_isEmpty(value)) ||
        count(`${svalue}`, '.') > 1
      )
        return;
      setForm({ ...form, [key]: svalue.replace(/[^0-9\.]/g, '') }); // eslint-disable-line
    };

    const renderLabel = (x, p) => (
      <label id={x.key} className="form-label d-flex align-items-center">
        <span>
          {x.label}{' '}
          {p.required && !x.isReadOnly && (
            <span className="text-danger">
              <i>*</i>
            </span>
          )}
        </span>
        {x.note && (
          <small className="ml-auto mr-0 text-muted">
            <i>{x.note}</i>
          </small>
        )}
      </label>
    );

    const setFormData = payload => {
      setForm({ ...form, ...payload });
    };

    const resetFormData = (defaultValue = {}) => {
      setForm(defaultValue);
    };

    const createInput = (data, p = {}) => (
      <React.Fragment>
        {renderLabel(data, p)}
        {data.isReadOnly ? (
          <input
            id={data.key}
            className="form-control-plaintext"
            readOnly
            value={form[data.key] || ''}
            {...p}
          />
        ) : (
          <input
            id={data.key}
            className="form-control"
            onChange={handleChangeInput(data.key)}
            value={form[data.key] || ''}
            {...p}
          />
        )}
      </React.Fragment>
    );

    const createCheckbox = (data, p = {}) => (
      <React.Fragment>
        {renderLabel(data, p)}
        {data.isReadOnly ? (
          <input
            id={data.key}
            className="form-control-plaintext"
            type="checkbox"
            readOnly
            checked={form[data.key] || false}
            {...p}
          />
        ) : (
          <input
            id={data.key}
            className="form-control"
            type="checkbox"
            onChange={handleChangeCheck(data.key)}
            checked={form[data.key] || false}
            {...p}
          />
        )}
      </React.Fragment>
    );

    const createTextArea = (data, p = {}) => (
      <React.Fragment>
        {renderLabel(data, p)}
        {data.isReadOnly ? (
          <p aria-labelledby={data.key} style={{ minHeight: 80 }}>
            {form[data.key]}
          </p>
        ) : (
          <textarea
            id={data.key}
            className="form-control"
            rows="3"
            onChange={handleChangeInput(data.key)}
            value={form[data.key] || ''}
            {...p}
            style={{ resize: 'none' }}
          />
        )}
      </React.Fragment>
    );

    const createInputNumber = (data, p = {}) => (
      <React.Fragment>
        {renderLabel(data, p)}
        {data.isReadOnly ? (
          <input
            id={data.key}
            className="form-control-plaintext"
            readOnly
            value={form[data.key] || ''}
            {...p}
          />
        ) : (
          <input
            id={data.key}
            className="form-control"
            type="number"
            onChange={handleChangeInputNumber(data.key)}
            value={form[data.key] || ''}
            {...p}
          />
        )}
      </React.Fragment>
    );

    const createInputMask = (data, mask, p = {}) => (
      <React.Fragment>
        {renderLabel(data, p)}
        {data.isReadOnly ? (
          <input
            id={data.key}
            className="form-control-plaintext"
            readOnly
            value={form[data.key] || ''}
            {...p}
          />
        ) : (
          <InputMask
            id={data.key}
            mask={mask}
            maskChar=""
            className="form-control"
            onChange={handleChangeInput(data.key)}
            value={form[data.key] || ''}
            {...p}
          />
        )}
      </React.Fragment>
    );

    const createInputMobileNumber = (data, p = {}) => (
      <React.Fragment>
        {renderLabel(data, p)}
        {data.isReadOnly ? (
          <input
            id={data.key}
            className="form-control-plaintext"
            readOnly
            value={form[data.key] || ''}
            {...p}
          />
        ) : (
          <InputMask
            id={data.key}
            mask="+63\9999999999"
            alwaysShowMask
            className="form-control"
            onChange={handleChangeInput(data.key)}
            value={form[data.key] || ''}
            {...p}
          />
        )}
      </React.Fragment>
    );

    const createTelephoneNumber = (data, p = {}) => (
      <React.Fragment>
        {renderLabel(data, p)}
        {data.isReadOnly ? (
          <input
            id={data.key}
            className="form-control-plaintext"
            readOnly
            value={form[data.key] || ''}
            {...p}
          />
        ) : (
          <InputMask
            id={data.key}
            mask="999-999-9999"
            alwaysShowMask
            className="form-control"
            onChange={handleChangeInput(data.key)}
            value={form[data.key] || ''}
            {...p}
          />
        )}
      </React.Fragment>
    );

    const createInputGroupNumber = (
      data,
      p = {},
      inputPlaceholder = '\u20B1'
    ) => (
      <React.Fragment>
        {renderLabel(data, p)}
        {data.isReadOnly ? (
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text font-weight-bold">
                {inputPlaceholder}
              </span>
            </div>
            <input
              id={data.key}
              readOnly
              value={form[data.key] || ''}
              className="form-control"
              {...p}
            />
          </div>
        ) : (
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text font-weight-bold">
                {inputPlaceholder}
              </span>
            </div>
            <InputMask
              id={data.key}
              mask="9999999999"
              maskChar=""
              placeholder="0.00"
              className="form-control"
              onChange={handleChangeInput(data.key)}
              value={form[data.key] || ''}
              {...p}
            />
          </div>
        )}
      </React.Fragment>
    );

    const createSelect = (data, options, p = {}) => (
      <React.Fragment>
        {renderLabel(data, p)}
        {data.isReadOnly ? (
          <input
            id={data.key}
            className="form-control-plaintext"
            readOnly
            value={
              (options.find(x => x.value === form[data.key] || '') || {})
                .label || ''
            }
            {...p}
          />
        ) : (
          <select
            id={data.key}
            className="form-control"
            onChange={handleChangeInput(data.key)}
            value={form[data.key] || ''}
            {..._omit(p, ['isLoading'])}
          >
            <option value="">
              {p.isLoading ? 'Loading...' : p.placeholder || '- Select -'}
            </option>
            {options.map((item, i) => (
              <option
                key={`${item.value}-${i}`} // eslint-disable-line
                value={item.value}
                disabled={item.disabled}
              >
                {item.label}
              </option>
            ))}
          </select>
        )}
      </React.Fragment>
    );

    const createReadOnly = (data, p = {}) => (
      <React.Fragment>
        {renderLabel(data, p)}
        <p aria-labelledby={data.key} className="form-control-plaintext" {...p}>
          {form[data.key] || '-'}
        </p>
      </React.Fragment>
    );

    return (
      <WrappedComponent
        {...props}
        formData={form}
        setFormData={setFormData}
        resetFormData={resetFormData}
        createInput={createInput}
        createCheckbox={createCheckbox}
        createTextArea={createTextArea}
        createInputNumber={createInputNumber}
        createSelect={createSelect}
        createReadOnly={createReadOnly}
        createInputMask={createInputMask}
        createInputMobileNumber={createInputMobileNumber}
        createInputGroupNumber={createInputGroupNumber}
        createTelephoneNumber={createTelephoneNumber}
      />
    );
  };
