import React, { useCallback, useState, useMemo } from "react";
import withModal from "modules/common/hoc/withModal";
import _ from "lodash";
import { ModalContext } from "App";
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ToastSuccess } from "modules/common/components/Toast";
import ReactInputMask from "react-input-mask";
import Button from "components/Button";
import { useLoading } from "modules/ui/hooks";
import * as actions from "../action";
import * as c from "../constant";
import AssignSIM from "../components/AssignSIM";

const form = {
  name: "",
  address: "",
  tin: "",
  business_style: "",
  code: "",
  sources: [],
};

function CreateBranchForm({
  createBranch,
  getBranch,
  retailerMins,
  dealerMins,
}) {
  const history = useHistory();
  const setModal = React.useContext(ModalContext);
  const [formData, setFormData] = React.useState(form);
  const [pending, setPending] = useState(false);

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleChangeInput = (key) => ({ target }) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: target.value,
    }));
  };

  const handleSIMAssignment = useCallback(
    (sources) => {
      setFormData((prevState) => ({
        ...prevState,
        sources,
      }));
    },
    [setFormData]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);
    const payload = {
      ...formData,
    };

    return createBranch(
      payload,
      (res) => {
        ToastSuccess("Successfully created.");
        setModal({ isOpen: false });
        setPending(false);
        getBranch();
        history.push(`/manage/branch/${_.get(res, "data.id")}/branch-admins`);
      },
      () => setPending(false)
    );
  };

  const isFormInvalid = useMemo(() => {
    const { tin, ...rest } = formData;
    if (_.some(rest, _.isEmpty)) return true;
    return _.size(tin) < 15;
  }, [formData]);

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-6">
          <label className="form-label text-success">Branch Name:</label>
          <input
            autoFocus
            type="text"
            required
            name="name"
            value={_.get(formData, "name") || ""}
            onChange={handleChangeInput("name")}
            className="form-control form-custom form-control_bottom"
            maxLength={c.FIELD_MAX_LIMIT}
          />
        </div>
        <div className="col-6">
          <label className="form-label text-primary">Address:</label>
          <input
            type="text"
            name="address"
            required
            value={_.get(formData, "address") || ""}
            onChange={handleChangeInput("address")}
            className="form-control form-custom form-control_bottom"
            maxLength={300}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-4">
          <label className="form-label text-primary">Branch TIN:</label>
          <ReactInputMask
            type="text"
            mask="999-999-999-99999"
            placeholder="111-111-111-11111"
            maskChar=""
            className="form-control form-custom form-control_bottom"
            name="tin"
            required
            onChange={handleChangeInput("tin")}
            value={_.get(formData, "tin") || ""}
          />
        </div>
        <div className="col-4">
          <label className="form-label text-primary">Business Style:</label>
          <input
            type="text"
            required
            name="business_style"
            value={_.get(formData, "business_style") || ""}
            onChange={handleChangeInput("business_style")}
            className="form-control form-custom form-control_bottom"
            maxLength={c.FIELD_MAX_LIMIT}
          />
        </div>
        <div className="col-4">
          <label className="form-label text-primary">Branch Code:</label>
          <input
            type="text"
            name="code"
            required
            value={_.get(formData, "code") || ""}
            className="form-control form-custom form-control_bottom"
            onChange={handleChangeInput("code")}
            maxLength={c.FIELD_MAX_LIMIT}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <label className="form-label text-primary">Assigned MINs:</label>
          <AssignSIM
            dealerMins={dealerMins}
            retailerMins={retailerMins}
            onChange={handleSIMAssignment}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <Button
              type="submit"
              className="btn btn-primary position-relative font-weight-bold mr-2 px-4"
              disabled={pending || isFormInvalid}
              loading={useLoading(c.CREATE_BRANCH)}
            >
              Save
            </Button>
            <Button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold ml-2 px-3"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

CreateBranchForm.propTypes = {
  createBranch: PropTypes.func.isRequired,
  getBranch: PropTypes.func.isRequired,
  dealerMins: PropTypes.instanceOf(Array).isRequired,
  retailerMins: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api }) => ({
  dealerMins: _.get(api, `${c.GET_DEALER_SIMS}.list`),
  retailerMins: _.get(api, `${c.GET_RETAILERS_SIMS}.list`),
});

const enhance = _.flowRight([
  withModal("create-branch", {
    title: "Create Branch",
    size: "modal-xl",
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(CreateBranchForm);
