import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

export const SimFilterContext = createContext();

const filter = {
  page: 1,
  per_page: 10,
  q: "",
  type: "",
  assigned_to: "",
  request_type: "",
  status: "",
  sim_status: "",
};

const sortFilter = {
  order_by: "created_at",
  sort_by: "desc",
};

export const SimFilterProvider = ({ children }) => {
  const [filterData, setFilterData] = useState(filter);

  const value = {
    filterData,
    setFilterData,
    sortFilter,
  };

  return (
    <SimFilterContext.Provider value={value}>
      {children}
    </SimFilterContext.Provider>
  );
};
SimFilterProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
export const useFilterSim = () => {
  return useContext(SimFilterContext);
};
