import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import OverflowTip from 'modules/common/components/OverflowTip';

const ListItem = React.memo(({ hide, data, onClick, icon }) => {
  const fullName = _.get(data, 'attributes.full_name');
  const mobile = _.get(data, 'attributes.mobile_identification_number');

  const handleClick = () => {
    onClick(data);
  };

  if (hide) return null;

  return (
    <div
      tabIndex={-1}
      role="menuitem"
      onKeyUp={() => {}}
      className="d-flex justify-content-between align-items-center px-3 border-bottom border-gray-200 mr-4"
      style={{ height: 55, cursor: 'pointer' }}
      onClick={handleClick}
    >
      <div className="d-flex">
        <div className="mr-3 font-weight-bold">
          {_.get(data, 'attributes.retailer_type')}
        </div>
        <div className="mr-3 font-weight-bold">{mobile}</div>
        <OverflowTip
          id={fullName}
          text={fullName}
          toUpperCase
          isSmall
          className="pr-2 "
        />
      </div>
      <div>
        <i className={icon} />
      </div>
    </div>
  );
});

ListItem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
  hide: PropTypes.bool,
  icon: PropTypes.string,
};

ListItem.defaultProps = {
  onClick: () => {},
  hide: false,
  icon: 'text-primary fa fa-angle-double-right',
};

export default ListItem;
