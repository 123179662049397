export const BALANCE_INQUIRE = 'LOAD-WALLET/balance_inquire';
export const SET_OTP = 'LOAD-WALLET/set_otp';
export const CONFIRM_OTP = 'LOAD-WALLET/confirm_otp';

export const GET_RETAILER_SIMS = 'LOAD-WALLET/get_retailer_sims';
export const GET_RETAILER_SIMS_ACTIVE = 'LOAD-WALLET/get_retailer_sims_active';
export const UPDATE_RETAILER_SIMS = 'LOAD-WALLET/update_retailer_sims';
export const RETAILER_BALANCE_INQUIRE = 'LOAD-WALLET/retailer_balance_inquire';

export const GET_DEALER_SIMS = 'LOAD-WALLET/get_dealer_sims';
export const GET_DEALER_SIMS_ACTIVE = 'LOAD-WALLET/get_dealer_sims_active';
export const SHOW_DEALER_SIM = 'LOAD-WALLET/show_dealer_sim';
export const CREATE_DEALER_SIMS = 'LOAD-WALLET/create_dealer_sims';
export const UPDATE_DEALER_SIMS = 'LOAD-WALLET/update_dealer_sims';
export const GET_ACTIVE_CHILD_SIMS = 'LOAD-WALLET/get_active_child_sims';

export const TRANSFER_INTERNAL = 'LOAD-WALLET/transfer-internal';

export const DOWNLOAD_SIM_HISTORY = 'LOAD-WALLET/download_sim_history';
export const DOWNLOAD_TRANSAC_HISTORY = 'LOAD-WALLET/download_transac_history';
export const VALIDATE_PASSWORD = 'USE-MGT/validate_password';

export const GET_TRANSACTIONS = 'LOAD-WALLET/get_transactions';

export const GET_ALL_TRANSACTIONS = 'LOAD-WALLET/get_all_transactions';

export const FORM_DATA = {};
export const DEALER_SIM_TYPE = 'dealer_sims';
export const SIM_TYPES = ['MOTHER SIM', 'MINI MOTHER SIM', 'BDL SIM'];
export const VALID_BALANCE_RESPONSE_CODE_DEALER = '0';
export const VALID_BALANCE_RESPONSE_CODE_RETAILER = '0';

export const GET_SIM_TYPES_INVALID_FOR_TRANSFER = ['DSP', 'LS2', 'SKAH'];
