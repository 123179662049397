import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Line } from "react-chartjs-2";

const helper = (num) => {
  if (num <= 0.1) return 0.1;
  if (num <= 0.2) return 0.2;
  if (num <= 0.25) return 0.25;
  if (num <= 0.3) return 0.3;
  if (num <= 0.4) return 0.4;
  if (num <= 0.5) return 0.5;
  if (num <= 0.6) return 0.6;
  if (num <= 0.7) return 0.7;
  if (num <= 0.75) return 0.75;
  if (num <= 0.8) return 0.8;
  if (num <= 0.9) return 0.9;
  if (num <= 1.0) return 1.0;
  return 1.0;
};

function DailyTransactions({ data, height }) {
  const ticks = 8;
  const merge = () => {
    const datasets = _.get(data, "datasets", []).map((i) => i.data);
    return datasets.reduce((a, b) => a.concat(b), []);
  };
  const max = Math.max(...merge());
  const min = Math.min(...merge());
  const getTicks = () => {
    const multiplier = 10 ** String(max).length;
    const range = max - min;
    let tr = range / ticks;
    tr /= multiplier;
    tr = helper(tr) * multiplier;
    return {
      step: tr,
      ub: tr * Math.round(1 + max / tr),
    };
  };
  const tick = _.isEmpty(data) ? { step: 0, ub: 0 } : getTicks();
  if (_.isEmpty(data)) return null;
  return (
    <Line
      height={height || 250}
      data={data}
      // legend="bottom"
      options={{
        bezierCurve: true,
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: true,
          position: "top",
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: "rgba(80, 80, 80, 0.1)",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: "rgba(80, 80, 80, 0.1)",
              },
              ticks: {
                suggestedMax: tick.ub,
                stepSize: tick.step,
                beginAtZero: true,
              },
            },
          ],
        },
      }}
      fill="rgba(12, 41, 59, 0.2)"
    />
  );
}

DailyTransactions.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  height: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
};

export default DailyTransactions;
