import React from 'react';

import PropTypes from 'prop-types';

import UserAccessDetails from './children-components/UserAccessDetails';
import CompanyInformation from './children-components/CompanyInformation';
import DeliveryAddressInformation from './children-components/DeliveryAddressInformation';
import MoaSignatoryInformation from './children-components/MoaSignatoryInformation';
import DealerAuthorization from './children-components/DealerAuthorization';

const CompanyInformationPage = ({ dealer_id }) => (
  <div className="CompanyInformation-container">
    <UserAccessDetails dealer_id={dealer_id} />
    <CompanyInformation dealer_id={dealer_id} />
    <DeliveryAddressInformation dealer_id={dealer_id} />
    <MoaSignatoryInformation dealer_id={dealer_id} />
    <DealerAuthorization dealer_id={dealer_id} />
  </div>
);

CompanyInformationPage.propTypes = {
  dealer_id: PropTypes.string.isRequired,
};

export default React.memo(CompanyInformationPage);
