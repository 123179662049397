import * as React from 'react';
import PropTypes from 'prop-types';

function SvgComponent(props) {
  const { fill, ...rest } = props;
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.7 5.5a.7.7 0 10-1.4 0v2.8H5.5a.7.7 0 000 1.4h2.8v2.8a.7.7 0 101.4 0V9.7h2.8a.7.7 0 100-1.4H9.7V5.5z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3.556a5.444 5.444 0 105.273 4.082.778.778 0 111.506-.387 7 7 0 11-2.841-4.039.778.778 0 11-.876 1.285A5.416 5.416 0 009 3.556z"
        fill={fill}
      />
    </svg>
  );
}

SvgComponent.propTypes = {
  fill: PropTypes.string,
};

SvgComponent.defaultProps = {
  fill: '#999',
};

const MemoSvgComponent = React.memo(SvgComponent);
export default MemoSvgComponent;
