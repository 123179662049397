import React from "react";
import { NavLink, withRouter, Switch, Route } from "react-router-dom";
import IconProducts from "assets/images/icon/icon-product.png";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { ModalContext } from "App";
import { checkPermissions } from "helper";
import { useMyProfile } from "modules/auth/hooks";
import ChangePassword from "../components/ChangePassword";
import ChangeSecurity from "../components/ChangeSecurity";
import DataPrivacy from "../components/DataPrivacy";
import UpdateProfile from "../form/UpdateProfile";
import Security from "./SecuritySettingsContainer";
import * as actions from "../actions";
import * as c from "../constant";
import SetMpin from "../components/SetMpin";
import ForgotSecurityPin from "../components/ForgotSecurityPin";

function ProfileContainer({
  match,
  showRetailer,
  retailer,
  retailerId,
  roles,
  profileType,
  showRetailerUses,
  retailerUser,
  showDealerUser,
  showCorpAdmin,
}) {
  const setModal = React.useContext(ModalContext);

  const isKapartnerUser =
    profileType === "retailers" && _.get(roles, "0", "") === "kapartner-user";
  const isDealer = profileType === "dealers";
  const isRetailerUser =
    (profileType === "retailer_users" &&
      _.get(roles, "0", "") === "branch-admin") ||
    _.get(roles, "0", "") === "frontliner";
  const isCorpAdmin = _.includes(_.get(roles, "0", ""), "corporate-admin");

  const myProfile = useMyProfile();

  const handleChecked = (key) => (e) => {
    e.preventDefault();
    if (isKapartnerUser) {
      setModal("set-mpin", {
        data: {
          [key]: +_.get(retailer, "attributes.is_pin_enabled") === 1 ? 0 : 1,
        },
        type: "change-password",
        mobile_identification_number: _.get(
          retailer,
          "attributes.mobile_identification_number"
        ),
      });
    }
    if (isRetailerUser) {
      setModal("set-mpin", {
        data: {
          [key]:
            +_.get(retailerUser, "attributes.is_pin_enabled") === 1 ? 0 : 1,
        },
        type: "change-password",
        mobile_identification_number: _.get(retailerUser, "attributes.mobile"),
      });
    }
  };
  // eslint-disable-next-line no-unused-vars
  const renderPin = () => {
    if (isKapartnerUser) {
      return (
        <div className="justify-content-end">
          <input
            className="toggle-switch self"
            type="checkbox"
            checked={+_.get(retailer, "attributes.is_pin_enabled") === 1}
            onChange={handleChecked("is_pin_enabled")}
          />
        </div>
      );
    }
    if (isRetailerUser) {
      return (
        <div className="justify-content-end">
          <input
            className="toggle-switch self"
            type="checkbox"
            checked={+_.get(retailerUser, "attributes.is_pin_enabled") === 1}
            onChange={handleChecked("is_pin_enabled")}
          />
        </div>
      );
    }
    return null;
  };

  React.useEffect(() => {
    const profileId = myProfile?.id;
    if (isKapartnerUser) {
      showRetailer(retailerId);
    }
    if (isRetailerUser) {
      showRetailerUses();
    }
    if (isDealer && profileId) {
      showDealerUser(profileId);
    }
    if (isCorpAdmin) {
      showCorpAdmin();
    }
  }, [
    isKapartnerUser,
    isRetailerUser,
    isDealer,
    isCorpAdmin,
    showRetailer,
    showRetailerUses,
    showDealerUser,
    retailerId,
    showCorpAdmin,
    myProfile,
  ]);
  return (
    <div className="main-content-inner space d-flex flex-column px-0 h-100">
      {checkPermissions(["can-view-retailer-profile"]) && (
        <div className="container-fluid mb-3">
          <div className="row h-100 d-flex flex-row">
            <div className="col-12">
              <div className="header-profile app-header shadow-sm">
                <UpdateProfile newData={myProfile?.attributes} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container-fluid flex-grow-1">
        <div className="row h-100">
          <div className="col-4 px-0 h-100">
            <div className="app-listing d-flex flex-column h-100">
              <div className="app-list submenu-list shadow-sm flex-grow-1">
                <NavLink
                  exact
                  activeClassName="active"
                  to={`${match.path}`}
                  className="submenu-item"
                >
                  <img
                    src={IconProducts}
                    alt="Change Password"
                    className="icon-image"
                  />
                  <span className="name">Change Password</span>
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to={`${match.path}/data-privacy`}
                  className="submenu-item"
                >
                  <img
                    src={IconProducts}
                    alt="Data Privacy"
                    className="icon-image"
                  />
                  <span className="name">Data Privacy</span>
                </NavLink>
                <NavLink
                  activeClassName="active"
                  to={`${match.path}/security`}
                  className="submenu-item"
                >
                  <img
                    src={IconProducts}
                    alt="Security Pin"
                    className="icon-image"
                  />
                  <span className="name">Security Pin</span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col-8 pl-0 h-100">
            <div className="app-section h-100 p-lg-0 p-xl-4">
              <Switch>
                <Route exact path={`${match.path}/security`}>
                  <Security />
                </Route>
                <Route path={`${match.path}/security/change-pin`}>
                  <ChangeSecurity />
                </Route>
                <Route path={`${match.path}/data-privacy`}>
                  <DataPrivacy />
                </Route>
                <Route path={`${match.path}/security/forgot-security-pin`}>
                  <ForgotSecurityPin />
                </Route>
                <Route exact path={`${match.path}`}>
                  <ChangePassword />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
      <SetMpin />
    </div>
  );
}

ProfileContainer.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  showRetailer: PropTypes.func.isRequired,
  retailerId: PropTypes.string.isRequired,
  showRetailerUses: PropTypes.func.isRequired,
  showDealerUser: PropTypes.func.isRequired,
  showCorpAdmin: PropTypes.func.isRequired,
  retailer: PropTypes.instanceOf(Object).isRequired,
  retailerUser: PropTypes.instanceOf(Object).isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  profileType: PropTypes.string.isRequired,
};

const mapStateToProps = ({ api }) => ({
  retailer: _.get(api, `${c.SHOW_RETAILER}.item`) || {},
  retailerUser: _.get(api, `${c.SHOW_RETAILER_USERS}.item`) || {},
  retailerId: _.get(api, "AUTH/user_profile.item.retailer_id") || "",
  profileType: _.get(api, "AUTH/user_profile.item.profile_type") || "",
  roles: _.get(api, "AUTH/user_profile.item.roles") || [],
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(ProfileContainer);
