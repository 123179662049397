import _ from 'lodash';
import * as c from './constants';

export const startLoading = (x, z = 'loader') => ({
  ...x,
  loading: x.loading.concat([z]),
});

export const endLoading = (x, z = 'loader') => ({
  ...x,
  loading: x.loading.filter(y => y !== z),
});

export const apiInitState = {
  loading: [],
  errors: {},
};

export default {
  [c.GET_LIST_ETERNAL]: (state, { key }) => startLoading(state, key),
  [c.GOT_LIST_ETERNAL]: (state, { key, data, pager, res, reset }) => {
    const oldList = _.get(state, `${key}.list`) || [];
    const newList = reset ? data : _.unionBy(oldList, data, 'id');
    return endLoading(
      {
        ...state,
        [key]: {
          list: newList || [],
          pager: pager || {},
          res: res || {},
        },
      },
      key
    );
  },
  [c.GET_LIST]: (state, { key }) => startLoading(state, key),
  [c.GOT_LIST]: (state, { key, data, pager, res }) =>
    endLoading(
      {
        ...state,
        [key]: {
          list: data || [],
          pager: pager || {},
          res: res || {},
        },
      },
      key
    ),
  [c.GET_ITEM]: (state, { key }) => startLoading(state, key),
  [c.GOT_ITEM]: (state, { key, data, res }) => {
    if (key === 'AUTH/logout') return apiInitState;
    const listKey = key.replace('select_', 'list_');
    const oldList = _.get(state, `${listKey}.list`) || [];
    const newList = {};
    if (oldList.length > 0) {
      newList[listKey] = {
        ...state[listKey],
        list: oldList.map(x => (x.id === data.id ? data : x)),
      };
    }
    return endLoading(
      {
        ...state,
        ...newList,
        [key]: {
          item: data || [],
          res: res || {},
        },
      },
      key
    );
  },
  [c.APPEND_ITEM]: (state, { key, data, res }) =>
    endLoading(
      {
        ...state,
        [key]: {
          item: {
            ...(_.get(state, `${key}.item`) || {}),
            ...(data || {}),
          },
          res: res || {},
        },
      },
      key
    ),
  [c.CREATE_ITEM]: (state, { key }) => startLoading(state, key),
  [c.CREATED_ITEM]: (state, { key }) => endLoading(state, key),
  [c.UPDATE_ITEM]: (state, { key }) => startLoading(state, key),
  [c.UPDATED_ITEM]: (state, { key }) => endLoading(state, key),
  [c.UPLOAD_FORM_DATA]: (state, { key }) => startLoading(state, key),
  [c.UPLOADED_FORM_DATA]: (state, { key }) => endLoading(state, key),
  [c.DOWNLOAD]: (state, { key }) => startLoading(state, key),
  [c.DOWNLOADED]: (state, { key }) => endLoading(state, key),

  [c.GOT_ITEM_DELAY]: (state, { key }) => startLoading(state, key),

  ON_ERROR: (state, action) => ({
    ...state,
    errors: {
      ...state.errors,
      [action.key]: action.error,
    },
    loading: state.loading.filter(x => x !== action.key),
  }),
  [c.CLEAR_ERROR]: (state, { key }) => {
    const newErrors = state.errors;
    delete newErrors[key];
    return {
      ...state,
      errors: newErrors,
    };
  },
  RESET: () => apiInitState,
};
