import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from 'components/Button';
import { ToastSuccess } from 'modules/common/components/Toast';
import * as actions from '../action';

const otpLength = 6;

const convertDisplayTime = seconds => {
  const displayMinutes = Math.floor(seconds / 60);
  const displaySeconds = seconds - displayMinutes * 60;
  const stringifiedMins = displayMinutes.toString();
  const stringifiedSecs = displaySeconds.toString();

  return `${stringifiedMins}:${
    displaySeconds <= 9 ? '0'.concat(stringifiedSecs) : stringifiedSecs
  }`;
};

const SendOTPForm = ({
  onNextStep,
  requestOTP,
  confirmOTP,
  username,
  setOtp,
  otp,
  spiel,
}) => {
  const numOfMins = 5;
  const initSeconds = numOfMins * 60;
  const [seconds, setSeconds] = useState(initSeconds);
  const isTimeUp = seconds <= 0;
  const [isPending, setIsPending] = useState(false);

  const emailDetails = spiel?.email?.detail;

  const handleInputChange = event => {
    const value = _.get(event, 'target.value');
    const match = value.match(/\d+/g);
    const transformed = match ? match[0].slice(0, otpLength) : '';
    setOtp(transformed);
  };

  const handleSendOtp = () => {
    setIsPending(true);
    requestOTP({ username, purpose: 'portal-verification' }, () => {
      ToastSuccess('OTP request sent.');
      setSeconds(initSeconds);
      setIsPending(false);
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsPending(true);

    if (isTimeUp) {
      handleSendOtp();
      return;
    }

    const successCallback = () => {
      setIsPending(false);
      onNextStep();
    };

    const errorCallback = () => {
      setIsPending(false);
      setOtp('');
    };

    confirmOTP(
      { username, otp, purpose: 'portal-verification' },
      successCallback,
      errorCallback
    );
  };

  useEffect(() => {
    let timer = null;
    if (!isTimeUp) {
      timer = setTimeout(() => setSeconds(seconds - 1), 1000);
    }

    return () => clearTimeout(timer);
  }, [seconds, isTimeUp, setSeconds]);

  return (
    <div style={{ maxWidth: 450 }} className="w-100 mx-auto">
      <h4 className="font-weight-bold text-center m-0 mb-4">
        Enter One-Time-Pin
      </h4>
      <p className="text-center m-0">
        We sent a One-Time-Pin to your email {emailDetails?.email_start}***
        {emailDetails?.email_end} and mobile number +63********
        {spiel?.sms?.detail?.mobile_end}
      </p>

      <form onSubmit={handleSubmit} className="mt-5">
        <div className="form-group text-center">
          <label className="form-label enter-otp-label">
            {`Enter your 6-Digit OTP `}
            <span className="text-danger">({convertDisplayTime(seconds)})</span>
          </label>
          <input
            className="form-control form-custom form-control_bottom text-center"
            type="text"
            required
            name="otp"
            value={otp}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group mt-4 text-center">
          <Button
            type="submit"
            loading={isPending}
            disabled={otp.length !== otpLength}
            className="btn btn-block btn-primary px-4 position-relative"
          >
            {isTimeUp ? 'Resend OTP' : 'Continue'}
          </Button>

          <p className="mt-3">
            {`Did not get? `}
            <Button
              type="button"
              loading={isPending}
              className="btn btn-link p-0 text-capitalize position-relative"
              onClick={handleSendOtp}
            >
              Resend
            </Button>
          </p>
        </div>
      </form>
    </div>
  );
};

SendOTPForm.propTypes = {
  onNextStep: PropTypes.func.isRequired,
  requestOTP: PropTypes.func.isRequired,
  confirmOTP: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  setOtp: PropTypes.func.isRequired,
  otp: PropTypes.string.isRequired,
  spiel: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = () => ({});

const enhance = _.flowRight([
  connect(mapStateToProps, {
    ...actions,
  }),
]);

export default enhance(SendOTPForm);
