import React from "react";
import placeholderPhoto from "assets/images/avatar-photo.png";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ToastSuccess } from "modules/common/components/Toast";
import * as actions from "../actions";
import * as c from "../constant";

const form = {
  last_name: "",
  first_name: "",
  middle_name: "",
  email: "",
};

function UpdateProfile({ updateProfile, showRetailer, retailerId, newData }) {
  const [state, setState] = React.useState(false);
  const [formData, setFormData] = React.useState(form);

  const handleChangeInput = (key) => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      last_name: _.get(formData, "last_name"),
      first_name: _.get(formData, "first_name"),
      middle_name: _.get(formData, "middle_name"),
      email: _.get(formData, "email"),
    };

    updateProfile(payload, () => {
      setState(false);
      ToastSuccess("Successfully changed your profile.");
      showRetailer(retailerId);
    });
  };

  React.useEffect(() => {
    setFormData({
      ...form,
      last_name: _.get(newData, "last_name"),
      first_name: _.get(newData, "first_name"),
      middle_name: _.get(newData, "middle_name"),
      email: _.get(newData, "email"),
    });
  }, [newData, setFormData]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row d-flex align-items-center h-100">
        <div className="form-group text-center mb-xl-0 mb-lg-4 col-xl-2 col-lg-12">
          <div className="user-avatar mx-auto">
            <img
              className="img-fluid"
              src={_.get(newData, "profile_img") || placeholderPhoto}
              alt="Juan Dela Cruz"
            />
          </div>
        </div>
        <div className="form-group mb-xl-0 mb-lg-auto col-xl-2 col-lg-5 pr-lg-auto pr-xl-0">
          <label className="form-label text-primary">First Name:</label>
          <input
            className="form-control form-custom form-control_bottom"
            type="text"
            placeholder="ex. Juan"
            value={_.get(formData, "first_name") || ""}
            readOnly={state === false}
            onChange={handleChangeInput("first_name")}
          />
        </div>
        <div className="form-group mb-xl-0 mb-lg-auto col-xl-2 col-lg-5 pr-lg-auto pr-xl-0">
          <label className="form-label text-primary">Last Name:</label>
          <input
            className="form-control form-custom form-control_bottom"
            type="text"
            placeholder="ex. Dela Cruz"
            value={_.get(formData, "last_name") || ""}
            readOnly={state === false}
            onChange={handleChangeInput("last_name")}
          />
        </div>
        <div className="form-group mb-xl-0 mb-lg-auto col-xl-3 col-lg-12 pr-lg-auto pr-xl-0">
          <label className="form-label text-primary">Email Address:</label>
          <input
            className="form-control form-custom form-control_bottom"
            type="text"
            value={_.get(formData, "email") || ""}
            placeholder="ex. juandelacruz@gmail.com"
            readOnly={state === false}
            onChange={handleChangeInput("email")}
          />
        </div>
      </div>
    </form>
  );
}

UpdateProfile.propTypes = {
  updateProfile: PropTypes.func.isRequired,
  showRetailer: PropTypes.func.isRequired,
  retailerId: PropTypes.string.isRequired,
  newData: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ api }) => ({
  retailerId: _.get(api, "AUTH/user_profile.item.retailer_id") || "",
  isLoading: api.loading.indexOf(c.UPDATE_PROFILE) > -1,
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(UpdateProfile);
