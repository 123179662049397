import React, {
  useContext,
  useState,
  useMemo,
  useEffect,
  createContext,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { removeEmpty } from "helper";
import Select from "react-select";
import { USER_PROFILE } from "modules/auth/constants";
import { useRouteMatch } from "react-router-dom";
import { getBranch } from "app/dashboard/actions";
import { GET_BRANCH } from "app/dashboard/constant";
import { ModalContext } from "App";
import withModal from "modules/common/hoc/withModal";
import { useLoading } from "modules/ui/hooks";
import ButtonAsync from "components/Button";
import * as c from "../../constant";
import * as actions from "../../actions";

import "./Transfer.style.scss";

// Custom style for select sim type input element
const style = {
  control: (base) => ({
    ...base,
    border: 0,
    // This line disable the blue border
    boxShadow: "none",
  }),
};

const transferDefaults = {
  data: {
    transferType: "internal",
    sourceType: "",
    sourceMin: "",
    sourceId: "",
    targetType: "dealer_retailer_sims",
    targetMin: "",
    targetId: "",
    amount: "",
  },
  loading: false,
};

export const TransferContext = createContext(transferDefaults);

const TransferProvider = ({ children }) => {
  // get source from store
  const source = useSelector(({ modal }) => _.get(modal, "modalData.item", {}));

  // initialize context state
  const transferState = useState(() => ({
    ...transferDefaults,
    data: {
      ...transferDefaults.data,
      sourceId: _.get(source, "id", ""),
      sourceType: _.get(source, "attributes.type", ""),
      sourceMin: _.get(source, "attributes.mobile_identification_number", ""),
    },
  }));

  return (
    <TransferContext.Provider value={transferState}>
      {children}
    </TransferContext.Provider>
  );
};

TransferProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const Transfer = () => {
  return (
    <TransferProvider>
      <TransferForm>
        <div className="col">
          <h6 className="h6 font-weight-bold my-3">Transfer From:</h6>
          <div className="form-row">
            <div className="col-5">
              <SourceTypeField />
            </div>
            <div className="col">
              <SourceMINField />
            </div>
          </div>
          <hr className="my-4" />
          <h6 className="h6 font-weight-bold my-3">Transfer To:</h6>
          <TargetTypeField />
          <TargetMinField />
          <AmountField />
          <div className="row justify-content-end mb-3">
            <div className="col-auto">
              <ProceedButton />
            </div>
          </div>
        </div>
      </TransferForm>
    </TransferProvider>
  );
};

const TransferForm = ({ children }) => {
  const [context] = useContext(TransferContext);
  const setModal = useContext(ModalContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    setModal("transfer-confirm", context.data);
  };

  return <form onSubmit={handleSubmit}>{children}</form>;
};

TransferForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const SourceTypeField = () => {
  const [{ data }] = useContext(TransferContext);

  return (
    <div className="form-group mb-0">
      <label className="form-label text-success">SIM Type:</label>
      <input
        type="text"
        required
        className="form-control"
        value={data.sourceType}
        readOnly
      />
    </div>
  );
};

const SourceMINField = () => {
  const [{ data }] = useContext(TransferContext);
  return (
    <div className="form-group mb-0">
      <label className="form-label text-success">Mobile Number:</label>
      <input
        type="text"
        required
        className="form-control"
        value={data.sourceMin}
        readOnly
      />
    </div>
  );
};

const TargetTypeField = () => {
  const [context, setContext] = useContext(TransferContext);
  const simTypes = [
    { value: "dealer_retailer_sims", label: "Retailer SIMS" },
    { value: "dealer_sims", label: "Dealer SIMS" },
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;

    setContext((current) => ({
      ...current,
      data: {
        ...context.data,
        [name]: value,
        targetId: "",
      },
    }));
  };

  return (
    <div className="form-group">
      {simTypes.map((type) => (
        <div
          key={type.value}
          className="custom-control custom-radio custom-control-inline"
        >
          <input
            type="radio"
            id={`radio_${type.value}`}
            name="targetType"
            className="custom-control-input"
            value={type.value}
            onChange={handleChange}
            checked={context.data.targetType === type.value}
          />
          <label
            className="custom-control-label"
            htmlFor={`radio_${type.value}`}
          >
            {type.label}
          </label>
        </div>
      ))}
    </div>
  );
};

const InvalidSource = () => (
  <p className="bg-gray-100 px-3 py-2 border border-gray-300 rounded-sm text-gray-400">
    Sim type is not allowed to transfer as source.
  </p>
);

const TargetMinField = () => {
  const [{ data }] = useContext(TransferContext);
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const isDSP = data?.sourceType?.includes("DSP");
  const isSKAH = data?.sourceType?.includes("SKAH");
  const isSubDealer = profile?.roles?.[0] === "sub-dealer";
  const isNonMigrated = profile?.roles?.[0]?.includes("non-migrated");
  const isB2B = profile?.roles?.[0]?.includes("b2b");
  const invalidRetailerSimTransfer = (isDSP && isSubDealer) || isSKAH;

  const invalidDealerSimTransfer = _.includes(
    c.GET_SIM_TYPES_INVALID_FOR_TRANSFER,
    data.sourceType
  );

  switch (data.targetType) {
    case "dealer_retailer_sims":
      return invalidRetailerSimTransfer && !isNonMigrated && !isB2B ? (
        <InvalidSource />
      ) : (
        <RetailerSIMSelection />
      );

    case "dealer_sims":
      return invalidDealerSimTransfer ? (
        <InvalidSource />
      ) : (
        <DealerSIMSelection />
      );
    default:
      return null;
  }
};

const RetailerSIMSelection = () => {
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const parentMatch = useRouteMatch();
  const matchUrl = useRouteMatch(`${parentMatch.url}/:dealer_id?`);
  const { dealer_id } = _.get(matchUrl, "params");
  const [{ data }, setContext] = useContext(TransferContext);
  const dispatch = useDispatch();
  const [q, setQ] = useState("");
  const isLoading = useLoading(c.GET_RETAILER_SIMS_ACTIVE);
  const retailerSIMs = useSelector(({ api }) =>
    _.get(api, `${c.GET_RETAILER_SIMS_ACTIVE}.list`)
  );

  const selectors = {
    branchSources: useSelector(
      ({ api }) => _.get(api, `${GET_BRANCH}.item.included.sources`, []) || []
    ),
  };
  const filteredBranchSources = _.filter(
    selectors.branchSources,
    (sim) =>
      _.get(sim, "attributes.type") === "dealer_retailer_sims" &&
      _.get(sim, "attributes.is_active") === true
  );

  const branchRetailerSims = _.intersectionBy(
    retailerSIMs,
    filteredBranchSources,
    (sim) => _.get(sim, "attributes.mobile_identification_number")
  );

  // build options for select field
  const options = useMemo(() => {
    if (!retailerSIMs) return [];

    const mappingFn = (sim) => {
      const company = _.get(sim, `attributes.company_name`);
      const min = _.get(sim, `attributes.mobile_identification_number`);
      const type = _.get(sim, `attributes.sim_type`) || "";

      return {
        value: _.get(sim, `id`),
        label: `${type} ${min} ${!_.isEmpty(company) ? `- ${company}` : ""}`,
      };
    };

    const displaySim = !_.isEmpty(selectors.branchSources)
      ? branchRetailerSims
      : retailerSIMs;
    return displaySim.map(mappingFn);
  }, [retailerSIMs, selectors.branchSources, branchRetailerSims]);

  const handleChange = ({ value, label }) => {
    setContext((current) => ({
      ...current,
      data: {
        ...current.data,
        targetId: value,
        targetMin: label,
      },
    }));
  };

  useEffect(() => {
    dispatch(
      actions.getActiveRetailerSims({
        q,
        is_active: true,
        per_page: 9999,
        dealer_id:
          dealer_id || _.get(profile, "id") || _.get(profile, "parent_id"),
      })
    );
    dispatch(getBranch());
  }, [q, profile, dispatch, dealer_id]);

  return (
    <div className="form-group">
      <Select
        defaultOptions
        options={options}
        isLoading={isLoading}
        onInputChange={(val) => setQ(val)}
        onChange={handleChange}
        value={options.find((option) => option.value === data.targetId)}
        styles={style}
        name="targetId"
        className="px-0 border transferTo__select-options border-primary"
        placeholder="Select SIM"
        required
      />
    </div>
  );
};

const DealerSIMSelection = () => {
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const parentMatch = useRouteMatch();
  const matchUrl = useRouteMatch(`${parentMatch.url}/:dealer_id?`);
  const { dealer_id } = _.get(matchUrl, "params");
  const [{ data }, setContext] = useContext(TransferContext);
  const dispatch = useDispatch();
  const [q, setQ] = useState("");
  const isLoading = useLoading(c.GET_DEALER_SIMS_ACTIVE);
  const dealerSimsActiveChild = useSelector(({ api }) =>
    _.get(api, `${c.GET_ACTIVE_CHILD_SIMS}.list`)
  );
  const selectors = {
    branchSources: useSelector(
      ({ api }) => _.get(api, `${GET_BRANCH}.item.included.sources`, []) || []
    ),
  };
  const filteredBranchSources = _.filter(
    selectors.branchSources,
    (sim) =>
      _.get(sim, "attributes.type") === "dealer_sims" &&
      _.get(sim, "attributes.is_active") === true
  );

  const branchDealerSims = _.intersectionBy(
    dealerSimsActiveChild,
    filteredBranchSources,
    (sim) => sim?.attributes?.mobile_identification_number
  );

  const source = useSelector(({ modal }) => _.get(modal, "modalData"));
  // build options for select field
  const options = useMemo(() => {
    if (!dealerSimsActiveChild) return [];

    const mappingFn = (sim) => {
      const {
        mobile_identification_number: min,
        type,
        company_name,
      } = sim.attributes;
      const company = company_name ? `- ${company_name}` : "";
      const value = sim?.id;
      const label = `${type} ${min} ${company}`;

      return { value, label, min };
    };

    const displaySim = !_.isEmpty(selectors.branchSources)
      ? branchDealerSims
      : dealerSimsActiveChild;
    return displaySim
      .map(mappingFn)
      .filter(
        (sim) => sim.min !== source?.attributes?.mobile_identification_number
      );
  }, [
    dealerSimsActiveChild,
    selectors.branchSources,
    branchDealerSims,
    source,
  ]);

  const handleChange = ({ value, label }) => {
    setContext((current) => ({
      ...current,
      data: {
        ...current.data,
        targetId: value,
        targetMin: label,
      },
    }));
  };

  useEffect(() => {
    const simId =
      dealer_id || _.get(profile, "id") || _.get(profile, "parent_id");
    dispatch(
      actions.getActiveDealerSims({
        is_active: true,
        per_page: 9999,
        dealer_id: simId,
        q,
      })
    );

    dispatch(
      actions.getActiveChildSims(
        data.sourceMin,
        removeEmpty({
          sim_type: data.sourceType,
          dealer_id: simId,
        })
      )
    );
    dispatch(getBranch());
  }, [q, data.sourceMin, data.sourceType, dispatch, profile, dealer_id]);

  return (
    <div className="form-group">
      <Select
        defaultOptions
        options={options}
        isLoading={isLoading}
        onChange={handleChange}
        onInputChange={(value) => setQ(value)}
        value={options.find((option) => option.value === data.targetId)}
        styles={style}
        name="targetId"
        className="px-0 border transferTo__select-options border-primary"
        placeholder="Select SIM"
        required
      />
    </div>
  );
};

const AmountField = () => {
  const [{ data }, setContext] = useContext(TransferContext);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const match = value.match(/\d+/g);
    const maskedValue = match ? match[0] : "";

    setContext((current) => ({
      ...current,
      data: { ...current.data, [name]: maskedValue },
    }));
  };

  return (
    <div className="form-group">
      <label className="form-label text-success">Input Amount:</label>
      <input
        type="text"
        name="amount"
        className="form-control form-custom form-control_bottom"
        onChange={handleChange}
        value={data.amount}
        placeholder="P 0.00"
        required
      />
    </div>
  );
};

const ProceedButton = () => {
  const [{ data }] = useContext(TransferContext);

  const hasBlankFields = useMemo(
    () =>
      Object.values(data).some((value) => !value) || Number(data.amount) < 1,
    [data]
  );

  return (
    <ButtonAsync disabled={hasBlankFields} type="submit">
      Proceed
    </ButtonAsync>
  );
};

const modalKey = "transfer-load";
const modalConfig = { title: "TRANSFER LOAD" };

export default withModal(modalKey, modalConfig)(Transfer);
