import React from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { GET_BRANCH } from "app/dashboard/constant";
import { useLoading } from "modules/ui/hooks";
import Loader from "modules/common/components/Loader";

const ShiftSchedule = () => {
  const branchDetails = useSelector(({ api }) =>
    _.get(api, `${GET_BRANCH}.item`, {})
  );

  const isBranchLoading = useLoading(GET_BRANCH);

  const renderShift = () => (
    <>
      <div>
        <h6 className="font-weight-bold mb-1 mt-0">
          {_.get(branchDetails, "attributes.name")}
        </h6>
      </div>
      <small className="text-primary mb-1 font-weight-bold">
        {_.get(branchDetails, "attributes.current_shift.day")}
      </small>
      <div>
        <small className="title m-0 font-weight-bold">
          {_.get(branchDetails, "attributes.current_shift.start_time")} -{" "}
          {_.get(branchDetails, "attributes.current_shift.end_time")}
        </small>
      </div>
    </>
  );

  const renderNoShift = () => (
    <div
      style={{
        height: "4.5rem",
        display: "flex",
        alignItems: "center",
      }}
    >
      <small className="text-danger mb-1 font-weight-bold text-center">
        Currently Out of Shift.
      </small>
    </div>
  );

  const renderContent = () => {
    if (isBranchLoading)
      return (
        <div
          style={{
            height: "4.5rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      );
    if (!_.isEmpty(branchDetails.attributes)) return renderShift();
    return renderNoShift();
  };

  return (
    <div className="w-100 card shadow-sm mb-3">
      <div className="card-header bg-primary">
        <small className="card-title m-0 text-white font-weight-bold">
          Selling Window:
        </small>
      </div>
      <div className="card-body">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-12">{renderContent()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftSchedule;
