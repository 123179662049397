import React, { useMemo } from 'react';
import _ from 'lodash';
import ListAsync from 'components/ListAsync';
import PropTypes from 'prop-types';
import RetailerListItem from './RetailerListItem';

const AvailableRetailers = ({ apiData, selected, onSelectAll, onSelect }) => {
  const { isFetching, isLoading, data, error, refetch } = apiData;
  const { original, current } = selected;
  const list = data?.data || [];
  const retailerCount =
    _.get(
      _.countBy(original, v => v === true),
      'false'
    ) || 0;

  const hideFunc = id => {
    return selected[id] || _.some(current, { id });
  };

  const isEmpty = useMemo(() => {
    if (_.every(original, elem => elem === true)) return true;
    return false;
  }, [original]);

  const showCount =
    _.isNumber(retailerCount) && !isFetching && !isEmpty && !_.isEmpty(list);

  return (
    <div className="d-flex flex-column">
      <div className="mb-2 px-3 py-2 d-flex justify-content-between align-items-baseline">
        <p className="m-0 text-md text-gray-400 font-weight-bold">
          Available Retailers {showCount ? `(${retailerCount})` : ''}
        </p>
        <button
          type="button"
          className="btn-green"
          onClick={onSelectAll}
          disabled={isEmpty}
        >
          ADD ALL TO SELECTION
        </button>
      </div>

      <div className="flex-grow-1 mr-3">
        <div className="box-fit-wrapper" style={{ height: '60vh' }}>
          <div className="box-fit-overflow scroll-lime">
            <ListAsync
              isLoading={isLoading}
              refetch={refetch}
              error={error}
              data={list}
              emptyMessage="No available retailers."
              showEmpty={retailerCount === 0 && isEmpty}
              renderItems={item => {
                return (
                  <RetailerListItem
                    key={item.id}
                    onClick={onSelect}
                    data={item}
                    hide={hideFunc(item?.id)}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AvailableRetailers.propTypes = {
  apiData: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.instanceOf(Object).isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default AvailableRetailers;
