import React from 'react';
import PropTypes from 'prop-types';
import List from 'components/List';
import RetailerListItem from './RetailerListItem';

const SelectedRetailers = ({ data, onClear, onClearAll }) => {
  return (
    <div className="d-flex flex-column">
      <div className="mb-2 px-3 py-2 d-flex justify-content-between align-items-baseline">
        <p className="m-0 text-md text-gray-400 font-weight-bold">
          Selected Retailers ({data.length})
        </p>
        <button
          type="button"
          className="btn-green"
          onClick={onClearAll}
          disabled={data.length === 0}
        >
          CLEAR SELECTION
        </button>
      </div>
      <div className="flex-grow-1 mr-3">
        <div className="box-fit-wrapper" style={{ height: '60vh' }}>
          <div className="box-fit-overflow scroll-lime">
            <List
              data={data}
              renderItems={item => (
                <RetailerListItem
                  onClick={onClear}
                  data={item}
                  icon="text-gray-400 fa fa-times"
                />
              )}
              emptyMessage="No items selected."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SelectedRetailers.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  onClear: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
};

export default SelectedRetailers;
