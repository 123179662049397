import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

function Loader() {
  return (
    <div style={{ minHeight: '9rem' }} className="bg-white">
      <div className="row">
        <div
          className="text-center mt-4"
          style={{
            zIndex: 5,
            left: 0,
            right: 0,
            margin: 'auto',
            width: 40,
            height: 40,
            background: '#eaeaea',
            borderRadius: '50%',
            paddingTop: 6,
            fontWeight: 'bolder',
            fontSize: 20,
          }}
        >
          <span className="fas fa-1x fa-spin fa-spinner" />
        </div>
      </div>
    </div>
  );
}

function EmptyData() {
  return (
    <div style={{ minHeight: '9rem' }} className="bg-white">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="row card-body justify-content-center">
              <span>No results found.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function TabDetails({ headerTitle, data, render, isLoading, containerClass }) {
  const renderRow = (row, index) => {
    try {
      if (typeof render === 'string') return _.get(row, render);
      if (typeof render === 'function') return render(row, index);
      return '-';
    } catch (err) {
      return '- ERR -';
    }
  };
  const renderContent = () => {
    if (isLoading && _.isEmpty(data)) return <Loader />;
    if (_.isEmpty(data)) return <EmptyData />;
    return (
      <div className="p-4 bg-white">
        <div className="row">
          {data.map((item, index) => renderRow(item, index))}
        </div>
      </div>
    );
  };
  return (
    <div className={containerClass}>
      <header
        className="rounded-top header-rounded background-gray text-white overflow-hidden d-flex justify-content-between pl-3"
        style={{ backgroundColor: '#939393' }}
      >
        {headerTitle()}
      </header>
      {renderContent()}
    </div>
  );
}

TabDetails.defaultProps = {
  data: [],
  headerTitle: '',
  render: 'id',
  isLoading: false,
  containerClass: 'DeliveryAddressInformation mt-5 mb-4 border-1 w-100',
};

TabDetails.propTypes = {
  render: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  data: PropTypes.instanceOf(Array),
  headerTitle: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]),
  isLoading: PropTypes.bool,
  containerClass: PropTypes.string,
};

export default TabDetails;
