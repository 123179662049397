import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import withModal from 'modules/common/hoc/withModal';
import userImg from 'assets/images/default-user.jpg';
import withForm from 'modules/common/hoc/withForm';
import { ToastSuccess } from 'modules/common/components/Toast';
import Img from 'modules/common/components/Img';
import InfiniteScroll from 'modules/common/components/InfiniteScroll';
import UploadFileModal from 'modules/common/components/UploadFileModal';
import * as actions from '../../action';
import * as c from '../../constant';
import Assignee from './Assignee';
import UploadPreview from './UploadPreview';
import Notes from './Notes';
import SharedFiles from './SharedFiles';

const renderIcon = type => {
  if (type === 'pdf') {
    return <i className="fas fa-3x fa-file-pdf" style={{ color: 'gray' }} />;
  }
  if (type === 'doc' || type === 'docx') {
    return <i className="fas fa-3x fa-file-word" style={{ color: 'gray' }} />;
  }
  if (type === 'csv') {
    return <i className="fas fa-3x fa-file-csv" style={{ color: 'gray' }} />;
  }
  if (type === 'txt') {
    return <i className="fas fa-3x fa-file-alt" style={{ color: 'gray' }} />;
  }
  return null;
};

const renderType = (xtn, item) => {
  const imgF = ['jpeg', 'jpg', 'png'];
  const detectWhatToRender = () => {
    if (imgF.indexOf(xtn) > -1) {
      return 'img';
    }
    return 'application';
  };

  try {
    const dispatchRender = {
      img: () => (
        <Img
          src={_.get(item, 'attributes.name')}
          className="img-fluid mr-2"
          role="presentation"
          alt={_.get(item, 'attributes.name')}
          style={{
            border: '1px solid lightgray',
            borderRadius: 5,
            width: 102,
            height: 72,
          }}
        />
      ),
      application: () => (
        <a
          href={_.get(item, 'attributes.name')}
          className="d-flex justify-content-center align-items-center mr-2"
          target="_heopenit"
          style={{
            border: '1px solid lightgray',
            borderRadius: 5,
            width: 102,
            height: 72,
          }}
        >
          {renderIcon(xtn)}
        </a>
      ),
    };

    return dispatchRender[detectWhatToRender()]
      ? dispatchRender[detectWhatToRender()]()
      : dispatchRender.default();
  } catch (error) {
    // eslint-disable-next-line no-console
    return null;
  }
};

const INIT_STATE = {
  message: '',
};

function TicketInformationModal({
  data,
  getMessage,
  createMessage,
  getStatus,
  statusList,
  getFiles,
  filesList,
}) {
  const [state, setstate] = useState(INIT_STATE);
  const [newMessage, setNewMessage] = useState({});
  const [file, setFiles] = useState([]);

  const handleChange = key => e => {
    setstate({
      ...state,
      [key]: e.target.value || '',
    });
  };

  const handleOnChangeUpload = files => {
    setFiles({
      ...file,
      files,
    });
  };

  const handleLoadOptions = (page, callback) => {
    getMessage(
      _.get(data, 'id'),
      {
        page,
      },
      res => {
        callback([].concat(res.data).reverse() || []);
      }
    );
  };

  const handleSubmitMessage = e => {
    e.preventDefault();
    const formatFiles = (_.get(file, 'files') || []).map(x => ({
      name: x,
    }));
    const payload = {
      message: _.get(state, 'message'),
      files: formatFiles,
    };
    createMessage(_.get(data, 'id'), payload, res => {
      ToastSuccess('Success!');
      setNewMessage(_.get(res, 'data'));
      getFiles(_.get(data, 'id'));
      setFiles([]);
      setstate(INIT_STATE);
    });
  };

  useEffect(() => {
    getStatus(_.get(data, 'id'));
    getFiles(_.get(data, 'id'));
  }, [getStatus, getFiles, data]);

  return (
    <div className="row p-3">
      <div className="col-12 col-md-9 d-flex flex-column pr-5">
        <div
          style={{
            height: 900,
            overflowY: 'auto',
          }}
          className="chat-list"
        >
          <InfiniteScroll
            loadOptions={handleLoadOptions}
            addedOption={newMessage}
            h={900}
            containerClassName="chat-list"
            render={item => {
              const { attributes, included } = item || {};
              return (
                <div
                  className={`${
                    _.get(attributes, 'is_sender') === 1
                      ? 'text-right mb-5'
                      : 'mb-5'
                  }`}
                >
                  {_.get(attributes, 'is_sender') === 1 ? (
                    <div className="form-label ml-2">
                      <span className="text-primary font-weight-bold">
                        {_.get(attributes, 'created_by') || '--'}
                      </span>{' '}
                      replied to this request via{' '}
                      <span className="text-primary font-weight-bold">
                        Portal
                      </span>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center mb-2">
                      <img
                        src={_.get(attributes, 'created_by_photo') || userImg}
                        alt="user"
                        className="avatar avatar-xs"
                      />
                      <div className="form-label ml-2">
                        <span className="text-primary font-weight-bold">
                          {_.get(attributes, 'created_by') || '--'}
                        </span>{' '}
                        raised this request via{' '}
                        <span className="text-primary font-weight-bold">
                          Portal
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="ml-4 pl-2 mb-2">
                    {_.get(attributes, 'message') || '--'}
                  </div>

                  {!_.isEmpty(_.get(included, 'ticket_files')) && (
                    <div className="ml-4 pl-2 mb-2">
                      <div
                        className={`${
                          _.get(attributes, 'is_sender') === 1
                            ? 'd-flex flex-row-reverse'
                            : 'row'
                        }`}
                      >
                        {(_.get(included, 'ticket_files') || []).map(f => {
                          const url = _.get(f, 'attributes.name') || '';
                          const ext = (url.match(/\.([^.]*?)(?=\?|#|$)/) ||
                            [])[1];
                          return (
                            <div key={_.get(f, 'attributes.name')}>
                              {renderType(ext, f)}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  <div className="form-label ml-4 pl-2">
                    {_.get(attributes, 'created_at_for_humans')}
                  </div>
                </div>
              );
            }}
            defaultValueToRender={() => (
              <div className="mb-5">
                <div className="d-flex align-items-center mb-2">
                  <img
                    src={_.get(data, 'attributes.created_by_photo') || userImg}
                    alt="user"
                    className="avatar avatar-xs"
                  />
                  <div className="form-label ml-2">
                    <span className="text-primary font-weight-bold">
                      {_.get(data, 'attributes.created_by') || '--'}
                    </span>{' '}
                    raised this request via{' '}
                    <span className="text-primary font-weight-bold">
                      Portal
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <div className="form-label ml-4">
                    <span className="text-primary font-weight-bold">
                      Subject
                    </span>{' '}
                    {_.get(data, 'attributes.subject') || '--'}
                  </div>
                </div>
                <div className="ml-4 pl-2 mb-2">
                  {_.get(data, 'attributes.description') || '--'}
                </div>

                {!_.isEmpty(_.get(data, 'included.default_files')) && (
                  <div className="ml-4 pl-2 mb-2">
                    <div
                      className={`${
                        _.get(data, 'attributes.is_sender') === 1
                          ? 'd-flex flex-row-reverse'
                          : 'row'
                      }`}
                    >
                      {(_.get(data, 'included.default_files') || []).map(f => {
                        const url = _.get(f, 'attributes.name') || '';
                        const ext = (url.match(/\.([^.]*?)(?=\?|#|$)/) ||
                          [])[1];
                        return (
                          <div key={_.get(f, 'attributes.name')}>
                            {renderType(ext, f)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                <div className="form-label ml-4 pl-2">
                  {_.get(data, 'attributes.created_at_for_humans')}
                </div>
              </div>
            )}
          />
        </div>
        {!_.isEmpty(file) && (
          <UploadPreview file={file} renderIcon={renderIcon} />
        )}
        <div className="d-flex flex-column align-items-end">
          <div className="prepend-form-right mb-3 w-100">
            <label htmlFor="reply" className="sr-only">
              Reply
            </label>
            <textarea
              name="reply"
              id="reply"
              value={_.get(state, 'message') || ''}
              rows="3"
              onChange={handleChange('message')}
              placeholder="Reply..."
              className="form-control p-3"
            />
            <UploadFileModal
              onChange={handleOnChangeUpload}
              className="btn btn-default btn-sm rounded-circle px-1 py-0 mr-1"
              customButton={() => <i className="fa fa-paperclip" />}
              caption="Maximum size of file is 2MB"
              maxSize={2}
              isImageOnly={false}
              fileType="image/*, .pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, .doc, .docx"
              isMultiple
              buttonStyle={{
                position: 'initial',
              }}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmitMessage}
          >
            <i className="fa fa-paper-plane" />
            <span className="ml-2">Send</span>
          </button>
        </div>
      </div>
      <div className="col-12 col-md-3 d-flex flex-column">
        <div className="flex-grow-1">
          <Assignee data={data} statusOption={statusList} />
          <div className="d-flex align-items-center mb-3">
            <div id="priority" className="form-label">
              Priority:
            </div>
            <div aria-labelledby="priority" className="ml-2">
              {_.get(data, 'attributes.status') || '--'}
            </div>
          </div>
          <div className="d-flex align-items-center mb-4">
            <div id="created-date" className="form-label">
              Created:
            </div>
            <div aria-labelledby="created-date" className="ml-2">
              {moment(_.get(data, 'attributes.created_at')).format('LLL') ||
                '--'}
            </div>
          </div>
          <SharedFiles data={filesList} />
          <Notes data={data} />
        </div>
      </div>
    </div>
  );
}

TicketInformationModal.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  statusList: PropTypes.instanceOf(Array).isRequired,
  filesList: PropTypes.instanceOf(Array).isRequired,
  getMessage: PropTypes.func.isRequired,
  createMessage: PropTypes.func.isRequired,
  getFiles: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
};

const mapStateToProps = ({ modal, api }) => ({
  data: _.get(modal, 'modalData.data') || {},
  showTicketList: _.get(api, `${c.SHOW_TICKET}.item`) || {},
  messageList: _.get(api, `${c.GET_MESSAGE}.list`) || [],
  statusList: _.get(api, `${c.GET_STATUS}.list`) || [],
  filesList: _.get(api, `${c.GET_FILES}.list`) || [],
});

const enhance = _.flowRight([
  withModal('ticket-information', {
    title: 'Ticket',
    size: 'modal-xl',
  }),
  withRouter,
  withForm,
  connect(mapStateToProps, actions),
]);

export default enhance(TicketInformationModal);
