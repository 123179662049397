import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { connect, useDispatch } from "react-redux";
import ButtonAsync from "modules/common/components/ButtonAsync";
import withModal from "modules/common/hoc/withModal";
import withForm from "modules/common/hoc/withForm";
import { ModalContext } from "App";
import { ToastSuccess, ToastError } from "modules/common/components/Toast";
import { checkRoles } from "helper";
import { getPinStatus } from "modules/auth/actions";
import * as actions from "../actions";

function PromoModal({ data, sendLoad, setBalance, inquireBalance, branch_id }) {
  const setModal = useContext(ModalContext);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const is_fl_ba = checkRoles([
    "frontliner",
    "branch-admin",
    "frontliner-non-migrated",
    "branch-admin-non-migrated",
  ]);
  const is_kapartner = checkRoles(["kapartner-user"]);

  const min = _.get(data, "retailer_sim");
  const payloadMin = min.replace("+63", "0");
  const inquireBalancePayload = {
    mobile: payloadMin,
  };

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const sendCallBack = (res) => {
    const response_code = _.get(res, "res.data.response_code");
    const other_response_code = _.get(res, "data.response_code");
    if (response_code === "0000") {
      ToastSuccess(`${_.get(res, "res.data.message")}`);
      setModal({ isOpen: false });
    } else if (other_response_code === "0") {
      ToastSuccess(`${_.get(res, "data.message")}`);
      setModal({ isOpen: false });
    } else {
      ToastError(
        `An error has occurred while processing this request. Response Code ${response_code}`
      );
      setModal({ isOpen: false });
    }
    inquireBalance(inquireBalancePayload, (inquireRes) => {
      setBalance(_.get(inquireRes, "data.available_balance"));
    });
    setLoading(false);

    //  refresh user shift
    if (!is_kapartner) dispatch(actions.showRetailerUses());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // force check user shift before proceeding
    if (!is_kapartner)
      dispatch(
        actions.showRetailerUses({}, (res) => {
          if (!_.get(res, "data.attributes.has_ongoing_schedule")) {
            setModal({ isOpen: false });
            return;
          }
        })
      );

    let payload = {
      category_id: _.get(data, "category_id"),
      target: _.get(data, "mobile_number"),
      keyword: _.get(data, "attributes.dms_keyword"),
      provider_id: _.get(data, "provider_id"),
      source: payloadMin,
      from_mobile: false,
    };

    let modalPayload = {
      data,
      category_id: _.get(data, "category_id"),
      keyword: _.get(data, "attributes.dms_keyword"),
      setBalance,
    };

    if (is_fl_ba) {
      payload = { ...payload, branch_id };
      modalPayload = { ...modalPayload, branch_id };
    }

    dispatch(
      getPinStatus({}, (res) => {
        const is_pin_enabled = _.get(res, "data.attributes.is_pin_enabled");
        if (is_pin_enabled) setModal("set-pin", modalPayload);
        else sendLoad(payload, sendCallBack, () => setLoading(false));
      })
    );
  };

  useEffect(() => {
    if (!is_kapartner) dispatch(actions.showRetailerUses());
  }, []);

  return (
    <div>
      <form className="container-fluid" onSubmit={handleSubmit}>
        <dl className="row">
          <dt className="col-sm-4">Brand:</dt>
          <dd className="col-sm-8 pr-0">
            <p>{_.get(data, "brand")}</p>
          </dd>
          <dt className="col-sm-4">Offer:</dt>
          <dd className="col-sm-8 pr-0">
            <p>{_.get(data, "attributes.name")}</p>
          </dd>
          <dt className="col-sm-4">Description:</dt>
          <dd className="col-sm-8 pr-0">
            <p>{_.get(data, "attributes.description")}</p>
          </dd>
          <dt className="col-sm-4">Price:</dt>
          <dd className="col-sm-8 pr-0">
            <p>PHP &nbsp; {_.get(data, "attributes.amount")}</p>
          </dd>
        </dl>
        <div className="row">
          <div className="col-12">
            <label className="form-label text-primary">
              Subscriber’s Mobile Number:
            </label>
            <input
              className="form-control input-textbox"
              type="text"
              required
              placeholder=""
              readOnly
              value={_.get(data, "mobile_number")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="mt-3 d-flex align-items-center justify-content-end">
              <ButtonAsync type="submit" loading={loading}>
                Submit
              </ButtonAsync>
              <button
                type="button"
                onClick={closeModal}
                className="btn px-5 btn-danger font-weight-bold ml-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

PromoModal.propTypes = {
  sendLoad: PropTypes.func.isRequired,
  setBalance: PropTypes.func.isRequired,
  inquireBalance: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  branch_id: PropTypes.string.isRequired,
};

const mapStateToProps = ({ modal }) => ({
  data: _.get(modal, "modalData.data"),
  setBalance: _.get(modal, "modalData.setBalance"),
  branch_id: _.get(modal, "modalData.branch_id", ""),
});

const enhance = _.flowRight([
  withModal("promo", {
    title: "LOAD OFFER TO SUBSCRIBER",
    size: "modal-md",
  }),
  withRouter,
  withForm,
  connect(mapStateToProps, actions),
]);

export default enhance(PromoModal);
