import React from 'react';
import userImg from 'assets/images/default-user.jpg';

const SelectedAccount = () => (
  <div className="row">
    <div className="col-12 col-md-3 d-flex flex-column">
      <img src={userImg} alt="user" className="img-fluid mb-3" />
      <button type="button" className="btn btn-primary">
        <i className="fa fa-upload" />
        <span className="ml-2">Upload</span>
      </button>
    </div>
    <div className="col-12 col-md-3">
      <div className="form-label">Dealer:</div>
      <div className="py-2 mb-3 border-bottom">Regional Distributor 1</div>

      <div className="form-label">Email Address:</div>
      <div className="py-2 mb-3 border-bottom">
        regionaldistributor1@email.com
      </div>

      <div className="form-label">Dealer Category:</div>
      <div className="py-2 border-bottom">Dealer</div>
    </div>

    <div className="col-12 col-md-3">
      <div className="form-label">Dealer Code:</div>
      <div className="py-2 mb-3 border-bottom">41512515</div>

      <div className="form-label">Mobile Number:</div>
      <div className="py-2 mb-3 border-bottom">09123456789</div>
    </div>

    <div className="col-12 col-md-3 d-flex justify-content-center align-items-start">
      <button type="button" className="btn btn-primary">
        View Details
      </button>
    </div>
  </div>
);

export default SelectedAccount;
