import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

function TransactionDetails({ profile, formData, handleChangeInput }) {
  return (
    <div className="card">
      <div className="card-header bg-card-header">
        <span className="justify-content-center text-white">
          Transaction Details
        </span>
      </div>
      <div className="card-body shadow-sm">
        <div className="row">
          <div className="col-6 p-0">
            <div className="form-group px-2">
              <label className="form-label">Dealer Name:</label>
              <input
                className="form-control input-textbox form-custom form-control_bottom"
                type="text"
                value={_.get(profile, 'attributes.full_name') || '--'}
                readOnly
              />
            </div>
          </div>
          <div className="col-6 p-0">
            <div className="form-group px-2">
              <label className="form-label">Area:</label>
              <input
                className="form-control input-textbox form-custom form-control_bottom"
                type="text"
                value={_.get(profile, 'attributes.area_name') || '--'}
                readOnly
              />
            </div>
          </div>
          <div className="col-5 p-0">
            <div className="form-group px-2">
              <label className="form-label">PO Number:</label>
              <input
                className="form-control input-textbox form-custom form-control_bottom"
                type="text"
                value={formData.po_number || ''}
                onChange={handleChangeInput('po_number')}
                required
              />
            </div>
          </div>
          <div className="col-3 p-0">
            <div className="form-group px-2">
              <label className="form-label">Sales Type:</label>
              <input
                className="form-control input-textbox form-custom form-control_bottom"
                type="text"
                value="CASH"
                readOnly
              />
            </div>
          </div>
          <div className="col-4 p-0">
            <div className="form-group px-2">
              <label className="form-label">Promo:</label>
              <input
                className="form-control input-textbox form-custom form-control_bottom"
                type="text"
                readOnly
              />
            </div>
          </div>
          <div className="col-6 p-0">
            <div className="form-group px-2">
              <label className="form-label">Trasaction Date:</label>
              <input
                className="form-control input-textbox form-custom form-control_bottom"
                type="text"
                value={moment().format('LL')}
                readOnly
              />
            </div>
          </div>
          <div className="col-6 p-0">
            <div className="form-group px-2">
              <label className="form-label">Order From:</label>
              <input
                className="form-control input-textbox form-custom form-control_bottom"
                type="text"
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TransactionDetails.propTypes = {
  profile: PropTypes.instanceOf(Object).isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
  handleChangeInput: PropTypes.func.isRequired,
};

export default TransactionDetails;
