import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useRootPath } from "hooks";
import SearchField from "components/SearchField";
import ListAsync from "components/ListAsync";
import Pagination from "modules/common/components/Pagination";
import { removeEmpty } from "helper";
import { set, get } from "session";
import AgentsListItem from "../components/AgentsListItem";
import { getAgentRaw } from "../actions";

const Listing = () => {
  const history = useHistory();
  const rootPath = useRootPath();
  const urlParams = useParams();
  const location = useLocation();

  const { state = "" } = location;

  const [query, setQuery] = useState("");
  const [page, setPage] = useState("");
  const { isFetching, data, error, refetch } = useQuery({
    queryKey: [
      "dealer-users",
      "",
      removeEmpty({
        q: query,
        page,
        role_slug: "sub-dealer-agent",
      }),
    ],
    queryFn: getAgentRaw,
    onSuccess: (res) => {
      const raw = res?.data?.data;
      if (state?.agentId) {
        const match = _.find(raw, (x) => x.id === state.agentId);
        if (match) {
          history.push(`${rootPath}/${state.agentId}`);
        }
      }
    },
    retry: false,
    keepPreviousData: true,
  });

  const handleItemSelection = (item) => {
    history.push(`${rootPath}/${item.id}`);
  };

  const handleChangePage = (page_num) => {
    set("agent-ops-page", page_num);
    setPage(page_num);
    history.push(rootPath);
  };

  const list = data?.data?.data || [];
  const pager = data?.data?.meta?.pagination || {};
  const page_data = get("agent-ops-page") || "";

  useEffect(() => {
    if (state) setPage(page_data);
  }, [state, page_data]);

  return (
    <div className="d-flex flex-column h-100 border-right border-gray-200">
      <div className="flex-grow-0">
        <div className="px-3 my-4">
          <SearchField
            isLoading={isFetching}
            onChange={(value) => {
              setPage("");
              setQuery(value);
            }}
          />
        </div>
      </div>
      <div className="flex-grow-1">
        <div className="box-fit-wrapper h-100">
          <div className="box-fit-overflow">
            <p className="m-0 px-3 py-2 text-sm text-gray-400">
              List of Sub-dealer Agents ({_.isEmpty(pager) ? "" : pager?.total})
            </p>
            <ListAsync
              isLoading={isFetching}
              refetch={refetch}
              error={error}
              data={list.sort((a, b) => {
                return b?.attributes?.created_at?.localeCompare(
                  a?.attributes?.created_at
                );
              })}
              renderItems={(item) => (
                <AgentsListItem
                  key={item.id}
                  isActive={_.get(urlParams, "agentId") === item.id}
                  onClick={handleItemSelection}
                  data={item}
                />
              )}
            />
            <Pagination onChange={handleChangePage} data={pager} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Listing;
