import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import SearchField from 'components/SearchField';
import SelectAsync from 'components/SelectAsync';

const Filter = ({ values, loading, onChange }) => {
  const [advance, setAdvance] = useState(false);
  const onToggle = () => {
    if (advance) {
      onChange(state => ({ ...state, retailer_type: '' }));
    }
    setAdvance(!advance);
  };
  const config = useMemo(() => {
    return {
      icon: advance ? 'fas fa-chevron-up' : 'fas fa-chevron-down',
      style: advance ? 'btn-green-filled' : 'btn-green',
    };
  }, [advance]);
  return (
    <div>
      <div className="row">
        <div className="col-3 pr-2">
          <button
            type="button"
            className={`${config.style} w-100`}
            onClick={onToggle}
          >
            <span>FILTERS</span>
            <i className={`${config.icon} text-md ml-2`} />
          </button>
        </div>
        <div className="col pl-0">
          <SearchField
            isLoading={loading}
            onChange={value => onChange(state => ({ ...state, q: value }))}
            custom={{ right: '2rem' }}
          />
        </div>
      </div>
      {advance && (
        <div className="row mr-0">
          <div className="col-xl-4 col-lg-8 pr-0">
            <div className="form-group">
              <label className="form-label text-gray-400 font-weight-bold text-xs">
                Retailer Type:
              </label>
              <SelectAsync
                value={values?.retailer_type}
                api="/api/v1/retailers/types"
                transformer={({ data }) => {
                  return data.map(item => ({
                    value: item?.attributes?.title,
                    label: item?.attributes?.title,
                  }));
                }}
                onChange={e =>
                  onChange(state => ({ ...state, retailer_type: e?.value }))
                }
                isDisabled={loading}
                placeholder="All"
                isSearchable={false}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Filter.propTypes = {
  values: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Filter;
