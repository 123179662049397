import React, { useEffect, useMemo } from "react";
import { withRouter, NavLink, Switch, Route } from "react-router-dom";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { checkPermissions } from "helper";

import logo from "assets/images/dealers-portal-logo-sm.png";
import IconDashboard from "assets/images/icon/icon-dashboard.png";
import IconLoad from "assets/images/icon/icon-load.png";
import IconProducts from "assets/images/icon/icon-product.png";
import { useProfile } from "modules/auth/hooks";

import * as actions from "./actions";
import * as c from "./constants";

import "./styles/Sidebar.style.scss";

export const mainModule = [
  {
    is_submenu: false,
    id: "dashboard",
    name: "Dashboard",
    to: "/dashboard",
    icon: IconDashboard,
    permissions: ["can-view-balance", "can-view-transaction"],
    subModules: [],
  },
  {
    id: "sales-order",
    name: "Sales Order",
    to: "/sales-order",
    icon: IconDashboard,
    permissions: [
      "can-view-providers",
      "can-view-dealer-sim-transaction-internal",
    ],

    subModules: [
      {
        id: "load-subscriber",
        name: "Load Subscriber",
        path: "load-subscriber",
        permissions: ["can-view-providers"],
      },
      {
        id: "load-retailer",
        name: "Load Retailer",
        path: "load-retailer",
        permissions: ["can-view-dealer-sim-transaction-internal"],
      },
    ],
  },
  {
    id: "product-management",
    name: "Product Mgnt",
    to: "/product-management",
    icon: IconDashboard,
    permissions: ["can-view-pricings"],
    subModules: [
      {
        id: "pricing",
        name: "Pricing",
        path: "pricing",
        permissions: ["can-view-pricings"],
      },
    ],
  },
  {
    id: "partners",
    name: "Partners",
    to: "/partners",
    icon: IconProducts,
    permissions: [
      "can-view-dealers",
      "can-view-applications",
      "can-view-retailer",
    ],
    subModules: [
      {
        id: "dealer",
        name: "Dealer",
        path: "dealer",
        permissions: ["can-view-dealers"],
      },
      {
        id: "applications",
        name: "Applications",
        path: "applications",
        permissions: ["can-view-applications"],
      },
      {
        id: "retailer",
        name: "Retailer",
        path: "retailer",
        permissions: ["can-view-retailer"],
      },
    ],
  },
  {
    id: "load-wallet",
    name: "Load Wallet",
    to: "/load-wallet",
    icon: IconLoad,
    permissions: ["can-view-dealer-sim", "can-view-retailer-sim"],
    subModules: [
      {
        id: "my-sims",
        name: "My SIMs",
        path: "my-sims",
        permissions: ["can-view-dealer-sim", "can-view-retailer-sim"],
      },
      {
        id: "sub-dealer-sims",
        name: "Sub-Dealer SIMs",
        path: "sub-dealer-sims",
        permissions: ["can-view-dealer-dealer-sim"],
      },
      {
        id: "retailer-sims",
        name: "Retailer SIMs",
        path: "retailer-sims",
        permissions: ["can-view-dealer-retailer-sim"],
      },
    ],
  },
  {
    is_submenu: false,
    id: "inventory",
    name: "Inventory",
    to: "/inventory",
    icon: IconDashboard,
    subModules: [],
  },
  {
    is_submenu: false,
    id: "purchase-order",
    name: "Purchase Order",
    to: "/purchase-order",
    icon: IconDashboard,
    subModules: [],
    permissions: ["can-view-order"],
  },
  {
    id: "retails-mgt",
    name: "Operations Mgmt",
    to: "/manage",
    icon: IconDashboard,
    permissions: [
      "can-view-branch",
      "can-view-schedule",
      "can-view-sub-dealer-agent-dealer-sims",
      "can-view-sub-dealer-agent-dealer-sims",
    ],
    subModules: [
      {
        id: "branch",
        name: "Branch Operation",
        path: "branch",
        permissions: ["can-view-branch"],
      },
      {
        id: "shift-schedule",
        name: "Selling Window",
        path: "shift-schedule",
        permissions: ["can-view-schedule"],
      },
      {
        id: "agent-operation",
        name: "Agent Operation",
        path: "agent-operation",
        permissions: ["can-view-sub-dealer-agent-dealer-sims"],
      },
      {
        id: "agent-clustsers",
        name: "Agent Clusters",
        path: "agent-clusters",
        permissions: ["can-view-sub-dealer-agent-dealer-sims"],
      },
    ],
  },
  {
    id: "notebook",
    name: "Notebook",
    to: "/notebook",
    icon: IconProducts,
    permissions: [
      "can-view-transaction",
      "can-view-sales-profit",
      "can-view-bills-transaction",
      "can-view-dealer-sim-transaction",
    ],
    subModules: [
      {
        id: "load-subscriber-history",
        name: "Load Subscriber History",
        path: "load-subscriber-history",
        permissions: ["can-view-transaction"],
      },
      {
        id: "load-retailer-history",
        name: "Load Retailer History",
        path: "load-retailer-history",
        permissions: ["can-view-dealer-sim-transaction"],
      },
      {
        id: "bill-payment-history",
        name: "Bills Payment History",
        path: "bill-payment-history",
        permissions: ["can-view-bills-transaction"],
      },
      {
        id: "benta-kita",
        name: "Benta at Kita",
        path: "benta-kita",
        permissions: ["can-view-sales-profit"],
      },
    ],
  },
  {
    id: "reports",
    name: "Reports",
    to: "/reports",
    icon: IconDashboard,
    permissions: ["can-view-eload-api-gateway-report"],
    subModules: [
      {
        id: "eload-api-gw",
        name: "Eload API Gateway",
        path: "eload-api-gw",
        permissions: ["can-view-eload-api-gateway-report"],
      },
    ],
  },
  {
    is_submenu: false,
    id: "dealer",
    name: "Dealer Information",
    to: "/dealer-information",
    icon: IconDashboard,
    permissions: ["can-view-dealer-information"],
    subModules: [],
  },
  {
    id: "assurance-management",
    name: "Assurance Mgmt",
    to: "/assurance-management",
    icon: IconProducts,
    permissions: ["can-view-ticket", "can-view-issue"],
    subModules: [
      {
        id: "inquiries",
        name: "Inquiries",
        path: "inquiries",
        permissions: ["can-view-ticket"],
      },
      {
        id: "helpdesk",
        name: "Helpdesk",
        path: "helpdesk",
        permissions: ["can-view-issue"],
      },
    ],
  },
  {
    id: "user-mgt",
    name: "User Account Mgmt",
    to: "/user-mgt",
    icon: IconDashboard,
    permissions: ["can-view-retailer-user", "can-view-dealer-user"],
    subModules: [
      {
        id: "user",
        name: "User Account",
        path: "user",
        permissions: ["can-view-retailer-user", "can-view-dealer-user"],
      },
      { id: "user-roles", name: "User Roles", path: "user-roles" },
    ],
  },
  {
    is_submenu: false,
    id: "profile-setting",
    name: "Profile & Settings",
    to: "/profile-setting",
    icon: IconDashboard,
    permissions: [
      "can-update-password",
      "can-update-pin",
      "can-update-retailer-profile",
      "can-update-retailer-profile",
    ],
    subModules: [
      {
        id: "change-password",
        name: "Change Password",
        path: "change-password",
        permissions: ["can-update-password"],
      },
    ],
  },
  {
    id: "help-support",
    name: "Help & Support",
    to: "/help-support",
    icon: IconProducts,
    permissions: ["can-create-contact-us"],
    subModules: [
      {
        id: "contact",
        name: "Contact Us",
        path: "contact",
        permissions: ["can-create-contact-us"],
      },
    ],
  },
  {
    is_submenu: false,
    id: "api-documentation",
    name: "API Documentation",
    to: "/api-documentation",
    icon: IconDashboard,
    permissions: ["can-view-api-documentation"],
    subModules: [],
  },
];

const DealerSideBar = () => {
  const dispatch = useDispatch();
  const profile = useProfile();

  const companyInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_USER_COMPANY_INFO}.item`, {})
  );

  const companyId = useMemo(
    () =>
      profile?.profile_type === "dealers" ? profile?.id : profile?.parent_id,
    [profile]
  );

  useEffect(() => {
    if (!companyId) return;
    dispatch(actions.showUserCompanyInfo(companyId));
  }, [companyId, dispatch]);

  return (
    <aside className="main-sidebar sidebar__container">
      <header className="flex flex-center mt-5 mb-3">
        <img src={logo} alt="PLTD / SMART Dealer's Portal" />
        <h4 className="h6 text-sm mt-3 ">
          {companyInfo?.attributes?.company_name}
        </h4>
      </header>
      <ul className="pl-0">
        {mainModule.map(
          (modules) =>
            checkPermissions(_.get(modules, "permissions") || []) && (
              <li
                className="menu-item text-left sidebar__list-item"
                key={modules.id}
              >
                <NavLink
                  className="btn btn-menu-link menu-link"
                  to={`${modules.to}`}
                  aria-haspopup="true"
                >
                  <img src={modules.icon} alt="Load" className="icon-image" />
                  <span className="name">{_.get(modules, "name")}</span>
                  {modules.is_submenu !== false && (
                    <Switch>
                      <Route
                        path={`${modules.to}`}
                        render={() => <i className="fas fa-chevron-up" />}
                      />
                      <Route
                        render={() => <i className="fas fa-chevron-down" />}
                      />
                    </Switch>
                  )}
                </NavLink>
                <Route
                  path={`${modules.to}`}
                  render={() => (
                    <div
                      className="menu-child active dropdown-menu-child"
                      aria-label="Load submenu"
                    >
                      <ul className="menu-list">
                        {modules.subModules.map(
                          (sub) =>
                            checkPermissions(
                              _.get(sub, "permissions") || []
                            ) && (
                              <li className="menu-item" key={sub.id}>
                                <NavLink
                                  to={`${modules.to}/${sub.path}`}
                                  className="menu-child-link"
                                  activeClassName="active"
                                >
                                  <span className="name">
                                    {_.get(sub, "name")}
                                  </span>
                                </NavLink>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  )}
                />
              </li>
            )
        )}
      </ul>
    </aside>
  );
};

export default withRouter(DealerSideBar);
