import React, { useState, useEffect } from "react";
import _ from "lodash";
import FormInputSelect from "modules/common/input/FormInputSelect";
import FormInputSelectApi from "modules/common/input/FormInputSelectApi";
import FormInputDate from "modules/common/input/FormInputDate";
import FormInput from "modules/common/input/FormInput";
import PropTypes from "prop-types";
import { ssv } from "helper";
import { customStyles } from "./styles/custom";
import { useDebounce } from "hooks";

function FormComponent({ field, state }) {
  const [filter, setFilter] = state;
  const {
    type,
    label = `${_.startCase(field.name)}:`,
    value = filter[field.name],
    onChange = setFilter,
    ...rest
  } = field;
  const form = {
    text: (
      <FormInput
        label={label}
        type={type}
        value={value}
        onChange={onChange}
        {...rest}
      />
    ),
    "select-static": (
      <FormInputSelect
        styles={customStyles}
        label={label}
        value={value}
        onChange={onChange}
        isSearchable={false}
        {...rest}
      />
    ),
    date: (
      <FormInputDate
        label={label}
        value={value}
        onChange={onChange}
        {...rest}
      />
    ),
    "select-async": (
      <FormInputSelectApi
        styles={customStyles}
        className="react-select-container mb-0"
        classNamePrefix="react-select"
        transformer={(list) => {
          return _.get(list, "data").map((x) => ({
            value: x.id,
            label: _.get(x, "attributes.name"),
          }));
        }}
        label={label}
        value={value}
        onChange={onChange}
        {...rest}
      />
    ),
  };
  return form[type];
}

function AdvancedFilter({ form, state }) {
  return form.map((elem) => {
    const { className = "col-2", show = true, ...item } = elem;
    if (show)
      return (
        <div
          className={ssv(className, "min-filter")}
          key={JSON.stringify(item.name)}
        >
          <FormComponent field={item} state={state} />
        </div>
      );
  });
}

function Filter({
  actions,
  filterData,
  form,
  onDownload,
  onClear,
  loading,
  containerClass,
}) {
  const [filter, setFilter] = filterData;
  const [toggle, setToggle] = useState(false);
  const showDownload = _.isFunction(onDownload);
  const showClear = _.isFunction(onClear);

  const handleOnToggle = (value) => (e) => {
    e.preventDefault();
    setToggle(value);
  };

  const handleOnSearch = (e) => {
    e.preventDefault();
    actions();
  };

  return (
    <form onSubmit={handleOnSearch} className={ssv("tw-mb-2", containerClass)}>
      <div className="tw-grid tw-grid-cols-3 tw-gap-4 tw-items-center">
        <div className="tw-col-span-2 tw-grid tw-grid-cols-3 tw-gap-2 tw-items-center">
          <div className="tw-col-end-1">
            <button
              type="button"
              className={ssv(
                "tw-uppercase btn",
                toggle
                  ? "btn-success text-white"
                  : "btn-white text-success border border-success",
              )}
              onClick={handleOnToggle(!toggle)}
            >
              Advance Filter
              <i
                className={ssv(
                  "ml-2",
                  "fas",
                  toggle ? "fa-angle-up" : "fa-angle-down",
                )}
              />
            </button>
          </div>
          <div className="tw-col-span-2">
            <div className="tw-relative text-success tw-w-full">
              <span className="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-2">
                {loading ? (
                  <div
                    className="spinner-border !tw-w-6 !tw-h-6 font-weight-normal tw-border-2"
                    role="status"
                  />
                ) : (
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    className="tw-w-6 tw-h-6"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                )}
              </span>
              <input
                type="text"
                name="q"
                className="border border-success tw-text-gray-500 tw-py-2 tw-w-full tw-rounded-md tw-pl-10 focus:tw-outline-none"
                placeholder="Search..."
                autoComplete="off"
                value={filter?.q}
                onChange={({ target }) => {
                  setFilter((state) => ({ ...state, q: target.value }));
                }}
              />
            </div>
          </div>
          <div className="tw-col-end-4">
            <div className="tw-flex">
              <button
                type="submit"
                className="btn btn-success text-uppercase"
                disabled={loading}
              >
                SEARCH
              </button>
              {showClear && (
                <button
                  className="btn btn-gray text-uppercase tw-ml-1"
                  onClick={onClear}
                >
                  CLEAR
                </button>
              )}
            </div>
          </div>
        </div>
        {showDownload && (
          <div className="tw-col-auto tw-justify-self-end">
            <button className="btn btn-success" onClick={onDownload}>
              <i className="fas fa-download mr-2" /> DOWNLOAD
            </button>
          </div>
        )}
      </div>
      {toggle && form && (
        <div className="tw-flex tw-flex-wrap mt-2">
          <AdvancedFilter form={form} state={filterData} />
        </div>
      )}
    </form>
  );
}

Filter.defaultProps = {
  onDownload: null,
  onClear: null,
  loading: false,
  containerClass: "",
};

Filter.propTypes = {
  /**
   Function to run on click of search button
  */
  actions: PropTypes.instanceOf(Function).isRequired,
  /**
   Array of filter components to render
  */
  form: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["select-static", "date", "text", "select-async"])
        .isRequired,
      className: PropTypes.string,
      api: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
  ).isRequired,
  /**
   Filter state object (must contain `q`)<br>
   <b>ex: filterData = { [ payload, setPayload ] } </b>
  */
  filterData: PropTypes.instanceOf(Array).isRequired,
  /**
   Function to clear filter & search state.
   Clear button will automatically render if given value of type function.
  */
  onClear: PropTypes.oneOfType([
    PropTypes.instanceOf(Function),
    PropTypes.number,
  ]),
  /**
   Function to run on click of download button.
   Download button will automatically render if given value of type function.
  */
  onDownload: PropTypes.oneOfType([
    PropTypes.instanceOf(Function),
    PropTypes.number,
  ]),
  /**
   This will disable the search button if true.
  */
  loading: PropTypes.bool,
  /**
   Custom style for search filter container
  */
  containerClass: PropTypes.string,
};

export function FilterSlide({ filterData, form, loading }) {
  const [_, setFilter] = filterData;
  const [collapse, setCollapse] = useState(false);
  const [value, setValue] = useState("");
  const debouncedValue = useDebounce(value, 700);

  const handleOnToggle = (value) => (e) => {
    e.preventDefault();
    setCollapse(value);
  };

  useEffect(() => {
    if (!setFilter || typeof setFilter !== "function") return;
    setFilter((state) => ({ ...state, q: value }));
  }, [debouncedValue]);

  return (
    <form className="tw-mb-2">
      <div className="tw-flex tw-flex-wrap tw-items-center">
        <div className="tw-mr-3">
          <div className="tw-relative text-success tw-w-full">
            <span className="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-2">
              {loading ? (
                <div
                  className="spinner-border !tw-w-6 !tw-h-6 font-weight-normal tw-border-2"
                  role="status"
                />
              ) : (
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  className="tw-w-6 tw-h-6"
                >
                  <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              )}
            </span>
            <input
              type="text"
              name="q"
              className="border border-success tw-text-gray-500 tw-py-2 tw-w-full tw-rounded-md tw-pl-10 focus:tw-outline-none"
              placeholder="Search..."
              autoComplete="off"
              value={value}
              onChange={({ target }) => setValue(target.value)}
            />
          </div>
        </div>
        <div className="tw-mr-3">
          <button
            type="button"
            className={ssv(
              "tw-uppercase btn",
              collapse
                ? "btn-success text-white"
                : "btn-white text-success border border-success",
            )}
            onClick={handleOnToggle(!collapse)}
          >
            Filter
            <i
              className={ssv(
                "ml-2",
                "fas",
                collapse ? "fa-angle-left" : "fa-angle-right",
              )}
            />
          </button>
        </div>
        {form && (
          <div
            style={{
              visibility: collapse ? "visible" : "hidden",
              opacity: collapse ? "1" : "0",
              transition: "visibility .7s, opacity .7s linear",
            }}
            className="tw-self-start tw-flex-grow tw-flex tw-flex-wrap"
          >
            <AdvancedFilter form={form} state={filterData} />
          </div>
        )}
      </div>
    </form>
  );
}

FilterSlide.defaultProps = {
  loading: false,
};

FilterSlide.propTypes = {
  /**
   Array of filter components to render
  */
  form: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["select-static", "date", "text", "select-async"])
        .isRequired,
      className: PropTypes.string,
      api: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
  ).isRequired,
  /**
   Filter state object (must contain `q`)<br>
   <b>ex: filterData = { [ payload, setPayload ] } </b>
  */
  filterData: PropTypes.instanceOf(Array).isRequired,
  /**
   This will disable the search button if true.
  */
  loading: PropTypes.bool,
};

export function CollapseFilter({ filterData, form, loading }) {
  const [_, setFilter] = filterData;
  const [collapse, setCollapse] = useState(false);
  const [value, setValue] = useState("");
  const debouncedValue = useDebounce(value, 700);

  const handleOnToggle = (value) => (e) => {
    e.preventDefault();
    setCollapse(value);
  };

  useEffect(() => {
    if (!setFilter || typeof setFilter !== "function") return;
    setFilter((state) => ({ ...state, q: value }));
  }, [debouncedValue]);

  return (
    <form className="tw-mb-2">
      <div className="tw-flex tw-flex-wrap tw-items-center">
        <div className="tw-mr-3 tw-flex-grow">
          <div className="tw-relative text-success tw-w-full">
            <span className="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-2">
              {loading ? (
                <div
                  className="spinner-border !tw-w-6 !tw-h-6 font-weight-normal tw-border-2"
                  role="status"
                />
              ) : (
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  className="tw-w-6 tw-h-6"
                >
                  <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              )}
            </span>
            <input
              type="text"
              name="q"
              className="border border-success tw-text-gray-500 tw-py-2 tw-w-full tw-rounded-md tw-pl-10 focus:tw-outline-none"
              placeholder="Search..."
              autoComplete="off"
              value={value}
              onChange={({ target }) => setValue(target.value)}
            />
          </div>
        </div>
        <div>
          <button
            type="button"
            className={ssv(
              "tw-uppercase btn",
              collapse
                ? "btn-success text-white"
                : "btn-white text-success border border-success",
            )}
            onClick={handleOnToggle(!collapse)}
          >
            <i
              className={ssv(
                "fas fa-filter",
                collapse ? "text-white" : "text-success",
              )}
            />
          </button>
        </div>
      </div>
      {form && (
        <div
          style={{
            transition: "height 1s",
            height: `${!collapse ? "0" : `${form.length * 6}rem`}`,
            overflow: collapse ? "unset" : "hidden",
            visibility: "0",
          }}
          className="tw-flex tw-flex-wrap tw-mt-3"
        >
          <AdvancedFilter form={form} state={filterData} />
        </div>
      )}
    </form>
  );
}

CollapseFilter.defaultProps = {
  loading: false,
};

CollapseFilter.propTypes = {
  /**
   Array of filter components to render
  */
  form: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["select-static", "date", "text", "select-async"])
        .isRequired,
      className: PropTypes.string,
      api: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
  ).isRequired,
  /**
   Filter state object (must contain `q`)<br>
   <b>ex: filterData = { [ payload, setPayload ] } </b>
  */
  filterData: PropTypes.instanceOf(Array).isRequired,
  /**
   This will disable the search button if true.
  */
  loading: PropTypes.bool,
};

export default React.memo(Filter);
