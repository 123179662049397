import { ModalContext } from "App";
import _ from "lodash";
import DataField from "components/DataField";
import Panel from "components/Panel";
import PanelButton from "components/PanelButton";
import PanelRow from "components/PanelRow";
import React, { Fragment, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { renderBranchStatus } from "modules/ui/hooks";
import { downloadBranchHistory } from "modules/ManageAccounts/action";
import { checkPermissions } from "helper";
import { getBranchById } from "../actions";
import DeleteBranch from "../modals/DeleteBranch";

const BranchDetailsPanel = () => {
  const urlParams = useParams();
  const [loadingHistory, setLoadingHistory] = useState(false);
  const setModal = useContext(ModalContext);

  const branchId = _.get(urlParams, "branchId");
  const apiBranch = useQuery({
    queryKey: ["branch", branchId],
    queryFn: getBranchById,
    retry: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const branch = _.get(apiBranch, "data.data");

  const handleBlock = (label, value, branchName, returnLabel) => (e) => {
    e.preventDefault();
    const payload = {
      is_blocked: value,
    };
    setModal("branch-update-status", {
      data: {
        label,
        value,
        branchName,
        payload,
        returnLabel,
        branchId,
        onSuccess: apiBranch?.refetch,
      },
    });
  };

  const handleOnUpdate = () =>
    setModal("branch-setup", { modalTitle: "Update Branch" });

  const handleDelete = () =>
    setModal("delete-branch", { modalTitle: "Delete Branch", branchId });

  const handleHistory = () => {
    downloadBranchHistory(branchId, {}, setLoadingHistory);
  };

  return (
    <Fragment>
      <Panel
        title="Details"
        renderActions={() => (
          <div className="btn-group divide-x divide-gray-400">
            <PanelButton
              label="History"
              onClick={handleHistory}
              className="bg-warning text-white btn text-uppercase rounded-0 outline-none d-flex align-items-center px-3"
              disabled={loadingHistory}
            />
            {checkPermissions(["can-delete-branch"]) && (
              <PanelButton
                label="Delete"
                onClick={handleDelete}
                className="bg-gray-700 text-white btn text-uppercase rounded-0 outline-none d-flex align-items-center px-3"
              />
            )}
            {!checkPermissions(["can-update-branch"]) ? null : branch
                ?.attributes?.is_blocked ? (
              <PanelButton
                label="Unblock"
                onClick={handleBlock(
                  "UNBLOCK",
                  false,
                  branch?.attributes?.name,
                  "Unblocked Branch",
                )}
                className="tw-border-x-2 bg-gray-700 text-white btn text-uppercase rounded-0 outline-none d-flex align-items-center px-3"
              />
            ) : (
              <PanelButton
                label="Block"
                onClick={handleBlock(
                  "BLOCK",
                  true,
                  branch?.attributes?.name,
                  "Blocked Branch",
                )}
                className="tw-border-x-2 bg-danger text-white btn text-uppercase rounded-0 outline-none d-flex align-items-center px-3"
              />
            )}
            {checkPermissions(["can-update-branch"]) && (
              <PanelButton label="Update" onClick={handleOnUpdate} />
            )}
          </div>
        )}
      >
        <PanelRow appendClass="tw-grid tw-grid-cols-4 tw-gap-2">
          <DataField
            label="Branch Name:"
            value={_.get(branch, "attributes.name") || ""}
            className="col-md  mb-md-0 order-1"
          />
          <DataField
            label="Branch TIN:"
            value={_.get(branch, "attributes.tin") || ""}
            className="col-md  mb-md-0 order-1"
          />
          <DataField
            label="Branch Code:"
            value={_.get(branch, "attributes.code") || ""}
            className="col-md  mb-md-0 order-1"
          />
          <DataField
            label="Address:"
            value={_.get(branch, "attributes.address") || ""}
            className="col-md  mb-md-0 order-1"
          />
          <DataField
            label="Business Style:"
            value={_.get(branch, "attributes.business_style") || ""}
            className="col-md  mb-md-0 order-1"
          />
          <DataField
            label="Date Created:"
            value={_.get(branch, "attributes.created_at") || ""}
            className="col-md  mb-md-0 order-1"
          />
          <DataField
            label="Status:"
            value={
              renderBranchStatus[+_.get(branch, "attributes.is_blocked")] || ""
            }
            className="col-md  mb-md-0 order-1"
          />
        </PanelRow>
      </Panel>
      <DeleteBranch />
    </Fragment>
  );
};

export default BranchDetailsPanel;
