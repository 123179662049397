import React from 'react';
import PropTypes from 'prop-types';

function FormInput({
  name,
  onChange,
  value,
  label,
  containerClassName,
  labelClassName,
  withLabel,
  required,
  inputGroup,
  icon,
  type,
  onGetValue,
  rowKey,
  invisible,
  maxLength,
  ...props
}) {
  const handleChange = ({ target }) => {
    onChange(state => ({ ...state, [name]: target.value }));
    if (onGetValue) {
      onGetValue(target.value);
    }
  };

  const pattern =
    type === 'email'
      ? {
          pattern: "[a-z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-z0-9.-]+.[a-z]{2,}$",
          title: 'Please enter a valid email address in lowercase.',
        }
      : {};

  return (
    <div className={containerClassName}>
      {withLabel && (
        <label htmlFor={name} className={labelClassName}>
          {label} {required && <span className="text-danger">*</span>}
        </label>
      )}
      {!inputGroup ? (
        <input
          id={`${rowKey}-${name}`}
          type={type}
          className="form-control form-control filter-form"
          autoComplete="off"
          onChange={handleChange}
          value={value}
          required={required}
          style={invisible ? { opacity: 0, width: 0, display: 'inline' } : {}}
          maxLength={maxLength}
          {...pattern}
          {...props}
        />
      ) : (
        <div className="input-group">
          <div className="input-group-append">
            <div className="input-group-text">{icon}</div>
          </div>
          <input
            id={`${rowKey}-${name}`}
            type={type}
            autoComplete="off"
            className="form-control form-control-group filter-form"
            onChange={handleChange}
            value={value}
            required={required}
            style={invisible ? { opacity: 0, width: 0, display: 'inline' } : {}}
            {...pattern}
            {...props}
          />
        </div>
      )}
    </div>
  );
}

FormInput.defaultProps = {
  onChange: () => {},
  label: 'Name',
  containerClassName: 'form-group',
  labelClassName: 'form-label',
  withLabel: true,
  required: false,
  inputGroup: false,
  type: 'text',
  icon: <i className="fas fa-search" />,
  onGetValue: false,
  rowKey: '',
  invisible: false,
  maxLength: '50',
};

FormInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.instanceOf(Function),
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  type: PropTypes.string,
  withLabel: PropTypes.bool,
  icon: PropTypes.element,
  required: PropTypes.bool,
  inputGroup: PropTypes.bool,
  onGetValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Function),
  ]),
  rowKey: PropTypes.string,
  invisible: PropTypes.bool,
  maxLength: PropTypes.string,
};

export default React.memo(FormInput);
