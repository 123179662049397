import * as req from "api/actions";
import * as c from "./constants";

export const checkSession = (credentials) => ({
  type: c.AUTH_SESSION,
  credentials,
});

export const loginOTP = (credentials) => ({
  type: c.AUTH_OTP,
  credentials,
});

export const loginVerify = (credentials) => ({
  type: c.AUTH_VERIFY,
  credentials,
});

export const verifyOtp = (credentials) => ({
  type: c.OTP_VERIFY,
  credentials,
});
export const updateToken = (callback) => ({ type: c.UPDATE_ACCESS_TOKEN, callback });
export const logout = (callback) => ({ type: c.LOGOUT, callback });

export const getPinStatus = (payload, callback) =>
  req.item(c.PIN_STATUS, "api/v1/external/users/my/profile", payload, callback);

export const getMyProfile = () =>
  req.item(c.MY_PROFILE, "api/v1/external/users/my/profile");

export const refreshToken = (payload, callback, errCallback) =>
req.create(
  c.REFRESH_TOKEN,
  "/api/v2/auth/refresh",
  payload,
  callback,
  {},
  errCallback,
);
//inital refresh token for payload 
// "refresh_token" : "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIiLCJpYXQiOjE2NjI1MzMzMTAsImV4cCI6MTY2Mjc0OTMxMCwidWlkIjoiOWVjYjNjZDItMTlmYS00Mzg1LWE1ZDAtY2U2OWRhNGU5NjA0IiwidXNlcl9pZCI6IjQ2N2U4NDc2LTI2N2UtNDRkOC04Mjg1LWRhZDI4NzljYWExMCIsInR5cGUiOiJkZWFsZXJzIiwicHJvZmlsZSI6eyJpZCI6IjllY2IzY2QyLTE5ZmEtNDM4NS1hNWQwLWNlNjlkYTRlOTYwNCIsImNoYW5uZWxfaWQiOiIxIiwiYWNjb3VudF90eXBlX2lkIjoiMjI0IiwicG9zaXRpb24iOm51bGwsImFjY291bnRfc3RhdHVzIjoib24tYm9hcmRlZCIsImJ1c2luZXNzX21vZGVsX2lkIjoiODJmZTNmMjYtMTQxNy00ZjBmLTg3OTItZmViOTM4NWFjMWZiIiwiYXJlYV9pZCI6IjM3NGUzNGUwLTk3NGMtNGVlNy05NTViLTBlMWQ2NDA3ZmNiZCIsImR1cmF0aW9uX2Zyb20iOiIyMDIxLTA3LTA3IiwiZHVyYXRpb25fdG8iOm51bGwsImlzX2NoYW5nZWRfcGFzc3dvcmQiOmZhbHNlLCJpc19maXJzdF90aW1lIjpmYWxzZSwibWFuYWdlbWVudF9hY2NvdW50X3N0ZXAiOjUsInNtYXJ0X21vbmV5X251bWJlciI6bnVsbCwiY3JlZGl0X2xpbWl0IjpudWxsLCJ0cmFuc2FjdGlvbl9saW1pdCI6bnVsbCwicXVvdGEiOm51bGwsInF1b3RhX2R1cmF0aW9uIjpudWxsLCJpc19hY3RpdmUiOnRydWUsInByb2ZpbGVfaW1nIjoiV1NIZVNLbFVaWk5TeVFIR21Ba1hndlluM0o4MFdjeUZtcHpiRWdRUi5qcGciLCJwYXJlbnRfaWQiOm51bGwsImNyZWF0ZWRfYXQiOiIyMDIxLTA3LTA3VDA5OjExOjA3LjAwMDAwMFoiLCJ1cGRhdGVkX2F0IjoiMjAyMi0wOC0xOVQxNjoyNzo0My4wMDAwMDBaIiwiaXNfZXhwcmVzcyI6dHJ1ZSwiaXNfcGluX2VuYWJsZWQiOmZhbHNlLCJpc19leGNsdXNpdmUiOnRydWUsInBheW1lbnRfbG9jYXRpb25faWQiOiJhYjZlZDgxYy1lOGE3LTQ0ZWMtYWFjYi0yZTIxOTY0ZmUxYzIiLCJ1cGRhdGVkX2J5IjoiTWEuIEFuZ2VsaWNhIFBhbG9tYW5pYSIsIm9yZ2FuaXphdGlvbl9pZCI6ImIxMWQxZGU2LTU2ZGEtNGI2OC1iNzlhLTU2OGFhMmM1MTA3OSIsImlzX25vbl9taWdyYXRlZCI6ZmFsc2UsInRyYWRlX25hbWUiOm51bGx9LCJyb2xlcyI6W119.nhET3w3qXO1QPtTdMYCN9xy1bU5_VtZMTOAa-X_mdz0"
// "is_for_testing" : true