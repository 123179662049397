import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import withModal from 'modules/common/hoc/withModal';

const ViewDocument = () => (
  <div>
    <figure className="w-100">
      <img
        src={useSelector(({ modal }) => _.get(modal, 'modalData.image'))}
        alt="View Document"
        className="w-100"
      />
    </figure>
  </div>
);

const modalKey = 'view-document';
const modalConfig = {
  title: '',
  size: 'modal-xs',
};

export default withModal(modalKey, modalConfig)(ViewDocument);
