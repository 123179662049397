import * as req from 'api/actions';
import * as c from './constants';

export const showUserCompanyInfo = dealer_id =>
  req.item(
    c.SHOW_USER_COMPANY_INFO,
    `dealer/api/v1/dealers/${dealer_id}/companies`
  );

export default {};
