import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactInputMask from 'react-input-mask';
import { ModalContext } from 'App';
import withModal from 'modules/common/hoc/withModal';
import ButtonAsync from 'components/Button';
import WithTimerButton from 'components/WithTimerButton';
import { ToastSuccess } from 'modules/common/components/Toast';
import * as actions from '../actions';
import * as c from '../constant';

function ChangeTopOrgOTP() {
  const dispatch = useDispatch();
  const initialData = useSelector(({ modal }) => modal?.modalData);
  const [otpValue, setOtpValue] = useState('');
  const setModal = React.useContext(ModalContext);
  const [pending, setPending] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);

    const createPayload = {
      ...initialData,
      mobile_identification_number: initialData?.mobile_identification_number,
      transfer_assigned_to: initialData?.transfer_assigned_to,
      otp: otpValue,
    };

    const onAddSuccess = () => {
      ToastSuccess(c.MESSAGES_SIM_ACTION.update);
      setModal({ isOpen: false });
      setPending(false);
      dispatch(
        actions.getDealerSims({
          ...c.sortFilter,
          userType: 'dealers',
          page: 1,
          per_page: 10,
        })
      );
    };

    const onAddError = () => {
      setPending(false);
      setOtpValue('');
    };

    const onConfirmSuccess = () => {
      return dispatch(
        actions.createDealerSims(createPayload, onAddSuccess, onAddError)
      );
    };

    const onConfirmError = () => setPending(false);

    const onConfirmPayload = {
      mobile: initialData?.transfer_to,
      purpose: c.PURPOSE.portal,
      otp: otpValue,
    };

    dispatch(
      actions.confirmOtp(onConfirmPayload, onConfirmSuccess, onConfirmError)
    );
  };

  const isInputEmpty = useMemo(() => otpValue?.length === 6, [otpValue]);

  const handleResend = e => {
    e.preventDefault();
    const payload = {
      mobile: initialData?.transfer_to,
      purpose: c.PURPOSE.portal,
    };
    dispatch(actions.setOtp(payload));
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="prepend-form-right">
            <label className="form-label text-primary text-center">
              Enter the one-time-password we have sent to your mobile number
              +63********{initialData?.sms?.detail?.mobile_end}
            </label>
            <ReactInputMask
              autoFocus
              type="text"
              placeholder="6 Digit OTP"
              mask="999999"
              maskChar=""
              className="form-control form-custom form-control_bottom"
              name="otp"
              required
              onChange={e => setOtpValue(e.target.value)}
              value={otpValue || ''}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <WithTimerButton
              type="button"
              duration={0.5}
              handleClick={handleResend}
            >
              Resend OTP
            </WithTimerButton>
            <ButtonAsync
              type="submit"
              className="btn btn-primary position-relative ml-1"
              loading={pending}
              disabled={!isInputEmpty}
            >
              Confirm OTP
            </ButtonAsync>
          </div>
        </div>
      </div>
    </form>
  );
}

const modalKey = 'change-top-org-confirm-otp';
const modalConfig = {
  title: 'Enter your 6-Digit OTP',
  size: 'modal-md',
};

export default withModal(modalKey, modalConfig)(ChangeTopOrgOTP);
