import React, { useState, useRef, forwardRef, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { ToastWarn } from "modules/common/components/Toast";
import { IconCalendar } from "components/Icons";

const formatDate = (date, def = "", format = "YYYY-MM-DD") => {
  try {
    const endDate = moment(date, "YYYY-MM-DD HH:mm").format(format);

    if (_.isEmpty(date) && date === "") {
      return endDate === "";
    }

    if (endDate === "Invalid date") {
      ToastWarn("Entered date is invalid");
    }

    const newDate = endDate === "Invalid date" ? "" : endDate;

    return newDate;
  } catch (error) {
    return def;
  }
};

const CustomRender = forwardRef(({ onClick }, ref) => (
  <button
    type="button"
    ref={ref}
    className="btn btn-sm btn-icon no-outline-btn"
    onClick={onClick}
  >
    <IconCalendar />
  </button>
));

CustomRender.propTypes = {
  onClick: PropTypes.instanceOf(Function),
};

CustomRender.defaultProps = {
  onClick: () => {},
};

const renderDateFormat = (showMonthYearPicker, showYearPicker, withTime) => {
  if (showMonthYearPicker) {
    return "YYYY-MM";
  }
  if (showYearPicker) {
    return "YYYY";
  }
  if (withTime) {
    return "YYYY-MM-DD HH:mm";
  }
  return "YYYY-MM-DD";
};

const inputMaskRenderFormat = (
  showMonthYearPicker,
  showYearPicker,
  withTime
) => {
  if (showMonthYearPicker) {
    return "9999-99";
  }
  if (showYearPicker) {
    return "9999";
  }
  if (withTime) {
    return "9999-99-99 99:99";
  }
  return "9999-99-99";
};

const renderPlaceHolder = (
  data,
  showMonthYearPicker,
  showYearPicker,
  withTime
) => {
  let placeHolder = "";
  if (data.length > 0) {
    return data;
  }

  placeHolder = () => {
    if (showMonthYearPicker) {
      return "YYYY-MM";
    }
    if (showYearPicker) {
      return "YYYY";
    }
    if (withTime) {
      return "YYYY-MM-DD HH:MM";
    }
    return "YYYY-MM-DD";
  };

  return placeHolder();
};

function FormInputDate({
  disabled,
  showMonthYearPicker,
  showYearPicker,
  withTime,
  required,
  placeholder,
  onChange,
  maxDate,
  minDate,
  value,
  name,
  label,
  openToDate,
  containerClassName,
  labelClassName,
  className,
  withLabel,
  onGetValue,
}) {
  const [selected, setSelected] = useState(null);
  const customRenderRef = useRef();

  const handleChangeInput = (e) => {
    setSelected(e.target.value);
  };

  const handleChangePicker = (e) => {
    const newVal = formatDate(
      e,
      "",
      renderDateFormat(showMonthYearPicker, showYearPicker, withTime)
    );
    onChange((state) => ({ ...state, [name]: newVal }));
    setSelected(newVal);
    if (onGetValue) {
      onGetValue(newVal);
    }
  };

  const handleUpdateActual = (e) => {
    const newVal = formatDate(
      e.target.value,
      selected,
      renderDateFormat(showMonthYearPicker, showYearPicker, withTime)
    );

    if (
      newVal < moment(maxDate).format("YYYY-MM-DD") &&
      newVal >= moment(minDate).format("YYYY-MM-DD")
    ) {
      setSelected(newVal);
      onChange((state) => ({ ...state, [name]: newVal }));
      return;
    }

    setSelected("");
    onChange((state) => ({ ...state, [name]: "" }));
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div className={containerClassName}>
      {withLabel && (
        <label htmlFor={name} className={labelClassName}>
          {label} {required && <span className="text-danger">*</span>}
        </label>
      )}
      <div className="input-group">
        <InputMask
          onChange={handleChangeInput}
          onBlur={handleUpdateActual}
          maskChar={null}
          autoComplete="off"
          disabled={disabled}
          required={required}
          placeholder={renderPlaceHolder(
            placeholder,
            showMonthYearPicker,
            showYearPicker,
            withTime
          )}
          mask={inputMaskRenderFormat(
            showMonthYearPicker,
            showYearPicker,
            withTime
          )}
          className={className}
          value={selected || ""}
        />
        <div className="input-group-append react-datepicker-outer">
          <span className="input-group-btn">
            <DatePicker
              customInput={<CustomRender ref={customRenderRef} />}
              disabled={disabled}
              showYearDropdown={!showYearPicker}
              showYearPicker={showYearPicker}
              timeIntervals={15}
              showMonthYearPicker={showMonthYearPicker}
              showTimeSelect={withTime}
              timeFormat="HH:mm"
              maxDate={maxDate}
              minDate={minDate}
              openToDate={!_.isEmpty(value) ? new Date(selected) : openToDate}
              popperPlacement="top-end"
              selected={new Date(value || openToDate)}
              onChange={handleChangePicker}
              dropdownMode="select"
            />
          </span>
        </div>
      </div>
    </div>
  );
}

FormInputDate.defaultProps = {
  disabled: false,
  showMonthYearPicker: false,
  showYearPicker: false,
  withTime: false,
  required: false,
  withLabel: true,
  placeholder: "",
  maxDate: new Date("3000-01-01"),
  minDate: new Date("1900-01-01"),
  openToDate: new Date(),
  label: "Date",
  value: "",
  containerClassName: "form-group",
  labelClassName: "form-label",
  className: "form-control form-control-date text-uppercase font-weight-bold",
  onGetValue: false,
};

FormInputDate.propTypes = {
  disabled: PropTypes.bool,
  showMonthYearPicker: PropTypes.bool,
  showYearPicker: PropTypes.bool,
  withTime: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  withLabel: PropTypes.bool,
  onChange: PropTypes.instanceOf(Function).isRequired,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  openToDate: PropTypes.instanceOf(Date),
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  onGetValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.instanceOf(Function),
  ]),
};

export default React.memo(FormInputDate);
