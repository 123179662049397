import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { ModalContext } from "App";
import ButtonAsync from "components/Button";
import { ToastSuccess } from "modules/common/components/Toast";
import withModal from "modules/common/hoc/withModal";
import { useDealer } from "app/load-wallet/context/DealerProvider";
import * as actions from "../actions";
import * as c from "../constant";

function RetailerSimPasswordConfirmation() {
  const dispatch = useDispatch();
  const messages = c.MESSAGES_SIM_ACTION;
  const setModal = useContext(ModalContext);
  const data = useSelector(({ modal }) => modal?.modalData);
  const [password, setPassword] = useState("");
  const [pending, setPending] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { simDealerId } = useDealer();

  const closeModal = () => {
    setModal({ isOpen: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);

    const createPayload = {
      ...data,
      mobile_identification_number: data?.mobile_identification_number,
      ..._.omit(data, ["tempSubmitType"]),
    };

    const onError = () => {
      setPassword("");
      setPending(false);
      closeModal();
    };

    const onCreateSuccess = () => {
      const submitType = data?.tempSubmitType || "default";
      ToastSuccess(messages[submitType]);

      setModal({ isOpen: false });
      setPending(false);
      dispatch(
        actions.retailerSimsGetRetailerSims({
          dealer_id: data?.attributes?.dealer_id || simDealerId,
          ...c.sortFilter,
        })
      );
    };

    const onConfirmSuccess = () => {
      const submitTypeValue = data?.tempSubmitType;
      const submitTypes = Object.keys(c.SUBMIT_TYPE);

      if (_.dropRight(submitTypes)?.includes(submitTypeValue)) {
        dispatch(
          actions.retailerSimsUpdateRetailerSims(
            data?.id,
            createPayload,
            onCreateSuccess,
            onError
          )
        );
      }
      if (submitTypes.pop()?.includes(submitTypeValue)) {
        dispatch(
          actions.deleteRetailerSims(data?.id, onCreateSuccess, onError)
        );
      }
    };

    const onSimValidate = () => {
      dispatch(
        actions.validateSim(
          {
            mobile: data?.mobile_identification_number,
          },
          onConfirmSuccess,
          onError
        )
      );
    };

    dispatch(actions.validatePassword({ password }, onSimValidate, onError));
  };

  const handleFieldVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="prepend-form-right">
            <h5 className="font-weight-bolder my-2">IMPORTANT!</h5>
            <p className="mb-4">Enter your password to authorize action</p>
            <div className="form-group">
              <div className="prepend-form-right">
                <label className="form-label mb-2">Password *</label>
                <div className="prepend-input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control input-textbox password with-pass-icon form-custom form-control_bottom "
                    required
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="off"
                  />
                </div>
                <button
                  type="button"
                  onClick={handleFieldVisibility}
                  className="btn btn-default rounded-circle p-0 mr-1 outline-none"
                >
                  <i
                    className={`fas ${
                      !showPassword ? "fa-eye" : "fa-eye-slash"
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-5 d-flex align-items-center justify-content-end">
            <ButtonAsync
              type="submit"
              className="btn btn-primary position-relative ml-1"
              loading={pending}
            >
              Confirm
            </ButtonAsync>
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

const modalKey = "retailer-sim-confirm-password";
const modalConfig = {
  title: "Password Confirmation",
  size: "modal-md",
};

export default withModal(
  modalKey,
  modalConfig
)(RetailerSimPasswordConfirmation);
