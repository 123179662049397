import _ from "lodash";
import { service } from "api/service";

const transformResponse = (data) => _.get(JSON.parse(data), "data");

export const getLocations = ({ queryKey: [__, id, { params }] }) => {
  const url = `/api/v1/area/${id}`;
  return service.get(url, { params });
};

export const getNewLocations = () => {
  const url = `/relationship/api/v1/my/dealers/areas`;
  return service.get(url);
};

export const getMarketingLocationLevels = () => {
  const url = `/relationship/api/v1/marketing_location_levels`;
  return service.get(url);
};

export const getAgent = ({ queryKey: [__, agentId, params] }) => {
  const url = `/api/v1/dealer-users/${agentId}`;
  return service.get(url, { params, transformResponse });
};

export const getAgentRaw = ({ queryKey: [__, agentId, params] }) => {
  const url = `/api/v1/dealer-users/${agentId}`;
  return service.get(url, { params });
};

export const setAgent = ({ agentId, ...data }) => {
  const url = `/api/v1/dealer-users/${agentId}`;
  return service.put(url, data);
};

export const getRetailers = ({ queryKey: [__, params] }) => {
  const url = `/external/api/v1/retailers`;
  return service.get(url, { params, transformResponse });
};

export const getAgentRetailers = ({ queryKey: [__, agentId, params] }) => {
  const url = `/external/api/v1/dealer-sims/${agentId}/retailers`;
  return service.get(url, { params, transformResponse });
};

export const setAgentRetailers = ({ simId, retailerId }) => {
  const url = `/external/api/v1/dealer-sims/${simId}/retailers/${retailerId}`;
  return service.post(url);
};

export const deleteAgentRetailer = ({ simId, retailerId }) => {
  const url = `/external/api/v1/dealer-sims/${simId}/retailers/${retailerId}`;
  return service.delete(url);
};

export const transferAgentRetailers = ({ simId, ...data }) => {
  const url = `/external/api/v1/dealer-sims/${simId}/transfer/retailers`;
  return service.post(url, data);
};

export const getAgentMins = ({ queryKey: [__, { agentId, ...params }] }) => {
  const url = `/dealer/api/v1/dealer-users/${agentId}/dealer-sims`;
  return service.get(url, { params, transformResponse });
};

export const setAgentMins = ({ agentId, ...data }) => {
  const url = `/dealer/api/v1/dealer-users/${agentId}/dealer-sims`;
  return service.post(url, data);
};

export const deleteAgentMin = ({ agentId }) => {
  const url = `/dealer/api/v1/dealer-users/${agentId}/dealer-sims`;
  return service.delete(url);
};
