import React from "react";
import { Redirect, Switch, Route, useRouteMatch } from "react-router-dom";
import Eload from "./eload-api-gw";

function LoadWalletContainer() {
  const match = useRouteMatch();

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.url}`}
          render={() => <Redirect to={`${match.url}/eload-api-gw/D2R`} />}
        />
        <Route path={`${match.path}/eload-api-gw`} component={Eload} />
      </Switch>
    </>
  );
}

export default LoadWalletContainer;
