import React, { Fragment, useContext } from 'react';
import GenericTable from 'modules/common/components/GenericTable';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalContext } from 'App';
import moment from 'moment';

const CartDetails = ({
  data,
  onRemove,
  total,
  loading,
  type,
  profile,
  onPlaceOrder,
}) => {
  const setModal = useContext(ModalContext);
  const renderButton = args => (
    <Fragment>
      {/* <button
        type="button"
        className="btn btn-primary btn-sm mr-2"
        onClick={onUpdate('update', args)}
      >
        <i className="fas fa-pencil-alt" />
      </button> */}
      <button
        type="button"
        className="btn btn-danger btn-sm"
        onClick={onRemove('remove', args)}
      >
        <i className="fas fa-trash-alt" />
      </button>
    </Fragment>
  );

  const handleCloseCart = e => {
    e.preventDefault();
    setModal({ isOpen: false });
  };
  return (
    <Fragment>
      {type === 'order' && (
        <div className="row">
          <div className="col p-0">
            <div className="form-group px-2">
              <label className="form-label">Dealer Name:</label>
              <input
                className="form-control input-textbox form-custom form-control_bottom"
                type="text"
                value={profile.full_name || '--'}
                readOnly
              />
            </div>
          </div>
          <div className="col p-0">
            <div className="form-group px-2">
              <label className="form-label">Area:</label>
              <input
                className="form-control input-textbox form-custom form-control_bottom"
                type="text"
                value={profile.area_name || '--'}
                readOnly
              />
            </div>
          </div>
          <div className="col p-0">
            <div className="form-group px-2">
              <label className="form-label">Trasaction Date:</label>
              <input
                className="form-control input-textbox form-custom form-control_bottom"
                type="text"
                value={moment().format('LL')}
                readOnly
              />
            </div>
          </div>
        </div>
      )}
      <GenericTable
        isLoading={loading}
        format={[
          {
            key: item => <span>{_.get(item, 'attributes.name') || '--'}</span>,
            label: 'Items',
            width: 'auto',
          },
          {
            key: item => (
              <span>{_.get(item, 'attributes.quantity') || '--'}</span>
            ),
            label: 'Quantity',
            width: 'auto',
          },
          {
            key: item => <span>{_.get(item, 'attributes.price') || '--'}</span>,
            label: 'Sub-Total',
            width: 'auto',
          },
          {
            key: item => renderButton(item),
            label: '',
            width: 'auto',
          },
        ]}
        data={data}
        height="20vh"
      />
      <div className="row">
        <div className="ml-auto">
          <h4 className="font-weight-bold m-2">TOTAL</h4>
        </div>
        <h4 className="font-weight-bold m-2 text-success ml-4">{total}</h4>
      </div>
      <div className="row py-3">
        <button
          type="button"
          className="btn btn-default ml-auto"
          onClick={handleCloseCart}
        >
          <i className="fas fa-chevron-left mr-2" /> Back to Products
        </button>
        <button
          type="button"
          className="btn btn-success ml-3"
          onClick={onPlaceOrder}
        >
          Proceed to Order
        </button>
      </div>
    </Fragment>
  );
};

CartDetails.defaultProps = {
  profile: {},
  type: '',
};

CartDetails.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  profile: PropTypes.instanceOf(Object),
  onRemove: PropTypes.func.isRequired,
  onPlaceOrder: PropTypes.func.isRequired,
  total: PropTypes.string.isRequired,
  type: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

export default CartDetails;
