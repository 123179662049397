import React from 'react';
import { ModalContext } from 'App';
import TicketInformationModal from './TicketInformationModal';

const AccountInquiries = () => {
  const setModal = React.useContext(ModalContext);
  const [formData, setFormData] = React.useState({});

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    // search feature here
  };

  const handleOpenTicketInformation = () => {
    setModal('ticket-information');
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="row m-0 mb-3">
        <div className="col-12 col-md-3 pl-0">
          <div className="prepend-form-right mb-2">
            <label htmlFor="search" className="sr-only">
              Search Account
            </label>
            <input
              type="text"
              id="search"
              name="search"
              className="form-control"
              placeholder="Search..."
              value={formData.name}
              onChange={e => handleChange(e)}
            />
            <button
              type="submit"
              className="btn btn-default rounded-circle px-1 py-0 mr-1"
            >
              <i className="fa fa-search" />
            </button>
          </div>
        </div>
        <div className="col-12 col-md-3 pl-0">
          <label htmlFor="status" className="sr-only">
            Status
          </label>
          <select
            name="status"
            id="status"
            className="custom-select"
            value={formData.status}
            onChange={e => handleChange(e)}
          >
            <option defaultValue>All Status</option>
            <option value="type-1">Account Type 1</option>
            <option value="type-2">Account Type 2</option>
            <option value="type-3">Account Type 3</option>
          </select>
        </div>
      </form>
      <div className="table-responsive">
        <table className="table table-bordered table-interactive m-0">
          <thead className="table-header bg-gray">
            <tr>
              <td>Ticket</td>
              <td>Subject</td>
              <td>Reporter</td>
              <td>Dealer Name</td>
              <td>Assignee</td>
              <td>Status</td>
              <td>Created</td>
              <td>Resolution</td>
            </tr>
          </thead>
          <tbody>
            <tr onClick={handleOpenTicketInformation}>
              <td>123123123</td>
              <td>Sample Subject</td>
              <td>Mary Anne</td>
              <td>Regional</td>
              <td>Ivan Dianga</td>
              <td>Open</td>
              <td>Nov 21</td>
              <td>-----</td>
            </tr>

            <tr>
              <td>123123123</td>
              <td>Sample Subject</td>
              <td>Mary Anne</td>
              <td>Regional</td>
              <td>Ivan Dianga</td>
              <td>Open</td>
              <td>Nov 21</td>
              <td>-----</td>
            </tr>

            <tr>
              <td>123123123</td>
              <td>Sample Subject</td>
              <td>Mary Anne</td>
              <td>Regional</td>
              <td>Ivan Dianga</td>
              <td>Open</td>
              <td>Nov 21</td>
              <td>-----</td>
            </tr>
            <tr>
              <td>123123123</td>
              <td>Sample Subject</td>
              <td>Mary Anne</td>
              <td>Regional</td>
              <td>Ivan Dianga</td>
              <td>Open</td>
              <td>Nov 21</td>
              <td>-----</td>
            </tr>
            <tr>
              <td>123123123</td>
              <td>Sample Subject</td>
              <td>Mary Anne</td>
              <td>Regional</td>
              <td>Ivan Dianga</td>
              <td>Open</td>
              <td>Nov 21</td>
              <td>-----</td>
            </tr>
            <tr>
              <td>123123123</td>
              <td>Sample Subject</td>
              <td>Mary Anne</td>
              <td>Regional</td>
              <td>Ivan Dianga</td>
              <td>Open</td>
              <td>Nov 21</td>
              <td>-----</td>
            </tr>
            <tr>
              <td>123123123</td>
              <td>Sample Subject</td>
              <td>Mary Anne</td>
              <td>Regional</td>
              <td>Ivan Dianga</td>
              <td>Open</td>
              <td>Nov 21</td>
              <td>-----</td>
            </tr>
          </tbody>
        </table>
      </div>

      <TicketInformationModal />
    </>
  );
};
export default AccountInquiries;
