import React, { useContext, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { ModalContext } from 'App';
import { useLoading } from 'modules/ui/hooks';
import UpdateMoaSignatoryInformation from '../../../modal/UpdateMoaSignatoryInformation';

import * as actions from '../../../actions';
import * as c from '../../../constants';

const MoaSignatoryInformation = () => {
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);

  const dealer_id = useSelector(({ api }) =>
    _.get(api, 'AUTH/user_profile.item.id')
  );
  const companyInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_COMPANY_INFORMATION}.item`, {})
  );
  const moaInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_MOA_SIGNATORY_INFORMATION}.item`, [])
  );

  const company_id = _.get(companyInfo, 'id', '');
  const isLoading = useLoading(c.SHOW_MOA_SIGNATORY_INFORMATION);

  const transformObjectToArrayOfObjects = useMemo(
    () =>
      Object.keys(moaInfo)
        .map(item => moaInfo[item])
        .filter(item => !_.isEmpty(item)),
    [moaInfo]
  );

  const signatoryInformation = useMemo(
    () =>
      transformObjectToArrayOfObjects.filter(
        ({ attributes }) => attributes._type === 'MEMORANDUM_SIGNATORY'
      ),
    [transformObjectToArrayOfObjects]
  );

  const otherSignatories = useMemo(
    () =>
      transformObjectToArrayOfObjects.filter(
        ({ attributes }) => attributes._type === 'OTHER_SIGNATORY'
      ),
    [transformObjectToArrayOfObjects]
  );

  const openUpdateModal = () =>
    setModal('moa-signatory-information-update', {
      dealer_id,
      company_id,
      signatoryInformation,
      otherSignatories,
    });

  useEffect(() => {
    if (company_id)
      dispatch(actions.showMoaSignatoryInformation(dealer_id, company_id));
    else {
      dispatch(actions.removeMoaInfo());
      dispatch(actions.removeMoaInfo());
    }
    return () => dispatch(actions.removeMoaInfo());
  }, [company_id, dealer_id]);

  return (
    <>
      <div className="MoaSignatoryInformation mb-4">
        <div className="MoaSignatoryInformation-container">
          <header className="rounded-top-both bg-grey-800 overflow-hidden text-white  d-flex justify-content-between pl-3">
            <div className="header-title my-auto">
              <h6 className="h6 text-xs font-weight-bold p-0 m-0">
                Memorandum of Agreement Signatory Information
              </h6>
            </div>
            <div className="invisible">
              <button
                type="button"
                className="btn text-xs btn-success rounded-0 py-2"
                onClick={openUpdateModal}
                disabled={isLoading}
              >
                <i className="fas fa-pen mr-2" />
                Update
              </button>
            </div>
          </header>
          <div>
            <div className="p-3">
              <h6 className="h6 text-xs text-grey-1000 font-weight-bold p-0 m-0">
                Signatory Information
              </h6>
            </div>
            <div className="p-4 bg-white">
              <div className="row">
                <div className="col-2 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    First Name
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(signatoryInformation, '0.attributes.first_name') ||
                      '--'}
                  </p>
                </div>
                <div className="col-2 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    Middle Initial
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(signatoryInformation, '0.attributes.middle_name') ||
                      '--'}
                  </p>
                </div>
                <div className="col-2 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">Last Name</span>
                  <p className="description mb-0 break-word">
                    {_.get(signatoryInformation, '0.attributes.last_name') ||
                      '--'}
                  </p>
                </div>
                <div className="col-2 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    Contact No.
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(
                      signatoryInformation,
                      '0.attributes.contact_number'
                    ) || '--'}
                  </p>
                </div>
              </div>
            </div>
            {otherSignatories.length > 0 && (
              <div>
                <div className="p-3">
                  <h6 className="h6 text-xs text-grey-1000 font-weight-bold p-0 m-0">
                    Other Authorized Signatories
                  </h6>
                </div>
                <div className="p-4 bg-white">
                  {otherSignatories.map((item, index) => (
                    <div className="row mb-4" key={_.get(item, 'id')}>
                      <div className="col-2 d-flex align-items-center text-xs text-grey-1000">
                        <div
                          className="mr-3 rounded-circle bg-light-200 d-flex align-items-center justify-content-center"
                          style={{ minWidth: '1.3rem', minHeight: '1.3rem' }}
                        >
                          <span className="text-dark">{index + 1} </span>
                        </div>
                        <div>
                          <div className="label font-weight-bold mb-2">
                            Full Name
                          </div>
                          <p className="description mb-0 break-word">
                            {_.get(item, 'attributes.full_name')}
                          </p>
                        </div>
                      </div>
                      <div className="col-2 d-flex flex-column text-xs text-grey-1000">
                        <div className="label font-weight-bold mb-2">
                          Position
                        </div>
                        <p className="description mb-0 break-word">
                          {_.get(item, 'attributes.position')}
                        </p>
                      </div>
                      <div className="col-2 d-flex flex-column text-xs text-grey-1000">
                        <div className="label font-weight-bold mb-2">
                          Email Address
                        </div>
                        <p
                          className="description mb-0"
                          style={{
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                          }}
                        >
                          {_.get(item, 'attributes.email')}
                        </p>
                      </div>
                      <div className="col-2 d-flex flex-column text-xs text-grey-1000">
                        <div className="label font-weight-bold mb-2">
                          Contact No.
                        </div>
                        <p className="description mb-0 break-word">
                          {_.get(item, 'attributes.contact_number')}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <UpdateMoaSignatoryInformation />
    </>
  );
};

export default React.memo(MoaSignatoryInformation);
