import React, { useState } from 'react';
import { checkPermissions } from 'helper';
import LoadTransaction from 'modules/dashboard/components/LoadTransaction';
import BillsTransaction from 'modules/dashboard/components/BillsTransaction';

const TransactionHistory = () => {
  const [tab, setTab] = useState('load-transaction');
  return (
    <div className="transactions-history">
      <div className="card shadow-sm">
        <div className="card-header bg-primary">
          <p className="card-title m-0 text-white font-weight-bold">
            Transaction History:
          </p>
        </div>
        <div className="card-body px-0 pb-0">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <ul className="nav nav-tabs">
                  {checkPermissions(['can-view-transaction']) && (
                    <li className="nav-item">
                      <button
                        className={`outline-none tab-link smaller ${
                          tab === 'load-transaction' ? 'active' : ''
                        }`}
                        onClick={() => setTab('load-transaction')}
                        type="button"
                      >
                        <div className="ml-auto mr-auto">Load Transaction</div>
                      </button>
                    </li>
                  )}
                  {checkPermissions(['can-view-bills-transaction']) && (
                    <li className="nav-item">
                      <button
                        className={`outline-none tab-link smaller ${
                          tab === 'bills-transaction' ? 'active' : ''
                        }`}
                        onClick={() => setTab('bills-transaction')}
                        type="button"
                      >
                        <div className="ml-auto mr-auto">Bills Transaction</div>
                      </button>
                    </li>
                  )}
                </ul>
                <div>
                  {tab === 'load-transaction' ? (
                    <LoadTransaction />
                  ) : (
                    <BillsTransaction />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
