import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import withModal from 'modules/common/hoc/withModal';
import { connect } from 'react-redux';
import { useLoading } from 'modules/ui/hooks';
import { ToastError } from 'modules/common/components/Toast';
import * as actions from '../actions';
import * as c from '../constant';

const parseNumber = str => str.replace('+63', '0');

function ViewBalance({ data, inquireBalance, inquireRetailerBalance }) {
  const [balance, setBalance] = useState('');
  const balanceLoading = useLoading(c.BALANCE_INQUIRE);
  const newNumber = parseNumber(
    _.get(data, 'attributes.mobile_identification_number')
  );

  useEffect(() => {
    const payload = { mobile: newNumber };
    if (_.get(data, 'type') === c.DEALER_SIM_TYPE) {
      inquireBalance(payload, res => {
        const responseCode = _.get(res, 'data.response_code');

        if (responseCode !== c.VALID_BALANCE_RESPONSE_CODE_DEALER) {
          ToastError(
            `An error has occurred while processing this request. Response Code ${responseCode}`
          );
          setBalance(_.get(res, 'data.available_balance'));
        }

        setBalance(_.get(res, 'data.available_balance'));
      });
    } else {
      inquireRetailerBalance(payload, res => {
        const responseCode = _.get(res, 'data.response_code');
        if (responseCode !== c.VALID_BALANCE_RESPONSE_CODE_RETAILER) {
          ToastError(
            `An error has occurred while processing this request. Response Code ${res?.data?.response_code}`
          );
          setBalance(_.get(res, 'data.available_balance'));
        }

        setBalance(_.get(res, 'data.available_balance'));
      });
    }
  }, [newNumber, inquireBalance, data, inquireRetailerBalance]);

  const formattedBalance = useMemo(() => {
    return Number(balance).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }, [balance]);

  return (
    <div className="container-fluid">
      <div className="text-center font-weight-bold">Remaining Balance</div>
      <h4 className="m-1">
        <div className="text-center font-weight-bold text-success">
          {!balanceLoading ? (
            `₱ ${formattedBalance}`
          ) : (
            <small>Please wait...</small>
          )}
        </div>
      </h4>
    </div>
  );
}

ViewBalance.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  inquireBalance: PropTypes.func.isRequired,
  inquireRetailerBalance: PropTypes.func.isRequired,
};

const mapStateToProps = ({ modal }) => ({
  data: _.get(modal, 'modalData') || {},
});

const enhance = _.flowRight([
  withModal('balance', {
    title: 'Balance',
    size: 'modal-sm',
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(ViewBalance);
