import React from "react";
import PropTypes from "prop-types";
import emptyImg from "../../../assets/images/empty-states.svg";

function EmptyImgState({ message }) {
  return (
    <div>
      <div className="d-flex align-items-center justitfy-content-center h-100 mt-5">
        <div className="text-center mx-auto">
          <img
            src={emptyImg}
            className="image-wrapper mx-auto"
            alt="Empty State"
            style={{ height: 250, width: 250, marginTop: 35 }}
          />
          <p className="emp-title">{message}</p>
        </div>
      </div>
    </div>
  );
}

EmptyImgState.propTypes = {
  /**
   Messsage to display under image
  */
  message: PropTypes.string,
};

EmptyImgState.defaultProps = {
  message: "There are no items found.",
};

export default EmptyImgState;
