import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import Sales from 'modules/widgets/sales-amount';
import MonthlySales from 'modules/widgets/transaction-report/MonthlySales';
import TopRetailsBrand from 'modules/widgets/top-retails-brand';
import TopRetailsArea from 'modules/widgets/top-retails-area';
import TopRetailsProduct from 'modules/widgets/top-retails-product';
import { GET_DEALER_DASHBOARD_TOPS } from 'app/dashboard/constant';
import { getBentaKitaDealer, getTop } from '../actions';

const INIT_STATE = {
  filter_by: 'monthly',
  date: moment().format('YYYY-MM-DD'),
};

const Container = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const all = useSelector(({ api }) =>
    _.get(api, `${GET_DEALER_DASHBOARD_TOPS}.res`)
  );

  const data = {
    brands: _.get(all, 'brands.data', []),
    area: _.get(all, 'branches.data', []),
    products: _.get(all, 'products.data', []),
    total_sales: _.get(all, 'total-sales', ''),
    average_sales: _.get(all, 'average-sales', ''),
  };

  const sales = [
    {
      label: 'Total Sales Amount',
      data: _.get(data, 'total_sales'),
    },
    {
      label: 'Average Sales Amount',
      data: _.get(data, 'average_sales'),
    },
  ];
  useEffect(() => {
    setLoading(true);
    dispatch(getBentaKitaDealer(INIT_STATE));
    dispatch(getTop({ count: 5 }, () => setLoading(false)));
  }, []);

  return (
    <div className="main-content-inner space">
      <div className="box-fit-wrapper h-100">
        <div className="box-fix-overflow">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-6">
                <div className="row">
                  {sales.map(item => (
                    <Sales key={item.label} isLoading={loading} {...item} />
                  ))}
                </div>
                <TopRetailsBrand data={data.brands} isLoading={loading} />
              </div>
              <MonthlySales />
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <TopRetailsArea data={data.area} isLoading={loading} />
              </div>
              <div className="col-6">
                <TopRetailsProduct data={data.products} isLoading={loading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Container;
