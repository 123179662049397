import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import _ from "lodash";
import { ModalContext } from "App";
import { checkPermissions, removeEmpty } from "helper";
import { useSelector, useDispatch } from "react-redux";
import Filter from "components/Filter";
import Range from "components/Range";
import GenericTable from "modules/common/components/GenericTable";
import Pagination from "modules/common/components/Pagination";
import { useLoading } from "modules/ui/hooks";
import * as c from "../constant";
import * as actions from "../actions";
import ViewLoadTransaction from "../components/ViewLoadTransaction";

function LoadTransactHistory() {
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);
  const [payload, setFilter] = useState(c.LSH_default);
  const isLoading = useLoading(c.GET_LOAD_SUBSCRIBER_TRANSACTIONS);
  // const isDownloading = useLoading(c.DOWNLOAD_SUBSCRIBER_TRANSACTION); for future use //echo

  const profile = useSelector(c.LSH_selectors.profile);
  const role = _.head(profile?.roles) || "";
  const raw = useSelector(c.LSH_selectors.raw);
  const pager = raw?.res?.meta?.pagination || {};
  const list = raw?.list || [];

  const handleView = (data) => (e) => {
    e.preventDefault();
    const modalData = _.pick(_.get(data, "attributes"), c.dataMap);
    setModal("view-eload-modal", modalData);
  };

  const handleChangePage = (page) =>
    dispatch(actions.getLoadTransaction(_.pickBy({ ...payload, page })));

  const handleSubmitFilter = () => {
    const trimmedPayload = _.pickBy({
      ...payload,
      created_by_name: _.trim(payload?.created_by_name),
      created_by_username: _.trim(payload?.created_by_username),
      q: _.trim(payload?.q),
    });
    dispatch(actions.getLoadTransaction(trimmedPayload));
  };

  const handleDownload = (e) => {
    e.preventDefault();
    dispatch(actions.downloadLoadTransaction(payload));
  };

  const handleClear = () => {
    setFilter(c.LSH_default);
  };

  const updateTransaction = useCallback(() => {
    const trimmedPayload = _.pickBy({
      ...payload,
      created_by_name: _.trim(payload?.created_by_name),
      created_by_username: _.trim(payload?.created_by_username),
      q: _.trim(payload?.q),
    });
    dispatch(actions.getLoadTransaction(trimmedPayload));
  }, [payload]);

  const initialize = useCallback(() => {
    dispatch(actions.getLoadTransaction(_.pickBy(c.LSH_default)));
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // watch query independently of other params for debouncing
  useEffect(() => {
    updateTransaction();
  }, [..._.values(_.omit(payload, ["q"]))]); // eslint-disable-line

  const renderAction = (item) => (
    <React.Fragment>
      <button
        className="btn btn-no btn-sm"
        type="button"
        onClick={handleView(item)}
      >
        <i className="far fa-eye" /> View
      </button>
    </React.Fragment>
  );

  const showDownload = checkPermissions(["can-create-export-transaction"])
    ? handleDownload
    : null;

  const form = useMemo(() => {
    return c.LSH_form.map((x) => {
      if (x.name === "branch_id")
        return { ...x, show: role !== "kapartner-user" };
      if (x.name === "retailer_id")
        return {
          ...x,
          show: role !== "frontliner" && role !== "kapartner-user",
        };
      return x;
    });
  }, [c.LSH_form, role]);

  return (
    <div className="main-content-inner h-100 notebookTransaction scroll-lime">
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="col-12 h-100">
            <div className="container-fluid px-0">
              <Filter
                form={form}
                filterData={[payload, setFilter]}
                onDownload={showDownload}
                actions={handleSubmitFilter}
                onClear={handleClear}
                loading={isLoading}
                containerClass="tw-mb-4"
              />
              <div className="row">
                <div className="col d-flex align-items-bottom justify-content-between mb-3">
                  <div className="d-flex flex-column justify-content-center">
                    <div className="font-weight-bold my-0 text-xl">
                      Load Subscriber History
                    </div>
                    <Range
                      date_from={payload.date_from}
                      date_to={payload.date_to}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div id="notebook-table" className="col-12">
                  <GenericTable
                    isLoading={isLoading}
                    format={[
                      ...c.LSH_MAP,
                      {
                        key: (item) => renderAction(item),
                        label: "ACTION",
                        width: "auto",
                      },
                    ]}
                    data={list}
                    containerClassName="notebookTransaction__container-table scroll-lime"
                    customTableStyles={c.customTableStyles}
                  />
                  <Pagination onChange={handleChangePage} data={pager} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewLoadTransaction />
    </div>
  );
}

export default LoadTransactHistory;
