import React from "react";
import { Redirect, Switch, Route, useRouteMatch } from "react-router-dom";
import { checkPermissions } from "helper";
import _ from "lodash";
import MySims from "modules/LoadWalletManagement/container/MySims";
import DealerSims from "modules/LoadWalletManagement/container/DealerSims";
import SubDealerSims from "modules/LoadWalletManagement/container/SubDealerSims";
import Transactions from "modules/sim/containers/Transaction/Transaction";
import AllTransactions from "modules/sim/containers/Transaction/AllTransactions";
import { useSelector } from "react-redux";
import { USER_PROFILE } from "modules/auth/constants";
import RetailerSimsRoutes from "./retailer-sims";
import { SimFilterProvider } from "./context/SimFilterProvider";
import { DealerProvider } from "./context/DealerProvider";
import { DealerSimProvider } from "./dealer-sims/context/DealerSimProvider";
import { RetailerSimProvider } from "./retailer-sims/context/RetailerSimProvider";
import * as c from "./constant";

function LoadWalletContainer() {
  const match = useRouteMatch();
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));

  const renderRedirect = () => {
    const canViewSims =
      checkPermissions(["can-view-dealer-sim"]) &&
      !checkPermissions(["can-view-retailer-sim"]);

    if (
      (canViewSims && c.SIM_ADMIN_ROLES?.includes(profile?.roles[0])) ||
      c.SIM_ADMIN_ROLES?.includes(profile?.roles[0])
    ) {
      return <Redirect to={`${match.path}/my-sims`} />;
    }
    return <Redirect to={`${match.path}/dealer-sims`} />;
  };

  return (
    <>
      <SimFilterProvider>
        <DealerProvider>
          <DealerSimProvider>
            <RetailerSimProvider>
              <Switch>
                <Route exact path={match.path} render={renderRedirect} />
                <Route
                  exact
                  path={`${match.path}/dealer-sims`}
                  component={DealerSims}
                />
                <Route
                  exact
                  path={`${match.path}/my-sims`}
                  component={MySims}
                />
                <Route
                  path={`${match.path}/my-sims/transactions/:simId?`}
                  component={Transactions}
                />
                <Route
                  path={`${match.path}/dealer-sims/transactions/:simId?`}
                  component={Transactions}
                />
                <Route
                  exact
                  path={`${match.path}/sub-dealer-sims/:simId?/transactions`}
                  component={Transactions}
                />
                <Route
                  exact
                  path={`${match.path}/my-sims/transactions`}
                  component={AllTransactions}
                />
                <Route
                  path={`${match.path}/sub-dealer-sims/`}
                  component={SubDealerSims}
                />
                <Route
                  path={`${match.path}/retailer-sims`}
                  component={RetailerSimsRoutes}
                />
              </Switch>
            </RetailerSimProvider>
          </DealerSimProvider>
        </DealerProvider>
      </SimFilterProvider>
    </>
  );
}

export default LoadWalletContainer;
