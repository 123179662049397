import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Stepper, Step } from 'modules/common/components/Stepper';
import { USER_PROFILE } from 'modules/auth/constants';
import ConfirmAlert from 'modules/common/components/ConfirmAlert';
import { logout } from 'modules/auth/actions';
import { ToastSuccess } from 'modules/common/components/Toast';
import ChangePassword from './DealerChangePassword';
import Consent from './Consent';
import { GET_CONSENT } from '../constant';
import * as actions from '../action';

const Onboarding = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const apiConsents = useSelector(({ api }) => api?.[GET_CONSENT]?.list || []);
  const profile = useSelector(({ api }) => api?.[USER_PROFILE]?.item);
  const [currentStep, setCurrentStep] = useState(0);
  const [agreed, setAgreed] = useState([]);
  const [pending, setPending] = useState(false);

  const consents = useMemo(() => {
    return actions.organizeConsents(apiConsents, 'PRIVACY NOTICE', profile);
  }, [profile, apiConsents]);

  // Stepper Actions
  const handleConsentAgree = targetConsent => {
    const { id } = targetConsent;
    const att = _.get(targetConsent, 'attributes');

    setAgreed(prevConsent => [
      ...prevConsent,
      {
        consent_id: id,
        has_agreed: 1,
        title: att.title,
        version: att.version,
        body: att.body,
      },
    ]);

    setCurrentStep(step => step + 1);
  };

  // Cancel First Login
  const handleCancelFirstLogin = () => {
    const isProfileStep = currentStep === 3;
    const alertText = isProfileStep
      ? 'For any incorrect details, please contact your Administrator.'
      : `Are you sure you don't want to proceed?`;
    const username = _.get(profile, 'email', '');

    const logoutCallback = () => {
      history.push(`/?mobile_identification_number=${username}`);
      ToastSuccess('You have successfully logged out!', {
        autoClose: 1500,
      });
    };

    const alertCallback = () => {
      dispatch(logout(logoutCallback));
    };

    ConfirmAlert(alertText, alertCallback, () => {}, {
      yesLabel: 'Yes',
      noLabel: 'No',
    });
  };

  const handleSubmit = data => {
    setPending(true);

    const logoutCallback = () => {
      ToastSuccess(
        'You have successfully updated your information. Please log-in again.'
      );
    };

    const onUpdateProfileSuccess = () => {
      dispatch(
        actions.createConsent({ consents: agreed }, () => {
          dispatch(logout(logoutCallback));
          setPending(false);
        })
      );
    };

    const onPassworChangeError = () => {
      setPending(false);
    };

    const onProfileChangeError = () => {
      setPending(false);
    };

    const onPassworChangeSuccess = () => {
      const payload = { is_first_time: false };
      dispatch(
        actions.updateProfile(
          payload,
          onUpdateProfileSuccess,
          onProfileChangeError
        )
      );
    };

    dispatch(
      actions.changePassword(data, onPassworChangeSuccess, onPassworChangeError)
    );
  };

  return (
    <div>
      <Stepper activeStep={currentStep}>
        {consents.map(consent => (
          <Step key={consent.id}>{_.get(consent, 'attributes.title')}</Step>
        ))}
        <Step>Change Password</Step>
      </Stepper>

      {consents.map((consent, index) => {
        if (currentStep !== index) return null;
        return (
          <Consent
            key={consent.id}
            data={consent}
            onNext={() => handleConsentAgree(consent)}
            onCancel={handleCancelFirstLogin}
          />
        );
      })}

      {consents.length && currentStep === consents.length ? (
        <ChangePassword onSubmit={handleSubmit} pending={pending} />
      ) : null}
    </div>
  );
};

export default Onboarding;
