import * as req from "api/actions";
import { downloadGet, rootApi } from "helper";
import { ToastWarn, ToastSuccess } from "modules/common/components/Toast";

import * as c from "./constant";

export const setOtp = (payload, callback, errCallback) =>
  req.create(
    c.SET_OTP,
    "external/api/v1/otp/send",
    payload,
    callback,
    {},
    errCallback
  );

export const inquireBalance = (payload, callback) =>
  req.create(
    c.BALANCE_INQUIRE,
    `mobile/api/v2/inquire-balance`,
    payload,
    callback
  );

export const inquireRetailerBalance = (payload, callback) =>
  req.create(
    c.RETAILER_BALANCE_INQUIRE,
    "mobile/api/v2/inquire-balance",
    payload,
    callback
  );

export const confirmOtp = (payload, callback, errCallback) =>
  req.create(
    c.CONFIRM_OTP,
    "api/v1/external/otp/verify",
    payload,
    callback,
    {},
    errCallback
  );

export const createDealerSims = (payload, callback, errCallback) =>
  req.create(
    c.CREATE_DEALER_SIMS,
    "api/v1/dealer-sims",
    payload,
    callback,
    {},
    errCallback
  );

export const getRetailerSims = (payload, callback) =>
  req.list(
    c.GET_RETAILER_SIMS,
    "api/v1/dealer-retailer-sims",
    payload,
    callback
  );

export const updateRetailerSims = (Id, payload, callback, errCallback) =>
  req.update(
    c.UPDATE_RETAILER_SIMS,
    `api/v1/dealer-retailer-sims/${Id}`,
    payload,
    callback,
    {},
    errCallback
  );

export const getActiveRetailerSims = (params, callback) =>
  req.list(
    c.GET_RETAILER_SIMS_ACTIVE,
    "api/v1/dealer-retailer-sims",
    params,
    callback
  );

export const getDealerSims = (payload, callback) =>
  req.list(c.GET_DEALER_SIMS, "api/v1/dealer-sims", payload, callback);

export const getActiveDealerSims = (params, callback) =>
  req.list(c.GET_DEALER_SIMS_ACTIVE, "api/v1/dealer-sims", params, callback);

export const getTransactions = (payload, callback) =>
  req.list(
    c.GET_TRANSACTIONS,
    `api/v1/dealer-sims/transfer-load/transactions`,
    payload,
    callback
  );

export const getAllTransactions = (payload, callback) =>
  req.list(
    c.GET_ALL_TRANSACTIONS,
    `api/v1/dealer-sims/transfer-load/transactions`,
    payload,
    callback
  );

export const transferInternal = (dealerSimId, payload, callback, errCallback) =>
  req.create(
    c.TRANSFER_INTERNAL,
    `api/v1/dealer-sims/${dealerSimId}/transfer-load`,
    payload,
    callback,
    {},
    errCallback
  );

export const showDealerSim = (Id, payload, callback) =>
  req.item(c.SHOW_DEALER_SIM, `api/v1/dealer-sims/${Id}`, payload, callback);

export const updateDealerSims = (id, payload, callback, errCallback) =>
  req.update(
    c.UPDATE_DEALER_SIMS,
    `api/v1/dealer-sims/${id}`,
    payload,
    callback,
    {},
    errCallback
  );

export const downloadSimHistory = (id, params) => {
  ToastWarn("Generating export file...");
  downloadGet({
    url: `${rootApi}api/v1/history/dealer_sims/${id}`,
    params,
    filename: "Dealer Sim History.csv",
    onSuccess: () => {
      ToastSuccess("Export success!");
    },
  });
  return { type: "DOWNLOAD_SIM_HISTORY" };
};

export const downloadTransactionHistory = (params) => {
  ToastWarn("Generating export file...");
  const now = new Date().toLocaleString("en-US", { timeZone: "Asia/Manila" });
  const timestamp = now
    .replace(/[-/:]/g, "-")
    .replace(",", "")
    .replace(/\s/g, "_");
  downloadGet({
    url: `${rootApi}api/v1/export/dealer-sims/transfer-load/transactions`,
    params,
    filename: `Dealer Sim Transaction History ${timestamp}.csv`,
    onSuccess: () => {
      ToastSuccess("Export success!");
    },
  });
  return { type: "DOWNLOAD_TRANSAC_HISTORY" };
};

export const getActiveChildSims = (min, params, callback) =>
  req.list(
    c.GET_ACTIVE_CHILD_SIMS,
    `api/v1/dealer-sims/list-per-type/${min}`,
    params,
    callback
  );

export const validatePassword = (payload, callback, errCallback) =>
  req.create(
    c.VALIDATE_PASSWORD,
    "api/v1/external/users/my/validate-password",
    payload,
    callback,
    {},
    errCallback
  );
