import * as req from 'api/actions';
import * as c from './constant';

export const requestOTP = (payload, callback, errorCallback) =>
  req.create(
    c.REQUEST_OTP,
    `api/v1/external/users/forgot-password`,
    payload,
    callback,
    {},
    errorCallback
  );

export const confirmOTP = (payload, callback, errorCallback) =>
  req.create(
    c.CONFIRM_OTP,
    `api/v1/external/otp/verify`,
    { ...payload, purpose: 'portal-verification' },
    callback,
    {},
    errorCallback
  );

export const updatePassword = (payload, callback, errorCallback) =>
  req.update(
    c.UPDATE_PASSWORD,
    `api/v1/external/users/forgot-password-change`,
    payload,
    callback,
    {},
    errorCallback
  );
