import React from 'react';
import PropTypes from 'prop-types';
import * as Icon from 'components/Icons';

const NoRecords = ({ title, sub_text }) => {
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="d-flex flex-column align-items-center">
        <Icon.Brand className="d-block" style={{ opacity: 0.25 }} />
        <p className="my-2 text-xl text-gray-400 font-weight-bold">{title}</p>
        <p className="text-gray-400">{sub_text}</p>
      </div>
    </div>
  );
};

NoRecords.propTypes = {
  title: PropTypes.string,
  sub_text: PropTypes.string,
};

NoRecords.defaultProps = {
  title: 'There are no records found',
  sub_text: '',
};

export default NoRecords;
