import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Pagination from 'modules/common/components/Pagination';
import moment from 'moment';
import GenericTable from '../../common/components/GenericTable';
import * as actions from '../actions';
import * as c from '../constant';

function BillsTransaction({
  getBillsTransaction,
  profile,
  list,
  pager,
  isLoading,
}) {
  const handleChangePage = () => {
    const newFilter = { page: pager.current_page + 1 };
    getBillsTransaction(newFilter);
  };

  const isRetailerIdEmpty = !_.isEmpty(profile, 'retailer_id');

  React.useEffect(() => {
    if (isRetailerIdEmpty) {
      getBillsTransaction();
    }
  }, [isRetailerIdEmpty, getBillsTransaction]);

  return (
    <>
      <div className="card-body p-0">
        <GenericTable
          isLoading={isLoading}
          format={[
            {
              key: item => (
                <small>
                  {moment(_.get(item, 'attributes.created_at')).format('LL') ||
                    '--'}
                </small>
              ),
              label: 'Date/Time',
              width: '30%',
            },
            {
              key: item => (
                <small>
                  {_.get(item, 'attributes.transaction_type') || '--'}
                </small>
              ),
              label: 'Transaction Type',
              width: '20%',
            },
            {
              key: item => (
                <small>
                  {_.get(item, 'attributes.payment_biller') || '--'}
                </small>
              ),
              label: 'Channel',
              width: '30%',
            },
            {
              key: item => (
                <small>
                  {_.get(item, 'attributes.account_number') || '--'}
                </small>
              ),
              label: 'Account No.',
              width: '20%',
            },
          ]}
          data={list}
          height="300px"
        />
        <Pagination onChange={handleChangePage} data={pager} />
      </div>
    </>
  );
}

BillsTransaction.propTypes = {
  getBillsTransaction: PropTypes.func.isRequired,
  list: PropTypes.instanceOf(Array).isRequired,
  pager: PropTypes.instanceOf(Object).isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ api }) => ({
  pager: _.get(api, `${c.GET_BILLS_TRANSACTION}.pager`) || {},
  list: _.get(api, `${c.GET_BILLS_TRANSACTION}.list`) || [],
  isLoading: api.loading.indexOf(c.GET_BILLS_TRANSACTION) > -1,
  profile: _.get(api, 'AUTH/user_profile.item') || {},
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(BillsTransaction);
