import React, { useState, useMemo, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { ToastSuccess } from 'modules/common/components/Toast';
import moment from 'moment';
import ReactInputMask from 'react-input-mask';
import { IconPhoto } from 'components/Icons';
import Img from 'modules/common/components/ImgDoc';
import withModal from 'modules/common/hoc/withModal';
import { ModalContext } from 'App';
import { removeEmpty } from 'helper';
import ButtonAsync from 'components/Button';
import FormInputBorderBottom from 'modules/common/components/input/FormInputBorderBottom';
import placeholderPhoto from 'assets/images/upload-square-placeholder.png';
import { getExtension } from 'hooks';
import Calendar, { formatDate } from '../../partners/components/Calendar';
import UploadFile from '../../partners/components/UploadFileNew';
import LocationSelectField from '../../partners/components/LocationSelectField';

import * as c from '../constants';
import * as actions from '../actions';

const UpdateAccountInformation = () => {
  const setModal = useContext(ModalContext);
  const dispatch = useDispatch();
  const modalData = useSelector(({ modal }) => _.get(modal, 'modalData', ''));

  const [initialData, setInitialData] = useState(c.onboardDefaultValue);
  const [pending, setPending] = useState(false);

  const dealerInfo = _.get(modalData, 'item');

  const userProfile = useSelector(({ api }) =>
    _.get(api, 'AUTH/user_profile.item')
  );

  const dealer_id = _.get(dealerInfo, 'id');

  const companyInfo = _.get(modalData, 'companyInfo');

  const updated_by = _.get(userProfile, 'full_name');

  const handleUploadImage = (files, name) =>
    setInitialData({
      ...initialData,
      [name]: _.get(files, '0.file'),
    });

  const isInputEmpty = useMemo(() => {
    const { po_effectively_date, po_expiration_date } = initialData;
    const area_id = _.get(initialData, 'area_id');

    return (
      _.isEmpty(po_effectively_date) ||
      _.isEmpty(po_expiration_date) ||
      _.isEmpty(area_id)
    );
  }, [initialData]);

  const handleCloseModal = () => setModal({ isOpen: false });

  const handleSelectedDate = (e, key) => {
    const { value } = e.target;
    setInitialData({
      ...initialData,
      [key]: value,
    });
  };

  const handleChangeOtherFields = ({ target: { name, value } }) =>
    setInitialData({
      ...initialData,
      [name]: value,
    });

  const handleRadio = ({ target: { id } }) =>
    setInitialData({
      ...initialData,
      is_exclusive: id === 'exclusive',
    });

  const handleChangeLocation = selectedData =>
    setInitialData(prev => ({
      ...prev,
      area_id: _.get(selectedData, 'value'),
    }));

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);

    const errCallback = () => setPending(false);

    const dealerPayload = {
      area_id: _.get(initialData, 'area_id.value')
        ? _.get(initialData, 'area_id.value')
        : null,
      is_exclusive: _.get(initialData, 'is_exclusive'),
      account_status: 'on-boarded',
      updated_by,
    };

    const updateDealerSuccess = () => {
      dispatch(actions.showUserDetails(dealer_id));
      dispatch(actions.showCompanyInformation(dealer_id));
      ToastSuccess('Succesfully Updated Account Information');

      handleCloseModal();
      setPending(false);
    };

    const companySuccessCallback = () => {
      dispatch(
        actions.updateDealer(
          dealer_id,
          removeEmpty(dealerPayload),
          updateDealerSuccess,
          errCallback
        )
      );
    };

    const companyPayload = {
      ...initialData,
      updated_by,
      area_id: '',
      is_exclusive: '',
    };

    dispatch(
      actions.updateDealerCompany(
        dealer_id,
        companyPayload,
        companySuccessCallback,
        errCallback
      )
    );
  };

  useEffect(() => {
    if (_.isEmpty(initialData.po_effectively_date)) return;
    setInitialData(prev => {
      const yearPlusOne = moment(prev.po_effectively_date).add(1, 'year');
      const formattedDate = formatDate(yearPlusOne, '', 'YYYY-MM-DD');

      return {
        ...prev,
        po_expiration_date: formattedDate,
      };
    });
  }, [initialData.po_effectively_date]);

  useEffect(() => {
    const prevData = {
      is_exclusive: _.get(dealerInfo, 'attributes.is_exclusive'),
      area_id: {
        value: _.get(dealerInfo, 'attributes.area_id'),
        label: _.get(dealerInfo, 'attributes.area_name'),
      },
      contract: _.get(companyInfo, 'attributes.contract'),
      card_number: _.get(companyInfo, 'attributes.card_number'),
      bank_name: _.get(companyInfo, 'attributes.bank_name'),
      bank_account_name: _.get(companyInfo, 'attributes.bank_account_name'),
      bank_account_number: _.get(companyInfo, 'attributes.bank_account_number'),
      po_effectively_date: moment(
        _.get(companyInfo, 'attributes.po_effectively_date')
      ).format('YYYY-MM-DD'),
      po_expiration_date: moment(
        _.get(companyInfo, 'attributes.po_expiration_date')
      ).format('YYYY-MM-DD'),
    };
    setInitialData(prevData);
  }, [dealerInfo, companyInfo]);

  return (
    <div className="p-2">
      <form onSubmit={handleSubmit}>
        <div className="row" style={{ marginBottom: '2rem' }}>
          <div className="col-4">
            <FormInputBorderBottom
              autoFocus
              readOnly
              label="Dealer Code"
              name="code"
              placeholder="TS-0006789"
              value={_.get(dealerInfo, 'attributes.code')}
              inputStyles={{ fontSize: '.7rem', borderColor: 'gray' }}
              className="form-control form-custom form-control_bottom px-3 bg-light-100"
            />
          </div>

          <div className="col-4 row d-flex align-items-end">
            <div
              className={`col-5 d-flex align-items-center px-2 ${_.get(
                initialData,
                'is_exclusive'
              ) && 'bg-light-100'}`}
              style={{ padding: '.65rem 0' }}
            >
              <input
                type="radio"
                id="exclusive"
                name="radioGroup"
                onChange={handleRadio}
                checked={_.get(initialData, 'is_exclusive')}
                className="cursor-pointer mr-2"
                disabled
              />
              <label
                htmlFor="exclusive"
                className="cursor-pointer font-weight-bold mb-0"
                style={{ fontSize: '.8rem' }}
              >
                Exclusive
              </label>
            </div>
            <div
              className={`col d-flex align-items-center px-2 ${!_.get(
                initialData,
                'is_exclusive'
              ) && 'bg-light-100'}`}
              style={{ padding: '.65rem 0' }}
            >
              <input
                type="radio"
                id="nonExclusive"
                name="radioGroup"
                className="cursor-pointer mr-2"
                checked={!_.get(initialData, 'is_exclusive')}
                onChange={handleRadio}
                disabled
              />
              <label
                htmlFor="nonExclusive"
                className="cursor-pointer font-weight-bold mb-0"
                style={{ fontSize: '.8rem' }}
              >
                Non-Exclusive
              </label>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '2rem' }}>
          <div className="mb-3">
            <h6 className="h6 m-0 text-xs font-weight-bold">
              Selling Location
            </h6>
          </div>
          {_.get(initialData, 'area_id.value') && (
            <div className="row">
              <div className="col-4">
                <label
                  htmlFor="Territory"
                  style={{ fontSize: '.6rem' }}
                  className="font-weight-bold"
                >
                  Territory *
                </label>
                <LocationSelectField
                  onChange={handleChangeLocation}
                  name="area_id"
                  value={_.get(initialData, 'area_id')}
                  isDisabled
                />
              </div>
            </div>
          )}
        </div>

        <div style={{ marginBottom: '2rem' }}>
          <div className="row d-flex align-items-end">
            <div className="col-4">
              <div className="mb-3">
                <h6 className="h6 m-0 text-xs font-weight-bold">
                  Contract Details
                </h6>
              </div>
              <label
                htmlFor="effectivity_date"
                style={{ fontSize: '.6rem' }}
                className="font-weight-bold"
              >
                Effectivity Date *
              </label>
              <Calendar
                onChange={e => handleSelectedDate(e, 'po_effectively_date')}
                value={_.get(initialData, 'po_effectively_date')}
                disabled
              />
            </div>
            <div className="col-4">
              <label
                htmlFor="expiry_date"
                style={{ fontSize: '.6rem' }}
                className="font-weight-bold"
              >
                Expiry Date *
              </label>
              <Calendar
                onChange={e => handleSelectedDate(e, 'po_expiration_date')}
                value={_.get(initialData, 'po_expiration_date')}
                disabled
              />
            </div>

            <div className="col-4">
              <label
                htmlFor="expiry_date"
                style={{ fontSize: '.6rem' }}
                className="font-weight-bold"
              >
                Contract
              </label>
              <div
                className="py-2 px-1 text-center bg-light-100"
                style={{ border: '2px dashed gray', borderRadius: '.2rem' }}
              >
                {!_.isEmpty(_.get(initialData, 'contract')) ? (
                  <>
                    <Img
                      lightBox
                      withZoom
                      defaultSrc={placeholderPhoto}
                      type={getExtension(_.get(initialData, 'contract') || '')}
                      src={_.get(initialData, 'contract') || ''}
                    />
                    <div className="reupload-button-contract">
                      <UploadFile
                        caption="Maximum size for the document is 40MB. (.jpg / .jpeg, .png, .pdf)"
                        fileType="image/*, application/pdf"
                        className="btn btn-sm btn-success font-weight-semibold"
                        fileName="contract"
                        onChange={handleUploadImage}
                        customButton={() => (
                          <>
                            <i className="fas fa-file-upload mr-2" />
                            Update Document
                          </>
                        )}
                      />
                    </div>
                  </>
                ) : (
                  <section>
                    <span className="icon">
                      <IconPhoto />
                    </span>
                    <h5 className="icon-label">
                      Upload a soft copy of this document.
                    </h5>
                    <UploadFile
                      caption="Maximum size for the document is 40MB. (.jpg / .jpeg, .png, .pdf)"
                      fileType="image/*, application/pdf"
                      className="btn btn-success text-center px-3"
                      onChange={handleUploadImage}
                      fileName="contract"
                    />
                  </section>
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="mb-3">
            <h6 className="h6 m-0 text-xs font-weight-bold">
              Trade Money Information
            </h6>
          </div>
          <div className="row mb-3">
            <div className="col-4">
              <label
                className="form-label font-weight-bold text-dark"
                style={{ fontSize: '.6rem' }}
              >
                Dealer Card Number
              </label>
              <ReactInputMask
                mask="9999999999999999"
                maskChar=""
                className="form-control form-custom form-control_bottom px-3"
                name="card_number"
                placeholder="Enter 16-digit number..."
                style={{ fontSize: '.7rem' }}
                value={_.get(initialData, 'card_number')}
                onChange={handleChangeOtherFields}
              />
            </div>
            <div className="col-4 d-none">
              <FormInputBorderBottom
                label="BDL MIN*"
                name="min"
                readOnly
                placeholder="For setup"
                className="form-control form-custom form-control_bottom px-3 bg-light-100"
                value="For Setup"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                Bank Name
              </label>
              <input
                name="bank_name"
                className="form-control form-custom form-control_bottom px-3"
                placeholder="Enter..."
                onChange={handleChangeOtherFields}
                value={_.get(initialData, 'bank_name', '')}
                style={c.inputStyles}
              />
            </div>

            <div className="col-4">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                Bank Account Name
              </label>
              <input
                name="bank_account_name"
                className="form-control form-custom form-control_bottom px-3"
                placeholder="Enter..."
                onChange={handleChangeOtherFields}
                value={_.get(initialData, 'bank_account_name', '')}
                style={c.inputStyles}
              />
            </div>

            <div className="col-4">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                Bank Account Number
              </label>
              <ReactInputMask
                mask="9999999999"
                maskChar=""
                className="form-control form-custom form-control_bottom px-3"
                name="bank_account_number"
                value={_.get(initialData, 'bank_account_number', '')}
                onChange={handleChangeOtherFields}
                placeholder="Enter..."
                style={c.inputStyles}
              />
            </div>
          </div>
        </div>

        <div className="button-container d-flex justify-content-end mt-5">
          <ButtonAsync
            className="btn btn-primary position-relative py-2 px-4 mr-2"
            type="submit"
            loading={pending}
            disabled={isInputEmpty}
          >
            Update
          </ButtonAsync>

          <button
            type="button"
            className="btn bg-gray-300 text-white py-2 px-4"
            onClick={handleCloseModal}
            disabled={pending}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

const modalKey = 'update-dealer-info-account-information';
const modalConfig = {
  title: 'Account Information',
  size: 'modal-lg',
};

export default withModal(modalKey, modalConfig)(UpdateAccountInformation);
