import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { ToastError } from "modules/common/components/Toast";
import { renderIframe } from "helper";
import * as c from "../constant";

const ConsentAndTerms = ({ onNext, data, isLoading, onCancel }) => {
  const isPN = _.get(data, "attributes.title").includes("PRIVACY NOTICE");
  const [form, setForm] = useState(c.CHECKBOX);
  const handleNext = () => {
    onNext();
  };
  const handleToggle = (id, value) => (e) => {
    e.preventDefault();
    const temp = form.map((item) => {
      if (item.id === id) return { ...item, value };
      return item;
    });
    setForm(temp);
  };
  const handleDisagree = (e) => {
    e.preventDefault();
    ToastError(
      `You must agree on the ${_.get(data, "attributes.title")} to continue.`,
      {
        autoClose: 3000,
        showCount: 1,
      }
    );
  };

  const textStyle = {
    maxHeight: isPN ? 230 : 350,
  };

  const checked = form.filter((item) => item.value === true);

  const body = data?.attributes?.body || "";

  const toRender = useMemo(() => (body ? renderIframe(body) : ""), [body]);

  return (
    <div>
      <div className="consent-tnc-content mx-auto mb-3">
        <h1 className="font-weight-bold h4 text-primary text-center mt-4 mb-3">
          {_.get(data, "attributes.title")}
        </h1>
        <div style={textStyle} className="consent-tnc-text px-4">
          <div
            className="ck-content allow-list consent-tnc-text"
            dangerouslySetInnerHTML={{
              __html: toRender,
            }}
          />
        </div>
      </div>
      {isPN && (
        <div className="row mt-4 mx-auto px-3" style={{ maxWidth: 500 }}>
          {form.map((item) => (
            <React.Fragment key={item.id}>
              <a
                className="text-dark d-flex mb-2"
                href="/"
                onClick={handleToggle(item.id, !item.value)}
              >
                <i
                  className={`${
                    item.value
                      ? "fa fa-check-square text-success"
                      : "far fa-square"
                  }`}
                />
                <small
                  className="ml-2 text-justify"
                  style={{ lineHeight: 1.2 }}
                >
                  {item.content}
                </small>
              </a>
            </React.Fragment>
          ))}
        </div>
      )}

      <div className="d-flex justify-content-center my-2">
        <button
          type="button"
          className="btn btn-primary mr-3"
          onClick={handleNext}
          disabled={isPN ? checked.length !== 3 : false}
          style={{ width: 150 }}
        >
          Agree
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleDisagree}
          style={{ width: 150 }}
          disabled={isLoading}
        >
          Disagree
        </button>
      </div>
      <div className="text-center">
        <button
          type="button"
          className="cancel-btn btn btn-link px-4"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

ConsentAndTerms.propTypes = {
  onNext: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const mapStateToProps = ({ api }) => ({
  profile_type: _.get(api, "AUTH/user_profile.item.profile_type") || "",
  isLoading: api.loading.indexOf(c.GET_CONSENT) > -1,
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps)]);

export default enhance(ConsentAndTerms);
