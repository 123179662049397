import React from "react";
import logo from "assets/images/logo-loader.png";

function Page404() {
  return (
    <div className="container-fluid min-vh-100 bg-light-grey px-0">
      <div className="tw-h-full tw-flex tw-flex-col tw-justify-center">
        <div className="tw-flex tw-items-center tw-justify-center pl-0">
          <img src={logo} alt="Login Banner" />
        </div>
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center pr-0">
          <h4 className="mb-2 text-center tw-mt-0">
            Please check the URL or your access permission.
          </h4>
          <p className="text-center tw-w-6/12">
            Contact your system administrator for more details.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Page404;
