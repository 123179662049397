import { combineReducers } from 'redux';
import api, { apiInitState } from 'api/reducers';
import ui from 'modules/ui/reducers';
import modal, { modalInitState } from 'modules/common/reducers/modal';

const mapReduce = (actions, initState) => (state = initState, action) => {
  if (actions[action.type]) {
    return actions[action.type](state, action);
  }
  return state;
};

export default combineReducers({
  api: mapReduce(api, apiInitState),
  modal: mapReduce(modal, modalInitState),
  ui
});
