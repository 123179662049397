import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import RetailerSims from './containers/RetailerSims';

const RetailerSimsRoutes = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/:retailerId?`} component={RetailerSims} />
    </Switch>
  );
};

export default RetailerSimsRoutes;
