import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AgentOperation from './containers/AgentOperation';
import RetailerAssignmentSetup from './containers/RetailerAssignmentSetup';
import RetailerTransferSetup from './containers/RetailerTransferSetup';

const AgentOperationRoutes = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/:agentId?`} component={AgentOperation} />
      <Route
        path={`${match.url}/:agentId?/assignment`}
        component={RetailerAssignmentSetup}
      />
      <Route
        path={`${match.url}/:agentId?/transfer`}
        component={RetailerTransferSetup}
      />
    </Switch>
  );
};

export default AgentOperationRoutes;
