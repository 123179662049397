import _ from "lodash";
import PropTypes from "prop-types";
import { ModalContext } from "App";
import DataField from "components/DataField";
import * as Icon from "components/Icons";
import Panel from "components/Panel";
import PanelButton from "components/PanelButton";
import PanelRow from "components/PanelRow";
import Actions from "components/Actions";
import { renderStatus } from "modules/ui/hooks";
import React, { Fragment, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { ToastError, ToastSuccess } from "modules/common/components/Toast";
import ConfirmAlert from "modules/common/components/ConfirmAlert";
import { getClusterAgents, deleteClusterAgent } from "../actions";
import { getAgentMins } from "../../agent-operation/actions";

const AddIcon = () => <Icon.Add fill="#fff" />;

const ClusterAgentsPanel = () => {
  const urlParams = useParams();
  const setModal = useContext(ModalContext);
  const clusterId = _.get(urlParams, "clusterId");
  const queryClient = useQueryClient();

  const agentQuery = useQuery({
    queryKey: ["cluster-agents", clusterId],
    queryFn: getClusterAgents,
    keepPreviousData: true,
  });

  const { mutate: removeClusterAgent } = useMutation(deleteClusterAgent, {
    onSuccess: () => {
      queryClient.invalidateQueries("cluster-agents");
      ToastSuccess("Successfully removed agent from cluster.");
      setModal({ isOpen: false });
    },
    onError: () => {
      ToastError("Failed to remove agent from cluster.");
      setModal({ isOpen: false });
    },
  });

  const handleOnAssign = () => setModal("cluster-agent-setup");
  const handleOnUnassign = (agentId) => {
    const onConfirm = () => removeClusterAgent({ clusterId, agentId });
    const onReject = () => {};
    const message =
      "This will remove the sub-dealer agent assigned to this cluster.";
    const yesLabel = "Yes";
    const noLabel = "Cancel";
    const header = "Remove";
    const newButtons = true;
    const center = false;
    const sub_text = "Are you sure you want to proceed?";

    ConfirmAlert(message, onConfirm, onReject, {
      yesLabel,
      noLabel,
      header,
      newButtons,
      center,
      sub_text,
    });
  };

  const agentList = _.get(agentQuery, "data.data");

  return (
    <Fragment>
      <Panel
        title="Sub-dealer Agents"
        renderActions={() => (
          <div className="btn-group">
            <PanelButton
              iconLeft={AddIcon}
              label="Assign"
              onClick={handleOnAssign}
            />
          </div>
        )}
      >
        {_.isEmpty(agentList) ? (
          <PanelRow>
            <div className="col text-center text-gray-400 text-xs">
              No agents assigned to this cluster yet.
            </div>
          </PanelRow>
        ) : (
          <Fragment>
            {_.map(agentList, (agent) => {
              const id = _.get(agent, "id");
              return (
                <PanelRow key={id}>
                  <DataField
                    label="Name"
                    value={_.get(agent, "attributes.full_name")}
                    className="col-md mb-2 mb-md-0 order-1"
                  />
                  <DataFieldAgentMin agentId={agent?.id} />
                  <DataField
                    label="Agent Status"
                    value={renderStatus[+_.get(agent, "attributes.is_active")]}
                    className="col-md mb-2 mb-md-0 order-1"
                  />
                  <div className="col d-flex align-items-center justify-content-end order-md-1">
                    <Actions label="Actions" id={id}>
                      <button
                        type="button"
                        className="btn btn-sm text-gray-600 w-100 text-left"
                        onClick={() => handleOnUnassign(id)}
                      >
                        Remove
                      </button>
                    </Actions>
                  </div>
                </PanelRow>
              );
            })}
          </Fragment>
        )}
      </Panel>
    </Fragment>
  );
};

export default ClusterAgentsPanel;

const DataFieldAgentMin = ({ agentId }) => {
  const agentMinQuery = useQuery({
    queryKey: ["agent-mins", { agentId, is_active: true }],
    queryFn: getAgentMins,
    enabled: !!agentId,
    keepPreviousData: true,
  });

  const agentMin = agentMinQuery?.data?.data;

  return (
    <DataField
      label="DSP MIN"
      value={agentMin?.attributes?.mobile_identification_number}
      className="col-md mb-2 mb-md-0 order-1"
    />
  );
};

DataFieldAgentMin.propTypes = {
  agentId: PropTypes.string.isRequired,
};
