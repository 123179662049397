import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DateInputPicker from 'modules/common/components/DateInputPicker';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Pagination from '../../common/components/Pagination';
import GenericTable from '../../common/components/GenericTable';
import * as actions from '../actions';
import * as c from '../constant';

const viewWidth = window.innerWidth;
const finalHeight = viewWidth >= 1199 ? '60vh' : '40vh';

const INIT_STATE = {
  q: '',
  date_from: '',
  date_to: '',
  page: 1,
};

function BillPaymentHistory({ getBillsTransaction, isLoading, list, pager }) {
  const [filter, setFilter] = React.useState(INIT_STATE);

  const handleChangePage = () => {
    const newFilter = { ...filter, page: pager.current_page + 1 };
    setFilter(newFilter);
    getBillsTransaction(newFilter);
  };

  const handleClearFilter = e => {
    e.preventDefault();
    setFilter(INIT_STATE);
    getBillsTransaction(INIT_STATE);
  };

  const handleSubmitFilter = e => {
    e.preventDefault();
    getBillsTransaction(filter);
  };

  const handleOnChange = key => e => {
    setFilter({
      ...filter,
      [key]: e.target.value || '',
    });
  };

  const handleSelected = key => e => {
    setFilter({
      ...filter,
      [key]: e.target.value || '',
    });
  };

  React.useEffect(() => {
    getBillsTransaction();
  }, [getBillsTransaction]);

  return (
    <div className="main-content-inner h-100">
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="col-12 h-100">
            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-xl-2 col-lg-12 pr-0">
                  <div className="form-group">
                    <label className="form-label text-success">Search:</label>
                    <input
                      type="text"
                      placeholder="Search..."
                      className="form-control form-custom form-control_bottom"
                      value={_.get(filter, 'q') || ''}
                      onChange={handleOnChange('q')}
                    />
                  </div>
                </div>
                <div className="col-xl-2 col-lg-4 pr-0">
                  <div className="form-group">
                    <label className="form-label text-success">
                      Date From:
                    </label>
                    <DateInputPicker
                      dateFormat="yyyy/MM/dd"
                      placeholder="Date From"
                      className="form-control form-custom form-control_bottom w-100"
                      onChange={handleSelected('date_from')}
                      value={_.get(filter, 'date_from')}
                    />
                  </div>
                </div>
                <div className="col-xl-2 col-lg-4 pr-0">
                  <div className="form-group">
                    <label className="form-label text-success">Date To:</label>
                    <DateInputPicker
                      dateFormat="yyyy/MM/dd"
                      placeholder="Date To"
                      className="form-control"
                      onChange={handleSelected('date_to')}
                      value={_.get(filter, 'date_to')}
                    />
                  </div>
                </div>
                <div className="col-xl-2 col-lg-4 d-flex align-items-center w-100">
                  <button
                    type="button"
                    className="btn btn-success btn-block btn-md px-4 mt-3"
                    onClick={handleSubmitFilter}
                  >
                    Search
                  </button>
                </div>
                {!_.isEmpty(
                  _.get(filter, 'q') ||
                    _.get(filter, 'date_from') ||
                    _.get(filter, 'date_to')
                ) && (
                  <div className="col-xl-2 col-lg-4 d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-danger btn-md mt-3"
                      onClick={handleClearFilter}
                      style={{
                        boxShadow: 'none',
                      }}
                    >
                      Clear all Filter
                    </button>
                  </div>
                )}
              </div>
              <div className="row d-flex align-items-center justify-content-between mb-3">
                <div className="col-xl-5 col-lg-8">
                  <h3 className="font-weight-bold mt-0 mb-2">
                    Search Bills Payment History
                  </h3>
                </div>
                <div className="col-xl-2 col-lg-4 ml-auto">
                  <button
                    type="button"
                    className="btn btn-md btn-primary text-uppercase btn-block"
                  >
                    <i className="fas fa-download mr-2" /> Download
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <GenericTable
                    isLoading={isLoading}
                    format={[
                      {
                        key: item => (
                          <span>
                            {moment(
                              _.get(item, 'attributes.created_at')
                            ).format('LL') || '--'}
                          </span>
                        ),
                        label: 'Date/Time',
                        width: 'auto',
                      },
                      {
                        key: item => (
                          <span>
                            {_.get(item, 'attributes.transaction_type') || '--'}
                          </span>
                        ),
                        label: 'Transaction Type',
                        width: 'auto',
                      },
                      {
                        key: item => (
                          <span>
                            {_.get(item, 'attributes.payment_biller') || '--'}
                          </span>
                        ),
                        label: 'Channel',
                        width: 'auto',
                      },
                      {
                        key: item => (
                          <span>
                            {_.get(item, 'attributes.account_number') || '--'}
                          </span>
                        ),
                        label: 'Account No.',
                        width: 'auto',
                      },
                      {
                        key: item => (
                          <span>
                            {_.get(item, 'attributes.transaction_reference') ||
                              '--'}
                          </span>
                        ),
                        label: 'Transaction ID',
                        width: 'auto',
                      },
                      {
                        key: item => (
                          <span>{_.get(item, 'attributes.total') || '--'}</span>
                        ),
                        label: 'Total Amount',
                        width: 'auto',
                      },
                      {
                        key: item => (
                          <span>
                            {_.get(item, 'attributes.status') || '--'}
                          </span>
                        ),
                        label: 'Status',
                        width: 'auto',
                      },
                    ]}
                    data={list}
                    height={finalHeight}
                  />
                  <Pagination onChange={handleChangePage} data={pager} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BillPaymentHistory.propTypes = {
  getBillsTransaction: PropTypes.func.isRequired,
  pager: PropTypes.instanceOf(Object).isRequired,
  list: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ api }) => ({
  pager: _.get(api, `${c.GET_BILLS_TRANSACTION}.pager`) || {},
  list: _.get(api, `${c.GET_BILLS_TRANSACTION}.list`) || [],
  isLoading: api.loading.indexOf(c.GET_BILLS_TRANSACTION) > -1,
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(BillPaymentHistory);
