import _ from "lodash";
import Panel from "components/Panel";
import PanelButton from "components/PanelButton";
import PanelRow from "components/PanelRow";
import Pagination from "modules/common/components/Pagination";
import React, { Fragment, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { checkPermissions, numberToLocale, removeEmpty } from "helper";
import { getAssignedDealerRetailerSims } from "../actions";

const SourceRetailerMinsPanel = () => {
  const urlParams = useParams();
  const history = useHistory();
  const location = useLocation();
  const [page, setPage] = React.useState(1);

  const branchId = _.get(urlParams, "branchId");
  const apiDealerSims = useQuery({
    queryKey: [
      "retailer_source_mins",
      removeEmpty({ branch_id: branchId, page, per_page: 28 }),
    ],
    queryFn: getAssignedDealerRetailerSims,
    retry: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleChangePage = (page_num) => {
    setPage(page_num);
  };
  const assignedMins = _.get(apiDealerSims, "data.data.data.attributes.rows");
  const pager = _.get(apiDealerSims, "data.data.meta");

  const handleOnUpdate = () =>
    history.push(`${location.pathname}/retailer-sims`);

  const listing = useMemo(() => {
    if (_.isEmpty(assignedMins)) return [];
    const filtered = [...assignedMins].filter((x) => !x?.is_default);
    const defaultSim = [...assignedMins].find((x) => x?.is_default);
    if (page !== 1) return filtered;
    return [defaultSim, ...filtered];
  }, [assignedMins]);

  return (
    <Fragment>
      <Panel
        title="Source Retailer MINs"
        renderActions={() => (
          <div className="btn-group">
            {checkPermissions(["can-update-branch"]) && (
              <PanelButton label="Update" onClick={handleOnUpdate} />
            )}
          </div>
        )}
      >
        <div className="tw-px-4">
          <PanelRow appendClass="tw-grid tw-grid-cols-7 tw-gap-2 px-2">
            <div className="tw-col-start-1 tw-col-end-8 text-xs">
              Currently Assigned:{" "}
              <span className="tw-font-extrabold">
                {numberToLocale(pager?.total)}
              </span>
            </div>
            {listing?.map((min) => (
              <div
                className={`text-xs tw-rounded-full border text-center py-1 ${min?.is_default &&
                  "bg-gray-500 text-white"}`}
                key={JSON.stringify(min)}
              >
                {min?.mobile_identification_number}
              </div>
            ))}
          </PanelRow>
          <Pagination
            onChange={handleChangePage}
            data={pager}
            withPage
            className="tw-flex tw-justify-between tw-w-full tw-pb-5 tw-px-4"
          />
        </div>
      </Panel>
    </Fragment>
  );
};

export default SourceRetailerMinsPanel;
