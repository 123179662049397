import React from 'react';

export const IconDashboard = () => (
  <svg className="icon-menu" viewBox="0 0 32 32">
    <path
      d="M28.41 14.284a12.77 12.77 0 00-2.93-4.063c-1.228-1.136-2.69-2.04-4.39-2.712A14.379 14.379 0 0015.75 6.5c-1.862 0-3.642.336-5.34 1.009-1.699.672-3.162 1.576-4.39 2.712a12.77 12.77 0 00-2.93 4.063A11.651 11.651 0 002 19.227c0 2.481.721 4.768 2.164 6.861.194.275.47.412.828.412h21.516c.358 0 .634-.137.828-.412 1.443-2.102 2.164-4.39 2.164-6.86 0-1.724-.363-3.372-1.09-4.944zM14.362 8.851a1.972 1.972 0 011.389-.533c.542 0 1.005.178 1.389.533.384.355.576.784.576 1.285 0 .502-.192.93-.576 1.286a1.972 1.972 0 01-1.389.533 1.972 1.972 0 01-1.389-.533c-.383-.355-.575-.784-.575-1.286 0-.501.191-.93.575-1.285zM7.317 20.513a1.973 1.973 0 01-1.388.533 1.973 1.973 0 01-1.39-.533c-.383-.355-.575-.784-.575-1.286s.192-.93.576-1.285a1.972 1.972 0 011.389-.533c.542 0 1.005.178 1.388.533.384.355.576.783.576 1.285 0 .502-.192.93-.576 1.286zm2.947-6.364a1.973 1.973 0 01-1.389.533 1.973 1.973 0 01-1.389-.533c-.383-.355-.575-.783-.575-1.285 0-.502.191-.93.575-1.286a1.972 1.972 0 011.389-.532c.542 0 1.005.177 1.389.532.383.355.575.784.575 1.286 0 .501-.192.93-.575 1.285zm8.693.121l-1.55 5.426c.512.322.88.753 1.105 1.293.225.54.256 1.093.092 1.661-.204.73-.66 1.284-1.366 1.662a3.069 3.069 0 01-2.24.284c-.788-.189-1.386-.61-1.796-1.264-.409-.653-.51-1.344-.306-2.074a2.628 2.628 0 01.966-1.398 3.047 3.047 0 011.642-.618l1.55-5.427a.909.909 0 01.46-.56.98.98 0 01.737-.093.965.965 0 01.591.419.841.841 0 01.115.689zm2.28-.12c-.384-.356-.576-.784-.576-1.286s.192-.93.575-1.286a1.972 1.972 0 011.389-.532c.542 0 1.005.177 1.389.532.383.355.576.784.576 1.286 0 .501-.193.93-.576 1.285a1.972 1.972 0 01-1.389.533 1.972 1.972 0 01-1.389-.533zm5.723 6.363a1.973 1.973 0 01-1.389.533 1.973 1.973 0 01-1.388-.533c-.384-.355-.576-.784-.576-1.286s.192-.93.576-1.285a1.971 1.971 0 011.388-.533c.543 0 1.005.178 1.39.533.383.355.575.783.575 1.285 0 .502-.192.93-.576 1.286z"
      fillRule="nonzero"
    />
  </svg>
);

export const IconSubscriber = () => (
  <svg className="icon-menu" viewBox="0 0 32 32">
    <path
      fillRule="nonzero"
      d="M18.897 8.454c1.294.79 2.202 2.125 2.368 3.675.528.24 1.114.378 1.735.378 2.268 0 4.106-1.788 4.106-3.993S25.268 4.522 23 4.522c-2.247 0-4.069 1.756-4.103 3.932zm-2.69 8.175c2.268 0 4.106-1.788 4.106-3.993s-1.839-3.992-4.106-3.992c-2.268 0-4.108 1.788-4.108 3.993s1.84 3.992 4.108 3.992zm1.741.272h-3.484c-2.9 0-5.258 2.294-5.258 5.113v4.143l.01.065.294.089c2.767.84 5.17 1.12 7.15 1.12 3.864 0 6.104-1.07 6.242-1.139l.274-.135h.03v-4.143c0-2.82-2.358-5.113-5.258-5.113zm6.794-4.122h-3.457a4.86 4.86 0 01-1.562 3.428c2.577.745 4.462 3.068 4.462 5.813v1.276c3.414-.121 5.382-1.062 5.511-1.125l.275-.135H30V17.89c0-2.818-2.359-5.112-5.258-5.112zm-15.741-.272c.803 0 1.55-.228 2.184-.616a4.917 4.917 0 011.91-3.151c.004-.075.012-.15.012-.225 0-2.205-1.839-3.992-4.106-3.992-2.269 0-4.107 1.787-4.107 3.992s1.838 3.992 4.107 3.992zm3.688 3.7a4.863 4.863 0 01-1.561-3.409c-.128-.009-.255-.019-.386-.019H7.258C4.358 12.78 2 15.073 2 17.891v4.144l.01.064.294.09c2.22.674 4.2.984 5.921 1.082V22.02c.001-2.745 1.886-5.068 4.464-5.813z"
    />
  </svg>
);

export const IconTransaction = () => (
  <svg className="icon-menu" viewBox="0 0 32 32">
    <path
      d="M26.51 2.147H5.41c-1.782 0-3.23 1.54-3.23 3.435v20.152c0 1.894 1.448 3.435 3.23 3.435h21.1c1.78 0 3.23-1.541 3.23-3.435V5.582c0-1.894-1.45-3.435-3.23-3.435zm-21.1 2.29h21.1c.593 0 1.076.513 1.076 1.145v3.435H4.333V5.582c0-.632.483-1.145 1.076-1.145zm21.1 22.442H5.41c-.594 0-1.077-.514-1.077-1.145V11.307h23.253v14.427c0 .631-.483 1.145-1.076 1.145zM5.947 6.727c0-.633.482-1.145 1.077-1.145s1.077.512 1.077 1.145c0 .632-.482 1.145-1.077 1.145s-1.077-.513-1.077-1.145zm3.768 0c0-.633.482-1.145 1.077-1.145s1.077.512 1.077 1.145c0 .632-.482 1.145-1.077 1.145s-1.077-.513-1.077-1.145zm11.123 6.06l3.776 4.016-3.776 4.015-1.522-1.619 1.177-1.251h-5.664v-2.29h5.664l-1.177-1.252 1.522-1.619zm-9.412 7.45h5.556v2.29h-5.556l1.177 1.252-1.523 1.62-3.775-4.016 3.775-4.016 1.523 1.62-1.177 1.25z"
      fillRule="nonzero"
    />
  </svg>
);

export const IconUserManagemennt = () => (
  <svg className="icon-menu" viewBox="0 0 32 32">
    <path
      d="M28.42 24.319c.025-.186.058-.37.058-.563 0-.193-.034-.376-.058-.562l1.112-.817a.588.588 0 00.162-.778l-.998-1.678a.627.627 0 00-.774-.253l-1.28.523a4.626 4.626 0 00-1.011-.583l-.17-1.32a.612.612 0 00-.613-.525h-1.995a.611.611 0 00-.613.525l-.17 1.32c-.367.148-.7.353-1.01.583l-1.28-.523a.625.625 0 00-.775.253l-.998 1.678a.589.589 0 00.161.778l1.112.817c-.024.186-.057.37-.057.562 0 .193.034.377.057.563l-1.112.817a.588.588 0 00-.161.778l.998 1.68c.156.26.488.37.774.252l1.28-.525c.312.232.644.435 1.011.584l.17 1.32c.039.3.3.525.611.525h1.996a.611.611 0 00.612-.525l.17-1.32c.367-.149.7-.353 1.011-.584l1.28.524c.287.118.619.01.774-.253l.999-1.68a.589.589 0 00-.162-.777l-1.11-.816zM8.33 16.353h10.283c.741 0 1.447.148 2.1.398-.05.092-.1.182-.14.279a3.06 3.06 0 00-2.95 1.273l-.107.165-.979 1.645a2.878 2.878 0 00.246 3.29 2.88 2.88 0 00-.35 3.102l.104.19.14.234H3.491a.9.9 0 01-.9-.77l-.007-.11v-4.114c0-3.005 2.456-5.463 5.52-5.578l.226-.004h10.282H8.33zm15.52 5.006c1.364 0 2.468 1.073 2.468 2.397 0 1.325-1.104 2.398-2.468 2.398-1.364 0-2.469-1.073-2.469-2.398 0-1.324 1.105-2.397 2.469-2.397zM13.392 2.25c3.273 0 5.926 2.684 5.926 5.994 0 3.31-2.653 5.993-5.926 5.993s-5.927-2.683-5.927-5.993c0-3.31 2.654-5.994 5.927-5.994z"
      fillRule="nonzero"
    />
  </svg>
);

export const IconEload = () => (
  <svg className="icon-menu" viewBox="0 0 32 32">
    <path
      d="M21.557 2H9.999C8.893 2 8 2.914 8 4.04v23.922C8 29.087 8.893 30 9.999 30h11.558c1.104 0 1.999-.913 1.999-2.038V4.04c0-1.126-.895-2.04-2-2.04zm-8.631 1.239h5.706c.144 0 .26.219.26.49 0 .272-.116.492-.26.492h-5.706c-.145 0-.26-.22-.26-.492 0-.271.115-.49.26-.49zm2.853 24.748c-.704 0-1.276-.584-1.276-1.302 0-.718.572-1.3 1.276-1.3.701 0 1.274.582 1.274 1.3 0 .718-.573 1.302-1.274 1.302zm6.28-4.458H9.498V5.442h12.56v18.087z"
      fillRule="nonzero"
    />
  </svg>
);

export const IconProduct = () => (
  <svg className="icon-menu" viewBox="0 0 32 32">
    <path
      d="M15.559.75c.023 0 .043.01.065.014a.492.492 0 01.291.142l2.015 2.113c.095.099.148.233.148.373v2.642h4.534c.279 0 .504.237.504.529v15.323c0 1.166-.904 2.114-2.015 2.114H2.963c-1.111 0-2.015-.948-2.015-2.114V6.563c0-.292.225-.529.504-.529h4.534V3.392c0-.14.053-.274.148-.373L8.149.905A.486.486 0 018.698.79c.188.082.311.275.311.489v4.755h6.046V3.921H10.52a.516.516 0 01-.503-.529V1.28c0-.292.225-.529.503-.529zm-2.52 8.455h-2.015a.509.509 0 00-.488.4l-.242 1.012-.853-.537a.487.487 0 00-.616.08L7.4 11.654a.548.548 0 00-.075.646l.511.894-.965.254a.524.524 0 00-.381.512v2.114c0 .242.157.453.38.512l.966.254-.511.894a.548.548 0 00.075.646l1.425 1.494a.488.488 0 00.616.08l.853-.537.242 1.013c.056.234.257.4.488.4h2.016a.509.509 0 00.488-.4l.242-1.013.853.537a.487.487 0 00.615-.08l1.425-1.494a.548.548 0 00.076-.646l-.512-.894.966-.254a.524.524 0 00.381-.512V13.96a.524.524 0 00-.381-.512l-.966-.254.512-.894a.548.548 0 00-.076-.646l-1.425-1.494a.485.485 0 00-.615-.08l-.853.537-.242-1.013a.509.509 0 00-.488-.4zM12.033 12c1.382 0 2.503 1.175 2.503 2.625s-1.12 2.625-2.503 2.625c-1.382 0-2.503-1.175-2.503-2.625S10.65 12 12.032 12z"
      fill="#CFD2DF"
    />
  </svg>
);

export const IconVerified = () => (
  <svg className="icon-verified" viewBox="0 0 510 510">
    <path
      d="M150.45 206.55l-35.7 35.7L229.5 357l255-255-35.7-35.7-219.3 219.3-79.05-79.05zM459 255c0 112.2-91.8 204-204 204S51 367.2 51 255 142.8 51 255 51c20.4 0 38.25 2.55 56.1 7.65l40.801-40.8C321.3 7.65 288.15 0 255 0 114.75 0 0 114.75 0 255s114.75 255 255 255 255-114.75 255-255h-51z"
      fill="#ffffff"
      fillRule="nonzero"
    />
  </svg>
);

export const IconUpload = () => (
  <svg className="icon" viewBox="0 3 32 32">
    <path d="M29.529 20.438A1.619 1.619 0 0028.385 20H21.2a3.123 3.123 0 01-1.187 1.437 3.249 3.249 0 01-1.859.563h-4.308a3.248 3.248 0 01-1.86-.563A3.123 3.123 0 0110.802 20H3.615c-.448 0-.83.146-1.144.438A1.395 1.395 0 002 21.5v5c0 .417.157.771.471 1.063A1.62 1.62 0 003.615 28h24.77c.448 0 .83-.146 1.144-.437.314-.292.471-.646.471-1.063v-5c0-.417-.157-.77-.471-1.062zm-6.31 5.265a1.077 1.077 0 01-.758.297c-.291 0-.543-.099-.757-.297a.929.929 0 01-.32-.703c0-.27.107-.505.32-.703.214-.198.466-.297.757-.297.292 0 .545.1.758.297.213.198.32.432.32.703 0 .271-.107.506-.32.703zM26.769 24c.292 0 .544.1.757.297.214.198.32.432.32.703 0 .271-.106.506-.32.703a1.075 1.075 0 01-.757.297c-.291 0-.544-.099-.757-.297a.929.929 0 01-.32-.703c0-.27.107-.505.32-.703.213-.198.466-.297.757-.297zM15.222 4c.3 0 .55.093.75.28l7.472 6.588c.344.294.422.632.233 1.014-.188.392-.516.589-.984.589h-4.269v6.588c0 .255-.105.475-.317.661-.211.187-.46.28-.75.28h-4.27c-.289 0-.539-.093-.75-.28-.211-.186-.317-.406-.317-.661V12.47H7.751c-.467 0-.795-.196-.984-.589-.189-.382-.111-.72.234-1.014l7.47-6.589c.201-.186.451-.279.751-.279z" />
  </svg>
);

export const Iconedit = () => (
  <svg className="icon-verified" viewBox="0 0 20 20">
    <g fill="#A6A6A6" fillRule="evenodd">
      <circle cx={18} cy={2} r={2} />
      <circle cx={10} cy={2} r={2} />
      <circle cx={2} cy={2} r={2} />
    </g>
  </svg>
);

export const IconEyeOff = () => (
  <svg className="icon-password" viewBox="2 2 24 24">
    <path
      d="M17.81 13.395a8.947 8.947 0 0 0 3.182-5.771 1 1 0 0 0-1.984-.249C18.57 10.867 15.556 13.5 12 13.5s-6.569-2.633-7.008-6.125a1 1 0 1 0-1.984.249 8.947 8.947 0 0 0 3.183 5.771L3.88 15.707a1.003 1.003 0 0 0 0 1.414 1.003 1.003 0 0 0 1.414 0l2.603-2.603c.954.482 2.001.8 3.104.921V19c0 .55.45 1 1 1s1-.45 1-1v-3.561a9.062 9.062 0 0 0 3.103-.921l2.604 2.603a1.003 1.003 0 0 0 1.414 0 1.003 1.003 0 0 0 0-1.414l-2.312-2.312z"
      fill="#FFFFFF"
    />
  </svg>
);

export const IconEyeOn = () => (
  <svg className="icon-password" viewBox="2 2 24 24">
    <path
      d="M12 13.5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5m0-5c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5m.22 8.498c-4.306.1-7.115-3.583-8.047-5.002 1.026-1.605 3.61-4.891 7.608-4.993 4.289-.11 7.114 3.583 8.046 5.002-1.025 1.605-3.61 4.89-7.607 4.993m9.648-5.495c-.638-1.112-4.162-6.686-10.138-6.5-5.528.14-8.743 5.01-9.597 6.5a.999.999 0 0 0 0 .995C2.762 13.595 6.162 19 12.025 19l.246-.003c5.527-.141 8.743-5.01 9.597-6.5a1.004 1.004 0 0 0 0-.994"
      fill="#FFFFFF"
    />
  </svg>
);

export const IconEyeOffLog = () => (
  <svg className="icon-password" viewBox="0 0 24 24">
    <path
      d="M17.81 13.395a8.947 8.947 0 0 0 3.182-5.771 1 1 0 0 0-1.984-.249C18.57 10.867 15.556 13.5 12 13.5s-6.569-2.633-7.008-6.125a1 1 0 1 0-1.984.249 8.947 8.947 0 0 0 3.183 5.771L3.88 15.707a1.003 1.003 0 0 0 0 1.414 1.003 1.003 0 0 0 1.414 0l2.603-2.603c.954.482 2.001.8 3.104.921V19c0 .55.45 1 1 1s1-.45 1-1v-3.561a9.062 9.062 0 0 0 3.103-.921l2.604 2.603a1.003 1.003 0 0 0 1.414 0 1.003 1.003 0 0 0 0-1.414l-2.312-2.312z"
      fill="#000000"
    />
  </svg>
);

export const IconEyeOnLog = () => (
  <svg className="icon-password" viewBox="0 0  24 24">
    <path
      d="M12 13.5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5m0-5c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5m.22 8.498c-4.306.1-7.115-3.583-8.047-5.002 1.026-1.605 3.61-4.891 7.608-4.993 4.289-.11 7.114 3.583 8.046 5.002-1.025 1.605-3.61 4.89-7.607 4.993m9.648-5.495c-.638-1.112-4.162-6.686-10.138-6.5-5.528.14-8.743 5.01-9.597 6.5a.999.999 0 0 0 0 .995C2.762 13.595 6.162 19 12.025 19l.246-.003c5.527-.141 8.743-5.01 9.597-6.5a1.004 1.004 0 0 0 0-.994"
      fill="#000000"
    />
  </svg>
);

export const IconLayoutGrid = () => (
  <svg className="icon-action" viewBox="0 0 32 32">
    <path d="M14.52 17.013c.258 0 .467.209.467.466v12.055a.466.466 0 01-.466.466H2.466A.466.466 0 012 29.534V17.479c0-.257.209-.466.466-.466h12.055zm15.014 0c.257 0 .466.209.466.466v12.055a.466.466 0 01-.466.466H17.479a.466.466 0 01-.466-.466V17.479c0-.257.209-.466.466-.466h12.055zM14.52 2c.257 0 .466.209.466.466v12.055a.466.466 0 01-.466.466H2.466A.466.466 0 012 14.521V2.466C2 2.21 2.209 2 2.466 2h12.055zm15.013 0c.257 0 .466.209.466.466v12.055a.466.466 0 01-.466.466H17.479a.466.466 0 01-.466-.466V2.466c0-.257.209-.466.466-.466h12.055z" />
  </svg>
);

export const IconLayoutTable = () => (
  <svg className="icon-action" viewBox="0 0 32 32">
    <path d="M8.04 21.374V28H2v-6.626h6.04zm21.96 0V28H10.037v-6.626H30zM8.04 12.687v6.626H2v-6.626h6.04zm21.96 0v6.626H10.037v-6.626H30zM8.04 4v6.626H2V4h6.04zM30 4v6.626H10.037V4H30z" />
  </svg>
);
