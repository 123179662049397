import React, { useState, useContext, useEffect } from 'react';
import { ModalContext } from 'App';
import { withRouter, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import ItemsList from '../components/ItemsList';
import CreditDetails from '../components/CreditDetails';
// import OtherItemsList from '../components/OtherItemsList';
import ProductModal from '../components/ProductModal';
import * as c from '../constant';
import * as actions from '../actions';
import CategoryFIlter from '../components/CategoryFIlter';
import PaymentMethod from '../components/PaymentMethod';
import Cart from './Cart';
import PlaceOrder from './PlaceOrder';

function Product({
  getProduct,
  productList,
  getCategory,
  categoryList,
  total,
  getCart,
}) {
  const [is_choose, setChoose] = useState(true);
  const setModal = useContext(ModalContext);
  const history = useHistory();
  const [filter, setFilter] = useState({
    id: 0,
    name: 'All Items',
  });

  const handleIsChoose = value => e => {
    e.preventDefault();
    setChoose(value);
  };

  const handleProductModal = data => e => {
    e.preventDefault();
    setModal('product', data);
  };

  const handleOpenCart = e => {
    e.preventDefault();
    setModal('cart');
  };

  // const handlePlaceOrder = e => {
  //   e.preventDefault();
  //   setModal('summary');
  // }

  const handleOrderDetails = e => {
    e.preventDefault();
    history.push('/purchase-order/order-details');
  };

  const handleSelectCategory = (id, name) => e => {
    e.preventDefault();
    setFilter({ id, name });
    if (id === 0) return getProduct();
    getProduct({ category_id: id });
    return null;
  };

  useEffect(() => {
    getProduct();
    getCategory();
    getCart();
  }, [getProduct, getCategory, getCart]);

  return (
    <div className="main-content-inner">
      <div className="box-fit-wrapper h-100">
        <div className="box-fix-overflow">
          <div className="container-fluid h-100 px-0">
            <div className="row h-100">
              <div className="col-lg-12 h-100">
                <div className="sales-order-wrapper">
                  <div className="mobile-loaded">
                    <div className="card shadow-sm px-0">
                      <span className="text-success font-weight-bold p-2">
                        Order Information
                      </span>
                      <small className="p-2">Date: 05/25/2020</small>
                      <div className="form-group px-2">
                        <label className="form-label text-primary">
                          Promo:
                        </label>
                        <select
                          name="promo"
                          id="promo"
                          className="custom-select"
                        >
                          <option defaultValue>Select Promo</option>
                        </select>
                      </div>
                      <div className="form-group px-2">
                        <label className="form-label text-primary">
                          Dealer DPO No:
                        </label>
                        <input
                          className="form-control input-textbox form-custom form-control_bottom"
                          type="text"
                          placeholder="Input PO Number"
                        />
                      </div>
                      <small className="px-2">Order From:</small>
                      <small className="text-muted p-2">
                        Smart Telecommucanations Inc.
                      </small>
                      <hr />
                      <CategoryFIlter
                        categoryData={categoryList}
                        onSelectCategory={handleSelectCategory}
                        filter={filter}
                      />
                    </div>
                  </div>
                  <div className="offer-list mt-3">
                    <div className="container-fluid d-flex flex-column h-100 pl-0">
                      <div className="card shadow-sm px-0">
                        <div className="card-body">
                          <div className="row px-3">
                            <PaymentMethod
                              handleIsChoose={handleIsChoose}
                              is_choose={is_choose}
                            />
                            <div className="ml-auto">
                              <small className="mr-2">TOTAL PRICE</small>
                              <span className="text-success font-weight-bold">
                                {total || '00'}
                              </span>
                              <div className="text-right">
                                <a href="/" onClick={handleOpenCart}>
                                  <small className="font-weight-bold">
                                    View Summary
                                  </small>
                                </a>
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn-success btn-md ml-3"
                              onClick={handleOrderDetails}
                            >
                              PLACE ORDER
                            </button>
                          </div>
                        </div>
                      </div>
                      {!is_choose && (
                        <div className="row">
                          <CreditDetails />
                        </div>
                      )}
                      <div className="card shadow-sm px-0 mt-3">
                        <ItemsList
                          onProductModal={handleProductModal}
                          data={productList}
                          filter={filter}
                        />
                        {/* <OtherItemsList onProductModal={handleProductModal}/> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductModal />
      <Cart />
      <PlaceOrder />
    </div>
  );
}

Product.propTypes = {
  getProduct: PropTypes.func.isRequired,
  getCategory: PropTypes.func.isRequired,
  getCart: PropTypes.func.isRequired,
  productList: PropTypes.instanceOf(Array).isRequired,
  categoryList: PropTypes.instanceOf(Array).isRequired,
  total: PropTypes.string.isRequired,
};

const mapStateToProps = ({ api }) => ({
  productList: _.get(api, `${c.GET_PRODUCT}.list`) || [],
  total: _.get(api, `${c.GET_CART}.res.subtotal`) || '',
  categoryList: _.get(api, `${c.GET_CATEGORY}.list`) || [],
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(Product);
