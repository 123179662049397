import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import userImg from 'assets/images/default-user.jpg';
import { ToastSuccess } from 'modules/common/components/Toast';
import InfiniteScroll from 'modules/common/components/InfiniteScroll';
import PropTypes from 'prop-types';
import * as actions from '../../action';

const INIT_STATE = {
  note: '',
};

const Notes = ({ data, getNote, createNote }) => {
  const [state, setstate] = useState(INIT_STATE);
  const [newNotes, setNewNotes] = useState({});

  const handleChange = key => e => {
    setstate({
      ...state,
      [key]: e.target.value || '',
    });
  };

  const handleSubmitNote = e => {
    e.preventDefault();
    const payload = {
      note: _.get(state, 'note'),
    };
    createNote(_.get(data, 'id'), payload, res => {
      setNewNotes(_.get(res, 'data'));
      ToastSuccess('Success!');
      setstate(INIT_STATE);
    });
  };

  const handleLoadOptions = (page, callback) => {
    getNote(
      _.get(data, 'id'),
      {
        page,
      },
      res => {
        callback([].concat(res.data) || []);
      }
    );
  };

  return (
    <div>
      <div className="mb-4">
        <div id="notes" className="form-label mb-2">
          Notes:
        </div>
        <InfiniteScroll
          loadOptions={handleLoadOptions}
          addedOption={newNotes}
          h={397}
          containerClassName="chat-list"
          render={item => {
            const { attributes } = item || {};
            return (
              <div
                aria-labelledby="notes"
                className={`${
                  _.get(attributes, 'is_sender') === 1
                    ? 'text-right mb-2'
                    : 'mb-2'
                }`}
              >
                {_.get(attributes, 'is_sender') === 1 ? (
                  <div className="form-label ml-2">
                    <span className="text-primary font-weight-bold">
                      {_.get(attributes, 'created_by') || '--'}
                    </span>{' '}
                    left a note
                  </div>
                ) : (
                  <div className="d-flex align-items-center mb-2">
                    <img
                      src={_.get(attributes, 'created_by_photo') || userImg}
                      alt="user"
                      className="avatar avatar-xs"
                    />
                    <div className="form-label ml-2">
                      <span className="text-primary font-weight-bold">
                        {_.get(attributes, 'created_by') || '--'}
                      </span>{' '}
                      left a note
                    </div>
                  </div>
                )}
                <div className="ml-4 pl-2 mb-2">
                  {_.get(attributes, 'note') || '--'}
                </div>
                <div className="form-label ml-4 pl-2">
                  {moment(_.get(attributes, 'created_at')).format('LLL') ||
                    '--'}
                </div>
              </div>
            );
          }}
        />
        {/* {noteList.map(x => (
            <div
              aria-labelledby="notes"
              className={`${_.get(x, 'attributes.is_sender') === 1 ? 'text-right mb-2' : 'mb-2'}`}
              key={x.id}
            >
              {_.get(x, 'attributes.is_sender') === 1 ? (
                <div className="form-label ml-2">
                  <span className="text-primary font-weight-bold">
                    {_.get(x, 'attributes.created_by') || '--'}
                  </span>{' '}
                  left a note
                </div>
              ) : (
                <div className="d-flex align-items-center mb-2">
                  <img src={_.get(x, 'attributes.created_by_photo') || userImg} alt="user" className="avatar avatar-xs" />
                  <div className="form-label ml-2">
                    <span className="text-primary font-weight-bold">
                      {_.get(x, 'attributes.created_by') || '--'}
                    </span>{' '}
                    left a note
                  </div>
                </div>
              )}
              <div className="ml-4 pl-2 mb-2">
                {_.get(x, 'attributes.note') || '--'}
              </div>
              <div className="form-label ml-4 pl-2">{moment(_.get(x, 'attributes.created_at')).format('LLL') || '--'}</div>
            </div>
          ))} */}
      </div>
      <div className="d-flex flex-column align-items-end">
        <label htmlFor="internal-note" className="sr-only">
          Internal note
        </label>
        <textarea
          name="internal-note"
          id="internal-note"
          rows="3"
          value={_.get(state, 'note') || ''}
          placeholder="Internal note..."
          className="form-control mb-3 p-3"
          onChange={handleChange('note')}
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSubmitNote}
        >
          Add Note
        </button>
      </div>
    </div>
  );
};

Notes.propTypes = {
  getNote: PropTypes.func.isRequired,
  createNote: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object),
};

Notes.defaultProps = {
  data: {},
};

const mapStateToProps = () => ({});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(Notes);
