import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ModalContext } from "App";
import _ from "lodash";

import { removeEmpty } from "helper";
import withModal from "modules/common/hoc/withModal";
import GenericTable from "modules/common/components/GenericTable";
import ButtonAsync from "components/Button";
import { useProfile } from "modules/auth/hooks";
import { ToastSuccess } from "modules/common/components/Toast";

import * as action from "../action";
import * as c from "../constant";

const BatchCreateSetup = ({ csvData, validation }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const setModal = React.useContext(ModalContext);
  const profile = useProfile();
  const role = _.head(profile?.roles);

  const setFilter = useSelector(({ modal }) =>
    _.get(modal, "modalData.setFilter")
  );

  const handleBatchCreateSuccess = (data) => {
    setLoading(false);
    setModal({ isOpen: false });
    ToastSuccess(data?.res?.data?.success?.[0]?.detail);
    setFilter(removeEmpty(c.caSdaInitState));
  };

  const handleBatchCreateError = () => {
    setLoading(false);
    setModal({ isOpen: false });
    ToastSuccess("Error creating users");
  };

  const handleBatchCreate = () => {
    const payload = {
      role_guard_name: "",
    };
    if (role.includes("smmt")) payload.role_guard_name = "smmt-dealer-user";
    else if (role === "super-user-b2b" || role.includes("-b2b"))
      payload.role_guard_name = "b2b-dealer-user";
    else if (role === "super-user" || role.includes("rd"))
      payload.role_guard_name = "rd-dealer-user";
    else if (role === "super-user-ka" || role.includes("-ka"))
      payload.role_guard_name = "ka-dealer-user";
    else if (role.includes("-ska")) payload.role_guard_name = "ska-dealer-user";
    else if (role === "super-user-non-migrated")
      payload.role_guard_name = "non-migrated-dealer-user";
    else payload.role_guard_name = "dealer-user";
    setLoading(true);
    dispatch(
      action.createBatchDealerUser(
        { csv_link: csvData.url, role_guard_name: payload.role_guard_name },
        handleBatchCreateSuccess,
        handleBatchCreateError
      )
    );
  };

  return (
    <>
      <div className="text-sm mb-2">
        File: <strong>{csvData.name}</strong>
      </div>
      <GenericTable
        data={validation.map((item, index) => ({ ...item, index }))}
        format={[
          {
            key: (item) => <span className="text-sm">{item?.first_name}</span>,
            label: "First Name",
          },
          {
            key: (item) => <span className="text-sm">{item?.last_name}</span>,
            label: "Last Name",
          },
          {
            key: (item) => <span className="text-sm">{item?.username}</span>,
            label: "Username",
          },
          {
            key: (item) => <span className="text-sm">{item?.email}</span>,
            label: "Email",
          },
          {
            key: (item) => <span className="text-sm">{item?.mobile}</span>,
            label: "Mobile",
          },
          {
            key: (item) => <span className="text-sm">{item?.role}</span>,
            label: "Role",
          },
          {
            key: (item) => <Errors errors={item.errors} />,
            label: "Remarks",
            className: "lh-1",
          },
        ]}
        headerClassName="bg-gray-600"
        containerClassName="text-sm relative table-sticky border"
        customStyles={{ overflow: "auto", maxHeight: "50vh" }}
      />
      <p className="mt-3">
        <strong>Notice:</strong>
      </p>
      <div className="row">
        <div className="col text-sm">
          <p className="mb-2">
            Onboarding process can still proceed on items with no errors.
          </p>
          <p>
            Conflicting data will be recorded to{" "}
            <strong>&quot;Error Logs&quot;</strong> and will be available for
            download.
          </p>
        </div>
        <div className="col text-sm d-flex justify-content-end align-items-end">
          <p>
            <ButtonAsync
              loading={loading}
              disabled={loading || !validation.length}
              onClick={handleBatchCreate}
            >
              <span className="px-2">Batch Create</span>
            </ButtonAsync>
          </p>
        </div>
      </div>
    </>
  );
};

BatchCreateSetup.propTypes = {
  csvData: PropTypes.instanceOf(Object).isRequired,
  validation: PropTypes.instanceOf(Object).isRequired,
};

const modalKey = "batch-create-setup";
const modalConfig = {
  title: "Batch Create Account Validation",
  size: "modal-xl",
};

export default withModal(modalKey, modalConfig)(BatchCreateSetup);

const Errors = ({ errors }) => {
  if (!Object.keys(errors).length)
    return <div className="text-xs text-success">OK</div>;

  return Object.keys(errors).map((e) => {
    return errors[e].map((error) => (
      <div className="text-xs text-danger mb-1">* {error}</div>
    ));
  });
};

Errors.propTypes = {
  errors: PropTypes.instanceOf(Object).isRequired,
};
