/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from "react";
import _ from "lodash";
import Img from "modules/common/components/Img";
import { rootApi as baseURL } from "helper";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";

const uploadFile = (isMultiple, formdata, onProgress) =>
  new Promise((resolve, reject) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.timeout = 300000;
      xhr.addEventListener("timeout", () =>
        resolve({
          status: 400,
          message: "Request Timeout, Please try again.",
        })
      );
      const url = isMultiple
        ? `${baseURL}api/v1/uploads`
        : `${baseURL}api/v1/upload`;
      xhr.open("POST", url);
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${sessionStorage.getItem("token")}`
      );
      xhr.onload = () => {
        try {
          const jsonResponse = JSON.parse(xhr.response);

          const response = {
            status: xhr.status, // jsonResponse.status,
            data: jsonResponse,
          };
          resolve(_.get(response, "data"));
        } catch (err) {
          reject(err);
        }
      };
      xhr.onerror = () => {
        const errRes = {
          data: false,
          message: "Oops..Something went wrong.",
        };
        return resolve(errRes);
      };

      if (xhr.upload) {
        const progressListener = xhr.addEventListener("progress", (evt) => {
          if (evt.lengthComputable) {
            const progress = Math.ceil((evt.loaded / evt.total) * 100);
            if (onProgress) {
              onProgress(progress);
            }
            if (progress >= 100) xhr.removeEventListener(progressListener);
          } else {
            onProgress(100);
          }
        });
      }
      xhr.send(formdata);
    } catch (err) {
      reject(err);
    }
  });

function UploadPhotoModal({
  isMultiple,
  fileType,
  maxSize,
  onChange,
  caption,
  disabled,
  icon,
  iconClassName,
  buttonStyle,
}) {
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [onLoad, setOnLoad] = useState(false);
  const [sourceFiles, setSourceFiles] = useState();

  const handleOnSetModal = (value) => (e) => {
    e.preventDefault();
    setShowModal(value);
    if (!value) {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      setFiles([]);
    }
  };

  const handleOnConfirm = (e) => {
    e.preventDefault();
    setShowModal(false);
    if (isMultiple) {
      onChange(files);
      return;
    }
    onChange(...files);
  };

  const onProgress = (progress) => {
    if (progress < 100) return;
    setIsLoading(false);
  };

  const handleOnLoaded = () => {
    setOnLoad(true);
  };

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      setIsLoading(true);
      setSourceFiles(acceptedFiles);
      const isFileSizeOver = acceptedFiles.reduce(
        (a, f) => f.size > 1048576 * maxSize || a,
        false
      );
      if (!_.isEmpty(rejectedFiles) || isFileSizeOver) {
        setIsLoading(false);
        setIsFileTooLarge(true);
        return;
      }
      setIsFileTooLarge(false);

      const newFormData = new FormData();

      // eslint-disable-next-line no-unused-expressions
      isMultiple
        ? _.forEach(acceptedFiles, (v, k) =>
            newFormData.append(`files[${k}]`, acceptedFiles[k])
          )
        : newFormData.append("file", ...acceptedFiles);

      uploadFile(isMultiple, newFormData, onProgress).then((data) => {
        if (!data.data) {
          setIsFileTooLarge(true);
          return;
        }
        if (isMultiple) {
          setFiles([...files, ..._.get(data, "data.files")]);
          return;
        }
        setFiles([_.get(data, "data.file")]);
      });
    },
    [files, isMultiple, maxSize]
  );

  const renderIcon = (type) => {
    if (type === "pdf") {
      return <i className="fas fa-3x fa-file-pdf" style={{ color: "black" }} />;
    }
    if (type === "doc" || type === "docx") {
      return (
        <i className="fas fa-3x fa-file-word" style={{ color: "black" }} />
      );
    }
    if (type === "csv") {
      return <i className="fas fa-3x fa-file-csv" style={{ color: "black" }} />;
    }
    if (type === "txt") {
      return <i className="fas fa-3x fa-file-alt" style={{ color: "black" }} />;
    }
    return null;
  };

  const thumbs = () =>
    files.map((item) => {
      const ext = (item.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1];
      if (isMultiple) {
        return (
          <div key={item} className="col-md-3">
            {ext !== "pdf" &&
            ext !== "doc" &&
            ext !== "docx" &&
            ext !== "csv" &&
            ext !== "txt" ? (
              <div>
                <Img
                  src={item}
                  className="attachment-image"
                  role="presentation"
                  alt={item}
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: 5,
                    width: 100,
                    height: 100,
                  }}
                />
              </div>
            ) : (
              <a
                href={item}
                target="_heopenit"
                style={{
                  border: "1px solid lightgray",
                  borderRadius: 5,
                  width: 100,
                  height: 100,
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {renderIcon(ext)}
              </a>
            )}
          </div>
        );
      }
      if (ext === "txt") {
        return (
          <i
            className="fas fa-4x fa-file-alt my-5"
            key={item}
            style={{ color: "black" }}
          />
        );
      }
      return (
        <div key={item} className="d-flex justify-content-center my-3">
          {/* {
          !onLoad
            && (
              <div className="position-absolute align-self-center d-block">
                <i className="fas fa-3x fa-spin fa-spinner" />
              </div>
            )
        } */}
          <div style={{ width: 300 }}>
            <img
              src={item}
              onLoad={handleOnLoaded}
              title={item}
              alt={item}
              className="img-fluid img-thumbnail"
              width="300"
              height="300"
              style={{
                overflowX: "auto",
              }}
            />
          </div>
        </div>
      );
    });

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: 1048576 * maxSize,
  });

  return (
    <>
      <button
        type="button"
        // className="btn btn-success btn-sm btn-block mb-2 text-center mt-2"
        className={`${
          _.isEmpty(iconClassName)
            ? "btn btn-primary btn-sm btn-block mb-2 text-center mt-2 py-2"
            : iconClassName
        }`}
        onClick={handleOnSetModal(true)}
        disabled={disabled}
      >
        {_.isEmpty(icon) ? (
          <>
            <span className="text ml-1">Select File</span>
          </>
        ) : (
          icon
        )}
      </button>

      <div
        className="modal"
        style={{
          display: showModal ? "block" : "none",
        }}
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between">
              <h5 className="m-0 p-0">Upload File</h5>
              <button
                type="button"
                className="close"
                onClick={handleOnSetModal(false)}
              >
                <span>&times;</span>
                <span className="sr-only">Close</span>
              </button>
            </div>
            <div className="modal-body">
              {isMultiple && !_.isEmpty(files) && (
                <div className="img-content border border-light rounded-sm mb-3 p-2">
                  <div className="row">{thumbs(files, isMultiple)}</div>
                </div>
              )}
              <div className="drapzone-container bg-light text-center">
                <div
                  {...getRootProps()}
                  style={{
                    cursor: "pointer",
                    borderStyle: "dashed",
                    outline: "none",
                  }}
                  className="pb-3"
                >
                  {!isMultiple ? (
                    <>
                      {files.length > 0 ? (
                        thumbs(files, isMultiple)
                      ) : (
                        <i className="fas fa-image fa-4x my-5" />
                      )}
                    </>
                  ) : (
                    <i className="fas fa-image fa-4x my-5" />
                  )}
                  <input
                    {...getInputProps()}
                    multiple={isMultiple}
                    accept={fileType}
                  />
                  <div>
                    {isDragActive ? (
                      "Drop your files here"
                    ) : (
                      <span className="text-center">
                        {!files.length ? (
                          <React.Fragment>
                            {isLoading ? (
                              <label className="mb-0">
                                {isMultiple ? (
                                  `Uploading...`
                                ) : (
                                  <div className="position-relative">
                                    <i className="fas fa-spin fa-spinner" />{" "}
                                    {_.get(sourceFiles, "0.path")}
                                  </div>
                                )}
                              </label>
                            ) : (
                              <label className="mb-0">{caption}</label>
                            )}
                          </React.Fragment>
                        ) : (
                          <label className="mb-0">
                            {isMultiple ? (
                              <React.Fragment>
                                Uploaded {files.length} files
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-check text-success" />{" "}
                                {_.get(sourceFiles, "0.path")}
                              </React.Fragment>
                            )}
                          </label>
                        )}
                      </span>
                    )}
                  </div>
                  {isFileTooLarge && (
                    <div className="font-weight-bold text-warning">
                      File size is too large or file format is invalid.
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  onClick={handleOnConfirm}
                  disabled={_.isEmpty(files)}
                  className="mt-3 btn btn-success px-5 mr-1"
                  style={buttonStyle}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        key="modal-backdrop"
        className={`modal-backdrop ${showModal ? "show" : ""}`}
        style={{
          display: showModal ? "block" : "none",
        }}
      />
    </>
  );
}

UploadPhotoModal.defaultProps = {
  isMultiple: false,
  fileType: "image/*",
  maxSize: 10,
  caption:
    "Maximum size of image is 1MB and a minimum resolution of 380x251 pixels. JPG.PNG.",
  disabled: false,
  icon: {},
  iconClassName: "",
  buttonStyle: {},
};

UploadPhotoModal.propTypes = {
  isMultiple: PropTypes.bool,
  fileType: PropTypes.string,
  maxSize: PropTypes.number,
  onChange: PropTypes.instanceOf(Function).isRequired,
  caption: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.instanceOf(Object),
  iconClassName: PropTypes.string,
  buttonStyle: PropTypes.instanceOf(Object),
};

export default React.memo(UploadPhotoModal);
