import React from 'react';
import * as Icon from 'components/Icons';

const NoRoute = () => {
  return (
    <div className="d-flex align-items-center justify-content-center h-100">
      <div className="d-flex flex-column align-items-center">
        <Icon.Brand className="d-block" style={{ opacity: 0.25 }} />
        <p className="my-2 text-xl text-gray-400 font-weight-bold">
          Selection is empty
        </p>
        <p className="text-gray-400">
          Choose an item from the list to view details
        </p>
      </div>
    </div>
  );
};

export default NoRoute;
