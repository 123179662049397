import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToastSuccess } from 'modules/common/components/Toast';
import userImg from 'assets/images/default-user.jpg';
import _ from 'lodash';
import * as actions from '../../action';
import * as c from '../../constant';

const Assignee = ({
  updateTicketbyStatus,
  getTicket,
  assigneeList,
  getAssignee,
  data,
  isLoading,
  pager,
  statusOption,
}) => {
  const canLoadMore = pager.current_page < pager.total_pages;
  const [selectStatus, setStatus] = useState({ status: '' });

  const handleChangSelect = key => e => {
    setStatus({
      ...selectStatus,
      [key]: e.target.value || '',
    });
    updateTicketbyStatus(_.get(data, 'id'), { [key]: e.target.value }, () => {
      ToastSuccess('Success!');
      getTicket({ _reset: true });
    });
  };

  const handleLoadMore = () => {
    const newFilter = { page: pager.current_page + 1 };
    getAssignee(_.get(data, 'id'), newFilter);
  };

  useEffect(() => {
    getAssignee(_.get(data, 'id'), { _reset: true });
    setStatus({
      ...selectStatus,
      status: _.get(data, 'attributes.status').toLowerCase() || 'open',
    });
  }, [getAssignee, data, selectStatus]);

  return (
    <>
      <div className="form-group mb-4">
        <label htmlFor="status" className="form-label">
          Status:
        </label>
        <select
          name="status"
          id="status"
          className="custom-select"
          onChange={handleChangSelect('status')}
          value={_.get(selectStatus, 'status')}
        >
          {statusOption.map(x => (
            <option key={_.get(x, 'id')} value={_.get(x, 'attributes.value')}>
              {_.get(x, 'attributes.value')}
            </option>
          ))}
        </select>
      </div>
      <div id="assignee" className="form-label mb-2">
        Assignee:
      </div>
      {assigneeList.length > 0 ? (
        <div
          style={{
            height: 230,
            overflowY: 'auto',
          }}
        >
          <ul aria-labelledby="assignee" className="p-0 mb-4">
            {assigneeList.map(x => (
              <li className="d-flex align-items-center mb-2" key={x.id}>
                <img
                  src={_.get(x, 'attributes.assignee_photo') || userImg}
                  alt="user"
                  className="avatar avatar-xs"
                />
                <span className="ml-2">
                  {_.get(x, 'attributes.assignee_name')}
                </span>
              </li>
            ))}
          </ul>
          {canLoadMore && (
            <div className="px-1">
              <button
                className="btn btn-outline-success btn-block btn-sm"
                type="button"
                onClick={handleLoadMore}
                disabled={isLoading}
              >
                {isLoading ? (
                  <small>
                    <span className="spinner-border spinner-border-sm" />{' '}
                    Loading...
                  </small>
                ) : (
                  <small>
                    <i className="fa fa-arrow-down mr-1" /> Load More
                  </small>
                )}
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="text-center mt-1 mb-2">
          <span className="badge badge-warning">
            There are no Assignee Found.
          </span>
        </div>
      )}
    </>
  );
};

Assignee.propTypes = {
  getTicket: PropTypes.func.isRequired,
  updateTicketbyStatus: PropTypes.func.isRequired,
  getAssignee: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  assigneeList: PropTypes.instanceOf(Array).isRequired,
  statusOption: PropTypes.instanceOf(Array).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  pager: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ api }) => ({
  assigneeList: _.get(api, `${c.GET_ASSIGNEE}.list`) || [],
  pager: _.get(api, `${c.GET_ASSIGNEE}.pager`) || {},
  isLoading: api.loading.indexOf(c.GET_ASSIGNEE) > -1,
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(Assignee);
