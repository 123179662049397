import React, { Fragment, useContext } from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Panel from "components/Panel";
import PanelRow from "components/PanelRow";
import Button from "components/PanelButton";
import * as Icon from "components/Icons";
import DataField from "components/DataField";
import Actions from "components/Actions";
import { ToastSuccess, ToastError } from "modules/common/components/Toast";
import ErrorMessage from "modules/common/components/ErrorMessage";
import ConfirmAlert from "modules/common/components/ConfirmAlert";
import { ModalContext } from "App";
import { renderStatus } from "modules/ui/hooks";
import AgentMinSetup from "./AgentMinSetup";
import { getAgentMins, deleteAgentMin } from "../actions";

const AddIcon = () => <Icon.Add fill="#fff" />;

const AgentMinPanel = () => {
  const urlParams = useParams();
  const setModal = useContext(ModalContext);
  const agentId = _.get(urlParams, "agentId");
  const queryClient = useQueryClient();

  const agentMinQuery = useQuery({
    queryKey: ["agent-mins", { agentId, is_active: true }],
    queryFn: getAgentMins,
    retry: false,
    keepPreviousData: true,
  });

  const handleAddMin = () => {
    setModal("agent-min-setup");
  };

  const errorCallback = (err, defaultMessage) => {
    const errorMessage = (
      <ErrorMessage error={err} defaultMessage={defaultMessage} />
    );
    ToastError(errorMessage);
    setModal({ isOpen: false });
  };

  const { mutate: removeSim } = useMutation(deleteAgentMin, {
    onSuccess: () => {
      queryClient.invalidateQueries("agent-mins");
      ToastSuccess(`Successfully removed agent min`);
    },
    onError: (err) => errorCallback(err, "Failed to remove MIN."),
  });

  const handleRemoveMin = (simId) => {
    const onConfirm = () => removeSim({ agentId, simId });
    const onReject = () => {};
    const message = "This will remove the MIN assigned to this agent.";
    const yesLabel = "Yes";
    const noLabel = "Cancel";
    const header = "Remove";
    const newButtons = true;
    const center = false;
    const sub_text = "Are you sure you want to proceed?";

    ConfirmAlert(message, onConfirm, onReject, {
      yesLabel,
      noLabel,
      header,
      newButtons,
      center,
      sub_text,
    });
  };

  const agentMin = agentMinQuery?.data?.data;

  return (
    <Panel
      title="Sub-Dealer MIN"
      renderActions={() =>
        _.isEmpty(_.get(agentMin, "attributes")) && (
          <div className="btn-group">
            <Button iconLeft={AddIcon} label="Assign" onClick={handleAddMin} />
          </div>
        )
      }
    >
      {_.isEmpty(agentMin) ? (
        <PanelRow>
          <div className="col text-center text-gray-400 text-xs">
            <span>No MIN assigned to this agent yet.</span>
          </div>
        </PanelRow>
      ) : (
        <Fragment>
          <PanelRow key={agentMin?.id}>
            <DataField
              label="SIM Type"
              value={_.get(agentMin, "attributes.type")}
              className="col-md mb-2 mb-md-0 order-1"
            />
            <DataField
              label="SIM Number"
              value={_.get(agentMin, "attributes.mobile_identification_number")}
              className="col-md mb-2 mb-md-0 order-1"
            />
            <DataField
              label="Status"
              value={renderStatus[+_.get(agentMin, "attributes.is_active")]}
              className="col-md mb-2 mb-md-0 order-1"
            />
            <div className="col d-flex align-items-center justify-content-end order-md-1">
              <Actions label="Actions" id={agentMin?.id}>
                <button
                  type="button"
                  className="btn btn-sm text-gray-600 w-100 text-left"
                  onClick={() => handleRemoveMin(agentMin?.id)}
                >
                  Remove
                </button>
              </Actions>
            </div>
          </PanelRow>
        </Fragment>
      )}
      <AgentMinSetup />
    </Panel>
  );
};

export default AgentMinPanel;
