import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { join } from 'helper';
import { useOnClickOutside } from 'hooks';

const Dialog = ({
  show,
  title,
  children,
  windowClass = '',
  showCloseButton,
  renderFooter,
  cancelText,
  confirmText,
  onClose,
  onCancel,
  onConfirm,
  onClickOutside = () => {},
}) => {
  const focusRef = useRef();
  const contentRef = useRef();
  const [mounted, setMounted] = useState(false);
  const [shown, setShown] = useState(false);

  useOnClickOutside(contentRef, onClickOutside);

  const showModal = () => {
    setMounted(true);
    setTimeout(() => {
      setShown(true);
      if (focusRef.current) {
        focusRef.current.focus();
      }
    }, 200);
  };

  const hideModal = () => {
    setShown(false);
    setTimeout(() => {
      return setMounted(false);
    }, 200);
  };

  useEffect(() => {
    if (!show) return hideModal();
    return showModal();
  }, [show]);

  if (!mounted) return null;

  return ReactDOM.createPortal(
    <React.Fragment>
      <div
        className={join`modal fade d-block ${shown ? `show` : ``}`}
        role="dialog"
      >
        <div className={join`modal-dialog ${windowClass}`} role="document">
          <div className="modal-content" ref={contentRef}>
            <div className="modal-header">
              <span className="modal-title mt-0">{title}</span>
              {showCloseButton ? (
                <button
                  type="button"
                  className="close"
                  onClick={() => onClose()}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              ) : null}
            </div>
            <div className="modal-body">{children}</div>
            <div className="modal-footer border-0">
              {renderFooter || typeof renderFooter === 'function' ? (
                renderFooter(focusRef)
              ) : (
                <React.Fragment>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={onCancel}
                    ref={focusRef}
                  >
                    {cancelText || `Cancel`}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={onConfirm}
                  >
                    {confirmText || `Confirm`}
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </React.Fragment>,
    document.querySelector('body')
  );
};

Dialog.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  showCloseButton: PropTypes.bool,
  renderFooter: PropTypes.func,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onClickOutside: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
};

export default Dialog;
