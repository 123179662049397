import React from 'react';
import PropTypes from 'prop-types';

const Step = ({ active, done, stepNumber, children }) => {
  let classNames = 'mdl-stepper-step';

  if (done) {
    classNames += ' step-done';
  }

  if (active) {
    classNames += ' active-step';
  }

  return (
    <div className={classNames}>
      <div className="mdl-stepper-circle">
        <span className="mdl-stepper-number">
          {!done ? stepNumber : <i className="fa fa-check" />}
        </span>
      </div>
      <div className="mdl-stepper-title">{children}</div>
      <div className="mdl-stepper-bar-left" />
      <div className="mdl-stepper-bar-right" />
    </div>
  );
};

Step.propTypes = {
  active: PropTypes.bool,
  done: PropTypes.bool,
  stepNumber: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

Step.defaultProps = {
  active: false,
  done: false,
  stepNumber: 0,
};

export default Step;
