import React, { useState, useContext, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import _ from "lodash";

import { ModalContext } from "App";
import { ToastSuccess, ToastError } from "modules/common/components/Toast";
import withModal from "modules/common/hoc/withModal";

import ButtonAsync from "components/Button";
import AccountInfoForm from "modules/UserAccountManagement/forms/AccountInfoForm";
import TemporaryAccountForm from "modules/UserAccountManagement/forms/TemporaryAccountForm";

import { updateAttachUsers } from "../actions";

const temporaryFormFields = {
  email: "",
  mobile: "",
};

const form = {
  first_name: "",
  last_name: "",
  username: "",
  role_id: "",
};

function UpdateBranchAdmin() {
  const queryClient = useQueryClient();
  const setModal = useContext(ModalContext);
  const [pending, setPending] = useState(false);
  const modalData = useSelector(
    ({ modal }) => _.get(modal, "modalData", {}) || {}
  );
  const { temporaryData, userInfoData } = modalData;
  const [formData, setformData] = useState(userInfoData || form);
  const [temporaryForm, setTemporaryForm] = useState(
    temporaryData || temporaryFormFields
  );

  const closeModal = () => setModal({ isOpen: false });

  const handleChange = (key) => ({ target }) => {
    setformData({
      ...formData,
      [key]: target.value,
    });
  };

  const handleChangeInput = (value) => {
    setTemporaryForm({
      ...temporaryForm,
      ...value,
    });
  };

  const isFormEmpty = useMemo(
    () => _.isEmpty(formData?.first_name) || _.isEmpty(formData?.last_name),
    [formData]
  );

  const onSuccess = () => {
    queryClient.invalidateQueries("attached_users_ba");
    ToastSuccess("Successfully updated user account.");
    setPending(false);
    closeModal();
  };

  const onError = (errors) => {
    setPending(false);
    _.forEach(errors?.response?.data?.errors, (err) => ToastError(err?.detail));
  };

  const { mutate: updateAttachUsersApi } = useMutation(updateAttachUsers, {
    onSuccess,
    onError,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);
    const payload = {
      ...temporaryForm,
      first_name: _.get(formData, "first_name"),
      last_name: _.get(formData, "last_name"),
      username: _.get(formData, "username"),
      role_id: _.get(formData, "role_id"),
      was_updated: true,
      id: formData.id,
    };
    updateAttachUsersApi(payload);
  };

  return (
    <form onSubmit={handleSubmit} className="container-fluid">
      <AccountInfoForm
        onChange={handleChange}
        formData={formData}
        type="update"
      />
      <TemporaryAccountForm
        onChange={handleChangeInput}
        temporaryForm={temporaryForm}
        type="update"
      />
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold mr-2"
            >
              Close
            </button>
            <ButtonAsync
              type="submit"
              loading={pending}
              disabled={pending || isFormEmpty}
            >
              Save
            </ButtonAsync>
          </div>
        </div>
      </div>
    </form>
  );
}

const modalKey = "update-ba";
const modalConfig = {
  title: "Update Account",
  size: "modal-lg",
};

export default withModal(modalKey, modalConfig)(UpdateBranchAdmin);
