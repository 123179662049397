import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactInputMask from 'react-input-mask';
import DateInputPicker from 'modules/common/components/DateInputPicker';

const OnlineCash = ({ formData, onChangeInput, onSelected }) => {
  return (
    <Fragment>
      <div className="form-group">
        <label className="font-weight-bold">Mobile Number:</label>
        <ReactInputMask
          type="text"
          placeholder="ex. 09280000000"
          mask="99999999999"
          maskChar=""
          className="form-control input-textbox form-custom form-control_bottom"
          name="mobile_number"
          onChange={onChangeInput('mobile_number')}
          value={_.get(formData, 'mobile_number') || ''}
        />
      </div>
      <div className="form-group">
        <label className="font-weight-bold">Card Holder Name:</label>
        <input
          className="form-control input-textbox form-custom form-control_bottom"
          type="text"
          onChange={onChangeInput('card_holder')}
          value={_.get(formData, 'card_holder') || ''}
        />
      </div>
      <div className="form-group">
        <label className="font-weight-bold">Expiry Date:</label>
        <DateInputPicker
          dateFormat="yyyy/MM/dd"
          placeholder="Select Date"
          className="form-control form-custom form-control_bottom w-100"
          onChange={onSelected('expiry_date')}
          value={_.get(formData, 'expiry_date')}
        />
      </div>
      <div className="form-group">
        <label className="font-weight-bold">Amount Paid:</label>
        <input
          className="form-control input-textbox form-custom form-control_bottom"
          type="text"
          onChange={onChangeInput('amount_paid')}
          value={_.get(formData, 'amount_paid') || ''}
        />
      </div>
      <div className="form-group">
        <label className="font-weight-bold">Reference Number:</label>
        <input
          className="form-control input-textbox form-custom form-control_bottom"
          type="text"
          onChange={onChangeInput('ref')}
          value={_.get(formData, 'ref') || ''}
        />
      </div>
      {/* <button type="button" className="btn btn-blue mt-2">
        Attachment
      </button> */}
    </Fragment>
  );
};

OnlineCash.propTypes = {
  formData: PropTypes.instanceOf(Object).isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
};

export default OnlineCash;
