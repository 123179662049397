import React, { Fragment } from 'react';
import GenericTable from 'modules/common/components/GenericTable';
import imgThumb from 'assets/images/logo-loader.png';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { formatNumber } from 'helper';
import { ToastSuccess } from 'modules/common/components/Toast';
import ConfirmAlert from 'modules/common/components/ConfirmAlert';

const PurchaseOrders = ({ data, removeItemById, getCart }) => {
  const history = useHistory();

  const handleAddOrder = e => {
    e.preventDefault();
    history.push('/purchase-order/product');
  };

  const renderTotal = item => {
    const parsePrice = str => str.replace('₱', '');
    const firstParseResult = parsePrice(`${_.get(item, 'attributes.price')}`);
    const secondParse = str => str.replace('.00', '');
    const secondParseResult = secondParse(`${firstParseResult}`);
    const thirdParse = str => str.replace(',', '');
    const finalParseResult = thirdParse(`${secondParseResult}`);
    const total =
      `${finalParseResult}` * `${_.get(item, 'attributes.quantity')}`;
    return formatNumber(total);
  };

  const handleRemoveItem = args => e => {
    e.preventDefault();
    ConfirmAlert(
      `Are you sure you want to remove this Item?`,
      () => {
        removeItemById(args.id, () => {
          getCart();
          ToastSuccess('Successfully removed');
        });
      },
      () => {},
      {
        yesLabel: 'Yes',
        noLabel: 'Cancel',
      }
    );
  };

  const renderButton = args => (
    <button
      type="button"
      className="btn btn-danger btn-sm"
      onClick={handleRemoveItem(args)}
    >
      <i className="fas fa-trash-alt" />
    </button>
  );

  return (
    <Fragment>
      <div className="card-header bg-white border-0">
        <span className="justify-content-center font-weight-bold">
          Purchase Orders ({data.length || 0})
        </span>
        <button
          type="button"
          className="btn btn-success float-right"
          onClick={handleAddOrder}
        >
          + Add Order
        </button>
      </div>
      <div className="card-body shadow-sm p-0">
        <div className="col-12 px-0 bg-white border">
          <GenericTable
            format={[
              {
                key: item => (
                  <span>
                    <img
                      src={_.get(item, 'attributes.primary_photo') || imgThumb}
                      alt="..."
                      style={{ height: 70, objectFit: 'contain' }}
                    />
                  </span>
                ),
                label: 'Item',
                width: 'auto',
              },
              {
                key: item => (
                  <span>
                    <div>{_.get(item, 'attributes.description') || '--'}</div>
                    {/* <small className="text-muted">Category: {item.category}</small> */}
                  </span>
                ),
                label: 'Details',
                width: 'auto',
              },
              {
                key: item => (
                  <span>{_.get(item, 'attributes.price') || '--'}</span>
                ),
                label: 'Item Price',
                width: 'auto',
              },
              {
                key: item => (
                  <span>{_.get(item, 'attributes.quantity') || '--'}</span>
                ),
                label: 'Quantity',
                width: 'auto',
              },
              {
                key: item => <span>{item.discount || '--'}</span>,
                label: 'Discount',
                width: 'auto',
              },
              {
                key: item => (
                  <span className="text-success">₱ {renderTotal(item)}</span>
                ),
                label: 'Total Amount',
                width: 'auto',
              },
              {
                key: item => (
                  <span className="text-success">{renderButton(item)}</span>
                ),
                label: 'Action',
                width: 'auto',
              },
            ]}
            data={data}
            is_striped={false}
            headerClassName="bg-card-header"
            className="border-bottom "
            height="80vh"
          />
        </div>
      </div>
    </Fragment>
  );
};

PurchaseOrders.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  removeItemById: PropTypes.func.isRequired,
  getCart: PropTypes.func.isRequired,
};

export default PurchaseOrders;
