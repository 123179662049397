import React, {
  useState,
  useMemo,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { ToastSuccess } from "modules/common/components/Toast";
import ReactInputMask from "react-input-mask";
import Select from "react-select";

import withModal from "modules/common/hoc/withModal";
import { ModalContext } from "App";
import { removeEmpty, middleNameValidator } from "helper";
import { useLoading } from "modules/ui/hooks";

import FormInputBorderBottom from "modules/common/components/input/FormInputBorderBottom";
import ButtonAsync from "components/Button";
import Calendar from "../../components/Calendar";

import * as c from "../constants";
import * as actions from "../actions";

const AddRetailer = () => {
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);

  const selectorApi = useSelector(({ api }) => ({
    accountTypeList: _.get(api, `${c.GET_RETAILER_TYPES}.list`, []) || [],
    provinceList: _.get(api, `${c.GET_PROVINCE_LIST}.list`, []) || [],
    cityList: _.get(api, `${c.GET_CITY_LIST}.list`, []) || [],
    barangayList: _.get(api, `${c.GET_BARANGAY_LIST}.list`, []) || [],
    userProfile: _.get(api, "AUTH/user_profile.item", {}) || {},
  }));

  const {
    accountTypeList,
    provinceList,
    cityList,
    barangayList,
    userProfile,
  } = selectorApi;
  const userFullName = _.get(userProfile, "full_name", "") || "";

  const [initialData, setInitialData] = useState(
    c.addUpdateRetailerDefaultValue
  );
  const [pending, setPending] = useState(false);

  const modalData = useSelector(
    ({ modal }) => _.get(modal, "modalData.data", []) || {}
  );
  const { is_mobile_app_registered } = modalData;

  useEffect(() => {
    const { min = "", attributes = {} } = modalData;
    if (_.isEmpty(attributes)) {
      setInitialData((state) => ({
        ...state,
        mobile_identification_number: min,
      }));
      return;
    }
    const { line, postal_code } = attributes?.address;
    setInitialData((state) => ({
      ...state,
      mobile_identification_number: min,
      line,
      postal_code,
    }));
  }, [modalData]);

  const provinceOptions = useMemo(
    () =>
      provinceList.map(({ attributes: { name, code } }) => {
        if (
          _.get(modalData, "attributes.address.province") &&
          _.toLower(name) ===
            _.toLower(_.get(modalData, "attributes.address.province"))
        ) {
          setInitialData((state) => ({
            ...state,
            province: {
              value: code,
              label: name,
            },
          }));
        }
        return {
          value: code,
          label: name,
        };
      }),
    [provinceList]
  );

  const cityOptions = useMemo(
    () =>
      cityList.map(({ attributes: { name, code } }) => {
        if (
          _.get(modalData, "attributes.address.city_municipality") &&
          _.toLower(name) ===
            _.toLower(_.get(modalData, "attributes.address.city_municipality"))
        ) {
          setInitialData((state) => ({
            ...state,
            city_municipality: {
              value: code,
              label: name,
            },
          }));
        }
        return {
          value: code,
          label: name,
        };
      }),
    [cityList]
  );

  const barangayOptions = useMemo(
    () =>
      barangayList.map(({ attributes: { name, code, zip } }) => {
        if (
          _.get(modalData, "attributes.address.barangay") &&
          _.toLower(name) ===
            _.toLower(_.get(modalData, "attributes.address.barangay"))
        ) {
          setInitialData((state) => ({
            ...state,
            barangay: {
              value: code,
              label: name,
              zip,
            },
          }));
        }
        return { value: code, label: name, zip };
      }),
    [barangayList]
  );

  const newAccountTypeList = useMemo(
    () =>
      accountTypeList.map(({ id, attributes: { title } }) => {
        if (
          _.get(modalData, "attributes.retailer_type") &&
          _.toLower(title) ===
            _.toLower(_.get(modalData, "attributes.retailer_type"))
        ) {
          setInitialData((state) => ({
            ...state,
            retailer_type: {
              value: id,
              label: title,
            },
          }));
        }
        return {
          value: id,
          label: title,
        };
      }),
    [accountTypeList]
  );

  const resetCityBarangay = useCallback(() => {
    dispatch(actions.resetCity());
    dispatch(actions.resetBarangay());
  }, []);

  const isInputEmpty = useMemo(() => {
    const toRemove = is_mobile_app_registered
      ? ["email", "first_name", "last_name", "birth_date", "middle_name"]
      : ["middle_name", "birth_date", "email"];
    const removedFields = _.omit(initialData, toRemove);
    return Object.values(removedFields).filter((y) => y === "").length > 0;
  }, [initialData]);

  const handleCloseModal = () => setModal({ isOpen: false });

  const handleProvince = (value) => {
    setInitialData({
      ...initialData,
      province: value,
      city_municipality: "",
      created_by_name: userFullName,
      barangay: "",
      postal_code: "",
    });

    if (value) resetCityBarangay();
  };

  const handleMunicipality = (value) => {
    setInitialData({
      ...initialData,
      city_municipality: value,
      barangay: "",
      postal_code: "",
    });
  };

  const handleBarangay = (value) =>
    setInitialData({
      ...initialData,
      barangay: value,
      postal_code: _.get(value, "zip"),
    });

  const handleChangeAccountType = (selectedItem) =>
    setInitialData({ ...initialData, retailer_type: selectedItem });

  const handleChangeOtherFields = (e) => {
    const { name, value } = e.target;
    setInitialData({
      ...initialData,
      [name]: value,
    });
  };

  const handleMiddleName = ({ target: { value } }) => {
    if (middleNameValidator(value) || value === "") {
      setInitialData({
        ...initialData,
        middle_name: value,
      });
    }
  };

  const handleSelectDate = ({ target }) =>
    setInitialData({
      ...initialData,
      birth_date: _.get(target, "value"),
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);

    const payload = {
      ...initialData,
      retailer_type: _.get(initialData, "retailer_type.label"),
      barangay: _.get(initialData, "barangay.label"),
      city_municipality: _.get(initialData, "city_municipality.label"),
      province: _.get(initialData, "province.label"),
    };

    const successCallback = () => {
      dispatch(
        actions.getRetailerList(
          removeEmpty(c.compoundSearchRetailerDefaultValue)
        )
      );
      ToastSuccess("Successfully created retailer.");
      setPending(false);
      handleCloseModal();
      setPending(false);
    };

    const errCallback = () => setPending(false);

    dispatch(
      actions.addRetailer(removeEmpty(payload), successCallback, errCallback)
    );
  };

  useEffect(() => {
    if (_.get(initialData, "province.value"))
      dispatch(actions.getCityList(_.get(initialData, "province.value")));
  }, [initialData.province]);

  useEffect(() => {
    const provinceCode = _.get(initialData, "province.value", "");
    const cityCode = _.get(initialData, "city_municipality.value", "");

    if (provinceCode && cityCode)
      dispatch(actions.getBarangayList(provinceCode, cityCode));
  }, [initialData.province, initialData.city_municipality]);

  useEffect(() => {
    dispatch(actions.getRetailerTypes());
    dispatch(actions.getProvinceList());

    return () => resetCityBarangay();
  }, []);

  return (
    <div className="add-modal-container p-3">
      <form onSubmit={handleSubmit}>
        <div className="row mb-2">
          <div className="col">
            <FormInputBorderBottom
              label="Retailer Mobile No. *"
              name="mobile_identification_number"
              value={_.get(initialData, "mobile_identification_number", "")}
              disabled
            />
          </div>

          <div className="col">
            <label
              className="font-weight-bold"
              style={c.labelStyles}
              htmlFor="retailer_type"
            >
              Retailer Type: *
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              name="retailer_type"
              placeholder="Set here..."
              id="retailer_type"
              required
              options={newAccountTypeList}
              styles={c.selectOptionStyles}
              onChange={handleChangeAccountType}
              value={_.get(initialData, "retailer_type", "")}
              isLoading={false}
            />
          </div>
          {!is_mobile_app_registered && (
            <div className="col">
              <label
                className="font-weight-bold"
                style={{ ...c.labelStyles, marginBottom: ".3rem" }}
                htmlFor="account_type_id"
              >
                Birthdate:
              </label>
              <Calendar
                onChange={handleSelectDate}
                value={_.get(initialData, "birth_date", "")}
              />
            </div>
          )}
        </div>
        {!is_mobile_app_registered && (
          <div className="row mb-2">
            <div className="col">
              <FormInputBorderBottom
                label="First Name *"
                name="first_name"
                value={_.get(initialData, "first_name", "")}
                onChange={handleChangeOtherFields}
              />
            </div>

            <div className="col">
              <FormInputBorderBottom
                label="Middle Initial"
                name="middle_name"
                value={_.get(initialData, "middle_name", "")}
                onChange={handleMiddleName}
                required={false}
              />
            </div>

            <div className="col">
              <FormInputBorderBottom
                label="Last Name *"
                name="last_name"
                value={_.get(initialData, "last_name", "")}
                onChange={handleChangeOtherFields}
              />
            </div>
          </div>
        )}
        {!is_mobile_app_registered && (
          <div className="row pb-2">
            <div className="col-4">
              <FormInputBorderBottom
                label="Email"
                name="email"
                value={_.get(initialData, "email", "")}
                onChange={handleChangeOtherFields}
                required={false}
              />
            </div>
          </div>
        )}
        <div className="mt-4 mb-2">
          <h6 className="h6 m-0 text-xs font-weight-bold">Selling Location</h6>
        </div>

        <div className="row mb-2">
          <div className="col">
            <FormInputBorderBottom
              label="House No. / Bdlg / Street No. *"
              name="line"
              value={_.get(initialData, "line", "")}
              onChange={handleChangeOtherFields}
              styles={c.selectOptionStyles}
            />
          </div>

          <div className="col-4 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Province *
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="province"
              required
              isClearable
              styles={c.selectOptionStyles}
              options={provinceOptions}
              value={
                _.get(initialData, "province.value")
                  ? _.get(initialData, "province")
                  : ""
              }
              onChange={handleProvince}
              isLoading={useLoading(c.GET_PROVINCE_LIST)}
              isDisabled={_.isEmpty(provinceOptions)}
            />
          </div>

          <div className="col-4 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              City / Municipality *
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="city_municipality"
              required
              isClearable
              options={cityOptions}
              styles={c.selectOptionStyles}
              value={
                _.get(initialData, "city_municipality.value")
                  ? _.get(initialData, "city_municipality", "")
                  : ""
              }
              onChange={handleMunicipality}
              isLoading={useLoading(c.GET_CITY_LIST)}
              isDisabled={
                _.isEmpty(_.get(initialData, "province.value")) &&
                _.isEmpty(cityOptions)
              }
            />
          </div>
        </div>

        <div className="row mb-4 pb-2">
          <div className="col-4 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Barangay *
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="barangay"
              required
              isClearable
              options={barangayOptions}
              onChange={handleBarangay}
              styles={c.selectOptionStyles}
              value={
                _.get(initialData, "barangay.value")
                  ? _.get(initialData, "barangay")
                  : ""
              }
              isLoading={useLoading(c.GET_BARANGAY_LIST)}
              isDisabled={_.isEmpty(barangayOptions)}
            />
          </div>

          <div className="col-4">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              ZIP Code *
            </label>
            <ReactInputMask
              type="text"
              disabled
              required
              mask="9999999999"
              maskChar=""
              className="form-control form-custom form-control_bottom px-3"
              name="postal_code"
              value={_.get(initialData, "postal_code", "")}
              onChange={handleChangeOtherFields}
              placeholder="Enter..."
              style={c.inputStyles}
            />
          </div>
        </div>

        <div className="button-container d-flex justify-content-end">
          <ButtonAsync
            className="btn btn-primary position-relative py-2 px-4 mr-2"
            type="submit"
            loading={pending}
            disabled={isInputEmpty}
          >
            Create
          </ButtonAsync>

          <button
            type="button"
            className="btn bg-gray-300 text-white py-2 px-4"
            onClick={handleCloseModal}
            disabled={pending}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

const modalKey = "add-retailer";
const modalConfig = {
  title: "Create Retailer Account",
  size: "modal-lg",
};

export default withModal(modalKey, modalConfig)(AddRetailer);
