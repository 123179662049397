import React, { useState, useContext, useCallback } from "react";
import withModal from "modules/common/hoc/withModal";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { removeEmpty } from "helper";
import _ from "lodash";
import { ModalContext } from "App";
import ButtonAsync from "components/Button";
import SelectAsync from "components/SelectAsync";
import { useDealer } from "app/load-wallet/context/DealerProvider";
import { useDealerSim } from "app/load-wallet/dealer-sims/context/DealerSimProvider";
import ReactInputMask from "react-input-mask";
import { useSimTypeParams } from "../hooks";
import * as actions from "../actions";
import * as c from "../constant";

function DealerSimForm() {
  const form = {
    assigned_to: "",
    mobile_identification_number: "",
    type: "",
  };

  const dispatch = useDispatch();
  const {
    simDealerId,
    dealerRole,
    accountTypeName,
    isNonMigrated,
    role,
    isB2B,
  } = useDealer();

  const { dealerSimsAll } = useDealerSim();
  const setModal = useContext(ModalContext);
  const [pending, setPending] = useState(false);
  const [formData, setFormData] = useState(form);

  const closeModal = () => {
    setModal({ isOpen: false });
  };

  const successCallback = () => {
    const simPayload = {
      dealer_id: simDealerId,
      page: 1,
      per_page: 10,
      userType: "dealers",
    };
    closeModal();
    dispatch(actions.getDealerSims(simPayload));
    dispatch(actions.getDealerSimsAll({ ...simPayload, per_page: 9999 }));
  };

  const handleChangeInput = (key) => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value,
    });
  };

  const handleChangeSelect = (key) => (e) => {
    setFormData((f) => ({ ...f, [key]: e ? e.value : "", page: 1 }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);
    const payload = {
      ...formData,
      is_smmt: dealerRole?.includes(c.DEALER_SIM_ROLES.smmt),
      dealer_id: simDealerId,
      type: formData.type || "TBD",
      role_type: isNonMigrated || isB2B ? role : "",
    };
    if (c.otp_enabled === "false" && c.isDevEnvironment) {
      dispatch(
        actions.createDealerSims(
          removeEmpty(payload),
          successCallback,
          closeModal
        )
      );
      return;
    }

    dispatch(
      actions.setOtp(
        {
          mobile: formData?.mobile_identification_number,
          purpose: c.PURPOSE.portal,
        },
        (res) =>
          setModal(
            "dealer-confirm-otp",
            removeEmpty({ ...payload, ...res?.res?.data })
          ),
        () => setPending(false)
      )
    );
  };

  const simTypeParams = useSimTypeParams(
    dealerSimsAll,
    accountTypeName,
    dealerRole
  );

  const selectDealerSimType = useCallback(() => {
    return (
      <SelectAsync
        value={_.get(formData, "type", null)}
        api="/api/v1/dealer-sim-types"
        transformer={({ data }) => {
          return data.map((item) => ({
            value: _.get(item, "value"),
            label: _.get(item, "value"),
          }));
        }}
        onChange={handleChangeSelect("type")}
        required
        isDisabled={false}
        params={{ ...simTypeParams }}
      />
    );
  }, [simTypeParams, formData]);

  return (
    <form onSubmit={handleSubmit}>
      {isNonMigrated || isB2B ? (
        <div className="row">
          <div className="col-12">
            <label className="form-label font-weight-bold">SIM Number:</label>
            <ReactInputMask
              mask="+639999999999"
              maskChar=""
              type="text"
              required
              className="form-control form-custom form-control_bottom"
              value={formData?.mobile_identification_number}
              onChange={handleChangeInput("mobile_identification_number")}
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-6">
            <label className="form-label font-weight-bold">SIM Type*:</label>
            {selectDealerSimType()}
          </div>
          <div className="col-6">
            <label className="form-label font-weight-bold">SIM Number:</label>
            <ReactInputMask
              mask="+639999999999"
              maskChar=""
              type="text"
              required
              className="form-control form-custom form-control_bottom"
              value={formData?.mobile_identification_number}
              onChange={handleChangeInput("mobile_identification_number")}
            />
          </div>
        </div>
      )}
      <div className="row mt-5">
        <div className={`${isNonMigrated || isB2B ? "col-12" : "col-6"}`}>
          <label className="form-label font-weight-bold">
            Assign to physical SIM holder (name)
          </label>
          <input
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={formData?.assigned_to}
            onChange={handleChangeInput("assigned_to")}
            maxLength={50}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 my-4">
          <span className="text-left form-label">
            By clicking &quot;CONTINUE&quot; a One-Time-Password(OTP) <br />
            Will be sent through this number to verify ownership
          </span>
        </div>
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <ButtonAsync
              type="submit"
              className="btn btn-primary position-relative text-uppercase mx-2"
              loading={pending}
            >
              Continue
            </ButtonAsync>
            <button
              type="button"
              onClick={closeModal}
              className="text-uppercase btn btn-danger font-weight-bold mr-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

const mapStateToProps = ({ modal }) => ({
  dealerStatus: _.get(modal, "modalData.dealerStatus") || "",
});

const enhance = _.flowRight([
  withModal("fill-dealer-sim", {
    title: "Add new Dealer SIM",
    size: "modal-md",
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(DealerSimForm);
