import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import ConfirmAlert from "modules/common/components/ConfirmAlert";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as authAction from "modules/auth/actions";
import { ToastSuccess } from "modules/common/components/Toast";
import ChangePassword from "modules/auth/form/ChangePassword";
import imgThumb from "assets/images/default-user.jpg";
import OverflowTip from "modules/common/components/OverflowTip";
import { useMyProfile } from "modules/auth/hooks";
import * as c from "./constants";

function Header({ logout, roles }) {
  const [toggle, setToggle] = useState(false);
  const myProfile = useMyProfile();

  const profile = myProfile?.attributes;

  const handleClickBody = (e) => {
    if (!e.target.closest("#for-dropdown-navigation") && toggle) {
      setToggle(false);
    }
  };

  const handleOnLogOut = (e) => {
    e.preventDefault();
    ConfirmAlert(
      "Are you sure you want to logout?",
      () => {
        logout(() => {
          ToastSuccess("You have successfully logged out!", {
            autoClose: 1500,
          });
        });
      },
      () => {},
      {
        yesLabel: "Log out",
        noLabel: "Cancel",
      }
    );
  };

  useEffect(() => {
    document.body.addEventListener("click", handleClickBody);
    return () => {
      document.body.removeEventListener("click", handleClickBody);
    };
  });

  const role = _.get(roles, "0");
  const headerRoles = c.HEADER_NAMES[role];
  const name = _.isEmpty(profile) ? "User" : profile?.full_name;

  return (
    <header className="px-3 py-0 shadow-sm" style={{ zIndex: 5 }}>
      <nav className="navbar navbar-expand navbar-light p-0">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <button
              type="button"
              id="for-dropdown-navigation"
              className="btn btn-link user-dropdown nav-link border-0 p-3 outline-none"
              aria-haspopup="true"
              aria-expanded={toggle}
              onClick={() => setToggle(!toggle)}
            >
              <span className="text-capitalize text-right lh-1">
                <small className="d-block text-sm">
                  Hi{" "}
                  <strong>
                    <OverflowTip
                      id={name}
                      text={name}
                      className="d-inline-block max-header "
                    />
                  </strong>
                </small>
                <small className="text-xs">{headerRoles}</small>
              </span>
              <img
                className="img-profile rounded-circle border mx-3"
                src={_.get(profile, "profile_img") || imgThumb}
                alt="Profile"
                onError={(e) => {
                  e.target.src = imgThumb;
                }}
                style={{ width: 30 }}
              />
              <i className="dropdown-toggle text-primary" />
            </button>
            <div
              className={`${toggle ? "show" : ""} dropdown-menu fade`}
              style={{ right: 0, left: "auto" }}
              aria-labelledby="for-dropdown-navigation"
            >
              <button
                type="button"
                className="dropdown-item btn btn-default btn-dropdown"
                onClick={handleOnLogOut}
              >
                <i className="fas fa-sign-out-alt mr-2" />
                <span className="text-sm">Log Out</span>
              </button>
            </div>
          </li>
          <li className="nav-item notification" />
        </ul>

        <ChangePassword />
      </nav>
    </header>
  );
}

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api }) => ({
  profile: _.get(api, "AUTH/user_profile.item") || {},
  roles: _.get(api, "AUTH/user_profile.item.roles") || [],
});

const enhance = _.flowRight([
  withRouter,
  connect(mapStateToProps, {
    ...authAction,
  }),
]);

export default enhance(Header);
