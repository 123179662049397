import React, { useCallback, useState, useEffect, useMemo } from "react";
import withModal from "modules/common/hoc/withModal";
import _ from "lodash";
import { ModalContext } from "App";
import { withRouter, useRouteMatch } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import ReactInputMask from "react-input-mask";
import ButtonAsync from "components/Button";
import { ToastSuccess } from "modules/common/components/Toast";
import { useLoading } from "modules/ui/hooks";
import * as actions from "../action";
import * as c from "../constant";
import AssignSIM from "../components/AssignSIM";

const form = {
  name: "",
  address: "",
  tin: "",
  business_style: "",
  code: "",
};

function UpdateBranchForm({ newData, updateBranch, getBranch, showBranch }) {
  const match = useRouteMatch();
  const sub_match = useRouteMatch(`${match.path}/:id`);
  const branchId = _.get(sub_match, "params.id");
  const setModal = React.useContext(ModalContext);

  const assignedSims = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_BRANCH}.item.included.sources`)
  );

  const [formData, setFormData] = useState(form);
  const [pending, setPending] = useState(false);

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleSIMAssignment = useCallback(
    (sources) => {
      setFormData((prevState) => ({
        ...prevState,
        sources,
      }));
    },
    [setFormData]
  );

  const handleChangeInput = (key) => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value,
    });
  };

  const isFormInvalid = useMemo(() => {
    const { tin, ...others } = formData;
    const sources = _.get(formData, "sources", []);

    return (
      Object.values(others).includes("") ||
      _.size(tin) < 15 ||
      _.isEmpty(sources)
    );
  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setPending(true);
    const onSuccess = () => {
      ToastSuccess("Successfully Updated.");
      setModal({ isOpen: false });
      setPending(false);
      getBranch();
      showBranch(branchId);
    };
    const onError = () => setPending(false);
    return updateBranch(branchId, formData, onSuccess, onError);
  };

  useEffect(() => {
    setFormData(newData);
  }, [setFormData, newData]);

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-6">
          <label className="form-label text-success">Branch Name:</label>
          <input
            autoFocus
            type="text"
            name="name"
            required
            value={_.get(formData, "name") || ""}
            onChange={handleChangeInput("name")}
            className="form-control form-custom form-control_bottom"
            maxLength={c.FIELD_MAX_LIMIT}
          />
        </div>
        <div className="col-6">
          <label className="form-label text-primary">Address:</label>
          <input
            type="text"
            name="address"
            required
            value={_.get(formData, "address") || ""}
            onChange={handleChangeInput("address")}
            className="form-control form-custom form-control_bottom"
            maxLength={300}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-4">
          <label className="form-label text-primary">Branch TIN:</label>
          <ReactInputMask
            type="text"
            mask="999-999-999-99999"
            placeholder="111-111-111-11111"
            maskChar=""
            className="form-control form-custom form-control_bottom"
            name="tin"
            required
            onChange={handleChangeInput("tin")}
            value={_.get(formData, "tin") || ""}
          />
        </div>
        <div className="col-4">
          <label className="form-label text-primary">Business Style:</label>
          <input
            type="text"
            name="business_style"
            required
            value={_.get(formData, "business_style") || ""}
            onChange={handleChangeInput("business_style")}
            className="form-control form-custom form-control_bottom"
            maxLength={c.FIELD_MAX_LIMIT}
          />
        </div>
        <div className="col-4">
          <label className="form-label text-primary">Branch Code:</label>
          <input
            type="text"
            name="code"
            required
            value={_.get(formData, "code") || ""}
            className="form-control form-custom form-control_bottom"
            onChange={handleChangeInput("code")}
            maxLength={c.FIELD_MAX_LIMIT}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <label className="form-label text-primary">Assigned MINs:</label>
          <AssignSIM
            assignedSims={assignedSims}
            onChange={handleSIMAssignment}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <ButtonAsync
              type="submit"
              loading={useLoading(c.UPDATE_BRANCH) || pending}
              disabled={isFormInvalid}
            >
              Save
            </ButtonAsync>
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

UpdateBranchForm.propTypes = {
  updateBranch: PropTypes.func.isRequired,
  getBranch: PropTypes.func.isRequired,
  showBranch: PropTypes.func.isRequired,
  newData: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ api, modal }) => ({
  newData: _.get(modal, "modalData.data") || {},
});

const enhance = _.flowRight([
  withModal("update-branch", {
    title: "Update Branch",
    size: "modal-xl",
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(UpdateBranchForm);
