import React, { useMemo, useState } from "react";
import _ from "lodash";
import { USER_PROFILE } from "modules/auth/constants";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import Select from "react-select";
import { service } from "api/service";

export const getLocations = ({ queryKey: [__, id, params] }) => {
  const url = `/api/v1/area/${id}/children`;
  return service.get(url, { params });
};

export const getDealer = ({ queryKey: [__, id] }) => {
  const url = `api/v1/dealers/${id}`;
  return service.get(url);
};

const LocationSelectField = React.memo((props) => {
  const { onChange, dealerInfo, ...rest } = props;
  const [inputValue, setInputValue] = useState("");
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const child = dealerInfo?.attributes;
  const id = profile?.id || child?.parent_id;

  const apiDealer = useQuery({
    queryKey: ["dealer", id],
    queryFn: getDealer,
    retry: false,
    keepPreviousData: true,
  });

  const dealer = apiDealer?.data?.data?.data?.attributes;

  const area_id = dealer?.area_id || child?.area_id;

  const withAreaId = !!area_id;

  const apiLocations = useQuery({
    queryKey: ["area", area_id, { q: inputValue, per_page: 200 }],
    queryFn: getLocations,
    enabled: withAreaId,
    retry: false,
    keepPreviousData: true,
  });

  const locations = apiLocations?.data?.data?.data || [];

  const loading = apiLocations?.isFetching || apiDealer?.isLoading;

  const locationOptions = useMemo(
    () =>
      _.map(locations, (elem) => ({
        value: elem?.id,
        label: elem?.attributes?.name,
      })),
    [locations]
  );

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleChange = (value) => {
    if (!_.isFunction(onChange)) return;
    onChange(value);
  };

  return (
    <Select
      options={locationOptions}
      onChange={handleChange}
      onInputChange={handleInputChange}
      isLoading={loading}
      isDisabled={!withAreaId}
      noOptionsMessage={() => "No chidren locations"}
      {...rest}
    />
  );
});

LocationSelectField.defaultProps = {
  dealerInfo: {},
};

LocationSelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
  dealerInfo: PropTypes.instanceOf(Object),
};

export default LocationSelectField;
