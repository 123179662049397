import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { GET_BENTA_KITA_DEALER } from 'app/dashboard/constant';
import { getBentaKitaDealer } from 'app/dashboard/actions';
import DailyTransactions from './DailyTransactions';

const INIT_STATE = {
  filter_by: 'monthly',
  date: moment().format('YYYY-MM-DD'),
};
const viewWidth = window.innerWidth;
const finalWidth = viewWidth >= 1199 ? 230 : 180;

const TransactionReport = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(INIT_STATE);
  const data = useSelector(({ api }) =>
    _.get(api, `${GET_BENTA_KITA_DEALER}/graph.item`)
  );
  const handleOnChange = key => e => {
    setFilter({
      ...filter,
      [key]: e.target.value,
    });
    dispatch(getBentaKitaDealer({ ...filter, [key]: e.target.value }));
  };

  return (
    <div className="col-6">
      <div className="daily-transactions">
        <div className="card shadow-sm h-100">
          <div className="card-body px-0">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <h6 className="m-0 font-weight-bold mr-auto">
                      Sales Report
                    </h6>
                    <div className="filter-by">
                      <select
                        name="dateRage"
                        id="dateRage"
                        className="custom-select form-custom form-control_bottom w-100"
                        onChange={handleOnChange('filter_by')}
                        value={_.get(filter, 'filter_by')}
                      >
                        <option value="daily">Daily</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                      </select>
                    </div>
                  </div>
                  <DailyTransactions data={data || {}} height={finalWidth} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionReport;
