export const CREATE_CLIENT = "GET_CLIENT";
export const GET_CLIENT = "GET_CLIENT";
export const VALIDATE_PASSWORD = "VALIDATE_PASSWORD";
export const GENERATE_KEY = "GENERATE_KEY";
export const GET_BRANCHES = "GET_BRANCHES";

export const selectOptionStyles = {
  control: (base) => ({
    ...base,
    border: 0,
    borderBottom: "1px solid gray",
    borderRadius: 0,
    backgroundColor: "none",
    boxShadow: "none",
    fontWeight: "light",
    padding: "0",
  }),
};
