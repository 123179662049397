import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MobileNumberInput from 'components/MobileNumberInput';

function TemporaryAccountForm({ temporaryForm, onChange, type }) {
  const isTypeUpdate = type === 'update';

  return (
    <Fragment>
      {!isTypeUpdate ? (
        <div className="d-flex align-items-center mb-2 mt-3">
          <span className="flex-grow-1 mt-0 mb-0 font-weight-bold">
            Send Temporary Password:
          </span>
        </div>
      ) : null}
      <div className="row">
        <div className="col">
          <label className="form-label text-success">Email Address:</label>
          <input
            type="email"
            required
            name="email"
            onChange={e => onChange({ email: e.target.value })}
            value={_.get(temporaryForm, 'email') || ''}
            className="form-control form-custom form-control_bottom"
          />
        </div>
        <div className="col">
          <label className="form-label text-primary">Mobile Number:</label>
          <MobileNumberInput
            prefix="+63"
            name="mobile"
            className="form-control form-custom form-control_bottom"
            value={_.get(temporaryForm, 'mobile') || ''}
            defaultValue={_.get(temporaryForm, 'mobile') || ''}
            onValueChange={value => onChange({ mobile: value })}
            required
          />
        </div>
      </div>
    </Fragment>
  );
}

TemporaryAccountForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  temporaryForm: PropTypes.instanceOf(Object).isRequired,
};

export default TemporaryAccountForm;
