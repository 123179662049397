import React, { useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ModalContext } from "App";
import { useRootPath } from "hooks";
import ReactInputMask from "react-input-mask";
import Button from "components/Button";
import { ToastError, ToastSuccess } from "modules/common/components/Toast";
import ErrorMessage from "modules/common/components/ErrorMessage";
import withModal from "modules/common/hoc/withModal";
import { createBranch, getBranchById, updateBranch } from "../actions";

const defaultData = {
  is_active: true,
  name: "",
  address: "",
  business_style: "",
  tin: "",
  code: "",
};

const BranchSetup = ({ isNew }) => {
  const [form, setForm] = useState(defaultData);
  const rootPath = useRootPath();
  const history = useHistory();
  const urlParams = useParams();
  const setModal = useContext(ModalContext);
  const queryClient = useQueryClient();
  const branchId = _.get(urlParams, "branchId");

  const upsertBranch = isNew ? createBranch : updateBranch;
  const apiBranch = useQuery({
    queryKey: ["branch", branchId],
    queryFn: getBranchById,
    enabled: !isNew && !_.isEmpty(branchId),
    retry: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const isBranchFetching = apiBranch.isFetching;

  const { mutate: saveBranch, isLoading: isProcessing } = useMutation(
    upsertBranch,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("branches");
        queryClient.invalidateQueries("branch");
        ToastSuccess(`Successfully ${isNew ? `added` : `updated`} branch.`);
        setModal({ isOpen: false });
        if (isNew) {
          const newId = _.get(res, "data.data.id");
          history.push(_.join([rootPath, newId], "/"));
        }
      },
      onError: (err) => {
        const defaultMessage = `Failed to ${isNew ? `add` : `update`} branch.`;
        const errorMessage = (
          <ErrorMessage error={err} defaultMessage={defaultMessage} />
        );
        ToastError(errorMessage);
        setModal({ isOpen: false });
      },
    },
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = isNew ? { data: form } : { branchId, data: form };
    saveBranch(params);
  };

  const handleModalClose = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setForm((state) => ({ ...state, [name]: value }));
  };

  const isFormValid = useMemo(() => {
    return !_.some(form, (v) => v === "") && form?.tin?.length === 17;
  }, [form]);

  useEffect(() => {
    if (isNew) {
      setForm(defaultData);
      return;
    }

    const branchData = _.pick(
      _.get(apiBranch, "data.data.attributes"),
      _.keys(defaultData),
    );

    setForm(branchData);
  }, []);

  return (
    <div className="col">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-6">
            <label className="form-label tw-text-gray-700">Branch Name</label>
            <input
              type="text"
              name="name"
              className="form-control form-custom form-control_bottom"
              value={_.get(form, "name") || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6">
            <label className="form-label tw-text-gray-700">Branch TIN</label>
            <ReactInputMask
              type="text"
              mask="999-999-999-99999"
              placeholder="111-111-111-11111"
              maskChar=""
              className="form-control form-custom form-control_bottom"
              name="tin"
              required
              onChange={handleInputChange}
              value={_.get(form, "tin") || ""}
            />
          </div>
          <div className="col-6 my-4">
            <label className="form-label tw-text-gray-700">Branch Code</label>
            <input
              type="text"
              name="code"
              className="form-control form-custom form-control_bottom"
              value={_.get(form, "code") || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-6 my-4">
            <label className="form-label tw-text-gray-700">
              Business Style
            </label>
            <input
              type="text"
              name="business_style"
              className="form-control form-custom form-control_bottom"
              value={_.get(form, "business_style") || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-12">
            <label className="form-label tw-text-gray-700">Address</label>
            <input
              type="text"
              name="address"
              className="form-control form-custom form-control_bottom"
              value={_.get(form, "address") || ""}
              onChange={handleInputChange}
              maxLength={300}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end pt-4">
          <Button
            loading={isBranchFetching || isProcessing}
            type="submit"
            disabled={!isFormValid}
          >
            Save
          </Button>
          <Button
            onClick={handleModalClose}
            className="btn btn-danger font-weight-bold ml-2"
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

BranchSetup.propTypes = {
  isNew: PropTypes.bool,
};

BranchSetup.defaultProps = {
  isNew: false,
};

const modalKey = "branch-setup";
const modalConfig = {
  title: "Create Branch",
  size: "modal-lg",
};

export default withModal(modalKey, modalConfig)(BranchSetup);
