import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M17.722 16.384l-3.076-3.075a6.99 6.99 0 001.444-4.262 7 7 0 00-2.064-4.983A7 7 0 009.043 2a7 7 0 00-4.982 2.064c-2.748 2.748-2.748 7.218 0 9.965a7 7 0 004.982 2.064 6.988 6.988 0 004.262-1.443l3.076 3.075a.945.945 0 001.341 0c.37-.37.37-.97 0-1.34zm-12.32-3.696a5.156 5.156 0 010-7.283 5.117 5.117 0 013.641-1.508c1.376 0 2.67.536 3.642 1.508a5.116 5.116 0 011.508 3.642 5.116 5.116 0 01-1.508 3.641 5.116 5.116 0 01-3.642 1.509 5.116 5.116 0 01-3.641-1.509z"
        fill="#999"
      />
    </svg>
  )
}

export default SvgComponent
