import React from 'react';
import GigaBanner from 'assets/images/ads/giga-video.jpg';

const AdsBanner = () => {
  return (
    <div className="services">
      <div className="ads-section">
        <img className="ads-banner" src={GigaBanner} alt="Discover" />
      </div>
    </div>
  );
};

export default AdsBanner;
