import * as actions from './actions';

const loadingFlow = ({ dispatch }) => next => action => {
  next(action);

  if (action.type.endsWith('-async')) {
    dispatch(actions.queueLoading(action.type));
  }

  if (action.type.endsWith('-success')) {
    dispatch(actions.dequeueLoading(action.type.replace('-success', '-async')));
  }

  if (action.type.endsWith('-error')) {
    dispatch(actions.dequeueLoading(action.type.replace('-error', '-async')));
  }
};

export default [loadingFlow];
