import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ModalContext } from 'App';
import { ToastError, ToastSuccess } from 'modules/common/components/Toast';
import PropTypes from 'prop-types';
import { passwordStrength, checkPermissions } from 'helper';
import { USER_PROFILE } from 'modules/auth/constants';
import { CHANGE_PASSWORD } from '../constants';
import * as actions from '../actions';
import RequestOTP from './RequestOTP';

const form = {
  old_password: '',
  new_password: '',
  new_password_confirmation: '',
};

function UpdatePassword({
  requestOtp,
  isLoading,
  roles,
  profileType,
  changePasswordDealers,
  mobile_identification_number,
}) {
  const setModal = React.useContext(ModalContext);
  const [formData, setFormData] = React.useState(form);
  const [view, setView] = React.useState({
    old_password: false,
    new_password: false,
    new_password_confirmation: false,
  });

  const isKapartnerUser =
    profileType === 'retailers' && _.get(roles, '0', '') === 'kapartner-user';
  const isDealer = profileType === 'dealers';
  const isRetailerUser =
    (profileType === 'retailer_users' &&
      _.get(roles, '0', '') === 'branch-admin') ||
    _.get(roles, '0', '') === 'frontliner';

  const pwStrength = passwordStrength(formData.new_password || '');

  const handleChangeInput = key => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value,
    });
  };

  const handleOnViewPassword = (key, value) => e => {
    e.preventDefault();
    setView({
      [key]: value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (isKapartnerUser) {
      const payload = { mobile_identification_number };
      if (!pwStrength.isValid) {
        ToastError(
          'Password length should be minimum of eight (8) characters and should contain characters from three out of four categories.'
        );
      }
      if (pwStrength.isValid) {
        requestOtp(payload, () => {
          setModal('set-otp', {
            payload: formData,
            type: 'change-password',
            mobile_identification_number,
          });
        });
      }
    }

    if (isRetailerUser) {
      const payload = { mobile_identification_number };
      if (!pwStrength.isValid) {
        ToastError(
          'Password length should be minimum of eight (8) characters and should contain characters from three out of four categories.'
        );
      }
      if (pwStrength.isValid) {
        requestOtp(payload, () => {
          setModal('set-otp', {
            payload: formData,
            mobile_identification_number,
            setFormData,
            form,
          });
        });
      }
    }
    if (isDealer) {
      const payload = {
        ...formData,
        password: formData.new_password,
        password_confirmation: formData.new_password_confirmation,
      };
      changePasswordDealers(payload, () => {
        ToastSuccess('Successfully Changed your Password.');
        setModal({ isOpen: false });
        setFormData(form);
      });
    }
  };

  return (
    <div className="container-fluid h-100">
      <div className="row d-flex align-items-center justify-content-center">
        <div className="px-4 pt-4">
          <p className="border border-danger text-danger py-2 px-3">
            This user account&apos;s password has expired. You are now required
            to update your password for security purposes. Contact your System
            Administrator if you need technical assistance.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="prepend-form-right">
                <label className="form-label text-primary">
                  Current Password:
                </label>
                <input
                  className="form-control form-custom form-control_bottom"
                  type={_.get(view, 'old_password') ? 'text' : 'password'}
                  placeholder="Current Password"
                  required
                  onChange={handleChangeInput('old_password')}
                  value={_.get(formData, 'old_password') || ''}
                  disabled={!checkPermissions(['can-update-password'])}
                />
                <button
                  type="button"
                  onClick={handleOnViewPassword(
                    'old_password',
                    !_.get(view, 'old_password')
                  )}
                  className="btn btn-default rounded-circle p-0 mr-1"
                >
                  <i
                    className={`fas ${
                      !_.get(view, 'old_password') ? 'fa-eye' : 'fa-eye-slash'
                    }`}
                  />
                </button>
              </div>
            </div>
            <div className="form-group">
              <div className="prepend-form-right">
                <label className="form-label text-primary">New Password:</label>
                <input
                  className="form-control form-custom form-control_bottom"
                  type={_.get(view, 'new_password') ? 'text' : 'password'}
                  placeholder="New Password"
                  required
                  onChange={handleChangeInput('new_password')}
                  value={_.get(formData, 'new_password') || ''}
                  disabled={!checkPermissions(['can-update-password'])}
                />
                <button
                  type="button"
                  onClick={handleOnViewPassword(
                    'new_password',
                    !_.get(view, 'new_password')
                  )}
                  className="btn btn-default rounded-circle p-0 mr-1"
                >
                  <i
                    className={`fas ${
                      !_.get(view, 'new_password') ? 'fa-eye' : 'fa-eye-slash'
                    }`}
                  />
                </button>
              </div>
            </div>
            <div className="form-group">
              <div className="prepend-form-right">
                <label className="form-label text-primary">
                  Retype New Password:
                </label>
                <input
                  className="form-control form-custom form-control_bottom"
                  type={
                    _.get(view, 'new_password_confirmation')
                      ? 'text'
                      : 'password'
                  }
                  required
                  placeholder="New Password"
                  onChange={handleChangeInput('new_password_confirmation')}
                  value={_.get(formData, 'new_password_confirmation') || ''}
                  disabled={!checkPermissions(['can-update-password'])}
                />
                <button
                  type="button"
                  onClick={handleOnViewPassword(
                    'new_password_confirmation',
                    !_.get(view, 'new_password_confirmation')
                  )}
                  className="btn btn-default rounded-circle p-0 mr-1"
                >
                  <i
                    className={`fas ${
                      !_.get(view, 'new_password_confirmation')
                        ? 'fa-eye'
                        : 'fa-eye-slash'
                    }`}
                  />
                </button>
              </div>
              <p className="text-muted mt-2" style={{ lineHeight: 1 }}>
                <small>
                  Password length should be minimum of eight (8) characters and should contain characters from three out of four categories:
                </small>
              </p>
            </div>
            <div className="form-inline mb-4">
              <div className="form-group mr-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={pwStrength.isAlphaNumeric}
                    readOnly
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="passwordAlpha"
                  >
                    Alphanumeric
                  </label>
                </div>
              </div>
              <div className="form-group mr-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={pwStrength.hasUpperCase}
                    readOnly
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="passwordUppercase"
                  >
                    Uppercase Letter
                  </label>
                </div>
              </div>
              <div className="form-group mr-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    readOnly
                    checked={pwStrength.hasLowerCase}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="passwordLowercase"
                  >
                    Lowercase Letter
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    readOnly
                    checked={pwStrength.hasSpecialCharacter}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="passwordSpecialChar"
                  >
                    Special Characters
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary btn-block px-4"
                disabled={
                  !pwStrength.isValid ||
                  isLoading ||
                  !checkPermissions(['can-update-password'])
                }
              >
                Change Password
              </button>
            </div>
          </form>
        </div>
      </div>
      <RequestOTP />
    </div>
  );
}

UpdatePassword.propTypes = {
  requestOtp: PropTypes.func.isRequired,
  changePasswordDealers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  profileType: PropTypes.string.isRequired,
  mobile_identification_number: PropTypes.string.isRequired,
};

const mapStateToProps = ({ api }) => ({
  isLoading: api.loading.includes(CHANGE_PASSWORD),
  profileType: _.get(api, `${USER_PROFILE}.item.profile_type`) || '',
  roles: _.get(api, `${USER_PROFILE}.item.roles`) || [],
  mobile_identification_number: _.get(api, `${USER_PROFILE}.item.mobile`) || '',
});

const enhance = _.flowRight([
  withRouter,
  connect(mapStateToProps, {
    ...actions,
  }),
]);

export default enhance(UpdatePassword);
