import React, { useContext, useState } from "react";
import _ from "lodash";
import { ModalContext } from "App";
import { checkPermissions } from "helper";
import ButtonAsync from "modules/common/components/ButtonAsync";
import CollapseDocu from "./CollapseDocumentation";

const KeyGen = () => {
  const setModal = useContext(ModalContext);
  const [options] = useState({
    loading: false,
    isDisabled: false,
  });

  const openAuthModal = () => setModal("can-input-password");

  return (
    <div className="tw-container-fluid tw-bg-gray-200 px-4 py-1 tw-rounded">
      <h5 className="pt-1 mb-2 font-weight-bold mx-1">Introduction</h5>
      <p className="mx-1">
        DMS eLoad API gateway enables SMART Trade Partners to facilitate SMART
        Load and Trade transactions such as reload product offer, transfer fund
        and inquire balance. The connection to the API Platform will be
        established using a secure HTTPS Connection and REST Protocol.
      </p>
      <CollapseDocu />
      <div className="d-flex justify-content-end tw-my-3">
        <div className="d-flex justify-content-center">
          {checkPermissions(["can-update-api-documentation"]) && (
            <ButtonAsync
              type="button"
              className="btn btn-success"
              onClick={openAuthModal}
              loading={options.loading}
              disabled={options.isDisabled}
            >
              <span>Generate</span>
            </ButtonAsync>
          )}
        </div>
      </div>
    </div>
  );
};

export default KeyGen;
