import React from "react";
import PropTypes from "prop-types";

function OfferGrid({ data, onSelectPromo, brand, retailer, logo, isLoading }) {
  return (
    <>
      <div
        className="col-xl-10 tw-overflow-y-scroll"
        style={{ maxHeight: "calc(100vh - 215px)" }}
      >
        {isLoading && (
          <div
            className="text-center mt-5"
            style={{
              position: "absolute",
              zIndex: 5,
              left: 0,
              right: 0,
              margin: "100px auto",
              width: 40,
              height: 40,
              background: "#eaeaea",
              borderRadius: "50%",
              paddingTop: 6,
              fontWeight: "bolder",
              fontSize: 20,
            }}
          >
            <span className="fas fa-1x fa-spin fa-spinner" />
          </div>
        )}
        <div className="row">
          {data.map((offer) => (
            <a
              key={offer.id}
              href="/"
              onClick={onSelectPromo(offer, brand, retailer)}
              className="col-lg-6 col-xl-4 pb-3 px-2 h-auto"
              style={{ height: 137 }}
            >
              <div className="card-offer card h-100">
                <div className="promo-title d-flex align-items-center">
                  <img
                    src={offer?.attributes?.provider?.logo}
                    alt="promo-logo"
                    className="mr-2"
                    style={{ height: 27 }}
                  />
                  <p className="title font-weight-bold m-0">
                    {offer?.attributes?.name}
                  </p>
                </div>
                <small className="promo-description mt-2 mb-1">
                  {offer?.attributes?.description.substring(0, 35)}...
                </small>
                <div className="promo-meta d-flex align-items-center justify-content-between">
                  <span className="promo-price text-black font-weight-bold text-uppercase mt-0">
                    PHP {offer?.attributes?.amount}
                  </span>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </>
  );
}

OfferGrid.propTypes = {
  onSelectPromo: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array),
  brand: PropTypes.string,
  logo: PropTypes.string,
  retailer: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
};

OfferGrid.defaultProps = {
  data: [],
  brand: "",
  logo: "",
  retailer: {},
  isLoading: false,
};

export default OfferGrid;
