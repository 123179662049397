import _ from "lodash";
import { apiGet, apiPost, setItem } from "api/actions";
import { CANCEL } from "api/constants";
import * as c from "./constants";
import history from "../../history";

const authFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === c.AUTH_OTP) {
    const payload = { ...action.credentials, purpose: "portal-verification" };
    dispatch(
      apiPost("/api/v1/external/otp", payload, c.AUTH_OTP_SENT, c.AUTH_OTP_FAIL)
    );
  }

  if (action.type === c.AUTH_OTP_SENT) {
    dispatch({
      type: "SET_MODAL",
      modalKey: "login-otp",
      modalData: { ...action.payload, ..._.get(action, "response.data") },
    });
  }

  if (action.type === c.OTP_VERIFY) {
    const regexp = /^09/;
    const match = regexp.test(_.get(action, "credentials.username"));
    const { username, ...creds } = action.credentials;
    const payload = {
      ...creds,
      ...(match ? { mobile: username } : { username }),
      from_mobile: false,
    };
    dispatch(
      apiPost("/api/v2/auth", payload, c.OTP_VERIFY_SUCCESS, c.OTP_VERIFY_FAIL)
    );
  }

  if (action.type === c.OTP_VERIFY_SUCCESS) {
    if (_.get(action, "response.data.is_for_logout_only")) {
      dispatch({
        type: "SET_MODAL",
        modalKey: "session-confirm",
        modalData: {
          ..._.get(action, "response.data", {}),
          credentials: action.payload,
        },
      });
      return;
    }
    dispatch({
      type: c.AUTH_SUCCESS,
      response: _.get(action, "response"),
    });
  }

  if (
    action.type === c.OTP_VERIFY_FAIL &&
    action?.error?.response?.status === 401
  ) {
    const clear = action?.payload?.clear;
    if (_.isFunction(clear)) clear();
  }
};

const dealerFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  const userType = _.get(action, "response.data.data.type");
  if (action.type !== c.AUTH_SUCCESS) return;
  if (userType !== "dealers") return;

  const {
    data,
    token,
    refresh_token,
    retailer_id,
    roles,
  } = action.response.data;

  const profile = {
    ..._.get(data, "attributes"),
    id: _.get(data, "id"),
    profile_type: _.get(data, "type"),
    retailer_id,
    roles,
  };

  const profileId = {
    id: _.get(data, "id"),
  };

  sessionStorage.setItem("token", token);
  sessionStorage.setItem("refresh_token", refresh_token);
  sessionStorage.setItem("profile", JSON.stringify(profile));
  sessionStorage.setItem("profileId", JSON.stringify(profileId));
  sessionStorage.setItem("is_refresh_token_used", false);

  dispatch(setItem(c.USER_PROFILE, profile));
  setTimeout(() => {
    dispatch({ type: c.AUTH_PERMISSIONS });
  }, 100);
};

const retailerFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type !== c.AUTH_SUCCESS) return;

  const userType = _.get(action, "response.data.data.type");
  if (userType !== "retailers" && userType !== "dealer_users") return;

  const {
    data,
    token,
    refresh_token,
    roles,
    trade_name,
  } = action.response.data;
  const profile = {
    ..._.get(data, "attributes"),
    retailer_id: _.get(data, "id") || _.get(data, "attributes.retailer_id"),
    profile_type: _.get(data, "type"),
    roles,
    trade_name,
  };

  const profileId = {
    id: _.get(data, "id"),
  };

  sessionStorage.setItem("token", token);
  sessionStorage.setItem("refresh_token", refresh_token);
  sessionStorage.setItem("profile", JSON.stringify(profile));
  sessionStorage.setItem("profileId", JSON.stringify(profileId));
  sessionStorage.setItem("is_refresh_token_used", false);

  dispatch(setItem(c.USER_PROFILE, profile));
  setTimeout(() => {
    dispatch({ type: c.AUTH_PERMISSIONS });
  }, 100);
};

const retailerUsersFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type !== c.AUTH_SUCCESS) return;
  const userType = _.get(action, "response.data.data.type");
  if (userType !== "retailer_users") return;

  const {
    data,
    token,
    refresh_token,
    roles,
    trade_name,
  } = action.response.data;
  const profile = {
    ..._.get(data, "attributes"),
    retailer_id: _.get(data, "id"),
    profile_type: _.get(data, "type"),
    roles,
    trade_name,
  };

  const profileId = {
    id: _.get(data, "id"),
  };

  sessionStorage.setItem("token", token);
  sessionStorage.setItem("refresh_token", refresh_token);
  sessionStorage.setItem("profile", JSON.stringify(profile));
  sessionStorage.setItem("profileId", JSON.stringify(profileId));

  dispatch(setItem(c.USER_PROFILE, profile));
  setTimeout(() => {
    dispatch({ type: c.AUTH_PERMISSIONS });
  }, 100);
};

const permissionFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === c.AUTH_PERMISSIONS) {
    const payload = { ...action.credentials };
    dispatch(
      apiGet(
        `/acl/api/v1/my/role_permissions`,
        payload,
        c.AUTH_PERMISSIONS_SUCCESS,
        c.AUTH_PERMISSIONS_FAIL
      )
    );
  }

  if (action.type === c.AUTH_PERMISSIONS_SUCCESS) {
    const token = sessionStorage.getItem("token");
    const permissions = action?.response?.data?.data?.permissions || [];
    sessionStorage.setItem("permissions", JSON.stringify(permissions));
    dispatch(setItem(c.AUTH, { token, isAuthenticated: true }));
    dispatch({ type: "SET_MODAL", modalKey: "", modalData: {} });
  }
};

const authFailFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  const failedRequests = [
    c.AUTH_FAIL,
    c.AUTH_OTP_FAIL,
    c.AUTH_PERMISSIONS_FAIL,
    c.AUTH_CREDS_FAIL,
    c.OTP_SEND_FAIL,
    c.OTP_VERIFY_FAIL,
  ];

  if (failedRequests.includes(action.type)) {
    const error = {
      status: action?.error?.response?.status,
      response: action?.error?.response?.data,
    };

    dispatch({ type: "ON_ERROR", error });
  }
};

const authLogoutFlow = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === c.LOGOUT) {
    dispatch({ type: CANCEL });
    dispatch(
      apiPost(
        "/api/v1/external/users/logout",
        { callback: action.callback },
        c.LOGOUT_SUCCESS
      )
    );
  }
};

const authLogoutComplete = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === c.LOGOUT_SUCCESS) {
    const callback = _.get(action, "payload.callback");
    if (callback) {
      callback();
    }

    localStorage.clear();
    sessionStorage.clear();
    dispatch({ type: "RESET" });
    dispatch(setItem(c.AUTH, { token: null, isAuthenticated: false }));
    history.replace({ pathname: "/" });
  }
};

export default [
  authFlow,
  dealerFlow,
  retailerFlow,
  retailerUsersFlow,
  authFailFlow,
  permissionFlow,
  authLogoutFlow,
  authLogoutComplete,
];
