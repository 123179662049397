import React from "react";
import PropTypes from "prop-types";

const ErrorMessage = ({ error, defaultMessage }) => {
  const errors = error?.response?.data?.errors;

  if (errors instanceof Array) {
    return errors?.map((msg) => <div>{msg?.detail || msg?.message}</div>);
  }

  if (errors?.detail) {
    return <div>{errors?.detail}</div>;
  }

  if (error?.message) {
    return (
      <>
        {defaultMessage ? <div>{defaultMessage}</div> : null}
        <small>
          <em>{error?.message}</em>
        </small>
      </>
    );
  }

  return <div>{defaultMessage || `An error has occurred.`}</div>;
};

export default ErrorMessage;

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.instanceOf(Error),
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]),
  defaultMessage: PropTypes.string,
};

ErrorMessage.defaultProps = {
  error: null,
  defaultMessage: "An error has occurred",
};
