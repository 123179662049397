import React, { Fragment } from "react";
import _ from "lodash";
import NoRoute from "components/NoRoute";
import { useParams } from "react-router-dom";
import BranchListing from "./BranchLisiting";
import BranchDetailsPanel from "./BranchDetailsPanel";
import SourceDealerMinsPanel from "./SourceDealerMinsPanel";
import SourceRetailerMinsPanel from "./SourceRetailerMinsPanel";
import BranchAdminPanel from "./BranchAdminPanel";
import FrontlinerPanel from "./FrontlinerPanel";

const BranchIndex = () => {
  const params = useParams();

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-3 h-100 px-0">
          <BranchListing />
        </div>
        <div className="col-9 px-0 bg-gray-100">
          {_.get(params, "branchId") ? (
            <Fragment>
              <BranchDetailsPanel />
              <BranchAdminPanel/>
              <FrontlinerPanel/>
              <SourceDealerMinsPanel/>
              <SourceRetailerMinsPanel/>
            </Fragment>
          ) : (
            <NoRoute />
          )}
        </div>
      </div>
    </div>
  );
};

export default BranchIndex;
