import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import * as req from "api/actions";
import { useRouteMatch, useLocation } from "react-router-dom";
import { useLoading } from "modules/ui/hooks";
import { USER_PROFILE } from "modules/auth/constants";
import * as c from "modules/LoadWalletManagement/constant";
import * as a from "modules/LoadWalletManagement/actions";

export const DealerContext = createContext();

const dealerFilter = {
  page: 1,
  per_page: 10,
  q: "",
};

export const DealerProvider = ({ children }) => {
  const dispatch = useDispatch();
  const parentMatch = useRouteMatch();
  const location = useLocation();
  const transactionPath = location?.pathname?.includes("/transactions");
  const match = useRouteMatch(`${parentMatch.url}/sub-dealer-sims/:dealerId?`);
  const { dealerId } = match?.params || "";
  const { data: dealers, meta: dealersMeta } = useSelector(
    ({ api }) => _.get(api, `${c.GET_DEALER_LIST}.res`) || []
  );
  const dealerListLoading = useLoading(c.GET_DEALER_LIST);
  const dealersPager = dealersMeta?.pagination || [];

  const [dealerFilterData, setDealerFilterData] = useState(dealerFilter);
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const profileId = profile?.id;
  const parentId = profile?.parent_id;
  const selectedCompany = useSelector(({ api }) =>
    _.get(api, `${c.GET_SELECTED_COMPANY_BY_ID}.res.data`)
  );
  const selectedDealer = useSelector(({ api }) =>
    _.get(api, `${c.GET_SELECTED_DEALER_BY_ID}.res.data`)
  );
  const selectedDealerRole = useSelector(({ api }) =>
    _.get(api, `${c.GET_ROLE_BY_SELECTED_DEALER_BY_ID}.item[0]`)
  );
  const role = profile?.roles[0];
  const dealerRole =
    c.DEALER_SIM_ROLES[selectedDealerRole?.attributes?.slug || role];
  const isBA = role?.includes("branch-admin");
  const isSKA = role?.includes("ska");
  const isSMMT = role?.includes("smmt");
  const isB2B = role?.includes("b2b");
  const isNonMigrated = role?.includes("non-migrated");
  const selectedDealerLoading = useLoading(c.GET_SELECTED_DEALER_BY_ID);
  const accountType = useSelector(({ api }) =>
    _.get(api, `${c.GET_ACCOUNT_TYPE}.res.data`)
  );
  const accountTypeName =
    selectedDealer?.attributes?.accountTypeName ||
    accountType?.attributes?.name;

  const simDealerId = useMemo(() => {
    return dealerId || profileId || parentId;
  }, [dealerId, profileId, parentId]);
  useEffect(() => {
    if (dealerId) {
      if (transactionPath) return;
      dispatch(a?.getSelectedCompanyById(dealerId));
    }
    return () => {
      dispatch(req.setItem(c.GET_SELECTED_COMPANY_BY_ID, {}));
    };
  }, [dispatch, dealerId]);

  useEffect(() => {
    if (dealerId) {
      if (transactionPath) return;
      dispatch(a.getSelectedDealerById(dealerId));
    }
    return () => {
      dispatch(req.setItem(c.GET_SELECTED_DEALER_BY_ID, {}));
    };
  }, [dispatch, dealerId]);

  useEffect(() => {
    if (dealerId) {
      if (transactionPath) return;
      dispatch(a.getRoleBySelectedDealerById(dealerId));
    }
    return () => {
      dispatch(req.setItem(c.GET_ROLE_BY_SELECTED_DEALER_BY_ID, {}));
    };
  }, [dispatch, dealerId]);

  useEffect(() => {
    const shouldUseAccountType =
      selectedDealer?.attributes?.account_type_id || profile?.account_type_id;
    if (shouldUseAccountType) {
      dispatch(a.getAccountType(shouldUseAccountType));
    }
    return () => {
      dispatch(req.setItem(c.GET_ACCOUNT_TYPE, {}));
    };
  }, [profile, selectedDealer, dispatch]);

  useEffect(() => {
    if (isBA || isSKA || isSMMT) return;
    dispatch(
      a.getDealerList({
        parent_id: profileId || parentId,
        ...dealerFilterData,
        ...c.listUserFilter,
      })
    );
    dispatch(req.setItem(c.GET_DEALER_LIST, {}));
  }, [dispatch, dealerFilterData, profileId, parentId]);

  const dealersValue = {
    isBA,
    isB2B,
    selectedDealer,
    profile,
    profileId,
    parentId,
    selectedDealerLoading,
    accountTypeName,
    dealerRole,
    selectedCompany,
    dealerListLoading,
    dealers,
    dealersMeta,
    dealersPager,
    dealerFilterData,
    setDealerFilterData,
    simDealerId,
    parentMatch,
    match,
    isNonMigrated,
    role,
    transactionPath,
  };

  return (
    <DealerContext.Provider value={dealersValue}>
      {children}
    </DealerContext.Provider>
  );
};
DealerProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
export const useDealer = () => {
  return useContext(DealerContext);
};
