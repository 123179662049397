import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const getCell = (item, col) => {
  try {
    return typeof col.key === 'function' ? col.key(item) : _.get(item, col.key);
  } catch (err) {
    return '-';
  }
};

const getStyle = col =>
  _.assign({}, col.style || {}, col.width ? { width: col.width } : {});

function GenericTable({
  data,
  format,
  selected,
  customStyles,
  customTableStyles,
  selectedKey,
  flashKey,
  containerClassName,
  className,
  showHeader,
  noRowsLabel,
  footer,
  isLoading,
  onSelectRow,
  subData,
  newData,
  is_striped,
  headerClassName,
}) {
  const handleSelectRow = args => e => {
    e.preventDefault();
    if (onSelectRow) {
      onSelectRow(args, subData, newData);
    }
  };

  return (
    <div
      className={ `generic-list ${containerClassName}` }
      style={ customStyles }
    >
      {isLoading && (
        <div
          className="text-center mt-5"
          style={{
            position: 'absolute',
            zIndex: 5,
            left: 0,
            right: 0,
            margin: '100px auto',
            width: 40,
            height: 40,
            background: '#eaeaea',
            borderRadius: '50%',
            paddingTop: 6,
            fontWeight: 'bolder',
            fontSize: 20,
          }}
        >
          <span className="fas fa-1x fa-spin fa-spinner" />
        </div>
      )}
      <table
        className={`table mb-0 table-link table-hover pb-0 ${is_striped &&
          'table-striped'}  ${className}`}
          style={ customTableStyles }
      >
        {showHeader && (
          <thead
            className={`${
              _.isEmpty(headerClassName) ? 'bg-primary' : headerClassName
            } pt-2 bc text-white font-weight-bold`}
          >
            <tr>
              {format.map(col => (
                <th
                  key={col.label}
                  className={`text-ellipsis text-uppercase th-sm ${col.className ||
                    ''}`}
                >
                  {col.label}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {data.length < 1 ? (
            <tr className="text-center">
              <td rowSpan={format.length} colSpan={format.length}>
                <small className="text-muted">
                  {isLoading ? '' : noRowsLabel}
                </small>
              </td>
            </tr>
          ) : (
            data.map((item, i) => {
              const isSelected =
                `${selected}` === `${_.get(item, selectedKey)}`;
              const key = item[selectedKey];

              const getClassName = () => {
                const flash = `${key}` === `${flashKey}` ? 'active' : '';
                return isSelected
                  ? `bg-dark text-white font-weight-bold${flash}`
                  : flash;
              };

              return (
                <tr
                  key={key}
                  className={getClassName()}
                  onClick={handleSelectRow(item)}
                  style={{ cursor: 'pointer' }}
                >
                  {format.map(col => {
                    if (col.key === '_count') {
                      return (
                        <td
                          key={`${key}-${col.label}`}
                          className={col.className || ''}
                          style={getStyle(col)}
                        >
                          {i + 1}
                        </td>
                      );
                    }
                    return (
                      <td
                        key={`${key}-${col.label}`}
                        className={col.className || ''}
                        style={getStyle(col)}
                      >
                        {getCell(item, col)}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
        {footer && <tfoot>{footer(format, data)}</tfoot>}
      </table>
    </div>
  );
}

GenericTable.propTypes = {
  data: PropTypes.instanceOf(Array),
  format: PropTypes.instanceOf(Array),
  onSelectRow: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.func,
    PropTypes.bool,
  ]),
  selected: PropTypes.string,
  customStyles: PropTypes.instanceOf(Object),
  customTableStyles: PropTypes.instanceOf(Object),
  selectedKey: PropTypes.string,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  showHeader: PropTypes.bool,
  noRowsLabel: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.func,
    PropTypes.string,
  ]),
  footer: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  flashKey: PropTypes.string,
  subData: PropTypes.string,
  headerClassName: PropTypes.string,
  newData: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  is_striped: PropTypes.bool,
};

GenericTable.defaultProps = {
  data: [],
  format: [],
  onSelectRow: false,
  selected: '',
  customStyles: {},
  customTableStyles: {},
  selectedKey: 'id',
  containerClassName: '',
  className: '',
  showHeader: true,
  is_striped: true,
  noRowsLabel: 'There are no records found.',
  footer: false,
  flashKey: '',
  isLoading: false,
  subData: '',
  headerClassName: '',
  newData: {},
};

export default GenericTable;
