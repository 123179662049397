import React from 'react';
import PropTypes from 'prop-types';

const PanelButton = ({
  iconLeft: LeftIcon,
  label,
  labelClass,
  iconRight: RightIcon,
  ...rest
}) => (
  <button
    type="button"
    className="btn btn-primary text-uppercase rounded-0 outline-none d-flex align-items-center px-3"
    {...rest}
  >
    {LeftIcon ? (
      <span className="mr-1 d-flex">
        <LeftIcon />
      </span>
    ) : null}
    <span className={`text-sm text-uppercase font-weight-bold ${labelClass}`}>
      {label}
    </span>
    {RightIcon ? (
      <span className="ml-1 d-flex">
        <RightIcon />
      </span>
    ) : null}
  </button>
);

export default PanelButton;

PanelButton.propTypes = {
  label: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  iconLeft: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  iconRight: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

PanelButton.defaultProps = {
  iconLeft: null,
  iconRight: null,
  labelClass: '',
};
