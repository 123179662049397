import React, { useContext, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ModalContext } from 'App';
import { useLoading } from 'modules/ui/hooks';
import NoDataPlaceholder from '../../../../components/NoDataPlaceholder';
import MoaSignatoryInformationUpdate from '../../../modal/MoaSignatoryInformationUpdate';

import * as actions from '../../../actions';
import * as c from '../../../constants';

const MoaSignatoryInformation = ({ dealer_id }) => {
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);

  const userDetails = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_USER_ACCESS_DETAILS}.item`)
  );
  const companyInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_COMPANY_INFORMATION}.item`, {})
  );
  const deliveryAddressInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_DELIVERY_ADDRESS_INFORMATION}.item`, {})
  );
  const moaInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_MOA_SIGNATORY_INFORMATION}.item`, [])
  );

  const isLoading = useLoading(c.SHOW_MOA_SIGNATORY_INFORMATION || '');

  const isAllowed = useMemo(() => {
    const applicationnStatus = _.get(
      userDetails,
      'attributes.application_status'
    );
    return applicationnStatus === null || applicationnStatus === 'returned';
  }, [userDetails]);

  const company_id = _.get(companyInfo, 'id', '');
  const deliveryAddressId = _.get(deliveryAddressInfo, 'id');

  const transformObjectToArrayOfObjects = useMemo(
    () =>
      Object.keys(moaInfo)
        .map(item => moaInfo[item])
        .filter(item => !_.isEmpty(item)),
    [moaInfo]
  );

  const signatoryInformation = useMemo(
    () =>
      transformObjectToArrayOfObjects.filter(
        ({ attributes }) => attributes._type === 'MEMORANDUM_SIGNATORY'
      ),
    [transformObjectToArrayOfObjects]
  );

  const otherSignatories = useMemo(
    () =>
      transformObjectToArrayOfObjects.filter(
        ({ attributes }) => attributes._type === 'OTHER_SIGNATORY'
      ),
    [transformObjectToArrayOfObjects]
  );

  const openUpdateModal = () =>
    setModal('moa-signatory-information-update', {
      dealer_id,
      signatoryInformation,
      otherSignatories,
    });

  useEffect(() => {
    if (!_.isEmpty(company_id)) {
      dispatch(actions.showMoaSignatoryInformation(dealer_id, company_id));
    }
    if (_.isEmpty(company_id)) {
      dispatch(actions.removeMoaInfo());
    }
    return () => dispatch(actions.removeMoaInfo());
  }, [company_id, dealer_id]);

  return (
    <>
      {isLoading ? (
        <div className="w-100 mb-3" style={{ fontSize: 25 }}>
          <div className="bg-light-0 py-3 text-center w-100">
            <span className="fas fa-1x fa-spin fa-spinner" />
          </div>
        </div>
      ) : (
        <div className="MoaSignatoryInformation mb-4">
          <div className="MoaSignatoryInformation-container">
            <header className="rounded-top-both bg-grey-800 overflow-hidden text-white  d-flex justify-content-between pl-3">
              <div
                className="header-title my-auto"
                style={{ padding: `${!isAllowed ? '.7rem' : ''} 0` }}
              >
                <h6 className="h6 text-xs font-weight-bold p-0 m-0">
                  Memorandum of Agreement Signatory Information
                </h6>
              </div>
              {isAllowed && (
                <div>
                  <button
                    type="button"
                    className="btn text-xs btn-success rounded-0 py-2"
                    onClick={openUpdateModal}
                    disabled={isLoading || !deliveryAddressId}
                  >
                    <i className="fas fa-pen mr-2" />
                    Update
                  </button>
                </div>
              )}
            </header>
            {!_.isEmpty(signatoryInformation) ? (
              <div>
                <div className="p-3">
                  <h6 className="h6 text-xs text-grey-1000 font-weight-bold p-0 m-0">
                    Signatory Information
                  </h6>
                </div>
                <div className="p-4 bg-white">
                  <div className="row">
                    <div className="col-lg-3 col-xl-2 d-flex flex-column text-xs text-grey-1000">
                      <span className="label font-weight-bold mb-2">
                        First Name
                      </span>
                      <p className="description mb-0 break-word">
                        {_.get(
                          signatoryInformation,
                          '0.attributes.first_name'
                        ) || '--'}
                      </p>
                    </div>
                    <div className="col-lg-3 col-xl-2 d-flex flex-column text-xs text-grey-1000">
                      <span className="label font-weight-bold mb-2">
                        Middle Initial
                      </span>
                      <p className="description mb-0 break-word">
                        {_.get(
                          signatoryInformation,
                          '0.attributes.middle_name'
                        ) || '--'}
                      </p>
                    </div>
                    <div className="col-lg-3 col-xl-2 d-flex flex-column text-xs text-grey-1000">
                      <span className="label font-weight-bold mb-2">
                        Last Name
                      </span>
                      <p className="description mb-0 break-word">
                        {_.get(
                          signatoryInformation,
                          '0.attributes.last_name'
                        ) || '--'}
                      </p>
                    </div>
                    <div className="col-lg-3 col-xl-2 d-flex flex-column text-xs text-grey-1000">
                      <span className="label font-weight-bold mb-2">
                        Contact No.
                      </span>
                      <p className="description mb-0 break-word">
                        {_.get(
                          signatoryInformation,
                          '0.attributes.contact_number'
                        ) || '--'}
                      </p>
                    </div>
                  </div>
                </div>
                {!_.isEmpty(otherSignatories) && (
                  <div>
                    <div className="p-3">
                      <h6 className="h6 text-xs text-grey-1000 font-weight-bold p-0 m-0">
                        Other Authorized Signatories
                      </h6>
                    </div>
                    <div className="p-4 bg-white">
                      {otherSignatories.map((item, index) => (
                        <div className="row mb-4" key={_.get(item, 'id')}>
                          <div className="col-lg-3 col-xl-2 d-flex align-items-center text-xs text-grey-1000">
                            <div
                              className="mr-3 rounded-circle bg-light-200 d-flex align-items-center justify-content-center"
                              style={{
                                minWidth: '1.3rem',
                                minHeight: '1.3rem',
                              }}
                            >
                              <span className="text-dark">{index + 1} </span>
                            </div>
                            <div>
                              <div className="label font-weight-bold mb-2">
                                Full Name
                              </div>
                              <p className="description mb-0 break-word">
                                {_.get(item, 'attributes.full_name') || '--'}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-3 col-xl-2 d-flex flex-column text-xs text-grey-1000">
                            <div className="label font-weight-bold mb-2">
                              Position
                            </div>
                            <p className="description mb-0 break-word">
                              {_.get(item, 'attributes.position') || '--'}
                            </p>
                          </div>
                          <div className="col-lg-3 col-xl-2 d-flex flex-column text-xs text-grey-1000 break-word">
                            <div className="label font-weight-bold mb-2">
                              Email Address
                            </div>
                            <p className="description mb-0 break-word">
                              {_.get(item, 'attributes.email') || '--'}
                            </p>
                          </div>
                          <div className="col-lg-3 col-xl-2 d-flex flex-column text-xs text-grey-1000">
                            <div className="label font-weight-bold mb-2">
                              Contact No.
                            </div>
                            <p className="description mb-0 break-word">
                              {_.get(item, 'attributes.contact_number') || '--'}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-center p-4 bg-white">
                <NoDataPlaceholder />
              </div>
            )}
          </div>
        </div>
      )}

      <MoaSignatoryInformationUpdate />
    </>
  );
};

MoaSignatoryInformation.propTypes = {
  dealer_id: PropTypes.string.isRequired,
};

export default React.memo(MoaSignatoryInformation);
