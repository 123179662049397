/*eslint-disable*/
import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Select from 'react-select';
import { ModalContext } from 'App';
import Button from 'components/Button';
import { ToastError } from 'modules/common/components/Toast';
import LoadRetailerBG from '../../../assets/images/LoadRetailer_BG.svg';
import TransactionConfirmForm from '../modalForms/TransactionConfirmForm';
import ForgotPinForm from '../modalForms/ForgotPinForm';
import ChangePinForm from '../modalForms/ChangePinForm';
import LoadThreshold from '../components/LoadThreshold';
import { useLoading } from '../../ui/hooks';
import * as actions from '../actions';
import * as c from '../constant';

import '../styles/DealerToRetailer.style.scss';

const initForm = {
  branch: {
    id: '',
    name: '',
  },
  dealer_min: '',
  target_min: '',
  amount: '',
  type: 'external',
};
const initThresholdBarReq = {
  threshold: {
    isSuccess: false,
    value: 0,
  },
  balance: {
    isSuccess: false,
    value: 0,
  },
};
const errorTargetMinInit = {
  length: '',
  char: '',
};

function DealerToRetailer({
  getBranch,
  branchDetails,
  getLoadThreshold,
  loadThreshold,
  getLoadBalance,
  postLoadRetailer,
  validateMPin,
  retailer,
  retailerId,
  showRetailer,
  showRetailerUses,
  roles,
}) {
  const setModal = useContext(ModalContext);
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [form, setForm] = useState(initForm);
  const [dealerMinOptions, setDealerMinOptions] = useState([]);
  const [loadBalance, setLoadBalance] = useState(0);
  const [loadThresholdValue, setLoadThresholdValue] = useState(0);
  const isLoadingBranch = useLoading(c.GET_BRANCH);
  const isLoadingThreshold = useLoading(c.GET_DEALER_MIN_THRESHOLD);
  const [isLoadingBalance, setIsLoadingBalance] = useState(false);
  const isLoadingRetailer = useLoading(c.SHOW_RETAILER);
  const [dealerSimsNormalized, setDealerSimsNormalized] = useState([]);
  const [errorTragetMin, setErrorTragetMin] = useState(errorTargetMinInit);
  const [errorAmount, setErrorAmount] = useState('');
  const [thresholdBarReq, setThresholdBarReq] = useState(initThresholdBarReq);
  const [hasErrors, setHasErrors] = useState(false);
  const [isThresholdBarLoading, setIsThresholdBarLoading] = useState(true);
  const [isProfileLoading, setIsProfileLoading] = useState(false);

  const isRetailerWithinSchedule = _.get(
    retailer,
    'attributes.has_ongoing_schedule',
    null
  );

  const setThresholdAndBalance = (id, minValue) => {
    setIsThresholdBarLoading(true);
    setLoadThresholdValue(0);
    setLoadBalance(0);
    setThresholdBarReq(initThresholdBarReq);
    getLoadThreshold(id, {}, response => {
      setThresholdBarReq(s => ({
        ...s,
        threshold: {
          isSuccess: true,
          value: _.get(response, 'data.attributes.load_threshold', 0),
        },
      }));
    });
    setIsLoadingBalance(true);
    getLoadBalance(
      { mobile: _.replace(minValue, '+63', '0') },
      response => {
        setThresholdBarReq(s => ({
          ...s,
          balance: {
            isSuccess: true,
            value: _.get(response, 'data.current_balance', 0),
          },
        }));
        setIsLoadingBalance(false);
      },
      () => {
        setIsLoadingBalance(false);
      }
    );
  };

  const setBranchInForm = () => {
    setForm(state => ({
      ...state,
      branch:
        {
          id: _.get(branchDetails, 'id'),
          name: _.get(branchDetails, 'attributes.name'),
        } || {},
    }));
  };

  const setDefaultDealerMin = source => {
    const { mobile_identification_number, sim_type, sim_id } = source?.attributes
    if (source.attributes.is_default) {
      setForm(state => ({
        ...state,
        dealer_min: {
          value: mobile_identification_number,
          label: `${mobile_identification_number} ${sim_type ? `- ${sim_type}` : ''}`,
          dealerMinId: sim_id,
        },
      }));
      setThresholdAndBalance(
        sim_id,
        mobile_identification_number
      );
    }
  };

  const getDealerSims = () => {
    return _.get(branchDetails, 'included.sources', [])
      .filter(source => source.attributes.type === 'dealer_sims')
      .filter(source => source.attributes.is_active === true)
      .map(source => {
        setDefaultDealerMin(source);
        const { mobile_identification_number, sim_type, sim_id } = source?.attributes
        return {
          value: mobile_identification_number,
          label: `${mobile_identification_number} ${sim_type ? `- ${sim_type}` : ''}`,
          dealerMinId: sim_id,
        };
      });
  };

  const handleOnProceed = e => {
    e.preventDefault();
    setIsProfileLoading(true);
    showRetailerUses({}, res => {
      if (_.get(res, 'data.attributes.has_ongoing_schedule')) {
        setIsProfileLoading(false);

        if (_.isUndefined(_.get(dealerSimsNormalized, form.target_min))) {
          setModal('confirm-load-retailer', { payload: form });
          return;
        }

        ToastError(
          'Recipient Mobile Number cannot be one of assigned Dealer MINs.'
        );
      }
    });
  };
  const handleCloseSuccessAlert = e => {
    e.preventDefault();
    setSuccessAlertVisible(false);
  };

  const handleOnChangeForm = e => {
    const { name, value } = e.target;
    setForm(state => ({
      ...state,
      [name]: value,
    }));
  };

  const handleOnChangeTragetMin = e => {
    const { value } = e.target;
    const errorMsgLength = value.length < 11 ? 'Must be 11 digits.' : '';
    const errorMsgChar = /^\d+$/.test(value) ? '' : 'Only numbers allowed.';
    setErrorTragetMin({ length: errorMsgLength, char: errorMsgChar });
    setHasErrors(!_.isEmpty(errorMsgLength) || !_.isEmpty(errorMsgChar));
    setForm(state => ({
      ...state,
      target_min: value,
    }));
  };

  const handleOnChangeAmount = e => {
    const { value } = e.target;
    const errorMsgChar = /^\d+$/.test(value)
      ? ''
      : 'Only positive whole numbers allowed.';
    setErrorAmount(errorMsgChar);
    setHasErrors(!_.isEmpty(errorMsgChar));
    setForm(state => ({
      ...state,
      amount: value,
    }));
  };

  const handleSelectDealerMin = selectedOption => {
    setForm(state => ({
      ...state,
      dealer_min: selectedOption,
    }));
    setBranchInForm();
    setThresholdAndBalance(selectedOption.dealerMinId, selectedOption.value);
  };

  const resetFormData = () => {
    setForm(state => ({
      ...state,
      target_min: initForm.target_min,
      amount: initForm.amount,
      type: initForm.type,
    }));
  };

  useEffect(() => {
    getBranch();
    if (_.get(roles, '0') === 'kapartner-user') {
      showRetailer(retailerId);
    }
    if (
      _.get(roles, '0') === 'branch-admin' ||
      _.get(roles, '0') === 'frontliner'
    ) {
      showRetailerUses();
    }
  }, [getBranch, showRetailer, showRetailerUses, roles]);

  useEffect(() => {
    setBranchInForm();
    const dealerSims = getDealerSims();
    setDealerSimsNormalized(
      _.mapKeys(
        dealerSims.map(sim => ({ value: _.replace(sim.value, '+63', '0') })),
        'value'
      )
    );
    setDealerMinOptions(dealerSims);
  }, [branchDetails]);

  useEffect(() => {
    if (
      thresholdBarReq.threshold.isSuccess &&
      thresholdBarReq.balance.isSuccess
    ) {
      setLoadThresholdValue(thresholdBarReq.threshold.value);
      setLoadBalance(thresholdBarReq.balance.value);
    }
  }, [thresholdBarReq.threshold.isSuccess, thresholdBarReq.balance.isSuccess]);

  useEffect(() => {
    if (!isLoadingBranch && !isLoadingThreshold && !isLoadingBalance) {
      setIsThresholdBarLoading(false);
    }
  }, [isLoadingBranch, isLoadingThreshold, isLoadingBalance]);

  const successAlert = (
    <div className="position-absolute w-100 bg-primary loadRetailer__success-alert-container">
      <div className="d-flex justify-content-between align-items-center">
        <div className="position-relative">
          <div className="mr-2 position-absolute loadRetailer__success-alert" />
          <span className="font-weight-bold mr-2">SUCCESS ALERT:</span>
          <span>Your Security PIN has been updated successfully.</span>
        </div>
        <div>
          <button
            type="button"
            className="close loadRetailer__success-close"
            onClick={handleCloseSuccessAlert}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
  );

  if (_.isNil(isRetailerWithinSchedule) && isLoadingRetailer)
    return (
      <div className="d-flex justify-content-center align-items-center h-100 ">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  if (!isRetailerWithinSchedule)
    return (
      <div className="container-fluid pt-5">
        <div className="alert alert-danger" role="alert">
          You are not allowed to use this feature outside your selling window.
          If this is not the case, please contact your System Administrator.
        </div>
      </div>
    );

  return (
    <div className="container-flow p-5 position-relative loadRetailer">
      {successAlertVisible ? successAlert : null}
      <div>
        <h4 className="font-weight-bold mt-0 mb-2">Load Retailer</h4>
      </div>
      <div>
        <p>
          Select Dealer MIN wallet and input mobile number and amount to be
          transferred.
        </p>
      </div>
      <div className="d-flex mt-4 position-relative loadRetailer__box-container">
        <div className="position-relative loadRetailer__loadBalance-container">
          {!isLoadingBranch &&
            !isLoadingThreshold &&
            !isLoadingBalance ? null : (
              <div
                className="spinner-border text-light position-absolute loadRetailer__loadBalance-spinner"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            )}
          <div className="loadRetailer__loadBalance-title">
            Load Wallet Balance
          </div>
          <div className="mb-4 loadRetailer__loadBalance-branch-group">
            <label className="loadRetailer__loadBalance-branch-label">
              Branch:
            </label>
            <input
              className="form-control form-custom form-control_bottom loadRetailer__loadBalance-branch-input"
              type="text"
              name="branch"
              required
              onChange={handleOnChangeForm}
              value={_.get(branchDetails, 'attributes.name') || ''}
              readOnly
            />
          </div>
          <div className="mb-4 loadRetailer__loadBalance-dealerMin-group">
            <label className="loadRetailer__loadBalance-dealerMin-label">
              {' '}
              Dealer MIN:{' '}
            </label>
            <div className="loadRetailer__loadBalance-dealerMin-select-container">
              <Select
                options={dealerMinOptions}
                onChange={handleSelectDealerMin}
                value={form.dealer_min}
                styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }}
                isDisabled={isThresholdBarLoading}
                placeholder="Select Min"
              />
            </div>
          </div>
          <div className="mb-4 loadRetailer__loadBalance-threshold-group">
            <label className="loadRetailer__loadBalance-threshold-input">
              Load Wallet Threshold:{' '}
            </label>
            <div className="progress-bar" style={{ border: 'solid 2px white' }}>
              <LoadThreshold
                threshold={loadThresholdValue}
                balance={loadBalance}
              />
            </div>
          </div>
        </div>
        <div className="w-50 loadRetailer__transfer-container">
          <div className="loadRetailer__transfer-title">Transfer Load to</div>
          <div className="col-xl-10 px-0">
            <div className="mb-lg-4 mb-xl-0">
              <label className="form-label text-primary">
                {' '}
                Recipient Mobile Number:{' '}
              </label>
              <input
                className="form-control form-custom form-control_bottom lighter_placeholder_color"
                type="text"
                name="target_min"
                placeholder="Example: 09076543210"
                required
                onChange={handleOnChangeTragetMin}
                value={form.target_min}
                maxLength="11"
              />
              <div className="px-2 loadRetailer__transfer-feedback">
                <em>
                  {form.target_min &&
                    `${errorTragetMin.length} ${errorTragetMin.char}`}
                </em>
              </div>
            </div>
            <div>
              <label className="form-label text-primary"> Input Amount: </label>
              <input
                className="form-control form-custom form-control_bottom"
                type="text"
                name="amount"
                required
                onChange={handleOnChangeAmount}
                value={form.amount}
              />
              <div className="px-2 loadRetailer__transfer-feedback mb-lg-4 mb-xl-0">
                <em>{form.amount && `${errorAmount}`}</em>
              </div>
            </div>
          </div>
          <div className="loadRetailer__transfer-proceed-button-container">
            <Button
              type="button"
              onClick={handleOnProceed}
              className="btn btn-primary btn-block position-relative py-2 text-uppercase loadRetailer__transfer-proceed-button"
              disabled={
                _.some(form, field => {
                  return _.isEmpty(field);
                }) ||
                hasErrors ||
                isThresholdBarLoading ||
                _.isEmpty(_.get(form, 'dealer_min.value', ''))
              }
              loading={isProfileLoading}
            >
              Proceed
            </Button>
          </div>
        </div>
        <img
          className="loadRetailer__transfer-img"
          src={LoadRetailerBG}
          alt="Load Retailer BG"
        />
      </div>
      <TransactionConfirmForm
        formData={form}
        simType={_.get(loadThreshold, 'item.attributes.type', '')}
        postLoadRetailer={postLoadRetailer}
        validateMPin={validateMPin}
        setFormData={resetFormData}
      />
      <ForgotPinForm />
      <ChangePinForm setSuccessAlertVisible={setSuccessAlertVisible} />
    </div>
  );
}

DealerToRetailer.propTypes = {
  getBranch: PropTypes.instanceOf(Function).isRequired,
  branchDetails: PropTypes.instanceOf(Object).isRequired,
  getLoadThreshold: PropTypes.instanceOf(Function).isRequired,
  loadThreshold: PropTypes.instanceOf(Object).isRequired,
  getLoadBalance: PropTypes.instanceOf(Function).isRequired,
  postLoadRetailer: PropTypes.instanceOf(Function).isRequired,
  validateMPin: PropTypes.instanceOf(Function).isRequired,
  retailer: PropTypes.instanceOf(Object).isRequired,
  retailerId: PropTypes.string.isRequired,
  showRetailer: PropTypes.instanceOf(Function).isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  showRetailerUses: PropTypes.instanceOf(Function).isRequired,
};

const mapStateToProps = ({ api }) => ({
  branchDetails: _.get(api, `${c.GET_BRANCH}.item`, {}),
  loadThreshold: _.get(api, `${c.GET_DEALER_MIN_THRESHOLD}`, {}),
  retailer: _.get(api, `${c.SHOW_RETAILER}.item`) || {},
  retailerId:
    _.get(api, 'AUTH/user_profile.item.retailer_id') ||
    _.get(api, 'AUTH/user_profile.item.id'),
  roles: _.get(api, 'AUTH/user_profile.item.roles') || [],
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(DealerToRetailer);
