import React from 'react';
import PropTypes from 'prop-types';

const CardItem = ({ label, description, status }) => (
  <div className="col-xl">
    <div
      className="font-weight-bold mb-2"
      style={{ fontSize: '.6rem', color: 'rgba(0, 0, 0, .6)' }}
    >
      {label}
    </div>
    {!status ? (
      <p
        className="mb-0"
        style={{ fontSize: '.7rem', color: 'rgba(0, 0, 0, .7)',whiteSpace:'nowrap' }}
      >
        {description}
      </p>
    ) : (
      <p
        className={`mb-0 font-weight-bold ${
          description ? 'text-success' : 'text-secondary'
        }`}
        style={{ fontSize: '.7rem', color: 'rgba(0, 0, 0, .7)' }}
      >
        {description ? 'Active' : 'Inactive'}
      </p>
    )}
  </div>
);

CardItem.defaultProps = {
  status: false,
};

CardItem.propTypes = {
  status: PropTypes.bool,
  label: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default CardItem;
