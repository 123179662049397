import React from 'react';
import PropTypes from 'prop-types';
import Logo from 'assets/images/dealers-portal-logo-sm.png';

const Layout = ({ children }) => {
  return (
    <div className="forgot-password-page">
      <div className="layer" />
      <div className="main-content d-flex align-items-center justify-content-center mx-auto py-4 px-3 position-relative">
        <div className="w-100">
          <img
            src={Logo}
            alt="PLDT / SMART Dealer's Portal"
            className="d-block mx-auto mb-5"
          />
          <div style={{ maxWidth: 450, height: 500 }} className="w-100 mx-auto">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Layout;
