import React from "react";

const SVGComponent = (props) => (
  <svg
    width={1920}
    height={1024}
    viewBox="0 0 1920 1024"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <rect width={1920} height={1024} fill="white" />
      <ellipse
        cx={1833.36}
        cy={-66.2638}
        rx={536.404}
        ry={242.217}
        transform="rotate(29.4342 1833.36 -66.2638)"
        fill="#23AA4B"
      />
      <ellipse
        cx={575.801}
        cy={1286.49}
        rx={803.442}
        ry={491.693}
        transform="rotate(17.7747 575.801 1286.49)"
        fill="#CB2D3B"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={1920} height={1024} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SVGComponent;
