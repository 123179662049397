import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Img from 'modules/common/components/Img';

const UploadPreview = ({ renderIcon, file }) => {
  const renderPartial = (xtn, item) => {
    const imgF = ['jpeg', 'jpg', 'png'];
    const detectWhatToRender = () => {
      if (imgF.indexOf(xtn) > -1) {
        return 'img';
      }
      return 'application';
    };

    try {
      const dispatchRender = {
        img: () => (
          <Img
            src={item}
            className="img-fluid mr-2"
            role="presentation"
            alt={item}
            style={{
              border: '1px solid lightgray',
              borderRadius: 5,
              width: 102,
              height: 72,
            }}
          />
        ),
        application: () => (
          <a
            href={item}
            className="d-flex justify-content-center align-items-center mr-2"
            target="_heopenit"
            style={{
              border: '1px solid lightgray',
              borderRadius: 5,
              width: 102,
              height: 72,
            }}
          >
            {renderIcon(xtn)}
          </a>
        ),
      };

      return dispatchRender[detectWhatToRender()]
        ? dispatchRender[detectWhatToRender()]()
        : dispatchRender.default();
    } catch (error) {
      // eslint-disable-next-line no-console
      return null;
    }
  };

  return (
    <div>
      <hr />
      <div className="row mb-2 px-3">
        {_.get(file, 'files').map(x => {
          const ext = (x.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1];
          return <div key={x}>{renderPartial(ext, x)}</div>;
        })}
      </div>
    </div>
  );
};

UploadPreview.propTypes = {
  renderIcon: PropTypes.func.isRequired,
  file: PropTypes.instanceOf(Object),
};

UploadPreview.defaultProps = {
  file: {},
};

export default UploadPreview;
