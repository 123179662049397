import React, { useContext } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Img from 'modules/common/components/ImgDoc';
import { ModalContext } from 'App';
import { useLoading } from 'modules/ui/hooks';
import placeholderPhoto from 'assets/images/logo-loader.png';
import UpdateUserDetails from '../../../modal/UpdateUserDetails';
import * as c from '../../../constants';

const UserAccessDetails = () => {
  const setModal = useContext(ModalContext);
  const dealer_id = useSelector(({ api }) =>
    _.get(api, 'AUTH/user_profile.item.id')
  );
  const userDetails = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_USER_DETAILS}.item`)
  );

  const isLoading = useLoading(c.SHOW_USER_DETAILS);

  const openUpdateModal = () => {
    const previousData = {
      dealer_id,
      first_name: _.get(userDetails, 'attributes.first_name', ''),
      last_name: _.get(userDetails, 'attributes.last_name', ''),
      email: _.get(userDetails, 'attributes.email', ''),
      mobile: _.get(userDetails, 'attributes.mobile', ''),
      profile_img: _.get(userDetails, 'attributes.profile_img', ''),
      is_express: _.get(userDetails, 'attributes.is_express'),
    };

    setModal('update-user-details', previousData);
  };

  return (
    <>
      <div className="UserAccessDetails my-4">
        <header className="rounded-top-both bg-grey-800 overflow-hidden text-white d-flex justify-content-between pl-3">
          <div className="header-title my-auto">
            <h6 className="h6 text-xs font-weight-bold p-0 m-0">
              User Access Details
            </h6>
          </div>
          <div className="invisible">
            <button
              type="button"
              className="btn text-xs btn-success rounded-0 py-2"
              onClick={openUpdateModal}
              disabled={isLoading}
            >
              <i className="fas fa-pen mr-2" />
              Update
            </button>
          </div>
        </header>
        <div className="p-4 bg-white">
          <div className="row">
            <div className="col-2 pr-0 mr-2" style={{ minWidth: '12rem' }}>
              <figure
                className="border d-flex align-items-center justify-content-center"
                style={{ height: '10rem' }}
              >
                <Img
                  lightBox
                  withZoom
                  defaultSrc={placeholderPhoto}
                  src={_.get(userDetails, 'attributes.profile_img')}
                />
              </figure>
            </div>
            <div className="col-7 text-light-gray">
              <div className="row mb-4">
                <div className="col-4 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    First Name
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(userDetails, 'attributes.first_name', '--')}
                  </p>
                </div>
                <div className="col-4 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">Last Name</span>
                  <p className="description mb-0 break-word">
                    {_.get(userDetails, 'attributes.last_name', '--')}
                  </p>
                </div>

                <div className="col-4 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    Mobile No.
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(userDetails, 'attributes.mobile', '--')}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-6 d-flex flex-column text-xs text-grey-1000">
                  <span className="label font-weight-bold mb-2">
                    Email Address
                  </span>
                  <p className="description mb-0 break-word">
                    {_.get(userDetails, 'attributes.email', '--')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdateUserDetails />
    </>
  );
};
export default React.memo(UserAccessDetails);
