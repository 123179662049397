import React, { useContext } from "react";
import * as Icon from "components/Icons";
import IconTooltip from "modules/common/components/IconTooltip";
import Pagination from "modules/common/components/Pagination";
import moment from "moment";
import _ from "lodash";
import CardItem from "modules/common/components/CardItem";
import DealerSimActions from "modules/sim/components/DealerSimActions";
import { useDealerSim } from "app/load-wallet/dealer-sims/context/DealerSimProvider";
import { useFilterSim } from "app/load-wallet/context/SimFilterProvider";
import { useDealer } from "app/load-wallet/context/DealerProvider";
import { useDispatch } from "react-redux";
import { checkPermissions, removeEmpty } from "helper";
import { ModalContext } from "App";
import ConfirmOTP from "modules/sim/containers/ConfirmOTP";
import ViewBalance from "modules/sim/containers/ViewBalance";
import SetThreshold from "modules/sim/containers/SetThreshold";
import Transfer from "modules/sim/containers/Transfer";
import TransferConfirm from "modules/sim/containers/TransferConfirm";
import TabDetails from "./TabDetails";
import ChangeTopOrg from "../modalForms/ChangeTopOrg";
import VerifyTopOrg from "../modalForms/VerifyTopOrg";
import ChangeTopOrgOTP from "./ChangeTopOrgOTP";
import DealerSimForm from "../modalForms/DealerSimForm";
import UpdateDealerSimForm from "../modalForms/UpdateDealerSimForm";
import DelinkingDealerSim from "../modalForms/DelinkingDealerSim";
import DealerSimPasswordConfirmation from "./DealerSimPasswordConfirmation";
import { getDealerSources } from "app/dashboard/actions";
import * as c from "../constant";
import * as a from "../actions";

const AddIcon = () => <Icon.Add fill="#fff" />;

const DealerSimsList = () => {
  const {
    dealerRole,
    accountTypeName,
    simDealerId,
    role,
    profile,
  } = useDealer();

  const { filterData } = useFilterSim();

  const {
    dealerSimSliced,
    dealerSimsPager,
    dealerSimsLoading,
  } = useDealerSim();
  const isBA = role?.includes("branch-admin");
  const dispatch = useDispatch();

  const ChangePage = (page) => {
    isBA
      ? dispatch(
          getDealerSources(
            removeEmpty({
              ...filterData,
              page,
              sim_status: filterData?.sim_status?.value || "",
              status: filterData?.status?.value || "",
              retailer_id: profile?.retailer_id,
            })
          )
        )
      : dispatch(
          a.getDealerSims(
            removeEmpty({
              dealer_id: simDealerId,
              userType: "dealers",
              ...filterData,
              page,
              sim_status: filterData?.sim_status?.value || "",
              status: filterData?.status?.value || "",
            })
          )
        );
  };

  return (
    <>
      <TabDetails
        isLoading={dealerSimsLoading}
        headerTitle={() => {
          return <AddDealerSim />;
        }}
        data={dealerSimSliced}
        render={(item, index) => {
          const {
            status,
            delete_evc_remarks,
            dms_remarks,
            remarks,
            type,
            delete_status,
          } = item?.attributes;
          const iconClassName = `fas fa-exclamation-circle icon-tooltip
           ${
             status?.toLowerCase() === c.DEALERSIM_STATUS.approved.toLowerCase()
               ? "text-warning"
               : "text-danger"
           }`;
          const allRemarks = delete_evc_remarks || dms_remarks || remarks;
          const isProcessing =
            status?.toLowerCase() ===
              c.DEALERSIM_STATUS.processing.toLowerCase() ||
            delete_status?.toLowerCase() ===
              c.DEALERSIM_STATUS.processing.toLowerCase();

          return (
            <div className="col-lg-4 col-xl-12 pb-3 pb-lg-3" key={index}>
              <div className="card">
                <div
                  className="row card-body"
                  style={{ opacity: isProcessing ? 0.5 : 1 }}
                >
                  <div className="col row pr-0 d-flex">
                    {!!allRemarks && (
                      <IconTooltip
                        icon={iconClassName}
                        text={allRemarks}
                        id={item?.id}
                        backgroundColor="black"
                      />
                    )}
                    <CardItem label="SIM Type" description={type || "-"} />
                    <CardItem
                      label="Dealer MIN"
                      description={
                        item?.attributes?.mobile_identification_number || "-"
                      }
                    />
                    <CardItem
                      label="Assign to"
                      description={_.truncate(item?.attributes?.assigned_to, {
                        length: c.TEXT_LIMIT,
                        separator: " ",
                      })}
                    />
                    <CardItem
                      label="Date Created"
                      description={moment(item?.attributes?.created_at).format(
                        "lll"
                      )}
                    />
                    <CardItem
                      label="Request Type"
                      description={item?.attributes?.request_type || "-"}
                    />
                    <CardItem
                      label="Request Status"
                      description={status || "-"}
                    />
                    <CardItem
                      label="SIM Status"
                      description={item?.attributes?.sim_status || "-"}
                    />
                  </div>

                  <div className="col-2 text-right">
                    {!isProcessing && (
                      <>
                        <div
                          className="font-weight-bold"
                          style={{
                            fontSize: ".7rem",
                            color: "rgba(0, 0, 0, .6)",
                          }}
                        >
                          Action
                        </div>
                        <div>
                          <DealerSimActions
                            item={item}
                            accountTypeName={accountTypeName}
                            dealerRole={dealerRole}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      />

      <Pagination
        className="d-flex justify-content-between p-2 border-top border-muted"
        onChange={ChangePage}
        data={dealerSimsPager}
        withPage
      />

      <DealerSimForm />
      <UpdateDealerSimForm />
      <DelinkingDealerSim />
      <ViewBalance />
      <SetThreshold />
      <Transfer />
      <TransferConfirm />
      <ChangeTopOrg />
      <ChangeTopOrgOTP />
      <VerifyTopOrg />
      <ConfirmOTP />
      <DealerSimPasswordConfirmation />
    </>
  );
};

export default DealerSimsList;

const AddDealerSim = () => {
  const { dealerRole, accountTypeName, selectedDealer } = useDealer();
  const { dealerSimsLoading } = useDealerSim();

  const setModal = useContext(ModalContext);
  const newDealerSim = (dealerStatus) =>
    setModal("fill-dealer-sim", {
      dealerStatus,
      dealerData: selectedDealer,
      accountTypeName,
    });

  return (
    <>
      <div className="header-title my-auto">
        <h6 className="h6 text-md-size font-weight-bold p-0 m-0">
          Manage Dealer SIMs
        </h6>
      </div>
      {checkPermissions(["can-create-dealer-sim"]) ? (
        <div>
          <button
            type="button"
            className="d-flex btn text-md-size btn-success rounded-0 py-2 rounded-top-right"
            disabled={dealerSimsLoading}
            onClick={() => newDealerSim(dealerRole)}
          >
            <AddIcon />
            <span className="ml-2">add new sim</span>
          </button>
        </div>
      ) : (
        <button
          type="button"
          className="d-flex btn text-md-size rounded-0 py-4 rounded-top-right"
          disabled
        />
      )}
    </>
  );
};
