import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import ReactInputMask from 'react-input-mask';
import Select from 'react-select';
import { ToastSuccess } from 'modules/common/components/Toast';
import { useLoading } from 'modules/ui/hooks';

import withModal from 'modules/common/hoc/withModal';
import { ModalContext } from 'App';
import { removeNull, removeEmpty, middleNameValidator } from 'helper';

import FormInputBorderBottom from 'modules/common/components/input/FormInputBorderBottom';
import ButtonAsync from 'components/Button';
import Calendar from '../../components/Calendar';

import * as c from '../constants';
import * as actions from '../actions';

const UpdateRetailer = () => {
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);

  const prevData = useSelector(
    ({ modal }) => _.get(modal, 'modalData.item.item', []) || []
  );

  const filterData = useSelector(
    ({ modal }) => _.get(modal, 'modalData.item.filterData', []) || []
  );

  const is_mobile = !!prevData?.attributes?.mobile_app_registered_at;

  const retailerAddress = useSelector(
    ({ api }) => _.get(api, `${c.SHOW_RETAILER_ADDRESS}.item`, {}) || {}
  );

  const userProfile = useSelector(
    ({ api }) => _.get(api, 'AUTH/user_profile.item', {}) || {}
  );

  const [initialData, setInitialData] = useState(
    c.addUpdateRetailerDefaultValue
  );
  const [pending, setPending] = useState(false);

  const selectorApi = useSelector(({ api }) => ({
    accountTypeList: _.get(api, `${c.GET_RETAILER_TYPES}.list`, []) || [],
    provinceList: _.get(api, `${c.GET_PROVINCE_LIST}.list`, []) || [],
    cityList: _.get(api, `${c.GET_CITY_LIST}.list`, []) || [],
    barangayList: _.get(api, `${c.GET_BARANGAY_LIST}.list`, []) || [],
  }));

  const { accountTypeList, provinceList, cityList, barangayList } = selectorApi;

  const userFullName = _.get(userProfile, 'full_name', '') || '';

  const retailerId = _.get(prevData, 'id');

  const handleChangeOtherFields = e => {
    const { name, value } = e.target;
    setInitialData({
      ...initialData,
      [name]: value,
    });
  };

  const handleMiddleName = ({ target: { value } }) => {
    if (middleNameValidator(value) || value === '') {
      setInitialData({
        ...initialData,
        middle_name: value,
      });
    }
  };

  const resetCityBarangay = useCallback(() => {
    dispatch(actions.resetCity());
    dispatch(actions.resetBarangay());
  }, []);

  const handleCloseModal = () => {
    resetCityBarangay();
    dispatch(actions.resetRetailerAdd());
    setModal({ isOpen: false });
  };

  const handleSelectDate = ({ target }) =>
    setInitialData({
      ...initialData,
      birth_date: _.get(target, 'value'),
    });

  const handleChangeAccountType = selectedItem =>
    setInitialData({ ...initialData, retailer_type: selectedItem });

  const handleProvince = value => {
    setInitialData({
      ...initialData,
      province: value,
      city_municipality: '',
      created_by_name: userFullName,
      barangay: '',
      postal_code: '',
    });

    if (value) resetCityBarangay();
  };

  const handleMunicipality = value => {
    setInitialData({
      ...initialData,
      city_municipality: value,
      barangay: '',
      postal_code: '',
    });
  };

  const handleBarangay = value =>
    setInitialData({
      ...initialData,
      barangay: value,
      postal_code: _.get(value, 'zip'),
    });

  const isInputEmpty = useMemo(() => {
    const removedFields = _.omit(initialData, [
      'middle_name',
      'birth_date',
      'email',
    ]);
    return Object.values(removedFields).filter(y => y === '').length > 0;
  }, [initialData]);

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);

    const payload = {
      ...initialData,
      updated_by_name: userFullName,
      retailer_type: initialData?.retailer_type?.label,
      barangay: initialData?.barangay?.label,
      city_municipality: initialData?.city_municipality?.label,
      province: initialData?.province?.label,
      birth_date: initialData?.birth_date
        ? moment(_.get(initialData, 'birth_date')).format('YYYY-MM-DD')
        : null,
    };

    const successCallback = () => {
      dispatch(actions.getRetailerList(removeEmpty(filterData)));
      ToastSuccess('Successfully updated retailer.');
      setPending(false);
      handleCloseModal();
      setPending(false);
    };

    const errCallback = () => setPending(false);

    dispatch(
      actions.updateRetailer(
        retailerId,
        removeNull(payload),
        successCallback,
        errCallback
      )
    );
  };

  const newAccountTypeList = useMemo(
    () =>
      accountTypeList.map(({ id, attributes: { title } }) => {
        const retailer_type = prevData?.attributes?.retailer_type;
        const isMatch =
          _.toLower(id) === _.toLower(retailer_type) ||
          _.toLower(title) === _.toLower(retailer_type);
        if (isMatch) {
          setInitialData(state => ({
            ...state,
            retailer_type: {
              value: id,
              label: title,
            },
          }));
        }
        return {
          value: id,
          label: title,
        };
      }),
    [accountTypeList]
  );

  const provinceOptions = useMemo(
    () =>
      provinceList.map(({ attributes: { name, code } }) => {
        if (
          _.get(retailerAddress, 'attributes.address.province') &&
          _.toLower(name) ===
            _.toLower(_.get(retailerAddress, 'attributes.address.province'))
        ) {
          setInitialData(state => ({
            ...state,
            province: {
              value: code,
              label: name,
            },
          }));
        }
        return {
          value: code,
          label: name,
        };
      }),
    [provinceList]
  );

  const cityOptions = useMemo(
    () =>
      cityList.map(({ attributes: { name, code } }) => {
        if (
          _.get(retailerAddress, 'attributes.address.city_municipality') &&
          _.toLower(name) ===
            _.toLower(
              _.get(retailerAddress, 'attributes.address.city_municipality')
            )
        ) {
          setInitialData(state => ({
            ...state,
            city_municipality: {
              value: code,
              label: name,
            },
          }));
        }
        return {
          value: code,
          label: name,
        };
      }),
    [cityList]
  );

  const barangayOptions = useMemo(
    () =>
      barangayList.map(({ attributes: { name, code, zip } }) => {
        if (
          _.get(retailerAddress, 'attributes.address.barangay') &&
          _.toLower(name) ===
            _.toLower(_.get(retailerAddress, 'attributes.address.barangay'))
        ) {
          setInitialData(state => ({
            ...state,
            barangay: {
              value: code,
              label: name,
              zip,
            },
          }));
        }
        return { value: code, label: name, zip };
      }),
    [barangayList]
  );

  useEffect(() => {
    if (retailerId) dispatch(actions.showRetailerAddress(retailerId));
  }, [retailerId]);

  useEffect(() => {
    const previousData = {
      first_name: _.get(prevData, 'attributes.first_name', '') || '',
      middle_name: _.get(prevData, 'attributes.middle_name', '') || '',
      last_name: _.get(prevData, 'attributes.last_name', '') || '',
      birth_date: _.get(prevData, 'attributes.birth_date', '') || '',
      email: _.get(prevData, 'attributes.email', '') || '',
      mobile_identification_number:
        _.get(prevData, 'attributes.mobile_identification_number', '') || '',
      line: _.get(retailerAddress, 'attributes.address.line', ''),
      postal_code:
        _.get(retailerAddress, 'attributes.address.postal_code', '') || '',
      address_id: _.get(retailerAddress, 'attributes.address.id', '') || '',
    };

    if (previousData && retailerAddress)
      setInitialData(state => ({ ...state, ...previousData }));
  }, [prevData, retailerAddress]);

  useEffect(() => {
    if (_.get(initialData, 'province.value'))
      dispatch(actions.getCityList(_.get(initialData, 'province.value')));
  }, [initialData.province]);

  useEffect(() => {
    const provinceCode = _.get(initialData, 'province.value', '');
    const cityCode = _.get(initialData, 'city_municipality.value', '');

    if (provinceCode && cityCode)
      dispatch(actions.getBarangayList(provinceCode, cityCode));
  }, [initialData.province, initialData.city_municipality]);

  useEffect(() => {
    if (_.isEmpty(retailerAddress)) return;
    dispatch(actions.getProvinceList());
  }, [retailerAddress]);

  useEffect(() => {
    dispatch(actions.getRetailerTypes());
  }, []);

  return (
    <div className="add-modal-container p-3">
      <form onSubmit={handleSubmit}>
        <div className="row mb-2">
          <div className="col">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Retailer Mobile No. *
            </label>

            <ReactInputMask
              type="text"
              required
              mask="+639999999999"
              maskChar=""
              className="form-control form-custom form-control_bottom px-3"
              name="mobile_identification_number"
              value={_.get(initialData, 'mobile_identification_number', '')}
              placeholder="Enter..."
              style={c.inputStyles}
              disabled
            />
          </div>

          <div className="col">
            <label
              className="font-weight-bold"
              style={c.labelStyles}
              htmlFor="retailer_type"
            >
              Retailer Type: *
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              name="retailer_type"
              placeholder="Set here..."
              id="retailer_type"
              required
              styles={c.selectOptionStyles}
              value={_.get(initialData, 'retailer_type', '')}
              options={newAccountTypeList}
              onChange={handleChangeAccountType}
              isLoading={false}
            />
          </div>

          <div className="col">
            <label
              className="font-weight-bold"
              style={{ ...c.labelStyles, marginBottom: '.3rem' }}
              htmlFor="account_type_id"
            >
              Birthdate:
            </label>
            <Calendar
              onChange={handleSelectDate}
              value={
                _.get(initialData, 'birth_date')
                  ? moment(_.get(initialData, 'birth_date')).format(
                      'YYYY-MM-DD'
                    )
                  : ''
              }
              disabled={is_mobile}
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col">
            <FormInputBorderBottom
              label="First Name *"
              name="first_name"
              value={_.get(initialData, 'first_name', '')}
              onChange={handleChangeOtherFields}
              disabled={is_mobile}
            />
          </div>

          <div className="col">
            <FormInputBorderBottom
              label="Middle Initial"
              name="middle_name"
              value={_.get(initialData, 'middle_name', '')}
              onChange={handleMiddleName}
              required={false}
              disabled={is_mobile}
            />
          </div>

          <div className="col">
            <FormInputBorderBottom
              label="Last Name *"
              name="last_name"
              value={_.get(initialData, 'last_name', '')}
              onChange={handleChangeOtherFields}
              disabled={is_mobile}
            />
          </div>
        </div>

        <div className="row mb-4 pb-2">
          <div className="col-4">
            <FormInputBorderBottom
              label="Email"
              name="email"
              value={_.get(initialData, 'email', '')}
              onChange={handleChangeOtherFields}
              disabled={is_mobile}
              required={false}
            />
          </div>
        </div>

        <div className="mb-2">
          <h6 className="h6 m-0 text-xs font-weight-bold">Selling Location</h6>
        </div>

        <div className="row mb-2">
          <div className="col">
            <FormInputBorderBottom
              label="House No. / Bdlg / Street No. *"
              name="line"
              value={_.get(initialData, 'line', '')}
              onChange={handleChangeOtherFields}
            />
          </div>

          <div className="col-4 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Province *
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="province"
              required
              isClearable
              styles={c.selectOptionStyles}
              value={
                _.get(initialData, 'province.value')
                  ? _.get(initialData, 'province')
                  : ''
              }
              options={provinceOptions}
              onChange={handleProvince}
              isLoading={useLoading(c.GET_PROVINCE_LIST)}
              isDisabled={_.isEmpty(provinceOptions)}
            />
          </div>

          <div className="col-4 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              City / Municipality *
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="city_municipality"
              required
              isClearable
              styles={c.selectOptionStyles}
              value={
                _.get(initialData, 'city_municipality.value')
                  ? _.get(initialData, 'city_municipality', '')
                  : ''
              }
              options={cityOptions}
              onChange={handleMunicipality}
              isLoading={useLoading(c.GET_CITY_LIST)}
              isDisabled={
                _.isEmpty(_.get(initialData, 'province.value')) &&
                _.isEmpty(cityOptions)
              }
            />
          </div>
        </div>

        <div className="row mb-4 pb-2">
          <div className="col-4 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Barangay *
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="barangay"
              required
              isClearable
              styles={c.selectOptionStyles}
              value={
                _.get(initialData, 'barangay.value')
                  ? _.get(initialData, 'barangay')
                  : ''
              }
              options={barangayOptions}
              onChange={handleBarangay}
              isLoading={useLoading(c.GET_BARANGAY_LIST)}
              isDisabled={_.isEmpty(barangayOptions)}
            />
          </div>

          <div className="col-4">
            <FormInputBorderBottom
              label="ZIP Code *"
              type="number"
              name="postal_code"
              value={String(_.get(initialData, 'postal_code', ''))}
              disabled
            />
          </div>
        </div>

        <div className="button-container d-flex justify-content-end">
          <ButtonAsync
            className="btn btn-primary position-relative py-2 px-4 mr-2"
            type="submit"
            loading={pending}
            disabled={isInputEmpty}
          >
            Save
          </ButtonAsync>

          <button
            type="button"
            className="btn bg-gray-300 text-white py-2 px-4"
            onClick={handleCloseModal}
            disabled={pending}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

const modalKey = 'update-retailer';
const modalConfig = {
  title: 'Update Retailer Account',
  size: 'modal-lg',
};

export default withModal(modalKey, modalConfig)(UpdateRetailer);
