import React from 'react';
import Logo from 'assets/images/dealers-portal-logo-sm.png';
import UpdatePassword from './UpdatePassword';
import Backdrop from '../components/Backdrop';

const Container = () => {
  return (
    <div className="vw-100 vh-100">
      <Backdrop />
      <div className="d-flex align-items-center justify-content-center vw-100 vh-100">
        <div className="col-4">
          <img
            src={Logo}
            alt="PLDT / SMART Dealer's Portal"
            className="d-block mx-auto mb-5"
          />
          <div className="card shadow-sm">
            <UpdatePassword />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Container;
