import React from 'react';
import { checkRoles } from 'helper';
import AdminContainer from './admin';
import UserContainer from './user';
import { RETAILER_USERS } from './constant';

const Dashboard = () => {
  const retailerRoles = Object.keys(RETAILER_USERS);
  return (
    <div>
      {checkRoles(retailerRoles) ? <UserContainer /> : <AdminContainer />}
    </div>
  );
};

export default Dashboard;
