import React, {
  useState,
  useMemo,
  useContext,
  useEffect,
  useCallback,
} from "react";
import withModal from "modules/common/hoc/withModal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import PropTypes from "prop-types";
import { removeEmpty } from "helper";
import FormInput from "modules/common/input/FormInput";
import ReactInputMask from "react-input-mask";
import { ModalContext } from "App";
import ButtonAsync from "components/Button";
import { useDealer } from "app/load-wallet/context/DealerProvider";
import * as actions from "../actions";
import * as c from "../constant";

function UpdateDealerSimForm({ item }) {
  const { profile, dealerRole, isNonMigrated, role, isB2B } = useDealer();

  const setModal = useContext(ModalContext);
  const [formData, setFormData] = useState({});
  const [pending, setPending] = useState(false);

  const disableStatus = [
    c.DEALERSIM_STATUS.approval,
    c.DEALERSIM_STATUS.failed,
    c.DEALERSIM_STATUS.incomplete,
  ];

  const isDisabled = disableStatus?.includes(item?.attributes?.status);
  const isProcessing =
    c.DEALERSIM_STATUS.processing?.includes(item?.attributes?.status) ||
    isDisabled;

  const closeModal = () => {
    setModal({ isOpen: false });
  };

  const handleChangeInput = (key) => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value,
    });
  };

  const handleChangeSelect = (key) => (e) => {
    setFormData((f) => ({ ...f, [key]: e ? e.value : "", page: 1 }));
  };

  const defaults = useMemo(
    () => ({
      email: profile?.email,
      assigned_to: profile?.assigned_to,
    }),
    [profile]
  );
  const sameMin = useMemo(() => {
    if (
      formData?.mobile_identification_number ===
      item?.attributes?.mobile_identification_number
    )
      return "";
    return formData?.mobile_identification_number;
  }, [formData?.mobile_identification_number, item]);

  const isSimFailed = item?.attributes?.status?.includes(
    c.DEALERSIM_STATUS.declined || c.DEALERSIM_STATUS.failed
  );
  const payload = {
    ...defaults,
    ...formData,
    role_type: role,
    mobile_identification_number: sameMin,
    old_min: item?.attributes?.mobile_identification_number,
    status: isSimFailed
      ? c.DEALERSIM_STATUS.approval
      : item?.attributes?.status,
    is_smmt: dealerRole?.includes(c.DEALER_SIM_ROLES.smmt),
    tempSubmitType: c.SUBMIT_TYPE.update,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);

    setModal("dealer-sim-confirm-password", removeEmpty(payload));
  };

  useEffect(() => {
    setFormData({
      id: item?.id,
      is_active: item?.attributes?.is_active,
      email: item?.attributes?.email,
      assigned_to: item?.attributes?.assigned_to,
      type: item?.attributes?.type,
      mobile_identification_number:
        item?.attributes?.mobile_identification_number,
      status: item?.attributes?.status,
    });
  }, [item, setFormData]);

  const selectDealerSimType = useCallback(() => {
    return (
      <FormInput
        withLabel={false}
        name="type"
        onChange={setFormData}
        value={formData?.type}
        readOnly
        disabled
      />
    );
  }, [setFormData, formData?.type]);
  return (
    <form onSubmit={handleSubmit}>
      {isNonMigrated || isB2B ? (
        <div className="row">
          <div className="col-12">
            <label className="form-label font-weight-bold">SIM Number:</label>
            <ReactInputMask
              mask="+639999999999"
              maskChar=""
              type="text"
              required
              className="form-control form-custom form-control_bottom"
              value={formData?.mobile_identification_number}
              onChange={handleChangeInput("mobile_identification_number")}
              readOnly
              disabled
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-6">
            <>
              <label className="form-label font-weight-bold">SIM Type:</label>
              {selectDealerSimType()}
            </>
          </div>
          <div className="col-6">
            <label className="form-label font-weight-bold">SIM Number:</label>
            <ReactInputMask
              mask="+639999999999"
              maskChar=""
              type="text"
              required
              className="form-control form-custom form-control_bottom"
              value={formData?.mobile_identification_number}
              onChange={handleChangeInput("mobile_identification_number")}
              readOnly
              disabled
            />
          </div>
        </div>
      )}

      <div className="row mt-5">
        <div className={`${isNonMigrated || isB2B ? "col-12" : "col-6"}`}>
          <label className="form-label font-weight-bold">
            Assign to physical SIM holder (name)
          </label>
          <input
            type="text"
            required
            className="form-control form-custom form-control_bottom"
            value={formData?.assigned_to}
            onChange={handleChangeInput("assigned_to")}
            readOnly={item?.attributes?.status?.includes(
              c.DEALERSIM_STATUS.approval
            )}
            maxLength={50}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 my-4">
          <span className="text-left form-label">
            By clicking &quot;CONTINUE&quot; a password confirmation form
            <br />
            will pop-up to verify ownership
          </span>
        </div>
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            {!isProcessing && (
              <ButtonAsync
                type="submit"
                className="btn btn-primary position-relative text-uppercase mx-2"
                loading={pending}
              >
                Continue
              </ButtonAsync>
            )}
            <button
              type="button"
              onClick={closeModal}
              className="text-uppercase btn bg-gray-500 text-white font-weight-bold mr-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

UpdateDealerSimForm.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ modal }) => ({
  item: _.get(modal, "modalData.data") || {},
});

const enhance = _.flowRight([
  withModal("fill-update-dealer-sim", {
    title: "Update Dealer SIM",
    size: "modal-md",
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(UpdateDealerSimForm);
