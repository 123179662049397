import React, { useState } from 'react';
import Logo from 'assets/images/dealers-portal-logo-sm.png';
import RequestOTPForm from '../components/RequestOTPForm';
import SendOTPForm from '../components/SendOTPForm';
import ChangePasswordForm from '../components/ChangePasswordForm';

const REQUEST_OTP = 'Request OTP';
const CONFIRM_OTP = 'Confirm OTP';
const CHANGE_PASSWORD = 'Enter new password';
const steps = [REQUEST_OTP, CONFIRM_OTP, CHANGE_PASSWORD];

const ForgotPasswordContainer = () => {
  // States and variables
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [username, setUsername] = useState('');
  const [otp, setOtp] = useState('');
  const [spiel, setSpiel] = useState({});

  // State handlers
  const onNextStep = otp_res => {
    setSpiel(otp_res);

    setCurrentStep(step => {
      if (step === REQUEST_OTP) return CONFIRM_OTP;
      if (step === CONFIRM_OTP) return CHANGE_PASSWORD;
      return step;
    });
  };

  const sendBackToVerify = () => {
    setCurrentStep(CONFIRM_OTP);
  };

  // Render
  return (
    <div className="forgot-password-page scroll-first">
      <div className="layer" />
      <div className="main-content d-flex align-items-center justify-content-center mx-auto py-4 px-3 position-relative">
        <div className="w-100">
          <img
            src={Logo}
            alt="PLDT / SMART Dealer's Portal"
            className="d-block mx-auto mb-5"
          />

          {currentStep === REQUEST_OTP && (
            <RequestOTPForm
              onNextStep={onNextStep}
              username={username}
              setUsername={setUsername}
            />
          )}
          {currentStep === CONFIRM_OTP && (
            <SendOTPForm
              onNextStep={onNextStep}
              username={username}
              otp={otp}
              setOtp={setOtp}
              spiel={spiel}
            />
          )}
          {currentStep === CHANGE_PASSWORD && (
            <ChangePasswordForm
              otp={otp}
              username={username}
              setOtp={setOtp}
              sendBackToVerify={sendBackToVerify}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordContainer;
