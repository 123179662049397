import * as req from 'api/actions';
import * as c from './constants';

export const getApplicationList = payload =>
  req.list(c.GET_APPLICATION_LIST, 'api/v1/dealers', payload);

export const updateApplicationStatus = (
  on_going_application_id,
  payload,
  successCallback,
  errCallback
) =>
  req.update(
    c.UPDATE_APPLCATION_STATUS,
    `api/v1/applications/${on_going_application_id}`,
    payload,
    successCallback,
    {},
    errCallback
  );

export const getDealerById = (id, payload, callback) =>
  req.item(c.GET_DEALER_BY_ID, `api/v1/dealers/${id}`, payload, callback);
