import React, { useState, useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import withModal from "modules/common/hoc/withModal";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { USER_PROFILE } from "modules/auth/constants";
import _ from "lodash";
import GenericTable from "modules/common/components/GenericTable";
import ButtonAsync from "components/Button";
import { ModalContext } from "App";
import { ToastSuccess, ToastError } from "modules/common/components/Toast";
import ConfirmAlert from "modules/common/components/ConfirmAlert";
import SelectShift from "modules/ManageAccounts/modalForms/SelectShift";
import {
  getAttachUsersBA,
  getAttachUsers,
} from "modules/ManageAccounts/action";
import { customStyles } from "components/styles/custom";
import FormInputSelectApi from "modules/common/input/FormInputSelectApi";
import * as actions from "../action";
import * as c from "../constant";
import { useQueryClient } from "react-query";

function Assign({
  getShift,
  getBranch,
  assign,
  branchId,
  userId,
  getAssignedBranchShift,
  assignedList,
  isAssignedLoading,
  detach,
  isSaveLoading,
  isDetachLoading,
}) {
  const qc = useQueryClient();
  const [formData, setFormData] = useState({ branch: "", shift: "" });
  const setModal = useContext(ModalContext);
  const [pending, setPending] = useState(false);
  const dispatch = useDispatch();
  const { branch, shift } = formData;
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const isNonMigrated = profile?.roles[0]?.includes("non-migrated");

  const handleAssign = (e) => {
    e.preventDefault();
    setPending(true);
    const payload = {
      branch_id: _.get(formData, "branch"),
      schedule_id: _.get(formData, "shift.value"),
    };

    let setPendingError;

    if (_.isEmpty(shift) && !_.isEmpty(branch)) {
      ToastError("Select Shift field is required");
      setPendingError = setTimeout(() => {
        setPending(false);
        clearTimeout(setPendingError);
      }, 400);
    } else {
      assign(
        userId,
        payload,
        () => {
          setFormData({});
          setPending(false);
          ToastSuccess("Successfully assigned branch and selling window.");
          getAssignedBranchShift(userId);
        },
        () => setPending(false),
      );
    }
  };

  const isInputEmpty = useMemo(() => {
    return _.isEmpty(branch) || _.isEmpty(shift);
  }, [formData]);

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
    if (!_.isEmpty(branchId)) {
      dispatch(
        getAttachUsersBA(branchId, {
          role_slug: isNonMigrated
            ? "branch-admin-non-migrated"
            : "branch-admin",
        }),
      );
      dispatch(
        getAttachUsers(branchId, {
          role_slug: isNonMigrated ? "frontliner-non-migrated" : "frontliner",
        }),
      );
    }
  };

  const handleDelete = (id) => (e) => {
    e.preventDefault();
    ConfirmAlert(
      "Are you sure you want to remove this branch and schedule ?",
      () => {
        detach(userId, id, () => {
          qc.invalidateQueries("attached_users_fl");
          qc.invalidateQueries("attached_users_ba");
          ToastSuccess("Successfully Removed.", { autoClose: 1500 });
          getAssignedBranchShift(userId);
        });
      },
      () => {},
      {
        yesLabel: "Yes",
        noLabel: "No",
      },
    );
  };
  const renderAction = (item) => {
    return (
      <ButtonAsync
        type="button"
        className="btn btn-danger position-relative position-relative"
        onClick={handleDelete(item.id)}
        loading={isAssignedLoading}
      >
        Delete
      </ButtonAsync>
    );
  };

  const handleChangeSelect = (key, callback) => (value) => {
    setFormData({
      ...formData,
      [key]: value || {},
    });
    if (callback) callback();
  };

  useEffect(() => {
    getShift();
    getBranch();
    if (!_.isEmpty(userId)) {
      getAssignedBranchShift(userId);
    }
  }, [userId, getAssignedBranchShift, getBranch, getShift]);

  return (
    <div>
      <form onSubmit={handleAssign}>
        <div className="row mb-3">
          <div className="col-6">
            <FormInputSelectApi
              styles={customStyles}
              className="react-select-container mb-0"
              classNamePrefix="react-select"
              value={formData?.branch}
              name="branch"
              label="Select Branch:"
              transformer={(list) =>
                list?.data.map((x) => ({
                  value: x?.id,
                  label: x?.attributes?.name,
                }))
              }
              api="api/v1/branches"
              onChange={setFormData}
              params={{ include: null }}
            />
          </div>
          <div className="col-6">
            <label className="form-label text-primary">Select Shift:</label>
            <SelectShift
              onChange={handleChangeSelect("shift")}
              value={_.get(formData, "shift", {})}
            />
          </div>
          <div className="col">
            <ButtonAsync
              type="submit"
              loading={pending}
              disabled={isInputEmpty}
              className="float-right btn btn-primary position-relative mt-2"
            >
              Save
            </ButtonAsync>
          </div>
        </div>
      </form>
      <span className="font-weight-bold text-success">
        Assigned Branch and Shift
      </span>
      <GenericTable
        isLoading={isAssignedLoading}
        format={[
          {
            key: (item) => (
              <span>{_.get(item, "attributes.branch_name") || "--"}</span>
            ),
            label: "Branch Name",
            width: "auto",
          },
          {
            key: (item) => (
              <span>{_.get(item, "attributes.schedule_name") || "--"}</span>
            ),
            label: "Shift",
            width: "auto",
          },
          {
            key: (item) => renderAction(item),
            label: "Action",
            width: "20px",
          },
        ]}
        data={assignedList}
        height="30vh"
      />
      <div className="row">
        <div className="col-12">
          <button
            type="button"
            onClick={closeModal}
            disabled={isSaveLoading || isDetachLoading}
            className="btn btn-danger font-weight-bold ml-2 float-right"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

Assign.propTypes = {
  getBranch: PropTypes.func.isRequired,
  getShift: PropTypes.func.isRequired,
  assign: PropTypes.func.isRequired,
  getAssignedBranchShift: PropTypes.func.isRequired,
  detach: PropTypes.func.isRequired,
  branchId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  isAssignedLoading: PropTypes.bool.isRequired,
  isSaveLoading: PropTypes.bool.isRequired,
  isDetachLoading: PropTypes.bool.isRequired,
  assignedList: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api, modal }) => ({
  assignedList: _.get(api, `${c.GET_ASSIGNED_BRANCH_SHIFT}.list`, []),
  isAssignedLoading: api.loading.indexOf(c.GET_ASSIGNED_BRANCH_SHIFT) > -1,
  isSaveLoading: api.loading.indexOf(c.ASSIGN) > -1,
  isDetachLoading: api.loading.indexOf(c.DETACH) > -1,
  userId: _.get(modal, "modalData.id", ""),
  branchId: _.get(modal, "modalData.branchId", ""),
});

const enhance = _.flowRight([
  withModal("assign", {
    title: "Assign",
    size: "modal-lg",
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(Assign);
