import React, { createContext, useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useLoading } from "modules/ui/hooks";
import { useDealer } from "app/load-wallet/context/DealerProvider";
import { useFilterSim } from "app/load-wallet/context/SimFilterProvider";
import * as req from "api/actions";
import { getRetailerSources } from "app/dashboard/actions";
import { GET_RETAILER_SOURCES } from "app/dashboard/constant";
import * as c from "modules/LoadWalletManagement/constant";
import * as a from "modules/LoadWalletManagement/actions";
import { removeEmpty } from "helper";

export const RetailerSimsContext = createContext();

export const RetailerSimProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { simDealerId, role, profile, transactionPath } = useDealer();
  const { filterData, sortFilter } = useFilterSim();
  const { meta: branchSimsMeta } =
    useSelector(({ api }) => _.get(api, `${GET_RETAILER_SOURCES}.res`)) || {};
  const isBA = role?.includes("branch-admin");
  const { meta: retailerSimMeta, data: retailerSims } = useSelector(
    ({ api }) => _.get(api, `${c.RETAILER_SIMS_GET_RETAILER_SIMS}.res`) || {}
  );
  const retailerSimsAll = useSelector(({ api }) =>
    _.get(api, `${c.RETAILER_SIMS_GET_RETAILER_SIMS_ALL}.res.data`)
  );

  const retailerSimsLoading = useLoading(
    c.RETAILER_SIMS_GET_RETAILER_SIMS || c.RETAILER_SIMS_GET_RETAILER_SIMS_ALL
  );
  const retailerSimPager = branchSimsMeta || retailerSimMeta || [];
  // branch sources
  const branchSources = useSelector(({ api }) =>
    _.get(api, `${GET_RETAILER_SOURCES}.res.data.attributes.rows`, [])
  );

  const filteredBranchSources = branchSources.filter((sim) =>
    sim?.type.includes("dealer_retailer_sims")
  );
  const branchRetailerSims = _.intersectionBy(
    retailerSimsAll,
    filteredBranchSources,
    (sim) =>
      sim?.attributes?.mobile_identification_number ||
      sim?.mobile_identification_number
  );

  const retailerSimSliced = useMemo(() => {
    const retailerSimListSliced = isBA
      ? branchRetailerSims
      : retailerSims?.slice(0, 10);
    return retailerSimListSliced;
  }, [retailerSims, branchRetailerSims, isBA]);

  const retailerSimsValue = {
    retailerSimSliced,
    retailerSimMeta,
    retailerSims,
    retailerSimPager,
    retailerSimsLoading,
  };

  useEffect(() => {
    if (!isBA) return;
    dispatch(
      getRetailerSources({
        ...filterData,
        retailer_id: profile?.retailer_id,
        sim_status: filterData?.sim_status?.value || "",
        status: filterData?.status?.value || "",
      })
    );
  }, [dispatch, filterData]);

  useEffect(() => {
    if (transactionPath) return;
    dispatch(
      a.retailerSimsGetRetailerSims(
        removeEmpty({
          dealer_id: simDealerId,
          ...filterData,
          ...sortFilter,
          sim_status: filterData?.sim_status?.value || "",
        })
      )
    );
    return () => {
      dispatch(req.setItem(c.RETAILER_SIMS_GET_RETAILER_SIMS, {}));
    };
  }, [
    dispatch,
    simDealerId,
    filterData.q,
    filterData.assigned_to,
    filterData.sim_status,
  ]);

  useEffect(() => {
    if (transactionPath) return;
    dispatch(
      a.retailerSimsAll(
        removeEmpty({
          dealer_id: simDealerId,
          ...filterData,
          ...sortFilter,
          per_page: 9999,
          sim_status: filterData?.sim_status?.value || "",
        })
      )
    );
    return () => {
      dispatch(req.setItem(c.RETAILER_SIMS_GET_RETAILER_SIMS_ALL, {}));
    };
  }, [
    dispatch,
    simDealerId,
    filterData.q,
    filterData.assigned_to,
    filterData.sim_status,
  ]);
  return (
    <RetailerSimsContext.Provider value={retailerSimsValue}>
      {children}
    </RetailerSimsContext.Provider>
  );
};
RetailerSimProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
export const useRetailerSim = () => {
  return useContext(RetailerSimsContext);
};
