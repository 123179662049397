import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'modules/common/components/Loader';

const LoadThresholdStatus = ({ threshold, balance, loading }) => {
  const isNormal = threshold < balance;
  const empty =
    threshold === 0 || (threshold === '' && balance === 0) || balance === '';

  const renderThreshold = () => (
    <>
      <div
        className={`progress-bar-percentage ${
          isNormal ? `percent-green` : `percent-red`
        }`}
        style={{ width: `${balance}%` }}
      />
      <span className="percent-text">{isNormal ? `Good` : `Critical`}</span>
    </>
  );

  const render = () => {
    if (loading) return <Loader />;
    if (empty) return null;
    return renderThreshold();
  };
  return <div className="progress-bar">{render()}</div>;
};

LoadThresholdStatus.propTypes = {
  threshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool,
};

LoadThresholdStatus.defaultProps = {
  threshold: 0,
  balance: 0,
  loading: false,
};

export default LoadThresholdStatus;
