import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
// import imgThumb from 'assets/images/logo-loader.png';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import withModal from 'modules/common/hoc/withModal';
import { connect } from 'react-redux';
// import { ModalContext } from 'App';
// import { ToastSuccess } from 'modules/common/components/Toast';
import { ModalContext } from 'App';
import { ToastSuccess } from 'modules/common/components/Toast';
import * as actions from '../actions';
import * as c from '../constant';
import CartDetails from '../components/CartDetails';
import RemoveItem from '../forms/RemoveItem';

function PlaceOrder({
  cartList,
  total,
  isLoading,
  profile,
  createOrder,
  cartId,
}) {
  const [toggle, setToggle] = useState('details');
  const [oldData, setOldData] = useState({});
  const setModal = useContext(ModalContext);

  const handleRemoveItem = (value, args) => e => {
    e.preventDefault();
    setToggle(value);
    setOldData(args);
  };

  const handleBack = value => e => {
    e.preventDefault();
    setToggle(value);
  };

  const handleCreateOrder = e => {
    e.preventDefault();
    const payload = {
      dealer_code: '123a5432109876543210cdef',
      dealer_name: profile.full_name,
      dealer_area: profile.area_name,
      cart_id: cartId,
    };
    createOrder(payload, () => {
      setModal({ isOpen: false });
      ToastSuccess('Success!');
    });
  };

  return (
    <div className="container-fluid">
      {toggle === 'details' && (
        <CartDetails
          data={cartList}
          type="order"
          onRemove={handleRemoveItem}
          total={total}
          loading={isLoading}
          profile={profile}
          onCreateOrder={handleCreateOrder}
        />
      )}
      {toggle === 'remove' && (
        <RemoveItem data={oldData} onBack={handleBack} setToggle={setToggle} />
      )}
    </div>
  );
}

PlaceOrder.propTypes = {
  cartList: PropTypes.instanceOf(Array).isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
  total: PropTypes.string.isRequired,
  cartId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  createOrder: PropTypes.func.isRequired,
};

const mapStateToProps = ({ api }) => ({
  total: _.get(api, `${c.GET_CART}.res.subtotal`) || '',
  cartId: _.get(api, `${c.GET_CART}.res.cart_id`) || '',
  cartList: _.get(api, `${c.GET_CART}.list`) || [],
  isLoading: api.loading.indexOf(c.GET_CART) > -1,
  profile: _.get(api, 'AUTH/user_profile.item') || {},
});

const enhance = _.flowRight([
  withModal('summary', {
    title: 'Order Summary',
    size: 'modal-lg',
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(PlaceOrder);
