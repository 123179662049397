import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { Router } from "react-router-dom";
import Toast from "modules/common/components/Toast";
import NoClickJack from "components/NoClickJack";
import App from "./App";
import history from "./history";
import store from "./store";
import pkg from "../package.json";

import "react-toastify/dist/ReactToastify.css";
import "assets/styles/tailwind.css";
import "assets/styles/styles.scss";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "react-datepicker/dist/react-datepicker.css";

console.info(`%cVersion: ${pkg.version}`, "color: #2dbe60");

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <App />
        <Toast />
      </Router>
    </QueryClientProvider>
    <NoClickJack />
  </Provider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}
