import React from 'react';
import MainBannerImg from 'assets/images/ads/main-banner-ads.png';

const MainBanner = () => {
  return (
    <div className="main-banner">
      <div className="ads-section">
        <img className="ads-banner" src={MainBannerImg} alt="Discover" />
      </div>
    </div>
  );
};

export default MainBanner;
