import React, { useMemo, useState, useCallback, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { ModalContext } from "App";
import Pagination from "modules/common/components/Pagination";
import GenericTable from "modules/common/components/GenericTable";
import Filter from "../../components/TransactionFilter";
import ViewLoadTransaction from "../../modalForms/ViewLoadTransaction";

import * as actions from "../../actions";
import * as c from "../../constant";

function Transaction() {
  const dispatch = useDispatch();
  const { params } = useRouteMatch();
  const setModal = React.useContext(ModalContext);

  const defaultPayload = {
    type: "internal",
    page: 1,
    per_page: 7,
    date_from: "",
    date_to: "",
    created_by_username: "",
    dealer_sim_id: params.simId,
    dealer_sim_min: "",
    dealer_sim_type: "",
    target_min: "",
    target_sim_type: "",
    q: "",
  };

  const customTableStyles = {
    minWidth: "1600px",
    width: "100%",
  };

  const [filters, setFilter] = useState(defaultPayload);
  const loading = useSelector(({ api }) => _.get(api, `loading`));
  const sim = useSelector(({ api }) => _.get(api, `${c.SHOW_DEALER_SIM}.item`));
  const list = useSelector(({ api }) =>
    _.get(api, `${c.GET_TRANSACTIONS}.list`, [])
  );

  const TransactionListPager = useSelector(({ api }) =>
    _.get(api, `${c.GET_TRANSACTIONS}.pager`, {})
  );

  const filterParams = useMemo(() => {
    return _.pickBy({
      ...filters,
      target_min: filters.target_min.replace("+63", "0"),
    });
  }, [filters]);

  const updateTransactions = useCallback(() => {
    dispatch(actions.getTransactions(filterParams));
  }, [filters, actions]);

  const handleClear = () => {
    setFilter(defaultPayload);
    dispatch(actions.getTransactions(_.pickBy(defaultPayload)));
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    updateTransactions();
  };

  const handleChange = (key) => (e) => {
    const value = _.get(e, "value") || _.get(e, "target.value") || "";
    setFilter((f) => ({ ...f, [key]: value, page: 1 }));
  };

  const handleDownload = () => {
    const downloadParams = _.omit(filterParams, ["page", "per_page"]);
    dispatch(actions.downloadTransactionHistory(downloadParams));
  };

  const handlePager = (page) => setFilter((state) => ({ ...state, page }));

  useEffect(() => {
    dispatch(actions.getTransactions(_.pickBy(defaultPayload)));
  }, [actions]);

  useEffect(() => {
    dispatch(actions.getTransactions(filterParams));
  }, [actions, filters.q]);

  useEffect(() => {
    updateTransactions();
  }, [..._.values(_.omit(filters, ["q"]))]); // eslint-disable-line

  const handleView = (data) => (e) => {
    e.preventDefault();
    setModal("view-eload-modal", {
      amount_deducted: _.get(data, "attributes.amount_deducted"),
      amount_loaded: _.get(data, "attributes.amount_loaded"),
      target_mobile: _.get(data, "attributes.target_min"),
      transaction_reference: _.get(data, "attributes.ref_id"),
      transaction_timestamp:
        moment(_.get(data, "attributes.created_at")).format("LLL") || "--",
      created_by_name: _.get(data, "attributes.created_by_name"),
      debit_balance: _.get(data, "attributes.debit_balance"),
    });
  };

  const renderAction = (data) => (
    <button
      className="btn btn-no btn-sm"
      type="button"
      onClick={handleView(data)}
    >
      <i className="far fa-eye" /> View
    </button>
  );

  return (
    <div className="main-content-inner loadWalletDealerTransaction scroll-lime">
      <div className="row d-flex">
        <div className="col">
          <Filter
            onSubmit={handleSubmitFilter}
            onChange={handleChange}
            filters={filters}
            onClear={handleClear}
          />
        </div>
      </div>
      <h1 className="h5 flex-grow-1 mt-0 mb-0">Dealer Transactions</h1>
      <div className="d-flex align-items-center my-2">
        <h1 className="h5 font-weight-bold flex-grow-1 mt-0 mb-0">
          Dealer SIM:{" "}
          {loading.includes(c.SHOW_DEALER_SIM)
            ? `...`
            : _.get(sim, `attributes.mobile_identification_number`)}
        </h1>
        <button
          type="button"
          disabled={list.length === 0}
          onClick={handleDownload}
          className="btn btn-success"
        >
          <i className="fas fa-download mr-2" />
          <small>Download</small>
        </button>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0 bg-white border">
            <GenericTable
              isLoading={loading.includes(c.GET_TRANSACTIONS)}
              format={[
                {
                  key: (item) => (
                    <span>
                      {moment(_.get(item, "attributes.created_at")).format(
                        "LLL"
                      ) || "--"}
                    </span>
                  ),
                  label: "Date and Time",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>
                      {_.get(item, "attributes.created_by_username", "--")}
                    </span>
                  ),
                  label: "Username",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>{_.get(item, "attributes.ref_id", "--")}</span>
                  ),
                  label: "Reference No.",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>
                      {_.get(item, "attributes.dealer_sim_min", "--")}
                    </span>
                  ),
                  label: "Dealer Min",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>
                      {_.get(item, "attributes.dealer_sim_type", "--")}
                    </span>
                  ),
                  label: "Source Sim Type",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>{_.get(item, "attributes.target_min", "--")}</span>
                  ),
                  label: "Target MIN",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>
                      {_.get(item, "attributes.target_sim_type", "--")}
                    </span>
                  ),
                  label: "Target Sim Type",
                  width: "auto",
                },
                {
                  key: (item) => (
                    <span>{_.get(item, "attributes.amount_loaded", "--")}</span>
                  ),
                  label: "Amount",
                  width: "auto",
                },
                {
                  key: (item) => renderAction(item),
                  label: "ACTION",
                  width: "6rem",
                },
              ]}
              data={list}
              containerClassName="loadWalletDealerTransaction__container-table scroll-lime"
              customTableStyles={customTableStyles}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <Pagination
          className="mt-3"
          onChange={handlePager}
          data={TransactionListPager}
        />
      </div>
      <ViewLoadTransaction />
    </div>
  );
}

export default Transaction;
