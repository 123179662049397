import React from "react";
import _ from "lodash";
import NoRoute from "components/NoRoute";
import { Route, Redirect } from "react-router-dom";
import { useDealer } from "app/load-wallet/context/DealerProvider";
import DealerSimsList from "../components/DealerSimsList";
import RetailerSimsList from "../components/RetailerSimsList";
import SelectedDealerInfo from "../components/SelectedDealerInfo";
import DealerList from "../components/DealerList";

const SubDealerSims = () => {
  const { dealers, parentMatch, match } = useDealer();

  const emptyDealer = () => {
    return (
      <div
        style={{
          display: "flex",
          minHeight: "85vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <NoRoute />
      </div>
    );
  };

  return (
    <>
      <div style={{ background: "#F7F7F7" }}>
        <div className="container-fluid">
          <div className="row">
            <DealerList parentMatch={parentMatch} match={match} />
            <div className="col-9 p-3">
              {dealers ? (
                <div className="SubDealerSims mb-4">
                  <SelectedDealerInfo />
                  <DealerSimsList />
                  <RetailerSimsList />
                </div>
              ) : (
                emptyDealer()
              )}
            </div>
          </div>
        </div>
      </div>
      <Route
        path={`${match?.path}`}
        render={({ match: { params } }) => {
          return (
            !params?.dealerId &&
            dealers?.length > 0 && (
              <Redirect to={`${match?.url}/${_.get(dealers, "0.id")}`} />
            )
          );
        }}
      />
    </>
  );
};

export default React.memo(SubDealerSims);
