import * as req from "api/actions";
import moment from "moment";
import { downloadGet, rootApi } from "helper";
import { ToastWarn, ToastSuccess } from "modules/common/components/Toast";
import * as c from "./constants";

export const getBranch = (payload, callback) =>
  req.list(c.GET_ELOAD_LIST, "partners/api/v1/transactions", payload, callback);

export const downloadReport = (params) => {
  ToastWarn("Generating export file...");
  downloadGet({
    url: `${rootApi}partners/api/v1/transactions/export`,
    params,
    filename: `Eload API Gateway Report ${moment().format(
      "MM/DD/YYYY h:mm a"
    )}.csv`,
    onSuccess: () => {
      ToastSuccess("Export success!");
    },
    onError: () => {},
  });
  return { type: c.DOWNLOAD_ELOAD_REPORT };
};
