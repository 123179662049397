import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import withModal from 'modules/common/hoc/withModal';
import { ModalContext } from 'App';
import * as actions from '../actions';

function ViewLoadTransaction({ newData }) {
  const setModal = useContext(ModalContext);
  const debit = _.get(newData, 'debit_balance');
  const ending_balance = debit
    ? `PHP ${debit.toLocaleString('en', {
        minimumFractionDigits: 2,
      })}`
    : '--';

  const closeModal = e => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  return (
    <div>
      <div className="row px-4">
        <div className="col-5 px-0">
          <div className="text-muted">Date:</div>
        </div>
        <div className="col-7 px-0">
          <div className="text-muted font-weight-bold">
            {_.get(newData, 'transaction_timestamp')}
          </div>
        </div>
      </div>
      <div className="row px-4 mt-3">
        <div className="col-5 px-0 w-25">
          <div className="text-muted">Reference Number:</div>
        </div>
        <div className="col-7 px-0">
          <div className="text-muted font-weight-bold">
            {_.get(newData, 'transaction_reference')}
          </div>
        </div>
      </div>
      <div className="flex-grow-1 mt-3">
        <div className="row">
          <div className="col-12">
            <table className="table table-striped table-sm table-borderless">
              <tbody>
                <tr>
                  <td
                    className="text-left text-muted"
                    style={{ width: 400, paddingLeft: 10 }}
                  >
                    Target No.
                  </td>
                  <td className="text-left text-muted font-weight-bold">
                    {_.get(newData, 'target_mobile')}
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-left text-muted"
                    style={{ width: 400, paddingLeft: 10 }}
                  >
                    Deducted
                  </td>
                  <td className="text-left text-muted font-weight-bold">
                    {_.get(newData, 'amount_deducted')}
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-left text-muted"
                    style={{ width: 400, paddingLeft: 10 }}
                  >
                    Ending Balance
                  </td>
                  <td className="text-left text-muted font-weight-bold">
                    {ending_balance}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <button
          type="button"
          onClick={closeModal}
          className="mt-3 btn btn-modal-yes ml-auto mr-2"
          style={{ borderRadius: 8 }}
        >
          <span
            className="font-weight-bold"
            style={{ color: '#FFFFFF', fontSize: 14 }}
          >
            CLOSE
          </span>
        </button>
      </div>
    </div>
  );
}

ViewLoadTransaction.propTypes = {
  newData: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = ({ modal }) => ({
  newData: modal.modalData,
});

const enhance = _.flowRight([
  withModal('view-eload-modal', {
    title: 'Transaction Details',
    size: 'modal-md',
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(ViewLoadTransaction);
