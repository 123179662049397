import React, { useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Img from 'modules/common/components/ImgDoc';
import { checkPermissions } from 'helper';
import { ModalContext } from 'App';
import { useLoading } from 'modules/ui/hooks';
import placeholderPhoto from 'assets/images/default-user.jpg';
import placeholderPhotoTwo from 'assets/images/avatar-photo.png';
import { useProfile } from 'modules/auth/hooks';
import UpdateDealerAuthorization from '../../../modal/UpdateDealerAuthorization';
import ViewDocument from '../../../../partners/dealer/modal/ViewDocument';

import * as c from '../../../constants';

const DealerAuthorization = () => {
  const setModal = useContext(ModalContext);

  const profile = useProfile();

  const dealer_id = profile?.id || profile?.parent_id;
  const moaInfo = useSelector(({ api }) =>
    _.get(api, `${c.SHOW_MOA_SIGNATORY_INFORMATION}.item`, {})
  );

  const newDealerInfo = useMemo(
    () =>
      Object.keys(moaInfo)
        .map(item => moaInfo[item])
        .filter(item => !_.isEmpty(item))
        .filter(
          ({ attributes }) => attributes._type === 'DISTRIBUTOR_SIGNATORY'
        ),
    [moaInfo]
  );

  const isLoading = useLoading(c.SHOW_MOA_SIGNATORY_INFORMATION);

  const openUpdateModal = () =>
    setModal('dealer-authorization-update', {
      dealer_id,
      dealerInfo: newDealerInfo,
    });

  return (
    <>
      <div className="DealerAuthorization mb-4">
        <div className="DealerAuthorization-container">
          <header className="rounded-top-both bg-grey-800 overflow-hidden text-white d-flex justify-content-between pl-3">
            <div className="header-title my-auto py-2">
              <h6 className="h6 text-xs font-weight-bold p-0 m-0">
                Dealer Authorization
              </h6>
            </div>
            <div>
              {checkPermissions(['can-update-dealer-information']) && (
                <button
                  type="button"
                  className="btn text-xs btn-success rounded-0"
                  onClick={openUpdateModal}
                  disabled={isLoading}
                >
                  <i className="fas fa-pen mr-2" />
                  Update
                </button>
              )}
            </div>
          </header>
          <div className="p-3">
            <h6 className="h6 text-xs text-grey-1000 font-weight-bold p-0 m-0">
              User Details
            </h6>
          </div>
          <div className="p-4 bg-white">
            {newDealerInfo.map((item, index) => (
              <div className="row mt-4" key={_.get(item, 'id')}>
                <div className="col-lg-3 d-flex align-items-start text-xs text-grey-1000">
                  <div
                    className="mr-3 rounded-circle bg-light-200 d-flex align-items-center justify-content-center"
                    style={{ minWidth: '1.3rem', minHeight: '1.3rem' }}
                  >
                    <span className="text-dark">{index + 1 || 0}</span>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="auth-image">
                        <Img
                          lightBox
                          defaultSrc={placeholderPhoto}
                          src={_.get(item, 'attributes.attach_image_1')}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="auth-image">
                        <Img
                          lightBox
                          defaultSrc={placeholderPhotoTwo}
                          src={_.get(item, 'attributes.attach_image_2')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2 d-flex flex-column text-xs text-grey-1000">
                  <div className="label font-weight-bold mb-2">First Name</div>
                  <p className="description mb-0 break-word">
                    {_.get(item, 'attributes.first_name') || '--'}
                  </p>
                </div>
                <div className="col-2 d-flex flex-column text-xs text-grey-1000">
                  <div className="label font-weight-bold mb-2">
                    Middle Initial
                  </div>
                  <p className="description mb-0 break-word">
                    {_.get(item, 'attributes.middle_name') || '--'}
                  </p>
                </div>
                <div className="col-2 d-flex flex-column text-xs text-grey-1000">
                  <div className="label font-weight-bold mb-2">Last Name</div>
                  <p className="description mb-0 break-word">
                    {_.get(item, 'attributes.last_name') || '--'}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <UpdateDealerAuthorization />
      <ViewDocument />
    </>
  );
};

export default React.memo(DealerAuthorization);
