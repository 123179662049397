import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { service } from 'api/service';
import * as c from './constants';
import { getMyProfile } from './actions';

const transformResponse = data => _.get(JSON.parse(data), 'data');

const checkPermissions = () => {
  const url = `/acl/api/v1/my/role_permissions`;
  return service.get(url, { transformResponse });
};

export function usePermissions() {
  return useQuery(['permissions'], checkPermissions, {
    keepPreviousData: true,
  });
}

export function useProfile() {
  return useSelector(({ api }) => api?.[c.USER_PROFILE]?.item);
}

export const useMyProfile = () => {
  const dispatch = useDispatch();
  const myProfile = useSelector(({ api }) => api?.[c.MY_PROFILE]?.item);

  useEffect(() => {
    dispatch(getMyProfile());
  }, [dispatch]);
  return myProfile;
};

export default {};
