import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const CreditMemo = ({
  CreditData,
  credit,
  onHandleCredit,
  onChangeInput,
  formData,
}) => {
  return (
    <Fragment>
      <div className="font-weight-bold">Select Available Credit Memo</div>
      <table className="table table-link table-hover table-light">
        <thead>
          <tr>
            <th scope="col" />
            <th scope="col" className="text-success">
              Ref
            </th>
            <th scope="col" className="text-success">
              Amount
            </th>
            <th scope="col" className="text-success">
              Date
            </th>
          </tr>
        </thead>
        <tbody>
          {CreditData.map(x => (
            <tr key={x.id}>
              <th scope="row">
                <a
                  href="/"
                  onClick={onHandleCredit(x.name)}
                  className="custom-control custom-checkbox pl-0"
                >
                  {x.name === credit ? (
                    <i className="fas fa-check-square text-success mr-2" />
                  ) : (
                    <i className="fas fa-square text-muted mr-2" />
                  )}
                  <label>{x.title}</label>
                </a>
              </th>
              <td>{x.ref}</td>
              <td>{x.amount}</td>
              <td>{x.date}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="form-group">
        <label className="font-weight-bold text-warning">
          if not on the list, Enter CM Amount:
        </label>
        <input
          className="form-control input-textbox form-custom form-control_bottom"
          type="text"
          required
          onChange={onChangeInput('cm_account')}
          value={_.get(formData, 'cm_account') || ''}
        />
      </div>
    </Fragment>
  );
};

CreditMemo.propTypes = {
  CreditData: PropTypes.instanceOf(Array).isRequired,
  formData: PropTypes.instanceOf(Object).isRequired,
  credit: PropTypes.string.isRequired,
  onHandleCredit: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
};

export default CreditMemo;
