import React, { useState, useEffect } from 'react';
import {
  withRouter,
  NavLink,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import * as c from '../constant';
import * as actions from '../actions';

const viewWidth = window.innerWidth;
const finalHeight = viewWidth >= 1199 ? '350px' : '280px';

const contentInitialLevel1 = (
  <div className="d-flex h-100 w-100 justify-content-center align-items-center">
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

function DataPrivacy({ getConsent, consent, isLoading }) {
  const match = useRouteMatch();
  const [contentLevel1, setContentLevel1] = useState(null);
  const [timeStampLevel1, settimeStampLevel1] = useState(null);
  const [
    buttonWithCheckboxSelectedLevel1,
    setButtonWithCheckboxSelectedLevel1,
  ] = useState('');

  // wrapper function for setConentLevel1 and the provides the static HTML elements. Done for code brevity.
  const setContentLevel1html = ({ title, body }) => {
    setContentLevel1(
      <div>
        <label className="form-label text-primary">{title}</label>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }} />
      </div>
    );
  };

  const handleButtonCheckClickLevel1 = ({
    id,
    attributes: { title, body, updated_at },
  }) => {
    setContentLevel1html({ title, body });
    settimeStampLevel1(updated_at);
    setButtonWithCheckboxSelectedLevel1(id);
  };

  const handleTabClickLevel1 = () => {
    if (consent[0]) {
      setContentLevel1html(consent[0].attributes);
      setButtonWithCheckboxSelectedLevel1(consent[0].id);
      settimeStampLevel1(consent[0].attributes.updated_at);
    } else {
      setContentLevel1html({ title: '', body: '' });
    }
  };

  useEffect(() => {
    getConsent();
  }, [getConsent]);

  useEffect(() => {
    handleTabClickLevel1();
  }, [consent]);

  const level1Consent = () => {
    return (
      <div className="col h-100 d-flex flex-column">
        {isLoading ? (
          contentInitialLevel1
        ) : (
          <div>
            <div className="row p-3">
              {consent.map(consentItem => {
                return (
                  <div key={consentItem.id}>
                    <input
                      className="buttonWithCheckbox-input"
                      type="radio"
                      id={consentItem.id}
                      name="buttonWithCheckbox"
                      value="all"
                      onChange={() => handleButtonCheckClickLevel1(consentItem)}
                      checked={
                        buttonWithCheckboxSelectedLevel1 === consentItem.id
                      }
                    />
                    <label
                      className="buttonWithCheckbox-button"
                      htmlFor={consentItem.id}
                    >
                      <div className="buttonWithCheckbox-checkbox">
                        <div className="buttonWithCheckbox-box" />
                        <div
                          className="buttonWithCheckbox-check"
                          style={{
                            opacity: consentItem.attributes.has_agreed
                              ? '1'
                              : '0',
                          }}
                        />
                        <div className="buttonWithCheckbox-text">
                          {consentItem.attributes.title}
                        </div>
                      </div>
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="row flex-grow-1 p-3">
              <div
                className="flex-fill overflow-auto"
                style={{
                  border: 'solid 1px #D8D8D8',
                  borderRadius: '4px',
                  padding: '35px',
                }}
              >
                <div
                  className="card-body"
                  style={{ height: `${finalHeight}`, overflowY: 'auto' }}
                >
                  {contentLevel1}
                </div>
              </div>
            </div>
            <div
              className="row p-3"
              style={{ fontSize: '16px', fontWeight: '700', color: '#2DBE60' }}
            >
              {timeStampLevel1 &&
                `You have accepted this on: ${moment(timeStampLevel1).format(
                  'LLL'
                )}`}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container h-100">
      <div className="row p-3 h-100">
        <div className="col-12 h-100 d-flex flex-column">
          <div className="row">
            <ul className="flex-fill nav nav-tabs-baselined">
              <li className="nav-item">
                <NavLink
                  exact
                  to={`${match.url}`}
                  activeClassName="active"
                  className="nav-link smaller"
                  onClick={handleTabClickLevel1}
                >
                  <div className="ml-auto mr-auto">Consent</div>
                </NavLink>
              </li>
              {/* <li className="nav-item">
								<NavLink
									exact
									to={`${match.url}/level2Consent`}
									activeClassName="active"
									className="nav-link smaller"
								><div className="ml-auto mr-auto">Level 2 Consent</div>
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink
									exact
									to={`${match.url}/level3Consent`}
									activeClassName="active"
									className="nav-link smaller"
								><div className="ml-auto mr-auto">Level 3 Consent</div>
								</NavLink>
							</li> */}
            </ul>
          </div>

          <div className="row flex-grow-1">
            <div className="flex-fill p-4 tab-baselined-content overflow-hidden">
              <Switch>
                {/* <Route
									path={`${match.path}/level3Consent`}
									render={tabContentInitial}
								/>
								<Route
									path={`${match.path}/level2Consent`}
									render={tabContentInitial}
								/> */}
                <Route path={`${match.path}/`} render={level1Consent} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DataPrivacy.propTypes = {
  getConsent: PropTypes.func.isRequired,
  consent: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ api }) => ({
  consent: _.get(api, `${c.GET_CONSENT}.list`) || [],
  isLoading: api.loading.indexOf(c.GET_CONSENT) > -1,
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(DataPrivacy);
