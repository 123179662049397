import * as req from 'api/actions';
import { serialize } from 'helper';
import * as c from './constants';

export const verifyRetailerMin = (payload, successCallback, errCallback) =>
  req.create(
    c.VERIFY_RETAILER_MIN,
    'external/api/v1/dealers/retailers/min/validate',
    payload,
    successCallback,
    {},
    errCallback
  );

export const getRetailerList = payload =>
  req.list(c.GET_RETAILER_LIST, 'external/api/v1/retailers', payload);

export const getRetailerTypes = payload =>
  req.list(c.GET_RETAILER_TYPES, 'api/v1/retailers/types', payload);

export const getProvinceList = () =>
  req.list(c.GET_PROVINCE_LIST, 'api/v1/provinces', { per_page: 1000 });

export const getCityList = provinceCode =>
  req.list(c.GET_CITY_LIST, `api/v1/provinces/${provinceCode}/municipalities`, {
    per_page: 1000,
  });

export const getBarangayList = (provinceCode, cityCode) =>
  req.list(
    c.GET_BARANGAY_LIST,
    `api/v1/provinces/${provinceCode}/municipalities/${cityCode}/barangays`,
    { per_page: 1500 }
  );

export const showRetailerAddress = retailerId =>
  req.item(c.SHOW_RETAILER_ADDRESS, `external/api/v1/retailers/${retailerId}`);

export const resetCity = () => req.setItem(c.GET_CITY_LIST, {});

export const resetBarangay = () => req.setItem(c.GET_BARANGAY_LIST, {});

export const resetRetailerAdd = () => req.setItem(c.SHOW_RETAILER_ADDRESS, {});

export const addRetailer = (payload, succesCallback, errCallback) =>
  req.create(
    c.CREATE_RETAILER,
    'external/api/v1/retailers',
    payload,
    succesCallback,
    {},
    errCallback
  );

export const updateRetailer = (
  retailerId,
  payload,
  succesCallback,
  errCallback
) =>
  req.update(
    c.UPDATE_RETAILER,
    `external/api/v1/retailers/${retailerId}`,
    payload,
    succesCallback,
    {},
    errCallback
  );

export const downloadRetailerFormat = (payload, params, callback) =>
  req.download(
    c.DOWNLOAD_RETAILER_FORMAT,
    `external/api/v1/retailers/download/import-format${serialize(params)}`,
    payload,
    callback
  );

export const createBatchRetailer = (payload, callback, errCallback) =>
  req.create(
    c.CREATE_BATCH_RETAILER,
    'external/api/v1/retailers/import',
    payload,
    callback,
    {},
    errCallback
  );

export const getFailedImports = params =>
  req.list(c.GET_FAILED_IMPORTS, `external/api/v1/failed-imports`, params);

export const validatePassword = (payload, callback, errCallback) =>
  req.create(
    c.VALIDATE_PASSWORD,
    'api/v1/external/users/my/validate-password',
    payload,
    callback,
    {},
    errCallback
  );

export const DeleteRetailer = (id, callback, errCallback) =>
  req.remove(
    c.DELETE_RETAILER,
    `external/api/v1/retailers/${id}`,
    callback,
    {},
    '',
    errCallback
  );
