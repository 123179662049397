import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const CategoryFIlter = ({ categoryData, onSelectCategory, filter }) => {
  return (
    <div className="p-2">
      <small className="font-weight-bold">CATEGORIES</small>
      <a href="/" onClick={onSelectCategory(0, 'All Items')}>
        <div>
          <small
            className={`${filter.id === 0 && 'text-success font-weight-bold'}`}
          >
            Show All
          </small>
        </div>
      </a>
      {categoryData.map(x => (
        <a
          href="/"
          onClick={onSelectCategory(x.id, _.get(x, 'attributes.name'))}
          key={x.id}
        >
          <div>
            <small
              className={`${x.id === filter.id &&
                'text-success font-weight-bold'}`}
            >
              {_.get(x, 'attributes.name')}
            </small>
          </div>
        </a>
      ))}
    </div>
  );
};

CategoryFIlter.propTypes = {
  categoryData: PropTypes.instanceOf(Array).isRequired,
  filter: PropTypes.instanceOf(Object).isRequired,
  onSelectCategory: PropTypes.func.isRequired,
};

export default CategoryFIlter;
