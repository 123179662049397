import * as req from 'api/actions';
import { CHANGE_PASSWORD, REQUEST_OTP, CONFIRM_OTP } from './constants';

export const changePasswordDealers = (payload, callback) =>
  req.create(
    CHANGE_PASSWORD,
    'api/v1/external/users/change_password',
    payload,
    callback
  );

export const changePasswordUsers = (payload, callback) =>
  req.update(
    CHANGE_PASSWORD,
    'api/v1/external/users/change_password',
    payload,
    callback
  );

export const changePasswordRetailers = (payload, callback) =>
  req.create(
    CHANGE_PASSWORD,
    'api/v1/external/users/change_password',
    payload,
    callback
  );

export const requestOtp = (payload, callback) =>
  req.create(REQUEST_OTP, 'api/v1/retailers/update/set-otp', payload, callback);

export const confirmOtp = (payload, callback) =>
  req.create(
    CONFIRM_OTP,
    'api/v1/retailers/get-otp',
    { ...payload, purpose: 'portal-verification' },
    callback
  );
