import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import GenericTable from "../../common/components/GenericTable";

function OfferTable({ data, onSelectPromo, brand, retailer, isLoading }) {
  return (
    <div
      className="col-12"
      style={{ maxHeight: "calc(100vh - 215px)", overflowY: "scroll" }}
    >
      <div className="box-fit-wrapper h-100">
        <div className="box-fix-overflow">
          <GenericTable
            isLoading={isLoading}
            onSelectRow={onSelectPromo}
            format={[
              {
                key: (item) => (
                  <span> {_.get(item, "attributes.name") || "--"} </span>
                ),
                label: "Offer",
                width: "auto",
              },
              {
                key: (item) => (
                  <span> {_.get(item, "attributes.description") || "--"} </span>
                ),
                label: "Description",
                width: "auto",
              },
              {
                key: (item) => (
                  <span>PHP {_.get(item, "attributes.amount") || "--"} </span>
                ),
                label: "Price",
                width: "auto",
              },
            ]}
            data={data}
            subData={brand}
            newData={retailer}
          />
        </div>
      </div>
    </div>
  );
}

OfferTable.propTypes = {
  onSelectPromo: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array),
  brand: PropTypes.string,
  retailer: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
};

OfferTable.defaultProps = {
  data: [],
  brand: "",
  retailer: {},
  isLoading: false,
};

export default OfferTable;
