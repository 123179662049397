import React, { useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { service } from 'api/service';
import { ModalContext } from 'App';
import withModal from 'modules/common/hoc/withModal';
import { ToastError } from 'modules/common/components/Toast';
import ButtonAsync from 'components/Button';
import UploadField from '../components/UploadField';

function ValidateCSV() {
  const [loading, setLoading] = useState();
  const setModal = useContext(ModalContext);
  const [csv, setCsv] = useState();
  const handleUploadSuccess = useCallback(file => setCsv(file), [setCsv]);

  const handleValidate = useCallback(async () => {
    setLoading(true);
    if (!csv) return;
    try {
      const params = { csv_link: csv.url };
      const validation = await service.post(
        'external/api/v1/retailers/validate/import',
        params
      );
      const modalConfig = {
        csvData: csv,
        validation: validation?.data?.data,
      };
      setModal('batch-create-retailer-setup', modalConfig);
      setLoading(false);
    } catch (err) {
      const errors = err?.response?.data?.errors || [];
      setLoading(false);
      setModal({ isOpen: false });
      ToastError(<ErrorsList errors={errors} />);
    }
  }, [setModal, csv]);

  return (
    <div className="container-fluid py-3 px-5 pb-4">
      <div className="row">
        <div className="mb-4">
          <strong>Easy steps on enrolling retailers by batch</strong>
        </div>
        <div className="row mt-1">
          <div className="col-6 d-flex align-items-start border-right">
            <div className="mr-2 px-2 rounded-circle bg-dark">
              <span className="text-white">1</span>
            </div>

            <div className="description" style={{ lineHeight: '1.3' }}>
              <small>
                Download the starting template (.xlxs) below and follow the
                instructions within the file to generate file for batch upload.
              </small>
              <p className="mt-3">
                <Link
                  to={`${process.env.PUBLIC_URL}/Template_RetailerBatchUpload.xlsx`}
                  target="_blank"
                  download
                  className="btn btn-primary"
                >
                  <i className="fas fa-download mr-2" />
                  <small>Download Starting Template</small>
                </Link>
              </p>
            </div>
          </div>

          <div className="col-6 d-flex align-items-start pl-5 h-100">
            <div className="mr-2 px-2 rounded-circle bg-dark">
              <span className="text-white">2</span>
            </div>
            <div className="description w-100">
              <p className="text-sm">
                Drag and drop the accomplished CSV <br/>
                (Comma Separated Values or Comma Delimited)
                template file on the field below or click it to launch your
                system&apos;s file selection dialog
              </p>
              <div className="row d-flex align-items-center my-3 mb-4">
                <div className="col">
                  <UploadField onSuccess={handleUploadSuccess} />
                  <ButtonAsync
                    loading={loading}
                    className="btn btn-primary position-relative btn-block mt-3"
                    onClick={handleValidate}
                    disabled={!csv?.url}
                  >
                    Validate
                  </ButtonAsync>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const modalKey = 'validate-batch-create-retailer-csv';
const modalConfig = {
  title: 'Batch Create Retailers',
  size: 'modal-lg',
};

export default withModal(modalKey, modalConfig)(ValidateCSV);

const ErrorsList = ({ errors }) => {
  return errors.map(error => <div>{error?.detail}</div>);
};
