import React, { useEffect } from 'react';
import _ from 'lodash';
import { useRouteMatch, useHistory, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useProfile } from 'modules/auth/hooks';

import CompanyInformationPage from './pages/Company/CompanyInformationPage';
import SupportingDocumentsPage from './pages/SupportingDocuments/SupportingDocumentsPage';
import AccountInformationPage from './pages/AccountInformationPage/AccountInformationPage';

import * as c from './constants';
import * as actions from './actions';

const DealerInformationContainer = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const tabs_slugs = useRouteMatch(`${match.url}/:tabs_slug?`);
  const profile = useProfile();

  const dealer_id = profile?.id || profile?.parent_id;

  const { tabs_slug } = _.get(tabs_slugs, 'params');

  useEffect(() => {
    if (_.isEmpty(dealer_id)) return;
    dispatch(actions.showCompanyInformation(dealer_id));
    dispatch(actions.showUserDetails(dealer_id));
  }, [dealer_id]);

  return (
    <>
      <div className="scroll-lime partners partners__dealers-information partners__bg-light-gray px-4 py-3 pb-4 position-relative">
        <ul
          className="tabs-container d-flex mb-0 p-0 mt-3 w-100 position-sticky"
          style={{ top: 0, left: 0, zIndex: 1 }}
        >
          {c.tabsInfo.map(({ label, slug }) => (
            <button
              className={`tabs__container-button font-weight-bold px-3 text-xs py-2 ${
                tabs_slug === slug ? 'active' : ''
              }`}
              onClick={() => history.push(`${match.url}/${slug}`)}
              type="button"
              key={label}
            >
              {label}
            </button>
          ))}
        </ul>

        <Switch>
          <Route
            path={`${match.url}/company-information`}
            component={CompanyInformationPage}
          />
          <Route
            path={`${match.url}/account-information`}
            component={AccountInformationPage}
          />
          <Route
            path={`${match.url}/supporting-documents`}
            component={SupportingDocumentsPage}
          />
        </Switch>
      </div>
    </>
  );
};

export default React.memo(DealerInformationContainer);
