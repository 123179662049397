import React, { useState } from "react";
import Async from "react-select/async";
import { USER_PROFILE } from "modules/auth/constants";
import { rootApi } from "helper";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

function SelectBranchAdmins({
  className,
  value,
  onChange,
  isMulti,
  placeholder,
  disabledValues,
  branchId,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const isNonMigrated = profile?.roles[0]?.includes("non-migrated");

  const handleChangeSelect = (args) => {
    onChange(args);
  };

  const loadOptions = (keyword) =>
    new Promise(async (resolve) => {
      // TODO: CLEAN LATER!
      try {
        const token = sessionStorage.getItem("token");
        if (!document.getElementById("select-branch-admin")) return;
        setIsLoading(true);
        const raw = await fetch(
          `${rootApi}api/v1/external_users/branches/${branchId}/retailer_users/assignable?role_slug=${
            isNonMigrated ? "branch-admin-non-migrated" : "branch-admin"
          }&q=${keyword}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const res = await raw.json();
        const options = res.data
          .map((x) => ({
            label: _.get(x, "attributes.full_name"),
            value: x.id,
          }))
          .filter((x) => disabledValues.indexOf(x.value) < 0);
        if (!document.getElementById("select-branch-admin")) return;
        setIsLoading(false);

        resolve(options);
      } catch (err) {
        if (!document.getElementById("select-branch-admin")) return;
        setIsLoading(true);
        resolve([]);
      }
    });

  return (
    <Async
      id="select-branch-admin"
      className={className}
      isMulti={isMulti}
      defaultOptions
      placeholder={placeholder}
      onChange={handleChangeSelect}
      loadOptions={loadOptions}
      isLoading={isLoading}
      value={value}
    />
  );
}

SelectBranchAdmins.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  branchId: PropTypes.string.isRequired,
  disabledValues: PropTypes.instanceOf(Array),
};

SelectBranchAdmins.defaultProps = {
  className: "",
  isMulti: false,
  placeholder: "Select Branch Admin...",
  disabledValues: [],
};

export default SelectBranchAdmins;
