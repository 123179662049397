import { ModalContext } from "App";
import ListAsync from "components/ListAsync";
import SearchField from "components/SearchField";
import { removeEmpty } from "helper";
import { useRootPath } from "hooks";
import _ from "lodash";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Pagination from "modules/common/components/Pagination";
import { getClustersRaw } from "../actions";
import ClustersListItem from "../components/ClustersListItem";
import ClusterSetup from "./ClusterSetup";

const Listing = () => {
  const setModal = useContext(ModalContext);
  const history = useHistory();
  const rootPath = useRootPath();
  const urlParams = useParams();

  const [query, setQuery] = useState("");
  const [page, setPage] = useState("");

  const { isFetching, data, error, refetch } = useQuery({
    queryKey: ["clusters", "", removeEmpty({ q: query, page })],
    queryFn: getClustersRaw,
    retry: false,
    keepPreviousData: true,
  });

  const handleItemSelection = (item) => {
    history.push(`${rootPath}/${item.id}`);
  };

  const handleChangePage = (page_num) => setPage(page_num);

  const handleAddCluster = () => {
    setModal("cluster-setup", { isNew: true });
  };

  useEffect(() => {
    history.push(rootPath);
  }, [query, history, rootPath, page]);

  const sortedClusters = data?.data?.data?.sort((a, b) =>
    b?.attributes?.created_at?.localeCompare(a?.attributes?.created_at)
  );
  const pager = data?.data?.meta?.pagination || {};

  return (
    <Fragment>
      <div className="d-flex flex-column h-100 border-right border-gray-200">
        <div className="flex-grow-0">
          <div className="px-3 my-4">
            <SearchField
              isLoading={isFetching}
              onChange={(value) => {
                setPage("");
                setQuery(value);
              }}
            />
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleAddCluster}
            >
              Create New Cluster
            </button>
          </div>
        </div>
        <div className="flex-grow-1">
          <div className="box-fit-wrapper h-100">
            <div className="box-fit-overflow">
              <p className="m-0 px-3 py-2 text-sm text-gray-400">
                List of Clusters {!_.isEmpty(pager) ? `(${pager?.total})` : ""}
              </p>
              <ListAsync
                isLoading={isFetching}
                refetch={refetch}
                error={error}
                data={sortedClusters}
                renderItems={(item) => (
                  <ClustersListItem
                    key={item.id}
                    isActive={_.get(urlParams, "clusterId") === item.id}
                    onClick={handleItemSelection}
                    data={item}
                  />
                )}
              />
              <Pagination onChange={handleChangePage} data={pager} />
            </div>
          </div>
        </div>
      </div>
      <ClusterSetup />
    </Fragment>
  );
};

export default Listing;
