import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { diffFromNow } from 'helper';
import { useSessionStorage } from 'hooks';
import { USER_PROFILE } from 'modules/auth/constants';

import Notice from './components/Notice';
import Dialog from '../common/components/Dialog';

const minimum = process.env.REACT_APP_PW_NOTICE_START || 6.048e8;

const PasswordExpireNotice = ({ redirect }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [suppressed, setSuppressed] = useSessionStorage(
    'supress-password-expire',
    false
  );
  const { pathname } = useLocation();
  const history = useHistory();
  const expiry = useSelector(
    ({ api }) =>
      new Date(_.get(api, `${USER_PROFILE}.item.password_expiration`))
  );

  const handleRedirect = () => {
    history.push(redirect);
    setShowDialog(false);
  };

  const shouldWarn = expiry - new Date() < minimum && pathname !== redirect;
  const daysFromNow = Math.floor(diffFromNow(expiry, 8.64e7)); // 8.64e+7 milliseconds to day

  if (!shouldWarn || suppressed) return null;

  return (
    <React.Fragment>
      <Notice onClose={() => setSuppressed(true)}>
        <span>
          <strong>NOTIFICATION:</strong> Your password is about to expire in{' '}
          {daysFromNow} days. Take action now by clicking{' '}
          <button
            type="button"
            className="btn btn-link alert-link p-0 text-lowercase"
            onClick={() => setShowDialog(true)}
          >
            here
          </button>
          .
        </span>
      </Notice>
      <Dialog
        title="Password Expiration Notice"
        show={showDialog}
        showCloseButton
        onClose={() => setShowDialog(false)}
        onClickOutside={() => setShowDialog(false)}
        renderFooter={ref => (
          <button
            type="button"
            className="btn btn-primary mx-auto mb-3"
            onClick={handleRedirect}
            ref={ref}
          >
            Change my password now
          </button>
        )}
      >
        <p className="text-center col-10 mx-auto mt-4 mb-0">
          Regularly updating your password reduces the risk of your account
          being compromised by malicious softwares, hacking, and tampering.
        </p>
      </Dialog>
    </React.Fragment>
  );
};

PasswordExpireNotice.propTypes = {
  redirect: PropTypes.string.isRequired,
};

export default PasswordExpireNotice;
