import { ModalContext } from "App";
import ListAsync from "components/ListAsync";
import { checkPermissions, removeEmpty, removeNull } from "helper";
import { useRootPath } from "hooks";
import _ from "lodash";
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQuery } from "react-query";
import { CollapseFilter as Filter } from "components/Filter";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Pagination from "modules/common/components/Pagination";
import { get, set } from "session";
import { getBranchesRaw } from "../actions";
import BranchesListItem from "../components/BranchesListItem";
import BranchSetup from "./BranchSetup";

const form = [
  {
    type: "select-static",
    name: "is_blocked",
    label: "Status:",
    options: [
      { value: 0, label: "ACTIVE" },
      { value: 1, label: "INACTIVE" },
    ],
    className: "col-12",
    isClearable: true,
    isSearchable: false,
  },
];

const Listing = () => {
  const setModal = useContext(ModalContext);
  const history = useHistory();
  const location = useLocation();
  const rootPath = useRootPath();
  const urlParams = useParams();

  const { state = "" } = location;

  const [filter, setFilter] = useState({ q: "", is_blocked: "" });
  const [page, setPage] = useState("");

  const filterData = useMemo(
    () =>
      removeEmpty(
        removeNull({
          ...filter,
          is_blocked: filter?.is_blocked?.value,
          page,
        }),
      ),
    [filter, page],
  );

  useEffect(() => {
    setPage("");
  }, [filter.q]);

  const { isLoading, data, error, refetch } = useQuery({
    queryKey: ["branches", "", filterData],
    queryFn: getBranchesRaw,
    retry: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      const raw = res?.data?.data;
      if (state?.branchId) {
        const match = _.find(raw, (x) => x.id === state.branchId);
        if (match) {
          history.push(`${rootPath}/${state.branchId}`, state);
        }
      }
    },
  });

  const handleItemSelection = (item) => {
    history.push(`${rootPath}/${item.id}`);
  };

  const handleChangePage = (page_num) => {
    set("branch-ops-page", page_num);
    setPage(page_num);
    history.push(rootPath);
  };

  const handleAddBranch = () => {
    setModal("branch-setup", { isNew: true });
  };

  const sortedBranches = data?.data?.data?.sort((a, b) =>
    b?.attributes?.created_at?.localeCompare(a?.attributes?.created_at),
  );
  const pager = data?.data?.meta || {};
  const page_data = get("branch-ops-page") || "";

  useEffect(() => {
    if (state) setPage(page_data);
  }, [state, page_data]);

  return (
    <Fragment>
      <div className="d-flex flex-column h-100 border-right border-gray-200">
        <div className="flex-grow-0">
          <div className="px-3 my-4">
            {checkPermissions(["can-create-branch"]) && (
              <button
                type="button"
                className="btn btn-block btn-primary"
                onClick={handleAddBranch}
              >
                + Create Branch
              </button>
            )}
            <div className="my-2">
              <Filter
                form={form}
                filterData={[filter, setFilter]}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
        <div className="flex-grow-1">
          <div className="box-fit-wrapper h-100">
            <div className="box-fit-overflow">
              <p className="m-0 px-3 py-2 text-sm text-gray-400">
                List of Branches {!_.isEmpty(pager) ? `(${pager?.total})` : ""}
              </p>
              <ListAsync
                isLoading={isLoading}
                refetch={refetch}
                error={error}
                data={sortedBranches}
                renderItems={(item) => (
                  <BranchesListItem
                    key={item.id}
                    isActive={_.get(urlParams, "branchId") === item.id}
                    onClick={handleItemSelection}
                    data={item}
                  />
                )}
              />
              <Pagination onChange={handleChangePage} data={pager} />
            </div>
          </div>
        </div>
      </div>
      <BranchSetup />
    </Fragment>
  );
};

export default Listing;
