import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { service } from 'api/service';

const UploadField = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState();
  const [activeDrag, setActiveDrag] = useState();
  const { getRootProps, getInputProps } = useDropzone({
    accept:
      '.csv,text/csv,application/vnd.ms-excel,application/csv,text/x-csv,application/x-csv,text/comma-separated-values, text/x-comma-separated-values',
    multiple: false,
    onDrop: (accepted, rejected) => {
      setActiveDrag(false);
      setFiles({ accepted, rejected });
    },
    onDragEnter: () => setActiveDrag(true),
    onDragLeave: () => setActiveDrag(false),
  });

  const csvFile = useMemo(() => files?.accepted[0], [files]);

  const uploadFile = useCallback(
    async file => {
      if (!file) return;
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await service.post('api/v1/upload', formData);
        onSuccess({
          name: file?.name,
          url: response?.data?.data?.file,
        });
        setLoading(false);
      } catch (e) {
        // console.log(e);
        setLoading(false);
      }
    },
    [onSuccess, setLoading]
  );

  useEffect(() => {
    if (!csvFile) return;
    uploadFile(csvFile);
  }, [csvFile, uploadFile]);

  const rootClassNames = useMemo(() => {
    return `upload-field ${activeDrag ? `border-primary` : ``}`;
  }, [activeDrag]);

  return (
    <>
      <div {...getRootProps({ className: rootClassNames })}>
        <input {...getInputProps()} />
        {files?.accepted?.length ? (
          <>
            {files?.accepted?.map(file => (
              <div className="d-flex align-items-center" key={file.name}>
                <div className="d-flex mr-2">
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm text-gray-200"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <i className="fas fa-check text-success" />
                  )}
                </div>
                <div>{file.name}</div>
              </div>
            ))}
          </>
        ) : (
          <div className="text-center text-gray-400">
            <strong>
              Drag and drop CSV file here, or click to select file
            </strong>
          </div>
        )}
      </div>
    </>
  );
};

UploadField.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default memo(UploadField);
