import React from 'react';
import GenericTable from 'modules/common/components/GenericTable';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../action';
import * as c from '../constant';

function RoleContainer({ isLoading, getRoles, roleList }) {
  React.useEffect(() => {
    getRoles();
  }, [getRoles]);

  return (
    <div className="main-content-inner">
      <div className="d-flex align-items-center mb-2 mt-2">
        <h1 className="h5 flex-grow-1 mt-0 mb-0">
          List of User Roles <br />
          <small>List of Users Roles</small>
        </h1>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0 bg-white border">
            <GenericTable
              isLoading={isLoading}
              format={[
                {
                  key: item => (
                    <span>{_.get(item, 'attributes.name') || '--'}</span>
                  ),
                  label: 'Role',
                  width: 'auto',
                },
              ]}
              data={roleList}
              height="80vh"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

RoleContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getRoles: PropTypes.func.isRequired,
  roleList: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api }) => ({
  roleList: _.get(api, `${c.GET_ROLES}.list`) || [],
  isLoading: api.loading.indexOf(c.GET_ROLES) > -1,
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(RoleContainer);
