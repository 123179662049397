/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import { checkPermissions } from 'helper';
import withModal from 'modules/common/hoc/withModal';
import { ModalContext } from 'App';
import { ToastSuccess } from 'modules/common/components/Toast';
import * as actions from '../actions';
import * as c from '../constant';

function SetPassword({ payload, isLoading, forgotPin }) {
  const setModal = React.useContext(ModalContext);
  const [state, setState] = React.useState({});
  const [view, setView] = React.useState();

  const closeModal = e => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const response = {
      ...payload,
      ...state,
    };
    const callback = () => {
      setModal({ isOpen: false });
      ToastSuccess('Your pin was successfully changed.');
    };
    forgotPin(response, callback);
  };

  const handleOnViewPassword = (key, value) => e => {
    e.preventDefault();
    setView({
      [key]: value,
    });
  };
  const handleChangeInput = key => ({ target }) => {
    setState({
      ...state,
      [key]: target.value,
    });
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <label
            className="form-label  text-medium text-left font-extrabold"
            style={{ color: '#4a4a4a' }}
          >
            Are you sure you want to Reset your Security PIN?
          </label>
          <p className="text-sm my-2">Your Password is required to continue.</p>
          <div
            className="prepend-form-right mt-4"
            style={{ maxWidth: '250px' }}
          >
            <span className="text-sm text-primary">Password :</span>
            <input
              className="form-control form-custom form-control_bottom text-sm"
              type={_.get(view, 'password') ? 'text' : 'password'}
              placeholder="Insert Password"
              required
              onChange={handleChangeInput('password')}
              value={_.get(state, 'password') || ''}
              disabled={!checkPermissions(['can-update-password'])}
            />
            <button
              type="button"
              onClick={handleOnViewPassword(
                'password',
                !_.get(view, 'password')
              )}
              className="btn btn-default rounded-circle p-0 mr-1"
            >
              <i
                className={`fas ${
                  !_.get(view, 'password') ? 'fa-eye' : 'fa-eye-slash'
                }`}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-center">
            <button
              type="submit"
              className="btn forgot-btn font-weight-bold mr-2"
              disabled={isLoading}
            >
              Submit
            </button>
            <button
              type="button"
              onClick={closeModal}
              className="btn forgot-btn font-weight-bold ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

SetPassword.propTypes = {
  inquireMpin: PropTypes.func.isRequired,
  updatePin: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
const mapStateToProps = ({ api, modal }) => ({
  payload: _.get(modal, 'modalData.payload'),
  forgotPin: _.get(api, `${c.FORGOT_PIN}`),
  isLoading:
    api.loading.indexOf(c.MPIN) > -1 &&
    api.loading.indexOf(c.UPDATE_PROFILE) > -1,
});

const enhance = _.flowRight([
  withModal('set-password', {
    title: 'RESET SECURITY PIN',
    size: 'modal-md',
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(SetPassword);
