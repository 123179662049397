import React from 'react';
// import PropTypes from 'prop-types';

function SettingsContainer() {
  return <div className="main-content-inner">Settings Container</div>;
}

SettingsContainer.propTypes = {};

export default SettingsContainer;
