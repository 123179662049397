import React, { useContext, useMemo } from "react";
import { ModalContext } from "App";
import _ from "lodash";
import Button from "components/Button";
import { useQueryClient, useQuery, useMutation } from "react-query";
import withModal from "modules/common/hoc/withModal";
import GenericTable from "modules/common/components/GenericTable";
import { UNLINKED_PARAMS } from "app/load-wallet/constant";
import { getParentSim, bulkLinkRetailers } from "app/load-wallet/actions";
import { ToastError, ToastSuccess } from "modules/common/components/Toast";
import ErrorMessage from "modules/common/components/ErrorMessage";
import { useProfile } from "modules/auth/hooks";

const ViewUnlinked = () => {
  const setModal = useContext(ModalContext);
  const queryClient = useQueryClient();
  const profile = useProfile();
  const id = profile?.id;

  const errorCallback = (err, defaultMessage) => {
    const errorMessage = (
      <ErrorMessage error={err} defaultMessage={defaultMessage} />
    );
    ToastError(errorMessage);
    setModal({ isOpen: false });
  };

  const { data, isFetching } = queryClient.getQueryState([
    "unlinked-retailers",
    { evc_status: UNLINKED_PARAMS },
  ]);

  const apiParentSim = useQuery({
    queryKey: ["parent-sim", { dealer_id: id, type: "KA-BDL" }],
    queryFn: getParentSim,
    retry: false,
    keepPreviousData: true,
  });

  const dealer_sim_id = _.get(apiParentSim, "data.data.data.0.id");

  const { mutateAsync: link } = useMutation(bulkLinkRetailers, {
    onSuccess: () => {
      queryClient.invalidateQueries("retailer-id");
      queryClient.invalidateQueries("unlinked-retailers");
      queryClient.invalidateQueries("retailers-listing");
      ToastSuccess("Retailer batch linking is in progress.");
    },
    onError: (err) => errorCallback(err, "Failed to start batch linking."),
  });

  const list = data?.data?.data || [];

  const retailer_ids = useMemo(() => list.map((sim) => sim.id), [list]);

  const handleModalClose = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const callBack = () => link({ dealer_sim_id, retailer_ids });

  const openPasswordConfirmation = () =>
    setModal("confirm-password-setup", { callBack });

  return (
    <div className="col">
      <GenericTable
        isLoading={isFetching}
        headerClassName="bg-gray-500 rounded-top"
        format={[
          {
            key: (item) => <span>{item?.attributes?.full_name}</span>,
            label: "Name",
            width: "auto",
          },
          {
            key: (item) => (
              <span>{item?.attributes?.mobile_identification_number}</span>
            ),
            label: "MIN",
            width: "auto",
          },
        ]}
        data={list}
      />
      <div className="mt-2">
        <small>
          <b>
            Link MIN-to-Parent process will proceed by clicking the
            &quot;LINK&quot; button.
          </b>
        </small>
      </div>
      <div className="d-flex justify-content-end pt-4">
        <Button onClick={openPasswordConfirmation}>Link</Button>
        <Button onClick={handleModalClose} className="btn btn-gray ml-2">
          Cancel
        </Button>
      </div>
    </div>
  );
};

const modalKey = "view-unlinked-retailers";
const modalConfig = {
  title: "List of Unlinked Retailer MINs",
  size: "modal-md modal-center",
};

export default withModal(modalKey, modalConfig)(ViewUnlinked);
