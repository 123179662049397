export const SEND_OTP = "USER-MGT/send_otp";
export const CONFIRM_OTP = "USER-MGT/confirm_otp";
export const GET_USERS = "USER-MGT/get_user";
export const GET_USER_BY_ID = "USER-MGT/get_user_by_id";
export const CREATE_USERS = "USER-MGT/create_user";
export const UPDATE_USERS = "USER-MGT/update_user";
export const GET_BRANCH = "USER-MGT/get_branch";
export const GET_SHIFT = "USER-MGT/get_shift";
export const DOWNLOAD_FORMAT = "USER-MGT/download_format";
export const DOWNLOAD_IMPORT_FORMAT_DU =
  "USER-MGT/download_import_format_dealer_user";
export const GET_FAILED_IMPORTS = "USER-MGT/get_failed_imports";
export const CREATE_BATCH = "USER-MGT/create_batch";
export const CREATE_BATCH_DU = "USER-MGT/create_batch_dealer_user";

export const ASSIGN = "USER-MGT/assign";
export const DETACH = "USER-MGT/detach";
export const GET_ASSIGNED_BRANCH_SHIFT = "USER-MGT/get_assigned_branch_shift";

export const GET_CORP_ADMIN = "USER-MGT/get_corp_admin";
export const CREATE_CORP_ADMIN = "USER-MGT/create_corp_admin";
export const UPDATE_CORP_ADMIN = "USER-MGT/update_corp_admin";

export const GET_ROLES = "USE-MGT/get_roles";
export const GET_TAB_ROLES = "USER-MGT/get-tab-roles";
export const VALIDATE_PASSWORD = "USE-MGT/validate_password";

export const LOGOUT_USER = "AUTH/logout-user";

export const CaSdaTemplates = {
  ca_rd: "Template_UAM_CorpAdmin_SubDealerAgent_RD.xlsx",
  ca_pd: "Template_UAM_CorpAdmin_SubDealerAgent.xlsx",
  ca_pdca: "Template_UAM_CorpAdmin_SubDealerAgent_PDCA.xlsx",
  ca_smmt: "Template_UAM_CorpAdmin_SubDealerAgent_SMMT.xlsx",
  ca_ka: "Template_UAM_CorpAdmin_SubDealerAgent_KA.xlsx",
  ca_ska: "Template_UAM_CorpAdmin_SubDealerAgent_SKA.xlsx",
  ca_su_b2b: "Template_UAM_CorpAdmin_SU_B2B.xlsx",
  ca_su_nm: "Template_UAM_CorpAdmin_SU_NM.xlsx",
};

export const BaFlTemplates = {
  ba_fl: "Template_UAM_Branch_Admin_Frontliner.xlsx",
  fl: "Template_UAM_Frontliner.xlsx",
  ba_fl_nm: "Template_UAM_Branch_Admin_Frontliner_NM.xlsx",
  fl_nm: "Template_UAM_Frontliner_NM.xlsx",
};

export const blFlInitState = {
  q: "",
  page: 1,
  per_page: 10,
  is_web: true,
  order_by: "created_at",
  sort_by: "desc",
  is_active: "",
  role_slug: "",
};

export const caSdaInitState = {
  q: "",
  page: 1,
  per_page: 10,
  order_by: "created_at",
  sort_by: "desc",
  is_active: "",
  role_slug: "",
};

export const form = [
  {
    type: "select-async",
    name: "role_slug",
    label: "Role:",
    api: "api/v1/acl/kapartner-web/roles",
    className: "col-3",
    isClearable: true,
    isSearchable: false,
    transformer: (list) =>
      list?.data.map((x) => ({
        value: x?.attributes?.slug,
        label: x?.attributes?.slug.includes("corporate-admin")
          ? "Corporate Admin"
          : x?.attributes?.name,
      })),
  },
  {
    type: "select-static",
    name: "is_active",
    label: "Status:",
    options: [
      { value: 1, label: "ACTIVE" },
      { value: 0, label: "INACTIVE" },
    ],
    className: "col-3",
    isClearable: true,
    isSearchable: false,
  },
];

export const guard_name = {
  "corporate-admin-smmt": "smmt-dealer-user",
  "corporate-admin-ka": "ka-dealer-user",
  "corporate-admin-rd": "rd-dealer-user",
  "corporate-admin": "dealer-user",
  "corporate-admin-ska": "ska-dealer-user",
  "corporate-admin-b2b": "b2b-dealer-user",
};

export const getGuardName = (user_role) => {
  const payload = {
    user_guard_name: "",
    role_guard_name: "",
  };
  const isSMMTCA = user_role === "corporate-admin-smmt";
  const isKACA = user_role === "corporate-admin-ka";
  const isRDCA = user_role === "corporate-admin-rd";
  const isPDCA = user_role === "corporate-admin";
  const isSKACA = user_role === "corporate-admin-ska";
  const isB2BCA = user_role === "corporate-admin-b2b";
  const isSUNM = user_role === "super-user-non-migrated";
  const isCANM = user_role === "corporate-admin-non-migrated";
  const isBANM = user_role === "branch-admin-non-migrated";

  if (isSMMTCA) payload.user_guard_name = "smmt-dealer-user";
  else if (isKACA) payload.user_guard_name = "ka-dealer-user";
  else if (isRDCA) payload.user_guard_name = "rd-dealer-user";
  else if (isSKACA) payload.user_guard_name = "ska-dealer-user";
  else if (isPDCA) payload.user_guard_name = "dealer-user";
  else if (isB2BCA) payload.user_guard_name = "b2b-dealer-user";
  else if (isSUNM) payload.role_guard_name = "non-migrated-retailer-user";
  else if (isCANM) {
    payload.user_guard_name = "non-migrated-dealer-user";
    payload.role_guard_name = "non-migrated-retailer-user";
  } else if (isBANM) {
    payload.user_guard_name = "non-migrated-retailer-user";
    payload.role_guard_name = "non-migrated-retailer-user";
  }
  return payload;
};

export const getCAGuardName = (role) => {
  const payload = {
    role_guard_name: "",
  };
  if (role.includes("smmt")) payload.role_guard_name = "smmt-dealer-user";
  else if (role === "super-user-b2b" || role.includes("-b2b"))
    payload.role_guard_name = "b2b-dealer-user";
  else if (role === "super-user" || role.includes("rd"))
    payload.role_guard_name = "rd-dealer-user";
  else if (role === "super-user-ka" || role.includes("-ka"))
    payload.role_guard_name = "ka-dealer-user";
  else if (role.includes("-ska")) payload.role_guard_name = "ska-dealer-user";
  else if (role === "super-user-non-migrated" || role.includes("non-migrated"))
    payload.role_guard_name = "non-migrated-dealer-user";
  else payload.role_guard_name = "dealer-user";
  return payload;
};
