import * as req from 'api/actions';
import _ from 'lodash';
import store from 'store';
import * as c from './constant';

export const inquireBalance = (payload, callback) =>
  req.create(
    c.INQUIRE_BALANCE,
    'mobile/api/v2/inquire-balance',
    payload,
    callback
  );

export const showRetailer = (retailerId, payload, callback) =>
  req.item(c.SHOW_RETAILER, `api/v1/retailers/${retailerId}`, payload, res => {
    const sourceFund = _.get(res, 'data.attributes.branch.sourceFunds') || [];
    store.dispatch(req.setList(`${c.SHOW_RETAILER}/sourceFund`, sourceFund));
    if (callback) callback(res);
  });

export const showRetailerUses = (payload, callback) =>
  req.item(c.SHOW_RETAILER, 'api/v1/retailer-users/my', payload, callback);

export const getBentaKita = (payload, callback) =>
  req.list(
    c.GET_BENTA_KITA,
    'api/v1/logs/transactions/sales-profit',
    payload,
    res => {
      let parseSales = [];
      let parseProfit = [];
      const graphData = {
        labels: (_.get(res, 'data') || []).map(item => {
          const sales = +_.get(item, 'attributes.sales').replace(/,/g, '');
          const profit = +_.get(item, 'attributes.profit').replace(/,/g, '');
          parseSales = parseSales.concat([sales]);
          parseProfit = parseProfit.concat([profit]);
          return _.get(item, 'attributes.period');
        }),
        datasets: [
          {
            label: 'Total Sales',
            fill: true,
            cubicInterpolationMode: 'monotone',
            borderColor: 'rgba(0, 166, 215, 1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBackgroundColor: 'rgba(255,255,255,1)',
            pointBorderColor: 'rgba(0, 166, 215, 1)',
            pointBorderWidth: 2,
            pointRadius: 3,
            borderWidth: 2,
            showLine: true,
            pointHitRadius: 50,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(0, 166, 215, 1)',
            pointHoverBorderColor: 'rgba(0, 166, 215, 1)',
            pointHoverBorderWidth: 5,
            data: parseSales,
          },
          {
            label: 'Earnings',
            fill: true,
            cubicInterpolationMode: 'monotone',
            borderColor: 'rgba(45, 190, 96, 1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBackgroundColor: 'rgba(255,255,255,1)',
            pointBorderColor: 'rgba(45, 190, 96, 1)',
            pointBorderWidth: 2,
            pointRadius: 3,
            borderWidth: 2,
            showLine: true,
            pointHitRadius: 50,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(45, 190, 96, 1)',
            pointHoverBorderColor: 'rgba(45, 190, 96, 1)',
            pointHoverBorderWidth: 5,
            data: parseProfit,
          },
        ],
      };
      store.dispatch(req.setItem(`${c.GET_BENTA_KITA}/graph`, graphData));
      if (callback) callback(res);
    }
  );

export const getLoadTransaction = (payload, callback) =>
  req.list(
    c.GET_LOAD_TRANSACTION,
    'api/v1/logs/transactions',
    payload,
    callback
  );

export const getBillsTransaction = (id, payload, callback) =>
  req.list(
    c.GET_BILLS_TRANSACTION,
    `api/v1/retailers/${id}/transactions`,
    payload,
    callback
  );

export const getBentaKitaDealer = (payload, callback) =>
  req.list(
    c.GET_BENTA_KITA_DEALER,
    'api/v1/logs/transactions/sales-profit/complete-range',
    payload,
    res => {
      let parseSales = [];
      let parseProfit = [];
      const graphData = {
        labels: (_.get(res, 'data') || []).map(item => {
          const sales = +_.get(item, 'attributes.sales').replace(/,/g, '');
          const profit = +_.get(item, 'attributes.profit').replace(/,/g, '');
          parseSales = parseSales.concat([sales]);
          parseProfit = parseProfit.concat([profit]);
          return _.get(item, 'attributes.period');
        }),
        datasets: [
          {
            label: 'Benta',
            fill: true,
            cubicInterpolationMode: 'monotone',
            borderColor: 'rgba(0, 166, 215, 1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBackgroundColor: 'rgba(255,255,255,1)',
            pointBorderColor: 'rgba(0, 166, 215, 1)',
            pointBorderWidth: 2,
            pointRadius: 3,
            borderWidth: 2,
            showLine: true,
            pointHitRadius: 50,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(0, 166, 215, 1)',
            pointHoverBorderColor: 'rgba(0, 166, 215, 1)',
            pointHoverBorderWidth: 5,
            data: parseSales,
          },
          {
            label: 'Kita',
            fill: true,
            cubicInterpolationMode: 'monotone',
            borderColor: 'rgba(45, 190, 96, 1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBackgroundColor: 'rgba(255,255,255,1)',
            pointBorderColor: 'rgba(45, 190, 96, 1)',
            pointBorderWidth: 2,
            pointRadius: 3,
            borderWidth: 2,
            showLine: true,
            pointHitRadius: 50,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(45, 190, 96, 1)',
            pointHoverBorderColor: 'rgba(45, 190, 96, 1)',
            pointHoverBorderWidth: 5,
            data: parseProfit,
          },
        ],
      };
      store.dispatch(
        req.setItem(`${c.GET_BENTA_KITA_DEALER}/graph`, graphData)
      );
      if (callback) callback(res);
    }
  );

export const getBranch = (payload, callback) =>
  req.item(
    c.GET_BRANCH,
    'api/v1/retailer/retailer_users/my/branches/current',
    payload,
    callback
  );

export const getTop = (params, callback) =>
  req.list(
    c.GET_DEALER_DASHBOARD_TOPS,
    'api/v1/logs/dashboard',
    params,
    callback
  );
