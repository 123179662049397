import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalContext } from 'App';
import { USER_PROFILE } from 'modules/auth/constants';
import Actions from 'components/Actions';
import { checkPermissions, join } from 'helper';
import {useDealerSim} from 'app/load-wallet/dealer-sims/context/DealerSimProvider';
import * as c from 'modules/LoadWalletManagement/constant';
import {
  getDealerSims,
  retryFailedSim,
} from 'modules/LoadWalletManagement/actions';
import { downloadSimHistory } from '../actions';


const button = `mt-0 btn btn-block btn-default py-1 rounded-0 text-right`;
const button_bt = join`${button} border-top`;

function DealerSimActions({ item, accountTypeName, dealerRole }) {
  const { simDealerId, filterData } = useDealerSim();
  const dispatch = useDispatch();
  const setModal = useContext(ModalContext);
  const { url } = useRouteMatch();
  const match = useRouteMatch(`${url}/:dealerId?`);
  const history = useHistory();
  const handleDownloadSimsHistory = () =>
    dispatch(downloadSimHistory(item.id, {}));
  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const handleThreshold = () => setModal('threshold', { data: item });
  const handleViewBalance = () => setModal('balance', item);
  const handleTransfer = () => setModal('transfer-load', { item, dealerRole });
  const handleChange = () =>
    setModal('change-top-org', { dealerSims: item });
  const handleUnlink = () => setModal('delink-dealer-sim', { data: item });
  const handleUpdateDealerModal = () =>
    setModal('fill-update-dealer-sim', {
      data: item,
    });
  const handleDetailsDealerModal = () =>
    setModal('fill-update-dealer-sim', { data: item });
  const handleRedirect = () => {
     const pathname = url ===  "/load-wallet/sub-dealer-sims" ?  `${url}/${item.id}/transactions` : `${url}/transactions/${item.id}`
    return history.push({ pathname });

  }

  const isBA = profile?.roles?.[0]?.includes("branch-admin");
  const {
    status,
    sim_status,
    is_bdl_sim_delete,
    type,
    mobile_identification_number,
  } = item?.attributes || '';

  const handleBlock = () => {
    const payload = {
      sim_status: 'BLOCKED',
      id: item?.id,
      mobile_identification_number,
      tempSubmitType: 'block',
    };

    setModal('dealer-sim-confirm-password', payload);
  };

  const handleUnblock = () => {
    const payload = {
      sim_status: c.SIM_STATUS.active,
      id: item?.id,
      mobile_identification_number,
      tempSubmitType: 'unblock',
    };

    setModal('dealer-sim-confirm-password', payload);
  };

  const isActive = sim_status?.includes(c.SIM_STATUS.active)
  const isApproved = status?.includes(c.DEALERSIM_STATUS.approved)
  const isApproval =status?.includes(c.DEALERSIM_STATUS.approval)
  const isBlocked = sim_status?.includes(c.SIM_STATUS.blocked)
  const isDeleting = item?.attributes?.delete_status?.includes('Processing'); 
  const isIncomplete = status?.includes(c.DEALERSIM_STATUS.incomplete)
  const isFailed = status?.includes(c.DEALERSIM_STATUS.failed)
  const isDeclined = status?.includes(c.DEALERSIM_STATUS.declined)
  const bdlType = type?.includes('BDL')

  const handleRetry = () => {
    const payload = {
      dealer_id: item?.attributes?.dealer_id,
      mobile_identification_number: item?.attributes?.mobile_identification_number,
      assigned_to: item?.attributes?.assigned_to,
      type: item?.attributes?.type,
      userType: 'dealers',
    };

    const dealerSimCallback = () => {
      return dispatch(
        getDealerSims({
          dealer_id: simDealerId,
          userType: 'dealers',
          filterData,
        })
      );
    };

    if (isIncomplete) {
      dispatch(
        retryFailedSim(item.id, payload, dealerSimCallback, dealerSimCallback)
      );
    }
    if (isFailed) {
      setModal('dealer-sim-confirm-password', {
        ...payload,
        mobile_identification_number:item?.attributes?.mobile_identification_number,
        tempSubmitType: 'update',
        old_min:item?.attributes?.mobile_identification_number,
        status: c.DEALERSIM_STATUS.approved,
      });
    }
  };

  const ACL = [
    {
      id: 'details',
      label: 'Details',
      class: button_bt,
      display: isApproval || isFailed || isIncomplete,
      handler: handleDetailsDealerModal,
      permission: ['can-update-dealer-sim'],
    },
    {
      id: 'update',
      label: 'Update',
      class: button_bt,
      display: isDeclined || isApproved && (isActive && !isBA && !isDeleting),
      handler: handleUpdateDealerModal,
      permission: ['can-update-dealer-sim'],
    },
    {
      id: 'view-balance',
      label: 'View Balance',
      class: button_bt,
      display:
      !isFailed &&
      (!isIncomplete && isApproved && !isBlocked) &&
       !isDeleting,
      handler: handleViewBalance,
      permission: ['can-view-dealer-sim-balance'],
    },
    {
      id: 'threshold',
      label: 'Threshold',
      class: button_bt,
      display: !isApproval && isApproved && !isBlocked && !isDeleting,
      handler: handleThreshold,
      permission: ['can-update-dealer-sim'],
    },
    {
      id: 'history',
      label: 'History',
      class: button_bt,
      display:!isApproval && isApproved && !isBlocked && !isDeleting,
      handler: handleDownloadSimsHistory,
      permission: ['can-view-dealer-sim-balance'],
    },
    {
      id: 'transaction',
      label: 'Transaction',
      class: button_bt,
      display:!isApproval && isApproved && !isBlocked && !isDeleting,
      handler: handleRedirect,
      permission: ['can-view-dealer-sim'],
    },
    {
      id: 'transfer',
      label: 'Transfer',
      class: button_bt,
      display:
      (isApproved && !match?.params?.dealerId && !isBlocked) && !isDeleting,
      handler: handleTransfer,
      permission: ['can-create-dealer-sim-transaction-internal'],
    },
    {
      id: 'retry',
      label: 'Retry',
      class: button_bt,
      display: isFailed || isIncomplete,
      handler: handleRetry,
      permission: ['can-update-dealer-sim'],
    },
    {
      id: 'unlink',
      label: 'Delete',
      class: button_bt,
      display:
      (!bdlType && !isApproval) ||
      (is_bdl_sim_delete && !isBA && !isDeleting),
      handler: handleUnlink,
      permission: ['can-delete-dealer-sim'],
    },
    {
      id: 'change',
      label: 'Change',
      class: button_bt,
      display: isApproved && bdlType && !isBlocked && !isBA && !isDeleting,
      handler: handleChange,
      permission: ['can-update-dealer-sim'],
    },
    {
      id: 'block',
      label: 'Block',
      class: button_bt,
      display: isApproved && !isBlocked && !isBA && !isDeleting,
      handler: handleBlock,
      permission: ['can-update-dealer-sim'],
    },
    {
      id: 'unblock',
      label: 'Unblock',
      class: button_bt,
      display: isApproved && isBlocked && !isBA && !isDeleting,
      handler: handleUnblock,
      permission: ['can-update-dealer-sim'],
    },
  ];
  return (
    <Actions id={item?.id}>
      {ACL?.map(
        action =>
          checkPermissions(action?.permission) &&
          action?.display && (
            <button
              key={action?.id}
              type="button"
              className={action?.class}
              onClick={action?.handler}
            >
              <small>{action?.label}</small>
            </button>
          )
      )}
    </Actions>
  );
}
DealerSimActions.defaultProps = {
  accountTypeName: "",
  dealerRole:"",
};
DealerSimActions.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  accountTypeName: PropTypes.string,
  dealerRole: PropTypes.string,
};

export default DealerSimActions;
