export const VERIFY_RETAILER_MIN = "RETAILER/VERIFY_RETAILER_MIN";
export const GET_RETAILER_LIST = "RETAILER/GET_RETAILER_LIST";

export const GET_RETAILER_TYPES = "RETAILER/GET_RETAILER_TYPES";
export const GET_PROVINCE_LIST = "RETAILER/GET_PROVINCE_LIST";
export const GET_CITY_LIST = "RETAILER/GET_CITY_LIST";
export const GET_BARANGAY_LIST = "RETAILER/GET_BARANGAY_LIST";
export const GET_FAILED_IMPORTS = "RETAILER/GET_FAILED_IMPORTS";

export const SHOW_RETAILER_ADDRESS = "RETAILER/SHOW_RETAILER_ADDRESS";

export const CREATE_RETAILER = "RETAILER/CREATE_RETAILER";
export const CREATE_BATCH_RETAILER = "RETAILER/CREATE_BATCH_RETAILER";

export const UPDATE_RETAILER = "RETAILER/UPDATE_RETAILER";

export const DOWNLOAD_RETAILER_FORMAT = "RETAILER/DOWNLOAD_RETAILER_FORMAT";

export const VALIDATE_PASSWORD = "VALIDATE_PASSWORD";

export const DELETE_RETAILER = "DELETE_RETAILER";

export const compoundSearchRetailerDefaultValue = {
  order_by: "created_at",
  sort_by: "desc",
  page: 1,
  per_page: 10,
  q: "",
  is_active: "",
};

export const labelStyles = { fontSize: ".6rem" };
export const inputStyles = { fontSize: ".7rem" };
export const TEXT_LIMIT = 20;
export const selectOptionStyles = {
  control: (base) => ({
    ...base,
    border: 0,
    borderBottom: "1px solid gray",
    borderRadius: 0,
    // This line disable the blue border
    boxShadow: "none",
  }),
};

export const addUpdateRetailerDefaultValue = {
  mobile_identification_number: "",
  retailer_type: "",
  birth_date: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",

  line: "",
  province: "",
  city_municipality: "",
  barangay: "",
  postal_code: "",
};

export const statusOptions = [
  { value: true, label: "Active" },
  { value: false, label: "Deactivated" },
];

export const form = [
  {
    type: "select-static",
    name: "is_active",
    label: "Status:",
    options: statusOptions,
  },
];
