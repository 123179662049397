import React, { useContext } from "react";
import DataField from "components/DataField";
import Panel from "components/Panel";
import PanelRow from "components/PanelRow";
import _ from "lodash";
import { ModalContext } from "App";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  getRetailerById,
  getParentSim,
  linkRetailerMin,
  unlinkRetailerMin,
} from "app/load-wallet/actions";
import { UNLINKED_STATUS } from "app/load-wallet/constant";
import ConfirmAlert from "modules/common/components/ConfirmAlert";
import { ToastError, ToastSuccess } from "modules/common/components/Toast";
import ErrorMessage from "modules/common/components/ErrorMessage";
import Button from "components/PanelButton";
import { useProfile } from "modules/auth/hooks";

export const renderStatus = {
  1: <span className="badge badge-clear-success p-0">active</span>,
  0: <span className="badge badge-clear-danger p-0">inactive</span>,
  progress: <span className="badge badge-clear-warning p-0">in progress</span>,
};

const DetailsPanel = () => {
  const urlParams = useParams();
  const queryClient = useQueryClient();
  const setModal = useContext(ModalContext);
  const retailerId = _.get(urlParams, "retailerId");
  const profile = useProfile();
  const role = _.head(profile?.roles);
  const id = profile?.id;
  const isKA = role.includes("ka");

  const apiRetailer = useQuery({
    queryKey: ["retailer-id", retailerId],
    queryFn: getRetailerById,
    retry: false,
    keepPreviousData: true,
  });

  const apiParentSim = useQuery({
    queryKey: ["parent-sim", { dealer_id: id, type: "KA-BDL" }],
    queryFn: getParentSim,
    retry: false,
    keepPreviousData: true,
  });

  const errorCallback = (err, defaultMessage) => {
    const errorMessage = (
      <ErrorMessage error={err} defaultMessage={defaultMessage} />
    );
    ToastError(errorMessage);
    setModal({ isOpen: false });
  };

  const dealer_sim_id = _.get(apiParentSim, "data.data.data.0.id");
  const retailer_id = _.get(apiRetailer, "data.data.data.id");

  const retailer = apiRetailer?.data?.data?.data?.attributes;
  const evc_status = retailer?.evc_status || null;

  const isInProgress = evc_status ? evc_status.includes("in-progress") : false;
  const link_status = isInProgress
    ? "progress"
    : +!UNLINKED_STATUS.includes(evc_status);

  const action = link_status ? unlinkRetailerMin : linkRetailerMin;

  const { mutateAsync: link } = useMutation(action, {
    onSuccess: () => {
      queryClient.invalidateQueries("retailer-id");
      queryClient.invalidateQueries("unlinked-retailers");
      queryClient.invalidateQueries("retailers-listing");
      ToastSuccess(
        `Retailer MIN ${link_status ? "unlinking" : "linking"} is in progress.`
      );
    },
    onError: (err) =>
      errorCallback(
        err,
        `Failed to ${link_status ? "unlinked" : "linked"} Retailer MIN.`
      ),
  });

  const callBack = () => link({ dealer_sim_id, retailer_id });
  const handleOnUnlink = () => {
    const onConfirm = () => setModal("confirm-password-setup", { callBack });
    const onReject = () => {};
    const message = `This will ${
      link_status ? "unlink" : "link"
    } Retailer MIN ${link_status ? "from" : "to"} respective parent account.`;
    const yesLabel = "Yes";
    const noLabel = "Cancel";
    const header = `${link_status ? "Unlink" : "Link"} Retailer MIN ${
      link_status ? "from" : "to"
    } Parent`;
    const newButtons = true;
    const center = false;
    const sub_text = "Are you sure you want to proceed?";

    ConfirmAlert(message, onConfirm, onReject, {
      yesLabel,
      noLabel,
      header,
      newButtons,
      center,
      sub_text,
    });
  };

  return (
    <Panel
      title="Retailer Details"
      renderActions={() => {
        if (isKA)
          return (
            <div className="btn-group">
              <Button
                label={`${link_status ? "UNLINK" : "LINK"} MIN`}
                onClick={handleOnUnlink}
                labelClass="w-7"
                disabled={isInProgress}
              />
            </div>
          );
        return null;
      }}
    >
      <PanelRow>
        <DataField
          label="Retailer MIN"
          value={<div className="ellipsis-wrap">{retailer?.mobile}</div>}
          className="col-md-3 mb-2 mb-md-0"
        />
        <DataField
          label="Outlet Type"
          value={<div className="ellipsis-wrap">{retailer?.retailer_type}</div>}
          className="col-md-3 mb-2 mb-md-0 ellipsis-wrap"
        />
        <DataField
          label="Email Address"
          value={<div className="ellipsis-wrap">{retailer?.email}</div>}
          className="col-md-3 mb-2 mb-md-0 ellipsis-wrap"
        />
        <DataField
          label="Link Status"
          value={
            <div className="ellipsis-wrap">{renderStatus[link_status]}</div>
          }
          className="col-md-3 mb-2 mb-md-0 ellipsis-wrap"
        />
      </PanelRow>
    </Panel>
  );
};

export default DetailsPanel;
