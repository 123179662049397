import { ModalContext } from "App";
import _ from "lodash";
import moment from "moment";
import DataField from "components/DataField";
import * as Icon from "components/Icons";
import Panel from "components/Panel";
import PanelButton from "components/PanelButton";
import PanelRow from "components/PanelRow";
import React, { Fragment, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { renderStatus } from "modules/ui/hooks";
import { getClusters } from "../actions";

const AddIcon = () => <Icon.Add fill="#fff" />;

const ClustersDetailsPanel = () => {
  const urlParams = useParams();
  const setModal = useContext(ModalContext);

  const clusterId = _.get(urlParams, "clusterId");
  const apiCluster = useQuery({
    queryKey: ["cluster", clusterId],
    queryFn: getClusters,
    retry: false,
    keepPreviousData: true,
  });
  const cluster = _.get(apiCluster, "data.data");

  const handleOnUpdate = () =>
    setModal("cluster-setup", { modalTitle: "Update Cluster" });

  return (
    <Fragment>
      <Panel
        title="Cluster Details"
        renderActions={() => (
          <div className="btn-group">
            <PanelButton
              iconLeft={AddIcon}
              label="Update"
              onClick={handleOnUpdate}
            />
          </div>
        )}
      >
        <PanelRow>
          <DataField
            label="Description"
            value={_.get(cluster, "attributes.description") || ""}
            className="col-md mb-2 mb-md-0 order-1"
          />

          <DataField
            label="Date Created"
            value={moment(_.get(cluster, "attributes.created_at")).format("LL")}
            className="col-md mb-2 mb-md-0 order-1"
          />
          <DataField
            label="Cluster Status"
            value={renderStatus[+_.get(cluster, "attributes.is_active")]}
            className="col-md mb-2 mb-md-0 order-1"
          />
        </PanelRow>
      </Panel>
    </Fragment>
  );
};

export default ClustersDetailsPanel;
