import React from 'react';
import Layout from '../Layout';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <h4 className="font-weight-bold text-center m-0 mb-4">Privacy Policy</h4>
    </Layout>
  );
};

export default PrivacyPolicy;
