import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { checkPermissions, removeEmpty, ssv } from "helper";
import FormInputDate from "modules/common/input/FormInputDate";
import FormInputSelect from "modules/common/input/FormInputSelect";
import { customStyles } from "components/styles/custom";
import GenericTable from "modules/common/components/GenericTable";
import Pagination from "modules/common/components/Pagination";
import LineChart from "../components/LineCharts";
import * as actions from "../actions";
import * as c from "../constant";

function BentaKita({
  getBentaKita,
  pager,
  list,
  isLoading,
  graph,
  downloadBentaKita,
  total,
}) {
  const [filter, setFilter] = React.useState(c.BK_INIT);
  const [collapse, setCollapse] = useState(false);
  const filter_by = filter?.filter_by?.value;

  const payload = useMemo(() => {
    const { date, start_date, end_date } = filter;
    return removeEmpty({
      date: moment(collapse ? start_date : date).format("YYYY-MM-DD"),
      end_date: collapse ? moment(end_date).format("YYYY-MM-DD") : "",
    });
  }, [filter, collapse]);

  const handleOnToggle = (value) => (e) => {
    e.preventDefault();
    setCollapse(value);
  };

  const handleChangePage = () => {
    const newFilter = { ...filter, page: pager.current_page + 1 };
    setFilter(newFilter);
    getBentaKita(newFilter);
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    getBentaKita({ filter_by: filter.filter_by.value, ...payload });
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setFilter(c.BK_INIT);
    getBentaKita();
  };

  const handleDownload = (e) => {
    e.preventDefault();
    const filename = "Benta Kita.csv";
    downloadBentaKita(filename, {
      filter_by: filter.filter_by.value,
      ...payload,
    });
  };

  useEffect(() => {
    getBentaKita();
  }, [getBentaKita]);

  useEffect(() => {
    const format = {
      daily: "YYYY-MM-DD",
      monthly: "YYYY-MM",
      yearly: "YYYY",
    };
    setFilter((state) => ({
      ...state,
      date: moment(filter?.date).format(format[filter_by]),
    }));
  }, [filter_by]);

  return (
    <div className="main-content-inner h-100">
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="col-12 h-100">
            <div className="container-fluid px-0">
              <div className="d-flex align-items-center">
                <div>
                  <button
                    type="button"
                    className={ssv(
                      "btn tw-mt-4",
                      collapse
                        ? "btn-success text-white"
                        : "btn-white text-success border border-success"
                    )}
                    onClick={handleOnToggle(!collapse)}
                  >
                    <i
                      className={ssv(
                        "fas fa-filter",
                        collapse ? "text-white" : "text-success"
                      )}
                    />
                  </button>
                </div>
                <div className="col-2 pr-0">
                  <FormInputSelect
                    options={c.BK_FORM}
                    styles={customStyles}
                    label="Filter by:"
                    value={filter.filter_by}
                    onChange={setFilter}
                    isSearchable={false}
                    name="filter_by"
                  />
                </div>
                <div className="col-2">
                  <FormInputDate
                    name="date"
                    value={filter.date}
                    onChange={setFilter}
                    showMonthYearPicker={filter_by === "monthly"}
                    showYearPicker={filter_by === "yearly"}
                    label="Select Date:"
                    disabled={collapse}
                  />
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-success btn-block btn-md mt-3"
                    onClick={handleSubmitFilter}
                  >
                    Search
                  </button>
                </div>
                {filter.filter_by && filter.date ? (
                  <div>
                    <button
                      type="button"
                      className="btn btn-danger btn-md ml-2 mt-3"
                      onClick={handleClearFilter}
                      style={{
                        boxShadow: "none",
                      }}
                    >
                      Clear
                    </button>
                  </div>
                ) : null}
              </div>
              <div
                style={{
                  transition: "height 1s",
                  height: `${!collapse ? "0" : "6rem"}`,
                  overflow: collapse ? "unset" : "hidden",
                  visibility: "0",
                }}
                className="tw-flex tw-flex-wrap tw-mt-3"
              >
                <div className="min-filter col-2">
                  <FormInputDate
                    name="start_date"
                    value={filter.start_date}
                    onChange={setFilter}
                    label="Date From:"
                  />
                </div>
                <div className="min-filter col-2">
                  <FormInputDate
                    name="end_date"
                    value={filter.end_date}
                    onChange={setFilter}
                    label="Date To:"
                    minDate={c.addDay(filter.start_date)}
                  />
                </div>
              </div>
              <div className="row d-flex align-items-center justify-content-between mb-3">
                <div className="col-xl-2 col-lg-6">
                  <h3 className="font-weight-bold d-inline-block m-0">
                    Benta at Kita
                  </h3>
                </div>
                <div className="col-xl-2"></div>
                {checkPermissions(["can-create-export-sales-profit"]) && (
                  <div className="col-xl-2 col-lg-4 ml-auto">
                    <button
                      type="button"
                      className="btn btn-md btn-primary px-4 text-uppercase btn-block"
                      onClick={handleDownload}
                    >
                      <i className="fas fa-download mr-2" /> Download
                    </button>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-xl-5 col-lg-12 order-lg-1 order-xl-0">
                  <GenericTable
                    isLoading={isLoading}
                    format={[
                      {
                        key: (item) => (
                          <span>
                            {_.get(item, "attributes.period") || "--"}
                          </span>
                        ),
                        label: c.DATE_LABEL[filter_by],
                        width: "auto",
                      },
                      {
                        key: (item) => (
                          <span>{_.get(item, "attributes.sales") || "--"}</span>
                        ),
                        label: "Benta",
                        width: "auto",
                      },
                      {
                        key: (item) => (
                          <span>
                            {_.get(item, "attributes.profit") || "--"}
                          </span>
                        ),
                        label: "Kita",
                        width: "auto",
                        className: "text-right",
                      },
                    ]}
                    data={list}
                    height={c.finalTable}
                  />
                  <Pagination onChange={handleChangePage} data={pager} />
                  <div className="bg-primary text-white">
                    <div className="d-flex p-2">
                      <div className="font-weight-bold">Total</div>
                      <div className="font-weight-bold ml-auto">
                        ₱ {total.total_sales}
                      </div>
                      <div className="font-weight-bold ml-auto">
                        ₱ {total.total_profit}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-12 order-lg-0 order-xl-1">
                  <div className="chart-content">
                    <LineChart data={graph} height={c.finalChart} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BentaKita.propTypes = {
  getBentaKita: PropTypes.func.isRequired,
  downloadBentaKita: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pager: PropTypes.instanceOf(Object).isRequired,
  graph: PropTypes.instanceOf(Object).isRequired,
  total: PropTypes.instanceOf(Object).isRequired,
  list: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api }) => ({
  pager: _.get(api, `${c.GET_BENTA_KITA}.pager`) || {},
  list: _.get(api, `${c.GET_BENTA_KITA}.list`) || [],
  total: _.get(api, `${c.GET_BENTA_KITA}.res`) || {},
  isLoading: api.loading.indexOf(c.GET_BENTA_KITA) > -1,
  graph: _.get(api, `${c.GET_BENTA_KITA}/graph.item`) || {},
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(BentaKita);
