import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Select from "react-select";
import { getAgent } from "../actions";

const RetailerSelectField = (props) => {
  const { onChange, ...rest } = props;
  const urlParams = useParams();
  const agentId = _.get(urlParams, "agentId");
  const [inputValue, setInputValue] = useState("");

  const apiGetParams = {
    role_slug: "sub-dealer-agent",
    q: inputValue,
    has_dealer_sim: true,
    per_page: 999,
  };
  const agents = useQuery({
    queryKey: ["dealer-users", "", apiGetParams],
    queryFn: getAgent,
    retry: false,
    keepPreviousData: true,
  });

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleChange = (value) => {
    if (!_.isFunction(onChange)) return;
    onChange(_.get(value, "data"));
  };

  const agentsMatch = _.filter(
    _.get(agents, "data.data"),
    (a) => _.get(a, "id") !== agentId
  );

  const agentOptions = _.map(agentsMatch, (opt) => ({
    label: _.get(opt, "attributes.full_name"),
    value: _.get(opt, "id"),
    data: opt,
  }));

  return (
    <Select
      options={agentOptions}
      onChange={handleChange}
      onInputChange={handleInputChange}
      isLoading={false}
      noOptionsMessage={() => "No sub-dealer agents available"}
      {...rest}
    />
  );
};

RetailerSelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default RetailerSelectField;
