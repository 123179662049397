import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ControlPanel from './ControlPanel';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFReader = ({ src, onClose }) => {
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  function onDocumentLoadSuccess(item) {
    const pages = get(item, 'numPages') || get(item, 'pages');
    setNumPages(pages);
    setIsLoading(false);
  }

  const loader = () => (
    <span className="text-muted">
      <i className="spinner-border spinner-border-sm mr-1" />
      Loading...
    </span>
  );

  return (
    <>
      {!isLoading && (
        <ControlPanel
          scale={scale}
          setScale={setScale}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          file={src}
          onClose={onClose}
        />
      )}
      <div className="px-3 pb-4 min-pdf d-flex w-100 justify-content-center">
        <section id="pdf-section">
          <Document
            file={src}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={loader}
          >
            <Page pageNumber={pageNumber} scale={scale} loading={null} />
          </Document>
        </section>
      </div>
    </>
  );
};

PDFReader.propTypes = {
  src: PropTypes.string.isRequired,
  onClose: PropTypes.string.isRequired,
};

export default PDFReader;
