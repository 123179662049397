import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ModalContext } from 'App';
import withModal from 'modules/common/hoc/withModal';
import PropTypes from 'prop-types';
import * as actions from 'modules/auth/actions';

const IdleDialog = ({ sessionCountdown, setSessionCountdown }) => {
  const setModal = useContext(ModalContext);
  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(actions.logout());
    setModal({ isOpen: false });
  };

  const stayActive = () => setModal({ isOpen: false });

  useEffect(() => {
    const logOutCountdown = setInterval(() => {
      setSessionCountdown(sessionCountdown - 1);
    }, 1000);

    if (sessionCountdown === 0) {
      clearInterval(logOutCountdown);
      handleLogOut();
    }

    return () => {
      clearInterval(logOutCountdown);
    };
  }, [sessionCountdown]);

  const outputCountdown = useMemo(() => {
    const minutes = Math.floor(sessionCountdown / 60);
    let seconds = sessionCountdown % 60;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${minutes}: ${seconds}`;
  }, [sessionCountdown]);

  useEffect(() => {
    const modalContent = document.querySelector('.session-modal').parentElement
      .parentElement;
    const buttonClose = modalContent.querySelector('.close');
    if (buttonClose) buttonClose.className = 'd-none';
  }, []);

  return (
    <div className="session-modal px-3 pt-3">
      <p className="text-center">
        <strong>
          You have been idle for an extended period and will be logged off
          atomatically if further inactivity is detected.
        </strong>
      </p>
      <p className="text-center my-4">
        Automatically logging off in ({outputCountdown})
      </p>
      <div className="d-flex justify-content-end border-top pt-3">
        <button
          onClick={stayActive}
          type="button"
          className="btn btn-primary mr-2"
        >
          Keep me signed in
        </button>
        <button
          onClick={handleLogOut}
          type="button"
          className="btn btn-danger "
        >
          Log off now
        </button>
      </div>
    </div>
  );
};

IdleDialog.propTypes = {
  sessionCountdown: PropTypes.number.isRequired,
  setSessionCountdown: PropTypes.func.isRequired,
};

const modalKey = 'session-expired';
const modalConfig = {
  title: 'Inactivity Detected',
};

export default withModal(modalKey, modalConfig)(IdleDialog);
