import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import {
  withRouter,
  NavLink,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import { checkPermissions } from "helper";
import PropTypes from "prop-types";
import { useLoading } from "modules/ui/hooks";
import * as actions from "../action";
import * as c from "../constant";
import BaAndFlUsers from "./BaAndFlUsers";
import CorpAdminUser from "./CorpAdminUser";

function UserContainer({ role_slug, getTabRoles, roleList }) {
  const match = useRouteMatch();
  const isLoading = useLoading(c.GET_ROLES);

  useEffect(() => {
    const payload = {
      role_guard_name: "",
    };

    if (role_slug.includes("smmt"))
      payload.role_guard_name = "smmt-dealer-user";
    else if (role_slug === "super-user-b2b" || role_slug.includes("-b2b"))
      payload.role_guard_name = "b2b-dealer-user";
    else if (role_slug === "super-user" || role_slug.includes("rd"))
      payload.role_guard_name = "rd-dealer-user";
    else if (role_slug === "super-user-ka" || role_slug.includes("-ka"))
      payload.role_guard_name = "ka-dealer-user";
    else if (role_slug.includes("-ska"))
      payload.role_guard_name = "ska-dealer-user";
    else if (role_slug === "super-user-non-migrated")
      payload.role_guard_name = "non-migrated-dealer-user";
    else payload.role_guard_name = "dealer-user";

    getTabRoles(payload);
  }, [getTabRoles, role_slug]);

  const dealer_user_tab = useMemo(() => {
    const hasCA =
      roleList.filter((role) =>
        role?.attributes?.slug.includes("corporate-admin")
      ).length > 0;
    const hasSDA =
      roleList.filter((role) => role?.attributes?.slug.includes("sub-dealer"))
        .length > 0;
    return `${hasCA ? "Corporate Admins" : ""}${
      hasCA && hasSDA ? " and " : ""
    }${hasSDA ? "Sub-Dealer Agents" : ""}`;
  }, [roleList]);

  const renderDealerUserNav = useMemo(() => {
    if (_.isEmpty(roleList)) return null;
    return (
      <NavLink
        to={`${match.path}/dealer-users`}
        className="nav-link"
        activeClassName="active"
      >
        {dealer_user_tab}
      </NavLink>
    );
  }, [roleList]);

  return (
    <div className="container-fluid">
      <ul className="nav nav-tabs mt-4">
        {checkPermissions(["can-view-retailer-user"]) && (
          <li className="nav-item">
            <NavLink
              to={`${match.path}/retailer-users`}
              className="nav-link"
              activeClassName="active"
            >
              {role_slug.includes("branch")
                ? "Frontliner"
                : "Branch Admin and Frontliner users"}
            </NavLink>
          </li>
        )}
        {checkPermissions(["can-view-dealer-user"]) && (
          <li className="nav-item">
            {isLoading ? (
              <div className="nav-link nav-loading">
                <div className="spinner-border text-success" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              renderDealerUserNav
            )}
          </li>
        )}
      </ul>
      <div className="card">
        <div
          className="card-body p-0"
          style={{ borderTop: "6px solid #049051" }}
        >
          <Switch>
            {checkPermissions(["can-view-retailer-user"]) && (
              <Route path={`${match.path}/retailer-users`}>
                <BaAndFlUsers />
              </Route>
            )}
            {checkPermissions(["can-view-dealer-user"]) && (
              <Route path={`${match.path}/dealer-users`}>
                <CorpAdminUser tab_name={dealer_user_tab} />
              </Route>
            )}
          </Switch>
        </div>
      </div>
      <Route
        path={`${match.path}`}
        render={() => {
          const canViewRetailer = checkPermissions(["can-view-retailer-user"]);
          if (canViewRetailer)
            return <Redirect to={`${match.path}/retailer-users`} />;
          if (!canViewRetailer)
            return <Redirect to={`${match.path}/dealer-users`} />;
          return null;
        }}
      />
    </div>
  );
}

UserContainer.propTypes = {
  role_slug: PropTypes.string.isRequired,
  getTabRoles: PropTypes.func.isRequired,
  roleList: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api }) => ({
  role_slug: _.get(api, "AUTH/user_profile.item.roles.0") || "",
  roleList: _.get(api, `${c.GET_TAB_ROLES}.list`) || [],
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(UserContainer);
