import React, { useState, useMemo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { ToastSuccess } from 'modules/common/components/Toast';
import ReactInputMask from 'react-input-mask';

import withModal from 'modules/common/hoc/withModal';
import { ModalContext } from 'App';
import { removeEmpty } from 'helper';
import placeholderPhoto from 'assets/images/upload-square-placeholder.png';
import ButtonAsync from 'components/Button';
import UploadFile from '../../partners/components/UploadFile';

import * as c from '../constants';
import * as actions from '../actions';

const UpdateUserDetails = () => {
  const setModal = useContext(ModalContext);
  const dispatch = useDispatch();
  const userProfile = useSelector(({ api }) =>
    _.get(api, 'AUTH/user_profile.item')
  );
  const previousData = useSelector(({ modal }) =>
    _.get(modal, 'modalData', '')
  );

  const [initialData, setInitialData] = useState(previousData);
  const [pending, setPending] = useState(false);
  const [isUploadModalHidden, setHidden] = useState(false);

  const channel_id = _.get(userProfile, 'channel_id');
  const dealer_id = _.get(previousData, 'dealer_id', '');
  const parent_id = _.get(userProfile, 'id');

  const isInputEmpty = useMemo(() => {
    const { first_name, last_name, email, mobile, profile_img } = initialData;
    return (
      !first_name && !last_name && !email && !mobile && _.isEmpty(profile_img)
    );
  }, [initialData]);

  const handleCloseModal = () => setModal({ isOpen: false });
  const openUploadModal = name => setHidden(!isUploadModalHidden);

  const handleChangeFields = e => {
    const { name, value } = e.target;
    setInitialData({
      ...initialData,
      [name]: value,
    });
  };

  const handleUploadImage = (imgInfo, name) => {
    setInitialData({
      ...initialData,
      [name]: imgInfo.attach_img,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setPending(true);

    const payload = {
      ...initialData,
      email: _.get(initialData, 'email').toLowerCase(),
      channel_id,
      parent_id,
      dealer_id: '',
    };

    const successCallback = () => {
      dispatch(actions.showUserDetails(dealer_id));
      handleCloseModal();
      ToastSuccess('Successfully user details.');
      setPending(false);
    };

    const errCallback = () => setPending(false);

    dispatch(
      actions.updateDealer(
        dealer_id,
        removeEmpty(payload),
        successCallback,
        errCallback
      )
    );
  };

  return (
    <div className="add-modal-container p-2">
      <div className="mb-2">
        <h6 className="h6 text-sm font-weight-bold m-0">User Access Details</h6>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-3 pt-2 pl-1">
            <div
              className="text-center p-2"
              style={{ background: 'rgba(50, 50, 50, .1)' }}
            >
              <figure
                className="img-container mx-auto"
                style={{ maxWidth: '50%' }}
              >
                <img
                  src={_.get(initialData, 'profile_img') || placeholderPhoto}
                  alt="profile"
                  className="w-100"
                />
              </figure>
              <p className="m-0 my-2" style={{ fontSize: '.65rem' }}>
                Upload Dealer Display Photo
              </p>
              <button
                className="btn btn-success text-lowercase font-weight-normal px-4"
                type="button"
                style={{ fontSize: '.7rem' }}
                onClick={openUploadModal}
              >
                <span className="text-uppercase">U</span>pload
              </button>
            </div>
          </div>
          <div className="col row mb-5">
            <div className="col-6 mb-2">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                First Name *
              </label>
              <input
                type="text"
                name="first_name"
                className="form-control form-custom form-control_bottom px-3"
                placeholder="Enter First Name"
                autoFocus
                required
                style={c.inputStyles}
                value={
                  _.get(initialData, 'first_name').replace(/  +/g, ' ') || ''
                }
                onChange={handleChangeFields}
                maxLength="50"
              />
            </div>

            <div className="col-6 ">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                Last Name *
              </label>
              <input
                type="text"
                name="last_name"
                className="form-control form-custom form-control_bottom px-3"
                placeholder="Enter Last Name"
                style={c.inputStyles}
                value={
                  _.get(initialData, 'last_name').replace(/  +/g, ' ') || ''
                }
                onChange={handleChangeFields}
                required
                maxLength="50"
              />
            </div>

            <div className="col-6 ">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                Email Address *
              </label>
              <input
                type="email"
                name="email"
                className="form-control form-custom form-control_bottom px-3 "
                placeholder="Enter Email Address"
                style={c.inputStyles}
                value={_.get(initialData, 'email', '').replace(/\s/g, '')}
                onChange={handleChangeFields}
                required
              />
            </div>

            <div className="col-6 ">
              <label
                className="form-label font-weight-bold text-dark"
                style={c.labelStyles}
              >
                Mobile Number *
              </label>

              <ReactInputMask
                required
                type="text"
                mask="+639999999999"
                maskChar=""
                className="form-control form-custom form-control_bottom px-3"
                name="mobile"
                value={_.get(initialData, 'mobile', '')}
                onChange={handleChangeFields}
                placeholder="Enter..."
                style={c.inputStyles}
              />
            </div>
          </div>
        </div>
        <div className="button-container d-flex justify-content-end">
          <ButtonAsync
            className="btn btn-primary position-relative py-2 px-4 mr-2"
            type="submit"
            loading={pending}
            disabled={isInputEmpty}
          >
            Submit For Approval
          </ButtonAsync>

          <button
            type="button"
            className="btn bg-gray-300 text-white py-2 px-4"
            onClick={handleCloseModal}
            disabled={pending}
          >
            Cancel
          </button>
        </div>
      </form>
      {isUploadModalHidden && (
        <UploadFile
          isUploadModalHidden={isUploadModalHidden}
          setHidden={setHidden}
          handleUploadImg={handleUploadImage}
          dealerAvatarInfo="profile_img"
        />
      )}
    </div>
  );
};

const modalKey = 'update-user-details';
const modalConfig = {
  title: 'Update Dealer Account',
  size: 'modal-lg',
};

export default withModal(modalKey, modalConfig)(UpdateUserDetails);
