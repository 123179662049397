import React from 'react';
import PropTypes from 'prop-types';

const ControlPanel = ({
  file,
  pageNumber,
  numPages,
  setPageNumber,
  scale,
  setScale,
  onClose,
}) => {
  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const firstPageClass = isFirstPage ? 'disabled' : 'clickable';
  const lastPageClass = isLastPage ? 'disabled' : 'clickable';

  const goToFirstPage = e => {
    e.preventDefault();
    if (!isFirstPage) setPageNumber(1);
  };
  const goToPreviousPage = e => {
    e.preventDefault();
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };
  const goToNextPage = e => {
    e.preventDefault();
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };
  const goToLastPage = e => {
    e.preventDefault();
    if (!isLastPage) setPageNumber(numPages);
  };

  const close = e => {
    e.preventDefault();
    onClose();
  };

  const isMinZoom = scale < 0.9;
  const isMaxZoom = scale >= 5.0;

  const zoomOutClass = isMinZoom ? 'disabled' : 'clickable';
  const zoomInClass = isMaxZoom ? 'disabled' : 'clickable';

  const zoomReset = e => {
    e.preventDefault();
    setScale(1);
  };

  const zoomOut = e => {
    e.preventDefault();
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = e => {
    e.preventDefault();
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <div className="control-panel p-3 d-flex align-items-baseline justify-content-between">
      <div className="d-flex justify-content-between align-items-baseline">
        <a href="/" onClick={zoomReset}>
          <i className="fas fa-redo mr-3" />
        </a>
        <a href="/" onClick={zoomOut}>
          <i className={`fas fa-search-minus mr-3 ${zoomOutClass}`} />
        </a>
        <a href="/" onClick={zoomIn}>
          <i className={`fas fa-search-plus mr-3 ${zoomInClass}`} />
        </a>
        <span>{(scale * 100).toFixed()}%</span>
      </div>
      <div className="d-flex justify-content-between align-items-baseline pr-5">
        <a href="/" onClick={goToFirstPage}>
          <i className={`fas fa-fast-backward mr-3 ${firstPageClass}`} />
        </a>
        <a href="/" onClick={goToPreviousPage}>
          <i className={`fas fa-backward mr-3 ${firstPageClass}`} />
        </a>
        <span>
          Page {pageNumber} of {numPages}
        </span>
        <a href="/" onClick={goToNextPage}>
          <i className={`fas fa-forward mx-3 ${lastPageClass}`} />
        </a>
        <a href="/" onClick={goToLastPage}>
          <i className={`fas fa-fast-forward mr-3 ${lastPageClass}`} />
        </a>
      </div>
      <div className="d-flex justify-content-between align-items-baseline">
        <a
          href={file}
          download
          title="download"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-file-download clickable mr-3" />
        </a>
        <a href="/" onClick={close}>
          <i className="fas fa-times" style={{ fontSize: 16 }} />
        </a>
      </div>
    </div>
  );
};

ControlPanel.propTypes = {
  file: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  scale: PropTypes.number.isRequired,
  setScale: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ControlPanel;
