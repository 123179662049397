import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import ReactInputMask from 'react-input-mask';
import Select from 'react-select';

import withModal from 'modules/common/hoc/withModal';

import FormInputBorderBottom from 'modules/common/components/input/FormInputBorderBottom';
import Calendar from '../../components/Calendar';

import * as c from '../constants';
import * as actions from '../actions';

const Details = () => {
  const dispatch = useDispatch();
  const prevData = useSelector(
    ({ modal }) => _.get(modal, 'modalData.item', []) || []
  );
  const retailerAddress = useSelector(
    ({ api }) => _.get(api, `${c.SHOW_RETAILER_ADDRESS}.item`, {}) || {}
  );

  const [initialData, setInitialData] = useState(
    c.addUpdateRetailerDefaultValue
  );

  const retailerId = _.get(prevData, 'id');

  useEffect(() => {
    if (retailerId) dispatch(actions.showRetailerAddress(retailerId));
    return () => {
      dispatch(actions.resetRetailerAdd());
    };
  }, [retailerId]);

  useEffect(() => {
    const previousData = {
      first_name: _.get(prevData, 'attributes.first_name', '') || '',
      middle_name: _.get(prevData, 'attributes.middle_name', '') || '',
      last_name: _.get(prevData, 'attributes.last_name', '') || '',
      birth_date: _.get(prevData, 'attributes.birth_date', '') || '',
      email: _.get(prevData, 'attributes.email', '') || '',
      mobile_identification_number:
        _.get(prevData, 'attributes.mobile_identification_number', '') || '',
      retailer_type:
        {
          value: _.get(prevData, 'attributes.retailer_type', '') || '',
          label: _.get(prevData, 'attributes.retailer_type', '') || '',
        } || {},
      line: _.get(retailerAddress, 'attributes.address.line', ''),
      province: {
        value: _.get(retailerAddress, 'attributes.address.province', '') || '',
        label: _.get(retailerAddress, 'attributes.address.province', '') || '',
      },
      city_municipality: {
        value:
          _.get(retailerAddress, 'attributes.address.city_municipality', '') ||
          '',
        label:
          _.get(retailerAddress, 'attributes.address.city_municipality', '') ||
          '',
      },
      postal_code:
        _.get(retailerAddress, 'attributes.address.postal_code', '') || '',
      barangay: {
        value: _.get(retailerAddress, 'attributes.address.barangay', '') || '',
        label: _.get(retailerAddress, 'attributes.address.barangay', '') || '',
      },
      address_id: _.get(retailerAddress, 'attributes.address.id', '') || '',
    };

    if (previousData && retailerAddress) setInitialData(previousData);
  }, [prevData, retailerAddress]);

  return (
    <div className="add-modal-container p-3">
      <form>
        <div className="row mb-2">
          <div className="col">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Retailer Mobile No.
            </label>

            <ReactInputMask
              type="text"
              required
              mask="+639999999999"
              maskChar=""
              className="form-control form-custom form-control_bottom px-3"
              name="mobile_identification_number"
              value={_.get(initialData, 'mobile_identification_number', '')}
              placeholder="Enter..."
              style={c.inputStyles}
              readOnly
            />
          </div>

          <div className="col">
            <label
              className="font-weight-bold"
              style={c.labelStyles}
              htmlFor="retailer_type"
            >
              Retailer Type:
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              name="retailer_type"
              placeholder="Set here..."
              id="retailer_type"
              required
              styles={c.selectOptionStyles}
              value={_.get(initialData, 'retailer_type', '')}
              isDisabled
            />
          </div>

          <div className="col">
            <label
              className="font-weight-bold"
              style={{ ...c.labelStyles, marginBottom: '.3rem' }}
              htmlFor="account_type_id"
            >
              Birthdate:
            </label>
            <Calendar
              value={
                _.get(initialData, 'birth_date')
                  ? moment(_.get(initialData, 'birth_date')).format(
                      'YYYY-MM-DD'
                    )
                  : ''
              }
              onChange={() => {}}
              disabled
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col">
            <FormInputBorderBottom
              label="First Name"
              name="first_name"
              value={_.get(initialData, 'first_name', '')}
              readOnly
            />
          </div>

          <div className="col">
            <FormInputBorderBottom
              label="Middle Name"
              name="middle_name"
              value={_.get(initialData, 'middle_name', '')}
              readOnly
            />
          </div>

          <div className="col">
            <FormInputBorderBottom
              label="Last Name"
              name="last_name"
              value={_.get(initialData, 'last_name', '')}
              readOnly
            />
          </div>
        </div>

        <div className="row mb-4 pb-2">
          <div className="col-4">
            <FormInputBorderBottom
              label="Email"
              name="email"
              value={_.get(initialData, 'email', '')}
              readOnly
            />
          </div>
        </div>

        <div className="mb-2">
          <h6 className="h6 m-0 text-xs font-weight-bold">Selling Location</h6>
        </div>

        <div className="row mb-2">
          <div className="col">
            <FormInputBorderBottom
              label="House No. / Bdlg / Street No."
              name="line"
              value={_.get(initialData, 'line', '')}
              readOnly
            />
          </div>

          <div className="col-4 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Province
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="province"
              required
              isClearable
              styles={c.selectOptionStyles}
              value={
                _.get(initialData, 'province.value')
                  ? _.get(initialData, 'province')
                  : ''
              }
              isDisabled
            />
          </div>

          <div className="col-4 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              City / Municipality
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="city_municipality"
              required
              isClearable
              styles={c.selectOptionStyles}
              value={
                _.get(initialData, 'city_municipality.value')
                  ? _.get(initialData, 'city_municipality', '')
                  : ''
              }
              isDisabled
            />
          </div>
        </div>

        <div className="row mb-4 pb-2">
          <div className="col-4 ">
            <label
              className="form-label font-weight-bold text-dark"
              style={c.labelStyles}
            >
              Barangay
            </label>
            <Select
              className="text-xs partners__select-options border-0 bottom-0"
              placeholder="Set here..."
              name="barangay"
              required
              isClearable
              styles={c.selectOptionStyles}
              value={
                _.get(initialData, 'barangay.value')
                  ? _.get(initialData, 'barangay')
                  : ''
              }
              isDisabled
            />
          </div>

          <div className="col-4">
            <FormInputBorderBottom
              label="ZIP Code"
              type="number"
              name="postal_code"
              value={String(_.get(initialData, 'postal_code', ''))}
              readOnly
            />
          </div>
        </div>
      </form>
    </div>
  );
};

const modalKey = 'details-retailer';
const modalConfig = {
  title: 'View Retailer Details',
  size: 'modal-lg',
};

export default withModal(modalKey, modalConfig)(Details);
