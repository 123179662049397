import React, { useContext, useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { removeEmpty } from "helper";
import withModal from "modules/common/hoc/withModal";
import { ModalContext } from "App";
import { ToastSuccess } from "modules/common/components/Toast";
import ButtonAsync from "components/Button";
import { useLoading } from "modules/ui/hooks";
import * as retailerSimsAction from "modules/ManageAccounts/action";
import { useProfile } from "modules/auth/hooks";
import AccountInfoForm from "../forms/AccountInfoForm";
import TemporaryAccountForm from "../forms/TemporaryAccountForm";

import * as actions from "../action";
import * as c from "../constant";

const temporaryFormFields = {
  email: "",
  mobile: "",
};

const form = {
  first_name: "",
  last_name: "",
  username: "",
  role_id: "",
};

function UpdateFillupCorpInfo({
  updateCorpAdmin,
  getCorpUsers,
  userInfoData,
  getRoles,
  roleList,
  terporaryData,
}) {
  const setModal = useContext(ModalContext);
  const [formData, setformData] = useState(form);
  const [temporaryForm, setTemporaryForm] = useState(temporaryFormFields);

  const profile = useProfile();
  const role = _.head(profile?.roles);

  const modalData = useSelector(
    ({ modal }) => _.get(modal, "modalData", {}) || {}
  );

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleChange = (key) => ({ target }) => {
    setformData({
      ...formData,
      [key]: target.value,
    });
  };

  const checkInput = useMemo(() => {
    const { first_name, last_name, username, role_id } = formData;
    const { email, mobile } = temporaryForm;
    return (
      _.isEmpty(first_name) ||
      _.isEmpty(last_name) ||
      _.isEmpty(username) ||
      _.isEmpty(role_id) ||
      _.isEmpty(email) ||
      _.size(mobile) !== 13
    );
  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      ...temporaryForm,
      first_name: _.get(formData, "first_name"),
      last_name: _.get(formData, "last_name"),
      username: _.get(formData, "username"),
      role_id: _.get(formData, "role_id"),
      was_updated: true,
    };
    updateCorpAdmin(formData.id, payload, () => {
      ToastSuccess("Successfully updated user account.");
      setModal({ isOpen: false });
      getCorpUsers(removeEmpty(modalData.filter));
    });
  };

  const handleChangeInput = (value) => {
    setTemporaryForm({
      ...temporaryForm,
      ...value,
    });
  };

  useEffect(() => {
    const payload = {
      role_guard_name: "",
    };
    if (role.includes("smmt")) payload.role_guard_name = "smmt-dealer-user";
    else if (role === "super-user-b2b" || role.includes("-b2b"))
      payload.role_guard_name = "b2b-dealer-user";
    else if (role === "super-user" || role.includes("rd"))
      payload.role_guard_name = "rd-dealer-user";
    else if (role === "super-user-ka" || role.includes("-ka"))
      payload.role_guard_name = "ka-dealer-user";
    else if (role.includes("-ska")) payload.role_guard_name = "ska-dealer-user";
    else if (role === "super-user-non-migrated")
      payload.role_guard_name = "non-migrated-dealer-user";
    else if (role === "branch-admin-non-migrated") {
      payload.user_guard_name = "non-migrated-retailer-user";
      payload.role_guard_name = "non-migrated-retailer-user";
    } else payload.role_guard_name = "dealer-user";
    setformData(userInfoData);
    setTemporaryForm(terporaryData);
    getRoles(payload);
  }, [setformData, setTemporaryForm, getRoles, userInfoData, terporaryData]);

  return (
    <form onSubmit={handleSubmit} className="container-fluid">
      <AccountInfoForm
        onChange={handleChange}
        formData={formData}
        roleList={roleList}
        type="update"
      />
      <TemporaryAccountForm
        onChange={handleChangeInput}
        temporaryForm={temporaryForm}
        type="update"
      />
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold mr-2"
            >
              Cancel
            </button>
            <ButtonAsync
              type="submit"
              loading={useLoading(c.UPDATE_CORP_ADMIN)}
              disabled={checkInput}
            >
              Save
            </ButtonAsync>
          </div>
        </div>
      </div>
    </form>
  );
}

UpdateFillupCorpInfo.propTypes = {
  updateCorpAdmin: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  getCorpUsers: PropTypes.func.isRequired,
  userInfoData: PropTypes.instanceOf(Object).isRequired,
  terporaryData: PropTypes.instanceOf(Object).isRequired,
  roleList: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api, modal }) => ({
  userInfoData: _.get(modal, "modalData.userInfoData") || {},
  terporaryData: _.get(modal, "modalData.terporaryData") || {},
  branchId: _.get(modal, "modalData.branchId") || "",
  roleList: _.get(api, `${c.GET_ROLES}.list`) || [],
});

const enhance = _.flowRight([
  withModal("update-fill-up-corp-info", {
    title: "Update Account",
    size: "modal-lg",
  }),
  withRouter,
  connect(mapStateToProps, {
    ...retailerSimsAction,
    ...actions,
  }),
]);

export default enhance(UpdateFillupCorpInfo);
