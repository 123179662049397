import React, { useMemo } from "react";
import {
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
  withRouter,
} from "react-router-dom";
import GenericList from "modules/common/components/GenericList";
import Pagination from "modules/common/components/Pagination";
import { CollapseFilter as Filter } from "components/Filter";
import _ from "lodash";
import { ModalContext } from "App";
import { USER_PROFILE } from "modules/auth/constants";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { checkPermissions, removeEmpty, removeNull } from "helper";
import ButtonAsync from "components/Button";
import { useLoading } from "modules/ui/hooks";
import BranchDetails from "../components/BranchDetails";
import CreateBranchForm from "../modalForms/CreateBranchForm";
import UpdateBranchForm from "../modalForms/UpdateBranchForm";
import * as actions from "../action";
import * as c from "../constant";
import RemoveBranchForm from "../modalForms/RemoveBranchForm";
import BranchStatusModal from "../modalForms/BranchStatusModal";

function Branches({
  getBranch,
  branchList,
  pager,
  showBranch,
  branchDetails,
  getAttachUsers,
  scheduleData,
  roles,
  getAttachUsersBA,
  downloadBranchHistory,
  simsOption,
}) {
  const [filterData, setFilterData] = React.useState(c.filter);
  const setModal = React.useContext(ModalContext);
  const match = useRouteMatch();
  const sub_match = useRouteMatch(`${match.path}/:id`);
  const branchId = _.get(sub_match, "params.id");
  const history = useHistory();
  const [isLoadingHistory, setIsLoadingHistory] = React.useState(false);
  const isBranchLoading = useLoading(c.GET_BRANCH);

  const profile = useSelector(({ api }) => _.get(api, `${USER_PROFILE}.item`));
  const isNonMigrated = profile?.roles[0]?.includes("non-migrated");

  const filter = useMemo(
    () =>
      removeNull({ ...filterData, is_blocked: filterData?.is_blocked?.value }),
    [filterData]
  );

  const handleChangePage = (page) => {
    setFilterData((state) => ({ ...state, page }));
    getBranch({ ...filter, page });
  };

  const handleSelectRow = (item) => {
    if (!_.get(roles, "0", "")?.includes("branch-admin")) {
      return history.push(`${match.url}/${_.get(item, "id")}/branch-admins`);
    }
    if (_.get(roles, "0", "")?.includes("branch-admin")) {
      return history.push(`${match.url}/${_.get(item, "id")}/frontliners`);
    }
    return null;
  };

  const handleCreateBranch = (e) => {
    e.preventDefault();
    setModal("create-branch", { options: simsOption });
  };

  const handleUpdateBranch = (item) => (e) => {
    e.preventDefault();
    setModal("update-branch", {
      data: {
        name: _.get(item, "attributes.name"),
        address: _.get(item, "attributes.address"),
        tin: _.get(item, "attributes.tin"),
        business_style: _.get(item, "attributes.business_style"),
        code: _.get(item, "attributes.code"),
        retailer_ids: (_.get(item, "included.sourceFunds") || []).map((x) => ({
          label: _.get(x, "attributes.mobile_identification_number"),
          value: x.id,
        })),
      },
      options: simsOption,
    });
  };

  const handleRemoveBranch = (item) => (e) => {
    e.preventDefault();
    setModal("remove-branch", {
      data: {
        name: _.get(item, "attributes.name"),
        address: _.get(item, "attributes.address"),
        tin: _.get(item, "attributes.tin"),
        business_style: _.get(item, "attributes.business_style"),
        code: _.get(item, "attributes.code"),
        retailer_ids: (_.get(item, "included.sourceFunds") || []).map((x) => ({
          label: _.get(x, "attributes.mobile_identification_number"),
          value: x.id,
        })),
      },
      options: simsOption,
    });
  };

  const handleBlock = (label, value, branchName, returnLabel) => (e) => {
    e.preventDefault();
    const payload = {
      is_blocked: value,
    };
    setModal("branch-update-status", {
      data: { label, value, branchName, payload, returnLabel, branchId },
    });
  };

  const handleDownloadBranchHistory = (id) => async (e) => {
    e.preventDefault();
    downloadBranchHistory(id, {}, setIsLoadingHistory);
  };

  React.useEffect(() => {
    const trimmedParams = removeEmpty({
      ...filter,
      q: _.trim(filterData?.q),
      page: 1,
    });
    getBranch(trimmedParams);
    if (!_.isEmpty(branchId)) {
      showBranch(branchId);

      getAttachUsers(branchId, {
        role_slug: isNonMigrated ? "frontliner-non-migrated" : "frontliner",
      });
      getAttachUsersBA(branchId, {
        role_slug: isNonMigrated ? "branch-admin-non-migrated" : "branch-admin",
      });
    }
  }, [
    branchId,
    getAttachUsers,
    getAttachUsersBA,
    getBranch,
    showBranch,
    filterData.q,
    filterData.is_blocked,
    filterData.business_style,
  ]);

  return (
    <div className="main-content-inner p-0 h-100">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-3 h-100 px-0">
            <div className="app-listing d-flex flex-column h-100">
              <div className="app-search shadow-sm pt-3 px-3 mt-3 mb-3">
                <Filter
                  form={c.form}
                  filterData={[filterData, setFilterData]}
                  loading={isBranchLoading}
                />
                {checkPermissions(["can-create-branch"]) && (
                  <div className="form-group">
                    <ButtonAsync
                      type="submit"
                      loading={isBranchLoading}
                      className="btn btn-primary btn-block py-2 text-uppercase position-relative"
                      onClick={handleCreateBranch}
                    >
                      Create Branch
                    </ButtonAsync>
                  </div>
                )}
              </div>
              <div className="app-list shadow-sm flex-grow-1 mb-2">
                <div className="box-fit-wrapper h-100">
                  <div className="box-fit-overflow">
                    <Route
                      path={`${match.path}/:dealer_id?`}
                      render={({ match: { params } }) => (
                        <GenericList
                          label=""
                          labelKey={(item) => (
                            <div className="d-flex align-items-center w-100 justify-content-between ">
                              <div className="details">
                                <div className="schedule-title">
                                  {_.truncate(_.get(item, "attributes.name"), {
                                    length: c.TRUNCATE_LIMIT,
                                  })}
                                </div>
                                <small className="schedule-item">
                                  {_.truncate(
                                    _.get(item, "attributes.address"),
                                    {
                                      length: c.TRUNCATE_LIMIT,
                                    }
                                  ) || ""}
                                </small>
                              </div>
                            </div>
                          )}
                          rowsCount={pager.total || false}
                          isLoading={isBranchLoading}
                          selectedKey="id"
                          data={branchList}
                          selected={params.dealer_id}
                          onSelectRow={handleSelectRow}
                          height="550px"
                        />
                      )}
                    />
                    <Pagination onChange={handleChangePage} data={pager} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-9 px-0" style={{ height: "170vh" }}>
            <div className="schedule-section h-100 p-4">
              <div className="calendar-view">
                <BranchDetails
                  branchDetails={branchDetails}
                  onUpdateBranch={handleUpdateBranch}
                  onBlock={handleBlock}
                  onRemove={handleRemoveBranch}
                  scheduleData={scheduleData}
                  roles={roles}
                  onDownloadBranchHistory={handleDownloadBranchHistory}
                  isLoadingHistory={isLoadingHistory}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {!_.get(roles, "0", "")?.includes("branch-admin") ? (
        <Route
          path={`${match.path}/:id?`}
          render={({ match: { params } }) =>
            !params.id && branchList.length > 0 ? (
              <Redirect
                to={`${match.url}/${_.get(branchList, "0.id")}/branch-admins`}
              />
            ) : null
          }
        />
      ) : (
        <Route
          path={`${match.path}/:id?`}
          render={({ match: { params } }) =>
            !params.id && branchList.length > 0 ? (
              <Redirect
                to={`${match.url}/${_.get(branchList, "0.id")}/frontliners`}
              />
            ) : null
          }
        />
      )}
      <CreateBranchForm />
      <UpdateBranchForm />
      <RemoveBranchForm />
      <BranchStatusModal />
    </div>
  );
}

Branches.propTypes = {
  getBranch: PropTypes.func.isRequired,
  showBranch: PropTypes.func.isRequired,
  getAttachUsers: PropTypes.func.isRequired,
  getAttachUsersBA: PropTypes.func.isRequired,
  downloadBranchHistory: PropTypes.func.isRequired,
  branchList: PropTypes.instanceOf(Array).isRequired,
  scheduleData: PropTypes.instanceOf(Array).isRequired,
  branchDetails: PropTypes.instanceOf(Object).isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  pager: PropTypes.instanceOf(Object).isRequired,
  simsOption: PropTypes.instanceOf(Array).isRequired,
};

const mapStateToProps = ({ api }) => ({
  scheduleData: _.get(api, `${c.GET_ATTACHED_USERS}/sheduleData.list`) || [],
  branchList: _.get(api, `${c.GET_BRANCH}.list`) || [],
  branchDetails: _.get(api, `${c.SHOW_BRANCH}.item`) || {},
  pager: _.get(api, `${c.GET_BRANCH}.res.meta`) || {},
  roles: _.get(api, "AUTH/user_profile.item.roles") || [],
  simsOption: _.get(api, `${c.GET_RETAILERS_SIMS}/options.list`) || [],
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(Branches);
