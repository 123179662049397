import React from 'react';
import { Redirect, Switch, Route, useRouteMatch } from 'react-router-dom';
import Pricing from 'modules/ProductManagement/containers/Pricing';

function PricingContainer() {
  const match = useRouteMatch();
  const returnRedirect = () => {
    return <Redirect to={`${match.path}/pricing`} />;
  };
  return (
    <>
      <Switch>
        <Route exact path={match.path} render={returnRedirect} />
        <Route path={`${match.path}/pricing`} component={Pricing} />
      </Switch>
    </>
  );
}

export default PricingContainer;
