import React, { useState } from "react";
import ReactInputMask from "react-input-mask";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { ModalContext } from "App";
import WithTimerButton from "components/WithTimerButton";
import ButtonAsync from "components/Button";
import { ToastSuccess } from "modules/common/components/Toast";
import { MESSAGES_SIM_ACTION as messages } from "modules/LoadWalletManagement/constant";
import withModal from "modules/common/hoc/withModal";
import { useDealer } from "app/load-wallet/context/DealerProvider";
import * as actions from "../actions";
import * as c from "../constant";

function ConfirmOtp() {
  const dispatch = useDispatch();
  const { simDealerId } = useDealer();
  const data = useSelector(({ modal }) => modal?.modalData);
  const [otpValue, setOtpValue] = useState("");
  const setModal = React.useContext(ModalContext);
  const [pending, setPending] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);

    const onError = () => {
      setOtpValue("");
      setPending(false);
    };

    const onVerifyFail = () => {
      setModal({ isOpen: false });
    };

    const onAddSuccess = () => {
      const submitType = data?.tempSubmitType || "default";
      ToastSuccess(messages[submitType]);
      setModal({ isOpen: false });
      setPending(false);
      dispatch(
        actions.retailerSimsGetRetailerSims({
          dealer_id: simDealerId,
          ...c.sortFilter,
        })
      );
    };

    const cleanedPayload = {
      ...data,
      mobile_identification_number: data?.mobile_identification_number,
      ..._.omit(data, ["tempSubmitType"]),
      otp: otpValue,
    };

    const onVerifySuccess = () => {
      const submitTypeValue = data?.tempSubmitType;
      if (submitTypeValue === "delete")
        return dispatch(
          actions.deleteRetailerSims(data?.id, onAddSuccess, onError)
        );
      if (submitTypeValue === "update" || submitTypeValue?.includes("block"))
        return dispatch(
          actions.retailerSimsUpdateRetailerSims(
            data?.id,
            cleanedPayload,
            onAddSuccess,
            onError
          )
        );
      return dispatch(
        actions.createRetailerSims(cleanedPayload, onAddSuccess, onError)
      );
    };

    const onConfirmSuccess = () => {
      dispatch(
        actions.validateSim(
          {
            mobile: data?.mobile_identification_number,
          },
          onVerifySuccess,
          onVerifyFail
        )
      );
    };

    const confirmPayload = {
      mobile: data?.mobile_identification_number,
      purpose: c.PURPOSE.portal,
      otp: otpValue,
    };

    dispatch(actions.confirmOtp(confirmPayload, onConfirmSuccess, onError));
  };

  const handleResend = (e) => {
    e.preventDefault();
    const payload = {
      mobile: data?.mobile_identification_number,
      purpose: c.PURPOSE.portal,
    };
    dispatch(actions.setOtp(payload));
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="prepend-form-right">
            <label className="form-label text-primary text-center">
              Enter the one-time-password we have sent to your mobile number
              +63********{data?.sms?.detail?.mobile_end}
            </label>
            <ReactInputMask
              autoFocus
              type="text"
              placeholder="6 Digit OTP"
              mask="999999"
              maskChar=""
              className="form-control form-custom form-control_bottom"
              name="otp"
              required
              onChange={(e) => setOtpValue(e.target.value)}
              value={otpValue}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <WithTimerButton duration={0.5} handleClick={handleResend}>
              Resend OTP
            </WithTimerButton>
            <ButtonAsync
              type="submit"
              className="btn btn-primary position-relative ml-1"
              loading={pending}
            >
              Confirm OTP
            </ButtonAsync>
          </div>
        </div>
      </div>
    </form>
  );
}

const modalKey = "confirm-otp";
const modalConfig = {
  title: "Enter your 6-Digit OTP",
  size: "modal-md",
};

export default withModal(modalKey, modalConfig)(ConfirmOtp);
