import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const ProductInformation = ({ data }) => {
  return (
    <div className="my-4" style={{ fontSize: '.875rem' }}>
      <header
        className="rounded-top header-rounded background-gray text-white overflow-hidden d-flex justify-content-between pl-3"
        style={{ backgroundColor: '#7d7b7a' }}
      >
        <div
          className="header-title my-auto d-flex align-items-center"
          style={{ height: 39 }}
        >
          <h6 className="h6 text-md-size font-weight-bold p-0 m-0">
            Product Information
          </h6>
        </div>
      </header>
      <div
        className="p-4 bg-white rounded-bottom"
        style={{ border: '1px solid #cec7c780' }}
      >
        <div className="w-100">
          <div className="row mb-4">
            <div className="col-3 d-flex flex-column text-sm-size">
              <span className="label font-weight-bold mb-2">Product Code</span>
              <p className="description mb-0">
                {_.startCase(_.get(data, 'attributes.product.code')) || '--'}
              </p>
            </div>
            <div className="col-3 d-flex flex-column text-sm-size">
              <span className="label font-weight-bold mb-2">Product Name</span>
              <p className="description mb-0">
                {_.startCase(_.get(data, 'attributes.product.name')) || '--'}
              </p>
            </div>
            <div className="col-3 d-flex flex-column text-sm-size">
              <span className="label font-weight-bold mb-2">Category</span>
              <p className="description mb-0">
                {_.startCase(
                  _.get(data, 'attributes.product.category.name', '--')
                ) || '--'}
              </p>
            </div>
            <div className="col-3 d-flex flex-column text-sm-size">
              <span className="label font-weight-bold mb-2">Description</span>
              <p className="description mb-0">
                {_.startCase(_.get(data, 'attributes.product.description')) ||
                  '--'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductInformation.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default ProductInformation;
