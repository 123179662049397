import React, { useState, useCallback, useEffect, useMemo } from "react";
import _ from "lodash";
import { checkPermissions } from "helper";
import { useLoading } from "modules/ui/hooks";
import Filter from "components/Filter";
import Range from "components/Range";
import GenericTable from "modules/common/components/GenericTable";
import Pagination from "modules/common/components/Pagination";
import { useSelector, useDispatch } from "react-redux";
import ViewLoadTransaction from "../components/ViewLoadTransaction";
import * as c from "../constant";
import * as actions from "../actions";

function LoadRetailerHistory() {
  const dispatch = useDispatch();
  const [payload, setPayload] = useState(c.LRH_default);
  // const isDownloading = useLoading(c.DOWNLOAD_RETAILER_TRANSACTION);
  const isLoading = useLoading(c.GET_LOAD_RETAILER_TRANSACTION);

  const profile = useSelector(c.LRH_selectors.profile);
  const role = _.head(profile?.roles) || "";
  const raw = useSelector(c.LRH_selectors.raw);
  const pager = raw?.res?.meta?.pagination || {};
  const list = raw?.list || [];

  const handleChangePage = (page) =>
    dispatch(
      actions.getLoadRetailerTransaction(_.pickBy({ ...payload, page }))
    );

  const handleSubmitFilter = () => {
    const trimmedPayload = _.pickBy({
      ...payload,
      created_by_username: _.trim(payload?.created_by_username),
      q: _.trim(payload?.q),
    });
    dispatch(actions.getLoadRetailerTransaction(trimmedPayload));
  };
  const handleDownload = (e) => {
    e.preventDefault();
    const now = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Manila",
    });
    const timestamp = now
      .replace(/[-/:]/g, "-")
      .replace(",", "")
      .replace(/\s/g, "_");
    const filename = `Load Retailer History ${timestamp}.csv`;
    dispatch(
      actions.downloadLoadRetailerTransaction(
        filename,
        _.pickBy(_.omit(payload, ["page"]))
      )
    );
  };

  const handleClear = () => {
    setPayload(c.LRH_default);
  };

  const updateTransaction = useCallback(() => {
    const trimmedPayload = _.pickBy({
      ...payload,
      created_by_username: _.trim(payload?.created_by_username),
      q: _.trim(payload?.q),
    });
    dispatch(actions.getLoadRetailerTransaction(trimmedPayload));
  }, [payload]);

  // watch query independently of other params for debouncing
  useEffect(() => {
    updateTransaction();
  }, [..._.values(_.omit(payload, ["q"]))]); // eslint-disable-line

  const initialize = useCallback(() => {
    dispatch(actions.getLoadRetailerTransaction(_.pickBy(c.LRH_default)));
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const showDownload = checkPermissions(["can-create-export-transaction"])
    ? handleDownload
    : null;

  const form = useMemo(() => {
    return c.LRH_form.map((x) => {
      if (x.name === "branch_id")
        return { ...x, show: role !== "kapartner-user" };
      if (x.name === "dealer_sim_min")
        return {
          ...x,
          show: role !== "frontliner" && role !== "kapartner-user",
        };
      return x;
    });
  }, [c.LRH_form, role]);

  return (
    <div className="main-content-inner h-100 notebookRetailer scroll-lime">
      <div className="container-fluid h-100 px-0">
        <div className="row h-100">
          <div className="col-12 h-100">
            <div className="container-fluid px-0">
              <Filter
                form={form}
                filterData={[payload, setPayload]}
                onDownload={showDownload}
                actions={handleSubmitFilter}
                onClear={handleClear}
                loading={isLoading}
                containerClass="tw-mb-4"
              />
              <div className="row">
                <div className="col d-flex align-items-bottom justify-content-between mb-3">
                  <div className="d-flex flex-column justify-content-center ">
                    <div className="font-weight-bold text-xl">
                      Load Retailer History
                    </div>
                    <Range
                      date_from={payload.date_from}
                      date_to={payload.date_to}
                    />
                  </div>
                </div>
              </div>
              <GenericTable
                isLoading={isLoading}
                format={c.LRH_MAP}
                data={list}
                containerClassName="notebookTransaction__container-table scroll-lime"
                customTableStyles={c.customTableStyles}
              />
              <Pagination
                onChange={handleChangePage}
                data={pager}
                className="d-flex justify-content-between p-2 border-top border-muted"
                withPage
              />
            </div>
          </div>
        </div>
      </div>
      <ViewLoadTransaction />
    </div>
  );
}

export default LoadRetailerHistory;
