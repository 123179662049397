import React from 'react';
import PropTypes from 'prop-types';

const PanelRow = ({ children, appendClass }) => {
  return (
    <div className={`${appendClass ? appendClass : "row"} mx-0 py-3 border-top border-gray-200`}>
      {children}
    </div>
  );
};

export default PanelRow;

PanelRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  appendClass: PropTypes.string,
};

PanelRow.defaultProps = {
  appendClass: '',
};
