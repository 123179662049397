import moment from "moment";

export const GET_BENTA_KITA = "NOTEBOOK/get_benta_kita";
export const DOWNLOAD_BENTA_KITA = "NOTEBOOK/download_benta_kita";
export const GET_LOAD_SUBSCRIBER_TRANSACTIONS =
  "NOTEBOOK/get_load_subscriber_transactions";
export const GET_BILLS_TRANSACTION = "NOTEBOOK/get_bills_transaction";
export const DOWNLOAD_SUBSCRIBER_TRANSACTION =
  "NOTEBOOK/download_subscriber_transaction";

export const GET_RETAILER_USERS = "NOTEBOOK/get_retailer_users";
export const GET_SOURCE_FUND = "NOTEBOOK/get_source_fund";
export const GET_SOURCE_FUND_DEALER = "NOTEBOOK/get_source_fund_dealer";
export const GET_BRANCH = "NOTEBOOK/get_branch";
export const GET_LOAD_RETAILER_TRANSACTION =
  "NOTEBOOK/get_load_retailer_transactions";
export const DOWNLOAD_RETAILER_TRANSACTION =
  "NOTEBOOK/download_retailer_transaction";

export const LSH_selectors = {
  profile: ({ api }) => api["AUTH/user_profile"].item || {},
  raw: ({ api }) => api[GET_LOAD_SUBSCRIBER_TRANSACTIONS] || {},
};

export const LRH_selectors = {
  profile: ({ api }) => api["AUTH/user_profile"].item || {},
  raw: ({ api }) => api[GET_LOAD_RETAILER_TRANSACTION] || {},
};

export const LSH_default = {
  per_page: 15,
  q: "",
  date_from: "",
  date_to: "",
  created_by_id: "",
  created_by_name: "",
  created_by_username: "",
  retailer_min: "",
  branch_id: "",
  promo_id: "",
};

export const LRH_default = {
  per_page: 15,
  type: "external",
  q: "",
  date_from: "",
  date_to: "",
  created_by_username: "",
  dealer_sim_min: "",
  branch_id: "",
};

export const dataMap = [
  "amount_deducted",
  "amount_loaded",
  "earning",
  "promo_description",
  "promo_name",
  "remaining_balance",
  "retailer_id",
  "retailer_min",
  "retailer_name",
  "target_mobile",
  "transaction_reference",
  "transaction_timestamp",
  "created_by_name",
];

export const LSH_form = [
  {
    type: "date",
    name: "date_from",
    label: "Date From:",
  },
  {
    type: "date",
    name: "date_to",
    label: "Date To:",
  },
  {
    type: "select-async",
    name: "branch_id",
    label: "Branch:",
    api: "api/v1/branches",
    params: { include: null },
    isClearable: true,
  },
  {
    type: "select-async",
    name: "retailer_min",
    label: "Source Fund:",
    api: "api/v1/logs/transactions-logs/min",
    transformer: (list) =>
      list?.data.map((x) => ({
        value: x?.attributes?.retailer_min,
        label: x?.attributes?.retailer_min,
      })),
    params: { type: "external" },
    isClearable: true,
  },
  {
    type: "text",
    name: "created_by_name",
    label: "Transacted By:",
  },
  {
    type: "select-async",
    name: "promo_id",
    label: "Promo:",
    api: "api/v1/promos",
    transformer: (list) =>
      list?.data.map((x) => ({
        value: x?.id,
        label: `${x?.attributes?.name} - ${x?.attributes?.provider?.title}`,
      })),
    isClearable: true,
    params: {
      ui_channel: "web",
      per_page: 150,
    },
    queryName: "name",
  },
];

export const LRH_form = [
  {
    type: "date",
    name: "date_from",
    label: "Date From:",
  },
  {
    type: "date",
    name: "date_to",
    label: "Date To:",
  },
  {
    type: "select-async",
    name: "branch_id",
    label: "Branch:",
    api: "api/v1/branches",
    params: { include: null },
    isClearable: true,
  },
  {
    type: "select-async",
    name: "dealer_sim_min",
    label: "Source Fund:",
    api: "api/v1/dealer-sims/transfer-load/transactions/all-min",
    transformer: (list) =>
      list?.data.map((x) => ({
        value: x?.attributes?.dealer_sim_min,
        label: x?.attributes?.dealer_sim_min,
      })),
    params: { type: "external" },
    isClearable: true,
  },
  {
    type: "text",
    name: "created_by_username",
    label: "Username:",
  },
];

export const LSH_MAP = [
  {
    key: (item) => (
      <span>{item?.attributes?.transaction_timestamp || "--"}</span>
    ),
    label: "DATE / TIME",
    width: "auto",
  },
  {
    key: (item) => <span>{item?.attributes?.created_by_name || "--"}</span>,
    label: "TRANSACTED BY",
    width: "auto",
  },
  {
    key: (item) => <span>{item?.attributes?.created_by_username || "--"}</span>,
    label: "USERNAME",
    width: "auto",
  },
  {
    key: (item) => <span>{item?.attributes?.retailer_min || "--"}</span>,
    label: "SOURCE FUND",
    width: "auto",
  },
  {
    key: (item) => <span>{item?.attributes?.target_mobile || "--"}</span>,
    label: "TARGET MOBILE #",
    width: "auto",
  },
  {
    key: (item) => <span>{item?.attributes?.amount_loaded || "--"}</span>,
    label: "AMOUNT",
    width: "auto",
  },
  {
    key: (item) => <span>{item?.attributes?.branch_name || "--"}</span>,
    label: "BRANCH",
    width: "auto",
  },
  {
    key: (item) => (
      <span>{item?.attributes?.transaction_reference || "--"}</span>
    ),
    label: "REFERENCE",
    width: "auto",
  },
  {
    key: (item) => <span>{item?.attributes?.promo_name || "--"}</span>,
    label: "PROMO",
    width: "auto",
  },
];

export const LRH_MAP = [
  {
    key: (item) => (
      <span>
        {!item?.attributes?.created_at
          ? "--"
          : moment(item?.attributes?.created_at).format(
              "DD MMM YYYY / hh:mm a",
            )}
      </span>
    ),
    label: "DATE / TIME",
    width: "auto",
  },
  {
    key: (item) => <span>{item?.attributes?.dealer_sim_min || "--"}</span>,
    label: "SOURCE FUND",
    width: "auto",
  },
  {
    key: (item) => <span>{item?.attributes?.dealer_sim_type || "--"}</span>,
    label: "SOURCE SIM TYPE",
    width: "auto",
  },
  {
    key: (item) => <span>{item?.attributes?.target_min || "--"}</span>,
    label: "TARGET MOBILE #",
    width: "auto",
  },
  {
    key: (item) => <span>{item?.attributes?.target_sim_type || "--"}</span>,
    label: "TARGET SIM TYPE",
    width: "auto",
  },
  {
    key: (item) => {
      const amount = item?.attributes?.amount_loaded;
      return (
        <span>
          {amount
            ? `${amount.toLocaleString("en", {
                minimumFractionDigits: 2,
              })}`
            : "--"}
        </span>
      );
    },
    label: "AMOUNT",
    width: "auto",
  },
  {
    key: (item) => <span>{item?.attributes?.ref_id || "--"}</span>,
    label: "REFERENCE NO.",
    width: "auto",
  },
  {
    key: (item) => {
      const credit = item?.attributes?.debit_balance;
      return (
        <span>
          {credit
            ? `PHP ${credit.toLocaleString("en", {
                minimumFractionDigits: 2,
              })}`
            : "--"}
        </span>
      );
    },

    label: "ENDING BALANCE",
    width: "auto",
  },
  {
    key: (item) => <span>{item?.attributes?.created_by_username || "--"}</span>,
    label: "USERNAME",
    width: "auto",
  },
  {
    key: (item) => <span>{item?.attributes?.branch_name || "--"}</span>,
    label: "BRANCH",
    width: "auto",
  },
];

export const customTableStyles = {
  minWidth: "1350px",
  width: "100%",
};

export const BK_INIT = {
  filter_by: {
    value: "daily",
    label: "DAILY",
  },
  date: moment().format("YYYY-MM-DD"),
  start_date: moment().format("YYYY-MM-DD"),
  end_date: moment()
    .add(1, "days")
    .format("YYYY-MM-DD"),
  page: 1,
};

export const DATE_LABEL = {
  daily: "Time",
  monthly: "Day",
  yearly: "Month",
};

export const BK_FORM = [
  {
    value: "daily",
    label: "DAILY",
  },
  {
    value: "monthly",
    label: "MONTHLY",
  },
  {
    value: "yearly",
    label: "YEARLY",
  },
];

const viewWidth = window.innerWidth;
export const finalChart = viewWidth >= 1199 ? 500 : 300;
export const finalTable = viewWidth >= 1199 ? 65 : 50;
export const addDay = (start) => {
  const date = new Date(start);
  date.setDate(date.getDate() + 1);
  return date;
};
