import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ModalContext } from "App";
import Button from "components/Button";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ToastError, ToastWarn } from "modules/common/components/Toast";
import ErrorMessage from "modules/common/components/ErrorMessage";
import withModal from "modules/common/hoc/withModal";
import FormInputCheckbox from "modules/common/components/input/FormInputCheckbox";
import AgentSelectField from "./AgentSelectField";
import {
  getAgentMins,
  getAgentRetailers,
  transferAgentRetailers,
} from "../actions";

const AgentRetailerSetup = () => {
  const urlParams = useParams();
  const [targetAgent, setTargetAgent] = useState();
  const [retailers, setRetailers] = useState({});
  const setModal = useContext(ModalContext);
  const queryClient = useQueryClient();
  const agentId = urlParams?.agentId;

  const agentMinQuery = useQuery({
    queryKey: ["agent-mins", { agentId }],
    queryFn: getAgentMins,
    enabled: !!agentId,
  });

  const simId = agentMinQuery?.data?.data?.id;

  const targetAgentMinQuery = useQuery({
    queryKey: ["agent-mins", { agentId: targetAgent?.id }],
    queryFn: getAgentMins,
    enabled: !!targetAgent,
  });

  const transfer_to_dealer_sim_id = targetAgentMinQuery?.data?.data?.id;

  const agentRetailers = useQuery({
    queryKey: [
      "agent-retailers",
      simId,
      {
        evc_status:
          "transfer-done,transfer-failed,delink-failed,delink-done,link-done,link-failed",
      },
    ],
    queryFn: getAgentRetailers,
    enabled: !!simId,
  });

  const {
    mutate: transfer,
    status,
    ...rest
  } = useMutation(transferAgentRetailers, {
    onSuccess: () => {
      queryClient.invalidateQueries("agent-retailers");
      ToastWarn("Retailer transfer is now in progress.");
      setModal({ isOpen: false });
    },
    onError: (err) => {
      const errorMessage = (
        <ErrorMessage err={err} defaultMessage="Retailer transfer failed." />
      );
      ToastError(errorMessage);
      setModal({ isOpen: false });
    },
  });

  const retailersData = agentRetailers?.data?.data || [];

  console.log(rest);

  const retailer_ids = useMemo(
    () => _.keys(_.pickBy(retailers, (v) => v === true)),
    [retailers]
  );

  const isTransferring = useMemo(() => {
    return status === "loading";
  }, [status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    transfer({ simId, transfer_to_dealer_sim_id, retailer_ids });
  };

  const handleModalClose = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleSelectAll = (value) => {
    if (value === 1) {
      setRetailers(_.mapValues({ ...retailers }, () => false));
      return;
    }
    setRetailers(_.mapValues({ ...retailers }, () => true));
  };

  const isFormValid = useMemo(
    () =>
      _.every([
        !_.isEmpty(transfer_to_dealer_sim_id),
        !_.isEmpty(retailer_ids),
      ]),
    [transfer_to_dealer_sim_id, retailer_ids]
  );

  const allStatus = useMemo(() => {
    if (_.every(retailers)) return 1;
    return 0;
  }, [retailers]);

  useEffect(() => {
    if (!retailersData) return;
    setRetailers(_.mapValues(_.mapKeys(retailersData, "id"), () => false));
  }, [retailersData]);

  return (
    <div className="col">
      <form onSubmit={handleSubmit}>
        <div className="row px-0 mb-3">
          <div className="col-md-8">
            <label className="form-label text-primary">Assign to Agent *</label>
            <AgentSelectField onChange={setTargetAgent} />
          </div>
        </div>
        {retailersData.length > 1 && (
          <div className="row">
            <div className="col-3 mb-3">
              <FormInputCheckbox
                label="Select all"
                name="select-all"
                value={allStatus}
                onGetValue={(value) => handleSelectAll(value)}
                containerClassName="form-check d-flex align-items-center pl-0"
              />
            </div>
          </div>
        )}
        <div className="row px-0">
          <div className="col">
            <div className="multiple-select bg-grey-1100 p-2 d-flex flex-wrap overflow-auto">
              {_.isEmpty(retailersData) ? (
                <div className="d-flex align-items-center justify-content-center px-3 w-100">
                  <span className="text-gray-300">
                    Retailers list is empty.
                  </span>
                </div>
              ) : (
                <Fragment>
                  {_.map(retailersData, (retailer) => {
                    const id = retailer?.id;
                    return (
                      <div className="px-2 py-1" key={id}>
                        <li className="list-unstyled">
                          <label
                            className="d-flex align-items-center"
                            style={{ lineHeight: 1.2 }}
                          >
                            <input
                              type="checkbox"
                              checked={retailers[id] || false}
                              className="mr-2 "
                              onChange={() => {
                                const up = _.update(
                                  retailers,
                                  id,
                                  () => !retailers[id]
                                );
                                setRetailers({ ...up });
                              }}
                            />
                            <span className="mr-2">
                              {retailer?.attributes?.full_name}
                            </span>
                            (
                            <span className="text-primary font-weight-bold">
                              {
                                retailer?.attributes
                                  ?.mobile_identification_number
                              }
                            </span>
                            )
                          </label>
                        </li>
                      </div>
                    );
                  })}
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end pt-4">
          <Button
            loading={isTransferring}
            type="submit"
            disabled={!isFormValid || isTransferring}
          >
            Add
          </Button>
          <Button onClick={handleModalClose} className="btn btn-light ml-2">
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

const modalKey = "agent-retailer-transfer-setup";
const modalConfig = {
  title: "Transfer Retailer",
  size: "modal-md",
};

export default withModal(modalKey, modalConfig)(AgentRetailerSetup);
