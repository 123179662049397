import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import _ from "lodash";
import { checkPermissions, firstModule } from "helper";
import UserIdleTimeout from "modules/auth/containers/UserIdleTimeout";
import Branches from "modules/ManageAccounts/containers/Branches";
import ShiftSchedule from "modules/ManageAccounts/containers/ShiftSchedule";
import LoadTransactHistory from "modules/notebook/container/LoadTransactHistory";
import LoadRetailerHistory from "modules/notebook/container/LoadRetailerHistory";
import BillPaymentHistory from "modules/notebook/container/BillPaymentHistory";
import BentaKita from "modules/notebook/container/BentaKita";
import ProfileContainer from "modules/profile-settings/containers/ProfileContainer";
import SecuritySettingsContainer from "modules/profile-settings/containers/SecuritySettingsContainer";
import UserContainer from "modules/UserAccountManagement/container/UserContainer";
import RoleContainer from "modules/UserAccountManagement/container/RoleContainer";
import HelpSupport from "modules/Contact-us/container/HelpSupport";
import Inquiries from "modules/AssuranceManagement/Inquiries";
import HelpDesk from "modules/AssuranceManagement/HelpDesk";
import OrderContainer from "modules/purchase-order/container/OrderContainer";
import Product from "modules/purchase-order/container/Product";
import OrderDetails from "modules/purchase-order/container/OrderDetails";
import PasswordExpireNotice from "modules/partials/PasswordExpireNotice";
import AgentOperation from "app/retail-management/agent-operation";
import AgentClusters from "app/retail-management/agent-clusters";
import LoadWallet from "app/load-wallet";
import Dashboard from "app/dashboard";
import ProductPricing from "app/product-management/pricing";
import DealerContainer from "modules/partners/dealer/containers/DealerContainer";
import ViewUpdateContainer from "modules/partners/dealer/containers/ViewUpdateContainer";
import ApplicationsContainer from "modules/partners/applications/containers/ApplicationsContainer";
import RetailerContainer from "modules/partners/retailer/containers/RetailerContainer";
import NoPermissions from "modules/auth/containers/NoPermissions";
import ReportsContainer from "app/reports";
import LogoutContainer from "../auth/containers/LogoutContainer";
import SettingsContainer from "../Settings/container/SettingsContainer";
import RelationshipManagement from "../RelationshipManagement";
import AssuranceManagement from "../AssuranceManagement";
import APIContainer from "app/api-documentation/eload-api-gw-dev-swagger";
import Header from "./Header";
import DealerSideBar from "./DealerSideBar";
import RetailerSideBar from "./RetailerSideBar";
import SalesOrderContainer from "app/sales-order";
import Page404 from "modules/auth/containers/Page404";
import BranchOperations from "app/branch-operations";

function PrivateRoutes() {
  const profile = JSON.parse(sessionStorage.getItem("profile"));
  const role = _.get(profile, "profile_type");
  const partners_first_module = firstModule("partners");

  return (
    <div className="layout">
      <UserIdleTimeout />

      <Route
        path="/:mainModule?"
        render={() =>
          role === "dealers" || role === "dealer_users" ? (
            <DealerSideBar />
          ) : (
            <RetailerSideBar />
          )
        }
      />

      <main className="main-content">
        <Header />
        <PasswordExpireNotice minimum={90} redirect="/profile-setting" />
        <Switch>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Redirect exact from="/" to="/dashboard" />
          <Route path="/dealer-information">
            <RelationshipManagement />
          </Route>

          <Redirect
            exact
            from="/partners"
            to={`/partners/${partners_first_module?.path}`}
          />
          <Route exact path="/partners/dealer" component={DealerContainer} />
          <Route
            path="/partners/:partners_modules/:dealer_id"
            component={ViewUpdateContainer}
          />
          <Route
            path="/partners/applications"
            component={ApplicationsContainer}
          />
          <Route path="/partners/retailer" component={RetailerContainer} />

          {/* assurance Management */}
          <Route path="/assurance-management">
            <AssuranceManagement />
          </Route>
          <Route path="/assurance-management/inquiries">
            <Inquiries />
          </Route>
          <Route path="/assurance-management/helpdesk">
            <HelpDesk />
          </Route>
          {/* end */}
          <Route path="/settings">
            <SettingsContainer />
          </Route>
          {/* <Route path="/dashboard">
            <OldDashboardContainer />
          </Route>
          <Route path="/dealer/dashboard">
            <DealerDashboard />
          </Route> */}
          {/* sale order */}
          <Route path="/sales-order" component={SalesOrderContainer} />
          {/* manage */}
          <Route exact path="/manage">
            {checkPermissions(["can-view-sub-dealer-agent-dealer-sims"]) && (
              <Redirect to="/manage/agent-operation" />
            )}
            {checkPermissions(["can-view-branch"]) && (
              <Redirect to="/manage/branch" />
            )}
          </Route>
          <Route exact path="/manage/user-access">
            {checkPermissions(["can-view-retailer-user"]) && (
              <Redirect to="/manage/user-access/retailer-users" />
            )}
            {checkPermissions(["can-view-dealer-user"]) && (
              <Redirect to="/manage/user-access/dealer-users" />
            )}
          </Route>
          <Route path="/manage/user-access">
            <UserContainer />
          </Route>
          <Route path="/manage/branch" component={BranchOperations} />
          <Route path="/manage/shift-schedule">
            <ShiftSchedule />
          </Route>
          <Route path="/manage/agent-operation" component={AgentOperation} />
          <Route path="/manage/agent-clusters" component={AgentClusters} />
          {/* suki list */}
          <Route exact path="/suki-list">
            <Redirect to="/suki-list/subscriber-suki" />
          </Route>
          {/* note book */}
          <Route exact path="/notebook">
            <Redirect to="/notebook/load-subscriber-history" />
          </Route>
          <Route path="/notebook/load-subscriber-history">
            <LoadTransactHistory />
          </Route>
          <Route path="/notebook/load-retailer-history">
            <LoadRetailerHistory />
          </Route>
          <Route path="/notebook/bill-payment-history">
            <BillPaymentHistory />
          </Route>
          <Route path="/notebook/benta-kita">
            <BentaKita />
          </Route>
          {/* profile and settings */}
          <Route path="/profile-setting">
            <ProfileContainer />
          </Route>
          <Route path="/profile-setting/security-settings">
            <SecuritySettingsContainer />
          </Route>
          {/* new Routes */}

          {/* user management */}
          <Redirect exact from="/user-mgt" to="/user-mgt/user" />
          <Redirect
            exact
            from="/user-mgt/user"
            to="/user-mgt/user/retailer-users"
          />
          <Route path="/user-mgt/user">
            <UserContainer />
          </Route>
          <Route path="/user-mgt/user-roles">
            <RoleContainer />
          </Route>
          {/* load wallet */}
          <Route path="/load-wallet" component={LoadWallet} />

          {/* Product Management */}
          <Route path="/product-management" component={ProductPricing} />
          {/* purchase order */}
          <Route exact path="/purchase-order">
            <OrderContainer />
          </Route>
          <Route path="/purchase-order/product">
            <Product />
          </Route>

          <Route path="/purchase-order/order-details">
            <OrderDetails />
          </Route>
          <Route path="/help-support">
            <HelpSupport />
          </Route>
          <Route path="/reports">
            <ReportsContainer />
          </Route>
          <Route path="/api-documentation">
            <APIContainer />
          </Route>
          <Route path="/logout" component={LogoutContainer} />
          <Route path="/not-found" component={Page404} />
          <Route path="*" component={Page404} />
        </Switch>
      </main>
    </div>
  );
}

export default PrivateRoutes;
