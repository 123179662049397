import React, {
  useContext,
  useState,
  useMemo,
  useEffect,
  Fragment,
} from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import withModal from "modules/common/hoc/withModal";
import { ToastError, ToastSuccess } from "modules/common/components/Toast";
import ErrorMessage from "modules/common/components/ErrorMessage";
import FormInputCheckbox from "modules/common/components/input/FormInputCheckbox";
import { ModalContext } from "App";
import Button from "components/Button";
import { bulkLinkRetailers } from "app/load-wallet/actions";
import { getAgentMins, getRetailers } from "../actions";

const AgentRetailerSetup = () => {
  const urlParams = useParams();
  const [retailers, setRetailers] = useState({});
  const setModal = useContext(ModalContext);
  const queryClient = useQueryClient();
  const agentId = _.get(urlParams, "agentId");

  const agentMinQuery = useQuery({
    queryKey: ["agent-mins", { agentId }],
    queryFn: getAgentMins,
    enabled: !!agentId,
  });

  const apiRetailers = useQuery({
    queryKey: [
      "api-retailers",
      {
        has_dealer_sim: false,
      },
    ],
    queryFn: getRetailers,
  });

  const retailerData = apiRetailers?.data?.data || [];

  const dsp = agentMinQuery?.data?.data;

  const dealer_sim_id = dsp?.id;

  const allStatus = useMemo(() => {
    if (_.every(retailers)) return 1;
    return 0;
  }, [retailers]);

  const retailer_ids = useMemo(
    () => _.keys(_.pickBy(retailers, (v) => v === true)),
    [retailers]
  );

  const handleSelectAll = (value) => {
    if (value === 1) {
      setRetailers(_.mapValues({ ...retailers }, () => false));
      return;
    }
    setRetailers(_.mapValues({ ...retailers }, () => true));
  };

  const isFormValid = useMemo(
    () => _.every([!_.isEmpty(dealer_sim_id), !_.isEmpty(retailer_ids)]),
    [dealer_sim_id, retailer_ids]
  );

  const { mutate: addRetailers, isLoading: isAdding } = useMutation(
    bulkLinkRetailers,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("agent-retailers");
        ToastSuccess(
          `Successfully assigned retailer${
            retailer_ids.length > 1 ? "s" : ""
          } to agent.`
        );
        setModal({ isOpen: false });
      },
      onError: (err) => {
        const errorMessage = (
          <ErrorMessage
            err={err}
            defaultMessage={`Failed assigning retailer${
              retailer_ids.length > 1 ? "s" : ""
            } to agent.`}
          />
        );
        ToastError(errorMessage);
        setModal({ isOpen: false });
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isAdding) return;
    addRetailers({ dealer_sim_id, retailer_ids });
  };

  const handleModalClose = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  useEffect(() => {
    if (!retailerData) return;
    setRetailers(_.mapValues(_.mapKeys(retailerData, "id"), () => false));
  }, [retailerData]);

  return (
    <div className="col">
      <form onSubmit={handleSubmit}>
        <div className="row px-0 mb-3">
          <div className="col">
            {/* <label className="form-label text-primary">Retailer Name *</label>
            <SelectAsync
              allowFalsy
              value={retailer}
              api="/external/api/v1/retailers"
              onChange={onSelect}
              params={{ has_dealer_sim: false }}
              transformer={({ data }) => {
                return data.map(item => ({
                  label: (
                    <span>
                      {_.get(item, 'attributes.mobile_identification_number')} -{' '}
                      {_.get(item, 'attributes.full_name')}
                    </span>
                  ),
                  value: _.get(item, 'id'),
                  data: item,
                }));
              }}
              noOptionsMessage={() => 'No retailers available'}
            /> */}
            <label className="form-label text-primary">Assign to MIN</label>
            <input
              readOnly
              type="text"
              value={_.get(dsp, "attributes.mobile_identification_number", "")}
              className="form-control form-custom form-control_bottom"
            />
          </div>
          <div className="col">
            <label className="form-label text-primary">SIM Type</label>
            <input
              readOnly
              type="text"
              value={_.get(dsp, "attributes.type", "")}
              className="form-control form-custom form-control_bottom"
            />
          </div>
        </div>
        {retailerData.length > 1 && (
          <div className="row">
            <div className="col-3 mb-3">
              <FormInputCheckbox
                label="Select all"
                name="select-all"
                value={allStatus}
                onGetValue={(value) => handleSelectAll(value)}
                containerClassName="form-check d-flex align-items-center pl-0"
              />
            </div>
          </div>
        )}
        <div className="row px-0">
          <div className="col">
            <div className="multiple-select bg-grey-1100 p-2 d-flex flex-wrap overflow-auto">
              {_.isEmpty(retailers) ? (
                <div className="d-flex align-items-center justify-content-center px-3 w-100">
                  <span className="text-gray-300">
                    Retailers list is empty.
                  </span>
                </div>
              ) : (
                <Fragment>
                  {_.map(retailerData, (retailer) => {
                    const id = retailer?.id;
                    return (
                      <div className="px-2 py-1" key={id}>
                        <li className="list-unstyled">
                          <label
                            className="d-flex align-items-center"
                            style={{ lineHeight: 1.2 }}
                          >
                            <input
                              type="checkbox"
                              checked={retailers[id] || false}
                              className="mr-2 "
                              onChange={() => {
                                const up = _.update(
                                  retailers,
                                  id,
                                  () => !retailers[id]
                                );
                                setRetailers({ ...up });
                              }}
                            />
                            <span className="mr-2">
                              {retailer?.attributes?.full_name}
                            </span>
                            (
                            <span className="text-primary font-weight-bold">
                              {
                                retailer?.attributes
                                  ?.mobile_identification_number
                              }
                            </span>
                            )
                          </label>
                        </li>
                      </div>
                    );
                  })}
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end pt-4">
          <Button
            loading={isAdding}
            type="submit"
            disabled={!isFormValid || isAdding}
          >
            Add
          </Button>
          <Button onClick={handleModalClose} className="btn btn-light ml-2">
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

const modalKey = "agent-retailer-setup";
const modalConfig = {
  title: "Assign Retailer",
  size: "modal-md",
};

export default withModal(modalKey, modalConfig)(AgentRetailerSetup);
