import * as req from 'api/actions';
import * as c from './constants';

export const getBdl = (payload, callback) =>
  req.list(c.GET_BDL, 'api/v1/dealer-sims', payload, callback);

export const getDealerList = payload =>
  req.list(c.GET_DEALER_LIST, 'api/v1/dealers', payload);

export const getRequiredDocumentsImagesList = dealer_company_id =>
  req.list(
    c.GET_REQUIRED_DOCUMENTS_IMAGES_LIST,
    `api/v1/dealers/companies/${dealer_company_id}/documents`
  );

export const getAccountType = (channel_id, payload) =>
  req.list(
    c.GET_ACCOUNT_TYPE,
    `api/v1/channels/${channel_id}/account_types`,
    payload
  );

// Dropdown
export const getRequiredDocumentsList = () =>
  req.list(
    c.GET_REQUIRED_DOCUMENTS_LIST,
    'relationship/api/v1/required_documents'
  );

export const getChannelList = channel_id =>
  req.list(c.GET_CHANNEL_LIST, `api/v1/channels/${channel_id}/requirements`);

export const getLocation = id => req.item(c.GET_LOCATION, `api/v1/area/${id}`);

export const createDealer = (payload, successCallback, errCallback) =>
  req.create(
    c.CREATE_DEALER,
    'api/v1/dealers',
    payload,
    successCallback,
    {},
    errCallback
  );

export const updateDealer = (
  dealer_id,
  payload,
  successCallback,
  errCallback
) =>
  req.update(
    c.UPDATE_DEALER,
    `api/v1/dealers/${dealer_id}`,
    payload,
    successCallback,
    {},
    errCallback
  );

export const showUserDetails = dealer_id =>
  req.item(c.SHOW_USER_DETAILS, `api/v1/dealers/${dealer_id}`);

export const showCompanyInformation = dealer_id =>
  req.item(
    c.SHOW_COMPANY_INFORMATION,
    `dealer/api/v1/dealers/${dealer_id}/companies`
  );

export const showDeliveryAddressInformation = dealer_id =>
  req.item(
    c.SHOW_DELIVERY_ADDRESS_INFORMATION,
    `api/v1/dealers/delivery-address/${dealer_id}`
  );

export const showMoaSignatoryInformation = (dealer_id, dealer_company_id) =>
  req.item(
    c.SHOW_MOA_SIGNATORY_INFORMATION,
    `dealer/api/v1/dealers/companies/${dealer_company_id}/signatories`
  );

export const getProvinceList = params =>
  req.list(c.GET_PROVINCE_LIST, 'api/v1/provinces', params, {
    per_page: 1000,
  });

export const getCityList = (provinceCode, params) =>
  req.list(c.GET_CITY_LIST, `api/v1/provinces/${provinceCode}/municipalities`, {
    per_page: 1000,
  });

export const getBarangayList = (provinceCode, cityCode, params) =>
  req.list(
    c.GET_BARANGAY_LIST,
    `api/v1/provinces/${provinceCode}/municipalities/${cityCode}/barangays`,
    { per_page: 1500 }
  );

export const resetCityList = payload =>
  req.list(
    c.GET_CITY_LIST,
    'api/v1/provinces/15500000/municipalities',
    payload
  );

export const resetBarangayList = payload =>
  req.list(
    c.GET_BARANGAY_LIST,
    'api/v1/provinces/15500000/municipalities/15501000/barangays',
    payload
  );

export const createDealerCompany = (payload, successCallback, errCallback) =>
  req.create(
    c.CREATE_DEALER_COMPANY,
    'api/v1/dealers/companies',
    payload,
    successCallback,
    {},
    errCallback
  );

export const createMoaSignatories = (
  dealer_company_id,
  payload,
  successCallback,
  errCallback
) =>
  req.create(
    c.CREATE_MOA_SIGNATORIES,
    `api/v1/dealers/companies/${dealer_company_id}/signatories`,
    payload,
    successCallback,
    {},
    errCallback
  );

export const createDeliveryAddress = (payload, successCallback, errCallback) =>
  req.create(
    c.CREATE_DELIVERY_ADDRESS,
    'api/v1/dealers/delivery-address',
    payload,
    successCallback,
    {},
    errCallback
  );

export const createSupportingDocuments = (
  dealer_company_id,
  payload,
  successCallback,
  errCallback
) =>
  req.create(
    c.CREATE_SUPPORTING_DOCUMENTS,
    `api/v1/dealers/companies/${dealer_company_id}/documents`,
    payload,
    successCallback,
    {},
    errCallback
  );

export const createApplications = (payload, successCallback, errCallback) =>
  req.create(
    c.CREATE_APPLICATIONS,
    'api/v1/applications',
    payload,
    successCallback,
    {},
    errCallback
  );

export const addRequiredDocumentsImage = (
  dealer_company_id,
  payload,
  successCallback,
  errCallback
) =>
  req.create(
    c.ADD_REQUIRED_DOCUMENTS_IMAGE,
    `api/v1/dealers/companies/${dealer_company_id}/documents`,
    payload,
    successCallback,
    {},
    errCallback
  );

export const addOtherRequirementsCallOne = (
  payload,
  successCallback,
  errCallback
) =>
  req.create(
    c.ADD_OTHER_REQUIREMENTS_CALL_ONE,
    'api/v1/requirements',
    payload,
    successCallback,
    {},
    errCallback
  );

export const addOtherRequirementsCallTwo = (
  channel_id,
  primary_key,
  successCallback,
  errCallback
) =>
  req.create(
    c.ADD_OTHER_REQUIREMENTS_CALL_TWO,
    `api/v1/channels/${channel_id}/requirements/${primary_key}`,
    {},
    successCallback,
    errCallback
  );

export const addApplication = (payload, successCallback, errCallback) =>
  req.create(
    c.ADD_APPLICATION,
    'api/v1/applications',
    payload,
    successCallback,
    {},
    errCallback
  );

export const updateDealerCompany = (
  dealer_id,
  payload,
  successCallback,
  errCallback
) =>
  req.update(
    c.UPDATE_DEALER_COMPANY,
    `dealer/api/v1/dealers/${dealer_id}/companies`,
    payload,
    successCallback,
    {},
    errCallback
  );

export const updateDeliveryAddress = (
  dealer_id,
  payload,
  successCallback,
  errCallback
) =>
  req.update(
    c.UPDATE_DELIVERY_ADDRESS,
    `api/v1/dealers/delivery-address/${dealer_id}`,
    payload,
    successCallback,
    {},
    errCallback
  );

export const updateMoaSignatories = (
  dealer_company_id,
  payload,
  successCallback,
  errCallback
) =>
  req.update(
    c.UPDATE_MOA_SIGNATORIES,
    `api/v1/dealers/companies/${dealer_company_id}/signatories`,
    payload,
    successCallback,
    {},
    errCallback
  );

export const updateRequiredDocumentsImage = (
  dealer_company_id,
  payload,
  successCallback,
  errCallback
) =>
  req.update(
    c.UPDATE_REQUIRED_DOCUMENTS_IMAGE,
    `api/v1/dealers/companies/${dealer_company_id}/documents`,
    payload,
    successCallback,
    {},
    errCallback
  );

export const updateApplication = (
  on_going_application_id,
  payload,
  successCallback,
  errCallback
) =>
  req.update(
    c.UPDATE_APPLICATION,
    `api/v1/applications/${on_going_application_id}`,
    payload,
    successCallback,
    {},
    errCallback
  );

export const updateApplicationStatus = (
  on_going_application_id,
  payload,
  successCallback,
  errCallback
) =>
  req.update(
    c.UPDATE_APPLCATION_STATUS,
    `api/v1/applications/${on_going_application_id}`,
    payload,
    successCallback,
    {},
    errCallback
  );

export const removeMoaInfo = () =>
  req.setItem(c.SHOW_MOA_SIGNATORY_INFORMATION, {});

export const removeSupDocs = () =>
  req.setItem(c.GET_REQUIRED_DOCUMENTS_IMAGES_LIST, {});

export const removeSims = () => req.setItem(c.GET_BDL, {});
