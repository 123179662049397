import { combineEpics, ofType } from "redux-observable";
import _ from "lodash";
import _get from "lodash/get";
import { ToastError } from "modules/common/components/Toast";
import { of } from "rxjs";
import { map, delay, mergeMap, catchError, takeUntil } from "rxjs/operators";
import { LOGOUT_SUCCESS } from "modules/auth/constants";
import * as req from "./service";
import * as action from "./actions";
import * as c from "./constants";
import store from "../store";

// eslint-disable-next-line no-unused-vars
const getCache = (key, default_value = false) => {
  try {
    return JSON.parse(sessionStorage.getItem(key)) || default_value;
  } catch (err) {
    return null;
  }
};
const getList = (action$) =>
  action$.pipe(
    ofType(c.GET_LIST),
    map((x) => {
      store.dispatch({ type: x.key });
      // pass params
      // const cache = getCache(`${x.key}-${x.url}`);
      // if (cache) {
      //   const listed = action.toList(x.key)(cache);
      //   store.dispatch(listed);
      //   if (x.callback) x.callback(_.pick(listed, ['data', 'pager', 'res']));
      //   return false;
      // }
      return x;
    }),
    mergeMap((row) => {
      if (!row) return new Promise((r) => r({ type: "x" }));
      const { key, url, params, callback, errCallback } = row;
      return req.get(url, params).pipe(
        // map((x) => {
        //   sessionStorage.setItem(`${key}-${url}`, JSON.stringify(x));
        //   return x;
        // }),
        map(action.toList(key)),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ["data", "pager", "res"]));
          }, 50);
          return x;
        }),
        catchError(req.onErr(key, errCallback)),
        takeUntil(action$.pipe(ofType(key))),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      );
    })
  );

const getListEternal = (action$) =>
  action$.pipe(
    ofType(c.GET_LIST_ETERNAL),
    map((x) => {
      store.dispatch({ type: x.key });
      // pass params
      // const cache = getCache(`${x.key}-${x.url}`);
      // if (cache) {
      //   const listed = action.toList(x.key)(cache);
      //   store.dispatch(listed);
      //   if (x.callback) x.callback(_.pick(listed, ['data', 'pager', 'res']));
      //   return false;
      // }
      return x;
    }),
    mergeMap((row) => {
      if (!row) return new Promise((r) => r({ type: "x" }));
      const { key, url, params, callback, errCallback } = row;
      const { _reset, ...rest } = params;
      return req.get(url, rest).pipe(
        // map((x) => {
        //   sessionStorage.setItem(`${key}-${url}`, JSON.stringify(x));
        //   return x;
        // }),
        map(action.toListEternal(key, _reset)),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ["data", "pager", "res"]));
          }, 50);
          return x;
        }),
        catchError(req.onErr(key, errCallback)),
        takeUntil(action$.pipe(ofType(key))),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      );
    })
  );

const getItem = (action$) =>
  action$.pipe(
    ofType(c.GET_ITEM),
    map((x) => {
      store.dispatch({ type: x.key });
      return x;
    }),
    mergeMap(({ key, url, params, callback, transform }) =>
      req.get(url, params).pipe(
        map(action.toItem(key, transform)),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ["data", "res"]));
          }, 50);
          return x;
        }),
        catchError(action.itemError(key)),
        takeUntil(action$.pipe(ofType(key))),
        // takeUntil(action$.pipe(ofType(c.GET_LIST))),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      )
    )
  );

const createItem = (action$) =>
  action$.pipe(
    ofType(c.CREATE_ITEM),
    map((x) => {
      store.dispatch({ type: x.key });
      return x;
    }),
    mergeMap(({ key, url, payload, callback, options = {}, errCallback }) =>
      req.post(url, payload).pipe(
        map(
          action.withMessage(
            key,
            typeof options.defaultMessage !== "undefined"
              ? options.defaultMessage
              : "Created!"
          )
        ),
        map((x) => {
          setTimeout(() => {
            const { data = {}, included = [] } = x.data;
            if (callback)
              callback({
                data: action.flatIncludedToItem(data, included),
                res: x,
              });
          }, 50);
          return x;
        }),
        catchError(req.onErr(key, errCallback)),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      )
    )
  );

const updateItem = (action$) =>
  action$.pipe(
    ofType(c.UPDATE_ITEM),
    map((x) => {
      store.dispatch({ type: x.key });
      return x;
    }),
    mergeMap(({ key, url, payload, callback, options = {}, errCallback }) =>
      req.put(url, payload).pipe(
        map(
          action.withMessage(
            key,
            typeof options.defaultMessage !== "undefined"
              ? options.defaultMessage
              : "Updated!"
          )
        ),
        map((x) => {
          setTimeout(() => {
            const { data = {}, included = [] } = x.data;
            if (callback)
              callback({
                data: action.flatIncludedToItem(data, included),
                res: x,
              });
          }, 50);
          return x;
        }),
        catchError(req.onErr(key, errCallback)),
        takeUntil(action$.pipe(ofType(key))),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      )
    )
  );

const removeItem = (action$) =>
  action$.pipe(
    ofType(c.DELETE_ITEM),
    map((x) => {
      store.dispatch({ type: x.key });
      return x;
    }),
    mergeMap(
      ({ key, url, callback, options = {}, payload = {}, errCallback }) =>
        req.remove(url, payload).pipe(
          map(
            action.withMessage(
              key,
              typeof options.defaultMessage !== "undefined"
                ? options.defaultMessage
                : "Removed!"
            )
          ),
          map((x) => {
            setTimeout(() => {
              if (callback) callback(_.pick(x, ["data"]));
            }, 50);
            return x;
          }),
          catchError(req.onErr(key, errCallback)),
          takeUntil(action$.pipe(ofType(key))),
          takeUntil(action$.pipe(ofType(c.CANCEL)))
        )
    )
  );

const uploadFormData = (action$) =>
  action$.pipe(
    ofType(c.UPLOAD_FORM_DATA),
    mergeMap(({ key, url, formData, callback }) =>
      req.postFormData(url, formData).pipe(
        map(action.withMessage(key, "File Uploaded!")),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ["data"]));
          }, 50);
          return x;
        }),
        catchError(req.onErr(key)),
        // takeUntil(action$.pipe(ofType(c.GET_LIST))),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      )
    )
  );

const download = (action$) =>
  action$.pipe(
    ofType(c.DOWNLOAD),
    mergeMap(({ key, url, fileName, callback }) =>
      req.download(key, url, fileName, callback)
    ),
    map(({ key }) => ({
      type: c.DOWNLOADED,
      key,
    }))
  );

const randomDelay = (max, min) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const gotItemDelay = (action$) =>
  action$.pipe(
    ofType(c.GOT_ITEM_DELAY),
    delay(randomDelay(700, 350)),
    mergeMap(({ key, data, callback }) =>
      of([]).pipe(
        map(() => action.toItem(key)({ response: { data } })),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ["data"]));
          }, 50);
          return x;
        }),
        catchError(action.itemError(key)),
        // takeUntil(action$.pipe(ofType(c.GET_LIST))),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      )
    )
  );

const gotListDelay = (action$) =>
  action$.pipe(
    ofType(c.GOT_LIST_DELAY),
    delay(randomDelay(700, 350)),
    mergeMap(({ key, data, callback }) =>
      of([]).pipe(
        map(() => action.toList(key)({ response: { data } })),
        map((x) => {
          setTimeout(() => {
            if (callback) callback(_.pick(x, ["data", "pager"]));
          }, 50);
          return x;
        }),
        catchError(action.itemError(key)),
        // takeUntil(action$.pipe(ofType(c.GET_LIST))),
        takeUntil(action$.pipe(ofType(c.CANCEL)))
      )
    )
  );

const consolidateError = (action$) =>
  action$.pipe(
    ofType("ON_ERROR"),
    map((res) => {
      const error = res.error || {};
      const { status } = error;
      const reason =
        error?.response?.errors?.[0]?.detail || error?.response?.message;
      const errors = _get(error, "response.errors", []);
      if (status === 401) {
        const exp = _get(error, "response.exp");
        const reasons = _get(error, "response.message");
        const errorMsg = _get(error, "response.errors.message");

        if (
          reason ===
          "Your session has expired or you do not have active session."
        ) {
          store.dispatch({ type: LOGOUT_SUCCESS });
        }
        if (
          reason === "Your session expired or you do not have active session."
        ) {
          store.dispatch({ type: LOGOUT_SUCCESS });
        }
        if (reason === "Token expired" || reasons === "token expired") {
          store.dispatch({ type: LOGOUT_SUCCESS });
        }
        if (exp === "token expired" || reasons === "token expired") {
          store.dispatch({ type: LOGOUT_SUCCESS });
        }

        const message = exp || reason || reasons || errorMsg;

        if (errors.length) {
          errors.forEach(({ detail }) => {
            ToastError(detail, { autoClose: 3000, toastId: message });
          });
        } else {
          ToastError(message, { autoClose: 3000, toastId: message });
        }
      }
      if (status === 402) {
        ToastError(reason);
      }
      if (status === 404 && res.key !== "LOAD-WALLET/set_otp") {
        ToastError(reason);
      }
      if (status === 500) {
        if (
          reason ===
            "Unable to process your request at this moment, please try again later." &&
          res.key !== "DASHBOARD/inquire_balance" &&
          res.key !== "LOAD/inquire_balance" &&
          res.key === "LOAD/send_load"
        ) {
          ToastError(reason);
        }
        if (
          (reason ===
            "Unable to process your request at this moment, please try again later." &&
            res.key === "DASHBOARD/inquire_balance") ||
          (reason ===
            "Unable to process your request at this moment, please try again later." &&
            res.key === "LOAD/inquire_balance") ||
          (reason ===
            "Unable to process your request at this moment, please try again later." &&
            res.key === "LOAD-WALLET/balance_inquire")
        ) {
          ToastError("Unable to check your balance, please try again later");
        }
      }
      if (status === 400 && res.key === "LOAD/send_load") {
        ToastError(
          _.get(error, "response.error") ||
            _.get(error, "response.errors.0.detail")
        );
      }
      if (res.key === "LOAD-WALLET/set_otp") {
        if (reason === "Resource not found error.") {
          ToastError("Invalid retailer min.");
        }
      }
      if (status === 403) {
        const err =
          _get(error, "response.message") ||
          _get(error, "response.errors.0.detail") ||
          "Oops! Something went wrong";
        ToastError(err, { autoClose: 3000 });
      }
      if (status === 422 || status === 405) {
        const err =
          _get(error, "response.errors.0.detail") ||
          _get(error, "response.errors.0.message") ||
          "Oops! Something went wrong";
        if (err !== "The otp field is required.")
          ToastError(err, { autoClose: 3000 });
      }
      if (status === 400 && res.key !== "LOAD/send_load") {
        ToastError(
          _get(error, "response.message") ||
            _get(error, "response.errors.0.detail") ||
            _get(error, "response.0.message") ||
            _get(error, "response.reason") ||
            _get(error, "response.detail") ||
            "Oops! Something went wrong",
          { autoClose: 3000 }
        );
      }
      if (status === 409 || status === 429) {
        ToastError("Too many request. Please try again later...", {
          autoClose: 3000,
        });
      }
      if (error && !status && typeof status !== "number") {
        console.log(error); // eslint-disable-line
        ToastError(
          _get(error, "response.error") ||
            _get(error, "response.message") ||
            _get(error, "response.0.message") ||
            "Oops! Something went wrong",
          { autoClose: 3000 }
        );
      }
      if (!error) {
        console.log("unable to parse error", error); // eslint-disable-line
      }
      return {
        type: "ON_ERROR_DONE",
      };
    })
  );

export default combineEpics(
  getList,
  getListEternal,
  getItem,
  createItem,
  updateItem,
  removeItem,
  uploadFormData,
  download,
  gotItemDelay,
  gotListDelay,
  consolidateError
);
