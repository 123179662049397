import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { ModalContext } from "App";
import ButtonAsync from "components/Button";
import { removeEmpty } from "helper";
import { ToastSuccess } from "modules/common/components/Toast";
import withModal from "modules/common/hoc/withModal";
import { useDealer } from "app/load-wallet/context/DealerProvider";
import * as actions from "../actions";
import * as c from "../constant";

function DealerSimPasswordConfirmation() {
  const dispatch = useDispatch();
  const messages = c.MESSAGES_SIM_ACTION;
  const setModal = useContext(ModalContext);
  const data = useSelector(({ modal }) => modal?.modalData);
  const [password, setPassword] = useState("");
  const [pending, setPending] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { simDealerId } = useDealer();

  const closeModal = () => {
    setModal({ isOpen: false });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);

    const createPayload = {
      ...data,
      mobile_identification_number: data?.mobile_identification_number,
      ..._.omit(data, ["tempSubmitType"]),
    };

    const onError = () => {
      setPassword("");
      setPending(false);
      closeModal();
    };

    const onCreateSuccess = (res) => {
      const submitType = data?.tempSubmitType || "default";
      if (data?.tempSubmitType !== "delete") {
        ToastSuccess(messages[submitType]);
      } else {
        ToastSuccess(`${_.get(res, "data.success.0.details")}`);
      }
      setModal({ isOpen: false });
      setPending(false);
      const simPayload = {
        dealer_id: data?.attributes?.dealer_id || simDealerId,
        page: 1,
        per_page: 10,
        userType: "dealers",
        ...c.sortFilter,
      };
      dispatch(actions.getDealerSimsAll({ ...simPayload, per_page: 9999 }));
      dispatch(actions.getDealerSims(simPayload));
    };

    const onConfirmSuccess = () => {
      const submitTypeValue = data?.tempSubmitType;
      if (submitTypeValue === "update") {
        dispatch(
          actions.updateDealerSims(
            data?.id,
            removeEmpty({ ...createPayload, mobile_identification_number: "" }),
            onCreateSuccess,
            onError
          )
        );
      }
      if (submitTypeValue === "block" || submitTypeValue === "unblock") {
        dispatch(
          actions.updateDealerSims(
            data?.id,
            removeEmpty({ ...createPayload, mobile_identification_number: "" }),
            onCreateSuccess,
            onError
          )
        );
      }
      if (submitTypeValue === "delete") {
        dispatch(
          actions.removeDealerSims(
            data?.id,
            (res) => onCreateSuccess(res),
            onError
          )
        );
      }
    };

    dispatch(actions.validatePassword({ password }, onConfirmSuccess, onError));
  };

  const handleFieldVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="prepend-form-right">
            <h5 className="font-weight-bolder my-2">IMPORTANT!</h5>
            <p className="mb-4">Enter your password to authorize action</p>
            <div className="form-group">
              <div className="prepend-form-right">
                <label className="form-label mb-2">Password *</label>
                <div className="prepend-input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control input-textbox password with-pass-icon form-custom form-control_bottom "
                    required
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="off"
                  />
                </div>
                <button
                  type="button"
                  onClick={handleFieldVisibility}
                  className="btn btn-default rounded-circle p-0 mr-1 outline-none"
                >
                  <i
                    className={`fas ${
                      !showPassword ? "fa-eye" : "fa-eye-slash"
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-5 d-flex align-items-center justify-content-end">
            <ButtonAsync
              type="submit"
              className="btn btn-primary position-relative ml-1"
              loading={pending}
            >
              Confirm
            </ButtonAsync>
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-danger font-weight-bold ml-2"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

const modalKey = "dealer-sim-confirm-password";
const modalConfig = {
  title: "Password Confirmation",
  size: "modal-md",
};

export default withModal(modalKey, modalConfig)(DealerSimPasswordConfirmation);
