import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const ListAsync = ({
  data,
  error,
  isLoading,
  refetch,
  renderItems,
  emptyMessage,
  showEmpty,
}) => {
  if (error) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <small className="text-gray-400">
          An error occurred while fetching data.
        </small>
        <button
          type="button"
          className="btn text-primary outline-none text-sm"
          onClick={() => refetch()}
        >
          Reload
        </button>
      </div>
    );
  }

  if (!isLoading && _.isEmpty(data)) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <small className="text-gray-400">No items found.</small>
      </div>
    );
  }

  if (showEmpty) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <small className="text-gray-400">{emptyMessage}</small>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column position-relative">
      {_.map(data, renderItems)}
      {isLoading ? (
        <div
          className="position-absolute w-100 h-100"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.75)' }}
        >
          <span className="sr-only">Loading...</span>
        </div>
      ) : null}
    </div>
  );
};

export default ListAsync;

ListAsync.propTypes = {
  data: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  error: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  refetch: PropTypes.func.isRequired,
  renderItems: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  emptyMessage: PropTypes.string,
  showEmpty: PropTypes.bool,
};

ListAsync.defaultProps = {
  isLoading: false,
  emptyMessage: 'No items found.',
  showEmpty: false,
  data: [],
  error: [],
};
