import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { usePermissions } from 'modules/auth/hooks';
import Logo from 'assets/images/dealers-portal-logo-sm.png';
import { USER_PROFILE } from 'modules/auth/constants';
import { getConsent } from '../action';
import Onboarding from './Onboarding';
import DealerOnboarding from './DealerOnboarding';
import { GET_CONSENT } from '../constant';

const FirstLogin = () => {
  const dispatch = useDispatch();
  const permissions = usePermissions();
  const consents = useSelector(({ api }) => api?.[GET_CONSENT]?.list);
  const profile = useSelector(
    ({ api }) =>
      _.get(api, `${USER_PROFILE}.item`) ||
      JSON.parse(sessionStorage.getItem('profile'))
  );

  useEffect(() => {
    if (permissions.isLoading || consents) return;
    dispatch(
      getConsent({
        category: permissions.data?.data?.role_slug,
      })
    );
  }, [dispatch, permissions, consents, profile]);

  return (
    <div className="first-login-page scroll-first">
      <div className="layer" />
      <div className="main-content d-flex align-items-center w-100 py-4 px-3 position-relative">
        <div className="front w-50 my-auto mx-auto">
          <img
            src={Logo}
            alt="PLDT / SMART Dealer's Portal"
            className="d-block mx-auto mb-3"
          />
          {consents ? <UserOnboarding /> : <LoadingIndicator />}
        </div>
      </div>
    </div>
  );
};

export default FirstLogin;

const LoadingIndicator = () => {
  return (
    <div className="text-center py-4">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

const UserOnboarding = () => {
  const profile = useSelector(
    ({ api }) =>
      _.get(api, `${USER_PROFILE}.item`) ||
      JSON.parse(sessionStorage.getItem('profile'))
  );

  const isDealer = _.get(profile, 'profile_type') === 'dealers';

  if (isDealer) return <DealerOnboarding />;

  return <Onboarding />;
};
