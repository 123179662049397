import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import withModal from "modules/common/hoc/withModal";
import { ModalContext } from "App";
import * as actions from "../actions";

function ViewLoadTransaction({ newData, profileType }) {
  const setModal = useContext(ModalContext);

  const closeModal = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };
  return (
    <div>
      <div className="row px-4">
        <div className="col-2 px-0">
          <div className="text-muted">Retailer Name:</div>
        </div>
        <div className="col-4 px-0">
          <div className="text-muted font-weight-bold">
            {_.get(newData, "created_by_name")}
          </div>
        </div>
        <div className="col-1 px-0">
          <div className="text-muted">Date:</div>
        </div>
        <div className="col-4 px-0">
          <div className="text-muted font-weight-bold">
            {_.get(newData, "transaction_timestamp")}
          </div>
        </div>
      </div>
      <div className="row px-4 mt-3">
        <div className="col-2 px-0">
          <div className="text-muted">Retailer Number:</div>
        </div>
        <div className="col-4 px-0">
          <div className="text-muted font-weight-bold">
            {_.get(newData, "retailer_min")}
          </div>
        </div>
        <div className="col-2.6 px-0">
          <div className="text-muted">Reference Number:</div>
        </div>
        <div className="col-3 px-0 ml-2">
          <div className="text-muted font-weight-bold">
            {_.get(newData, "transaction_reference")}
          </div>
        </div>
      </div>
      <div className="flex-grow-1 mt-3">
        <div className="row">
          <div className="col-12">
            <table className="table table-striped table-sm table-borderless">
              <tbody>
                <tr>
                  <td
                    className="text-left text-muted"
                    style={{ width: 400, paddingLeft: 10 }}
                  >
                    Target No.
                  </td>
                  <td className="text-left text-muted font-weight-bold">
                    {_.get(newData, "target_mobile")}
                  </td>
                </tr>
                {_.get(newData, "promo_name") && (
                  <tr>
                    <td
                      className="text-left text-muted"
                      style={{ width: 400, paddingLeft: 10 }}
                    >
                      Promo
                    </td>
                    <td className="text-left text-muted font-weight-bold">
                      {_.get(newData, "promo_name")}
                    </td>
                  </tr>
                )}

                {_.get(newData, "promo_description") && (
                  <tr>
                    <td
                      className="text-left text-muted"
                      style={{ width: 400, paddingLeft: 10 }}
                    >
                      Promo Description
                    </td>
                    <td className="text-left text-muted font-weight-bold">
                      {_.get(newData, "promo_description")}
                    </td>
                  </tr>
                )}

                <tr>
                  <td
                    className="text-left text-muted"
                    style={{ width: 400, paddingLeft: 10 }}
                  >
                    Promo Price
                  </td>
                  <td className="text-left text-muted font-weight-bold">
                    {_.get(newData, "amount_loaded")}
                  </td>
                </tr>
                <tr>
                  <td
                    className="text-left text-muted"
                    style={{ width: 400, paddingLeft: 10 }}
                  >
                    Deducted
                  </td>
                  <td className="text-left text-muted font-weight-bold">
                    {_.get(newData, "amount_deducted")}
                  </td>
                </tr>

                {_.get(newData, "earning") && (
                  <tr>
                    <td
                      className="text-left text-muted"
                      style={{ width: 400, paddingLeft: 10 }}
                    >
                      Earnings
                    </td>
                    <td className="text-left text-muted font-weight-bold">
                      {_.get(newData, "earning")}
                    </td>
                  </tr>
                )}
                {profileType !== "retailer_users" && (
                  <tr>
                    <td
                      className="text-left text-muted"
                      style={{ width: 400, paddingLeft: 10 }}
                    >
                      Remaining Balance
                    </td>
                    <td className="text-left text-muted font-weight-bold">
                      {_.get(newData, "remaining_balance")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <button
          type="button"
          onClick={closeModal}
          className="mt-3 btn btn-modal-yes ml-auto mr-2"
          style={{ borderRadius: 8 }}
        >
          <span
            className="font-weight-bold"
            style={{ color: "#FFFFFF", fontSize: 14 }}
          >
            CLOSE
          </span>
        </button>
      </div>
    </div>
  );
}

ViewLoadTransaction.propTypes = {
  newData: PropTypes.instanceOf(Object).isRequired,
  profileType: PropTypes.string.isRequired,
};

const mapStateToProps = ({ api, modal }) => ({
  newData: modal.modalData,
  profileType: _.get(api, "AUTH/user_profile.item.profile_type") || "",
});

const enhance = _.flowRight([
  withModal("view-eload-modal", {
    title: "Transaction Details",
    size: "modal-lg modal-center",
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(ViewLoadTransaction);
