import * as req from "api/actions";
import * as c from "./constants";

export const setAuth = (payload) =>
  req.create(c.CREATE_CLIENT, "partners/api/v1/consumers/auth", payload);

export const validatePassword = (payload, callback, errCallback) =>
  req.create(
    c.VALIDATE_PASSWORD,
    "api/v1/external/users/my/validate-password",
    payload,
    callback,
    {},
    errCallback
  );

export const generateKey = (callback) =>
  req.update(c.GENERATE_KEY, "partners/api/v1/consumer/portal", {}, callback);

export const getBranches = (callback) =>
  req.list(c.GET_BRANCHES, "api/v1/branches", callback);
