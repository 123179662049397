import React from 'react';

const Loader = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{ height: '1.25rem', width: '1.25rem' }}
        className="spinner-border spinner-border-sm text-success"
        role="status"
      />
    </div>
  );
};

export default Loader;
