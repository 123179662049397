import React, { useState } from "react";
import Async from "react-select/async";
import PropTypes from "prop-types";
import { rootApi } from "helper";
import _ from "lodash";

function SelectShift({
  className,
  value,
  onChange,
  isMulti,
  placeholder,
  disabledValues,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeSelect = (args) => {
    onChange(args);
  };

  const loadOptions = (keyword) =>
    new Promise(async (resolve) => {
      // TODO: CLEAN LATER!
      try {
        const token = sessionStorage.getItem("token");
        if (!document.getElementById("select-shift")) return;
        setIsLoading(true);
        const raw = await fetch(`${rootApi}api/v1/schedules?q=${keyword}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const res = await raw.json();
        const options = res.data
          .map((x) => ({
            label: _.get(x, "attributes.name"),
            value: x.id,
          }))
          .filter((x) => disabledValues.indexOf(x.value) < 0);
        if (!document.getElementById("select-shift")) return;
        setIsLoading(false);

        resolve(options);
      } catch (err) {
        if (!document.getElementById("select-shift")) return;
        setIsLoading(true);
        resolve([]);
      }
    });

  return (
    <Async
      id="select-shift"
      className={className}
      isMulti={isMulti}
      defaultOptions
      placeholder={placeholder}
      onChange={handleChangeSelect}
      loadOptions={loadOptions}
      isLoading={isLoading}
      value={value}
    />
  );
}

SelectShift.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  disabledValues: PropTypes.instanceOf(Array),
};

SelectShift.defaultProps = {
  className: "",
  isMulti: false,
  placeholder: "Select Shift...",
  disabledValues: [],
};

export default SelectShift;
