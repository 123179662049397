import React from 'react';

import UserDetails from './children-components/UserDetails';
import CompanyInformation from './children-components/CompanyInformation';
import DeliveryAddressInformation from './children-components/DeliveryAddressInformation';
import MoaSignatoryInformation from './children-components/MoaSignatoryInformation';
import DealerAuthorization from './children-components/DealerAuthorization';

const CompanyInformationPage = () => {
  return (
    <div>
      <UserDetails />
      <CompanyInformation />
      <DeliveryAddressInformation />
      <MoaSignatoryInformation />
      <DealerAuthorization />
    </div>
  );
};

export default CompanyInformationPage;
