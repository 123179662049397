import React, { Fragment } from 'react';
import NoRoute from 'components/NoRoute';
import { useParams } from 'react-router-dom';
import RetailerListing from './RetailerListing';
import RetailerDetailsPanel from './RetailerDetailsPanel';
import ConfirmPassword from './ConfirmPassword';
// import RetailerSimsListing from './RetailerSimsListing';
// import AddNewSim from './AddNewSim';
// import ViewBalance from './ViewBalance';
import ViewUnlinked from './ViewUnlinked';
import NoticeUnlinked from './NoticeUnlinked';

const RetailerSims = () => {
  const params = useParams();

  return (
    <>
      <div className="container-fluid h-100">
        <NoticeUnlinked />
        <div className="row h-100">
          <div className="col-3 h-100 px-0">
            <RetailerListing />
          </div>
          <div className="col-9 px-0 bg-gray-100">
            {params.retailerId ? (
              <Fragment>
                <RetailerDetailsPanel />
                {/* <RetailerSimsListing />
                <AddNewSim />
                <ViewBalance /> */}
              </Fragment>
            ) : (
              <NoRoute />
            )}
          </div>
        </div>
      </div>
      <ViewUnlinked />
      <ConfirmPassword />
    </>
  );
};

export default RetailerSims;
