import React, { Fragment } from 'react';
import NoRoute from 'components/NoRoute';
import { useParams } from 'react-router-dom';
import AgentsListing from './AgentsListing';
import AgentLocationPanel from './AgentLocationPanel';
import AgentMinPanel from './AgentMinPanel';
import AgentRetailersPanel from './AgentRetailersPanel';

const AgentOperation = () => {
  const params = useParams();

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-3 h-100 px-0">
          <AgentsListing />
        </div>
        <div className="col-9 px-0 bg-gray-100">
          {params.agentId ? (
            <Fragment>
              <AgentLocationPanel />
              <AgentMinPanel />
              <AgentRetailersPanel />
            </Fragment>
          ) : (
            <NoRoute />
          )}
        </div>
      </div>
    </div>
  );
};

export default AgentOperation;
