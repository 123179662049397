import React, { useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ModalContext } from "App";
import { useRootPath } from "hooks";
import Button from "components/Button";
import { ToastError, ToastSuccess } from "modules/common/components/Toast";
import ErrorMessage from "modules/common/components/ErrorMessage";
import withModal from "modules/common/hoc/withModal";
import { createCluster, getClusters, updateCluster } from "../actions";
import * as c from "../constants";

const defaultData = {
  is_active: true,
  name: "",
  description: "",
};

const ClusterSetup = ({ isNew }) => {
  const [form, setForm] = useState(defaultData);
  const rootPath = useRootPath();
  const history = useHistory();
  const urlParams = useParams();
  const setModal = useContext(ModalContext);
  const queryClient = useQueryClient();
  const clusterId = _.get(urlParams, "clusterId");

  const upsertCluster = isNew ? createCluster : updateCluster;

  const apiCluster = useQuery({
    queryKey: ["cluster", clusterId],
    queryFn: getClusters,
    enabled: !isNew && clusterId,
    retry: false,
    keepPreviousData: true,
  });

  const isClusterFetching = apiCluster.isFetching;

  const { mutate: saveCluster, isLoading: isProcessing } = useMutation(
    upsertCluster,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("clusters");
        queryClient.invalidateQueries("cluster");
        ToastSuccess(`Successfully ${isNew ? `added` : `updated`} cluster.`);
        setModal({ isOpen: false });
        if (isNew) {
          const newId = _.get(res, "data.data.id");
          history.push(_.join([rootPath, newId], "/"));
        }
      },
      onError: (err) => {
        const defaultMessage = `Failed to ${isNew ? `add` : `update`} cluster.`;
        const errorMessage = (
          <ErrorMessage error={err} defaultMessage={defaultMessage} />
        );
        ToastError(errorMessage);
        setModal({ isOpen: false });
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = isNew ? { data: form } : { clusterId, data: form };
    saveCluster(params);
  };

  const handleModalClose = (e) => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setForm((state) => ({ ...state, [name]: value }));
  };

  const isFormValid = useMemo(() => {
    return !_.some(form, (v) => v === "");
  }, [form]);

  useEffect(() => {
    if (isNew) {
      setForm(defaultData);
      return;
    }

    const clusterData = _.pick(
      _.get(apiCluster, "data.data.attributes"),
      _.keys(defaultData)
    );

    setForm(clusterData);
  }, []);

  return (
    <div className="col">
      <form onSubmit={handleSubmit}>
        {/*
        <div className="d-flex justify-content-end">
          <div className="form-check d-flex justify-content-start align-items-center pl-0 my-2">
            <label
              className={`form-label mr-4 pr-1 text-md mb-0 ${
                form?.is_active ? 'text-primary' : ''
              }`}
            >
              {form?.is_active ? 'Activated' : 'Deactivated'}
            </label>
            <input
              type="checkbox"
              className="form-check-input toggle-switch"
              onChange={() => setForm(s => ({ ...s, is_active: !s.is_active }))}
              checked={_.get(form, 'is_active') || false}
            />
          </div>
        </div>
        */}
        <div className="row px-0">
          <div className="col">
            <label className="form-label text-primary">Cluster Code *</label>
            <input
              disabled={isClusterFetching || !isNew}
              type="text"
              name="name"
              className="form-control form-custom form-control_bottom"
              value={_.get(form, "name") || ""}
              onChange={handleInputChange}
              maxLength={c.TEXT_LIMIT}
            />
          </div>
          <div className="col">
            <label className="form-label text-primary">Description *</label>
            <input
              disabled={isClusterFetching}
              type="text"
              name="description"
              value={_.get(form, "description") || ""}
              className="form-control form-custom form-control_bottom"
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end pt-4">
          <Button
            loading={isClusterFetching || isProcessing}
            type="submit"
            disabled={!isFormValid}
          >
            Save
          </Button>
          <Button onClick={handleModalClose} className="btn btn-light ml-2">
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

ClusterSetup.propTypes = {
  isNew: PropTypes.bool,
};

ClusterSetup.defaultProps = {
  isNew: false,
};

const modalKey = "cluster-setup";
const modalConfig = {
  title: "Add Cluster",
  size: "modal-md",
};

export default withModal(modalKey, modalConfig)(ClusterSetup);
