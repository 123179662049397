import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { ToastError } from 'modules/common/components/Toast';
import { passwordStrength } from 'helper';

const RetailerChangePassword = ({
  onChangeInput,
  fieldsData,
  onNextStep,
  onPrevStep,
  onCancel,
}) => {
  const [visibleFields, setVisibleFields] = useState({
    old_password: false,
    new_password: false,
    new_password_confirmation: false,
  });

  const oldPw = _.get(fieldsData, 'old_password', '');
  const newPw = _.get(fieldsData, 'new_password', '');
  const newPwConfirm = _.get(fieldsData, 'new_password_confirmation', '');

  const {
    isAlphaNumeric,
    hasLowerCase,
    hasUpperCase,
    hasSpecialCharacter,
    isValid,
  } = passwordStrength(newPw);

  const onChangeVisibility = key => () => {
    setVisibleFields(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const checkFieldType = isVisible => (isVisible ? 'text' : 'password');
  const checkIconType = isVisible => (isVisible ? 'fa-eye-slash' : 'fa-eye');

  const onSubmit = e => {
    e.preventDefault();
    const isSameFromOldPassword = oldPw === newPw;
    const newPwDoesNotMatch = newPw !== newPwConfirm;
    const isMinNumCharsInvalid = newPw.length < 8;

    if (isSameFromOldPassword)
      return ToastError('New password cannot be the same as old password.');
    if (newPwDoesNotMatch)
      return ToastError('New password and confirm password did not match.');
    if (isMinNumCharsInvalid)
      return ToastError('Password needs to be 8 or more characters.');
    if (!isValid)
      return ToastError(
        'Password should contain characters from three out of four categories'
      );

    return onNextStep();
  };

  return (
    <div style={{ maxWidth: 450 }} className="w-100 mx-auto">
      <h5 className="font-weight-bold text-center m-0 mb-3">
        Change Temporary Password
      </h5>
      <p className="text-sm text-center m-0">
        Create a strong password to secure account. Please nominate a password
        that is different from your Temporary password.
      </p>

      <form onSubmit={onSubmit} className="change-pw-form">
        <div className="form-group mt-4">
          <div className="prepend-form-right">
            <label className="form-label text-primary">
              Temporary Password:
            </label>
            <input
              className="form-control form-custom form-control_bottom"
              type={checkFieldType(visibleFields.old_password)}
              placeholder="Temporary Password"
              required
              onChange={onChangeInput('old_password')}
              value={oldPw}
            />
            <button
              type="button"
              onClick={onChangeVisibility('old_password')}
              className="btn btn-default rounded-circle p-0 mr-1"
            >
              <i
                className={`fas ${checkIconType(visibleFields.old_password)}`}
              />
            </button>
          </div>
        </div>

        <div className="form-group">
          <div className="prepend-form-right">
            <label className="form-label text-primary">New Password:</label>
            <input
              className="form-control form-custom form-control_bottom"
              type={checkFieldType(visibleFields.new_password)}
              placeholder="New Password"
              required
              onChange={onChangeInput('new_password')}
              value={newPw}
            />
            <button
              type="button"
              onClick={onChangeVisibility('new_password')}
              className="btn btn-default rounded-circle p-0 mr-1"
            >
              <i
                className={`fas ${checkIconType(visibleFields.new_password)}`}
              />
            </button>
          </div>
        </div>

        <div className="form-group">
          <div className="prepend-form-right">
            <label className="form-label text-primary">Confirm Password:</label>
            <input
              className="form-control form-custom form-control_bottom"
              type={checkFieldType(visibleFields.new_password_confirmation)}
              required
              placeholder="Confirm New Password"
              onChange={onChangeInput('new_password_confirmation')}
              value={newPwConfirm}
            />
            <button
              type="button"
              onClick={onChangeVisibility('new_password_confirmation')}
              className="btn btn-default rounded-circle p-0 mr-1"
            >
              <i
                className={`fas ${checkIconType(
                  visibleFields.new_password_confirmation
                )}`}
              />
            </button>
          </div>
        </div>

        <p>
          <small>
            Password length should be minimum eight(8) characters and should
            contain characters from three out of four categories{' '}
          </small>
        </p>

        <div className="form-inline mb-4">
          <div className="form-group mr-2">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                checked={isAlphaNumeric}
                readOnly
              />
              <label className="custom-control-label" htmlFor="passwordAlpha">
                Alphanumeric
              </label>
            </div>
          </div>
          <div className="form-group mr-2">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                checked={hasUpperCase}
                readOnly
              />
              <label
                className="custom-control-label"
                htmlFor="passwordUppercase"
              >
                Uppercase Letter
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                readOnly
                checked={hasLowerCase}
              />
              <label
                className="custom-control-label"
                htmlFor="passwordLowercase"
              >
                Lowercase Letter
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                readOnly
                checked={hasSpecialCharacter}
              />
              <label
                className="custom-control-label"
                htmlFor="passwordSpecialChar"
              >
                Special Characters
              </label>
            </div>
          </div>
        </div>

        <div className="form-group mt-4">
          <button
            disabled={!isValid}
            type="submit"
            className="btn btn-block btn-primary px-4"
          >
            Change Password
          </button>

          <div className="text-center mt-2">
            <button
              type="button"
              className="prev-btn btn btn-link px-4 mr-4"
              onClick={onPrevStep}
            >
              Previous
            </button>
            <button
              type="button"
              className="cancel-btn btn btn-link px-4"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

RetailerChangePassword.propTypes = {
  fieldsData: PropTypes.instanceOf(Object).isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default RetailerChangePassword;
