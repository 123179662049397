import React, { useCallback } from "react";
import moment from "moment";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import logo from "assets/images/dealers-portal-logo-sm.png";
import AuthModal from "./modals/AuthModal";
import KeyModal from "./modals/KeyGeneratorModal";
import KeyGen from "./components/KeyGenerator";
import { invert, md5, sha256, sha1, removeEmpty } from "helper";
import Faker from "faker";

const apiDocUrl = `${process.env.PUBLIC_URL}/eload-gateway-2.0.1.openapi.yaml`;

const generateTransactionKey = (body) => {
  const getSecretKey = JSON.parse(sessionStorage.getItem("keyGenData"));
  const secretKey = getSecretKey?.secretKey;
  const { branchID, corporateID } = body;
  const bid = invert(branchID);
  const cid = invert(corporateID);
  const now = moment()
    .unix()
    .toString();

  return sha256(
    md5(now) +
      bid +
      md5(now) +
      bid +
      secretKey +
      md5(md5(now) + bid + md5(now) + bid + secretKey) +
      sha1(cid)
  );
};

function APIContainer() {
  const checkTransactionType = useCallback((transactionType) => {
    if (transactionType === "DLRINQ" || transactionType === "RTLINQ") {
      return "";
    }
    return Faker.finance.bic();
  }, []);
  const interceptorFn = (req) => {
    let apiPath = "";
    const ignoreURL = ["/partners/api/v1/consumers/auth"];

    try {
      apiPath = new URL(req.url);
      if (req.method !== "POST") return req;
      if (ignoreURL.includes(apiPath.pathname)) return req;

      const requestBody = JSON.parse(req.body);
      const transactionType = requestBody?.transactionType;
      const bodyJSON = {
        ...requestBody,
        transactionKey: generateTransactionKey(requestBody),
        requestRefNo: checkTransactionType(transactionType),
        requestTimestamp: moment()
          .unix()
          .toString(),
      };
      const removeEmptyRefNo = removeEmpty(bodyJSON);
      return { ...req, body: JSON.stringify(removeEmptyRefNo) };
    } catch (error) {
      console.log(error);
      return req;
    }
  };

  return (
    <>
      <div className="tw-container-fluid tw-overflow-x-hidden">
        <div
          className="card-body tw-font-semibold mb-0 mt-2 text-xl tw-text-white"
          style={{ backgroundColor: "#049051" }}
        >
          Eload API Gateway - API Documentation
        </div>
        <KeyGen />
        <div className="card-body tw-p-0 tw-break-words">
          <SwaggerUI
            layout="BaseLayout"
            logo={logo}
            requestInterceptor={interceptorFn}
            url={apiDocUrl}
          />
          <AuthModal />
          <KeyModal />
        </div>
      </div>
    </>
  );
}

export default APIContainer;
