import React from 'react';
import PropTypes from 'prop-types';

const Notice = ({ children, onClose }) => {
  return (
    <div
      className="rounded-0 alert alert-info alert-dismissible fade show"
      role="alert"
    >
      {children}
      <button
        onClick={onClose}
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

Notice.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClose: PropTypes.func,
};

Notice.defaultProps = {
  onClose: () => {},
};

export default Notice;
