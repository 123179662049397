import React from 'react';
import { checkPermissions } from 'helper';
import { useHistory, useRouteMatch } from 'react-router-dom';

function TransactionsButton() {
  const history = useHistory();
  const { url } = useRouteMatch();

  const handleTransactions = () =>
    history.push({ pathname: `${url}/transactions/` });

  return (
    <div className="d-flex">
      {checkPermissions(['can-view-dealer-sim']) && (
        <button
          type="button"
          style={{ backgroundColor: '#FFD339' }}
          className="text-uppercase  btn btn-md  text-white font-weight-bold mr-2 align-self-center"
          onClick={handleTransactions}
        >
          TRANSACTIONS
        </button>
      )}
    </div>
  );
}

export default TransactionsButton;
