import React, { useContext, useState, useMemo } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";

import { ModalContext } from "App";
import { removeEmpty } from "helper";
import withModal from "modules/common/hoc/withModal";
import { useLoading } from "modules/ui/hooks";
import ButtonAsync from "components/Button";
import LocalDropZone from "modules/common/components/LocalDropZone";
import { ToastSuccess } from "modules/common/components/Toast";
import { useProfile } from "modules/auth/hooks";

import * as actions from "../action";
import * as c from "../constant";

function BatchCreate({
  downloadFormat,
  createBatch,
  getRetailerUsers,
  isLoading,
}) {
  const setModal = useContext(ModalContext);
  const [file, setFile] = useState();
  const profile = useProfile();
  const role = useMemo(() => profile?.roles?.[0] || "", [profile]);

  const setFilter = useSelector(({ modal }) =>
    _.get(modal, "modalData.setFilter")
  );

  const templateUrl = useMemo(() => {
    let templateUrl = c.BaFlTemplates.ba_fl;

    if (role === "branch-admin") templateUrl = c.BaFlTemplates.fl;
    if (role === "branch-admin-non-migrated")
      templateUrl = c.BaFlTemplates.fl_nm;
    if (
      role === "super-user-non-migrated" ||
      role === "corporate-admin-non-migrated"
    )
      templateUrl = c.BaFlTemplates.ba_fl_nm;

    return templateUrl;
  }, [role]);

  const handleOnChangeUpload = (upload) => {
    setFile(upload);
  };

  const handleSubmitBranch = (e) => {
    e.preventDefault();
    const payload = {
      upload: file,
      role_guard_name: "",
      user_guard_name: "",
    };
    if (role === "super-user-non-migrated")
      payload.role_guard_name = "non-migrated-retailer-user";
    if (role === "corporate-admin-non-migrated") {
      payload.user_guard_name = "non-migrated-dealer-user";
      payload.role_guard_name = "non-migrated-retailer-user";
    }
    if (role === "branch-admin-non-migrated") {
      payload.user_guard_name = "non-migrated-retailer-user";
      payload.role_guard_name = "non-migrated-retailer-user";
    }
    createBatch(removeEmpty(payload), (res) => {
      getRetailerUsers(c.blFlInitState);
      setFilter(c.blFlInitState);
      setModal({ isOpen: false });
      ToastSuccess(`${_.get(res, "res.data.success.0.detail")}`);
      setFile();
    });
  };

  return (
    <form className="container-fluid py-3 px-5 pb-4">
      <div className="row">
        <div className="mb-4">
          <strong>Easy steps on creating accounts by batch</strong>
        </div>
        <div className="row mt-1">
          <div className="col-6 d-flex align-items-start border-right">
            <div className="mr-2 px-2 rounded-circle bg-dark">
              <span className="text-white">1</span>
            </div>
            <div className="description" style={{ lineHeight: "1.3" }}>
              <small>
                Download the starting template (.xlxs) below and follow the
                instructions within the file to generate file for batch upload.
              </small>
              <p className="mt-3">
                <Link
                  to={`${process.env.PUBLIC_URL}/${templateUrl}`}
                  target="_blank"
                  download
                  className="btn btn-primary"
                >
                  <i className="fas fa-download mr-2" />
                  <small>Download Starting Template</small>
                </Link>
              </p>
            </div>
          </div>

          <div className="col-6 d-flex align-items-start pl-5 h-100">
            <div className="mr-2 px-2 rounded-circle bg-dark">
              <span className="text-white">2</span>
            </div>
            <div className="description">
              <div style={{ lineHeight: "1.3" }}>
                <small>
                  Drag and drop the accomplished CSV <br />
                  (Comma Separated Values or Comma Delimited) template file on
                  the field below or click it to launch your system&apos;s file
                  selection dialog
                </small>
              </div>
              <div className="row d-flex align-items-center my-3 mb-4">
                <div className="col-5 pr-0">
                  <LocalDropZone
                    fileType=".csv"
                    onChange={handleOnChangeUpload}
                    hasInternal
                    caption="Click here or drag a file to upload"
                  />
                </div>
                <div className="col-7">
                  <input
                    type="text"
                    readOnly
                    value={file || ""}
                    className="form-control form-control_bottom "
                    placeholder="Upload your File"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <ButtonAsync
                    type="submit"
                    onClick={handleSubmitBranch}
                    disabled={isLoading || !file}
                    loading={useLoading(c.CREATE_BATCH)}
                    className="btn btn-primary btn-block position-relative"
                  >
                    Batch Create
                  </ButtonAsync>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

BatchCreate.propTypes = {
  downloadFormat: PropTypes.func.isRequired,
  createBatch: PropTypes.func.isRequired,
  getRetailerUsers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ api }) => ({
  isLoading: api.loading.indexOf(c.CREATE_BATCH) > -1,
});

const enhance = _.flowRight([
  withModal("batch-create", {
    title: "Batch Create Account",
    size: "modal-lg",
  }),
  withRouter,
  connect(mapStateToProps, actions),
]);

export default enhance(BatchCreate);
